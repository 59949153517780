import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { List } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import InfiniteScroll from "react-infinite-scroll-component";

import AllOrdersInfoMessage from "./AllOrdersInfoMessage";

import { utilsHelper } from "tap-io/helpers";
import { orderService } from "tap-io/client/services";

import OrderItem from "./OrderItem";

const styles = (theme) => ({
  orderList: {
    //overflowY: "scroll",
    //height: "100%"
  }
});

const ORDERS_INITIAL_BATCH_SIZE = 10;
const ORDERS_ADDITIONAL_BATCH_SIZE = 5;

class AllOrders extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      allOrdersMaxCount: ORDERS_INITIAL_BATCH_SIZE
    };

    this.orderListRef = React.createRef();
  }

  componentDidMount() {
    this.refreshAllOrders();
  }

  componentWillUnmount() {
    if (this.unsubscribeAllOrders) {
      this.unsubscribeAllOrders();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { bar, base } = this.props;
    const { allOrdersMaxCount } = this.state;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;

    const menuIds = base ? base.menuIds : undefined;
    const prevMenuIds = prevProps.base ? prevProps.base.menuIds : undefined;

    const prevAllOrdersMaxCount = prevState.allOrdersMaxCount;

    if (
      barId !== prevBarId ||
      !utilsHelper.areArraysEqualShallow(menuIds, prevMenuIds) ||
      allOrdersMaxCount !== prevAllOrdersMaxCount
    ) {
      this.refreshAllOrders();
    }
  }

  refreshAllOrders = () => {
    const { bar, base } = this.props;
    const { allOrdersMaxCount } = this.state;

    if (this.unsubscribeAllOrders) {
      this.unsubscribeAllOrders();
    }

    if (bar && bar.id) {
      this.unsubscribeAllOrders = orderService.onAllOrders(
        bar.id,
        (orders) => {
          this.setState({
            allOrders: orders
          });
        },
        allOrdersMaxCount,
        base ? base.menuIds : undefined
      );
    } else {
      this.unsubscribeAllOrders = undefined;
    }
  };

  handleFetchMoreOrders = () => {
    const { allOrdersMaxCount } = this.state;

    this.setState({
      allOrdersMaxCount: allOrdersMaxCount + ORDERS_ADDITIONAL_BATCH_SIZE
    });
  };

  render() {
    const { classes, t, bar, base, onOrderClick } = this.props;
    const { allOrdersMaxCount, allOrders } = this.state;

    // TO FIX: scrollableTarget

    return (
      <List ref={this.orderListRef} className={classes.orderList}>
        <AllOrdersInfoMessage bar={bar} />
        {allOrders && (
          <InfiniteScroll
            scrollableTarget={
              this.orderListRef &&
              this.orderListRef.current &&
              this.orderListRef.current.parentElement
                ? this.orderListRef.current.parentElement.parentElement
                : null
            }
            dataLength={allOrders.length}
            next={this.handleFetchMoreOrders}
            hasMore={
              allOrders === undefined || allOrdersMaxCount <= allOrders.length
            }
            loader={<h4>{t("label.loading")}</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>{t("order.all-orders-are-shown")}</b>
              </p>
            }
          >
            {allOrders.map((order) => (
              <OrderItem
                key={order.id}
                bar={bar}
                base={base}
                order={order}
                onClick={onOrderClick}
              />
            ))}
          </InfiniteScroll>
        )}
      </List>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(AllOrders)
);
