import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import SelectPlan from "./SelectPlan";

const styles = (theme) => ({
  dialog: {}
});

class SelectPlanDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, isOpen, onClose, bar, subscription } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        classes={{ paper: classes.dialog }}
        fullScreen
      >
        <DialogTitle>{t("subscription.select-a-plan")}</DialogTitle>
        <DialogContent>
          <SelectPlan
            bar={bar}
            subscription={subscription}
            onSelect={onClose}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {t("label.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(SelectPlanDialog));
