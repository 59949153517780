import { Asyncer } from "../../../components/common/Asyncer";
import { Async } from "../../../components/common/useAsync";
import { ProductReport } from "../../models/ProductReport";
import { BarStats } from "../common/BarStats";
import { DownloadIconCSVButton } from "../common/DownloadCSVIconButton";
import { FailedStatCard } from "../common/FailedStatCard";
import { LoadingStatCard } from "../common/LoadingStatCard";
import { StatCard } from "../common/StatCard";

export type InclusionOfProductsProps = {
  async: Async<ProductReport>;
  mark: string;
  t: any;
};

export function InclusionOfProducts(props: InclusionOfProductsProps) {
  const { async, mark, t } = props;

  const title = t("stats.stat.inclusion-per-product.title");
  const description = t("stats.stat.inclusion-per-product.description");

  return (
    <Asyncer
      async={async}
      Busy={<LoadingStatCard title={title} description={description} />}
      Failed={
        <FailedStatCard title={title} description={description} async={async} />
      }
    >
      {(report) => {
        return (
          <StatCard
            title={title}
            description={description}
            action={
              <DownloadIconCSVButton
                title={`${title}-${mark}`}
                csv={report.toInclusionCSV(t)}
              />
            }
          >
            <BarStats
              data={report.data}
              x="product"
              formatX={(_, r) => r.name}
              y="inclusionAmount"
              formatY={report.formatInteger}
              t={t}
            />
          </StatCard>
        );
      }}
    </Asyncer>
  );
}
