import React, { PureComponent } from "react";

import Fab from "@mui/material/Fab";
import CloseIcon from "@mui/icons-material/Close";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  root: {
    zIndex: 1000,
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "white"
  },
  video: {
    width: "100%",
    height: "100%"
  },
  button: {
    position: "absolute",
    top: 20,
    right: 20
  }
});

class QuickStart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /*this.unsubscribeConf = store.onGetConf("quickstart", conf => {
      this.setState({ conf });
    });*/
  }

  componentWillUnmount() {
    /*if (this.unsubscribeConf) {
      this.unsubscribeConf();
      this.unsubscribeConf = undefined;
    }*/
  }

  render() {
    const { classes, onClose } = this.props;
    const { conf } = this.state;

    return (
      <div className={classes.root}>
        {conf && conf.youTubeLink && (
          <iframe
            className={classes.video}
            width="560"
            height="315"
            src={conf.youTubeLink}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
        <Fab
          onClick={onClose}
          color="primary"
          aria-label="add"
          className={classes.button}
        >
          <CloseIcon />
        </Fab>
      </div>
    );
  }
}

export default withStyles(styles)(QuickStart);
