import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Switch,
  TextField
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { barHelper, utilsHelper } from "tap-io/helpers";
import { barService } from "tap-io/client/services";

import PaymentProvider from "tap-io/models/payment/PaymentProvider";

import ConfirmDeactivatePaymentProvider from "../payment/ConfirmDeactivatePaymentProvider";

const styles = (theme) => ({
  accentuate: {
    color: "red"
  },
  buttons: {
    justifyContent: "flex-end"
  },
  link: {
    textDecoration: "none"
  }
});

class EditStarnet extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (conf, isReady) => {
    const mergedConf = {
      host: conf && conf.host ? conf.host : "",
      apiKey: conf && conf.apiKey ? conf.apiKey : "",
      topUpUrl: conf?.topUpUrl || ""
    };

    return {
      isConfirmDeactivateStarnetPaymentsDialogOpen: false,
      isReady: isReady === true,
      isValid: barHelper.isValidBarStarnetPaymentProviderConf(conf),
      isDisabled: false,
      isAPIKeyRevealed: false,
      conf: mergedConf,
      savedConf: mergedConf
    };
  };

  initConf = () => {
    const { bar } = this.props;

    if (this.unsubscribeStarnetConf) {
      this.unsubscribeStarnetConf();
      this.unsubscribeStarnetConf = undefined;
    }

    this.unsubscribeStarnetConf = barService.onBarStarnetPaymentProviderConf(
      bar,
      (conf) => {
        this.setState(this.initialState(conf, true));
      }
    );
  };

  componentDidMount() {
    const { setOnSave } = this.props;

    if (setOnSave) {
      setOnSave(this.handleSaveConf);
    }

    this.initConf();
  }

  componentDidUpdate(prevProps, prevState) {
    const { bar } = this.props;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;

    if (barId !== prevBarId) {
      this.initConf();
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeStarnetConf) {
      this.unsubscribeStarnetConf();
      this.unsubscribeStarnetConf = undefined;
    }
  }

  handleChange = (key) => (event) => {
    const { conf } = this.state;
    const val = event.target.value;

    const newConf = { ...conf, [key]: val };
    const isValid = barHelper.isValidBarStarnetPaymentProviderConf(newConf);

    this.setState({ isValid, conf: newConf });
  };

  handleRevealAPIKey = (event) => {
    this.setState({ isAPIKeyRevealed: true });
  };

  handleObfuscateAPIKey = (event) => {
    this.setState({ isAPIKeyRevealed: false });
  };

  hasConfChanged = () => {
    const { conf, savedConf } = this.state;

    return !utilsHelper.areObjectsEqualShallow(conf, savedConf);
  };

  handleSaveConf = async () => {
    const { t, bar } = this.props;
    const { conf } = this.state;

    const toastId = toast(t("settings.saving-starnet-configuration"), {
      autoClose: false
    });
    this.setState({ isDisabled: true });

    try {
      await barService.setBarStarnetPaymentProviderConf(bar, conf);

      toast.update(toastId, {
        render: t("settings.starnet-configuration-saved"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
      this.setState({ isDisabled: false });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleRestoreConf = () => {
    this.initConf();
  };

  handleAllowStarnetPayments = async (event) => {
    const allowStarnetPayments = event.target.checked === true;

    if (allowStarnetPayments) {
      this.updateAllowStarnetPayments(allowStarnetPayments);
    } else {
      this.setState({ isConfirmDeactivateStarnetPaymentsDialogOpen: true });
    }
  };

  handleConfirmDeactivateStarnetPayments = () => {
    this.setState({ isConfirmDeactivateStarnetPaymentsDialogOpen: false });

    this.updateAllowStarnetPayments(false);
  };

  handleCancelDeactivateStarnetPayments = () => {
    this.setState({ isConfirmDeactivateStarnetPaymentsDialogOpen: false });
  };

  updateAllowStarnetPayments = async (allowStarnetPayments) => {
    const { t, bar } = this.props;

    if (allowStarnetPayments) {
      await this.handleSaveConf();
    }

    const toastId = toast(
      allowStarnetPayments
        ? t("settings.enabling-starnet-payments")
        : t("settings.disabling-starnet-payments"),
      { autoClose: false }
    );
    this.setState({ isDisabled: true });

    try {
      await barService.setBarStarnetPaymentProviderIsActive(
        bar,
        allowStarnetPayments
      );

      toast.update(toastId, {
        render: allowStarnetPayments
          ? t("settings.starnet-payments-enabled")
          : t("settings.starnet-payments-disabled"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, bar, setOnSave } = this.props;
    const {
      isConfirmDeactivateStarnetPaymentsDialogOpen,
      isReady,
      isValid,
      isDisabled,
      isAPIKeyRevealed,
      conf
    } = this.state;
    const { host, apiKey, topUpUrl } = conf;

    const allowStarnetPayments = bar.isAllowingStarnetPayments();

    return (
      <div>
        <ConfirmDeactivatePaymentProvider
          isOpen={isConfirmDeactivateStarnetPaymentsDialogOpen}
          paymentProvider={PaymentProvider.STARNET}
          onConfirm={this.handleConfirmDeactivateStarnetPayments}
          onCancel={this.handleCancelDeactivateStarnetPayments}
        />
        {isReady ? (
          <List>
            <ListItem>
              <ListItemText
                primary={t("settings.starnet-payments-label")}
                secondary={
                  !isValid && (
                    <span className={classes.accentuate}>
                      {t(
                        "settings.complete-configuration-below-to-enable-payments"
                      )}
                    </span>
                  )
                }
              />
              <Switch
                onChange={this.handleAllowStarnetPayments}
                checked={allowStarnetPayments}
                disabled={isDisabled || (!isValid && !allowStarnetPayments)}
              />
            </ListItem>
            <ListItem>
              <TextField
                variant="standard"
                margin="dense"
                label={t("settings.starnet-host-label")}
                helperText={t("settings.starnet-host-description")}
                fullWidth
                value={host}
                onChange={this.handleChange("host")}
                disabled={isDisabled || allowStarnetPayments}
                autoComplete="new-password"
                required
              />
            </ListItem>
            <ListItem>
              <TextField
                variant="standard"
                margin="dense"
                label={t("settings.starnet-api-key-label")}
                helperText={t("settings.starnet-api-key-description")}
                fullWidth
                type={isAPIKeyRevealed ? undefined : "password"}
                value={apiKey}
                onChange={this.handleChange("apiKey")}
                onFocus={this.handleRevealAPIKey}
                onBlur={this.handleObfuscateAPIKey}
                disabled={isDisabled || allowStarnetPayments}
                autoComplete="new-password"
                required
              />
            </ListItem>
            <ListItem>
              <TextField
                variant="standard"
                margin="dense"
                label={t("settings.starnet-top-up-url-label")}
                helperText={t("settings.starnet-top-up-url-description")}
                fullWidth
                value={topUpUrl}
                onChange={this.handleChange("topUpUrl")}
                disabled={isDisabled || allowStarnetPayments}
              />
            </ListItem>
            {!allowStarnetPayments && !setOnSave && (
              <ListItem className={classes.buttons}>
                {this.hasConfChanged() && (
                  <Button
                    color="secondary"
                    className={classes.button}
                    disabled={isDisabled}
                    onClick={this.handleRestoreConf}
                  >
                    {t("label.restore")}
                  </Button>
                )}
                <Button
                  color="primary"
                  className={classes.button}
                  disabled={isDisabled}
                  onClick={this.handleSaveConf}
                >
                  {t("label.save")}
                </Button>
              </ListItem>
            )}
          </List>
        ) : (
          <CircularProgress />
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditStarnet)
);

// import React from "react";

// import { withTranslation } from "react-i18next";

// import { toast } from "react-toastify";

// import { List, ListItem, ListItemText, Switch } from "@mui/material";
// import withStyles from "@mui/styles/withStyles";

// import { barService } from "tap-io/client/services";
// import PaymentProvider from "tap-io/models/payment/PaymentProvider";

// import ConfirmDeactivatePaymentProvider from "../payment/ConfirmDeactivatePaymentProvider";

// const styles = (theme) => ({
//   link: {
//     textDecoration: "none",
//     color: theme.palette.primary.main
//   }
// });

// class EditStarnet extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       isConfirmDeactivateStarnetPaymentsDialogOpen: false
//     };
//   }

//   handleAllowStarnetPayments = async (event) => {
//     const allowStarnetPayments = event.target.checked === true;

//     if (allowStarnetPayments) {
//       this.updateAllowStarnetPayments(allowStarnetPayments);
//     } else {
//       this.setState({ isConfirmDeactivateStarnetPaymentsDialogOpen: true });
//     }
//   };

//   handleConfirmDeactivateStarnetPayments = () => {
//     this.setState({ isConfirmDeactivateStarnetPaymentsDialogOpen: false });

//     this.updateAllowStarnetPayments(false);
//   };

//   handleCancelDeactivateStarnetPayments = () => {
//     this.setState({ isConfirmDeactivateStarnetPaymentsDialogOpen: false });
//   };

//   updateAllowStarnetPayments = async (allowStarnetPayments) => {
//     const { t, bar } = this.props;

//     const toastId = toast(
//       allowStarnetPayments
//         ? t("settings.enabling-starnet-payments")
//         : t("settings.disabling-starnet-payments"),
//       { autoClose: false }
//     );
//     this.setState({ isDisabled: true });

//     try {
//       await barService.setBarStarnetPaymentProviderIsActive(
//         bar,
//         allowStarnetPayments
//       );

//       toast.update(toastId, {
//         render: allowStarnetPayments
//           ? t("settings.starnet-payments-enabled")
//           : t("settings.starnet-payments-disabled"),
//         type: toast.TYPE.INFO,
//         autoClose: 3000
//       });

//       this.setState({ isDisabled: false });
//     } catch (error) {
//       console.warn(error);

//       this.setState({ isDisabled: false });
//       toast.update(toastId, {
//         render: `${t("label.something-went-wrong")} (${error.toString()})`,
//         type: toast.TYPE.ERROR,
//         autoClose: 5000
//       });
//     }
//   };

//   render() {
//     const { t, bar, classes } = this.props;
//     const { isConfirmDeactivateStarnetPaymentsDialogOpen } = this.state;

//     const isDisabled = this.props.isDisabled || this.state.isDisabled;

//     return (
//       <div>
//         <ConfirmDeactivatePaymentProvider
//           isOpen={isConfirmDeactivateStarnetPaymentsDialogOpen}
//           paymentProvider={PaymentProvider.STARNET}
//           onConfirm={this.handleConfirmDeactivateStarnetPayments}
//           onCancel={this.handleCancelDeactivateStarnetPayments}
//         />
//         <List>
//           <ListItem>
//             <ListItemText
//               primary={t("settings.starnet-payments-label")}
//               secondary={t("settings.starnet-payments-description")}
//             />
//             <Switch
//               onChange={this.handleAllowStarnetPayments}
//               checked={bar.isAllowingStarnetPayments()}
//               disabled={isDisabled}
//             />
//           </ListItem>
//         </List>
//       </div>
//     );
//   }
// }

// export default withStyles(styles, { withTheme: true })(
//   withTranslation("common")(EditStarnet)
// );
