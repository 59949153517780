import { Chip, Typography } from "@mui/material";
import { ReactElement } from "react";

export type StatChipProps = {
  title?: string;
  value?: string;
  icon?: ReactElement;
};

export function StatChip(props: StatChipProps) {
  const { title, value, icon } = props;

  return (
    <Chip
      icon={icon}
      color="primary"
      label={
        <Typography variant="button">
          {title} <b>{value}</b>
        </Typography>
      }
    />
  );
}
