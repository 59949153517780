import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Switch,
  TextField
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { barHelper, utilsHelper } from "tap-io/helpers";
import { barService } from "tap-io/client/services";
import PaymentProvider from "tap-io/models/payment/PaymentProvider";

import ConfirmDeactivatePaymentProvider from "../payment/ConfirmDeactivatePaymentProvider";

const styles = (theme) => ({
  accentuate: {
    color: "red"
  },
  buttons: {
    justifyContent: "flex-end"
  }
});

class EditPayconiq extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (conf, isReady) => {
    const mergedConf = {
      merchantName: conf && conf.merchantName ? conf.merchantName : "",
      merchantId: conf && conf.merchantId ? conf.merchantId : "",
      //paymentProfileId: conf && conf.paymentProfileId ? conf.paymentProfileId : "",
      apiKey: conf && conf.apiKey ? conf.apiKey : ""
    };

    return {
      isConfirmDeactivatePayconiqOnlinePaymentsDialogOpen: false,
      isConfirmDeactivatePayconiqPosPaymentsDialogOpen: false,
      isReady: isReady === true,
      isValid: barHelper.isValidBarPayconiqPaymentProviderConf(conf),
      isDisabled: false,
      isAPIKeyRevealed: false,
      conf: mergedConf,
      savedConf: mergedConf
    };
  };

  initConf = () => {
    const { bar } = this.props;

    if (this.unsubscribePayconiqConf) {
      this.unsubscribePayconiqConf();
      this.unsubscribePayconiqConf = undefined;
    }

    this.unsubscribePayconiqConf = barService.onBarPayconiqPaymentProviderConf(
      bar,
      (conf) => {
        this.setState(this.initialState(conf, true));
      }
    );
  };

  componentDidMount() {
    const { setSave } = this.props;

    if (setSave) {
      setSave(this.saveConf);
    }

    this.initConf();
  }

  componentDidUpdate(prevProps, prevState) {
    const { bar } = this.props;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;

    if (barId !== prevBarId) {
      this.initConf();
    }
  }

  componentWillUnmount() {
    if (this.unsubscribePayconiqConf) {
      this.unsubscribePayconiqConf();
      this.unsubscribePayconiqConf = undefined;
    }
  }

  handleChange = (key) => (event) => {
    const { conf } = this.state;
    const val = event.target.value;

    const newConf = { ...conf, [key]: val };
    const isValid = barHelper.isValidBarPayconiqPaymentProviderConf(newConf);

    this.setState({ isValid, conf: newConf });
  };

  handleRevealAPIKey = (event) => {
    this.setState({ isAPIKeyRevealed: true });
  };

  handleObfuscateAPIKey = (event) => {
    this.setState({ isAPIKeyRevealed: false });
  };

  hasConfChanged = () => {
    const { conf, savedConf } = this.state;

    return !utilsHelper.areObjectsEqualShallow(conf, savedConf);
  };

  handleSaveConf = async () => {
    const { t, bar } = this.props;
    const { conf } = this.state;

    const toastId = toast(t("settings.saving-payconiq-configuration"), {
      autoClose: false
    });
    this.setState({ isDisabled: true });

    try {
      await barService.setBarPayconiqPaymentProviderConf(bar, conf);

      toast.update(toastId, {
        render: t("settings.payconiq-configuration-saved"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
      this.setState({ isDisabled: false });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleRestoreConf = () => {
    this.initConf();
  };

  handleAllowPayconiqOnlinePayments = async (event) => {
    const allowPayconiqOnlinePayments = event.target.checked === true;

    const { bar } = this.props;

    if (allowPayconiqOnlinePayments) {
      this.updateAllowPayconiqPayments(
        allowPayconiqOnlinePayments,
        bar.isAllowingPayconiqPosPayments()
      );
    } else {
      this.setState({
        isConfirmDeactivatePayconiqOnlinePaymentsDialogOpen: true
      });
    }
  };

  handleAllowPayconiqPosPayments = async (event) => {
    const allowPayconiqPosPayments = event.target.checked === true;

    const { bar } = this.props;

    if (allowPayconiqPosPayments) {
      this.updateAllowPayconiqPayments(
        bar.isAllowingPayconiqOnlinePayments(),
        allowPayconiqPosPayments
      );
    } else {
      this.setState({ isConfirmDeactivatePayconiqPosPaymentsDialogOpen: true });
    }
  };

  handleConfirmDeactivatePayconiqOnlinePayments = () => {
    const { bar } = this.props;

    this.setState({
      isConfirmDeactivatePayconiqOnlinePaymentsDialogOpen: false
    });

    this.updateAllowPayconiqPayments(
      false,
      bar.isAllowingPayconiqPosPayments()
    );
  };

  handleCancelDeactivatePayconiqOnlinePayments = () => {
    this.setState({
      isConfirmDeactivatePayconiqOnlinePaymentsDialogOpen: false
    });
  };

  handleConfirmDeactivatePayconiqPosPayments = () => {
    const { bar } = this.props;

    this.setState({ isConfirmDeactivatePayconiqPosPaymentsDialogOpen: false });

    this.updateAllowPayconiqPayments(
      bar.isAllowingPayconiqOnlinePayments(),
      false
    );
  };

  handleCancelDeactivatePayconiqPosPayments = () => {
    this.setState({ isConfirmDeactivatePayconiqPosPaymentsDialogOpen: false });
  };

  updateAllowPayconiqPayments = async (
    allowPayconiqOnlinePayments,
    allowPayconiqPosPayments
  ) => {
    const { t, bar } = this.props;

    const allowPayconiqPayments =
      allowPayconiqOnlinePayments || allowPayconiqPosPayments;
    if (allowPayconiqPayments) {
      await this.handleSaveConf();
    }

    const toastId = toast(t("settings.updating-payconiq-payments"), {
      autoClose: false
    });
    this.setState({ isDisabled: true });

    try {
      await barService.setBarPayconiqPaymentProviderIsActive(
        bar,
        allowPayconiqOnlinePayments,
        allowPayconiqPosPayments
      );

      toast.update(toastId, {
        render: t("settings.payconiq-payments-updated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, bar, setSave } = this.props;
    const {
      isConfirmDeactivatePayconiqOnlinePaymentsDialogOpen,
      isConfirmDeactivatePayconiqPosPaymentsDialogOpen,
      isReady,
      isValid,
      isDisabled,
      isAPIKeyRevealed,
      conf
    } = this.state;
    const { merchantName, merchantId, /*paymentProfileId,*/ apiKey } = conf;

    const allowPayconiqOnlinePayments = bar.isAllowingPayconiqOnlinePayments();
    const allowPayconiqPosPayments = bar.isAllowingPayconiqPosPayments();
    const allowPayconiqPayments =
      allowPayconiqOnlinePayments || allowPayconiqPosPayments;

    return (
      <div>
        <ConfirmDeactivatePaymentProvider
          isOpen={isConfirmDeactivatePayconiqOnlinePaymentsDialogOpen}
          paymentProvider={PaymentProvider.PAYCONIQ}
          onConfirm={this.handleConfirmDeactivatePayconiqOnlinePayments}
          onCancel={this.handleCancelDeactivatePayconiqOnlinePayments}
        />
        <ConfirmDeactivatePaymentProvider
          isOpen={isConfirmDeactivatePayconiqPosPaymentsDialogOpen}
          paymentProvider={PaymentProvider.PAYCONIQ}
          onConfirm={this.handleConfirmDeactivatePayconiqPosPayments}
          onCancel={this.handleCancelDeactivatePayconiqPosPayments}
        />
        {isReady ? (
          <List>
            <ListItem>
              <ListItemText
                primary={t("settings.payconiq-pos-payments-label")}
                secondary={
                  !isValid && (
                    <span className={classes.accentuate}>
                      {t(
                        "settings.complete-configuration-below-to-enable-payments"
                      )}
                    </span>
                  )
                }
              />
              <Switch
                onChange={this.handleAllowPayconiqPosPayments}
                checked={allowPayconiqPosPayments}
                disabled={isDisabled || !isValid}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={t("settings.payconiq-online-payments-label")}
                secondary={
                  !isValid && (
                    <span className={classes.accentuate}>
                      {t(
                        "settings.complete-configuration-below-to-enable-payments"
                      )}
                    </span>
                  )
                }
              />
              <Switch
                onChange={this.handleAllowPayconiqOnlinePayments}
                checked={allowPayconiqOnlinePayments}
                disabled={isDisabled || !isValid}
              />
            </ListItem>
            <ListItem>
              <TextField
                variant="standard"
                margin="dense"
                label={t("settings.payconiq-merchant-name-label")}
                helperText={t("settings.payconiq-merchant-name-description")}
                fullWidth
                value={merchantName}
                onChange={this.handleChange("merchantName")}
                disabled={isDisabled || allowPayconiqPayments}
              />
            </ListItem>
            <ListItem>
              <TextField
                variant="standard"
                margin="dense"
                label={t("settings.payconiq-merchant-id-label")}
                helperText={t("settings.payconiq-merchant-id-description")}
                fullWidth
                value={merchantId}
                onChange={this.handleChange("merchantId")}
                disabled={isDisabled || allowPayconiqPayments}
                autoComplete="new-password"
                required
              />
            </ListItem>
            <ListItem>
              <TextField
                variant="standard"
                margin="dense"
                label={t("settings.payconiq-api-key-label")}
                helperText={t("settings.payconiq-api-key-description")}
                fullWidth
                type={isAPIKeyRevealed ? undefined : "password"}
                value={apiKey}
                onChange={this.handleChange("apiKey")}
                onFocus={this.handleRevealAPIKey}
                onBlur={this.handleObfuscateAPIKey}
                disabled={isDisabled || allowPayconiqPayments}
                autoComplete="new-password"
                required
              />
            </ListItem>
            {!allowPayconiqPayments && !setSave && (
              <ListItem className={classes.buttons}>
                {this.hasConfChanged() && (
                  <Button
                    color="secondary"
                    className={classes.button}
                    disabled={isDisabled}
                    onClick={this.handleRestoreConf}
                  >
                    {t("label.restore")}
                  </Button>
                )}
                <Button
                  color="primary"
                  className={classes.button}
                  disabled={isDisabled}
                  onClick={this.handleSaveConf}
                >
                  {t("label.save")}
                </Button>
              </ListItem>
            )}
          </List>
        ) : (
          <CircularProgress />
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditPayconiq)
);

/*
<ListItem>
  <TextField
    variant="standard"
    margin="dense"
    id="description"
    label="Payment Profile ID"
    helperText=""
    fullWidth
    value={paymentProfileId}
    onChange={this.handleChange("paymentProfileId")}
    disabled={allowPayconiqPayments}
  />
</ListItem>
*/
