import React from "react";
import classNames from "classnames";

import withStyles from "@mui/styles/withStyles";

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography
} from "@mui/material";

const CARD_MARGIN = 10;

const styles = (theme) => ({
  card: {
    // Does flex still cause issues for older versions of Chrome?
    display: "flex",
    flexDirection: "column",
    margin: CARD_MARGIN,
    width: "100%"
  },
  responsiveCard: {
    [theme.breakpoints.up(1200)]: {
      maxWidth: `calc(50% - ${2 * CARD_MARGIN}px)`
    }
  },
  title: {
    display: "block",
    textAlign: "left",
    fontSize: 20,
    fontWeight: "bold"
  },
  subtitle: {
    textAlign: "left"
  },
  action: {
    margin: 0
  },
  content: {
    //height: "100%", // filler class is used (legacy Safari issue)
    textAlign: "left"
  },
  filler: {
    flexGrow: 1
  },
  description: {
    margin: "0 16px",
    color: theme.palette.text.secondary
  }
});

class ContentCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes,
      variant,
      width,
      maxWidth,
      margin,
      headerLeft,
      headerRight,
      title,
      subtitle,
      description,
      children,
      footer,
      onClick
    } = this.props;

    const isResponsive =
      this.props.isResponsive === undefined || this.props.isResponsive;

    return (
      <Card
        className={classNames(
          classes.card,
          isResponsive ? classes.responsiveCard : undefined
        )}
        variant={variant}
        style={{
          width: width === undefined ? undefined : width,
          maxWidth: maxWidth === undefined ? undefined : maxWidth,
          margin: margin === undefined ? undefined : margin
        }}
        onClick={onClick}
      >
        <CardHeader
          avatar={headerLeft}
          action={headerRight}
          title={title}
          subheader={subtitle}
          classes={{
            title: classes.title,
            subheader: classes.subtitle,
            action: classes.action
          }}
        />
        {(description || children) && (
          <CardContent className={classes.content}>
            {description && (
              <Typography
                variant="body2"
                align="left"
                className={classes.description}
              >
                {description}
              </Typography>
            )}
            {children}
          </CardContent>
        )}
        <div className={classes.filler} />
        {footer && <CardActions>{footer}</CardActions>}
      </Card>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ContentCard);
