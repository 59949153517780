import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  dialog: {
    maxWidth: 500
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  content: {
    display: "flex",
    alignItems: "center"
  },
  imageContainer: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "auto 100%"
  },
  icon: {
    width: 80,
    minWidth: 80,
    height: 80,
    marginRight: 20
  },
  image: {
    width: 400,
    height: 400,
    marginTop: 40
  }
});

class ShowTipDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, isOpen, onClose, title, icon, description, image } =
      this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={classes.container}>
          <div className={classes.content}>
            <div
              className={classNames(classes.imageContainer, classes.icon)}
              style={{ backgroundImage: `url("${icon}")` }}
            />
            <Typography>{description}</Typography>
          </div>
          {image && (
            <div
              className={classNames(classes.imageContainer, classes.image)}
              style={{ backgroundImage: `url("${image}")` }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {t("label.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(ShowTipDialog));
