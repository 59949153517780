import React, { PropsWithChildren } from "react";

import { useTranslation } from "react-i18next";

import EnumSelector from "../common/EnumSelector";
import VatRate from "../../../models/menu/VatRate";
import EnumSelectorProps from "../common/EnumSelectorProps";

type VatRateSelectorProps = {
  vatRate: VatRate;
  onChange: (vatRate: VatRate) => void;
  isDisabled?: boolean;
} & EnumSelectorProps;

function VatRateSelector({
  vatRate,
  isDisabled,
  onChange,
  ...enumSelectorProps
}: PropsWithChildren<VatRateSelectorProps>) {
  const { t, i18n } = useTranslation("common");

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <EnumSelector
      {...enumSelectorProps}
      label={t("menu.vat-rate")}
      value={vatRate}
      disabled={isDisabled}
      onChange={handleChange}
      enumeration={VatRate}
      renderLabel={(vatRate) => t(`menu.vat-rate-${vatRate}`)}
    />
  );
}

export default VatRateSelector;
