import withStyles from "@mui/styles/withStyles";
import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { serviceOptionHelper } from "tap-io/helpers";
import SelectServiceOptions from "../service/SelectServiceOptions";

const styles = (theme) => ({});

class EditFeeServiceOptionCondition extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleServiceOptionChange = (serviceOption, isActive) => {
    const { condition, onChange } = this.props;

    const updatedCondition = condition.clone();
    if (isActive) {
      updatedCondition.addServiceOption(serviceOption.name);
    } else {
      updatedCondition.removeServiceOption(serviceOption.name);
    }
    onChange(updatedCondition);
  };

  render() {
    const { t, classes, isDisabled, bar, subscription, condition } = this.props;

    return (
      <div>
        <SelectServiceOptions
          isDisabled={isDisabled}
          allServiceOptions={serviceOptionHelper.createAllServiceOptions()}
          disabledServiceOptions={serviceOptionHelper.createInvertedServiceOptions(
            serviceOptionHelper.createServiceOptionsByName(
              subscription.capabilities.getAvailableServiceOptionsByName()
            )
          )}
          serviceOptions={serviceOptionHelper.createServiceOptionsByName(
            condition.serviceOptionNames
          )}
          onChange={this.handleServiceOptionChange}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditFeeServiceOptionCondition)
);
