import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { IconButton } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import withStyles from "@mui/styles/withStyles";

import SyncWithStarnetDialog from "./SyncWithStarnetDialog";
import { integrationService } from "../../services";
import config from "../../env";

const styles = (theme) => ({
  button: {
    fontSize: 12
  }
});

class SyncSubOrdersButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isSyncing: false,
      isSyncWithStarnetDialogOpen: false
    };
  }

  handleSyncSubOrders = (event) => {
    // Prevent Dialog 'clickthrough'
    event.stopPropagation();

    const { order, askForStarnetCardIfNeeded } = this.props;

    if (!order.subOrders.areAllSent && askForStarnetCardIfNeeded) {
      this.setState({ isSyncWithStarnetDialogOpen: true });
    } else {
      this.syncSubOrders();
    }
  };

  handleSyncWithStarnetConfirm = (cardNumber, cardPin) => {
    this.setState({ isSyncWithStarnetDialogOpen: false });

    this.syncSubOrders(cardNumber, cardPin);
  };

  handleSyncWithStarnetCancel = () => {
    this.setState({ isSyncWithStarnetDialogOpen: false });
  };

  syncSubOrders = async (cardNumber, cardPin) => {
    const { t, auth, bar, order } = this.props;

    if (!auth || !auth.user || !auth.user.uid) {
      return toast.error(
        `${t("label.error")}: ${t("error.no-valid-user-found")}`
      );
    }

    //this.setState({ isSyncWithStarnetDialogOpen: true });

    const toastId = toast(t("order.syncing-suborders"), {
      autoClose: false
    });
    this.setState({ isSyncing: true });

    try {
      await integrationService.syncOrder(
        config.functions.integrationsApi,
        auth.user,
        bar.id,
        order.id,
        cardNumber,
        cardPin
      );

      toast.update(toastId, {
        render: t("order.suborders-synced"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isSyncing: false });
    } catch (error) {
      console.warn(error);

      this.setState({ isSyncing: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(
          error ? error.message : "error.unknown-error"
        )})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, auth, isDisabled } = this.props;
    const { isSyncing, isSyncWithStarnetDialogOpen } = this.state;

    return (
      <div className={classes.container}>
        <SyncWithStarnetDialog
          auth={auth}
          isOpen={isSyncWithStarnetDialogOpen}
          onConfirm={this.handleSyncWithStarnetConfirm}
          onCancel={this.handleSyncWithStarnetCancel}
        />
        <IconButton
          disabled={isDisabled || isSyncing}
          onClick={this.handleSyncSubOrders}
          size="small"
          className={classes.button}
        >
          <SyncIcon />
        </IconButton>
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(SyncSubOrdersButton)
);
