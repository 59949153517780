import Moment from "moment-timezone";

import QRCode from "qrcode";

import { callNativeMethod, hasNativeMethod } from "./index";

import config from "tap-io/client/env";
import { formatToTwoDecimals } from "tap-io/utils";
import { serviceHelper, utilsHelper } from "tap-io/helpers";

const PRINT_LINES_HANDLER_METHOD_NAME = "printLinesHandler";

const PRINTER_TYPE_MOBILE_58MM = "mobile_58mm";
const PRINTER_TYPE_FIXED_58MM = "fixed_58mm";
const PRINTER_TYPE_FIXED_80MM = "fixed_80mm";

const printers = [
  {
    id: PRINTER_TYPE_MOBILE_58MM,
    type: PRINTER_TYPE_MOBILE_58MM,
    label: "Mobiel (58mm)",
    conf: {
      blockFeedPoints: 30,
      feedLinesOnHead: 2,
      feedLinesOnTail: 3,
      maxLines: 30,
      qrSupport: true
    }
  },
  {
    id: PRINTER_TYPE_FIXED_58MM,
    type: PRINTER_TYPE_FIXED_58MM,
    label: "Vast (58mm)",
    conf: {
      blockFeedPoints: 60,
      feedLinesOnHead: 0,
      feedLinesOnTail: 0,
      cutPaper: true
    }
  },
  {
    id: PRINTER_TYPE_FIXED_80MM,
    type: PRINTER_TYPE_FIXED_80MM,
    label: "Vast (80mm)",
    conf: {
      blockFeedPoints: 60,
      feedLinesOnHead: 0,
      feedLinesOnTail: 0,
      cutPaper: true,
      printDensity: 384,
      fontDensity: 8,
      maxLines: 20
    }
  }
];

export const isPrinterAvailable = () => {
  return hasNativeMethod(PRINT_LINES_HANDLER_METHOD_NAME);
};

export const getTypes = () => {
  return printers.map((printer) => {
    return { id: printer.id, type: printer.type, label: printer.label };
  });
};

const getPrinter = (type) => {
  const foundPrinters = printers.filter((printer) => {
    return printer.type === type;
  });

  if (foundPrinters.length === 1) {
    return foundPrinters[0];
  }
};

export const getCurrentPrinter = () => {
  const type = getCurrentType();
  if (type) {
    return getPrinter(type);
  }
};

export const getCurrentType = () => {
  /*if (localStorage) {
    const type = localStorage.getItem("printerType");
    if (type) {
      return type;
    }
  }*/

  return PRINTER_TYPE_FIXED_80MM;
};

export const setCurrentType = (type) => {
  if (getPrinter(type) === undefined) {
    throw new Error("printerType is invalid");
  }

  if (localStorage) {
    localStorage.setItem("printerType", type);
  }
};

const getCurrentConf = () => {
  const currentPrinter = getCurrentPrinter();

  if (currentPrinter) {
    return currentPrinter.conf;
  }

  return {};
};

// Private, use printLines instead
// All print functions in the end boil down to this one
const printRawLines = (lines, conf, amountOfCopies) => {
  console.info("printRawLines", lines, conf, amountOfCopies);

  if (isPrinterAvailable()) {
    const parsedAmountOfCopies = parseAmountOfCopies(amountOfCopies);

    for (let copy = 0; copy < parsedAmountOfCopies; copy++) {
      callNativeMethod(PRINT_LINES_HANDLER_METHOD_NAME, { lines, conf });
    }
  }
};

// Private, use printLines instead
const printLinesInParts = (lines, conf, partSize, amountOfCopies) => {
  const parsedAmountOfCopies = parseAmountOfCopies(amountOfCopies);

  for (let copy = 0; copy < parsedAmountOfCopies; copy++) {
    for (let i = 0; i < lines.length; i += partSize) {
      const begin = i;
      const end = i + Math.min(partSize, lines.length - i);

      const partLines = lines.slice(begin, end);
      const partConf = {};

      // blockFeedPoints
      if (conf.blockFeedPoints) {
        partConf.blockFeedPoints = conf.blockFeedPoints;
      }

      // feedLinesOnHead
      if (begin === 0) {
        if (conf.feedLinesOnHead) {
          partConf.feedLinesOnHead = conf.feedLinesOnHead;
        }
      } else {
        partConf.feedLinesOnHead = 0;
      }

      // feedLinesOnTail
      if (end === lines.length) {
        if (conf.feedLinesOnTail) {
          partConf.feedLinesOnTail = conf.feedLinesOnTail;
        }
      } else {
        partConf.feedLinesOnTail = 0;
      }

      printRawLines(partLines, partConf);
    }
  }
};

// Use this function to print
function printLines(lines, conf, amountOfCopies) {
  if (conf.maxLines && lines.length > conf.maxLines) {
    printLinesInParts(lines, conf, conf.maxLines, amountOfCopies);
  } else {
    printRawLines(lines, conf, amountOfCopies);
  }
}

export const printTest = (translator, bar, assets, printLogo) => {
  try {
    console.info(bar, assets);

    const conf = getCurrentConf();
    const lines = [];

    lines.push(textLine(translator("printer.test-page"), "center", "normal"));

    if (conf.cutPaper) {
      lines.push(...spacingLines(2));
      lines.push(cutLine());
      // If the printer can cut paper, prepare header for next ticket
      lines.push(...headerLines(bar, assets, printLogo));
      lines.push(...spacingLines(1));
    } else {
      lines.push(...spacingLines(2));
    }

    printLines(lines, conf);
  } catch (error) {
    throw error;
  }
};

export const printCustomerReceipts = async (
  translator,
  bar,
  allBases,
  assets,
  order,
  orderUrl,
  printLogo,
  amountOfCopies
) => {
  if (allBases) {
    await Promise.all(
      allBases
        .filter((base) => order.containsItemsForBase(base))
        .map((base) =>
          printCustomerReceipt(
            translator,
            bar,
            base,
            allBases,
            assets,
            order,
            orderUrl,
            printLogo,
            amountOfCopies
          )
        )
    );
  } else {
    await printCustomerReceipt(
      translator,
      bar,
      undefined,
      allBases,
      assets,
      order,
      orderUrl,
      printLogo,
      amountOfCopies
    );
  }
};

async function printCustomerReceipt(
  translator,
  bar,
  base,
  allBases,
  assets,
  order,
  orderUrl,
  printLogo,
  amountOfCopies
) {
  try {
    if (!bar) {
      throw new Error("error.bar-is-not-defined");
    }
    if (!order) {
      throw new Error("error.order-is-not-defined");
    }

    const conf = getCurrentConf();

    const lines = [];

    lines.push(...preLines(conf, bar, assets, printLogo));

    lines.push(
      textLine(translator("order.this-is-a-customer-receipt"), "center", "bold")
    );

    lines.push(
      ...orderLines(
        translator,
        bar,
        base,
        allBases,
        order,
        bar.isAllowingOnlinePayments(),
        true,
        conf.printDensity,
        conf.fontDensity
      )
    );
    lines.push(...spacingLines(1));

    if (!order.isFree() && bar.params && bar.params.payconiqMerchantLink) {
      lines.push(
        ...(await payconiqLines(
          conf,
          translator,
          bar.params.payconiqMerchantLink
        ))
      );
      //lines.push(...spacingLines(1));
    }

    lines.push(...spacingLines(1));

    const orderContainsItemsThatWillBeScanned =
      order.containsItemsThatWillBeScanned(allBases);

    lines.push(
      textLine(
        translator(
          orderContainsItemsThatWillBeScanned
            ? "order.have-the-qr-code-below-scanned-at-the-base-to-receive-your-order"
            : "order.follow-the-status-of-your-order-by-scanning-the-qr-code-below"
        ),
        "center",
        "normal"
      )
    );
    lines.push(...spacingLines(1));
    lines.push(
      ...(await urlLines(
        conf,
        translator,
        orderUrl,
        !orderContainsItemsThatWillBeScanned,
        !orderContainsItemsThatWillBeScanned
      ))
    );
    lines.push(...spacingLines(1));

    lines.push(...promoLines(translator));
    lines.push(...spacingLines(1));

    lines.push(
      textLine(translator("order.this-is-a-customer-receipt"), "center", "bold")
    );
    lines.push(...spacingLines(1));

    if (order.confirmationCode) {
      lines.push(
        textLine(
          `${translator(
            "order.confirmation-code"
          )}: ${order.confirmationCode.toUpperCase()}`,
          "center",
          "bold"
        )
      );
    }

    lines.push(...postLines(conf, bar, assets, printLogo, order.id));

    printLines(lines, conf, amountOfCopies);
  } catch (error) {
    throw error;
  }
}

export const printReceipt = async (
  translator,
  bar,
  base,
  assets,
  order,
  printLogo,
  printCustomerFields,
  amountOfCopies
) => {
  try {
    if (!bar) {
      throw new Error("error.bar-is-not-defined");
    }
    if (!order) {
      throw new Error("error.order-is-not-defined");
    }

    const conf = getCurrentConf();

    const lines = [];

    lines.push(...preLines(conf, bar, assets, printLogo));

    lines.push(
      ...orderLines(
        translator,
        bar,
        base,
        undefined,
        order,
        bar.isAllowingOnlinePayments(),
        printCustomerFields,
        conf.printDensity,
        conf.fontDensity
      )
    );
    lines.push(...spacingLines(1));

    if (
      !order.isFree() &&
      !order.isPaid() &&
      bar.params &&
      bar.params.payconiqMerchantLink
    ) {
      lines.push(
        ...(await payconiqLines(
          conf,
          translator,
          bar.params.payconiqMerchantLink
        ))
      );
      //lines.push(...spacingLines(1));
    }

    lines.push(...promoLines(translator));
    lines.push(...spacingLines(1));

    if (order.confirmationCode) {
      lines.push(
        textLine(
          `${translator(
            "order.confirmation-code"
          )}: ${order.confirmationCode.toUpperCase()}`,
          "center",
          "bold"
        )
      );
    }

    lines.push(...postLines(conf, bar, assets, printLogo, order.id));

    printLines(lines, conf, amountOfCopies);
  } catch (error) {
    throw error;
  }
};

export const printVoucher = async (
  translator,
  bar,
  assets,
  voucher,
  voucherPin,
  voucherUrl,
  printLogo,
  amountOfCopies
) => {
  try {
    if (!bar) {
      throw new Error("error.bar-is-not-defined");
    }
    if (!voucher) {
      throw new Error("error.voucher-is-not-defined");
    }

    const conf = getCurrentConf();
    //console.info(conf);

    const lines = [];

    lines.push(...preLines(conf, bar, assets, printLogo));

    lines.push(
      ...(await voucherLines(
        conf,
        translator,
        voucher,
        voucherPin,
        voucherUrl,
        conf.printDensity,
        conf.fontDensity
      ))
    );

    lines.push(...spacingLines(1));
    lines.push(...promoLines(translator));

    lines.push(...spacingLines(1));
    lines.push(...postLines(conf, bar, assets, printLogo));

    printLines(lines, conf, amountOfCopies);
  } catch (error) {
    throw error;
  }
};

function parseAmountOfCopies(amountOfCopies) {
  if (amountOfCopies) {
    const parsedAmountOfCopies = parseInt(amountOfCopies);
    if (!isNaN(parsedAmountOfCopies) && parsedAmountOfCopies > 0) {
      return parsedAmountOfCopies;
    }
  }

  return 1;
}

function preLines(conf, bar, assets, printLogo) {
  const lines = [];

  // If the printer can cut paper, header is printed last for next ticket
  // Otherwise, the header is printed first
  if (!conf.cutPaper) {
    lines.push(...headerLines(bar, assets, printLogo));
    lines.push(...spacingLines(1));
  }

  return lines;
}

function postLines(conf, bar, assets, printLogo, reference) {
  const lines = [...footerLines(bar.id, reference)];

  if (conf.cutPaper) {
    lines.push(...spacingLines(2));
    lines.push(cutLine());
    // If the printer can cut paper, prepare header for next ticket
    lines.push(...headerLines(bar, assets, printLogo));
    lines.push(...spacingLines(1));
  } else {
    lines.push(...spacingLines(2));
  }

  return lines;
}

function headerLines(bar, assets, printLogo) {
  const lines = [];

  if (
    printLogo &&
    assets &&
    assets.logoImageBase64 &&
    assets.logoImageBase64.content
  ) {
    lines.push(
      {
        type: "image",
        data: {
          content: assets.logoImageBase64.content,
          alignment: "center"
        }
      },
      ...spacingLines(1)
    );
  }

  return [...lines, textLine(bar.name, "center", "bold")];
}

function fieldsLines(translator, fields) {
  const lines = [];

  fields.forEach((field) => {
    if (field) {
      // Name
      const fieldName =
        field.name === undefined
          ? `(${translator("label.unknown")})`
          : field.name;

      // Value
      let fieldContent = `(${translator("label.empty")})`;
      if (field.value !== undefined) {
        switch (field.type) {
          case "date":
            fieldContent = Moment(field.value).format("DD/MM/YYYY");
            break;
          case "toggle":
            fieldContent = translator(
              field.value === true ? "label.yes" : "label.no"
            );
            break;
          case "select":
            if (field.label !== "") {
              fieldContent = field.label;
            }
            break;
          case "email":
            if (field.value) {
              fieldContent = utilsHelper.obfuscateEmail(field.value);
            }
            break;
          default:
            if (field.value !== "") {
              fieldContent = field.value;
            }
            break;
        }
      }

      lines.push(textLine(fieldName, "left", "bold"));
      lines.push(textLine(fieldContent, "left", "normal"));
    }
  });

  return lines;
}

function orderLines(
  translator,
  bar,
  base,
  allBases,
  order,
  areOnlinePaymentsAllowed,
  printCustomerFields,
  printDensity,
  fontDensity
) {
  const lines = [];

  // Sequence number
  if (base) {
    const sequenceNumber = order.fulfilment.getSequenceNumber(base.id);

    if (sequenceNumber) {
      lines.push(
        titleLine(`${translator("order.sequence-number")} ${sequenceNumber}`)
      );
      lines.push(...spacingLines(1));
    }
  }

  // Delivery Method
  lines.push(dividingLine(printDensity, fontDensity));
  lines.push(
    titleLine(
      base
        ? translator(
            order.isDeliveryMethodPickup
              ? "delivery.pick-up-at"
              : "delivery.served-by"
          ) + ` ${base.name}`
        : translator(
            order.isDeliveryMethodPickup ? "delivery.pickup" : "delivery.serve"
          )
    )
  );
  if (order.isDeliveryMethodPickup) {
    lines.push(...spacingLines(1));
    lines.push(
      titleLine(
        `${translator(`color.${order.getDeliveryColor(base)}`)} | ${
          order.delivery.code
        }`
      )
    );
  }
  lines.push(dividingLine(printDensity, fontDensity));
  lines.push(...spacingLines(2));

  // Name
  lines.push(titleLine(order.name));
  lines.push(...spacingLines(1));

  if (printCustomerFields && order.fields) {
    lines.push(...fieldsLines(translator, order.fields));
    lines.push(...spacingLines(2));
  }

  // Note
  if (order.note) {
    lines.push(
      textLine(translator("order.note"), "center", "bold"),
      textLine(order.note, "center", "normal"),
      ...spacingLines(1)
    );
  }

  // Items
  if (!base && allBases) {
    // Print items separately per base
    const menuIdToBaseMap = {};
    const baseIdToBaseMap = {};
    const itemLinesPerGroup = {};

    order.getItems().forEach((item) => {
      if (menuIdToBaseMap[item.menuId] === undefined) {
        const foundBases = allBases.filter((_base) =>
          _base.hasMenuId(item.menuId)
        );

        if (foundBases.length === 1) {
          const foundBase = foundBases[0];
          menuIdToBaseMap[item.menuId] = foundBase;
          baseIdToBaseMap[foundBase.id] = foundBase;
        } else {
          menuIdToBaseMap[item.menuId] = null;
        }
      }

      const id = menuIdToBaseMap[item.menuId]
        ? menuIdToBaseMap[item.menuId].id
        : item.menuId;

      if (!itemLinesPerGroup[id]) {
        itemLinesPerGroup[id] = [];
      }

      itemLinesPerGroup[id].push(
        ...orderItemLines(order, item, printDensity, fontDensity)
      );
    });

    for (let id in itemLinesPerGroup) {
      lines.push(
        textLine(
          baseIdToBaseMap[id]
            ? baseIdToBaseMap[id].name
            : `(${translator("label.unknown")})`,
          "left",
          "bold"
        ),
        ...itemLinesPerGroup[id],
        ...spacingLines(1)
      );
    }
  } else {
    // Print items (default)
    lines.push(
      ...order
        .getItems(base)
        .map((item) => orderItemLines(order, item, printDensity, fontDensity))
        .reduce((itemLines, linesForItem) => itemLines.concat(linesForItem), [])
    );
  }

  if (
    (!order.containsItemsFromMultipleMenus() || !base) &&
    order.fees.length > 0
  ) {
    lines.push(...spacingLines(1));
    lines.push(
      ...order.fees.map((fee) =>
        orderFeeLine(order, fee, printDensity, fontDensity)
      )
    );
  }

  // Tip
  if (order.hasTip()) {
    lines.push(...spacingLines(1));

    lines.push(
      leftRightTextLine(
        translator("order.tip"),
        `${order.getFormattedTip()} ${order.currency}`,
        printDensity,
        fontDensity
      )
    );
  }

  // Total
  lines.push(dividingLine(printDensity, fontDensity));
  if (order.containsItemsFromMultipleMenus()) {
    lines.push(
      orderTotalLine(
        translator,
        translator("order.subtotal"),
        order.getTotalAmount(base),
        order.getFormattedTotal(base),
        order.currency,
        printDensity,
        fontDensity
      )
    );
    lines.push(...spacingLines(1));
  }
  lines.push(
    orderTotalLine(
      translator,
      translator("order.total"),
      order.getTotalAmount(),
      order.getFormattedTotal(),
      order.currency,
      printDensity,
      fontDensity
    )
  );

  // Multiple menus
  if (bar.isUsingBases() && order.containsItemsFromMultipleMenus()) {
    lines.push(
      ...spacingLines(1),
      textLine(
        `${translator(
          "order.part-of-order-prepared-at-other-location"
        )}. ${translator("order.total-price-of-this-order-is-x", {
          totalPrice: `${order.getFormattedTotal()} ${order.currency}`
        })}`,
        "left",
        "normal"
      )
    );
  }

  // Payment
  lines.push(...spacingLines());
  lines.push(...orderPaymentLines(translator, order, areOnlinePaymentsAllowed));
  lines.push(...orderVoucherLines(translator, order));

  return lines;
}

function orderItemLines(order, item, printDensity, fontDensity) {
  const totalForItem = formatToTwoDecimals(item.amount * item.price);

  return [
    plainTextLine(`${item.amount} x ${item.name}`),
    leftRightTextLine(
      `  ${item.amount} x ${formatToTwoDecimals(item.price)} ${order.currency}`,
      `${totalForItem} ${order.currency}`,
      printDensity,
      fontDensity
    )
  ];
}

function orderFeeLine(order, fee, printDensity, fontDensity) {
  return leftRightTextLine(
    fee.name,
    `${formatToTwoDecimals(fee.value)} ${order.currency}`,
    printDensity,
    fontDensity
  );
}

function orderTotalLine(
  translator,
  totalLabel,
  totalAmount,
  totalPrice,
  totalCurrency,
  printDensity,
  fontDensity
) {
  return leftRightTextLine(
    `${totalLabel} (${totalAmount} ${translator(
      totalAmount === 1 ? "order.item" : "order.items"
    )})`,
    `${totalPrice} ${totalCurrency}`,
    printDensity,
    fontDensity
  );
}

function orderPaymentLines(translator, order, areOnlinePaymentsAllowed) {
  if (order.isPaid()) {
    const paymentDate = Moment(order.payment.timestamp);

    return [
      textLine(
        `${translator("payment.paid")} ${
          order.payment.method ? `met ${order.payment.method}` : ""
        } ${translator("label.on")} ${paymentDate.format(
          "DD/MM/YYYY"
        )} ${translator("label.at")} ${paymentDate.format("HH:mm:ss")}${
          order.payment.reference ? ` (${order.payment.reference})` : ""
        }`,
        "left",
        "normal"
      )
    ];
  }

  if (order.isPaymentProcessing()) {
    return [
      textLine(translator("payment.payment-is-processing"), "left", "normal")
    ];
  }

  if (order.isPartiallyPaid()) {
    return [
      textLine(translator("payment.order-partially-paid"), "left", "normal"),
      textLine(
        translator("payment.the-remaining-total-is-x", {
          totalToBePaid: `${order.getTotalToBePaid()} ${order.currency}`
        }),
        "left",
        "normal"
      )
    ];
  }

  if (areOnlinePaymentsAllowed && !order.isFree()) {
    return [textLine(translator("payment.not-paid"), "left", "normal")];
  }

  return [];
}

function orderVoucherLines(translator, order) {
  if (order.getTotalAlreadyPaidWithVouchers()) {
    return [
      textLine(
        translator("payment.amount-x-paid-with-vouchers", {
          totalAlreadyPaidWithVouchers: `${order.getFormattedTotalAlreadyPaidWithVouchers()} ${
            order.currency
          }`
        }),
        "left",
        "normal"
      )
    ];
  }

  return [];
}

async function voucherLines(
  conf,
  translator,
  voucher,
  voucherPin,
  voucherUrl,
  printDensity,
  fontDensity
) {
  const lines = [];

  if (voucher.label) {
    lines.push(
      titleLine(voucher.label),
      ...spacingLines(1),
      textLine(translator("voucher.voucher-worth"), "center", "normal"),
      textLine(`${voucher.originalValueToString(translator)}`, "center", "bold")
    );
  } else {
    lines.push(
      textLine(translator("voucher.voucher-worth"), "center", "normal"),
      ...spacingLines(1),
      titleLine(`${voucher.originalValueToString(translator)}`)
    );
  }

  lines.push(
    ...spacingLines(1),
    textLine(
      `${translator("voucher.voucher-valid-until")} ${Moment(
        voucher.validUntil
      ).format("DD/MM/YYYY")}`,
      "center",
      "normal"
    ),
    ...spacingLines(1),
    textLine(translator("label.scan-qr-code-below"), "center", "normal"),
    ...spacingLines(1),
    ...(await urlLines(conf, translator, voucherUrl, false, true)),
    textLine(
      translator("voucher.enter-code-and-pin-to-place-order-1", {
        voucherId: voucher.id
      }),
      "center",
      "normal"
    ),
    textLine(
      translator("voucher.enter-code-and-pin-to-place-order-2", { voucherPin }),
      "center",
      "normal"
    ),
    textLine(
      translator("voucher.enter-code-and-pin-to-place-order-3"),
      "center",
      "normal"
    )
  );

  return lines;
}

async function payconiqLines(conf, translator, payconiqMerchantLink) {
  return [
    textLine(translator("payment.pay-now-using-payconiq"), "center", "normal"),
    textLine(translator("label.scan-qr-code-below"), "center", "normal"),
    ...spacingLines(1),
    ...(await urlLines(conf, translator, payconiqMerchantLink, false, true))
  ];
}

async function urlLines(
  conf,
  translator,
  url,
  includeUrlAsText,
  includeHelperText
) {
  const lines = [];

  if (conf.qrSupport) {
    lines.push({
      type: "qr",
      data: {
        content: url
      }
    });
  } else {
    const qrCodeAsBase64String = await QRCode.toDataURL(url, {
      type: "image/png",
      margin: 0,
      scale: 8
    });
    const { header, content } =
      serviceHelper.parseBase64String(qrCodeAsBase64String);

    lines.push({
      type: "image",
      data: {
        content: content,
        alignment: "center"
      }
    });
  }

  if (includeHelperText) {
    lines.push(
      textLine(
        translator("label.no-qr-scanner-go-to-wdj-nu"),
        "center",
        "normal"
      )
    );
  }

  if (includeUrlAsText) {
    lines.push(...spacingLines(1), textLine(url, "center", "normal"));
  }

  return lines;
}

function promoLines(translator) {
  return [
    textLine(
      translator("promo.x-on-your-event", { productName: config.product.name }),
      "center",
      "normal"
    ),
    textLine(
      translator("promo.go-to-x-and-create-an-account-1", {
        url: config.links.promo.replace(/https?\:\/\//, "")
      }),
      "center",
      "normal"
    ),
    textLine(
      translator("promo.go-to-x-and-create-an-account-2"),
      "center",
      "normal"
    )
  ];
}

function footerLines(barId, reference) {
  const timestamp = Moment()
    .tz("Europe/Brussels")
    .format("DD/MM/YYYY HH:mm:ss");

  return [
    textLine(timestamp, "center", "normal"),
    textLine(barId, "center", "normal"),
    textLine(reference, "center", "normal")
  ];
}

function titleLine(title) {
  return {
    type: "title",
    data: {
      content: title
    }
  };
}

function textLine(text, alignment, style) {
  const line = {
    type: "text",
    data: {
      content: text
    }
  };

  if (alignment !== undefined) {
    line.data.alignment = alignment;
  }
  if (style !== undefined) {
    line.data.style = style;
  }

  return line;
}

function plainTextLine(text) {
  return {
    type: "plainText",
    data: {
      content: text
    }
  };
}

function leftRightTextLine(leftText, rightText, printDensity, fontDensity) {
  const line = {
    type: "kv",
    data: {
      k: leftText,
      v: rightText
    }
  };

  if (printDensity !== undefined && fontDensity !== undefined) {
    line.data.printDensity = printDensity;
    line.data.fontDensity = fontDensity;
  }

  return line;
}

function dividingLine(printDensity, fontDensity) {
  const line = {
    type: "dividing"
  };

  if (printDensity !== undefined && fontDensity !== undefined) {
    line.data = {
      printDensity,
      fontDensity
    };
  }

  return line;
}

function spacingLines(count) {
  const linesToPrint = count === undefined ? 1 : count;

  return [
    {
      type: "blank",
      data: {
        lines: linesToPrint
      }
    }
  ];
}

function cutLine() {
  return textLine("\x1d\x56\x41\x00", "left", "normal");
}
