import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import withAuthorization from "../auth/withAuthorization";

const styles = (theme) => ({
  accentuate: {
    fontWeight: "bold",
    color: theme.palette.error.main
  }
});

class PlanExpiredDescription extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, bar, subscription } = this.props;

    return (
      <div>
        {subscription && subscription.isExpired() && (
          <Typography>
            <span className={classes.accentuate}>
              {t("subscription.plan-x-is-expired", {
                planName: t(`subscription.plan-${subscription.planName}`)
              })}
            </span>
            <br />
            <br />
            {t("subscription.plan-expired-description")}
          </Typography>
        )}
      </div>
    );
  }
}

export default withAuthorization()(
  withStyles(styles)(withTranslation("common")(PlanExpiredDescription))
);
