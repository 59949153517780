import React, { PropsWithChildren, useEffect, useState } from "react";

import SetIntegrationDialog from "./SetIntegrationDialog";
import { withTranslation } from "react-i18next";

import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Switch,
  TextField,
  useTheme
} from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import CheckIcon from "@mui/icons-material/Check";
import PauseIcon from "@mui/icons-material/Pause";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import config from "tap-io/client/env";
import { integrationService } from "tap-io/client/services";
import Bar from "tap-io/models/bar/Bar";
import AbiIntegration from "tap-io/models/integration/AbiIntegration";

import withAuthorization from "../auth/withAuthorization";
import { toast } from "react-toastify";

type SetAbiIntegrationDialogProps = {
  t: any;
  auth: any;
  isOpen: boolean;
  onClose: () => void;
  bar: Bar;
  integration?: AbiIntegration;
};

function SetAbiIntegrationDialog({
  t,
  auth,
  isOpen,
  onClose,
  bar,
  integration
}: PropsWithChildren<SetAbiIntegrationDialogProps>) {
  const theme = useTheme();

  const [isDisabled, setIsDisabled] = useState(false);
  const [isPasswordRevealed, setIsPasswordRevealed] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [pocId, setPocId] = useState("");
  const [currentEventId, setCurrentEventId] = useState("");
  const [events, setEvents] = useState<
    | {
        id: string;
        name: string;
        from: string;
        to: string;
      }[]
    | null
  >(null);

  useEffect(() => {
    setIsActive(integration ? integration.isActive : false);
    setUsername(integration ? integration.credentials.username : "");
    setPassword(integration ? integration.credentials.password : "");
    setPocId(integration ? integration.credentials.pocId : "");
    setCurrentEventId(
      integration && integration.currentEvent ? integration.currentEvent.id : ""
    );
  }, [integration, isOpen]);

  useEffect(() => {
    handleRefreshEvents();
  }, [isOpen, bar?.id, integration?.canEventsBeFetched]);

  const handleRefreshEvents = async () => {
    if (
      isOpen &&
      integration?.canEventsBeFetched &&
      auth?.user?.uid &&
      bar?.id
    ) {
      try {
        setIsDisabled(true);

        const events = await integrationService.getAbiEvents(
          config.functions.integrationsApi,
          auth.user,
          bar.id
        );

        setEvents(events);
        setIsDisabled(false);
      } catch (error) {
        console.warn(error);
        setIsDisabled(false);

        toast.error(
          `${t("label.something-went-wrong")} (${t(
            error ? error.message : "error.unknown-error"
          )})`
        );
      }
    } else {
      setEvents(null);
    }
  };

  const handleRevealPassword = (event) => {
    setIsPasswordRevealed(true);
  };

  const handleObfuscatePassword = (event) => {
    setIsPasswordRevealed(false);
  };

  const handleVerifyCredentials = async () => {
    if (auth?.user?.uid && bar?.id && username && password && pocId) {
      const toastId = toast(t("integration.verifying-abi"), {
        autoClose: false
      });

      setIsDisabled(true);

      try {
        await integrationService.verifyAbi(
          config.functions.integrationsApi,
          auth.user,
          bar.id,
          username,
          password,
          pocId
        );

        setIsDisabled(false);

        toast.update(toastId, {
          render: t("integration.abi-verified"),
          type: toast.TYPE.INFO,
          autoClose: 3000
        });
      } catch (error) {
        console.warn(error);
        setIsDisabled(false);

        toast.update(toastId, {
          render: `${t("label.something-went-wrong")} (${t(
            error ? error.message : "error.unknown-error"
          )})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
      }
    }
  };

  const handleAbiIntegrationSave = async () => {
    const toastId = toast(t("integration.saving-integration"), {
      autoClose: false
    });

    try {
      setIsDisabled(true);

      if (integration) {
        const prevCurrentEventId = integration.currentEvent?.id;

        integration.isActive = isActive;

        integration.credentials = {
          username,
          password,
          pocId
        };

        const doSync = currentEventId !== integration.currentEvent?.id;

        integration.currentEventId = currentEventId;
        await integrationService.updateIntegration(integration);

        if (doSync && bar.id) {
          const syncedIntegration =
            await integrationService.onAbiIntegrationSynced(bar.id);
          await integrationService.syncAbi(
            config.functions.integrationsApi,
            auth.user,
            bar.id
          );

          // Automatically activate new correctly configured integration
          if (!prevCurrentEventId && !syncedIntegration.isActive) {
            syncedIntegration.isActive = true;
            await integrationService.updateIntegration(syncedIntegration);
          }
        }

        setIsDisabled(false);

        onClose();
      } else {
        if (!bar.id) {
          throw new Error("error.bar-id-is-not-defined");
        }

        await integrationService.setIntegration(
          integrationService.createAbiIntegration(bar.id, {
            isActive,
            credentials: {
              username,
              password,
              pocId
            },
            currentEvent: {
              id: currentEventId
            }
          })
        );
      }

      toast.update(toastId, {
        render: t("integration.integration-updated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);
      setIsDisabled(false);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(
          error ? error.message : "error.unknown-error"
        )})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  const handleSync = async () => {
    if (auth?.user?.uid && bar?.id && integration?.isConfigured) {
      const toastId = toast(t("integration.syncing-abi"), {
        autoClose: false
      });

      setIsDisabled(true);

      try {
        if (currentEventId !== integration.currentEvent?.id) {
          integration.currentEventId = currentEventId;
          await integrationService.updateIntegration(integration);
          await integrationService.onAbiIntegrationSynced(bar.id);
        }

        await integrationService.syncAbi(
          config.functions.integrationsApi,
          auth.user,
          bar.id
        );

        setIsDisabled(false);

        toast.update(toastId, {
          render: t("integration.abi-synced"),
          type: toast.TYPE.INFO,
          autoClose: 3000
        });
      } catch (error) {
        console.warn(error);
        setIsDisabled(false);

        toast.update(toastId, {
          render: `${t("label.something-went-wrong")} (${t(
            error ? error.message : "error.unknown-error"
          )})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
      }
    }
  };

  return (
    <SetIntegrationDialog
      title={t("integration.set-abi-integration")}
      isOpen={isOpen}
      isDisabled={isDisabled}
      onSave={handleAbiIntegrationSave}
      onCancel={onClose}
      integration={integration}
    >
      <List>
        <ListItem disableGutters>
          <ListItemText primary={t("integration.integration-is-active")} />
          <Switch
            disabled={isDisabled || !integration?.isConfigured}
            onChange={(e) => setIsActive(e.target.checked)}
            checked={isActive}
          />
        </ListItem>
        <ListItem disableGutters>
          <TextField
            disabled={isDisabled}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            label={t("integration.abi-username")}
            variant="standard"
            margin="dense"
            fullWidth
          />
        </ListItem>
        <ListItem disableGutters>
          <TextField
            disabled={isDisabled}
            type={isPasswordRevealed ? undefined : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onFocus={handleRevealPassword}
            onBlur={handleObfuscatePassword}
            label={t("integration.abi-password")}
            variant="standard"
            margin="dense"
            fullWidth
          />
        </ListItem>
        <ListItem disableGutters>
          <TextField
            disabled={isDisabled}
            value={pocId}
            onChange={(e) => setPocId(e.target.value)}
            label={t("integration.abi-poc-id")}
            variant="standard"
            margin="dense"
            fullWidth
          />
        </ListItem>
        {integration?.pos?.id ? (
          <ListItem disableGutters>
            <TextField
              disabled={isDisabled || !events}
              select
              error={!currentEventId}
              value={events ? currentEventId : ""}
              onChange={(e) => setCurrentEventId(e.target.value)}
              variant="standard"
              label={t("integration.current-abi-event")}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {events ? (
                      integration?.currentEvent?.syncedAt ? (
                        <CheckIcon />
                      ) : currentEventId ? (
                        <PauseIcon />
                      ) : (
                        <ErrorOutlineIcon color="error" />
                      )
                    ) : (
                      <CircularProgress size={20} sx={{ marginLeft: 2 }} />
                    )}
                  </InputAdornment>
                )
              }}
            >
              <MenuItem value="">{t("integration.select-abi-event")}</MenuItem>
              {events?.map((event) => (
                <MenuItem key={event.id} value={event.id}>
                  {event.name}
                </MenuItem>
              ))}
            </TextField>
            <IconButton
              disabled={isDisabled || !currentEventId}
              onClick={handleSync}
              size="large"
              sx={{
                marginRight: -theme.spacing(2),
                marginBottom: -theme.spacing(2)
              }}
            >
              <SyncIcon />
            </IconButton>
          </ListItem>
        ) : (
          integration && (
            <ListItem disableGutters>
              <Button
                fullWidth
                disabled={isDisabled}
                variant="contained"
                color="primary"
                onClick={handleVerifyCredentials}
              >
                {t("integration.verify-abi")}
              </Button>
            </ListItem>
          )
        )}
      </List>
    </SetIntegrationDialog>
  );
}

export default withAuthorization()(
  withTranslation("common")(SetAbiIntegrationDialog)
);
