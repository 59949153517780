import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@mui/material";
import TollIcon from "@mui/icons-material/Toll";
import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";
import { authHelper, utilsHelper } from "tap-io/helpers";

import withAuthorization from "../auth/withAuthorization";
import VerifyBarBusinessDataDialog from "../data/VerifyBarBusinessDataDialog";

const styles = (theme) => ({
  pricePerConsumptionItem: {
    paddingTop: 0,
    paddingBottom: 16
  }
});

class TopUpWithMollie extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false,
      pricingOptionToBePurchased: null
    };
  }

  handlePricingOptionClick = (pricingOption) => (event) => {
    this.setState({
      isDisabled: true,
      pricingOptionToBePurchased: pricingOption
    });
  };

  handleAcceptBarBusinessDataAndAllowCreditDebt = async () => {
    const { t, auth, bar } = this.props;
    const { pricingOptionToBePurchased } = this.state;

    this.setState({ pricingOptionToBePurchased: null });

    if (auth && auth.user) {
      const toastId = toast(t("credits.redirecting-to-payment-page"), {
        autoClose: false
      });

      try {
        const authorizedLink = await authHelper.generateAuthorizedLink(
          auth.user,
          `${config.functions.api}/crew/bar/purchase`,
          {
            barId: bar.id,
            pricingId: pricingOptionToBePurchased.id
          }
        );
        window.open(authorizedLink, "_self");
      } catch (error) {
        console.warn(error);

        this.setState({ isDisabled: false });
        toast.update(toastId, {
          render: `${t("label.something-went-wrong")} (${
            error ? error.message : t("label.unknown-error")
          })`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
      }
    } else {
      toast.error(t("error.no-authorized-user-found"));
    }
  };

  handleRejectBarBusinessData = () => {
    this.setState({ isDisabled: false, pricingOptionToBePurchased: null });
  };

  render() {
    const { classes, t, bar, balance, pricing } = this.props;
    const { isDisabled, pricingOptionToBePurchased } = this.state;

    return (
      <div>
        <VerifyBarBusinessDataDialog
          isOpen={pricingOptionToBePurchased !== null}
          onAccept={this.handleAcceptBarBusinessDataAndAllowCreditDebt}
          onReject={this.handleRejectBarBusinessData}
          bar={bar}
          balance={balance}
        />
        <List>
          {pricing && pricing.length > 0 ? (
            pricing.map((option) => (
              <div key={option.id}>
                <Button
                  startIcon={<TollIcon />}
                  disabled={isDisabled}
                  onClick={this.handlePricingOptionClick(option)}
                >
                  {`${option.creditCount} ${t("credits.credits")} (€${
                    option.totalPrice
                  }, ${t("credits.vat-included")})`}
                </Button>
                <ListItem className={classes.pricePerConsumptionItem}>
                  <ListItemText
                    secondary={`€${utilsHelper.formatToXDecimals(
                      option.totalPrice / option.creditCount,
                      4
                    )} ${t("credits.per-consumption")}`}
                  />
                </ListItem>
              </div>
            ))
          ) : (
            <Typography>{t("credits.no-pricing-available")}</Typography>
          )}
        </List>
      </div>
    );
  }
}

export default withAuthorization()(
  withStyles(styles, { withTheme: true })(
    withTranslation("common")(TopUpWithMollie)
  )
);
