import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { zoneHelper } from "tap-io/helpers";

const styles = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  alert: {
    color: theme.palette.error.main
  }
});

class EditLayoutNodeCodeDialog extends Component {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (layoutNode) => {
    return {
      isDisabled: false,
      code: layoutNode ? layoutNode.code : ""
    };
  };

  componentDidUpdate(prevProps, prevState) {
    const { t, isOpen, layoutNode } = this.props;

    if (isOpen && !prevProps.isOpen) {
      this.setState(this.initialState(layoutNode));
    }
  }

  handleCodeChange = (event) => {
    this.setState({ code: zoneHelper.parseLayoutNodeCode(event.target.value) });
  };

  handleUpdateLayoutNodeCode = () => {
    const { t, layoutNode, onUpdate, onClose } = this.props;
    const { code } = this.state;

    try {
      layoutNode.code = code;
      onUpdate(layoutNode);

      onClose();
    } catch (error) {
      toast.error(`${t("label.something-went-wrong")} (${error.message})`);
    }
  };

  render() {
    const { classes, t, isOpen, onClose } = this.props;
    const { isDisabled, code } = this.state;

    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>{t("zone.edit-layout-node-code")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("zone.edit-layout-node-code-below")}
            <br />
            <br />
            <strong className={classes.alert}>
              {t(
                "zone.warning-change-of-node-code-will-break-already-printed-qr-codes"
              )}
            </strong>
          </DialogContentText>
          <TextField
            variant="standard"
            margin="dense"
            placeholder={t("zone.node-code")}
            fullWidth
            disabled={isDisabled}
            value={code}
            onChange={this.handleCodeChange}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={isDisabled} onClick={onClose} color="secondary">
            {t("label.cancel")}
          </Button>
          <Button
            disabled={isDisabled}
            onClick={this.handleUpdateLayoutNodeCode}
            color="primary"
          >
            {t("label.update")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(EditLayoutNodeCodeDialog)
);
