import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import Moment from "moment";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import withAuthorization from "../auth/withAuthorization";

const styles = (theme) => ({});

class PlanActiveDescription extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, bar, subscription } = this.props;

    return (
      <div>
        {subscription && subscription.isActive() && (
          <Typography>
            {t("subscription.your-current-plan-description", {
              planName: t(`subscription.plan-${subscription.planName}`)
            })}
            <br />
            <br />
            {subscription.validUntil && (
              <strong className={classes.validUntil}>
                {t("subscription.your-subscription-is-valid-until", {
                  dateTime: Moment(subscription.validUntil).format("LLLL")
                })}
              </strong>
            )}
          </Typography>
        )}
      </div>
    );
  }
}

export default withAuthorization()(
  withStyles(styles)(withTranslation("common")(PlanActiveDescription))
);
