import React, { PropsWithChildren, ReactNode } from "react";

import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import PauseIcon from "@mui/icons-material/Pause";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

type IntegrationItemProps = {
  isDisabled: boolean;
  onClick: () => void;
  name: string;
  description: string;
  doesExist: boolean;
  isConfigured: boolean;
  isActive: boolean;
  quickAction: ReactNode;
};

function IntegrationItem({
  isDisabled,
  onClick,
  name,
  description,
  doesExist,
  isConfigured,
  isActive,
  quickAction
}: PropsWithChildren<IntegrationItemProps>) {
  return (
    <ListItem button disabled={isDisabled} onClick={onClick}>
      <ListItemIcon>
        {doesExist ? (
          isConfigured ? (
            isActive ? (
              <CheckIcon />
            ) : (
              <PauseIcon />
            )
          ) : (
            <EditIcon />
          )
        ) : (
          <AddIcon />
        )}
      </ListItemIcon>
      <ListItemText primary={name} secondary={description} />
      {quickAction && (
        <ListItemSecondaryAction>{quickAction}</ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

export default IntegrationItem;
