import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import HelpIcon from "@mui/icons-material/Help";
import withStyles from "@mui/styles/withStyles";

import { utilsHelper } from "tap-io/helpers";
import BarMenuItem from "./BarMenuItem";

const QUICKSTARTGUIDE_URL = "https://watdrinkje.be/qsg-wdj";

const styles = (theme) => ({});

class HelpMenuItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleOpenHelp = () => {
    const { onClicked } = this.props;

    utilsHelper.openLink(QUICKSTARTGUIDE_URL);

    if (onClicked) {
      onClicked();
    }
  };

  render() {
    const { t } = this.props;

    return <BarMenuItem icon={<HelpIcon />} label={t("label.need-help")} />;
  }
}

export default withStyles(styles)(withTranslation("common")(HelpMenuItem));
