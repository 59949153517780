import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  orderPrice: {
    width: 100
  },
  orderPriceWhenPaid: {
    color: "green"
  },
  orderPriceWhenNotPaidYet: {
    color: "red"
  }
});

class OrderTotalLabel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, bar, base, order } = this.props;

    return (
      <span
        className={classNames(
          classes.orderPrice,
          order.isPaid()
            ? classes.orderPriceWhenPaid
            : bar && bar.isAllowingOnlinePayments()
            ? order.isFree()
              ? undefined
              : classes.orderPriceWhenNotPaidYet
            : undefined
        )}
      >{`${order.getFormattedTotal(
        order.containsItemsFromMultipleMenus() ? base : undefined
      )} ${order.currency}`}</span>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(OrderTotalLabel));
