import RefreshIcon from "@mui/icons-material/Refresh";
import { Chip, Typography } from "@mui/material";
import { ReactElement } from "react";
import { Async } from "../../../components/common/useAsync";

export type FailedStatChipProps = {
  title?: string;
  icon?: ReactElement;
  async: Async<any>;
};

export function FailedStatChip(props: FailedStatChipProps) {
  const { title, icon, async } = props;

  return (
    <Chip
      icon={icon}
      onClick={async.load}
      color="error"
      label={
        <Typography sx={{ display: "flex" }} variant="button">
          {title} <RefreshIcon />
        </Typography>
      }
    />
  );
}
