import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { ChromePicker } from "react-color";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { barService } from "tap-io/client/services";
import theme from "tap-io/client/theme";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    margin: "0 16px"
  },
  colorContainer: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    margin: "20px 0",
    textAlign: "center"
  },
  colorPickerContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 10
  },
  colorPicker: {
    overflow: "hidden",
    borderRadius: "4px !important",
    boxShadow: "none !important",
    backgroundColor: `${theme.palette.background.default} !important`
  }
});

class EditColors extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      primaryColor: "",
      secondaryColor: ""
    };
  }

  componentDidMount() {
    const { bar } = this.props;

    this.setState({
      primaryColor: bar.getPrimaryColor(),
      secondaryColor: bar.getSecondaryColor()
    });
  }

  handlePrimaryColorChange = (color) => {
    if (this.saveTimeout) {
      clearTimeout(this.saveTimeout);
    }

    this.setState({ primaryColor: color.hex });
  };

  handleSecondaryColorChange = (color) => {
    if (this.saveTimeout) {
      clearTimeout(this.saveTimeout);
    }

    this.setState({ secondaryColor: color.hex });
  };

  requestSave = () => {
    if (this.saveTimeout) {
      clearTimeout(this.saveTimeout);
    }

    this.saveTimeout = setTimeout(() => {
      this.saveTimeout = undefined;
      this.handleSave();
    }, 1000);
  };

  handleSave = async () => {
    const { t, bar } = this.props;
    const { primaryColor, secondaryColor } = this.state;

    const toastId = toast(t("settings.updating-colors"), {
      autoClose: false
    });

    try {
      await barService.setBarColors(bar, primaryColor, secondaryColor);

      toast.update(toastId, {
        render: t("settings.colors-updated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t } = this.props;
    const { primaryColor, secondaryColor } = this.state;

    return (
      <div className={classes.container}>
        {theme.editableColors.indexOf("primary") >= 0 && (
          <div className={classes.colorContainer}>
            <Typography
              variant="button"
              color="primary"
              style={{ color: primaryColor }}
            >
              {t("settings.primary-color")}
            </Typography>
            <div className={classes.colorPickerContainer}>
              <ChromePicker
                className={classes.colorPicker}
                disableAlpha={true}
                color={primaryColor}
                onChange={this.handlePrimaryColorChange}
                onChangeComplete={this.requestSave}
              />
            </div>
          </div>
        )}
        {theme.editableColors.indexOf("secondary") >= 0 && (
          <div className={classes.colorContainer}>
            <Typography
              variant="button"
              color="secondary"
              style={{ color: secondaryColor }}
            >
              {t("settings.secondary-color")}
            </Typography>
            <div className={classes.colorPickerContainer}>
              <ChromePicker
                className={classes.colorPicker}
                disableAlpha={true}
                color={secondaryColor}
                onChange={this.handleSecondaryColorChange}
                onChangeComplete={this.requestSave}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditColors)
);
