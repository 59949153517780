import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";
import { feeService } from "tap-io/client/services";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import EnumSelector from "tap-io/client/components/common/EnumSelector";
import FeeType from "tap-io/models/fee/FeeType";

import EditFeeConditions from "./EditFeeConditions";

const styles = (theme) => ({
  dialog: {
    minWidth: 400
  },
  largeSpacing: {
    height: 25
  },
  smallSpacing: {
    height: 10
  }
});

class EditFeeDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (fee) => {
    return {
      isDisabled: false,
      type: fee?.type || FeeType.FLAT,
      conditions: fee?.conditions || [],
      isActive: fee?.isActive || true,
      name: fee?.name || "",
      value: fee?.value
        ? fee?.type === FeeType.VARIABLE
          ? fee?.value * 100
          : fee?.value
        : 0
    };
  };

  componentDidUpdate(prevProps, prevState) {
    const { isOpen, fee } = this.props;

    if (isOpen && !prevProps.isOpen) {
      this.setState(this.initialState(fee));
    }
  }

  handleTypeChange = (event) => {
    this.setState({ type: event.target.value }, this.checkValue);
  };

  handleConditionsChange = (conditions) => {
    this.setState({ conditions });
  };

  handleNameChange = (event) => {
    this.setState({ name: event.target.value });
  };

  handleValueChange = (event) => {
    const value = event.target.value.replace(/^0+(?=\d)/, ""); // Remove leading zeros
    this.setState({ value });
  };

  handleValueBlur = (event) => {
    this.checkValue();
  };

  checkValue = () => {
    const { type, value } = this.state;

    this.setState({
      value: type === FeeType.FLAT ? parseFloat(value) : parseInt(value)
    });
  };

  handleUpdateFee = async () => {
    const { t, fee, onClose } = this.props;
    const { type, conditions, isActive, name, value } = this.state;

    const toastId = toast(t("fee.updating-fee"), { autoClose: false });
    this.setState({ isDisabled: true });

    try {
      const parsedName = name.trim();
      const parsedValue = parseFloat(
        type === FeeType.VARIABLE ? value / 100 : value
      );

      fee.type = type;
      fee.conditions = conditions;
      fee.isActive = isActive;
      fee.name = parsedName;
      fee.value = parsedValue;

      await feeService.updateFee(fee);
      toast.update(toastId, {
        render: t("fee.fee-updated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false }, onClose);
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(
          error ? error.message : "error.unknown-error"
        )})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, isOpen, onClose, bar, subscription } = this.props;
    const { isDisabled, type, conditions, name, value } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle>{t("fee.edit-fee")}</DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText>{t("fee.edit-fee-below")}</DialogContentText>
          <TextField
            variant="standard"
            margin="dense"
            label={t("fee.name")}
            fullWidth
            value={name}
            disabled={isDisabled}
            onChange={this.handleNameChange}
          />
          <EnumSelector
            margin="dense"
            label={t("fee.type")}
            fullWidth
            value={type}
            disabled={true}
            onChange={this.handleTypeChange}
            enumeration={FeeType}
            renderLabel={(type) => t(`fee.${type}`)}
          />
          <div className={classes.smallSpacing} />
          <TextField
            variant="standard"
            label={t("fee.value")}
            type="number"
            inputProps={{
              step: type === FeeType.VARIABLE ? 1 : 0.01,
              min: type === FeeType.VARIABLE ? 1 : 0.01,
              max: type === FeeType.VARIABLE ? 100 : undefined
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {type === FeeType.FLAT
                    ? bar.getOrderCurrency()
                    : type === FeeType.VARIABLE
                    ? "%"
                    : ""}
                </InputAdornment>
              )
            }}
            fullWidth
            value={`${value}`}
            disabled={isDisabled}
            onChange={this.handleValueChange}
            onBlur={this.handleValueBlur}
          />
          <div className={classes.largeSpacing} />
          <EditFeeConditions
            bar={bar}
            subscription={subscription}
            conditions={conditions}
            onChange={this.handleConditionsChange}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={isDisabled} onClick={onClose} color="secondary">
            {t("label.cancel")}
          </Button>
          <Button
            disabled={isDisabled}
            onClick={this.handleUpdateFee}
            color="primary"
          >
            {t("label.update")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(EditFeeDialog));
