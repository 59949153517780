import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import withStyles from "@mui/styles/withStyles";

import OrderName from "tap-io/client/components/order/OrderName";

const styles = (theme) => ({
  orderName: {
    flexGrow: 1,
    margin: theme.spacing(-1.5, 0)
  },
  priorityIcon: {
    display: "flex",
    width: 28,
    fontSize: 20,
    color: theme.palette.warning.main
  }
});

class OrderNameLabel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, order, showInfoButton } = this.props;

    return (
      <>
        <span className={classes.priorityIcon}>
          {order.isHighPriority && <OfflineBoltIcon />}
        </span>
        <OrderName
          order={order}
          showInfoButton={showInfoButton}
          className={classes.orderName}
        />
      </>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(OrderNameLabel));
