import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { QRCode } from "react-qrcode-logo";

import { Button, CircularProgress, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";
import theme from "tap-io/client/theme";
import { barService } from "tap-io/client/services";

const styles = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  linkAndQr: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  link: {
    textDecoration: "none",
    wordBreak: "break-all",
    color: "black"
  },
  barUrl: {
    fontFamily: "'Anonymous Pro', 'Courier New', Courier, monospace"
  },
  hidden: {
    display: "none"
  }
});

class GenerateQRCode extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      barUrl: null
    };

    this.qrCodeRef = React.createRef();
  }

  componentDidMount() {
    this.refreshBarUrl();
  }

  componentDidUpdate(prevProps, prevState) {
    const { bar } = this.props;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;

    if (barId !== prevBarId) {
      this.refreshBarUrl();
    }
  }

  refreshBarUrl = async () => {
    const { bar } = this.props;

    const barUrl = await barService.getBarUrl(bar, config.hosting.orderDomain);

    this.setState({ barUrl });
  };

  downloadQRCodeImage = () => {
    if (
      this.qrCodeRef &&
      this.qrCodeRef.current &&
      this.qrCodeRef.current.canvas &&
      this.qrCodeRef.current.canvas.current
    ) {
      const canvas = this.qrCodeRef.current.canvas.current;

      const link = document.createElement("a");
      link.crossorigin = "anonymous";
      link.rel = "noreferrer";
      link.download = "qrcode.png";
      link.href = canvas.toDataURL();
      link.click();
    }
  };

  render() {
    const { classes, t, assets } = this.props;
    const { barUrl } = this.state;

    // TO FIX: enableCORS only works with theme assets
    const hasThemeLogo = !!theme.assets.qrCodeLogo;

    return (
      <div className={classes.content}>
        {barUrl ? (
          <div className={classes.linkAndQr}>
            <a
              className={classes.link}
              href={barUrl}
              target="_blank"
              rel="noreferrer"
            >
              <Typography variant="caption" className={classes.barUrl}>
                {barUrl}
              </Typography>
            </a>
            <QRCode
              ref={hasThemeLogo ? this.qrCodeRef : undefined}
              enableCORS={hasThemeLogo}
              className={classes.qr}
              value={barUrl}
              logoImage={
                theme.assets.qrCodeLogo
                  ? theme.assets.qrCodeLogo
                  : assets
                  ? assets.logoImageUrl
                  : undefined
              }
            />
            <div className={classes.hidden}>
              <QRCode
                ref={hasThemeLogo ? undefined : this.qrCodeRef}
                value={barUrl}
              />
            </div>
            <Button onClick={this.downloadQRCodeImage}>
              {t("label.download")}
            </Button>
          </div>
        ) : (
          <CircularProgress />
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(GenerateQRCode)
);
