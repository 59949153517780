import { useCallback, useEffect, useState } from "react";

type Awaited<T> = T extends PromiseLike<infer U> ? U : T;

export function createAsync<F extends (...args: any[]) => Promise<any>>(
  funct: F,
  live: boolean
) {
  return function useAsync(...args: Parameters<F>) {
    const [status, setStatus] = useState<"idle" | "busy" | "failed" | "ready">(
      "idle"
    );
    const [data, setData] = useState<Awaited<ReturnType<F>> | null>(null);

    const load = useCallback(() => {
      setStatus("busy");
      funct(...args)
        .then((data) => {
          setData(data);
          setStatus("ready");
        })
        .catch(() => {
          setStatus("failed");
        });
    }, args);

    useEffect(() => {
      if (live) load();
    }, [load]);

    return { status, data, load } as Async<Awaited<ReturnType<F>>>;
  };
}

export type Async<T> =
  | { status: "idle" | "busy" | "failed"; data: null; load: () => void }
  | { status: "ready" | "busy" | "failed"; data: T; load: () => void };

export type ResultOfAsync<A extends Async<any>> = A extends Async<infer T>
  ? NonNullable<T>
  : never;

export function enhanceAsync<A extends Async<any>, T extends any[]>(
  async: A,
  cb: (data: ResultOfAsync<A>) => T
): Async<T> {
  if (async.status !== "ready") return async;
  return {
    data: cb(async.data),
    status: async.status,
    load: async.load
  };
}
