import { useState } from "react";
import { Asyncer } from "../../../components/common/Asyncer";
import { Async } from "../../../components/common/useAsync";
import { FinancialTimeslotReport } from "../../models/FinancialTimeslotReport";
import { BarStats } from "../common/BarStats";
import { DownloadIconCSVButton } from "../common/DownloadCSVIconButton";
import { FailedStatCard } from "../common/FailedStatCard";
import { FilterSwitch } from "../common/FilterSwitch";
import { LoadingStatCard } from "../common/LoadingStatCard";
import { StatCard } from "../common/StatCard";

export type RevenuePerHourProps = {
  async: Async<FinancialTimeslotReport>;
  date: Date;
  mark: string;
  t: any;
};

export function RevenuePerHour(props: RevenuePerHourProps) {
  const { async, date, mark, t } = props;

  const [isVatActive, setIsVatActive] = useState(true);

  const title = t("stats.stat.value-per-hour.title");
  const description = t("stats.stat.value-per-hour.description");

  return (
    <Asyncer
      async={async}
      Busy={<LoadingStatCard title={title} description={description} />}
      Failed={
        <FailedStatCard title={title} description={description} async={async} />
      }
    >
      {(report) => {
        return (
          <StatCard
            title={title}
            description={description}
            action={
              <DownloadIconCSVButton
                title={`${title}-${mark}`}
                csv={report.toCSV(date, t)}
              />
            }
            filters={
              <FilterSwitch
                label={t("stats.input.vat.label")}
                value={isVatActive}
                onChange={setIsVatActive}
              />
            }
          >
            <BarStats
              limit={24}
              vertical
              sort={["hour", "ASC"]}
              data={report.activeHours}
              x="hour"
              formatX={report.formatHour(date)}
              y={isVatActive ? "valueInclVat" : "valueExclVat"}
              formatY={report.formatCurrency}
              t={t}
            />
          </StatCard>
        );
      }}
    </Asyncer>
  );
}
