import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import ReactMoment from "react-moment";

import {
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import withStyles from "@mui/styles/withStyles";

import { balanceService } from "tap-io/client/services";
import RestoreIcon from "@mui/icons-material/Restore";

const styles = (theme) => ({
  spinner: {
    justifyContent: "center"
  },
  success: {
    color: "green"
  },
  error: {
    color: "red"
  }
});

const TRANSACTION_INITIAL_BATCH_SIZE = 5;
const TRANSACTION_ADDITIONAL_BATCH_SIZE = 5;

class Transactions extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false,
      transactionMaxCount: TRANSACTION_INITIAL_BATCH_SIZE
    };
  }

  componentDidMount() {
    this.refreshTransactions();
  }

  componentWillUnmount() {
    if (this.unsubscribeBarTransactions) {
      this.unsubscribeBarTransactions();
      this.unsubscribeBarTransactions = undefined;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { bar } = this.props;
    const { transactionMaxCount } = this.state;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;

    const prevTransactionMaxCount = prevState.transactionMaxCount;

    if (
      barId !== prevBarId ||
      transactionMaxCount !== prevTransactionMaxCount
    ) {
      this.refreshTransactions();
    }
  }

  refreshTransactions() {
    const { bar } = this.props;
    const { transactionMaxCount } = this.state;

    if (this.unsubscribeBarTransactions) {
      this.unsubscribeBarTransactions();
      this.unsubscribeBarTransactions = undefined;
    }

    if (bar && bar.id) {
      this.setState({ isDisabled: true });

      this.unsubscribeBarTransactions = balanceService.onTransactionsById(
        bar.id,
        transactionMaxCount,
        (transactions) => {
          this.setState({ isDisabled: false, transactions });
        }
      );
    }
  }

  handleFetchMoreTransactions = () => {
    const { transactionMaxCount } = this.state;

    this.setState({
      transactionMaxCount:
        transactionMaxCount + TRANSACTION_ADDITIONAL_BATCH_SIZE
    });
  };

  render() {
    const { classes, t, bar } = this.props;
    const { isDisabled, transactionMaxCount, transactions } = this.state;

    return (
      <List>
        {transactions ? (
          <div>
            {transactions.length > 0 ? (
              transactions.map((transaction) => (
                <ListItem key={transaction.id}>
                  <ListItemIcon>
                    {transaction.isPaid ? (
                      <CheckCircleIcon className={classes.success} />
                    ) : (
                      <CancelIcon className={classes.error} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={`${transaction.creditCount} ${t(
                      transaction.creditCount === 1
                        ? "credits.credit"
                        : "credits.credits"
                    )} (€${transaction.totalPrice})`}
                    secondary={
                      <ReactMoment format="DD/MM/YYYY, HH:mm">
                        {transaction.timestamp}
                      </ReactMoment>
                    }
                  />
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemText primary={t("credits.no-transactions-found")} />
              </ListItem>
            )}
          </div>
        ) : (
          <ListItem className={classes.spinner}>
            <CircularProgress />
          </ListItem>
        )}
        {transactions && transactionMaxCount <= transactions.length && (
          <ListItem
            button
            disabled={isDisabled}
            onClick={this.handleFetchMoreTransactions}
          >
            <ListItemIcon>
              <RestoreIcon />
            </ListItemIcon>
            <ListItemText primary={t("label.load-more-items")} />
          </ListItem>
        )}
      </List>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(Transactions)
);
