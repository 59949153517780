import StatsService from "../../services/StatsService";
import {
  authHelper,
  serviceHelper,
  statsHelper,
  utilsHelper
} from "../../helpers";
import Stats from "../../models/stats/Stats";
import { statsParser } from "../../parsers";

export default class ClientStatsService extends StatsService {
  constructor(fbStore) {
    super(fbStore);
  }

  public onStatsBetweenDates(
    apiFunctionsEndpoint: string,
    authenticatedUser: any,
    barId: string,
    statsDataUnit: string,
    statsDataKeys: Array<string>,
    minDate: Date,
    maxDate: Date,
    cb: Function
  ) {
    const minDateAsString = utilsHelper.jsDateToDateString(minDate);
    const minTimeAsString = utilsHelper.jsDateToTimeString(minDate);
    const maxDateAsString = utilsHelper.jsDateToDateString(maxDate);
    const maxTimeAsString = utilsHelper.jsDateToTimeString(maxDate);

    const statsFormat = statsHelper.getStatsFormat(
      statsDataUnit,
      statsDataKeys
    );
    const datesToFetch = statsHelper
      .getDatesAsString(minDateAsString, maxDateAsString)
      .filter((date) => statsHelper.canStatsBeResetForDate(date));

    const ref = this.getStatsRef(barId)
      .where(serviceHelper.documentId(), ">=", minDateAsString)
      .where(serviceHelper.documentId(), "<=", maxDateAsString)
      .orderBy(serviceHelper.documentId(), "asc");

    let resetStatsPromise: Promise<any>;

    return ref.onSnapshot((querySnapshot) => {
      const docs = querySnapshot.docs;

      const datesNotFetched = [...datesToFetch];
      const statsPerDate: Array<Stats> = docs
        .map((doc, index: number) => {
          const stats = statsParser.parseDocToStats(barId, doc);

          // Only allow stats that have the correct format
          // and cannot be reset (e.g. if date === TODAY)
          if (
            stats.hasOnlyFormat(statsFormat) ||
            !statsHelper.canStatsBeResetForDate(stats.date)
          ) {
            // Date
            if (stats.date) {
              const dateIndex = datesNotFetched.indexOf(stats.date);
              if (dateIndex >= 0) {
                datesNotFetched.splice(dateIndex, 1);
              }
            }

            if (index === 0 || index === docs.length - 1) {
              const formattedMinTime =
                index === 0
                  ? `${minTimeAsString.substr(0, 2)}${minTimeAsString.substr(
                      3,
                      2
                    )}`
                  : undefined;
              const formattedMaxTime =
                index === docs.length - 1
                  ? `${maxTimeAsString.substr(0, 2)}${maxTimeAsString.substr(
                      3,
                      2
                    )}`
                  : undefined;

              return stats.getRange(formattedMinTime, formattedMaxTime);
            }

            return stats;
          }
        })
        .filter((stats: Stats | undefined) => !!stats);

      if (datesNotFetched.length > 0 && !resetStatsPromise) {
        resetStatsPromise = Promise.all(
          datesNotFetched.map((date) =>
            this.resetStatsForDate(
              apiFunctionsEndpoint,
              authenticatedUser,
              barId,
              date
            )
          )
        );
      }

      cb(statsPerDate, datesNotFetched);
    });
  }

  public async resetStatsForDate(
    apiFunctionsEndpoint: string,
    authenticatedUser: any,
    barId: string,
    date: string
  ) {
    const link = `${apiFunctionsEndpoint}/crew/bar/stats/reset`;
    const params = { barId, date };

    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link,
      params
    );

    const data = await serviceHelper.fetchText(url);

    return data;
  }
}
