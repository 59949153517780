import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import withStyles from "@mui/styles/withStyles";

import OpenOrdersCounters from "./OpenOrdersCounters";
import OpenOrdersBars from "./OpenOrdersBars";
import TopItemsInQueueRightNow from "../order/TopItemsInQueueRightNow";

const styles = (theme) => ({
  title: {
    width: "100%",
    fontWeight: "bold"
  },
  summary: {
    display: "flex",
    alignItems: "center"
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  subtitle: {
    marginBottom: theme.spacing(2)
  },
  spacing: {
    height: theme.spacing(4)
  }
});

class OpenOrders extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes,
      t,
      headerLeft,
      title,
      base,
      openOrders,
      openOrdersCountPerMinute
    } = this.props;

    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{ content: classes.summary }}
        >
          {headerLeft}
          <Typography className={classes.title}>{title}</Typography>
          <OpenOrdersCounters openOrders={openOrders} />
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Typography className={classes.subtitle}>
            {t("overview.order-count-per-minute-ago")}
          </Typography>
          <OpenOrdersBars openOrdersCountPerMinute={openOrdersCountPerMinute} />
          <div className={classes.spacing} />
          <Typography className={classes.subtitle}>
            {t("overview.top-items-being-ordered-right-now")}
          </Typography>
          <TopItemsInQueueRightNow
            base={base}
            openOrders={openOrders}
          />
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(OpenOrders)
);
