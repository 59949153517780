import { DataOf, Stat } from "./Stat";

type Data = {
  paymentOption: string;
  provider: string;
  method: string;
  value: number;
  currency: string;
};

export class PaymentReport extends Stat<Data> {
  constructor(data: Omit<DataOf<PaymentReport>, "paymentOption">[]) {
    super(
      data.map((data) => ({
        paymentOption: `${data.provider} - ${data.method}`,
        ...data
      }))
    );
  }

  formatProvider(t: any) {
    return (provider: string) => t(`payment.payment-provider-${provider}`);
  }
  formatMethod(t: any) {
    return (method: string) => {
      let formattedMethod = t(`payment.payment-method-${method}`);
      if (formattedMethod === `payment.payment-method-${method}`) {
        formattedMethod = method.charAt(0).toUpperCase() + method.slice(1);
      }
      return formattedMethod;
    };
  }
  formatPaymentOption(t: any) {
    return (_: string, data: any) =>
      `${this.formatProvider(t)(data.provider)} - ${this.formatMethod(t)(
        data.method
      )}`;
  }

  toCSV(t: any) {
    return this.generateCSV(
      {
        paymentOption: false,
        provider: this.formatProvider(t),
        method: this.formatMethod(t),
        value: this.formatCurrency,
        currency: true
      },
      t
    );
  }
}
