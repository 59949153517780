import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import ShowBarWaiterLinkDialog from "./ShowBarWaiterLinkDialog";
import EditPin from "../settings/EditPin";

const styles = (theme) => ({
  spacing: {
    width: "100%",
    height: 30
  },
  accentuate: {
    color: "red"
  }
});

class BarWaiterLink extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isShowBarWaiterLinkDialogOpen: false
    };
  }

  handleShowBarWaiterLink = () => {
    this.setState({ isShowBarWaiterLinkDialogOpen: true });
  };

  handleShowBarWaiterLinkDialogClose = () => {
    this.setState({ isShowBarWaiterLinkDialogOpen: false });
  };

  render() {
    const { classes, t, bar } = this.props;
    const { isShowBarWaiterLinkDialogOpen } = this.state;

    return (
      <div>
        <ShowBarWaiterLinkDialog
          isOpen={isShowBarWaiterLinkDialogOpen}
          onClose={this.handleShowBarWaiterLinkDialogClose}
          bar={bar}
        />
        <Typography>
          {t("promo.share-this-page-with-waiters-to-manually-take-orders")}
        </Typography>
        <div className={classes.spacing}></div>
        {bar.isAllowingOnlinePayments() &&
        !bar.isPinEnabledForDeferredPayments() ? (
          <div>
            <Typography className={classes.accentuate}>
              {`${t("promo.for-waiters-to-take-manual-orders-you-need")} `}
              <strong>{t("promo.to-set-a-pin")}</strong>.
            </Typography>
            <EditPin bar={bar} />
          </div>
        ) : (
          <Button fullWidth onClick={this.handleShowBarWaiterLink}>
            {t("promo.show-link")}
          </Button>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(BarWaiterLink)
);
