import { useMemo } from "react";

import { Alert, Box, Divider, Stack, Tab, Tabs, useTheme } from "@mui/material";

import { createAsync } from "../../components/common/useAsync";
import { StatGroup } from "./common/StatGroup";
import { AmountOfFeesCharged } from "./cards/AmountOfFeesCharged";
import { InclusionOfFees } from "./cards/InclusionOfFees";
import { MiscReportDownloads } from "./cards/MiscReportDownloads";
import { fetchFeeReport } from "../services/StatService";

function MiscStats(props: {
  organisationId: string;
  period: [begin: Date, end: Date];
  user: any;
  t: any;
}) {
  const theme = useTheme();
  const { organisationId, period, user, t } = props;

  const filter = useMemo(() => {
    return {
      organisationId,
      eventId: organisationId,
      from: period[0],
      to: period[1]
    };
  }, [organisationId, period]);

  const mark = `${period[0].toLocaleString("nl-BE")}-${period[1].toLocaleString(
    "nl-BE"
  )}`;

  const feeReports = createAsync(fetchFeeReport, true)(user, filter);

  return (
    <>
      <Stack spacing={10}>
        <StatGroup>
          <AmountOfFeesCharged async={feeReports} mark={mark} t={t} />
          <InclusionOfFees async={feeReports} mark={mark} t={t} />
        </StatGroup>

        <StatGroup>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing()
            }}
          >
            <Divider />
            <MiscReportDownloads fee={feeReports} mark={mark} t={t} />
          </Box>
        </StatGroup>
      </Stack>
    </>
  );
}

export default MiscStats;
