import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { Tab, Tabs } from "@mui/material";

import StatCategory from "../models/StatCategory";

const CATEGORIES = [
  StatCategory.OPERATIONAL,
  StatCategory.FINANCIAL,
  StatCategory.MISC
] as const;

function StatCategorySelector({
  t,
  value,
  onChange
}: {
  t: any;
  value: StatCategory;
  onChange: (category: StatCategory) => void;
}) {
  return (
    <Tabs
      sx={{ flex: 1 }}
      value={value}
      onChange={(e, category) => onChange(category)}
      variant="scrollable"
      scrollButtons="auto"
    >
      {CATEGORIES.map((category) => {
        let icon;
        switch (category) {
          case StatCategory.FINANCIAL:
            icon = <AttachMoneyIcon />;
            break;
          case StatCategory.OPERATIONAL:
            icon = <QueryStatsIcon />;
            break;
          case StatCategory.MISC:
            icon = <MiscellaneousServicesIcon />;
            break;

          default:
            icon = null;
            break;
        }

        return (
          <Tab
            key={category}
            value={category}
            label={t(`stats.category.${category}.label`)}
            icon={icon}
          />
        );
      })}
    </Tabs>
  );
}

export default StatCategorySelector;
