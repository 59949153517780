import { useState } from "react";

import { withTranslation } from "react-i18next";

import Moment from "moment";

import { Alert, Box, Divider, Stack, Tab, Tabs, useTheme } from "@mui/material";

import withBarAuthorization from "../components/auth/withBarAuthorization";
import withPasswordAuthentication from "../components/auth/withPasswordAuthentication";
import BaseSelector from "../components/base/BaseSelector";

import StatCategorySelector from "../stats/components/StatCategorySelector";
import StatPeriodSelect from "../stats/components/StatPeriodSelect";

import OperationalStats from "../stats/components/OperationalStats";
import FinancialStats from "../stats/components/FinancialStats";
import MiscStats from "../stats/components/MiscStats";

import StatCategory from "../stats/models/StatCategory";

//type StatCategory = typeof CATEGORIES[number];

function StatsPage({ t, auth, bar, allBases, subscription }) {
  const theme = useTheme();
  const user = auth.user;
  const organisationId = bar.id;

  const [selectedCategory, selectCategory] = useState<StatCategory>(
    StatCategory.OPERATIONAL
  );
  const [period, selectPeriod] = useState<[Date, Date]>([
    Moment().startOf("day").toDate(),
    Moment().endOf("day").toDate()
  ]);

  const [selectedBase, selectBase] = useState<string>();

  return (
    <Stack spacing={2} sx={{ margin: theme.spacing() }}>
      <Stack direction="row" spacing={2}>
        <Box
          sx={{
            padding: theme.spacing(),
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing()
          }}
        >
          <Alert severity="info">{t("stats.message.delay")}</Alert>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: theme.spacing()
            }}
          >
            <StatCategorySelector
              value={selectedCategory}
              onChange={selectCategory}
              t={t}
            />
            <Box sx={{ minWidth: 500 }}>
              <StatPeriodSelect value={period} onChange={selectPeriod} t={t} />
            </Box>
          </Box>

          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: theme.spacing(),
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            {selectedCategory === StatCategory.OPERATIONAL && (
              <BaseSelector
                t={t}
                organisationId={organisationId}
                allBases={allBases}
                value={selectedBase}
                onChange={(baseId) => selectBase(baseId)}
              />
            )}
            {selectedCategory === StatCategory.FINANCIAL && <Box />}
            {selectedCategory === StatCategory.MISC && <Box />}
          </Box>
        </Box>
      </Stack>
      {selectedCategory === StatCategory.OPERATIONAL && (
        <OperationalStats
          organisationId={organisationId}
          baseId={selectedBase}
          period={period}
          user={user}
          t={t}
        />
      )}
      {selectedCategory === StatCategory.FINANCIAL && (
        <FinancialStats
          organisationId={organisationId}
          period={period}
          user={user}
          t={t}
        />
      )}
      {selectedCategory === StatCategory.MISC && (
        <MiscStats
          organisationId={organisationId}
          period={period}
          user={user}
          t={t}
        />
      )}
    </Stack>
  );
}

export default withPasswordAuthentication()(
  withBarAuthorization()(withTranslation("common")(StatsPage))
);
