import React, { PureComponent } from "react";

import { Divider, Menu } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { hasNativeWrapper } from "../../native";
import BarSoundControlsMenuItem from "../navigation/BarSoundControlsMenuItem";
import AccountMenuItem from "../navigation/AccountMenuItem";
import AppMenuItem from "../navigation/AppMenuItem";
import HelpMenuItem from "../navigation/HelpMenuItem";
import MenusMenuItem from "../navigation/MenusMenuItem";
import OrdersMenuItem from "../navigation/OrdersMenuItem";
import PrinterMenuItem from "../navigation/PrinterMenuItem";
import CrewMenuItem from "../navigation/CrewMenuItem";
import ModesMenuItem from "../navigation/ModesMenuItem";
import CheckoutMenuItem from "../navigation/CheckoutMenuItem";
import VouchersMenuItem from "../navigation/VouchersMenuItem";
import OverviewMenuItem from "../navigation/OverviewMenuItem";
import * as routes from "../../constants/routes";

const styles = (theme) => ({
  menu: {
    minWidth: 250
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  link: {
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.87)"
  }
});

class BarBasicMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes,
      anchor,
      onClose,
      deviceSettings,
      mode,
      bar,
      base,
      balance,
      assets,
      toggleMuted,
      unlockApp
    } = this.props;

    const isOpen = Boolean(anchor);

    // TO FIX: MenuItems wrapped in a <div /> container to circumvent ForwardRef error
    return (
      <Menu anchorEl={anchor} open={isOpen} onClose={onClose}>
        <div className={classes.menu}>
          <BarSoundControlsMenuItem
            isMuted={deviceSettings && deviceSettings.isMuted}
            toggleMuted={toggleMuted}
          />
          <Divider className={classes.divider} />
          <ModesMenuItem onClicked={onClose} />
          {mode && (
            <div>
              {mode.routes.indexOf(routes.ORDERS) >= 0 && (
                <OrdersMenuItem onClicked={onClose} />
              )}
              {mode.routes.indexOf(routes.MENUS) >= 0 && (
                <MenusMenuItem onClicked={onClose} />
              )}
              {mode.routes.indexOf(routes.CHECKOUT) >= 0 && (
                <CheckoutMenuItem onClicked={onClose} />
              )}
              {mode.routes.indexOf(routes.VOUCHERS) >= 0 && (
                <VouchersMenuItem onClicked={onClose} />
              )}
              {mode.routes.indexOf(routes.OVERVIEW) >= 0 && (
                <OverviewMenuItem onClicked={onClose} />
              )}
            </div>
          )}
          {hasNativeWrapper() && (
            <PrinterMenuItem
              bar={bar}
              base={base}
              assets={assets}
              deviceSettings={deviceSettings}
              onClose={onClose}
            />
          )}
          <CrewMenuItem onClicked={onClose} unlockApp={unlockApp} />
          <Divider className={classes.divider} />
          <HelpMenuItem onClicked={onClose} />
          <AccountMenuItem />
          <AppMenuItem />
        </div>
      </Menu>
    );
  }
}

export default withStyles(styles)(BarBasicMenu);
