import React, { PureComponent } from "react";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: 20
  },
  image: {
    width: "100%",
    height: 100,
    margin: "20px 0",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "auto 100%"
  },
  title: {},
  description: {
    maxWidth: 600,
    padding: 10
  }
});

class SetupStep extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, children, image, title, description } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.header}>
          {image && (
            <div
              className={classes.image}
              style={{ backgroundImage: `url("${image}")` }}
            />
          )}
          <Typography variant="h6" className={classes.title} gutterBottom>
            {title}
          </Typography>
          <Typography
            variant="body1"
            className={classes.description}
            gutterBottom
          >
            {description}
          </Typography>
        </div>
        <div className={classes.content}>{children}</div>
      </div>
    );
  }
}

export default withStyles(styles)(SetupStep);
