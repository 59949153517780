import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import EditFees from "../fee/EditFees";
import CapabilityUnavailableButton from "../subscription/CapabilityUnavailableButton";

const styles = (theme) => ({
  capabilityButton: {
    marginTop: 10
  }
});

class EditFeeSettings extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { t, classes, bar, subscription, isEditable } = this.props;

    const areFeesAvailable =
      subscription && subscription.capabilities.areFeesAvailable();

    return (
      <div>
        {areFeesAvailable ? (
          <EditFees
            bar={bar}
            subscription={subscription}
            isEditable={isEditable}
          />
        ) : (
          <div className={classes.capabilityButton}>
            <CapabilityUnavailableButton
              bar={bar}
              subscription={subscription}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditFeeSettings)
);
