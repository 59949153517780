import React, { PureComponent } from "react";

import { Link } from "react-router-dom";

import withStyles from "@mui/styles/withStyles";
import BarMenuItem from "./BarMenuItem";

const styles = (theme) => ({
  link: {
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.87)"
  }
});

class BarLinkMenuItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, to, onClicked, icon, label, description } = this.props;

    return (
      <Link to={to} onClick={onClicked} className={classes.link}>
        <BarMenuItem
          icon={icon}
          label={label}
          description={description}
          onClick={() => {}}
        />
      </Link>
    );
  }
}

export default withStyles(styles)(BarLinkMenuItem);
