import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import ReactMoment from "react-moment";

import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({});

class OrderSequenceTimestampLabel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, bar, order, format, width } = this.props;

    return (
      <ReactMoment
        locale={bar.getOrderLocale()}
        format={format || "LT"}
        style={{ width: width || 80 }}
      >
        {order.sequenceTimestamp}
      </ReactMoment>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(OrderSequenceTimestampLabel)
);
