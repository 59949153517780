import React, { PureComponent } from "react";
import { Redirect } from "react-router-dom";

import * as routes from "../../constants/routes";

const withMode = (requiredModeName) => (Component) => {
  class WithMode extends PureComponent {
    render() {
      const { mode } = this.props;

      return mode && mode.name === requiredModeName ? (
        <Component {...this.props} />
      ) : (
        <Redirect to={routes.MODES} />
      );
    }
  }

  return WithMode;
};

export default withMode;
