import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import ReactMoment from "react-moment";

import {
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import DoneIcon from "@mui/icons-material/Done";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import BlockIcon from "@mui/icons-material/Block";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import withStyles from "@mui/styles/withStyles";

import OrderStatus from "tap-io/models/order/OrderStatus";

const styles = (theme) => ({
  orderLabel: {
    display: "block"
  },
  orderPriceWhenPaid: {
    color: "green"
  },
  orderPriceWhenNotPaidYet: {
    color: "red"
  }
});

class OrderItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleOrderClick = () => {
    const { order, onClick } = this.props;
    onClick(order);
  };

  getOrderStatusLabel = () => {
    const { t, base, order } = this.props;

    const orderStatus = order.getStatus(base);

    switch (orderStatus) {
      case OrderStatus.CREATED:
        return t("order.order-created-not-yet-paid");
        break;
      case OrderStatus.CANCELLED:
        return t("order.order-cancelled");
        break;
      case OrderStatus.ERROR:
        return t("order.order-errored");
        break;
    }

    return `(${t("label.unknown")})`;
  };

  render() {
    const { classes, t, bar, base, order, inProgress, secondaryAction } =
      this.props;

    const hasBeenQueued = order.hasBeenQueued(base);

    return (
      <ListItem onClick={this.handleOrderClick} disabled={!hasBeenQueued}>
        <ListItemIcon>
          {inProgress ? (
            <CircularProgress size={24} />
          ) : order.isStatusCreated(base) ? (
            <SwapHorizIcon />
          ) : order.isStatusComplete(base) ? (
            <DoneIcon />
          ) : order.isStatusCancelled(base) ? (
            <BlockIcon />
          ) : order.isStatusError(base) ? (
            <ErrorOutlineIcon />
          ) : (
            <HourglassEmptyIcon />
          )}
        </ListItemIcon>
        <ListItemText
          primary={order.name}
          secondary={
            <span>
              {hasBeenQueued ? (
                <span
                  className={classNames(
                    classes.orderLabel,
                    order.isPaid()
                      ? classes.orderPriceWhenPaid
                      : bar && bar.isAllowingOnlinePayments()
                      ? order.isFree()
                        ? undefined
                        : classes.orderPriceWhenNotPaidYet
                      : undefined
                  )}
                >{`${order.getFormattedTotal(base)} ${order.currency}`}</span>
              ) : (
                <span className={classes.orderLabel}>
                  {this.getOrderStatusLabel()}
                </span>
              )}
              {order.timestamp && (
                <ReactMoment format="DD/MM/YYYY LT">
                  {order.timestamp}
                </ReactMoment>
              )}
            </span>
          }
        />
        {secondaryAction && (
          <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>
        )}
      </ListItem>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(OrderItem));
