import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import AssessmentIcon from "@mui/icons-material/Assessment";
import withStyles from "@mui/styles/withStyles";

import * as routes from "../../constants/routes";
import BarLinkMenuItem from "./BarLinkMenuItem";

const styles = (theme) => ({});

class StatsMenuItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleItemClicked = () => {
    const { onClicked } = this.props;

    if (onClicked) {
      onClicked();
    }
  };

  render() {
    const { t } = this.props;

    return (
      <BarLinkMenuItem
        to={routes.STATS}
        icon={<AssessmentIcon />}
        label={t("label.stats")}
        onClicked={this.handleItemClicked}
      />
    );
  }
}

export default withStyles(styles)(withTranslation("common")(StatsMenuItem));
