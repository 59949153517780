import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { ListItemText, MenuItem } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import FeeServiceOptionCondition from "tap-io/models/fee/FeeServiceOptionCondition";

const styles = (theme) => ({});

class AddFeeServiceOptionConditionMenuItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleAddServiceOptionCondition = () => {
    const { onAdd } = this.props;

    onAdd(new FeeServiceOptionCondition({ serviceOptionNames: [] }));
  };

  render() {
    const { t } = this.props;

    return (
      <MenuItem onClick={this.handleAddServiceOptionCondition}>
        <ListItemText primary={t("fee.service-option-condition")} />
      </MenuItem>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(AddFeeServiceOptionConditionMenuItem)
);
