import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import withStyles from "@mui/styles/withStyles";

import { voucherService } from "tap-io/client/services";
import ConfirmDialog from "tap-io/client/components/common/ConfirmDialog";

import AddVoucherTemplateDialog from "./AddVoucherTemplateDialog";
import EditVoucherTemplateDialog from "./EditVoucherTemplateDialog";

const styles = (theme) => ({
  dialog: {
    minWidth: 650
  }
});

class ManageVoucherTemplatesDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isAddVoucherTemplateDialogOpen: false,
      voucherTemplateBeingEdited: null,
      voucherTemplatePendingRemovalConfirmation: null
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isOpen } = this.props;

    const prevIsOpen = prevProps.isOpen;

    if (isOpen && !prevIsOpen) {
      this.refreshVoucherTemplates();
    } else if (prevIsOpen && !isOpen) {
      this.clearVoucherTemplatesSubscription();
    }
  }

  componentWillUnmount() {
    this.clearVoucherTemplatesSubscription();
  }

  refreshVoucherTemplates = () => {
    const { bar } = this.props;

    this.clearVoucherTemplatesSubscription();

    if (bar && bar.id) {
      this.unsubscribeVoucherTemplates = voucherService.onAllVoucherTemplates(
        bar.id,
        (voucherTemplates) => {
          this.setState({ voucherTemplates });
        }
      );
    } else {
      this.setState({ voucherTemplates: [] });
    }
  };

  clearVoucherTemplatesSubscription = () => {
    if (this.unsubscribeVoucherTemplates) {
      this.unsubscribeVoucherTemplates();
      this.unsubscribeVoucherTemplates = undefined;
    }
  };

  handleAddVoucherTemplate = () => {
    this.setState({ isAddVoucherTemplateDialogOpen: true });
  };

  handleAddVoucherTemplateDialogClose = () => {
    this.setState({ isAddVoucherTemplateDialogOpen: false });
  };

  handleEditVoucherTemplate = (voucherTemplate) => (event) => {
    this.setState({ voucherTemplateBeingEdited: voucherTemplate });
  };

  handleEditVoucherTemplateDialogClose = () => {
    this.setState({ voucherTemplateBeingEdited: null });
  };

  handleRemoveVoucherTemplate = (voucherTemplate) => (event) => {
    event.stopPropagation();

    this.setState({
      voucherTemplatePendingRemovalConfirmation: voucherTemplate
    });
  };

  handleRemoveVoucherTemplateConfirm = async () => {
    const { t } = this.props;
    const { voucherTemplatePendingRemovalConfirmation } = this.state;

    this.setState({
      voucherTemplatePendingRemovalConfirmation: null
    });

    const toastId = toast(t("voucher.removing-voucher-template"), {
      autoClose: false
    });

    try {
      await voucherService.removeVoucherTemplate(
        voucherTemplatePendingRemovalConfirmation
      );

      toast.update(toastId, {
        render: t("voucher.voucher-template-removed"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleRemoveVoucherTemplateCancel = () => {
    this.setState({ voucherTemplatePendingRemovalConfirmation: null });
  };

  render() {
    const { classes, t, isOpen, onClose, bar } = this.props;
    const {
      isAddVoucherTemplateDialogOpen,
      voucherTemplateBeingEdited,
      voucherTemplatePendingRemovalConfirmation,
      voucherTemplates
    } = this.state;

    return (
      <div>
        <AddVoucherTemplateDialog
          isOpen={isAddVoucherTemplateDialogOpen}
          onClose={this.handleAddVoucherTemplateDialogClose}
          bar={bar}
        />
        <EditVoucherTemplateDialog
          isOpen={voucherTemplateBeingEdited !== null}
          onClose={this.handleEditVoucherTemplateDialogClose}
          bar={bar}
          voucherTemplate={voucherTemplateBeingEdited}
        />
        <ConfirmDialog
          isOpen={voucherTemplatePendingRemovalConfirmation !== null}
          title={t("voucher.confirm-removal-of-voucher-template")}
          description={t(
            "voucher.are-you-sure-you-want-to-remove-this-voucher-template"
          )}
          confirmButtonLabel={t("label.remove")}
          onConfirm={this.handleRemoveVoucherTemplateConfirm}
          onCancel={this.handleRemoveVoucherTemplateCancel}
        />
        <Dialog
          open={isOpen}
          onClose={onClose}
          classes={{ paper: classes.dialog }}
        >
          <DialogTitle>{t("voucher.manage-voucher-templates")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("voucher.manage-voucher-templates-below")}
            </DialogContentText>
            <List>
              {voucherTemplates &&
                voucherTemplates.map((voucherTemplate) => (
                  <ListItem
                    key={voucherTemplate.id}
                    onClick={this.handleEditVoucherTemplate(voucherTemplate)}
                  >
                    <ListItemIcon>
                      <IconButton
                        onClick={this.handleRemoveVoucherTemplate(
                          voucherTemplate
                        )}
                        size="large"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemIcon>
                    <ListItemText primary={voucherTemplate.name} />
                  </ListItem>
                ))}
              <ListItem>
                <Button
                  fullWidth
                  startIcon={<AddIcon />}
                  onClick={this.handleAddVoucherTemplate}
                >
                  {t("voucher.add-voucher-template")}
                </Button>
              </ListItem>
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="secondary">
              {t("label.close")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(ManageVoucherTemplatesDialog)
);
