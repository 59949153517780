import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";
import SelectAmount from "tap-io/client/components/common/SelectAmount";

import AuthorizedAppLink from "../auth/AuthorizedAppLink";

const styles = (theme) => ({
  linkItem: {
    justifyContent: "center"
  }
});

class DownloadTableNumbersDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tableNumbersCount: 4
    };
  }

  handleTableNumbersCountChange = (tableNumbersCount) => {
    this.setState({ tableNumbersCount });
  };

  render() {
    const { classes, t, isOpen, onClose, bar } = this.props;
    const { tableNumbersCount } = this.state;

    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>{t("promo.generate-table-numbers")}</DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <ListItemText
                primary={t("promo.table-numbers-label")}
                secondary={t("promo.table-numbers-description")}
              />
              <SelectAmount
                allowInput={true}
                min={4}
                max={200}
                step={4}
                amount={tableNumbersCount}
                onChange={this.handleTableNumbersCountChange}
              />
            </ListItem>
            <ListItem className={classes.linkItem}>
              <AuthorizedAppLink
                bar={bar}
                api={config.functions.promoApi}
                endpoint="layoutlocations"
                params={{ count: tableNumbersCount }}
                text={t("promo.download-table-numbers")}
                onClick={onClose}
              />
            </ListItem>
          </List>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(DownloadTableNumbersDialog)
);
