import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  TextField
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { utilsHelper } from "tap-io/helpers";
import ParamName from "tap-io/models/bar/ParamName";

import { PARAM_DESCRIPTIONS, PARAM_LABELS } from "../../constants/params";
import { barService } from "tap-io/client/services";

const styles = (theme) => ({});

class EditReplyToEmailAddressDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (bar) => {
    return {
      replyToEmailAddress:
        bar && bar.params && bar.params.replyToEmailAddress
          ? bar.params.replyToEmailAddress
          : ""
    };
  };

  componentDidUpdate(prevProps, prevState) {
    const { isOpen, bar } = this.props;

    if (isOpen && !prevProps.isOpen) {
      this.setState(this.initialState(bar));
    }
  }

  handleReplyToEmailAddressChange = (event) => {
    const val = event.target.value;

    this.setState({
      replyToEmailAddress: val
    });
  };

  handleSave = async () => {
    const { t, bar, onClose } = this.props;
    const { replyToEmailAddress } = this.state;

    const parsedReplyToEmailAddress = utilsHelper.parseEmail(
      replyToEmailAddress.trim().toLowerCase()
    );

    if (!parsedReplyToEmailAddress) {
      return toast.error(t("error.enter-a-valid-reply-to-email-address"));
    }

    const toastId = toast(t("settings.saving-settings"), { autoClose: false });
    this.setState({ isDisabled: true });

    try {
      const barParams = barService.createBarParams(bar, {
        replyToEmailAddress: parsedReplyToEmailAddress
      });
      await barService.updateBarParams(bar, barParams);

      this.setState({ isDisabled: false });

      toast.update(toastId, {
        render: t("settings.settings-saved"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      onClose(parsedReplyToEmailAddress);
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleCancel = () => {
    const { onClose } = this.props;

    onClose();
  };

  render() {
    const { classes, t, isOpen } = this.props;
    const { replyToEmailAddress } = this.state;

    return (
      <Dialog open={isOpen} onClose={this.handleCancel}>
        <DialogTitle>{t("settings.edit-reply-to-email-address")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t(
              "delivery.pickup-delivery-requires-customers-to-enter-their-email-address-to-receive-an-order-confirmation"
            )}
          </DialogContentText>
          <List>
            <ListItem>
              <TextField
                variant="standard"
                margin="dense"
                label={t(PARAM_LABELS[ParamName.REPLY_TO_EMAIL_ADDRESS])}
                helperText={t(
                  PARAM_DESCRIPTIONS[ParamName.REPLY_TO_EMAIL_ADDRESS]
                )}
                fullWidth
                value={replyToEmailAddress}
                onChange={this.handleReplyToEmailAddressChange}
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="secondary">
            {t("label.cancel")}
          </Button>
          <Button onClick={this.handleSave} color="primary">
            {t("label.save")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(EditReplyToEmailAddressDialog)
);
