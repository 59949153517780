import React, { PureComponent } from "react";
import { Redirect, withRouter } from "react-router-dom";

import AuthContext from "tap-io/client/components/auth/authContext";

import * as routes from "../../constants/routes";

const withMgmtAuthorization = (authCondition) => (Component) => {
  class WithMgmtAuthorization extends PureComponent {
    render() {
      return (
        <AuthContext.Consumer>
          {(auth) =>
            auth && auth.user ? (
              auth.crew && auth.crew.isMgmt ? (
                <Component auth={auth} {...this.props} />
              ) : (
                <Redirect to={routes.ORDERS} />
              )
            ) : (
              <Redirect to={routes.SIGN_IN} />
            )
          }
        </AuthContext.Consumer>
      );
    }
  }

  return withRouter(WithMgmtAuthorization);
};

export default withMgmtAuthorization;
