import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import ReactMoment from "react-moment";

import {
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import withStyles from "@mui/styles/withStyles";

import { utilsHelper } from "tap-io/helpers";
import { barService } from "tap-io/client/services";
import config from "tap-io/client/env";

import withAuthorization from "../auth/withAuthorization";
import BarInfoDialog from "./BarInfoDialog";

const styles = (theme) => ({
  spacing: {
    height: 10
  },
  offlineIcon: {
    opacity: 0.5
  },
  spinner: {
    display: "block",
    margin: "0 auto"
  },
  compliantIcon: {
    color: theme.palette.success.main
  },
  notCompliantIcon: {
    color: theme.palette.error.main
  }
});

class Bars extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      inputSearchQuery: "",
      formattedSearchQuery: "",
      bars: [],
      barBeingShown: null
    };
  }

  componentDidMount() {
    this.refreshBars();
  }

  componentDidUpdate(prevProps, prevState) {
    const { formattedSearchQuery } = this.state;
    const prevFormattedSearchQuery = prevState.formattedSearchQuery;

    if (formattedSearchQuery !== prevFormattedSearchQuery) {
      this.refreshBars();
    }
  }

  refreshBars = async () => {
    const { auth } = this.props;
    const { formattedSearchQuery } = this.state;

    this.setState({ isLoading: true });

    try {
      let bars;

      if (formattedSearchQuery && formattedSearchQuery.length > 0) {
        const searchQueryParsedAsEmail =
          utilsHelper.parseEmail(formattedSearchQuery);

        if (searchQueryParsedAsEmail) {
          bars = await barService.searchBarsByEmail(
            config.functions.api,
            auth.user,
            searchQueryParsedAsEmail
          );
        } else {
          const barsById = await barService.searchBarsById(
            formattedSearchQuery
          );
          const barsByName = await barService.searchBarsByName(
            formattedSearchQuery
          );

          const barsAsMap = {};
          barsById.forEach((bar) => (barsAsMap[bar.id] = bar));
          barsByName.forEach((bar) => (barsAsMap[bar.id] = bar));
          bars = Object.keys(barsAsMap).map((barId) => barsAsMap[barId]);
        }
      } else {
        bars = await barService.getLatestBars(20);
      }

      this.setState({ isLoading: false, bars: bars ? bars : [] });
    } catch (error) {
      console.warn(error);

      this.setState({ isLoading: false, bars: [] });
    }
  };

  handleQueryChange = (event) => {
    if (this.queryTimeout) {
      clearTimeout(this.queryTimeout);
      this.queryTimeout = undefined;
    }

    const val = event.target.value;

    this.queryTimeout = setTimeout(() => {
      this.setState({ formattedSearchQuery: val.trim() });
    }, 500);

    this.setState({ inputSearchQuery: val });
  };

  handleShowBarInfo = (bar) => (event) => {
    this.setState({ barBeingShown: bar });
  };

  handleShowBarInfoDialogClose = () => {
    this.setState({ barBeingShown: null });
  };

  isBarCompliant = (bar) => {
    return (
      bar.isUsingBases() && bar.isUsingZones() && bar.isAllowingOnlinePayments()
    );
  };

  render() {
    const { classes, t, auth, onBarAccessChanged } = this.props;
    const { isLoading, bars, queryInput, barBeingShown } = this.state;

    return (
      <div>
        <BarInfoDialog
          isOpen={barBeingShown !== null}
          onClose={this.handleShowBarInfoDialogClose}
          onBarAccessChanged={onBarAccessChanged}
          auth={auth}
          bar={barBeingShown}
        />
        <List>
          <ListItem>
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <TextField
              variant="standard"
              margin="dense"
              placeholder={t("mgmt.search-by-id-name-or-email-address")}
              fullWidth
              value={queryInput}
              onChange={this.handleQueryChange}
            />
          </ListItem>
          <div className={classes.spacing}></div>
          {isLoading ? (
            <CircularProgress className={classes.spinner} />
          ) : !bars || bars.length === 0 ? (
            <Typography>{t("mgmt.no-bars-found")}</Typography>
          ) : (
            bars.map((bar) => (
              <ListItemButton
                key={bar.id}
                onClick={this.handleShowBarInfo(bar)}
              >
                <ListItemIcon>
                  {bar.isLive ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon className={classes.offlineIcon} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={bar.name ? bar.name : `(${t("label.unknown")})`}
                  secondary={
                    <span>
                      {`${t("mgmt.registered-on")} `}
                      <span>
                        {bar.timestamp ? (
                          <ReactMoment format="DD/MM/YYYY [om] HH:mm:ss">
                            {bar.timestamp}
                          </ReactMoment>
                        ) : (
                          `(${t("label.unknown")})`
                        )}
                      </span>
                    </span>
                  }
                />
                {this.isBarCompliant(bar) ? (
                  <CheckCircleIcon className={classes.compliantIcon} />
                ) : (
                  <ErrorIcon className={classes.notCompliantIcon} />
                )}
              </ListItemButton>
            ))
          )}
        </List>
      </div>
    );
  }
}

export default withAuthorization()(
  withStyles(styles, { withTheme: true })(withTranslation("common")(Bars))
);
