import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { serviceOptionHelper } from "tap-io/helpers";

const styles = (theme) => ({
  form: {
    display: "block"
  },
  description: {
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  }
});

class SelectServiceOptions extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleChange = (serviceOption) => (event) => {
    const { onChange } = this.props;

    onChange(serviceOption, event.target.checked === true);
  };

  render() {
    const {
      classes,
      t,
      isDisabled,
      allServiceOptions,
      disabledServiceOptions,
      serviceOptions,
      getIcon,
      getDescription
    } = this.props;

    return (
      <FormControl className={classes.form}>
        {allServiceOptions.map((serviceOption) => (
          <div key={serviceOption.name}>
            <FormControlLabel
              value={serviceOption.name}
              control={
                <Checkbox
                  disabled={
                    isDisabled ||
                    (disabledServiceOptions &&
                      disabledServiceOptions.has(serviceOption.name))
                  }
                  checked={serviceOptions.has(serviceOption.name)}
                  onChange={this.handleChange(serviceOption)}
                  name={serviceOption.name}
                  color="primary"
                />
              }
              label={serviceOptionHelper.getServiceOptionLabel(
                serviceOption,
                t
              )}
            />
            {getIcon &&
              getIcon(serviceOption, serviceOptions.has(serviceOption.name))}
            <div className={classes.description}>
              {getDescription &&
                getDescription(
                  serviceOption,
                  allServiceOptions.has(serviceOption.name)
                )}
            </div>
          </div>
        ))}
      </FormControl>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(SelectServiceOptions)
);
