import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import Moment from "moment";

import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import withStyles from "@mui/styles/withStyles";

import SelectAmount from "tap-io/client/components/common/SelectAmount";
import { voucherService } from "tap-io/client/services";
import { utilsHelper } from "tap-io/helpers";
import VoucherType from "tap-io/models/voucher/VoucherType";
import SelectPaymentMethod from "tap-io/client/components/payment/SelectPaymentMethod";

import withAuthorization from "../auth/withAuthorization";
import SelectCurrencyVoucherValue from "./SelectCurrencyVoucherValue";
import SelectProductVoucherValue from "./SelectProductVoucherValue";
import CapabilityUnavailableButton from "../subscription/CapabilityUnavailableButton";
import SelectDate from "tap-io/client/components/common/SelectDate";

const styles = (theme) => ({
  dialog: {
    //overflowY: "visible",
    minWidth: 500,
    minHeight: 550
  },
  content: {
    //overflowY: "visible"
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: 50
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 50
  },
  inputLabel: {
    width: "100%"
  },
  inputInfo: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 10
  },
  inputInfoIcon: {
    marginTop: -2,
    marginRight: 5
  },
  date: {
    marginRight: 2,
    fontWeight: "bold",
    textAlign: "right"
  },
  noTopMargin: {
    marginTop: -theme.spacing(2)
  },
  voucherValue: {
    margin: "-5px -10px",
    padding: "5px 10px",
    borderRadius: 4,
    backgroundColor: theme.palette.background.default
  },
  personalVoucher: {
    margin: "-5px -10px",
    padding: "5px 10px",
    borderRadius: 4,
    backgroundColor: theme.palette.background.default
  },
  spacing: {
    height: 20
  },
  disabled: {
    opacity: 0.4
  }
});

class GenerateVouchersDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false,
      voucherValidUntilMinDate: Moment().add(1, "day").toDate(),
      voucherLabel: "",
      customerEmail: "",
      paymentMethod: "",
      amountOfVouchers: 1,
      voucherMustBeRedeemedInOneGo: false,
      voucherType: VoucherType.CURRENCY,
      valuePerVoucher: null,
      voucherValidUntil: Moment().add(7, "days").endOf("day").toDate()
    };
  }

  handleAmountOfVouchersChange = (amountOfVouchers) => {
    this.setState({ amountOfVouchers });
  };

  handleVoucherValidUntilChange = (date) => {
    this.setState({ voucherValidUntil: date.endOf("day").toDate() });
  };

  handleCustomerEmailChange = (event) => {
    this.setState({ customerEmail: event.target.value });
  };

  handlePaymentMethodChange = (paymentMethod) => {
    this.setState({ paymentMethod });
  };

  handleVoucherMustBeRedeemedInOneGoChange = (event) => {
    this.setState({ voucherMustBeRedeemedInOneGo: event.target.checked });
  };

  handleVoucherTypeChange = (event) => {
    const voucherType = event.target.value;

    this.setState({
      voucherMustBeRedeemedInOneGo: false, //voucherType === VoucherType.PRODUCT,
      voucherType,
      valuePerVoucher: null
    });
  };

  handleValuePerVoucherChange = (valuePerVoucher) => {
    this.setState({ valuePerVoucher });
  };

  handleVoucherLabelChange = (event) => {
    this.setState({
      voucherLabel: event.target.value || ""
    });
  };

  handleGenerateVouchers = async () => {
    const { t, auth, bar, onClose } = this.props;
    const {
      amountOfVouchers,
      voucherValidUntil,
      voucherLabel,
      customerEmail,
      paymentMethod,
      voucherMustBeRedeemedInOneGo,
      voucherType,
      valuePerVoucher
    } = this.state;

    let parsedCustomerEmail;
    if (customerEmail) {
      parsedCustomerEmail = utilsHelper.parseEmail(customerEmail);

      if (!parsedCustomerEmail) {
        return toast.error(t("error.customer-email-is-not-valid"));
      }
    }

    if (!auth || !auth.user || !auth.user.uid) {
      return toast.error(
        `${t("label.error")}: ${t("error.no-valid-user-found")}`
      );
    }

    const toastId = toast(`${t("voucher.generate-vouchers")}...`, {
      autoClose: false
    });
    this.setState({ isDisabled: true });

    try {
      const parsedAmountOfVouchers = parseInt(amountOfVouchers);
      if (isNaN(parsedAmountOfVouchers) || parsedAmountOfVouchers <= 0) {
        throw new Error("error.amount-of-vouchers-is-invalid");
      }

      const parsedVoucherValidUntil = Moment(voucherValidUntil);
      if (
        !parsedVoucherValidUntil.isValid() ||
        Moment().diff(parsedVoucherValidUntil, "days") >= 0
      ) {
        throw new Error("error.voucher-valid-until-is-invalid");
      }

      let parsedValuePerVoucher;
      switch (voucherType) {
        case VoucherType.CURRENCY:
          parsedValuePerVoucher = parseFloat(valuePerVoucher);
          if (isNaN(parsedValuePerVoucher) || parsedValuePerVoucher <= 0) {
            throw new Error("error.value-per-voucher-is-invalid");
          }
          break;
        case VoucherType.PRODUCT:
          if (!valuePerVoucher || valuePerVoucher.length === 0) {
            throw new Error("error.value-per-voucher-is-invalid");
          }
          parsedValuePerVoucher = valuePerVoucher.map((entry) => {
            return {
              productIds: [entry.productId],
              discountPercentage: entry.discountPercentage,
              amount: entry.amount
            };
          });
          break;
      }

      const data = await voucherService.generateVouchers(
        auth.user.uid,
        bar,
        parsedAmountOfVouchers,
        parsedVoucherValidUntil.toDate(),
        voucherMustBeRedeemedInOneGo === true,
        voucherType,
        parsedValuePerVoucher,
        voucherLabel,
        undefined,
        parsedCustomerEmail,
        paymentMethod ? paymentMethod : undefined
      );

      toast.update(toastId, {
        render: t("voucher.vouchers-generated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false }, () => {
        onClose(data && data.voucherIds ? data.voucherIds : []);
      });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.message})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleClose = (event) => {
    // Prevent event from being passed down as property of onClose (must be undefined or voucherIds)
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { classes, t, isOpen, bar, subscription, catalogue, allMenus } =
      this.props;
    const {
      isDisabled,
      voucherValidUntilMinDate,
      customerEmail,
      paymentMethod,
      amountOfVouchers,
      voucherMustBeRedeemedInOneGo,
      voucherType,
      voucherLabel,
      valuePerVoucher,
      voucherValidUntil
    } = this.state;

    const arePersonalVouchersAvailable =
      subscription &&
      subscription.capabilities &&
      subscription.capabilities.arePersonalVouchersAvailable();

    return (
      <Dialog
        open={isOpen}
        onClose={this.handleClose}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle>{t("voucher.generate-vouchers")}</DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText>
            {t("voucher.generate-vouchers-below")}
          </DialogContentText>
          <div className={classes.inputContainer}>
            <Typography className={classes.inputLabel}>
              {t("voucher.voucher-label")}
            </Typography>
            <TextField
              fullWidth
              variant="standard"
              placeholder={t("voucher.voucher-label-example")}
              InputProps={{
                disableUnderline: true
              }}
              inputProps={{ style: { textAlign: "right" } }}
              InputLabelProps={{ shrink: false }}
              value={voucherLabel}
              onChange={this.handleVoucherLabelChange}
            />
          </div>
          <div className={classes.inputContainer}>
            <Typography className={classes.inputLabel}>
              {t("voucher.amount-of-vouchers")}
            </Typography>
            <SelectAmount
              isDisabled={isDisabled}
              allowInput={true}
              min={1}
              max={200}
              step={1}
              amount={amountOfVouchers}
              onChange={this.handleAmountOfVouchersChange}
            />
          </div>
          <div className={classes.inputContainer}>
            <Typography className={classes.inputLabel}>
              {t("voucher.voucher-valid-until")}
            </Typography>
            <SelectDate
              isDisabled={isDisabled}
              className={classes.date}
              value={voucherValidUntil}
              onChange={this.handleVoucherValidUntilChange}
              minDate={voucherValidUntilMinDate}
            />
          </div>
          <div className={classes.inputContainer}>
            <Typography className={classes.inputLabel}>
              {t("voucher.voucher-type")}
            </Typography>
            <TextField
              select
              fullWidth
              variant="standard"
              className={voucherType ? undefined : classes.noTopMargin}
              label={voucherType ? undefined : t("label.select")}
              InputProps={{
                disableUnderline: true,
                style: { textAlign: "right" }
              }}
              InputLabelProps={{ shrink: false }}
              value={voucherType}
              onChange={this.handleVoucherTypeChange}
            >
              <MenuItem value={VoucherType.CURRENCY}>
                {t("voucher.currency-voucher")}
              </MenuItem>
              <MenuItem value={VoucherType.PRODUCT}>
                {t("voucher.product-voucher")}
              </MenuItem>
            </TextField>
          </div>
          {false && voucherType === VoucherType.PRODUCT && (
            <div className={classes.inputContainer}>
              <Typography className={classes.inputLabel}>
                {t("voucher.voucher-must-be-redeemed-in-one-go")}
              </Typography>
              <Switch
                edge="end"
                disabled={isDisabled}
                checked={voucherMustBeRedeemedInOneGo}
                onChange={this.handleVoucherMustBeRedeemedInOneGoChange}
              />
            </div>
          )}
          {voucherType && (
            <div className={classes.voucherValue}>
              {voucherType === VoucherType.CURRENCY ? (
                <SelectCurrencyVoucherValue
                  isDisabled={isDisabled}
                  bar={bar}
                  value={valuePerVoucher}
                  onChange={this.handleValuePerVoucherChange}
                />
              ) : voucherType === VoucherType.PRODUCT ? (
                <SelectProductVoucherValue
                  isDisabled={isDisabled}
                  bar={bar}
                  catalogue={catalogue}
                  allMenus={allMenus}
                  value={valuePerVoucher}
                  onChange={this.handleValuePerVoucherChange}
                />
              ) : null}
            </div>
          )}
          <div className={classes.spacing} />
          <div className={classes.personalVoucher}>
            <div
              className={
                arePersonalVouchersAvailable ? undefined : classes.disabled
              }
            >
              <div className={classes.inputContainer}>
                <Typography className={classes.inputLabel}>
                  {`${t("voucher.customer-email")} (${t("label.optional")})`}
                </Typography>
                <TextField
                  error={
                    customerEmail && !utilsHelper.parseEmail(customerEmail)
                      ? true
                      : false
                  }
                  value={customerEmail}
                  onChange={this.handleCustomerEmailChange}
                  disabled={isDisabled || !arePersonalVouchersAvailable}
                  variant="standard"
                  margin="none"
                  fullWidth
                />
              </div>
              <div className={classes.inputInfo}>
                <InfoIcon fontSize="small" className={classes.inputInfoIcon} />
                <Typography variant="caption">
                  {t("voucher.confirmation-will-be-sent-to-this-email-address")}
                </Typography>
              </div>
              <div className={classes.inputContainer}>
                <Typography className={classes.inputLabel}>
                  {`${t("voucher.payment-method")} (${t("label.optional")})`}
                </Typography>
                <SelectPaymentMethod
                  isDisabled={isDisabled || !arePersonalVouchersAvailable}
                  addEmptyOption={true}
                  paymentMethod={paymentMethod}
                  onChange={this.handlePaymentMethodChange}
                />
              </div>
            </div>
            {!arePersonalVouchersAvailable && (
              <div className={classes.buttonContainer}>
                <CapabilityUnavailableButton
                  bar={bar}
                  subscription={subscription}
                  label={t(
                    "voucher.personal-vouchers-available-in-other-plans"
                  )}
                />
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isDisabled}
            onClick={this.handleClose}
            color="secondary"
          >
            {t("label.cancel")}
          </Button>
          <Button
            disabled={isDisabled}
            onClick={this.handleGenerateVouchers}
            color="primary"
          >
            {t("voucher.generate")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withAuthorization()(
  withStyles(styles)(withTranslation("common")(GenerateVouchersDialog))
);
