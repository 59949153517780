import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import withStyles from "@mui/styles/withStyles";

import PrintOrderDialog from "./PrintOrderDialog";
import OrderSubOrders from "tap-io/client/components/order/OrderSubOrders";
import SyncSubOrdersButton from "tap-io/client/components/order/SyncSubOrdersButton";
import OrderDeliveryLabel from "tap-io/client/components/order/OrderDeliveryLabel";
import OrderPayment from "tap-io/client/components/order/OrderPayment";
import OrderVouchers from "tap-io/client/components/order/OrderVouchers";
import OrderProgress from "./OrderProgress";
import OrderNote from "tap-io/client/components/order/OrderNote";
import OrderItems from "tap-io/client/components/order/OrderItems";
import OrderDeposits from "tap-io/client/components/order/OrderDeposits";
import OrderFees from "tap-io/client/components/order/OrderFees";
import OrderTip from "tap-io/client/components/order/OrderTip";
import OrderConfirmationCode from "tap-io/client/components/order/OrderConfirmationCode";
import ShowCompleteOrderButton from "tap-io/client/components/order/ShowCompleteOrderButton";
import ConfirmDeferredPaymentButton from "tap-io/client/components/order/ConfirmDeferredPaymentButton";

import withAuthorization from "../auth/withAuthorization";
import { hasNativeWrapper } from "../../native";
import ConfirmOverrideSubOrdersDialog from "./ConfirmOverrideSubOrdersDialog";
import OrderDeliveryIcon from "./OrderDeliveryIcon";
import OrderSequenceTimestampLabel from "./OrderSequenceTimestampLabel";
import OrderTotalLabel from "./OrderTotalLabel";
import OrderNameLabel from "./OrderNameLabel";
import OrderSequenceNumberLabel from "./OrderSequenceNumberLabel";
import PrintOrderButton from "./PrintOrderButton";
import OrderEstimatedPreparationDurationLabel from "./OrderEstimatedPreparationDurationLabel";

const styles = (theme) => ({
  accordion: {
    overflow: "hidden",
    "&:before": {
      display: "none"
    }
  },
  orderCard: {
    borderRadius: 4,
    backgroundColor: theme.palette.background.default
  },
  subOrders: {
    padding: theme.spacing(),
    borderRadius: 4,
    backgroundColor: theme.palette.background.default
  },
  subOrdersHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(0.5)
  },
  subOrdersHeaderTitle: {
    flexGrow: 1,
    fontWeight: "bold",
    textAlign: "left"
  },
  largeSpacing: {
    width: theme.spacing(1)
  },
  smallSpacing: {
    width: theme.spacing(0.5)
  },
  verticalSpacing: {
    height: theme.spacing(2)
  },
  buttons: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    margin: theme.spacing(0, 2)
  },
  multipleMenusWarning: {
    display: "flex",
    flexDirection: "column"
  },
  multipleMenusWarningDescription: {
    maxWidth: 200,
    marginTop: theme.spacing(0.5),
    color: theme.palette.warning.main
  }
});

class OrderAccordion extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isPrintOrderDialogOpen: false,
      overrideSubOrdersFunction: null,
      overrideSubOrdersMessage: null,
      infoMessage: null,
      isClaimingOrderStatus: false,
      isCompletingOrderStatus: false,
      isExpanded: !!props.isInitiallyOpen
    };
  }

  handlePrintOrderIfAvailable = () => {
    const { deviceSettings } = this.props;

    if (
      hasNativeWrapper() &&
      deviceSettings &&
      deviceSettings.printOrderWhenClaimed
    ) {
      this.setState({ isPrintOrderDialogOpen: true });
    }
  };

  handlePrintOrderDialogClose = () => {
    this.setState({ isPrintOrderDialogOpen: false });
  };

  handleOverrideSubOrders = (
    overrideSubOrdersFunction,
    overrideSubOrdersMessage
  ) => {
    this.setState({
      overrideSubOrdersFunction,
      overrideSubOrdersMessage: overrideSubOrdersMessage || null
    });
  };

  handleConfirmOverrideSubOrders = () => {
    const { overrideSubOrdersFunction } = this.state;

    this.setState({
      overrideSubOrdersFunction: null,
      overrideSubOrdersMessage: null
    });

    if (overrideSubOrdersFunction) {
      overrideSubOrdersFunction();
    }
  };

  handleCancelOverrideSubOrders = () => {
    this.setState({
      overrideSubOrdersFunction: null,
      overrideSubOrdersMessage: null
    });
  };

  handleStopPropagation = (event) => {
    event.stopPropagation();
  };

  handleIsExpandedChange = (event) => {
    const { isExpanded } = this.state;

    this.setState({ isExpanded: !isExpanded })
  }

  render() {
    const {
      classes,
      t,
      auth,
      bar,
      base,
      order,
      assets,
      deviceSettings,
      isDisabled,
      isInitiallyOpen
    } = this.props;
    const {
      isPrintOrderDialogOpen,
      overrideSubOrdersFunction,
      overrideSubOrdersMessage,
      isExpanded
    } = this.state;

    return (
      <>
        <PrintOrderDialog
          isOpen={isPrintOrderDialogOpen}
          onClose={this.handlePrintOrderDialogClose}
          bar={bar}
          base={base}
          order={order}
          assets={assets}
          deviceSettings={deviceSettings}
        />
        <ConfirmOverrideSubOrdersDialog
          isOpen={overrideSubOrdersFunction !== null}
          message={overrideSubOrdersMessage}
          onConfirm={this.handleConfirmOverrideSubOrders}
          onCancel={this.handleCancelOverrideSubOrders}
        />
        <Accordion
          className={classes.orderCard}
          classes={{
            root: classes.accordion
          }}
          //defaultExpanded={isInitiallyOpen}
          expanded={isExpanded}
          onChange={this.handleIsExpandedChange}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            disabled={isDisabled}
          >
            <OrderDeliveryIcon order={order} />
            <div className={classes.largeSpacing} />
            <OrderSequenceTimestampLabel
              bar={bar}
              order={order}
              format="L LT"
              width={160}
            />
            <OrderTotalLabel bar={bar} base={base} order={order} />
            <OrderNameLabel order={order} showInfoButton={true} />
            {order.hasBeenClaimed(base) && (
              <>
                <OrderDeliveryLabel
                  order={order}
                  base={base}
                  hideDeliveryMethod={false}
                  hideColorText={false}
                />
                <div className={classes.smallSpacing} />
                <OrderEstimatedPreparationDurationLabel
                  order={order}
                  bar={bar}
                  base={base}
                />
                <div className={classes.smallSpacing} />
                <OrderSequenceNumberLabel
                  order={order}
                  base={base}
                  isCompact={true}
                />
              </>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <OrderProgress base={base} order={order} />
            <div className={classes.verticalSpacing} />
            <OrderNote order={order} />
            <OrderItems base={base} order={order} />
            <OrderDeposits bar={bar} order={order} />
            {(!order.containsItemsFromMultipleMenus() || !base) && (
              <OrderFees order={order} />
            )}
            <OrderTip order={order} />
            <OrderPayment bar={bar} order={order} />
            <OrderVouchers order={order} />
            <OrderConfirmationCode order={order} />
            {order.hasSubOrders && (
              <div
                className={classes.subOrders}
                onClick={this.handleStopPropagation}
              >
                <div className={classes.subOrdersHeader}>
                  <Typography
                    variant="overline"
                    className={classes.subOrdersHeaderTitle}
                  >
                    {t("order.suborders")}
                  </Typography>
                  <SyncSubOrdersButton
                    isDisabled={isDisabled}
                    auth={auth}
                    bar={bar}
                    order={order}
                    askForStarnetCardIfNeeded={true}
                  />
                </div>
                <OrderSubOrders order={order} />
              </div>
            )}
            <div className={classes.buttons}>
              {base && order.containsItemsFromMultipleMenus() && (
                <div className={classes.multipleMenusWarning}>
                  <ShowCompleteOrderButton
                    isDisabled={isDisabled}
                    bar={bar}
                    order={order}
                    variant="contained"
                  />
                  <Typography
                    variant="caption"
                    className={classes.multipleMenusWarningDescription}
                  >
                    {t("order.part-of-order-prepared-at-other-location")}
                  </Typography>
                </div>
              )}
              <div className={classes.smallSpacing} />
              <PrintOrderButton
                bar={bar}
                base={base}
                order={order}
                assets={assets}
                deviceSettings={deviceSettings}
                isDisabled={isDisabled}
              />
              <div className={classes.smallSpacing} />
              <ConfirmDeferredPaymentButton
                auth={auth}
                bar={bar}
                order={order}
                isDisabled={isDisabled}
                fullWidth={false}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </>
    );
  }
}

export default withAuthorization()(
  withStyles(styles)(withTranslation("common")(OrderAccordion))
);
