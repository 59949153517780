import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";
import withStyles from "@mui/styles/withStyles";

import FeeConditionType from "tap-io/models/fee/FeeConditionType";

import EditFeeZoneCondition from "./EditFeeZoneCondition";
import { Button, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import AddFeeConditionMenu from "./AddFeeConditionMenu";
import EditFeeServiceOptionCondition from "./EditFeeServiceOptionCondition";
import CapabilityUnavailableButton from "../subscription/CapabilityUnavailableButton";

const styles = (theme) => ({
  header: {
    display: "flex",
    alignItems: "center"
  },
  title: {
    width: "100%",
    fontWeight: "bold"
  },
  capabilityButton: {
    marginTop: 10
  },
  condition: {
    margin: "10px -10px",
    padding: 10,
    borderRadius: 4,
    backgroundColor: theme.palette.background.default
  },
  conditionType: {},
  conditionContent: {
    padding: "5px 0"
  },
  removeButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  }
});

class EditFeeConditions extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      addFeeConditionMenuAnchor: null
    };
  }

  addCondition = (event) => {
    this.setState({ addFeeConditionMenuAnchor: event.currentTarget });
  };

  handleAddFeeConditionMenuClose = () => {
    this.setState({ addFeeConditionMenuAnchor: null });
  };

  handleConditionChange = (prevCondition) => (newCondition) => {
    const { conditions, onChange } = this.props;

    onChange(
      conditions.map((condition) =>
        condition === prevCondition ? newCondition : condition
      )
    );
  };

  handleAddCondition = (conditionToAdd) => {
    const { onChange, conditions } = this.props;

    onChange([...conditions, conditionToAdd]);
    this.handleAddFeeConditionMenuClose();
  };

  handleRemoveCondition = (conditionToRemove) => (event) => {
    const { conditions, onChange } = this.props;

    onChange(conditions.filter((condition) => condition !== conditionToRemove));
  };

  render() {
    const { t, classes, bar, subscription, conditions } = this.props;
    const { addFeeConditionMenuAnchor } = this.state;

    const availableFeeConditionTypes = subscription
      ? subscription.capabilities.getAvailableFeeConditionTypes()
      : [];

    const hasFeeConditionTypesAvailable = availableFeeConditionTypes.length > 0;

    return (
      <div>
        <AddFeeConditionMenu
          anchor={addFeeConditionMenuAnchor}
          onClose={this.handleAddFeeConditionMenuClose}
          onAdd={this.handleAddCondition}
          subscription={subscription}
          conditions={conditions}
        />
        <div>
          <div className={classes.header}>
            <Typography className={classes.title}>
              {t("fee.conditions")}
            </Typography>
            <IconButton
              disabled={!hasFeeConditionTypesAvailable}
              onClick={this.addCondition}
              size="large"
            >
              <AddIcon />
            </IconButton>
          </div>
          {hasFeeConditionTypesAvailable ? (
            conditions && conditions.length > 0 ? (
              conditions.map((condition, index) => (
                <div key={index} className={classes.condition}>
                  <Typography className={classes.conditionType}>
                    {t(`fee.${condition.type}`)}
                  </Typography>
                  <div className={classes.conditionContent}>
                    {condition.type === FeeConditionType.SERVICE_OPTION ? (
                      <EditFeeServiceOptionCondition
                        bar={bar}
                        subscription={subscription}
                        condition={condition}
                        onChange={this.handleConditionChange(condition)}
                      />
                    ) : condition.type === FeeConditionType.ZONE ? (
                      <EditFeeZoneCondition
                        condition={condition}
                        onChange={this.handleConditionChange(condition)}
                      />
                    ) : null}
                  </div>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.removeButton}
                    onClick={this.handleRemoveCondition(condition)}
                  >
                    {t("fee.remove-condition")}
                  </Button>
                </div>
              ))
            ) : (
              <Typography>{t("fee.no-conditions-found")}</Typography>
            )
          ) : (
            <div className={classes.capabilityButton}>
              <CapabilityUnavailableButton
                bar={bar}
                subscription={subscription}
                label={t("fee.fee-conditions-available-in-other-plans")}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditFeeConditions)
);
