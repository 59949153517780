import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button, List, ListItem, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { barService } from "tap-io/client/services";
import config from "tap-io/client/env";

const styles = (theme) => ({
  spacing: {
    width: 20
  },
  link: {
    textDecoration: "none"
  },
  center: {
    justifyContent: "center"
  }
});

class WelcomeStep extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { setOnNext } = this.props;
    setOnNext(() => {});

    this.refreshBarUrl();
  }

  componentDidUpdate(prevProps, prevState) {
    const { bar } = this.props;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;

    if (barId !== prevBarId) {
      this.refreshBarUrl();
    }
  }

  refreshBarUrl = async () => {
    const { bar } = this.props;

    const barUrl = bar
      ? await barService.getBarUrl(bar, config.hosting.orderDomain)
      : null;

    this.setState({ barUrl });
  };

  render() {
    const { classes, t, onNext } = this.props;
    const { barUrl } = this.state;

    return (
      <List>
        <ListItem>
          <Button variant="contained" onClick={onNext}>
            {t("setup.my-personal-dashboard")}
          </Button>
          <div className={classes.spacing}></div>
          {barUrl && (
            <a
              className={classes.link}
              href={barUrl}
              target="_blank"
              rel="noreferrer"
            >
              <Button variant="contained">{t("setup.my-order-page")}</Button>
            </a>
          )}
        </ListItem>
        <ListItem className={classes.center}>
          <a className={classes.link} href="mailto:support@watdrinkje.be">
            <Typography color="primary" variant="button">
              {t("setup.i-need-additional-support")}
            </Typography>
          </a>
        </ListItem>
      </List>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(WelcomeStep)
);
