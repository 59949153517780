import React from "react";
import withStyles from "@mui/styles/withStyles";

import { Switch } from "@mui/material";

import ContentCard from "tap-io/client/components/common/ContentCard";

const styles = (theme) => ({});

class SettingsCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}

  handleIsEnabledChange = (event) => {
    const { onIsEnabledChange } = this.props;

    const isEnabled = event.target.checked === true;

    onIsEnabledChange(isEnabled);
  };

  render() {
    const {
      isDisabled,
      isCardEnabled,
      hideCardEnabledToggle,
      title,
      description,
      children
    } = this.props;

    return (
      <ContentCard
        headerLeft={
          !hideCardEnabledToggle && (
            <Switch
              disabled={isDisabled}
              onChange={this.handleIsEnabledChange}
              checked={isCardEnabled}
            />
          )
        }
        title={title}
        description={description}
      >
        {children}
      </ContentCard>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SettingsCard);
