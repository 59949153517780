import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { List, ListSubheader, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { barService } from "tap-io/client/services";
import config from "tap-io/client/env";

import EditLogo from "../settings/EditLogo";
import EditColors from "../settings/EditColors";

const styles = (theme) => ({
  container: {},
  content: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  link: {
    textDecoration: "none",
    color: "black"
  },
  list: {
    width: 400
  }
});

class EditLookAndFeelStep extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { setOnNext } = this.props;
    setOnNext(() => {});

    this.refreshBarUrl();
  }

  componentDidUpdate(prevProps, prevState) {
    const { bar } = this.props;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;

    if (barId !== prevBarId) {
      this.refreshBarUrl();
    }
  }

  refreshBarUrl = async () => {
    const { bar } = this.props;

    let barUrl = null;

    if (bar) {
      barUrl = await barService.getBarUrl(bar, config.hosting.orderDomain);
    }

    this.setState({ barUrl });
  };

  render() {
    const { classes, t, bar, assets } = this.props;
    const { barUrl } = this.state;

    return (
      <div className={classes.container}>
        <a
          className={classes.link}
          href={barUrl}
          target="_blank"
          rel="noreferrer"
        >
          <Typography variant="button" className={classes.barUrl}>
            {t("setup.show-my-order-page")}
          </Typography>
        </a>
        <div className={classes.content}>
          <List className={classes.list}>
            <ListSubheader>{t("setup.logo")}</ListSubheader>
            <EditLogo bar={bar} url={assets?.logoImageUrl} />
          </List>
          <List className={classes.list}>
            <ListSubheader>{t("setup.theme-colors")}</ListSubheader>
            <EditColors bar={bar} />
          </List>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditLookAndFeelStep)
);
