import React, { PureComponent } from "react";

import { IconButton, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import withStyles from "@mui/styles/withStyles";

import ShowTipDialog from "./ShowTipDialog";

const styles = (theme) => ({
  content: {
    cursor: "pointer"
  },
  text: {
    display: "inline",
    verticalAlign: "middle"
  },
  button: {
    display: "inline-block",
    verticalAlign: "middle",
    marginLeft: -6,
    marginTop: -12,
    marginBottom: -12,
    fontSize: 20
  }
});

class ShowTip extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isShowTipDialogOpen: false
    };
  }

  handleShowTip = () => {
    this.setState({ isShowTipDialogOpen: true });
  };

  handleShowTipDialogClose = () => {
    this.setState({ isShowTipDialogOpen: false });
  };

  render() {
    const {
      classes,
      label,
      variant,
      tipTitle,
      tipIcon,
      tipDescription,
      tipImage
    } = this.props;
    const { isShowTipDialogOpen } = this.state;

    return (
      <div>
        <ShowTipDialog
          isOpen={isShowTipDialogOpen}
          onClose={this.handleShowTipDialogClose}
          title={tipTitle}
          icon={tipIcon}
          description={tipDescription}
          image={tipImage}
        />
        <div onClick={this.handleShowTip} className={classes.content}>
          <Typography variant={variant} className={classes.text}>
            {label}
          </Typography>
          <IconButton color="secondary" className={classes.button} size="large">
            <InfoIcon fontSize="inherit" />
          </IconButton>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ShowTip);
