import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Button } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { subscriptionService } from "tap-io/client/services";

import withAuthorization from "../auth/withAuthorization";

const styles = (theme) => ({});

class RequestPlanRenewalButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleRequestPlanRenewal = async () => {
    const { t, auth, bar, subscription } = this.props;

    if (!auth || !auth.user || !auth.user.uid) {
      return toast.error(
        `${t("label.error")}: ${t("error.no-valid-user-found")}`
      );
    }

    this.setIsBusy(true);
    const toastId = toast(
      t("subscription.requesting-plan-x-renewal", {
        planName: t(`subscription.plan-${subscription.planName}`)
      }),
      {
        autoClose: false
      }
    );

    try {
      await subscriptionService.requestPlanRenewal(
        auth.user.uid,
        bar,
        (data) => {
          console.log("progress", data);
        }
      );

      toast.update(toastId, {
        render: t("subscription.plan-x-renewal-requested", {
          planName: t(`subscription.plan-${subscription.planName}`)
        }),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setIsBusy(false);
    } catch (error) {
      console.warn(error);

      this.setIsBusy(false);
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.message})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  setIsBusy = (isBusy) => {
    const { onBusy } = this.props;

    this.setState({ isDisabled: isBusy });

    if (onBusy) {
      onBusy(isBusy);
    }
  };

  render() {
    const { classes, t, subscription } = this.props;

    const isDisabled = this.props.isDisabled || this.state.isDisabled;

    return subscriptionService.isSubscriptionAboutToExpire(subscription) ? (
      <Button
        color="primary"
        variant="contained"
        size="large"
        disabled={isDisabled}
        onClick={this.handleRequestPlanRenewal}
      >
        {t("subscription.request-plan-x-renewal", {
          planName: t(`subscription.plan-${subscription.planName}`)
        })}
      </Button>
    ) : null;
  }
}

export default withAuthorization()(
  withStyles(styles)(withTranslation("common")(RequestPlanRenewalButton))
);
