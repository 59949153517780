import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import { PLAN_BASIC, PLAN_FULL_SERVICE, PLAN_PRO } from "../../constants/plans";
import ShowBasesTip from "../help/ShowBasesTip";
import ShowZonesTip from "../help/ShowZonesTip";
import RequestPlanDialog from ".//RequestPlanDialog";
import Plan from "./Plan";
import PlanFeature from "./PlanFeature";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  plan: {
    display: "flex",
    width: 340,
    padding: 5
  }
});

class SelectPlan extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false,
      nameOfPlanBeingRequested: null
    };
  }

  handleChoosePlan = (plan) => async (event) => {
    const { onSelect } = this.props;

    this.setState({ isDisabled: true });

    try {
      this.setState({ isDisabled: false });

      onSelect(plan);
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: true });
    }
  };

  handleRequestPlan = (plan) => (event) => {
    this.setState({ nameOfPlanBeingRequested: plan });
  };

  handleRequestPlanDialogClose = () => {
    this.setState({ nameOfPlanBeingRequested: null });
  };

  render() {
    const { classes, t, onSelect, bar, subscription, recommendedPlan } =
      this.props;
    const { nameOfPlanBeingRequested } = this.state;

    const currentPlanName = subscription ? subscription.planName : undefined;
    const requestedPlanName =
      subscription && subscription.isRequested()
        ? subscription.planName
        : undefined;

    return (
      <div className={classes.container}>
        <RequestPlanDialog
          isOpen={nameOfPlanBeingRequested !== null}
          onSuccess={onSelect}
          onCancel={this.handleRequestPlanDialogClose}
          bar={bar}
          subscription={subscription}
          nameOfPlanToRequest={nameOfPlanBeingRequested}
        />
        <div className={classes.plan}>
          <Plan
            isDisabled={
              currentPlanName !== PLAN_BASIC ||
              (requestedPlanName && requestedPlanName !== PLAN_BASIC)
            }
            isRecommended={recommendedPlan === PLAN_BASIC}
            isActive={currentPlanName === PLAN_BASIC}
            isRequested={requestedPlanName === PLAN_BASIC}
            onClick={this.handleChoosePlan(PLAN_BASIC)}
            planName={t("subscription.plan-basic")}
            planLink="https://beyondordering.com/nl/functionalities/#basic"
            features={
              <div>
                <PlanFeature
                  feature={t("subscription.plan-basic-description-1")}
                />
                <PlanFeature
                  feature={t("subscription.plan-basic-description-2")}
                />
                <PlanFeature
                  feature={t("subscription.plan-basic-description-3")}
                />
                <PlanFeature
                  feature={t("subscription.plan-basic-description-4")}
                />
                <PlanFeature
                  feature={t("subscription.plan-basic-description-5")}
                />
                <PlanFeature
                  feature={t("subscription.plan-basic-description-6")}
                />
                <PlanFeature
                  feature={t("subscription.plan-basic-description-7")}
                />
                <PlanFeature
                  feature={t("subscription.plan-basic-description-8")}
                />
                <PlanFeature
                  feature={t("subscription.plan-basic-description-9")}
                />
                <PlanFeature
                  feature={t("subscription.plan-basic-description-10")}
                />
                <PlanFeature
                  feature={t("subscription.plan-basic-description-11")}
                />
                <PlanFeature
                  feature={t("subscription.plan-basic-description-12")}
                />
                <PlanFeature
                  feature={t("subscription.plan-basic-description-13")}
                />
                <PlanFeature
                  feature={t("subscription.plan-basic-description-14")}
                />
              </div>
            }
          />
        </div>
        <div className={classes.plan}>
          <Plan
            isDisabled={
              currentPlanName === PLAN_FULL_SERVICE ||
              (requestedPlanName && requestedPlanName !== PLAN_PRO)
            }
            isRecommended={recommendedPlan === PLAN_PRO}
            isActive={currentPlanName === PLAN_PRO}
            isRequested={requestedPlanName === PLAN_PRO}
            onClick={this.handleRequestPlan(PLAN_PRO)}
            planName={t("subscription.plan-pro")}
            planLink="https://beyondordering.com/nl/functionalities/#pro"
            features={
              <div>
                <PlanFeature
                  feature={t("subscription.plan-pro-description-1")}
                />
                <PlanFeature
                  feature={
                    <ShowBasesTip
                      variant="body2"
                      label={t("subscription.plan-pro-description-2")}
                    />
                  }
                />
                <PlanFeature
                  feature={
                    <ShowZonesTip
                      variant="body2"
                      label={t("subscription.plan-pro-description-3")}
                    />
                  }
                />
                <PlanFeature
                  feature={t("subscription.plan-pro-description-4")}
                />
                <PlanFeature
                  feature={t("subscription.plan-pro-description-5")}
                />
                <PlanFeature
                  feature={t("subscription.plan-pro-description-6")}
                />
                <PlanFeature
                  feature={t("subscription.plan-pro-description-7")}
                />
                <PlanFeature
                  feature={t("subscription.plan-pro-description-8")}
                />
                <PlanFeature
                  feature={t("subscription.plan-pro-description-9")}
                />
                <PlanFeature
                  feature={t("subscription.plan-pro-description-10")}
                />
                <PlanFeature
                  feature={t("subscription.plan-pro-description-11")}
                />
              </div>
            }
          />
        </div>
        <div className={classes.plan}>
          <Plan
            isDisabled={
              requestedPlanName && requestedPlanName !== PLAN_FULL_SERVICE
            }
            isRecommended={recommendedPlan === PLAN_FULL_SERVICE}
            isRequested={requestedPlanName === PLAN_FULL_SERVICE}
            isActive={currentPlanName === PLAN_FULL_SERVICE}
            onClick={this.handleRequestPlan(PLAN_FULL_SERVICE)}
            planName={t("subscription.plan-full-service")}
            planLink="https://beyondordering.com/nl/functionalities/#full-service"
            features={
              <div>
                <PlanFeature
                  feature={t("subscription.plan-full-service-description-1")}
                />
                <PlanFeature
                  feature={
                    <ShowBasesTip
                      variant="body2"
                      label={t("subscription.plan-full-service-description-2")}
                    />
                  }
                />
                <PlanFeature
                  feature={
                    <ShowZonesTip
                      variant="body2"
                      label={t("subscription.plan-full-service-description-3")}
                    />
                  }
                />
                <PlanFeature
                  feature={t("subscription.plan-full-service-description-4")}
                />
                <PlanFeature
                  feature={t("subscription.plan-full-service-description-5")}
                />
                <PlanFeature
                  feature={t("subscription.plan-full-service-description-6")}
                />
                <PlanFeature
                  feature={t("subscription.plan-full-service-description-7")}
                />
                <PlanFeature
                  feature={t("subscription.plan-full-service-description-8")}
                />
                <PlanFeature
                  feature={t("subscription.plan-full-service-description-9")}
                />
              </div>
            }
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(SelectPlan)
);
