import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { barService } from "tap-io/client/services";

import withAuthorization from "../auth/withAuthorization";
import { barHelper } from "tap-io/helpers";
import config from "tap-io/client/env";

const styles = (theme) => ({
  dialog: {
    minWidth: 300
  }
});

class EditBarLocatorDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = () => {
    return {
      isDisabled: false,
      locator: ""
    };
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isOpen } = this.props;
    const prevIsOpen = prevProps.isOpen;

    if (isOpen && !prevIsOpen) {
      this.setState(this.initialState());
    }
  }

  handleLocatorChange = (event) => {
    this.setState({ locator: barHelper.cleanLocator(event.target.value) });
  };

  handleConfirm = async () => {
    const { t, auth, bar, onClose } = this.props;
    const { locator } = this.state;

    const toastId = toast(t("mgmt.updating-locator-for-bar"), {
      autoClose: false
    });
    this.setState({ isDisabled: true });

    try {
      await barService.updateBarLocator(
        config.functions.api,
        auth.user,
        bar.id,
        locator
      );

      toast.update(toastId, {
        render: t("mgmt.bar-locator-has-been-updated"),
        type: toast.TYPE.INFO,
        autoClose: 5000
      });

      this.setState({ isDisabled: false }, onClose);
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(
          error ? error.message : "error.unknown-error"
        )})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, isOpen, onClose, bar, balance } = this.props;
    const { isDisabled, locator } = this.state;

    return (
      <div>
        <Dialog open={isOpen} onClose={onClose} className={classes.dialog}>
          <DialogTitle>{t("bar.edit-locator")}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t("bar.edit-locator-below")}</DialogContentText>
            <TextField
              variant="standard"
              margin="dense"
              label={t("bar.locator")}
              fullWidth
              value={locator}
              disabled={isDisabled}
              onChange={this.handleLocatorChange}
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={isDisabled} onClick={onClose} color="secondary">
              {t("label.cancel")}
            </Button>
            <Button
              disabled={isDisabled}
              onClick={this.handleConfirm}
              color="primary"
            >
              {t("label.confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withAuthorization()(
  withStyles(styles)(withTranslation("common")(EditBarLocatorDialog))
);
