import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import SelectAmount from "tap-io/client/components/common/SelectAmount";
import { baseService } from "tap-io/client/services";

const styles = (theme) => ({
  dialog: {
    minWidth: 500
  },
  inputContainer: {
    display: "flex",
    alignItems: "center"
  },
  inputLabel: {
    width: "100%"
  }
});

class AddBasePreparationOptionDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      duration: 5
    };
  }

  handleDurationChange = (duration) => {
    this.setState({ duration });
  };

  handleAddPreparationOption = () => {
    const { onClose } = this.props;
    const { duration } = this.state;

    onClose(baseService.createBasePreparationOption({ duration }));
  };

  handleClose = () => {
    const { onClose } = this.props;

    onClose();
  };

  render() {
    const { classes, t, isOpen, isDisabled } = this.props;
    const { duration } = this.state;

    return (
      <Dialog
        onClose={this.handleClose}
        open={isOpen}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle>{t("base.add-preparation-option")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("base.add-a-new-preparation-option-below")}
          </DialogContentText>
          <div className={classes.inputContainer}>
            <Typography className={classes.inputLabel}>
              {t("base.preparation-option-duration")}
            </Typography>
            <SelectAmount
              isDisabled={isDisabled}
              allowInput={true}
              min={1}
              max={120}
              step={1}
              amount={duration}
              onChange={this.handleDurationChange}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="secondary">
            {t("label.cancel")}
          </Button>
          <Button onClick={this.handleAddPreparationOption} color="primary">
            {t("base.add-preparation-option")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(AddBasePreparationOptionDialog)
);
