import { Card, CardContent, CardHeader, useTheme } from "@mui/material";
import { ReactNode } from "react";

export type StatCardProps = {
  title?: string;
  description?: string;
  action?: ReactNode;
  filters?: ReactNode;
  children?: ReactNode;
  derivatives?: ReactNode;
};

export function StatCard(props: StatCardProps) {
  const theme = useTheme();
  const { title, description, action, filters, children, derivatives } = props;

  return (
    <Card
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        minWidth: 400
      }}
    >
      <CardHeader
        title={title}
        titleTypographyProps={{
          align: "left",
          fontWeight: "bold",
          fontSize: "1.25em"
        }}
        subheader={description?.length !== 0 ? description : undefined}
        subheaderTypographyProps={{
          align: "left",
          fontSize: "0.75em"
        }}
        action={action}
      />
      {filters && (
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing()
          }}
        >
          {filters}
        </CardContent>
      )}
      {children && (
        <CardContent
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing()
          }}
        >
          {children}
        </CardContent>
      )}
      {derivatives && (
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing()
          }}
        >
          {derivatives}
        </CardContent>
      )}
    </Card>
  );
}
