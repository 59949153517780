import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { List, ListItem, ListItemText, Switch } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { deviceStorage } from "tap-io/storage";

const styles = (theme) => ({
  buttons: {
    justifyContent: "center"
  }
});

class EditDeviceSettings extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (deviceSettings) => {
    return {
      deviceSettings: {
        isMuted: deviceSettings ? deviceSettings.isMuted === true : false,
        maxSelectedOrders:
          deviceSettings && deviceSettings.maxSelectedOrders
            ? deviceSettings.maxSelectedOrders
            : 3
      }
    };
  };

  componentDidMount() {
    const { deviceSettings } = this.props;

    this.setState(this.initialState(deviceSettings));
  }

  componentDidUpdate(prevProps, prevState) {
    const { deviceSettings } = this.props;

    // Update to isMuted (from Settings menu)
    const isMuted = deviceSettings ? deviceSettings.isMuted : undefined;
    const prevIsMuted = prevProps.deviceSettings
      ? prevProps.deviceSettings.isMuted
      : undefined;
    if (isMuted !== prevIsMuted) {
      this.setState({
        deviceSettings: {
          ...deviceSettings,
          isMuted: isMuted === true
        }
      });
    }
  }

  handlePlaySoundChange = (event) => {
    const { deviceSettings } = this.state;

    this.setState({
      deviceSettings: {
        ...deviceSettings,
        isMuted: event.target.checked !== true
      }
    });

    // Automatically save
    this.requestSave();
  };

  handleMaxSelectedOrdersChange = (amount) => {
    const { deviceSettings } = this.state;

    this.setState({
      deviceSettings: {
        ...deviceSettings,
        maxSelectedOrders: amount
      }
    });

    // Automatically save
    this.requestSave();
  };

  requestSave = () => {
    if (this.saveTimeout) {
      clearTimeout(this.saveTimeout);
    }

    this.saveTimeout = setTimeout(() => {
      this.saveTimeout = undefined;
      this.handleSave();
    }, 1000);
  };

  handleSave = async () => {
    const { t, bar } = this.props;
    const { deviceSettings } = this.state;

    const toastId = toast(t("settings.save-settings"), { autoClose: false });

    try {
      await deviceStorage.updateDeviceSettings(bar.id, deviceSettings);

      toast.update(toastId, {
        render: t("settings.settings-saved"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { t } = this.props;
    const { deviceSettings } = this.state;

    const { isMuted, maxSelectedOrders } = deviceSettings;

    return (
      <div>
        <List>
          <ListItem>
            <ListItemText
              primary={t("settings.device-play-sound-label")}
              secondary={t("settings.device-play-sound-description")}
            />
            <Switch onChange={this.handlePlaySoundChange} checked={!isMuted} />
          </ListItem>
        </List>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditDeviceSettings)
);

/*
<ListItem>
  <ListItemText
    primary={t("settings.device-max-selected-orders-label")}
    secondary={t("settings.device-max-selected-orders-description")}
  />
  <SelectAmount
    min={2}
    max={20}
    amount={maxSelectedOrders}
    onChange={this.handleMaxSelectedOrdersChange}
  />
</ListItem>
<ListItem className={classes.buttons}>
  <Button
    color="primary"
    className={classes.button}
    onClick={this.handleSave}
  >
    Bewaren
  </Button>
  <Button
    color="secondary"
    className={classes.button}
    onClick={this.handleCancel}
  >
    Annuleren
  </Button>
</ListItem>
*/
