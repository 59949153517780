import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Switch,
  TextField
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { barHelper, utilsHelper } from "tap-io/helpers";
import { barService } from "tap-io/client/services";

import PaymentProvider from "tap-io/models/payment/PaymentProvider";

import SignUpForMollie from "../payment/SignUpForMollie";
import ConfirmDeactivatePaymentProvider from "../payment/ConfirmDeactivatePaymentProvider";

const styles = (theme) => ({
  accentuate: {
    color: "red"
  },
  buttons: {
    justifyContent: "flex-end"
  },
  link: {
    textDecoration: "none"
  }
});

class EditMollie extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (conf, isReady) => {
    const mergedConf = {
      profileId: conf && conf.profileId ? conf.profileId : "",
      apiKey: conf && conf.apiKey ? conf.apiKey : ""
    };

    return {
      isConfirmDeactivateMolliePaymentsDialogOpen: false,
      isReady: isReady === true,
      isValid: barHelper.isValidBarMolliePaymentProviderConf(conf),
      isDisabled: false,
      isAPIKeyRevealed: false,
      conf: mergedConf,
      savedConf: mergedConf
    };
  };

  initConf = () => {
    const { bar } = this.props;

    if (this.unsubscribeMollieConf) {
      this.unsubscribeMollieConf();
      this.unsubscribeMollieConf = undefined;
    }

    this.unsubscribeMollieConf = barService.onBarMolliePaymentProviderConf(
      bar,
      (conf) => {
        this.setState(this.initialState(conf, true));
      }
    );
  };

  componentDidMount() {
    const { setOnSave } = this.props;

    if (setOnSave) {
      setOnSave(this.handleSaveConf);
    }

    this.initConf();
  }

  componentDidUpdate(prevProps, prevState) {
    const { bar } = this.props;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;

    if (barId !== prevBarId) {
      this.initConf();
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeMollieConf) {
      this.unsubscribeMollieConf();
      this.unsubscribeMollieConf = undefined;
    }
  }

  handleChange = (key) => (event) => {
    const { conf } = this.state;
    const val = event.target.value;

    const newConf = { ...conf, [key]: val };
    const isValid = barHelper.isValidBarMolliePaymentProviderConf(newConf);

    this.setState({ isValid, conf: newConf });
  };

  handleRevealAPIKey = (event) => {
    this.setState({ isAPIKeyRevealed: true });
  };

  handleObfuscateAPIKey = (event) => {
    this.setState({ isAPIKeyRevealed: false });
  };

  hasConfChanged = () => {
    const { conf, savedConf } = this.state;

    return !utilsHelper.areObjectsEqualShallow(conf, savedConf);
  };

  handleSaveConf = async () => {
    const { t, bar } = this.props;
    const { conf } = this.state;

    const toastId = toast(t("settings.saving-mollie-configuration"), {
      autoClose: false
    });
    this.setState({ isDisabled: true });

    try {
      await barService.setBarMolliePaymentProviderConf(bar, conf);

      toast.update(toastId, {
        render: t("settings.mollie-configuration-saved"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
      this.setState({ isDisabled: false });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleRestoreConf = () => {
    this.initConf();
  };

  handleAllowMolliePayments = async (event) => {
    const allowMolliePayments = event.target.checked === true;

    if (allowMolliePayments) {
      this.updateAllowMolliePayments(allowMolliePayments);
    } else {
      this.setState({ isConfirmDeactivateMolliePaymentsDialogOpen: true });
    }
  };

  handleConfirmDeactivateMolliePayments = () => {
    this.setState({ isConfirmDeactivateMolliePaymentsDialogOpen: false });

    this.updateAllowMolliePayments(false);
  };

  handleCancelDeactivateMolliePayments = () => {
    this.setState({ isConfirmDeactivateMolliePaymentsDialogOpen: false });
  };

  updateAllowMolliePayments = async (allowMolliePayments) => {
    const { t, bar } = this.props;

    if (allowMolliePayments) {
      await this.handleSaveConf();
    }

    const toastId = toast(
      allowMolliePayments
        ? t("settings.enabling-mollie-payments")
        : t("settings.disabling-mollie-payments"),
      { autoClose: false }
    );
    this.setState({ isDisabled: true });

    try {
      await barService.setBarMolliePaymentProviderIsActive(
        bar,
        allowMolliePayments
      );

      toast.update(toastId, {
        render: allowMolliePayments
          ? t("settings.mollie-payments-enabled")
          : t("settings.mollie-payments-disabled"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, bar, setOnSave, showSignUp } = this.props;
    const {
      isConfirmDeactivateMolliePaymentsDialogOpen,
      isReady,
      isValid,
      isDisabled,
      isAPIKeyRevealed,
      conf
    } = this.state;
    const { profileId, apiKey } = conf;

    const allowMolliePayments = bar.isAllowingMolliePayments();

    return (
      <div>
        <ConfirmDeactivatePaymentProvider
          isOpen={isConfirmDeactivateMolliePaymentsDialogOpen}
          paymentProvider={PaymentProvider.MOLLIE}
          onConfirm={this.handleConfirmDeactivateMolliePayments}
          onCancel={this.handleCancelDeactivateMolliePayments}
        />
        {isReady ? (
          <List>
            <ListItem>
              <ListItemText
                primary={t("settings.mollie-payments-label")}
                secondary={
                  !isValid && (
                    <span className={classes.accentuate}>
                      {t(
                        "settings.complete-configuration-below-to-enable-payments"
                      )}
                    </span>
                  )
                }
              />
              <Switch
                onChange={this.handleAllowMolliePayments}
                checked={allowMolliePayments}
                disabled={isDisabled || (!isValid && !allowMolliePayments)}
              />
            </ListItem>
            {allowMolliePayments && (
              <ListItem>
                <ListItemText
                  primary={t("settings.problems-with-mollie-online-payments")}
                  secondary={
                    <a
                      className={classes.link}
                      href="https://status.mollie.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("settings.check-mollie-status-page")}
                    </a>
                  }
                />
              </ListItem>
            )}
            {showSignUp && !isValid && (
              <ListItem>
                <SignUpForMollie />
              </ListItem>
            )}
            <ListItem>
              <TextField
                variant="standard"
                margin="dense"
                label={t("settings.mollie-profile-label")}
                helperText={t("settings.mollie-profile-id-description")}
                fullWidth
                value={profileId}
                onChange={this.handleChange("profileId")}
                disabled={isDisabled || allowMolliePayments}
                autoComplete="new-password"
                required
              />
            </ListItem>
            <ListItem>
              <TextField
                variant="standard"
                margin="dense"
                label={t("settings.mollie-live-api-key-label")}
                helperText={t("settings.mollie-live-api-key-description")}
                fullWidth
                type={isAPIKeyRevealed ? undefined : "password"}
                value={apiKey}
                onChange={this.handleChange("apiKey")}
                onFocus={this.handleRevealAPIKey}
                onBlur={this.handleObfuscateAPIKey}
                disabled={isDisabled || allowMolliePayments}
                autoComplete="new-password"
                required
              />
            </ListItem>
            {!allowMolliePayments && !setOnSave && (
              <ListItem className={classes.buttons}>
                {this.hasConfChanged() && (
                  <Button
                    color="secondary"
                    className={classes.button}
                    disabled={isDisabled}
                    onClick={this.handleRestoreConf}
                  >
                    {t("label.restore")}
                  </Button>
                )}
                <Button
                  color="primary"
                  className={classes.button}
                  disabled={isDisabled}
                  onClick={this.handleSaveConf}
                >
                  {t("label.save")}
                </Button>
              </ListItem>
            )}
          </List>
        ) : (
          <CircularProgress />
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditMollie)
);
