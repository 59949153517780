import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import InfoMessage from "tap-io/client/components/common/InfoMessage";

import { CACHE_KEY_ALL_ORDERS } from "../../constants/cache";

const styles = (theme) => ({});

class AllOrdersInfoMessage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { bar, t } = this.props;

    return (
      <InfoMessage
        messageKey={CACHE_KEY_ALL_ORDERS}
        bar={bar}
        text={`${t("order.here-you-find-order-overview")}${
          bar && bar.isAllowingOnlinePayments()
            ? ` ${t("order.unpaid-orders-are-greyed-out")}`
            : ""
        }`}
      />
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(AllOrdersInfoMessage)
);
