import { Stat } from "./Stat";

type Data = {
  location: string;
  name: string;
  amountOfOrders: number;
  amountOfProducts: number;
  amountOfOrdersNested: number;
  amountOfProductsNested: number;
  amountOfOrdersTotal: number;
  amountOfProductsTotal: number;
};

export class OperationalLocationReport extends Stat<Data> {
  get tree() {
    type LocationNode = {
      location: string;
      name: string;
      amountOfOrders: number;
      amountOfProducts: number;
      subLocations: LocationNode[];
    };
    return this.data.reduceRight((tree, location) => {
      const names = location.name?.split(", ") || [];
      const name = names[names.length - 1] || "unknown";

      const { filteredTree, subLocations } = tree.reduce(
        (result, node) => {
          if (node.location.includes(location.location)) {
            result.subLocations.push(node);
          } else {
            result.filteredTree.push(node);
          }
          return result;
        },
        { filteredTree: [], subLocations: [] } as {
          filteredTree: LocationNode[];
          subLocations: LocationNode[];
        }
      );

      filteredTree.push({
        location: location.location,
        name,
        amountOfOrders: location.amountOfOrdersTotal,
        amountOfProducts: location.amountOfProductsTotal,
        subLocations:
          subLocations.length > 0 && location.amountOfOrders > 0
            ? [
                {
                  location: location.location,
                  name: "_",
                  amountOfOrders: location.amountOfOrders,
                  amountOfProducts: location.amountOfProducts,
                  subLocations: []
                },
                ...subLocations
              ]
            : subLocations
      });

      return filteredTree;
    }, [] as LocationNode[]);
  }

  toCSV(t: any) {
    return this.generateCSV(
      {
        location: true,
        name: true,
        amountOfOrders: this.formatInteger,
        amountOfProducts: this.formatInteger,
        amountOfOrdersNested: false,
        amountOfOrdersTotal: false,
        amountOfProductsNested: false,
        amountOfProductsTotal: false
      },
      t
    );
  }
}
