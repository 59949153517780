import BarService from "../../services/BarService";
import { authHelper, serviceHelper } from "../../helpers";
import AdministrationData from "../../models/data/AdministrationData";
import Bar from "../../models/bar/Bar";

export default class ClientBarService extends BarService {
  constructor(fbStore) {
    super(fbStore);
  }

  public createBar(
    userId: string,
    name: string,
    params: any,
    administrationData: AdministrationData,
    onProgress?: Function
  ) {
    const data: any = {
      name,
      administration: administrationData.allPropsToJSON()
    };
    if (params) {
      data.params = params;
    }

    return serviceHelper.createTask(userId, "bar", "create", data, onProgress);
  }

  public async getTime(
    apiFunctionsEndpoint: string,
    authenticatedUser: any
  ): Promise<Date> {
    const link = `${apiFunctionsEndpoint}/public/time`;

    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link
    );

    const data = await serviceHelper.fetchText(url);

    return new Date(data);
  }

  public async getTimeOffset(
    apiFunctionsEndpoint: string,
    authenticatedUser: any
  ): Promise<number> {
    const time = await this.getTime(apiFunctionsEndpoint, authenticatedUser);

    return time.getTime() - new Date().getTime();
  }

  public async searchBarsByEmail(
    apiFunctionsEndpoint: string,
    authenticatedUser: any,
    email: string
  ): Promise<Array<Bar>> {
    const cleanEmail = email.trim().toLowerCase();

    const link = `${apiFunctionsEndpoint}/mgmt/bars/search`;
    const params = { email: cleanEmail };

    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link,
      params
    );

    const data = await serviceHelper.fetchJSON(url);

    const bars: Array<Bar> = [];

    data.forEach((entry) => {
      bars.push(this.parseDataToBar(entry));
    });

    return bars;
  }

  public async getBarContacts(
    apiFunctionsEndpoint: string,
    authenticatedUser: any,
    bar: Bar
  ) {
    if (!bar.id) {
      throw new Error("error.bar-id-is-not-defined");
    }
    const link = `${apiFunctionsEndpoint}/mgmt/bars/${bar.id}/contacts`;

    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link
    );

    const data = await serviceHelper.fetchJSON(url);

    return data;
  }

  public async getBarLogoImageAsBase64(
    apiFunctionsEndpoint: string,
    authenticatedUser: any,
    bar: Bar
  ) {
    const link = `${apiFunctionsEndpoint}/crew/bar/assets/logo`;
    const params = { barId: bar.id, maxWidth: 160 };

    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link,
      params
    );

    const data = await serviceHelper.fetchText(url);

    return serviceHelper.parseBase64String(data);
  }

  public async getBarPayconiqMerchantQRCodeImageAsBase64(
    apiFunctionsEndpoint: string,
    authenticatedUser: any,
    bar: Bar
  ) {
    const link = `${apiFunctionsEndpoint}/crew/bar/assets/payconiq`;
    const params = { barId: bar.id, size: 200 };

    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link,
      params
    );

    const data = await serviceHelper.fetchText(url);

    return serviceHelper.parseBase64String(data);
  }

  public async validateVATNumber(
    apiFunctionsEndpoint: string,
    authenticatedUser: any,
    vatNumber: string
  ): Promise<any> {
    const link = `${apiFunctionsEndpoint}/crew/validate/vatnumber`;
    const params = { vatNumber };

    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link,
      params
    );

    const data = await serviceHelper.fetchJSON(url);

    return data;
  }

  public async resetDeferredPaymentProvider(
    apiFunctionsEndpoint: string,
    authenticatedUser: any,
    barId: string
  ): Promise<any> {
    const link = `${apiFunctionsEndpoint}/mgmt/bars/${barId}/deferred/reset`;

    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link
    );

    const data = await serviceHelper.fetchJSON(url);

    return data;
  }

  public async updateBarLocator(
    apiFunctionsEndpoint: string,
    authenticatedUser: any,
    barId: string,
    locator: string
  ): Promise<any> {
    const link = `${apiFunctionsEndpoint}/mgmt/bars/${barId}/update`;

    const params = { locator };

    const url = await authHelper.generateAuthorizedLink(
      authenticatedUser,
      link,
      params
    );

    const data = await serviceHelper.fetchJSON(url);

    return data.locator;
  }

  private parseDataToBar(data): Bar {
    return new Bar(data.id, {
      ...data,
      timestamp: new Date(data.timestamp)
    });
  }
}
