import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";
import TopItemsInQueueRightNow from "./TopItemsInQueueRightNow";
import { Typography } from "@mui/material";

const styles = (theme) => ({
  container: {
    position: "fixed",
    zIndex: 100,
    bottom: 0,
    left: 0,
    padding: theme.spacing(1, 0),
    transition: "width 0.1s ease-in-out",
    backgroundColor: "rgba(235, 235, 235, 0.95)",
    "@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))": {
      backdropFilter: "saturate(180%) blur(20px)",
      backgroundColor: "rgba(235, 235, 235, 0.75)",
    }
  }
});

class TopItemsInQueueRightNowCard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, openOrders, base, width, height } = this.props;

    return (
      <div className={classes.container} style={{ width, maxHeight: height }}>
        <Typography>{t("order.top-items-being-ordered-right-now")}</Typography>
        <TopItemsInQueueRightNow
          openOrders={openOrders}
          base={base}
          align="center"
          color="white"
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(TopItemsInQueueRightNowCard)
);
