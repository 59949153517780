import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import ReactMoment from "react-moment";

import { toast } from "react-toastify";

import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader
} from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import KeyOffIcon from "@mui/icons-material/KeyOff";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import LaunchIcon from "@mui/icons-material/Launch";
import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import BusinessIcon from "@mui/icons-material/Business";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import TollIcon from "@mui/icons-material/Toll";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import LockResetIcon from "@mui/icons-material/LockReset";
import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";
import { utilsHelper } from "tap-io/helpers";
import {
  balanceService,
  barService,
  crewService,
  dataService,
  orderService,
  subscriptionService
} from "tap-io/client/services";
import { FIELD_DAYS, FIELD_LABELS } from "tap-io/client/constants/fields";
import PaymentProvider from "tap-io/models/payment/PaymentProvider";
import CustomerFieldType from "tap-io/models/bar/CustomerFieldType";
import ConfirmDialog from "tap-io/client/components/common/ConfirmDialog";

import { hasNativeWrapper } from "../../native";
import { PARAM_LABELS } from "../../constants/params";
import EditBarSubscriptionDialog from "./EditBarSubscriptionDialog";
import EditBarBalanceDialog from "./EditBarBalanceDialog";
import VerifyBarBusinessDataDialog from "../data/VerifyBarBusinessDataDialog";
import EditBarLocatorDialog from "./EditBarLocatorDialog";

const styles = (theme) => ({
  content: {
    minWidth: 500,
    marginTop: 8,
    paddingTop: 0
  },
  stickyItem: {
    background: "rgba(255,255,255,.85)",
    backdropFilter: "saturate(180%) blur(20px)"
  },
  iconAsButton: {
    margin: `-${theme.spacing()}`
  }
});

class BarInfoDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = () => {
    return {
      isVerifyBarBusinessDataDialogOpen: false,
      isEditBarSubscriptionDialogOpen: false,
      isEditBarBalanceDialogOpen: false,
      isEditBarLocatorDialogOpen: false,
      isConfirmResetDeferredPaymentMethodDialogOpen: false,
      isDisabled: false,
      barUrl: null,
      barContacts: null,
      barEmailAddresses: null,
      barAdministrationData: null,
      barBalance: null,
      barSubscription: null,
      barLatestOrder: null
    };
  };

  componentDidMount() {
    this.refreshBarInfo();
  }

  componentDidUpdate(prevProps, prevState) {
    const { bar } = this.props;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;

    if (barId !== prevBarId) {
      this.refreshBarInfo();
    }
  }

  grantAccessToBar = async () => {
    const { t, auth, bar, onBarAccessChanged } = this.props;

    const toastId = toast(t("mgmt.granting-access-to-bar"), {
      autoClose: false
    });
    this.setState({ isDisabled: true });

    try {
      await crewService.grantUserAccessToBar(
        config.functions.api,
        auth.user,
        bar.id
      );

      toast.dismiss(toastId);

      onBarAccessChanged(bar, true);

      //this.setState({ isDisabled: false });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(
          error ? error.message : "error.unknown-error"
        )})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  revokeAccessToBar = async () => {
    const { t, auth, bar, onBarAccessChanged } = this.props;

    const toastId = toast(t("mgmt.revoking-access-to-bar"), {
      autoClose: false
    });
    this.setState({ isDisabled: true });

    try {
      await crewService.revokeUserAccessToBar(
        config.functions.api,
        auth.user,
        bar.id
      );

      toast.dismiss(toastId);

      onBarAccessChanged(bar, false);

      //this.setState({ isDisabled: false });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(
          error ? error.message : "error.unknown-error"
        )})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  refreshBarInfo = () => {
    const { bar } = this.props;

    this.setState({});

    if (bar && bar.id) {
      this.refreshBarUrl();
      this.refreshBarContacts();
      this.refreshBarAdministrationData();
      this.refreshBarSubscription();
      this.refreshBarBalance();
      this.refreshBarLatestOrder();
    } else {
      this.setState(this.initialState());
    }
  };

  refreshBarUrl = async () => {
    const { bar } = this.props;

    try {
      const barUrl = await barService.getBarUrl(
        bar,
        config.hosting.orderDomain
      );

      this.setState({ barUrl });
    } catch (error) {
      console.warn(error);

      this.setState({ barUrl: null });
    }
  };

  refreshBarContacts = async () => {
    const { auth, bar } = this.props;

    try {
      const barContacts = await barService.getBarContacts(
        config.functions.api,
        auth.user,
        bar
      );

      const barEmailAddresses = barContacts
        ? barContacts
            .map((barContact) => barContact.emailAddress)
            .filter((emailAddress) => emailAddress !== undefined)
            .join(", ")
        : "";

      this.setState({ barContacts, barEmailAddresses });
    } catch (error) {
      console.warn(error);

      this.setState({ barContacts: null, barEmailAddresses: null });
    }
  };

  refreshBarAdministrationData = async () => {
    const { bar } = this.props;

    try {
      const barAdministrationData = await dataService.getAdministrationData(
        bar.id
      );

      this.setState({ barAdministrationData });
    } catch (error) {
      console.warn(error);

      this.setState({ barAdministrationData: null });
    }
  };

  refreshBarSubscription = async () => {
    const { bar } = this.props;

    try {
      const barSubscription = await subscriptionService.getSubscriptionById(
        bar.id
      );

      this.setState({ barSubscription });
    } catch (error) {
      console.warn(error);

      this.setState({ barSubscription: null });
    }
  };

  refreshBarBalance = async () => {
    const { bar } = this.props;

    try {
      const barBalance = await balanceService.getBalanceById(bar.id);

      this.setState({ barBalance });
    } catch (error) {
      console.warn(error);

      this.setState({ barBalance: null });
    }
  };

  refreshBarLatestOrder = async () => {
    const { bar } = this.props;

    try {
      const barLatestOrder = await orderService.getLatestOrder(bar.id);

      this.setState({ barLatestOrder });
    } catch (error) {
      console.warn(error);

      this.setState({ barLatestOrder: null });
    }
  };

  handleBarUrlClick = () => {
    const { barUrl } = this.state;
    window.open(barUrl, "_blank");
  };

  handleBarContactsClick = () => {
    const { barEmailAddresses } = this.state;

    if (barEmailAddresses) {
      window.open(
        `mailto:${barEmailAddresses}`,
        hasNativeWrapper() ? "_blank" : "_self"
      );
    }
  };

  handleCopiedToClipboard = () => {
    const { t } = this.props;

    toast.info(t("label.copied-to-clipboard"));
  };

  formatBarParamValue = (paramKey, paramValue) => {
    const { t } = this.props;

    switch (typeof paramValue) {
      case "boolean":
        return paramValue === true ? t("label.yes") : t("label.no");
      case "object":
        switch (paramKey) {
          case "customerFields":
            return paramValue
              .map(
                (field) =>
                  `${field.name}${
                    field.isRequired ? "*" : ""
                  } (${this.getCustomerFieldOptions(field)})`
              )
              .join(", ");
        }
        return JSON.stringify(paramValue);
    }

    return paramValue;
  };

  getCustomerFieldOptions = (field) => {
    const { t } = this.props;

    if (field && field.type) {
      const fieldLabel = FIELD_LABELS[field.type];

      switch (field.type) {
        case CustomerFieldType.EMAIL:
          return `${fieldLabel}${
            field.sendOrderConfirmationToThisAddress
              ? `, ${t("mgmt.send-confirmation")}`
              : ""
          }`;
        case CustomerFieldType.DATE:
          return `${fieldLabel}${
            field.exceptDays
              ? ` ${t("mgmt.excl")} ${Object.keys(field.exceptDays)
                  .filter((dayIndex) => field.exceptDays[dayIndex] === true)
                  .map((dayIndex) => {
                    const day = utilsHelper.findInArrayByPropVal(
                      FIELD_DAYS,
                      "index",
                      parseInt(dayIndex)
                    );
                    return day && day.label
                      ? t(day.label)
                      : `(${t("label.unknown")})`;
                  })
                  .join("/")}`
              : ""
          }`;
        case CustomerFieldType.SELECT:
          return `${fieldLabel}: ${field.options
            .map((option) => option.label)
            .join(", ")}`;
        case CustomerFieldType.TEXT:
        case CustomerFieldType.TOGGLE:
          return fieldLabel;
      }
    }

    return `(${t("label.unknown")})`;
  };

  isPaymentProviderActive(provider, providers) {
    if (provider && providers) {
      return providers[provider].isActive === true;
    }

    return false;
  }

  getPaymentProviderLabel(provider) {
    const { t } = this.props;

    if (provider) {
      switch (provider) {
        case "deferred":
          return t("mgmt.pay-to-waiter");
        case "mollie":
          return t("mgmt.pay-using-mollie");
        case "vivawallet":
          return t("mgmt.pay-using-vivawallet");
        case "ccv":
          return t("mgmt.pay-using-ccv");
        case "payconiq":
          return t("mgmt.pay-with-payconiq");
        case "voucher":
          return t("mgmt.pay-with-voucher");
      }
    }

    return `(${t("mgmt.unknown")})`;
  }

  getBarBalanceAsText = () => {
    const { t } = this.props;
    const { barBalance } = this.state;

    if (barBalance) {
      const creditCountAsText = `${barBalance.creditCount} ${t(
        barBalance.creditCount === 1 ? "credits.credit" : "credits.credits"
      )}`;

      return barBalance.doNotChargeCredits || barBalance.allowCreditDebt
        ? `${t("credits.subscription")} (${creditCountAsText})`
        : creditCountAsText;
    }

    return "";
  };

  handleVerifyBusinessData = () => {
    this.setState({ isVerifyBarBusinessDataDialogOpen: true });
  };

  handleEditBarSubscription = () => {
    this.setState({ isEditBarSubscriptionDialogOpen: true });
  };

  handleEditBarBalance = () => {
    this.setState({ isEditBarBalanceDialogOpen: true });
  };

  handleEditBarLocator = () => {
    this.setState({ isEditBarLocatorDialogOpen: true });
  };

  handleVerifyBarBusinessDataDialogClose = () => {
    this.setState({ isVerifyBarBusinessDataDialogOpen: false });
  };

  handleEditBarSubscriptionDialogClose = () => {
    this.setState({ isEditBarSubscriptionDialogOpen: false });
    this.refreshBarSubscription();
  };

  handleEditBarBalanceDialogClose = () => {
    this.setState({ isEditBarBalanceDialogOpen: false });
    this.refreshBarBalance();
  };

  handleEditBarLocatorDialogClose = () => {
    this.setState({ isEditBarLocatorDialogOpen: false }, this.refreshBarUrl);
  };

  handleResetDeferredPaymentMethod = () => {
    this.setState({ isConfirmResetDeferredPaymentMethodDialogOpen: true });
  };

  handleResetDeferredPaymentMethodConfirm = () => {
    this.setState(
      { isConfirmResetDeferredPaymentMethodDialogOpen: false },
      this.resetDeferredPaymentProvider
    );
  };

  handleResetDeferredPaymentMethodCancel = () => {
    this.setState({ isConfirmResetDeferredPaymentMethodDialogOpen: false });
  };

  resetDeferredPaymentProvider = async () => {
    const { t, auth, bar } = this.props;

    const toastId = toast(
      t("mgmt.resetting-deferred-payment-provider-for-bar"),
      {
        autoClose: false
      }
    );
    this.setState({ isDisabled: true });

    try {
      await barService.resetDeferredPaymentProvider(
        config.functions.api,
        auth.user,
        bar.id
      );

      toast.update(toastId, {
        render: t("mgmt.deferred-payment-provider-has-been-reset"),
        type: toast.TYPE.INFO,
        autoClose: 5000
      });

      this.setState({ isDisabled: false });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(
          error ? error.message : "error.unknown-error"
        )})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, isOpen, onClose, auth, bar } = this.props;
    const {
      isVerifyBarBusinessDataDialogOpen,
      isEditBarSubscriptionDialogOpen,
      isEditBarBalanceDialogOpen,
      isEditBarLocatorDialogOpen,
      isConfirmResetDeferredPaymentMethodDialogOpen,
      isDisabled,
      barSubscription,
      barUrl,
      barEmailAddresses,
      barAdministrationData,
      barBalance,
      barLatestOrder
    } = this.state;

    return (
      <div>
        <VerifyBarBusinessDataDialog
          isOpen={isVerifyBarBusinessDataDialogOpen}
          onAccept={this.handleVerifyBarBusinessDataDialogClose}
          onReject={this.handleVerifyBarBusinessDataDialogClose}
          bar={bar}
          balance={barBalance}
        />
        <EditBarSubscriptionDialog
          isOpen={isEditBarSubscriptionDialogOpen}
          onClose={this.handleEditBarSubscriptionDialogClose}
          bar={bar}
          subscription={barSubscription}
        />
        <EditBarBalanceDialog
          isOpen={isEditBarBalanceDialogOpen}
          onClose={this.handleEditBarBalanceDialogClose}
          bar={bar}
          balance={barBalance}
        />
        <EditBarLocatorDialog
          isOpen={isEditBarLocatorDialogOpen}
          onClose={this.handleEditBarLocatorDialogClose}
          bar={bar}
        />
        <ConfirmDialog
          isOpen={isConfirmResetDeferredPaymentMethodDialogOpen}
          title={t("mgmt.confirm-to-reset-deferred-payment-method")}
          description={t(
            "mgmt.are-you-sure-you-want-to-reset-deferred-payment-method"
          )}
          confirmButtonLabel={t("mgmt.reset-deferred-payment-method")}
          onConfirm={this.handleResetDeferredPaymentMethodConfirm}
          onCancel={this.handleResetDeferredPaymentMethodCancel}
        />
        <Dialog open={isOpen} onClose={onClose}>
          <DialogTitle>
            {bar && bar.name ? bar.name : `(${t("label.unkown")})`}
          </DialogTitle>
          <DialogContent className={classes.content}>
            {bar && (
              <List>
                <ListSubheader className={classes.stickyItem}>
                  {t("label.actions")}
                </ListSubheader>
                {auth && auth.user && auth.user.email && barEmailAddresses ? (
                  <>
                    {barEmailAddresses.indexOf(auth.user.email) >= 0 ? (
                      <ListItemButton
                        disabled={isDisabled}
                        onClick={this.revokeAccessToBar}
                      >
                        <ListItemIcon>
                          <KeyOffIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={t("label.revoke-access")}
                          secondary={bar.id}
                        />
                      </ListItemButton>
                    ) : (
                      <ListItemButton
                        disabled={isDisabled}
                        onClick={this.grantAccessToBar}
                      >
                        <ListItemIcon>
                          <KeyIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={t("label.grant-access")}
                          secondary={bar.id}
                        />
                      </ListItemButton>
                    )}
                  </>
                ) : (
                  <ListItem>
                    <ListItemText primary={t("label.loading")} />
                  </ListItem>
                )}
                <ListSubheader className={classes.stickyItem}>
                  {t("label.general")}
                </ListSubheader>
                <CopyToClipboard
                  text={bar.id}
                  onCopy={this.handleCopiedToClipboard}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <PanoramaFishEyeIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("label.id")} secondary={bar.id} />
                  </ListItemButton>
                </CopyToClipboard>
                {barUrl && (
                  <ListItem>
                    <ListItemIcon>
                      <IconButton
                        onClick={this.handleBarUrlClick}
                        className={classes.iconAsButton}
                      >
                        <LaunchIcon />
                      </IconButton>
                    </ListItemIcon>
                    <ListItemText
                      primary={t("label.order-page")}
                      secondary={barUrl}
                    />
                    <ListItemSecondaryAction>
                      <IconButton onClick={this.handleEditBarLocator}>
                        <EditIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
                {barEmailAddresses && (
                  <CopyToClipboard
                    text={barEmailAddresses}
                    onCopy={this.handleCopiedToClipboard}
                  >
                    <ListItemButton onClick={this.handleBarContactsClick}>
                      <ListItemIcon>
                        <AlternateEmailIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={t("label.email-address")}
                        secondary={barEmailAddresses}
                      />
                    </ListItemButton>
                  </CopyToClipboard>
                )}
                {barAdministrationData && barAdministrationData.contact && (
                  <CopyToClipboard
                    text={barAdministrationData.contact.phoneNumber}
                    onCopy={this.handleCopiedToClipboard}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        <PhoneIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={t("label.phone-number")}
                        secondary={`${barAdministrationData.contact.phoneNumber} (${barAdministrationData.contact.firstName} ${barAdministrationData.contact.lastName})`}
                      />
                    </ListItem>
                  </CopyToClipboard>
                )}
                {barLatestOrder !== null && (
                  <ListItem>
                    <ListItemIcon>
                      <ReceiptIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t("mgmt.latest-order")}
                      secondary={
                        barLatestOrder === undefined ? (
                          `(${t("order.no-orders-found")})`
                        ) : (
                          <ReactMoment format="DD/MM/YYYY [om] HH:mm:ss">
                            {barLatestOrder.timestamp}
                          </ReactMoment>
                        )
                      }
                    />
                  </ListItem>
                )}
                <ListSubheader className={classes.stickyItem}>
                  {t("mgmt.subscription")}
                </ListSubheader>
                {barBalance && (
                  <ListItemButton onClick={this.handleVerifyBusinessData}>
                    <ListItemIcon>
                      <BusinessIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t("mgmt.business-and-billing-info")}
                    />
                  </ListItemButton>
                )}
                {barSubscription && (
                  <ListItemButton onClick={this.handleEditBarSubscription}>
                    <ListItemIcon>
                      <LoyaltyIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t("subscription.current-plan")}
                      secondary={
                        barSubscription.isRequested()
                          ? t("subscription.plan-x-requested", {
                              planName: t(
                                `subscription.plan-${barSubscription.planName}`
                              )
                            })
                          : t(`subscription.plan-${barSubscription.planName}`)
                      }
                    />
                  </ListItemButton>
                )}
                {barBalance && (
                  <ListItemButton onClick={this.handleEditBarBalance}>
                    <ListItemIcon>
                      <TollIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t("credits.credit-balance")}
                      secondary={this.getBarBalanceAsText()}
                    />
                  </ListItemButton>
                )}
                <ListSubheader className={classes.stickyItem}>
                  {t("mgmt.params")}
                </ListSubheader>
                {bar.params &&
                  bar.params.map((param) => (
                    <ListItem key={param.key}>
                      <ListItemText
                        primary={t(PARAM_LABELS[param.key])}
                        secondary={this.formatBarParamValue(
                          param.key,
                          param.value
                        )}
                      />
                    </ListItem>
                  ))}
                {bar.paymentProviders && (
                  <div>
                    <ListSubheader className={classes.stickyItem}>
                      {t("mgmt.online-payments")}
                    </ListSubheader>
                    {Object.keys(bar.paymentProviders).map((provider) => (
                      <ListItem key={provider}>
                        <ListItemIcon>
                          {bar.isAllowingPaymentsFromProvider(provider) ? (
                            <DoneIcon />
                          ) : (
                            <CloseIcon />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={this.getPaymentProviderLabel(provider)}
                          secondary={
                            provider === PaymentProvider.DEFERRED &&
                            bar.isPinEnabledForDeferredPayments()
                              ? t("mgmt.pin-configured")
                              : undefined
                          }
                        />
                        {provider === PaymentProvider.DEFERRED &&
                          (bar.isAllowingDeferredPayments() ||
                            bar.isPinEnabledForDeferredPayments()) && (
                            <ListItemSecondaryAction>
                              <IconButton
                                disabled={isDisabled}
                                onClick={this.handleResetDeferredPaymentMethod}
                              >
                                <LockResetIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          )}
                      </ListItem>
                    ))}
                  </div>
                )}
              </List>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="secondary">
              {t("label.close")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(BarInfoDialog));
