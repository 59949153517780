import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button, IconButton, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import withStyles from "@mui/styles/withStyles";

import withMode from "../components/auth/withMode";
import withBarAuthorization from "../components/auth/withBarAuthorization";
import AllVouchers from "../components/voucher/AllVouchers";
import ShowVoucherDialog from "../components/voucher/ShowVoucherDialog";
import GenerateVouchersFromTemplatesDialog from "../components/voucher/GenerateVouchersFromTemplatesDialog";
import ModeName from "tap-io/models/mode/ModeName";
import DownloadVoucherReportDialog from "../components/voucher/DownloadVoucherReportDialog";

const styles = (theme) => ({
  content: {
    margin: theme.spacing(2)
  },
  header: {
    display: "flex",
    alignItems: "center"
  },
  title: {
    width: "100%",
    textAlign: "left"
  },
  largeSpacing: {
    height: 40
  },
  smallSpacing: {
    height: 10
  },
  voucherPaymentsDisabledWarning: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    color: theme.palette.warning.main
  },
  voucherPaymentsDisabledWarningText: {
    marginLeft: 10,
    textAlign: "left"
  }
});

class VouchersPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isGenerateVouchersFromTemplatesDialogOpen: false,
      isDownloadVoucherReportDialogOpen: false,
      voucherBeingShown: null
    };
  }

  handleGenerateVouchersFromTemplates = () => {
    this.setState({ isGenerateVouchersFromTemplatesDialogOpen: true });
  };

  handleGenerateVouchersFromTemplatesDialogClose = () => {
    this.setState({
      isGenerateVouchersFromTemplatesDialogOpen: false
    });
  };

  handleDownloadVoucherReport = () => {
    this.setState({ isDownloadVoucherReportDialogOpen: true });
  };

  handleDownloadVoucherReportDialogClose = () => {
    this.setState({
      isDownloadVoucherReportDialogOpen: false
    });
  };

  handleShowVoucher = (voucher) => {
    this.setState({ voucherBeingShown: voucher });
  };

  handleShowVoucherDialogClose = () => {
    this.setState({ voucherBeingShown: null });
  };

  render() {
    const { classes, t, bar, subscription, assets, deviceSettings } =
      this.props;
    const {
      isGenerateVouchersFromTemplatesDialogOpen,
      isDownloadVoucherReportDialogOpen,
      voucherBeingShown
    } = this.state;

    return (
      <div className={classes.content}>
        <GenerateVouchersFromTemplatesDialog
          isOpen={isGenerateVouchersFromTemplatesDialogOpen}
          onClose={this.handleGenerateVouchersFromTemplatesDialogClose}
          bar={bar}
          subscription={subscription}
          assets={assets}
          deviceSettings={deviceSettings}
        />
        <DownloadVoucherReportDialog
          isOpen={isDownloadVoucherReportDialogOpen}
          onClose={this.handleDownloadVoucherReportDialogClose}
          bar={bar}
        />
        <ShowVoucherDialog
          isOpen={voucherBeingShown !== null}
          onClose={this.handleShowVoucherDialogClose}
          bar={bar}
          voucher={voucherBeingShown}
          assets={assets}
          deviceSettings={deviceSettings}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={this.handleGenerateVouchersFromTemplates}
        >
          {t("voucher.generate-vouchers")}...
        </Button>
        {!bar.isAllowingVoucherPayments() && (
          <div className={classes.voucherPaymentsDisabledWarning}>
            <WarningIcon />
            <Typography className={classes.voucherPaymentsDisabledWarningText}>
              {t(
                "voucher.voucher-payments-are-disabled-enable-voucher-payments-to-allow-customers-to-pay-with-vouchers"
              )}
            </Typography>
          </div>
        )}
        <div className={classes.largeSpacing} />
        <div className={classes.header}>
          <Typography variant="h6" className={classes.title}>
            {t("voucher.generated-vouchers-below")}
          </Typography>
          <IconButton onClick={this.handleDownloadVoucherReport} size="large">
            <ArrowDownwardIcon />
          </IconButton>
        </div>
        <div className={classes.smallSpacing} />
        <AllVouchers
          scrollableTarget="body"
          bar={bar}
          allowSelection={false}
          onVoucherClick={this.handleShowVoucher}
          onVoucherSelectChange={this.handleCheckVoucherChange}
        />
      </div>
    );
  }
}

export default withBarAuthorization()(
  withMode(ModeName.VOUCHER)(
    withStyles(styles, { withTheme: true })(
      withTranslation("common")(VouchersPage)
    )
  )
);
