import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  spacing: {
    height: 20
  }
});

class OrderCompleted extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, base, order } = this.props;

    return (
      <div className={classes.content}>
        <Typography variant="h4">
          {t("order.this-order-is-complete")}
        </Typography>
        <div className={classes.spacing} />
        <Typography variant="body">
          {t(
            order.isDeliveryMethodServe
              ? "order.order-was-already-delivered"
              : order.isDeliveryMethodPickup
              ? "order.order-was-already-picked-up"
              : ""
          )}
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(OrderCompleted));
