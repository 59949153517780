import { Stat } from "./Stat";

type Data = {
  base: string;
  name: string;
  version: string;
  amountOfPreparations: number;
  amountOfProducts: number;
};

export class BaseReport extends Stat<Data> {
  get totalAmountOfPreparations() {
    return this.data.reduce(
      (total, report) => total + report.amountOfPreparations,
      0
    );
  }

  toCSV(t: any) {
    return this.generateCSV(
      {
        base: true,
        name: true,
        version: true,
        amountOfPreparations: this.formatInteger,
        amountOfProducts: this.formatInteger
      },
      t
    );
  }
}
