import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";

import withStyles from "@mui/styles/withStyles";

import SelectServiceOption from "tap-io/client/components/service/SelectServiceOption";

const styles = (theme) => ({
  root: {
    zIndex: "1301 !important"
  }
});

class SelectOrderServiceOptionDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      t,
      classes,
      isOpen,
      isDisabled,
      serviceOptions,
      orderServiceOption,
      onChange,
      onCancel
    } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={onCancel}
        disableEscapeKeyDown
        classes={{ root: classes.root }}
      >
        <DialogTitle>{t("service.select-a-service-option")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("service.select-a-service-option-below")}
          </DialogContentText>
          <SelectServiceOption
            isDisabled={isDisabled}
            allServiceOptions={serviceOptions}
            serviceOption={orderServiceOption}
            onChange={onChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            {t("label.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(SelectOrderServiceOptionDialog)
);
