import { Box, useTheme } from "@mui/material";
import { ReactNode } from "react";

type StatGroupProps = {
  children?: ReactNode;
};
export function StatGroup({ children }: StatGroupProps) {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: theme.spacing(2) }}>
      {children}
    </Box>
  );
}
