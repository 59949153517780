import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";
import { QRCode } from "react-qrcode-logo";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemText,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";
import { barService, scannerService } from "tap-io/client/services";
import { baseHelper, scannerHelper, utilsHelper } from "tap-io/helpers";
import ServiceOptionName from "tap-io/models/service/ServiceOptionName";

import SelectBase from "../base/SelectBase";
import EditPin from "../settings/EditPin";

const styles = (theme) => ({
  dialog: {
    minWidth: 400
  },
  qrContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  linkAndQr: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  link: {
    textDecoration: "none",
    color: "black"
  },
  waiterUrl: {
    fontFamily: "'Anonymous Pro', 'Courier New', Courier, monospace"
  },
  spacing: {
    height: 15
  },
  inputContainer: {
    display: "flex",
    alignItems: "center"
  },
  inputLabel: {
    width: "100%"
  },
  accentuate: {
    fontWeight: "bold",
    color: theme.palette.error.main
  },
  inputInfo: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 10
  },
  inputInfoIcon: {
    marginTop: -2,
    marginRight: 5
  }
});

class EditScannerDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (scanner) => {
    return {
      isDisabled: false,
      barScannerUrl: null,
      name: scanner ? scanner.name : "",
      baseId:
        scanner && this.isBaseUsingScanners(scanner.baseId)
          ? scanner.baseId
          : "",
      isActive: scanner ? scanner.isActive : false,
      //allowCreatingOrders: scanner ? scanner.allowCreatingOrders : false,
      allowCashPayments: scanner ? scanner.allowCashPayments : false,
      pin: ""
    };
  };

  componentDidUpdate(prevProps, prevState) {
    const { isOpen, scanner } = this.props;

    if (isOpen && !prevProps.isOpen) {
      this.setState(this.initialState(scanner), () => {
        this.refreshBarScannerUrl();
        this.refreshScannerPin();
      });
    }
  }

  isBaseUsingScanners = (baseId) => {
    const { allBases } = this.props;

    if (baseId) {
      const base = utilsHelper.findInArrayById(allBases, baseId);

      if (base && base.canUseScanner) {
        return true;
      }
    }

    return false;
  };

  refreshBarScannerUrl = async () => {
    const { bar, scanner } = this.props;

    let barScannerUrl = null;

    if (scanner) {
      const barUrl = await barService.getBarUrl(bar, config.hosting.scanDomain);
      barScannerUrl = scannerHelper.getScannerUrl(barUrl, scanner.id);
    }

    this.setState({ barScannerUrl });
  };

  refreshScannerPin = async () => {
    const { scanner } = this.props;

    if (scanner) {
      try {
        const pin = await scannerService.getScannerPin(
          scanner.barId,
          scanner.id
        );

        this.setState({ pin });
      } catch (error) {
        console.warn(error);

        this.setState({ pin: "" });
      }
    } else {
      this.setState({ pin: "" });
    }
  };

  handleNameChange = (event) => {
    const val = event.target.value;
    this.setState({ name: val });
  };

  handleBaseIdChange = (baseId) => {
    this.setState({ baseId });
  };

  handleIsActiveChange = (event) => {
    this.setState({ isActive: event.target.checked === true });
  };

  /*handleAllowCreatingOrdersChange = (event) => {
    this.setState({ allowCreatingOrders: event.target.checked === true });
  };*/

  handleAllowCashPaymentsChange = (event) => {
    this.setState({ allowCashPayments: event.target.checked === true });
  };

  handleUpdateScanner = async () => {
    const { t, scanner, onClose } = this.props;
    const { name, baseId, isActive, /*allowCreatingOrders,*/ allowCashPayments } = this.state;

    const toastId = toast(t("scanner.updating-scanner"), { autoClose: false });
    this.setState({ isDisabled: true });

    try {
      const parsedName = name.trim();

      scanner.name = parsedName;
      scanner.baseId = baseId;
      scanner.isActive = isActive;
      //scanner.allowCreatingOrders = allowCreatingOrders;
      scanner.allowCashPayments = allowCashPayments;

      await scannerService.updateScanner(scanner);
      toast.update(toastId, {
        render: t("scanner.scanner-updated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false }, onClose);
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(
          error ? error.message : "error.unknown-error"
        )})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, isOpen, onClose, bar, allBases } = this.props;
    const {
      isDisabled,
      barScannerUrl,
      name,
      baseId,
      pin,
      isActive,
      //allowCreatingOrders,
      allowCashPayments
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle>{t("scanner.edit-scanner")}</DialogTitle>
        <DialogContent className={classes.content}>
          <div className={classes.qrContainer}>
            {barScannerUrl ? (
              <div className={classes.linkAndQr}>
                <a
                  className={classes.link}
                  href={barScannerUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography variant="caption" className={classes.waiterUrl}>
                    {barScannerUrl}
                  </Typography>
                </a>
                <QRCode className={classes.qr} value={barScannerUrl} />
              </div>
            ) : (
              <CircularProgress />
            )}
          </div>
          <div className={classes.spacing} />
          <DialogContentText>
            {t("scanner.edit-scanner-below")}
          </DialogContentText>
          <TextField
            variant="standard"
            margin="dense"
            label={t("label.name")}
            fullWidth
            value={name}
            disabled={isDisabled}
            onChange={this.handleNameChange}
          />
          <div className={classes.spacing} />
          <SelectBase
            isDisabled={isDisabled}
            allBases={allBases}
            basesFilter={baseHelper.onlyBasesUsingScannersFilter}
            selectedBaseId={baseId}
            onSelect={this.handleBaseIdChange}
          />
          <div className={classes.spacing} />
          <div className={classes.inputInfo}>
            <InfoIcon fontSize="small" className={classes.inputInfoIcon} />
            <Typography variant="caption">
              {t(
                "scanner.enable-scanners-in-base-settings-in-order-to-select-it"
              )}
            </Typography>
          </div>
          <div className={classes.spacing} />
          <div className={classes.inputContainer}>
            <div className={classes.inputLabel}>
              <Typography>{t("scanner.active")}</Typography>
            </div>
            <Switch
              edge="end"
              disabled={isDisabled}
              checked={isActive}
              onChange={this.handleIsActiveChange}
            />
          </div>
          <div className={classes.inputContainer}>
            <div className={classes.inputLabel}>
              <Typography>{t("scanner.allow-cash-payments")}</Typography>
            </div>
            <Switch
              edge="end"
              disabled={isDisabled || !bar.isPinEnabledForDeferredPayments()}
              checked={allowCashPayments}
              onChange={this.handleAllowCashPaymentsChange}
            />
          </div>
          {!bar.isPinEnabledForDeferredPayments() && (
            <div>
              <Typography className={classes.accentuate}>
                {`${t("scanner.for-scanners-to-take-cash-payments-you-need")} `}
                <strong>{t("promo.to-set-a-pin")}</strong>.
              </Typography>
              <EditPin bar={bar} />
            </div>
          )}
          <div className={classes.spacing} />
          {pin && <ListItemText primary={t("scanner.pin")} secondary={pin} />}
        </DialogContent>
        <DialogActions>
          <Button disabled={isDisabled} onClick={onClose} color="secondary">
            {t("label.cancel")}
          </Button>
          <Button
            disabled={isDisabled}
            onClick={this.handleUpdateScanner}
            color="primary"
          >
            {t("label.update")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(EditScannerDialog));

/*
<div className={classes.inputContainer}>
  <div className={classes.inputLabel}>
    <Typography>{t("scanner.allow-creating-orders")}</Typography>
  </div>
  <Switch
    edge="end"
    disabled={isDisabled}
    checked={allowCreatingOrders}
    onChange={this.handleAllowCreatingOrdersChange}
  />
</div>
*/