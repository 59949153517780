import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import EditLayoutNodeBasics from "./EditLayoutNodeBasics";

const styles = (theme) => ({
  inputContainer: {
    display: "flex",
    alignItems: "center"
  },
  inputLabel: {
    width: "100%"
  },
  largeSpacing: {
    height: 25
  },
  smallSpacing: {
    height: 10
  }
});

class EditZoneLayoutNode extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleNameChange = (event) => {
    const val = event.target.value;
    const { layout, onUpdate } = this.props;

    layout.name = val;

    onUpdate({ name: val, layout });
  };

  handleBaseIdsChange = (baseIds) => {
    const { onUpdate } = this.props;

    onUpdate({ baseIds });
  };

  handleBasesChange = (bases) => {
    const { layout, onUpdate } = this.props;

    layout.customBases = bases;

    onUpdate({
      bases
    });
  };

  render() {
    const { classes, t, isDisabled, bar, allBases, layoutNode, onUpdate } =
      this.props;

    return (
      <EditLayoutNodeBasics
        isDisabled={isDisabled}
        bar={bar}
        allBases={allBases}
        layoutNode={layoutNode}
        onUpdate={onUpdate}
      />
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(EditZoneLayoutNode)
);
