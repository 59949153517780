import React, { PureComponent } from "react";

import { Link, Redirect } from "react-router-dom";

import { withTranslation } from "react-i18next";

import { Button, Divider, List, ListItem, TextField } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { toast } from "react-toastify";

import { authService } from "tap-io/client/services";
import { utilsHelper } from "tap-io/helpers";
import ConsentAgreement from "tap-io/client/components/common/ConsentAgreement";

import AuthContext from "tap-io/client/components/auth/authContext";
import BackgroundWithCard from "../components/auth/BackgroundWithCard";

import * as routes from "../constants/routes";
import withDevice from "../components/auth/withDevice";

const styles = (theme) => ({
  divider: {
    marginTop: 20
  },
  action: {
    margin: 0
  },
  link: {
    textDecoration: "none"
  },
  info: {
    marginTop: 5,
    fontSize: 14,
    color: "#888"
  }
});

class RegisterPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      repeatPassword: "",
      hasAllConsents: false,
      validEmail: false,
      validPassword: false,
      isRegistering: false
    };
  }

  handleBlur = (event) => {
    utilsHelper.resetScrollPosition();
  };

  handleChange = (name) => (event) => {
    let validEmail = this.state.validEmail;
    let validPassword = this.state.validPassword;

    let val = event.target.value;

    if (name === "email") {
      validEmail = val && val !== "";
    } else if (name === "password") {
      validPassword = val && val !== "" && val === this.state.repeatPassword;
    } else if (name === "repeatPassword") {
      validPassword = val && val !== "" && val === this.state.password;
    }

    this.setState(
      {
        [name]: val,
        validEmail: validEmail,
        validPassword: validPassword
      },
      () => {}
    );
  };

  handleConsentAgreementChange = (hasAllConsents) => {
    this.setState({ hasAllConsents });
  };

  handleRegister = async (event) => {
    const { t, unlockApp } = this.props;
    const { email, password } = this.state;

    event.preventDefault();

    this.setState({ isRegistering: true });
    const toastId = toast(t("auth.registering"), { autoClose: false });

    try {
      const auth = await authService.createUserWithEmailAndPassword(
        email,
        password
      );

      this.setState({ isRegistering: false });

      toast.update(toastId, {
        render: t("auth.registered-successfully"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      unlockApp(auth.user, password);
    } catch (error) {
      console.warn(error);

      this.setState({ isRegistering: false });

      if (error && error.message) {
        toast.update(toastId, {
          render: `${t("auth.error-while-registering")} (${error.message})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
      } else {
        toast.update(toastId, {
          render: `${t("auth.error-while-registering")} (${t(
            "error.unknown-error"
          )})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
      }
    }
  };

  render() {
    const { classes, t } = this.props;
    const {
      email,
      password,
      repeatPassword,
      hasAllConsents,
      validEmail,
      validPassword,
      isRegistering
    } = this.state;

    return (
      <AuthContext.Consumer>
        {(auth) =>
          auth && auth.user ? (
            <Redirect to={routes.ORDERS} />
          ) : (
            <BackgroundWithCard
              content={
                <List className={classes.client}>
                  <form onSubmit={this.handleRegister}>
                    <ListItem>
                      <TextField
                        data-cy="email"
                        variant="standard"
                        required
                        fullWidth
                        label={t("auth.email-address")}
                        className={classes.textField}
                        value={email}
                        onChange={this.handleChange("email")}
                        onBlur={this.handleBlur}
                        margin="normal"
                      />
                    </ListItem>
                    <ListItem>
                      <TextField
                        data-cy="password"
                        variant="standard"
                        required
                        fullWidth
                        label={t("auth.password")}
                        type="password"
                        className={classes.textField}
                        value={password}
                        onChange={this.handleChange("password")}
                        onBlur={this.handleBlur}
                        margin="normal"
                      />
                    </ListItem>
                    <ListItem>
                      <TextField
                        data-cy="repeat-password"
                        variant="standard"
                        required
                        fullWidth
                        label={t("auth.repeat-password")}
                        type="password"
                        className={classes.textField}
                        value={repeatPassword}
                        onChange={this.handleChange("repeatPassword")}
                        onBlur={this.handleBlur}
                        margin="normal"
                      />
                    </ListItem>
                    <ConsentAgreement
                      label={t("legal.by-registering-you-consent-to-the")}
                      value={hasAllConsents}
                      onChange={this.handleConsentAgreementChange}
                    />
                    <Button
                      data-cy="register"
                      type="submit"
                      size="medium"
                      disabled={
                        !hasAllConsents ||
                        !validEmail ||
                        !validPassword ||
                        isRegistering
                      }
                    >
                      {t("auth.register")}
                    </Button>
                  </form>
                  <Divider className={classes.divider} />
                </List>
              }
              actions={
                <div>
                  <div className={classes.info}>
                    {t("auth.i-already-have-an-account")}
                  </div>
                  <Link
                    to={routes.SIGN_IN}
                    className={`${classes.action} ${classes.link}`}
                  >
                    <Button data-cy="sign-in" size="small">
                      {t("auth.sign-in")}
                    </Button>
                  </Link>
                </div>
              }
            />
          )
        }
      </AuthContext.Consumer>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(withDevice()(RegisterPage))
);
