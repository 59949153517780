import React, { PureComponent } from "react";

import withStyles from "@mui/styles/withStyles";

import AuthorizedAppLink from "../auth/AuthorizedAppLink";

const styles = (theme) => ({});

class DownloadReport extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      isDisabled,
      onClick,
      bar,
      icon,
      label,
      reportFor,
      beginDateTime,
      endDateTime,
      fullWidth
    } = this.props;

    const params = {
      ...(this.props.params ? this.props.params : {}),
      beginDateTime,
      endDateTime
    };

    return (
      <AuthorizedAppLink
        isDisabled={isDisabled}
        onClick={onClick}
        bar={bar}
        endpoint={`crew/bar/reports/${reportFor}`}
        params={params}
        icon={icon}
        text={label}
        fullWidth={fullWidth}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(DownloadReport);
