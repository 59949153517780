import { Stat } from "./Stat";

type Data = {
  location: string;
  name: string;
  valueExclVat: number;
  vat: number;
  valueInclVat: number;
  valueExclVatNested: number;
  vatNested: number;
  valueInclVatNested: number;
  valueExclVatTotal: number;
  vatTotal: number;
  valueInclVatTotal: number;
  currency: string;
};

export class FinancialLocationReport extends Stat<Data> {
  get tree() {
    type LocationNode = {
      location: string;
      name: string;
      valueExclVat: number;
      vat: number;
      valueInclVat: number;
      subLocations: LocationNode[];
    };
    return this.data.reduceRight((tree, location) => {
      const names = location.name?.split(", ") || [];
      const name = names[names.length - 1] || "unknown";

      const { filteredTree, subLocations } = tree.reduce(
        (result, node) => {
          if (node.location.includes(location.location)) {
            result.subLocations.push(node);
          } else {
            result.filteredTree.push(node);
          }
          return result;
        },
        { filteredTree: [], subLocations: [] } as {
          filteredTree: LocationNode[];
          subLocations: LocationNode[];
        }
      );

      filteredTree.push({
        location: location.location,
        name,
        valueExclVat: location.valueExclVatTotal,
        vat: location.vatTotal,
        valueInclVat: location.valueInclVatTotal,
        subLocations:
          subLocations.length > 0 && location.valueInclVat > 0
            ? [
                {
                  location: location.location,
                  name: "_",

                  valueExclVat: location.valueExclVat,
                  vat: location.vat,
                  valueInclVat: location.valueInclVat,
                  subLocations: []
                },
                ...subLocations
              ]
            : subLocations
      });

      return filteredTree;
    }, [] as LocationNode[]);
  }

  toCSV(t: any) {
    return this.generateCSV(
      {
        location: true,
        name: true,
        valueExclVat: this.formatCurrency,
        vat: this.formatCurrency,
        valueInclVat: this.formatCurrency,
        valueExclVatNested: false,
        vatNested: false,
        valueInclVatNested: false,
        valueExclVatTotal: false,
        vatTotal: false,
        valueInclVatTotal: false,
        currency: true
      },
      t
    );
  }
}
