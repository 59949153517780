import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import Moment from "moment";

import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemSecondaryAction,
  Typography
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import withStyles from "@mui/styles/withStyles";

import { mgmtService } from "tap-io/client/services";
import ContentCard from "tap-io/client/components/common/ContentCard";

import StatsLineChart from "../stats/StatsLineChart";
import AuthorizedAppLink from "../auth/AuthorizedAppLink";
import SelectMonthDate from "../common/SelectMonthDate";

const styles = (theme) => ({
  container: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap"
  },
  periodPickerCard: {
    margin: 10,
    width: "100%"
  },
  periodPickerContent: {
    padding: "0 !important"
  },
  periodLabel: {
    textTransform: "uppercase"
  },
  totals: {
    display: "flex",
    flexDirection: "row"
  },
  total: {
    width: "100%",
    textAlign: "center"
  },
  chart: {
    display: "flex",
    justifyContent: "center"
  },
  spacing: {
    height: 20
  }
});

class Stats extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      period: new Date()
    };
  }

  componentDidMount() {
    this.refreshStats();
  }

  componentWillUnmount() {
    if (this.unsubscribeStats) {
      this.unsubscribeStats();
      this.unsubscribeStats = undefined;
    }
  }

  refreshStats = () => {
    const { period } = this.state;

    if (this.unsubscribeStats) {
      this.unsubscribeStats();
      this.unsubscribeStats = undefined;
    }

    this.unsubscribeStats = mgmtService.onStatsPerDay(
      period.getFullYear(),
      period.getMonth() + 1,
      (stats) => {
        let totalCreditsInCount = 0,
          totalCreditsOutCount = 0,
          totalOrderCount = 0,
          totalItemCount = 0;
        const chartData = [];

        if (stats) {
          // Figures
          Object.keys(stats).forEach((date) => {
            const entry = stats[date];

            if (entry) {
              if (entry.creditsInCount) {
                totalCreditsInCount += entry.creditsInCount;
              }
              if (entry.creditsOutCount) {
                totalCreditsOutCount += entry.creditsOutCount;
              }
              if (entry.orderCount) {
                totalOrderCount += entry.orderCount;
              }
              if (entry.itemCount) {
                totalItemCount += entry.itemCount;
              }
            }
          });

          // Chart
          const beginDate = Moment(period).startOf("month");
          const endDate = Moment(period).endOf("month");

          let date = beginDate;

          while (date <= endDate) {
            const day = stats[date.format("YYYY-MM-DD")] || {
              creditsInCount: 0,
              creditsOutCount: 0,
              orderCount: 0,
              itemCount: 0
            };

            //day.date = date.toDate().toISOString();
            day.time = date.format("D");
            chartData.push(day);

            date.add(1, "day");
          }
        }
        this.setState({
          totalCreditsInCount,
          totalCreditsOutCount,
          totalOrderCount,
          totalItemCount,
          chartData
        });
      }
    );
  };

  handlePeriodChange = (period) => {
    this.setState({ period }, this.refreshStats);
  };

  render() {
    const { classes, t, bar } = this.props;
    const {
      period,
      totalCreditsInCount,
      totalCreditsOutCount,
      totalOrderCount,
      totalItemCount,
      chartData
    } = this.state;

    return (
      <div className={classes.container}>
        <ContentCard title={t("mgmt.stats")} isResponsive={false}>
          {chartData && chartData.length > 0 && (
            <div>
              <div className={classes.totals}>
                <Typography className={classes.total}>
                  {`${t("mgmt.credits-in")} `}
                  <strong>{totalCreditsInCount}</strong>
                </Typography>
                <Typography className={classes.total}>
                  {`${t("mgmt.credits-out")} `}
                  <strong>{totalCreditsOutCount}</strong>
                </Typography>
                <Typography className={classes.total}>
                  {`${t("mgmt.orders")} `}
                  <strong>{totalOrderCount}</strong>
                </Typography>
                <Typography className={classes.total}>
                  {`${t("mgmt.consumptions")} `}
                  <strong>{totalItemCount}</strong>
                </Typography>
              </div>
              <div className={classes.spacing}></div>
            </div>
          )}
          <div className={classes.chart}>
            <StatsLineChart
              data={chartData}
              xLabel={t("mgmt.day")}
              yLabel={t("mgmt.amount")}
              lines={[
                { key: "creditsInCount" },
                { key: "creditsOutCount" },
                { key: "orderCount" },
                { key: "itemCount" }
              ]}
              width={700}
              height={450}
            />
          </div>
        </ContentCard>
        <Card className={classes.periodPickerCard}>
          <CardContent className={classes.periodPickerContent}>
            <List>
              <ListItem>
                <SelectMonthDate
                  isFutureDisabled={true}
                  monthDate={period}
                  onChange={this.handlePeriodChange}
                  fullWidth={true}
                />
                <ListItemSecondaryAction>
                  <AuthorizedAppLink
                    bar={bar}
                    endpoint="mgmt/reports/stats"
                    params={{
                      year: period.getFullYear(),
                      month: period.getMonth() + 1
                    }}
                    icon={<ArrowDownwardIcon />}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(Stats)
);
