import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Button, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import withStyles from "@mui/styles/withStyles";

import { orderService } from "tap-io/client/services";
import OrderStatus from "tap-io/models/order/OrderStatus";
import { utilsHelper } from "tap-io/helpers";

import ConfirmOverrideSubOrdersDialog from "./ConfirmOverrideSubOrdersDialog";
import SetOrderReadyForPickupButton from "./SetOrderReadyForPickupButton";

const styles = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  spacing: {
    height: 20
  }
});

class CompleteOrder extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false,
      isConfirmOverrideSubOrdersDialogOpen: false
    };
  }

  handleCompleteOrderClick = async (event) => {
    const { order } = this.props;

    if (order.hasSubOrders) {
      this.setState({
        isConfirmOverrideSubOrdersDialogOpen: true
      });
    } else {
      await this.completeOrder();
    }
  };

  handleConfirmOverrideSubOrders = async () => {
    this.setState({
      isConfirmOverrideSubOrdersDialogOpen: false
    });

    await this.completeOrder();
  };

  handleCancelOverrideSubOrders = () => {
    this.setState({
      isConfirmOverrideSubOrdersDialogOpen: false
    });
  };

  completeOrder = async (event) => {
    const { t, base, order, onDone } = this.props;

    const toastId = toast(t("order.competing-order"), { autoClose: false });
    this.setState({ isDisabled: true });

    try {
      await orderService.setOrderStatusComplete(order, base);

      toast.dismiss(toastId);

      onDone();
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, timeOffset, base, order, onDone } = this.props;
    const { isDisabled, isConfirmOverrideSubOrdersDialogOpen } = this.state;

    const sequenceNumber = base
      ? order.fulfilment.getSequenceNumber(base.id)
      : null;

    let isOrderReadyForPickupYet = true;
    if (
      order.isFulfilmentMethodAsSoonAsPossible &&
      order.isDeliveryMethodPickup
    ) {
      const estimatedRemainingPreparationTime =
        order.getEstimatedRemainingPreparationTime(
          (date) => utilsHelper.calculateRemainingTime(date, timeOffset),
          base
        );

      if (
        estimatedRemainingPreparationTime !== null &&
        estimatedRemainingPreparationTime > 0
      ) {
        isOrderReadyForPickupYet = false;
      }
    }

    return (
      <>
        <ConfirmOverrideSubOrdersDialog
          isOpen={isConfirmOverrideSubOrdersDialogOpen}
          message={t("order.override-suborders-by-completing-order")}
          onConfirm={this.handleConfirmOverrideSubOrders}
          onCancel={this.handleCancelOverrideSubOrders}
        />
        <div className={classes.content}>
          {sequenceNumber !== null && (
            <>
              <Typography variant="h4">
                {t("order.sequence-number")} <strong>{sequenceNumber}</strong>
              </Typography>
              <div className={classes.spacing} />
            </>
          )}
          {order?.getStatus(base) === OrderStatus.CLAIMED ? (
            <>
              {isOrderReadyForPickupYet ? (
                <>
                  <Typography variant="h6">
                    {t("order.complete-order-below")}
                  </Typography>
                  <div className={classes.spacing} />
                  <Button
                    variant="contained"
                    size="large"
                    disabled={isDisabled}
                    onClick={this.handleCompleteOrderClick}
                    startIcon={<CheckIcon />}
                  >
                    {t(
                      order.isDeliveryMethodServe
                        ? "order.order-is-being-delivered"
                        : order.isDeliveryMethodPickup
                        ? "order.order-has-been-picked-up-by-customer"
                        : "order.order-is-ready"
                    )}
                  </Button>
                </>
              ) : (
                <>
                  <Typography variant="h6">
                    {t(
                      "order.inform-the-customer-that-the-order-is-already-ready-to-be-picked-up"
                    )}
                  </Typography>
                  <div className={classes.spacing} />
                  <SetOrderReadyForPickupButton
                    size="large"
                    variant="contained"
                    isDisabled={isDisabled}
                    base={base}
                    order={order}
                    onSet={onDone}
                  />
                  <div className={classes.spacing} />
                  <Button
                    size="small"
                    disabled={isDisabled}
                    onClick={this.handleCompleteOrderClick}
                  >
                    {t("order.order-has-been-picked-up-by-customer-already")}
                  </Button>
                </>
              )}
            </>
          ) : (
            <Typography variant="h6">
              {t("order.cannot-complete-order")}
            </Typography>
          )}
        </div>
      </>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(CompleteOrder));
