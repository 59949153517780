import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  TextField
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import withStyles from "@mui/styles/withStyles";

import { barHelper, utilsHelper } from "tap-io/helpers";
import { barService } from "tap-io/client/services";
import PaymentProvider from "tap-io/models/payment/PaymentProvider";
import config from "tap-io/client/env";

import ConfirmDeactivatePaymentProvider from "../payment/ConfirmDeactivatePaymentProvider";
import CopyToClipboardButton from "../common/CopyToClipboardButton";

const styles = (theme) => ({
  accentuate: {
    color: "red"
  },
  buttons: {
    justifyContent: "flex-end"
  },
  link: {
    textDecoration: "none"
  },
  verificationContainer: {
    margin: theme.spacing(1, 2),
    borderRadius: 4,
    border: `2px solid black`
  },
  verifiedContainer: {
    borderColor: theme.palette.success.main
  },
  notVerifiedContainer: {
    borderColor: theme.palette.error.main
  },
  verified: {
    color: theme.palette.success.main
  },
  notVerified: {
    color: theme.palette.error.main
  } /*,
  disabledText: {
    color: theme.palette.text.disabled
  }*/
});

class EditVivaWallet extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (conf, isReady) => {
    const mergedConf = {
      isVerified: conf && conf.isVerified === true,
      merchantId: conf && conf.merchantId ? conf.merchantId : "",
      apiKey: conf && conf.apiKey ? conf.apiKey : "",
      clientId: conf && conf.clientId ? conf.clientId : "",
      clientSecret: conf && conf.clientSecret ? conf.clientSecret : "",
      sourceCode: conf && conf.sourceCode ? conf.sourceCode : ""
      //showReceipt: conf && conf.showReceipt ? conf.showReceipt : true
    };

    return {
      isConfirmDeactivateVivaWalletOnlinePaymentsDialogOpen: false,
      isConfirmDeactivateVivaWalletPosPaymentsDialogOpen: false,
      isReady: isReady === true,
      isOnlineValid: barHelper.isValidBarVivaWalletOnlinePaymentProviderConf(conf),
      isPosValid: barHelper.isValidBarVivaWalletPosPaymentProviderConf(conf),
      isDisabled: false,
      isAPIKeyRevealed: false,
      isClientSecretRevealed: false,
      conf: mergedConf,
      savedConf: mergedConf
    };
  };

  initConf = () => {
    const { bar } = this.props;

    if (this.unsubscribeVivaWalletConf) {
      this.unsubscribeVivaWalletConf();
      this.unsubscribeVivaWalletConf = undefined;
    }

    this.unsubscribeVivaWalletConf =
      barService.onBarVivaWalletPaymentProviderConf(bar, (conf) => {
        this.setState(this.initialState(conf, true));
      });
  };

  componentDidMount() {
    const { setOnSave } = this.props;

    if (setOnSave) {
      setOnSave(this.handleSaveConf);
    }

    this.initConf();
  }

  componentDidUpdate(prevProps, prevState) {
    const { bar } = this.props;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;

    if (barId !== prevBarId) {
      this.initConf();
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeVivaWalletConf) {
      this.unsubscribeVivaWalletConf();
      this.unsubscribeVivaWalletConf = undefined;
    }
  }

  handleChange = (key) => (event) => {
    const { conf } = this.state;
    const val = event.target.value;

    const newConf = { ...conf, [key]: val };
    const isOnlineValid = barHelper.isValidBarVivaWalletOnlinePaymentProviderConf(newConf);
    const isPosValid = barHelper.isValidBarVivaWalletPosPaymentProviderConf(newConf);

    this.setState({ isOnlineValid, isPosValid, conf: newConf });
  };

  /*handleSwitchChange = (key) => (event) => {
    const { conf } = this.state;
    const val = event.target.checked;

    const newConf = { ...conf, [key]: val };
    const isValid = barHelper.isValidBarVivaWalletPaymentProviderConf(newConf);

    this.setState({ isValid, conf: newConf });
  };*/

  handleRevealAPIKey = (event) => {
    this.setState({ isAPIKeyRevealed: true });
  };

  handleObfuscateAPIKey = (event) => {
    this.setState({ isAPIKeyRevealed: false });
  };

  handleRevealClientSecret = (event) => {
    this.setState({ isClientSecretRevealed: true });
  };

  handleObfuscateClientSecret = (event) => {
    this.setState({ isClientSecretRevealed: false });
  };

  hasConfChanged = () => {
    const { conf, savedConf } = this.state;

    return !utilsHelper.areObjectsEqualShallow(conf, savedConf);
  };

  handleSaveConf = async () => {
    const { t, bar } = this.props;
    const { conf, savedConf } = this.state;

    const toastId = toast(t("settings.saving-vivawallet-configuration"), {
      autoClose: false
    });
    this.setState({ isDisabled: true });

    if (
      conf?.merchantId !== savedConf?.merchantId ||
      conf?.apiKey !== savedConf?.apiKey
    ) {
      conf.isVerified = false;
    }

    try {
      await barService.setBarVivaWalletPaymentProviderConf(bar, conf);

      toast.update(toastId, {
        render: t("settings.vivawallet-configuration-saved"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
      this.setState({ isDisabled: false });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleRestoreConf = () => {
    this.initConf();
  };

  handleAllowVivaWalletOnlinePayments = async (event) => {
    const allowVivaWalletOnlinePayments = event.target.checked === true;

    const { bar } = this.props;

    if (allowVivaWalletOnlinePayments) {
      this.updateAllowVivaWalletPayments(allowVivaWalletOnlinePayments, bar.isAllowingVivaWalletPosPayments());
    } else {
      this.setState({ isConfirmDeactivateVivaWalletOnlinePaymentsDialogOpen: true });
    }
  };

  handleAllowVivaWalletPosPayments = async (event) => {
    const allowVivaWalletPosPayments = event.target.checked === true;

    const { bar } = this.props;

    if (allowVivaWalletPosPayments) {
      this.updateAllowVivaWalletPayments(bar.isAllowingVivaWalletOnlinePayments(), allowVivaWalletPosPayments);
    } else {
      this.setState({ isConfirmDeactivateVivaWalletPosPaymentsDialogOpen: true });
    }
  };

  handleConfirmDeactivateVivaWalletOnlinePayments = () => {
    const { bar } = this.props;

    this.setState({ isConfirmDeactivateVivaWalletOnlinePaymentsDialogOpen: false });

    this.updateAllowVivaWalletPayments(false, bar.isAllowingVivaWalletPosPayments());
  };

  handleCancelDeactivateVivaWalletOnlinePayments = () => {
    this.setState({ isConfirmDeactivateVivaWalletOnlinePaymentsDialogOpen: false });
  };

  handleConfirmDeactivateVivaWalletPosPayments = () => {
    const { bar } = this.props;

    this.setState({ isConfirmDeactivateVivaWalletPosPaymentsDialogOpen: false });

    this.updateAllowVivaWalletPayments(bar.isAllowingVivaWalletOnlinePayments(), false);
  };

  handleCancelDeactivateVivaWalletPosPayments = () => {
    this.setState({ isConfirmDeactivateVivaWalletPosPaymentsDialogOpen: false });
  };

  updateAllowVivaWalletPayments = async (allowVivaWalletOnlinePayments, allowVivaWalletPosPayments) => {
    const { t, bar } = this.props;

    const allowVivaWalletPayments = allowVivaWalletOnlinePayments || allowVivaWalletPosPayments;
    if (allowVivaWalletPayments) {
      await this.handleSaveConf();
    }

    const toastId = toast(t("settings.updating-vivawallet-payments"), { autoClose: false });
    this.setState({ isDisabled: true });

    try {
      await barService.setBarVivaWalletPaymentProviderIsActive(
        bar,
        allowVivaWalletOnlinePayments,
        allowVivaWalletPosPayments
      );

      toast.update(toastId, {
        render: t("settings.vivawallet-payments-updated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, bar, setOnSave } = this.props;
    const {
      isConfirmDeactivateVivaWalletOnlinePaymentsDialogOpen,
      isConfirmDeactivateVivaWalletPosPaymentsDialogOpen,
      isReady,
      isOnlineValid,
      isPosValid,
      isDisabled,
      isAPIKeyRevealed,
      isClientSecretRevealed,
      conf,
      savedConf
    } = this.state;
    const {
      isVerified,
      clientId,
      clientSecret,
      merchantId,
      apiKey,
      sourceCode
      //showReceipt
    } = conf;

    const allowVivaWalletOnlinePayments = bar.isAllowingVivaWalletOnlinePayments();
    const allowVivaWalletPosPayments = bar.isAllowingVivaWalletPosPayments();
    const allowVivaWalletPayments = allowVivaWalletOnlinePayments || allowVivaWalletPosPayments;
    // Conf needs to be saved because it is used for the webhook verification
    const allowWebhookVerification = savedConf?.merchantId && savedConf?.apiKey;

    return (
      <div>
        <ConfirmDeactivatePaymentProvider
          isOpen={isConfirmDeactivateVivaWalletOnlinePaymentsDialogOpen}
          paymentProvider={PaymentProvider.VIVAWALLET}
          onConfirm={this.handleConfirmDeactivateVivaWalletOnlinePayments}
          onCancel={this.handleCancelDeactivateVivaWalletOnlinePayments}
        />
        <ConfirmDeactivatePaymentProvider
          isOpen={isConfirmDeactivateVivaWalletPosPaymentsDialogOpen}
          paymentProvider={PaymentProvider.VIVAWALLET}
          onConfirm={this.handleConfirmDeactivateVivaWalletPosPayments}
          onCancel={this.handleCancelDeactivateVivaWalletPosPayments}
        />
        {isReady ? (
          <List>
            <ListItem>
              <ListItemText
                primary={t("settings.vivawallet-pos-payments-label")}
                secondary={
                  !isPosValid && (
                    <span className={classes.accentuate}>
                      {t(
                        "settings.complete-configuration-below-to-enable-payments"
                      )}
                    </span>
                  )
                }
              />
              <Switch
                onChange={this.handleAllowVivaWalletPosPayments}
                checked={allowVivaWalletPosPayments}
                disabled={isDisabled || (!isPosValid && !allowVivaWalletPosPayments)}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={t("settings.vivawallet-online-payments-label")}
                secondary={
                  !isOnlineValid && (
                    <span className={classes.accentuate}>
                      {t(
                        "settings.complete-configuration-below-to-enable-payments"
                      )}
                    </span>
                  )
                }
              />
              <Switch
                onChange={this.handleAllowVivaWalletOnlinePayments}
                checked={allowVivaWalletOnlinePayments}
                disabled={isDisabled || (!isOnlineValid && !allowVivaWalletOnlinePayments)}
              />
            </ListItem>
            {allowVivaWalletPayments && (
              <>
                <ListItem>
                  <ListItemText
                    primary={t(
                      "settings.problems-with-vivawallet-online-payments"
                    )}
                    secondary={
                      <a
                        className={classes.link}
                        href="https://status.vivawallet.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("settings.check-vivawallet-status-page")}
                      </a>
                    }
                  />
                </ListItem>
              </>
            )}
            <div
              className={classNames(
                classes.verificationContainer,
                isVerified
                  ? classes.verifiedContainer
                  : classes.notVerifiedContainer
              )}
            >
              {isVerified ? (
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon className={classes.verified} />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.verified}
                    primary={
                      <strong>
                        {t("settings.vivawallet-webhook-is-verified")}
                      </strong>
                    }
                  />
                </ListItem>
              ) : (
                <>
                  <ListItem>
                    <ListItemIcon>
                      <ErrorIcon className={classes.notVerified} />
                    </ListItemIcon>
                    <ListItemText
                      classes={{
                        primary: classes.notVerified,
                        secondary: classes.notVerified
                      }}
                      primary={
                        <strong>
                          {t("settings.vivawallet-webhook-verification-needed")}
                        </strong>
                      }
                      secondary={t(
                        allowWebhookVerification
                          ? "settings.copy-the-webhook-url-and-set-it-up-in-your-vivawallet-dashboard"
                          : "settings.enter-merchant-id-and-api-key-and-save-configuration"
                      )}
                    />
                  </ListItem>
                  {allowWebhookVerification && (
                    <CopyToClipboardButton
                      label={t("settings.copy-vivawallet-webhook")}
                      value={`${config.functions.vivaWalletWebhook}/${bar.id}`}
                    />
                  )}
                </>
              )}
            </div>
            <ListItem>
              <TextField
                variant="standard"
                margin="dense"
                label={t("settings.vivawallet-merchant-id-label")}
                helperText={t("settings.vivawallet-merchant-id-description")}
                fullWidth
                value={merchantId}
                onChange={this.handleChange("merchantId")}
                disabled={isDisabled || allowVivaWalletPayments}
                autoComplete="new-password"
                required
              />
            </ListItem>
            <ListItem>
              <TextField
                variant="standard"
                margin="dense"
                label={t("settings.vivawallet-api-key-label")}
                helperText={t("settings.vivawallet-api-key-description")}
                fullWidth
                type={isAPIKeyRevealed ? undefined : "password"}
                value={apiKey}
                onChange={this.handleChange("apiKey")}
                onFocus={this.handleRevealAPIKey}
                onBlur={this.handleObfuscateAPIKey}
                disabled={isDisabled || allowVivaWalletPayments}
                autoComplete="new-password"
                required
              />
            </ListItem>
            <ListItem>
              <TextField
                variant="standard"
                margin="dense"
                label={t("settings.vivawallet-client-id-label")}
                helperText={t("settings.vivawallet-client-id-description")}
                fullWidth
                value={clientId}
                onChange={this.handleChange("clientId")}
                disabled={isDisabled || allowVivaWalletPayments}
                autoComplete="new-password"
                required
              />
            </ListItem>
            <ListItem>
              <TextField
                variant="standard"
                margin="dense"
                label={t("settings.vivawallet-client-secret-label")}
                helperText={t("settings.vivawallet-client-secret-description")}
                fullWidth
                type={isClientSecretRevealed ? undefined : "password"}
                value={clientSecret}
                onChange={this.handleChange("clientSecret")}
                onFocus={this.handleRevealClientSecret}
                onBlur={this.handleObfuscateClientSecret}
                disabled={isDisabled || allowVivaWalletPayments}
                autoComplete="new-password"
                required
              />
            </ListItem>
            <ListItem>
              <TextField
                variant="standard"
                margin="dense"
                label={t("settings.vivawallet-source-code-label")}
                helperText={t("settings.vivawallet-source-code-description")}
                fullWidth
                value={sourceCode}
                onChange={this.handleChange("sourceCode")}
                disabled={isDisabled || allowVivaWalletOnlinePayments}
              />
            </ListItem>
            <CopyToClipboardButton
              label={t("settings.copy-vivawallet-checkout-success")}
              value={`${config.functions.vivaWalletCheckout}/success`}
            />
            <CopyToClipboardButton
              label={t("settings.copy-vivawallet-checkout-failure")}
              value={`${config.functions.vivaWalletCheckout}/failure`}
            />
            {!allowVivaWalletPayments && !setOnSave && (
              <ListItem className={classes.buttons}>
                {this.hasConfChanged() && (
                  <Button
                    color="secondary"
                    className={classes.button}
                    disabled={isDisabled}
                    onClick={this.handleRestoreConf}
                  >
                    {t("label.restore")}
                  </Button>
                )}
                <Button
                  color="primary"
                  className={classes.button}
                  disabled={isDisabled}
                  onClick={this.handleSaveConf}
                >
                  {t("label.save")}
                </Button>
              </ListItem>
            )}
          </List>
        ) : (
          <CircularProgress />
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditVivaWallet)
);

/* Allow to skip the printing + receipt step (disabled for now because of the requirement to show a receipt in the case of credit cards)
<ListItem>
  <ListItemText
    className={
      isDisabled || allowVivaWalletPayments
        ? classes.disabledText
        : undefined
    }
    primary={t("settings.vivawallet-show-receipt-label")}
    secondary={
      <span
        className={
          isDisabled || allowVivaWalletPayments
            ? classes.disabledText
            : undefined
        }
      >
        {t("settings.vivawallet-show-receipt-description")}
      </span>
    }
  />
  <Switch
    checked={showReceipt}
    onChange={this.handleSwitchChange("showReceipt")}
    disabled={isDisabled || allowVivaWalletPayments}
  />
</ListItem>
*/
