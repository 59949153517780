import React, { PureComponent } from "react";

import { Menu } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import FeeConditionType from "tap-io/models/fee/FeeConditionType";

import AddFeeServiceOptionConditionMenuItem from "./AddFeeServiceOptionConditionMenuItem";

const styles = (theme) => ({
  menu: {
    minWidth: 250
  }
});

class AddFeeConditionMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getAvailableFeeConditionTypes = () => {
    const { subscription, conditions } = this.props;

    const availableFeeConditionTypes = subscription
      ? [...subscription.capabilities.getAvailableFeeConditionTypes()]
      : [];

    conditions.forEach((condition) => {
      const index = availableFeeConditionTypes.indexOf(condition.type);
      if (index >= 0) {
        availableFeeConditionTypes.splice(index, 1);
      }
    });

    return availableFeeConditionTypes;
  };

  render() {
    const { classes, anchor, onAdd, onClose } = this.props;

    const isOpen = Boolean(anchor);

    const availableFeeConditionTypes = this.getAvailableFeeConditionTypes();

    // TO FIX: MenuItems wrapped in a <div /> container to circumvent ForwardRef error
    return availableFeeConditionTypes.length === 0 ? null : (
      <Menu anchorEl={anchor} open={isOpen} onClose={onClose}>
        <div className={classes.menu}>
          {availableFeeConditionTypes.map((feeConditionType) => (
            <div key={feeConditionType}>
              {feeConditionType === FeeConditionType.SERVICE_OPTION ? (
                <AddFeeServiceOptionConditionMenuItem onAdd={onAdd} />
              ) : null}
            </div>
          ))}
        </div>
      </Menu>
    );
  }
}

export default withStyles(styles)(AddFeeConditionMenu);
