import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { List, ListItem, Button, Card, CardContent, Typography, IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/Info";
import withStyles from "@mui/styles/withStyles";

import { baseService, orderService } from "tap-io/client/services";
import { utilsHelper } from "tap-io/helpers";
import ConfirmDeferredPaymentButton from "tap-io/client/components/order/ConfirmDeferredPaymentButton";
import OrderConfirmationCode from "tap-io/client/components/order/OrderConfirmationCode";
import OrderDeliveryLabel from "tap-io/client/components/order/OrderDeliveryLabel";
import OrderPriorityLabel from "tap-io/client/components/order/OrderPriorityLabel";
import OrderNote from "tap-io/client/components/order/OrderNote";
import OrderTip from "tap-io/client/components/order/OrderTip";
import OrderName from "tap-io/client/components/order/OrderName";
import ShowCompleteOrderButton from "tap-io/client/components/order/ShowCompleteOrderButton";
import OrderPayment from "tap-io/client/components/order/OrderPayment";
import OrderVouchers from "tap-io/client/components/order/OrderVouchers";
import OrderItems from "tap-io/client/components/order/OrderItems";

import withAuthorization from "../auth/withAuthorization";
import withEstimatedPreparationInfo from "./withEstimatedPreparationInfo";
import ConfirmOverrideSubOrdersDialog from "./ConfirmOverrideSubOrdersDialog";
import OrderEstimatedPreparationDurationLabel from "./OrderEstimatedPreparationDurationLabel";
import OrderSequenceNumberLabel from "./OrderSequenceNumberLabel";
import OrderDeliveryIcon from "./OrderDeliveryIcon";
import SelectOrderPreparationOption from "./SelectOrderPreparationOption";
import SkipOrderPreparationOption from "./SkipOrderPreparationOption";
import NoOrderPreparationOption from "./NoOrderPreparationOption";
import OrderEstimatedPreparationDurationMessage from "./OrderEstimatedPreparationDurationMessage";
import OrderCardOverlay from "./OrderCardOverlay";

const styles = (theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    minHeight: 320,
    margin: theme.spacing(1),
    borderRadius: 6
  },
  list: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: `0px !important`
  },
  fadedContent: {
    opacity: 0.5
  },
  info: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    //padding: 3,
    textAlign: "center",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  error: {
    display: "block",
    //padding: 3,
    textAlign: "center",
    backgroundColor: "#ffebee",
    color: "#c62828"
  },
  noBorder: {
    backgroundColor: "rgb(245, 245, 245)"
  },
  highlightedBorder: {
    border: `2px solid ${theme.palette.primary.main}`
    /*boxShadow:
      "0px -1px 10px 0px rgba(207, 173, 55, 0.4), 0px 1px 5px 0px rgba(207, 173, 55, 0.28), 0px 0px 1px 1px rgba(207, 173, 55, 0.24)"*/
  },
  errorBorder: {
    border: `2px solid ${theme.palette.error.main}`
    /*boxShadow:
      "0px -1px 10px 0px rgba(198, 40, 40, 0.4), 0px 1px 5px 0px rgba(198, 40, 40, 0.28), 0px 0px 1px 1px rgba(198, 40, 40, 0.24)"*/
  },
  cardActions: {
    justifyContent: "center",
    padding: 0
  },
  orderReadyButton: {
    height: 60,
    borderRadius: 0
  },
  elevatedOrderReadyButton: {
    zIndex: 100
  },
  subOrders: {
    padding: theme.spacing(),
    borderRadius: 4,
    backgroundColor: theme.palette.background.default
  },
  subOrdersHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(0.5)
  },
  subOrdersHeaderTitle: {
    flexGrow: 1,
    fontWeight: "bold",
    textAlign: "left"
  },
  spacing: {
    height: 20
  },
  completeButton: {
    width: "100%",
    fontSize: 18,
    fontWeight: "bold"
  },
  header: {
    minHeight: 56
  },
  content: {
    flexGrow: 1
  },
  orderContent: {
    marginBottom: theme.spacing(),
    backgroundColor: "rgb(240, 240, 240)"
  },
  orderActions: {
    justifyContent: "center",
    textAlign: "center"
  },
  filler: {
    flexGrow: 1,
    minWidth: theme.spacing()
  },
  highlight: {
    backgroundColor: "rgb(230, 230, 230)"
  },
  labels: {
    //display: "flex",
    flexWrap: "wrap",
    "& > *": {
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5)
    }
  },
  confirmDeferredPaymentButton: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  }
});

class OrderCard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      overrideSubOrdersFunction: null,
      overrideSubOrdersMessage: null,
      infoMessage: null,
      isClaimingOrderStatus: false,
      isCompletingOrderStatus: false
    };

    this.isBusy = false;
  }

  setInfoMessage = (infoMessage) => {
    this.setState({ infoMessage });
  };

  clearInfoMessage = () => {
    this.setState({ infoMessage: null });
  };

  handleContentClick = () => {
    const { base, order } = this.props;

    if (!order.isDeliveryMethodPickup && order.isStatusQueued(base)) {
      this.handleSelectPreparationOption();
    }
  }

  handleSelectPreparationOption = async (preparationOption) => {
    const { order } = this.props;

    if (order.hasSubOrders) {
      this.setState({
        isConfirmOverrideSubOrdersDialogOpen: true,
        preparationOption
      });
    } else {
      await this.claimOrder(preparationOption);
    }
  };

  claimOrder = async (preparationOption) => {
    const { t, base, order } = this.props;

    if (this.isBusy) {
      return;
    }
    this.isBusy = true;
    this.setState({ isClaimingOrderStatus: true });

    const disableBusy = () => {
      // Safari disabled glitch 'fix'
      this.setState({ isClaimingOrderStatus: false });
      setTimeout(() => {
        this.isBusy = false;
      }, 500);
    };

    try {
      await orderService.setOrderStatusClaimed(
        order,
        base,
        undefined,
        baseService.getNextSequenceNumber,
        preparationOption?.duration || null
      );

      disableBusy();
    } catch (error) {
      console.warn(error);

      disableBusy();
    }
  };

  /*handleClaimOrder = () => {
    const { t } = this.props;

    if (this.isBusy) {
      return;
    }
    this.isBusy = true;
    this.setState({ isClaimingOrderStatus: true });

    const disableBusy = () => {
      // Safari disabled glitch 'fix'
      this.setState({ isClaimingOrderStatus: false });
      setTimeout(() => {
        this.isBusy = false;
      }, 500);
    };

    this.claimOrder()
      .then(disableBusy)
      .catch((error) => {
        console.warn(error);

        disableBusy();

        toast.error(error ? error.message : t("error.unknown-error"));
      });
  };

  claimOrder = async () => {
    const { t, base, order, onOrderClaimed } = this.props;

    if (order.isStatusQueued(base)) {
      this.setInfoMessage(t("order.opening-order"));

      try {
        await orderService.setOrderStatusClaimed(
          order,
          base,
          undefined,
          baseService.getNextSequenceNumber
        );

        this.clearInfoMessage();

        if (onOrderClaimed) {
          onOrderClaimed();
        }
      } catch (error) {
        this.clearInfoMessage();

        throw error;
      }
    }
  };*/

  handleCheckAndRunOrConfirmFunction =
    (functionToRun, confirmationMessage) => (event) => {
      event.stopPropagation();

      if (this.isBusy) {
        return;
      }

      const { order } = this.props;

      if (order.hasSubOrders) {
        this.handleOverrideSubOrders(functionToRun, confirmationMessage);
      } else {
        functionToRun();
      }
    };

  handleOverrideSubOrders = (
    overrideSubOrdersFunction,
    overrideSubOrdersMessage
  ) => {
    this.setState({
      overrideSubOrdersFunction,
      overrideSubOrdersMessage: overrideSubOrdersMessage || null
    });
  };

  handleConfirmOverrideSubOrders = () => {
    const { overrideSubOrdersFunction } = this.state;

    this.setState({
      overrideSubOrdersFunction: null,
      overrideSubOrdersMessage: null
    });

    if (overrideSubOrdersFunction) {
      overrideSubOrdersFunction();
    }
  };

  handleCancelOverrideSubOrders = () => {
    this.setState({
      overrideSubOrdersFunction: null,
      overrideSubOrdersMessage: null
    });
  };

  handleCompleteOrder = (event) => {
    // Handled by handleCheckAndRunOrConfirmFunction()
    // event.stopPropagation();

    const { t } = this.props;

    if (this.isBusy) {
      return;
    }
    this.isBusy = true;
    this.setState({ isCompletingOrderStatus: true });

    this.completeOrder()
      .then(() => {
        // Order is complete (not shown anymore)
      })
      .catch((error) => {
        console.warn(error);

        this.isBusy = false;
        this.setState({ isCompletingOrderStatus: false });

        toast.error(error ? error.message : t("error.unknown-error"));
      });
  };

  completeOrder = async (event) => {
    const { base, order } = this.props;

    await orderService.setOrderStatusComplete(order, base);
  };

  handleStopPropagation = (event) => {
    event.stopPropagation();
  };

  render() {
    const { classes, t, width, auth, timeOffset, bar, base, order, onClick, estimatedPreparationInfo } =
      this.props;
    const {
      overrideSubOrdersFunction,
      overrideSubOrdersMessage,
      infoMessage,
      isClaimingOrderStatus,
      isCompletingOrderStatus
    } = this.state;

    const overallStatus = order.getStatus();
    const isStatusQueued = order.isStatusQueued(base);
    const isStatusClaimed = order.isStatusClaimed(base);
    const isStatusComplete = order.isStatusComplete(base);
    const isStatusError = order.isStatusError(base);

    const isBusy = isClaimingOrderStatus || isCompletingOrderStatus;

    const shouldPreparationBeComplete = base && order.shouldPreparationBeComplete(
      (date) => utilsHelper.calculateRemainingTime(date, timeOffset),
      base
    );

    return (
      <>
        <ConfirmOverrideSubOrdersDialog
          isOpen={overrideSubOrdersFunction !== null}
          message={overrideSubOrdersMessage}
          onConfirm={this.handleConfirmOverrideSubOrders}
          onCancel={this.handleCancelOverrideSubOrders}
        />
        <Card
          className={classNames(classes.card, {
            [classes.errorBorder]: isStatusError,
            [classes.highlightedBorder]: isStatusClaimed,
            [classes.noBorder]: !isStatusError && !isStatusClaimed
          })}
          style={{ width }}
        >
          {isStatusClaimed &&
            <>
              <Button
                disabled={isBusy}
                size="large"
                color="primary"
                variant="contained"
                fullWidth
                className={classNames(classes.orderReadyButton, { [classes.elevatedOrderReadyButton]: shouldPreparationBeComplete })}
                onClick={this.handleCheckAndRunOrConfirmFunction(
                  this.handleCompleteOrder,
                  t("order.override-suborders-by-completing-order")
                )}
                startIcon={!isCompletingOrderStatus && <CheckIcon />}
              >
                <div className={classes.completeButton}>
                  {isCompletingOrderStatus
                    ? t("order.completing-order")
                    : t(order.isDeliveryMethodPickup ? "order.order-is-picked-up" : "order.order-is-ready")}
                </div>
              </Button>
              <OrderCardOverlay
                timeOffset={timeOffset}
                bar={bar}
                base={base}
                order={order}
                estimatedPreparationInfo={estimatedPreparationInfo}
              />
            </>
          }
          {infoMessage && (
            <Typography variant="overline" className={classes.info}>
              {infoMessage}
            </Typography>
          )}
          {isStatusError && (
            <Typography variant="overline" className={classes.error}>
              {overallStatus.error && overallStatus.error.text
                ? overallStatus.error.text
                : `${t("label.unknown-error")}`}
            </Typography>
          )}
          <CardContent
            className={classNames(classes.container, { [classes.fadedContent]: isStatusComplete })}
            disabled={isBusy}
          >
            <List className={classes.list}>
              <ListItem dense={true} className={classes.header}>
                <OrderName
                  isDisabled={isBusy}
                  order={order}
                  showInfoButton={true}
                  variant="h6"
                />
                <IconButton
                  edge="end"
                  disabled={isBusy}
                  onClick={(event) => onClick(order)}
                  size="large"
                >
                  <InfoIcon />
                </IconButton>
                <div className={classes.filler} />
                <OrderDeliveryIcon order={order} />
              </ListItem>
              <div className={classes.content} onClick={this.handleContentClick}>
                {isStatusQueued
                  ? (
                    <div className={classes.orderContent}>
                      {order.isDeliveryMethodPickup && base &&
                        <>
                          <div className={classes.highlight}>
                            <OrderItems order={order} base={base} isCompact={false} hidePrice={true} />
                          </div>
                          <div className={classes.spacing} />
                        </>
                      }
                      <ListItem className={classes.orderActions}>
                        {order.isDeliveryMethodPickup ? (
                          base && base.preparationOptions.length > 0 ? (
                            <SelectOrderPreparationOption
                              variant="h6"
                              size="large"
                              isCompact={true}
                              isDisabled={isBusy}
                              base={base}
                              order={order}
                              onSelect={this.handleSelectPreparationOption}
                            />
                          ) : (
                            <SkipOrderPreparationOption
                              variant="h6"
                              size="large"
                              isCompact={true}
                              isDisabled={isBusy}
                              order={order}
                              onSelect={this.handleSelectPreparationOption}
                            />
                          )
                        ) : (
                          <NoOrderPreparationOption
                            variant="body2"
                            size="large"
                            isCompact={true}
                            isDisabled={isBusy}
                            order={order}
                            onSelect={this.handleSelectPreparationOption}
                          />
                        )}
                      </ListItem>
                    </div>
                  )
                  : isStatusClaimed
                    ? (
                      <div className={classes.orderContent}>
                        <OrderNote order={order} />
                        <OrderItems order={order} base={base} isCompact={false} hidePrice={true} />
                        <OrderTip order={order} />
                      </div>
                    )
                    : null}
                <ListItem className={classes.labels}>
                  <OrderPriorityLabel
                    order={order}
                    base={base}
                  />
                  {
                    order.hasBeenClaimed(base) && (
                      <>
                        <OrderEstimatedPreparationDurationLabel
                          timeOffset={timeOffset}
                          order={order}
                          bar={bar}
                          base={base}
                          estimatedPreparationInfo={estimatedPreparationInfo}
                        />
                        <OrderSequenceNumberLabel
                          order={order}
                          base={base}
                          isCompact={true}
                        />
                        <OrderDeliveryLabel
                          order={order}
                          base={base}
                          hideDeliveryMethod={true}
                          hideColorText={true}
                        />
                      </>
                    )
                  }
                </ListItem>
                <OrderConfirmationCode order={order} />
                {!order.isPaid() &&
                  <>
                    <OrderPayment bar={bar} order={order} />
                    <OrderVouchers order={order} />
                  </>
                }
              </div>
            </List>
          </CardContent>
          <ConfirmDeferredPaymentButton
            className={classes.confirmDeferredPaymentButton}
            auth={auth}
            bar={bar}
            order={order}
            isDisabled={isBusy}
            fullWidth={true}
            size="large"
          />
        </Card>
      </>
    );
  }
}

export default withAuthorization()(
  withStyles(styles)(
    withTranslation("common")(
      withEstimatedPreparationInfo()(
        OrderCard
      )
    )
  )
);
