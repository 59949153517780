import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: -theme.spacing(1)
  },
  subOrder: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    border: "1px solid #aaa",
    borderRadius: 20
  },
  statusIcon: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: "black"
  },
  sentStatusIcon: {
    backgroundColor: theme.palette.success.main
  },
  notYetSentStatusIcon: {
    backgroundColor: theme.palette.error.main
  },
  label: {
    marginLeft: theme.spacing()
  }
});

class OrderSubOrders extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, order } = this.props;

    return (
      <div className={classes.container}>
        {order.subOrders.map((subOrder, index) => (
          <div key={index} className={classes.subOrder}>
            <div
              className={classNames(
                classes.statusIcon,
                subOrder.isSent
                  ? classes.sentStatusIcon
                  : classes.notYetSentStatusIcon
              )}
            />
            <span className={classes.label}>
              {subOrder.label ? subOrder.label : `(${t("label.unknown")})`}
            </span>
          </div>
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(OrderSubOrders));
