import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import Moment from "moment";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center"
  }
});

const renderDayTick = (tickProps) => {
  const { x, y, payload } = tickProps;
  const { value, offset } = payload;
  const date = new Date(value);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  if (minutes !== 0) {
    return null;
  }

  switch (hours) {
    case 0:
      return <path d={`M${x + 0.5},${y - 4}v${-35}`} stroke="#ccc" />;
    case 11:
      return (
        <text
          x={x + offset}
          y={y - 4}
          textAnchor="middle"
          fill="#ccc"
        >{`${Moment(date).format("DD/MM")}`}</text>
      );
  }

  return null;
};

class StatsLineChart extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { t, data, lines, xLabel, yLabel, width, height } = this.props;

    if (data === undefined || data.length === 0) {
      return (
        <Typography variant="caption">{t("stats.no-data-to-show")}</Typography>
      );
    }

    return (
      <LineChart
        width={width ? width : 500}
        height={height ? height : 320}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 30
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          axisLine={false}
          tickLine={false}
          interval={0}
          tick={renderDayTick}
          height={1}
          scale="band"
          xAxisId="day"
        />
        <XAxis dataKey="time">
          <Label
            value={xLabel}
            position="insideBottom"
            style={{ textAnchor: "middle" }}
            dy={25}
          />
        </XAxis>
        <YAxis>
          <Label
            value={yLabel}
            position="insideLeft"
            angle={-90}
            style={{ textAnchor: "middle" }}
          />
        </YAxis>
        <Tooltip />
        {lines &&
          lines.map((line) => (
            <Line
              key={line.key}
              type="monotone"
              dataKey={line.key}
              stroke="#8884d8"
              dot={false}
              activeDot={{ r: 8 }}
            />
          ))}
      </LineChart>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(StatsLineChart));
/*["top","left","right","bottom","inside","outside","insideLeft","insideRight","insideTop","insideBottom","insideTopLeft","insideBottomLeft","insideTopRight","insideBottomRight","insideStart","insideEnd","end","center","centerTop","centerBottom"] */
