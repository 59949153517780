import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import { AppBar, Button, IconButton, Toolbar, Typography } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TollIcon from "@mui/icons-material/Toll";
import WarningIcon from "@mui/icons-material/Warning";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import SettingsIcon from "@mui/icons-material/Settings";
import withStyles from "@mui/styles/withStyles";

import { hasNativeWrapper, scanner } from "../../native";
import * as routes from "../../constants/routes";
import CurrentPlanButton from "../subscription/CurrentPlanButton";
import ScanOrdersButton from "../order/ScanOrdersButton";

const styles = (theme) => ({
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  appTitle: {
    flexGrow: 1,
    marginLeft: 20
  },
  barName: {
    textAlign: "left"
  },
  link: {
    textDecoration: "none !important",
    color: "inherit"
  },
  infiniteSymbol: {
    fontFamily: "Arial, Helvetica, sans-serif",
    fontSize: 26,
    lineHeight: "22px"
  },
  warningIcon: {
    color: "orange"
  },
  errorIcon: {
    color: "red"
  },
  buttonText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }
});

class BarHeader extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOrdersDrawerOpen: false,
      isMenuDrawerOpen: false
    };
  }

  render() {
    const {
      classes,
      t,
      drawerWidth,
      isMenuDrawerOpen,
      onOrdersButtonClick,
      onCreateOrderButtonClick,
      onSettingsButtonClick,
      onSelectBaseButtonClick,
      isAppLocked,
      timeOffset,
      mode,
      bar,
      subscription,
      base,
      balance,
      assets,
      deviceSettings,
      openOrders,
      unlockApp
    } = this.props;

    return (
      <AppBar
        position="fixed"
        className={classNames(classes.appBar, {
          "App-header-ios": hasNativeWrapper(),
          [classes.appBarShift]: isMenuDrawerOpen
        })}
        style={{
          width: `calc(100% - ${isMenuDrawerOpen ? drawerWidth : 0}px)`,
          marginRight: isMenuDrawerOpen ? drawerWidth : 0
        }}
      >
        <Toolbar>
          {mode && mode.fetchAllOrders && (
            <IconButton
              color="inherit"
              onClick={onOrdersButtonClick}
              size="large"
            >
              <FormatListBulletedIcon />
            </IconButton>
          )}
          {mode && mode.showCreateOrderButton && (
            <IconButton
              color="inherit"
              onClick={onCreateOrderButtonClick}
              size="large"
            >
              <AddToPhotosIcon />
            </IconButton>
          )}
          {scanner.isScannerAvailable() &&
            mode &&
            mode.showScanOrdersButton && (
              <ScanOrdersButton
                timeOffset={timeOffset}
                bar={bar}
                base={base}
                assets={assets}
                deviceSettings={deviceSettings}
              />
            )}
          <Link
            to={mode && mode.defaultRoute ? mode.defaultRoute : routes.MODES}
            className={classNames(classes.appTitle, classes.link)}
          >
            <Typography
              variant="h6"
              noWrap
              color="inherit"
              className={classes.barName}
            >
              {bar ? bar.name : t("label.bar")}
            </Typography>
          </Link>
          {bar && bar.isUsingBases() && mode && mode.isUsingBases && (
            <Button
              color="inherit"
              startIcon={<CallSplitIcon />}
              onClick={onSelectBaseButtonClick}
            >
              <span className={classes.buttonText}>
                {base ? base.name : t("base.base")}
              </span>
            </Button>
          )}
          {openOrders && (
            <Link to={routes.ORDERS} className={classes.link}>
              <Button color="inherit" startIcon={<ReceiptIcon />}>
                {openOrders.length}
              </Button>
            </Link>
          )}
          {balance && (
            <Link to={routes.BALANCE} className={classes.link}>
              <Button
                color="inherit"
                startIcon={<TollIcon />}
                endIcon={
                  !balance.doNotChargeCredits &&
                  !balance.allowCreditDebt &&
                  balance.isLowOnCredits() && (
                    <WarningIcon
                      className={classNames(classes.warningIcon, {
                        [classes.errorIcon]: !balance.hasCredits()
                      })}
                    />
                  )
                }
              >
                {balance.doNotChargeCredits || balance.allowCreditDebt ? (
                  <span className={classes.infiniteSymbol}>∞</span>
                ) : (
                  balance.creditCount
                )}
              </Button>
            </Link>
          )}
          {subscription && (
            <CurrentPlanButton
              isAllowedToShowPlan={!isAppLocked}
              requestPermissionToShowPlan={unlockApp}
              bar={bar}
              subscription={subscription}
            />
          )}
          {!isMenuDrawerOpen && (
            <IconButton
              color="inherit"
              onClick={onSettingsButtonClick}
              size="large"
            >
              <SettingsIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(BarHeader));
