import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import withStyles from "@mui/styles/withStyles";

import { baseService, menuService } from "tap-io/client/services";
import ConfirmDialog from "tap-io/client/components/common/ConfirmDialog";

import AddBaseDialog from "./AddBaseDialog";
import EditBaseDialog from "./EditBaseDialog";
import CapabilityMaxAmountIndicator from "../subscription/CapabilityMaxAmountIndicator";

const styles = (theme) => ({
  baseName: {
    display: "flex",
    alignItems: "center"
  },
  lockIcon: {
    marginLeft: theme.spacing(0.5),
    fontSize: 16,
    color: theme.palette.text.primary
  },
  baseText: {
    marginRight: 80
  },
  deliveryColor: {
    display: "inline-block",
    width: 10,
    height: 10,
    marginRight: 5,
    borderRadius: 5
  }
});

class EditBases extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isAddBaseDialogOpen: false,
      basePendingToggleIsActiveConfirmation: null,
      basePendingRemovalConfirmation: null,
      baseBeingEdited: null
    };
  }

  handleAddBase = (event) => {
    this.setState({ isAddBaseDialogOpen: true });
  };

  handleAddBaseDialogClose = () => {
    this.setState({ isAddBaseDialogOpen: false });
  };

  handleRemoveBase = (base) => (event) => {
    event.stopPropagation();

    this.setState({
      basePendingRemovalConfirmation: base
    });
  };

  handleToggleBaseIsActive = (base) => (event) => {
    event.preventDefault();

    this.setState({ basePendingToggleIsActiveConfirmation: base });
  };

  handleToggleBaseIsActiveConfirm = async () => {
    const { basePendingToggleIsActiveConfirmation } = this.state;

    this.setState({ basePendingToggleIsActiveConfirmation: null });

    await this.toggleBaseIsActive(basePendingToggleIsActiveConfirmation);
  };

  handleToggleBaseIsActiveCancel = () => {
    this.setState({ basePendingToggleIsActiveConfirmation: null });
  };

  handleRemoveBaseConfirm = async () => {
    const { t } = this.props;
    const { basePendingRemovalConfirmation } = this.state;

    this.setState({ basePendingRemovalConfirmation: null });

    const toastId = toast(t("base.removing-base"), {
      autoClose: false
    });

    try {
      await baseService.removeBase(basePendingRemovalConfirmation);

      toast.update(toastId, {
        render: t("base.base-removed"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleRemoveBaseCancel = () => {
    this.setState({ basePendingRemovalConfirmation: null });
  };

  handleEditBase = (base) => (event) => {
    this.setState({ baseBeingEdited: base });
  };

  handleEditBaseDialogClose = () => {
    this.setState({ baseBeingEdited: null });
  };

  toggleBaseIsActive = async (base) => {
    const { t } = this.props;

    const toastId = toast(
      base.isActive ? t("base.closing-base") : t("base.opening-base"),
      {
        autoClose: false
      }
    );

    try {
      base.isActive = !base.isActive;
      await baseService.updateBase(
        base,
        menuService.addSetMenuIsActiveByIdInBatch
      );

      toast.update(toastId, {
        render: base.isActive ? t("base.base-opened") : t("base.base-closed"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, bar, subscription, allBases, hideLinks, isEditable } =
      this.props;
    const {
      isAddBaseDialogOpen,
      basePendingToggleIsActiveConfirmation,
      basePendingRemovalConfirmation,
      baseBeingEdited
    } = this.state;

    return (
      <div>
        <AddBaseDialog
          isOpen={isAddBaseDialogOpen}
          onClose={this.handleAddBaseDialogClose}
          bar={bar}
          subscription={subscription}
          hideLinks={hideLinks}
        />
        <EditBaseDialog
          isOpen={baseBeingEdited !== null}
          onClose={this.handleEditBaseDialogClose}
          bar={bar}
          base={baseBeingEdited}
          subscription={subscription}
          hideLinks={hideLinks}
        />
        <ConfirmDialog
          isOpen={basePendingToggleIsActiveConfirmation !== null}
          title={t(
            basePendingToggleIsActiveConfirmation?.isActive
              ? "base.close-base"
              : "base.open-base"
          )}
          description={t(
            basePendingToggleIsActiveConfirmation?.isActive
              ? "base.are-you-sure-you-want-to-close-this-base"
              : "base.are-you-sure-you-want-to-open-this-base"
          )}
          confirmButtonLabel={t(
            basePendingToggleIsActiveConfirmation?.isActive
              ? "base.close-base"
              : "base.open-base"
          )}
          onConfirm={this.handleToggleBaseIsActiveConfirm}
          onCancel={this.handleToggleBaseIsActiveCancel}
        />
        <ConfirmDialog
          isOpen={basePendingRemovalConfirmation !== null}
          title={t("base.confirm-removal-of-base")}
          description={t("base.are-you-sure-you-want-to-remove-this-base")}
          confirmButtonLabel={t("label.remove")}
          onConfirm={this.handleRemoveBaseConfirm}
          onCancel={this.handleRemoveBaseCancel}
        />
        <List>
          {allBases && subscription && (
            <ListItem>
              <CapabilityMaxAmountIndicator
                currentAmount={allBases.length}
                maxAmount={subscription.capabilities.getMaxAmountOfBases()}
                amountDescription="base.amount-of-bases-used"
                amountExceededDescription="base.max-amount-of-bases-exceeded-remove-bases-to-continue"
              />
            </ListItem>
          )}
          {allBases &&
            allBases.map((base) => (
              <ListItem
                key={base.id}
                onClick={
                  isEditable || base?.externalId
                    ? this.handleEditBase(base)
                    : undefined
                }
              >
                {isEditable && (
                  <ListItemIcon>
                    <IconButton
                      onClick={this.handleRemoveBase(base)}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={
                    <span className={classes.baseName}>
                      {base.name}
                      {!base.isEditable && (
                        <LockIcon className={classes.lockIcon} />
                      )}
                    </span>
                  }
                  secondary={
                    <span>
                      {base.deliveryColors.colors
                        .sort()
                        .map((deliveryColor) => (
                          <span
                            key={deliveryColor}
                            className={classes.deliveryColor}
                            style={{ backgroundColor: deliveryColor }}
                          />
                        ))}
                    </span>
                  }
                  className={classes.baseText}
                />
                <ListItemSecondaryAction>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={!isEditable && !base?.externalId}
                        onChange={this.handleToggleBaseIsActive(base)}
                        checked={base.isActive}
                      />
                    }
                    label={
                      <Typography variant="caption">
                        {t("base.opened-question")}
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          {isEditable &&
            subscription &&
            allBases && allBases.length <
              subscription.capabilities.getMaxAmountOfBases() && (
              <ListItem>
                <Button
                  fullWidth
                  startIcon={<AddIcon />}
                  onClick={this.handleAddBase}
                >
                  {t("base.add-base")}
                </Button>
              </ListItem>
            )}
        </List>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditBases)
);
