import { VFC } from "react";

import { useTranslation } from "react-i18next";

import { MenuItem, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import EnumSelectorProps from "./EnumSelectorProps";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyLocation: {
      fontStyle: "italic"
    }
  })
);

const EnumSelector: VFC<EnumSelectorProps> = ({
  enumeration,
  renderLabel,
  allowEmpty,
  ...textFieldProps
}) => {
  const { t, i18n } = useTranslation("common");
  const classes = useStyles();

  return (
    <TextField select {...textFieldProps}>
      {allowEmpty && (
        <MenuItem key="" value="" className={classes.emptyLocation}>
          {t("label.none")}
        </MenuItem>
      )}
      {Object.values(enumeration).map((v) => (
        <MenuItem key={v} value={v}>
          {renderLabel ? renderLabel(v) : v}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default EnumSelector;
