import React, { PropsWithChildren, useState } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { IconButton } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";

import config from "tap-io/client/env";
import Bar from "tap-io/models/bar/Bar";
import AbiIntegration from "tap-io/models/integration/AbiIntegration";
import { integrationService } from "tap-io/client/services";

import withAuthorization from "../auth/withAuthorization";
import IntegrationItem from "./IntegrationItem";
import SetAbiIntegrationDialog from "./SetAbiIntegrationDialog";

type AbiIntegrationItemProps = {
  t: any;
  auth: any;
  bar: Bar;
  integration?: AbiIntegration;
};

function AbiIntegrationItem({
  t,
  auth,
  bar,
  integration
}: PropsWithChildren<AbiIntegrationItemProps>) {
  const [isSetAbiIntegrationDialogOpen, setIsSetAbiIntegrationDialogOpen] =
    useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleIntegrationClick = () => {
    setIsSetAbiIntegrationDialogOpen(true);
  };

  const handleCloseSetAbiIntegrationDialog = () => {
    setIsSetAbiIntegrationDialogOpen(false);
  };

  const handleSync = async () => {
    if (auth?.user?.uid && bar?.id && integration?.isConfigured) {
      const toastId = toast(t("integration.syncing-abi"), {
        autoClose: false
      });

      setIsDisabled(true);

      try {
        await integrationService.syncAbi(
          config.functions.integrationsApi,
          auth.user,
          bar.id
        );

        setIsDisabled(false);

        toast.update(toastId, {
          render: t("integration.abi-synced"),
          type: toast.TYPE.INFO,
          autoClose: 3000
        });
      } catch (error) {
        console.warn(error);
        setIsDisabled(false);

        toast.update(toastId, {
          render: `${t("label.something-went-wrong")} (${t(
            error ? error.message : "error.unknown-error"
          )})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
      }
    }
  };

  return (
    <>
      <SetAbiIntegrationDialog
        isOpen={isSetAbiIntegrationDialogOpen}
        onClose={handleCloseSetAbiIntegrationDialog}
        bar={bar}
        integration={integration}
      />
      <IntegrationItem
        isDisabled={isDisabled}
        onClick={handleIntegrationClick}
        name={t("integration.abi-name")}
        description={t("integration.abi-description")}
        doesExist={!!integration}
        isActive={integration ? integration.isActive : false}
        isConfigured={integration ? integration.isConfigured : false}
        quickAction={
          integration &&
          integration.isConfigured && (
            <IconButton disabled={isDisabled} onClick={handleSync} size="large">
              <SyncIcon />
            </IconButton>
          )
        }
      />
    </>
  );
}

export default withAuthorization()(
  withTranslation("common")(AbiIntegrationItem)
);
