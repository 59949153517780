import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { utilsHelper } from "tap-io/helpers";

const styles = (theme) => ({
  dialog: {
    minWidth: 550
  }
});

class EnterVoucherValueDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = () => {
    return {
      isValid: false,
      voucherValue: ""
    };
  };

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.props;

    if (isOpen && !prevProps.isOpen) {
      this.setState(this.initialState());
    }
  }

  handleVoucherValueChange = (event) => {
    const value = event.target.value;

    const parsedValue = parseFloat(value);

    this.setState({
      isValid: !isNaN(parsedValue) && parsedValue > 0,
      voucherValue: value
    });
  };

  handleConfirm = (event) => {
    const { onClose } = this.props;
    const { isValid, voucherValue } = this.state;

    if (!isValid) {
      return toast.error("error.voucher-value-is-not-valid");
    }

    onClose(utilsHelper.roundToTwoDecimals(voucherValue));
  };

  handleClose = (event) => {
    const { onClose } = this.props;

    onClose();
  };

  render() {
    const { classes, t, isOpen, bar } = this.props;
    const { isValid, voucherValue } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={this.handleClose}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle>{t("voucher.enter-voucher-value")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("voucher.enter-voucher-value-below-below")}
          </DialogContentText>
          <div className={classes.inputContainer}>
            <Typography className={classes.inputLabel}>
              {`${t("voucher.value-per-voucher")} `}
              {bar && bar.params && bar.params.orderCurrency ? (
                <span>
                  (<strong>{bar.params.orderCurrency}</strong>)
                </span>
              ) : (
                ""
              )}
            </Typography>
            <TextField
              variant="standard"
              type="number"
              color="secondary"
              error={!isValid}
              InputProps={{ inputProps: { min: 0 } }}
              value={`${voucherValue}`}
              onChange={this.handleVoucherValueChange}
              onBlur={this.handleVoucherValueBlur}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="secondary">
            {t("label.cancel")}
          </Button>
          <Button onClick={this.handleConfirm} color="primary">
            {t("label.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(EnterVoucherValueDialog)
);
