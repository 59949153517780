import { Stat } from "./Stat";

type Data = {
  hour: number;
  amountOfOrders: number;
  amountOfProducts: number;
};

export class OperationalTimeslotReport extends Stat<Data> {
  get activeHours() {
    const activeHoursStart =
      this.data.findIndex((p) => p.amountOfOrders !== 0) - 2;
    const activeHoursEnd =
      this.data.length -
      1 -
      this.data.findIndex(
        (_, idx) => this.data[this.data.length - 1 - idx].amountOfOrders !== 0
      ) +
      2;
    const activeHours =
      activeHoursStart >= 0
        ? activeHoursStart === -1
          ? []
          : this.data.filter(
              (_, idx) => activeHoursStart < idx && idx < activeHoursEnd
            )
        : [];
    return activeHours;
  }
  get totalOrders() {
    return this.data.reduce(
      (total, report) => total + report.amountOfOrders,
      0
    );
  }
  get totalProducts() {
    return this.data.reduce(
      (total, report) => total + report.amountOfProducts,
      0
    );
  }

  toCSV(reference: Date, t: any) {
    return this.generateCSV(
      {
        hour: this.formatHour(reference),
        amountOfOrders: this.formatInteger,
        amountOfProducts: this.formatInteger
      },
      t
    );
  }
}
