import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { zoneService } from "tap-io/client/services";
import { utilsHelper } from "tap-io/helpers";

import ZoneLayoutNode from "tap-io/models/zone/ZoneLayoutNode";
import EditLayout from "./EditLayout";
import CapabilityUnavailableButton from "../subscription/CapabilityUnavailableButton";

const styles = (theme) => ({
  largeDescription: {
    minHeight: 150
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  spacing: {
    display: "block",
    height: 15
  },
  errorText: {
    color: theme.palette.error.main
  }
});

class EditZoneDialog extends Component {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (zone) => {
    return {
      isDisabled: false,
      layout: zone
        ? zone.layout.clone()
        : new ZoneLayoutNode("", "", null, null)
    };
  };

  componentDidUpdate(prevProps, prevState) {
    const { isOpen, zone } = this.props;

    if (isOpen && !prevProps.isOpen) {
      this.setState(this.initialState(zone));
    }
  }

  getZoneBasesLabel = () => {
    const { t, allBases } = this.props;
    const { layout } = this.state;

    if (allBases && layout) {
      const baseNames = layout.allBaseIds.map((baseId) => {
        const base = utilsHelper.findInArrayById(allBases, baseId);

        return base ? base.name : t("label.unknown");
      });

      switch (baseNames.length) {
        case 0:
          return t("zone.there-are-no-bases-linked-to-this-zone");
          break;
        case 1:
          return `${t("zone.base-x-is-linked-to-this-zone", {
            baseName: baseNames[0]
          })}`;
          break;
        default:
          return `${t("zone.bases-x-are-linked-to-this-zone", {
            baseNames: baseNames.join(", ")
          })}`;
          break;
      }
    }

    return "";
  };

  handleLayoutUpdate = (layout) => {
    this.setState({ layout });
  };

  handleUpdateZone = async () => {
    const { t, onClose, bar, zone } = this.props;
    const { layout } = this.state;

    const toastId = toast(t("zone.updating-zone"), { autoClose: false });
    this.setState({ isDisabled: true });

    try {
      if (bar.isUsingBases()) {
        if (layout.allBaseIds.length === 0) {
          throw new Error(t("error.select-at-least-one-base"));
        }
      }

      //zone.name = name.trim();
      zone.layout = layout;

      /*if (!deliveryMethods.isServeActive && !deliveryMethods.isPickupActive) {
        throw new Error("error.select-at-least-one-delivery-method");
      }
      if (deliveryMethods.isServeActive && zone.tableCount === 0) {
        throw new Error(
          "error.select-at-least-one-table-to-enable-serve-delivery-method"
        );
      }
      if (
        deliveryMethods.isServeActive &&
        !deliveryMethods.isPickupActive &&
        !isLayoutPathRequiredToOrder
      ) {
        throw new Error(
          "error.layout-path-should-be-required-to-order-when-only-serve-delivery-method-is-selected"
        );
      }*/
      //zone.deliveryMethods = deliveryMethods;
      //zone.bases = bases;

      await zoneService.updateZone(zone);

      toast.update(toastId, {
        render: t("zone.zone-updated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false }, onClose);
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(
          error ? error.message : "label.unknown-error"
        )})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const {
      classes,
      t,
      isOpen,
      onClose,
      bar,
      subscription,
      allBases,
      maxHeight,
      zone
    } = this.props;
    const { isDisabled, layout } = this.state;

    const layoutHeightExceeded =
      maxHeight !== undefined && layout.height > maxHeight;

    // TO FIX: DialogContent duplicate
    return (
      <Dialog open={isOpen} onClose={onClose} fullScreen>
        <DialogTitle>{t("zone.edit-zone")}</DialogTitle>
        <DialogContent
          className={
            layoutHeightExceeded ? classes.largeDescription : undefined
          }
        >
          <DialogContentText>
            <span>{this.getZoneBasesLabel()}</span>
            <span className={classes.spacing} />
            {layoutHeightExceeded && (
              <>
                <strong className={classes.errorText}>
                  {t(
                    "zone.max-layout-height-is-exceeded-remove-marked-layout-nodes-to-continue"
                  )}
                </strong>
                <span className={classes.spacing} />
                <CapabilityUnavailableButton
                  bar={bar}
                  subscription={subscription}
                  label={t(
                    "zone.larger-layout-height-available-in-other-plans"
                  )}
                />
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogContent className={classes.content}>
          {zone && (
            <EditLayout
              isDisabled={isDisabled}
              bar={bar}
              allBases={allBases}
              maxHeight={maxHeight}
              layout={layout}
              onUpdate={this.handleLayoutUpdate}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={isDisabled} onClick={onClose} color="secondary">
            {t("label.cancel")}
          </Button>
          <Button
            disabled={isDisabled}
            onClick={this.handleUpdateZone}
            color="primary"
          >
            {t("label.update")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(EditZoneDialog));
