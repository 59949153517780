import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import ReactMoment from "react-moment";

import {
  Checkbox,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from "@mui/material";
import CropFreeIcon from "@mui/icons-material/CropFree";
import BlockIcon from "@mui/icons-material/Block";
import WarningIcon from "@mui/icons-material/Warning";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({});

class VoucherItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleClick = (event) => {
    const { voucher, onClick } = this.props;

    onClick(voucher);
  };

  handleChange = (event) => {
    const { voucher, onChange } = this.props;

    onChange(voucher, event.target.checked);
  };

  render() {
    const { classes, t, voucher, allowSelect, isSelected } = this.props;

    return (
      <ListItem key={voucher.id}>
        <ListItemIcon>
          <IconButton edge="start" onClick={this.handleClick} size="large">
            {voucher.isValid ? (
              <CropFreeIcon />
            ) : voucher.canBeEdited ? (
              <BlockIcon />
            ) : (
              <WarningIcon />
            )}
          </IconButton>
        </ListItemIcon>
        <ListItemText
          primary={
            <span>
              {voucher.label && <strong>{voucher.label} &bull; </strong>}
              <strong>{voucher.originalValueToString(t)}</strong>,{" "}
              <span
                style={{
                  color: !voucher.hasRemainingValue
                    ? "red"
                    : voucher.isValid
                    ? "green"
                    : undefined
                }}
              >
                {t("voucher.value-remaining", {
                  voucherRemainingValueAndCurrency:
                    voucher.remainingValueToString(t)
                })}
              </span>{" "}
              (
              <span
                style={{
                  color: voucher.isExpired
                    ? "red"
                    : voucher.isValid
                    ? "green"
                    : undefined
                }}
              >
                {`${t("voucher.voucher-valid-until")} `}
                <ReactMoment format="DD/MM/YYYY">
                  {voucher.validUntil}
                </ReactMoment>
              </span>
              )
            </span>
          }
          secondary={
            <span>
              {`${t("voucher.voucher-generated-on")} `}
              <ReactMoment format="DD/MM/YYYY [om] HH:mm:ss">
                {voucher.timestamp}
              </ReactMoment>
            </span>
          }
        />
        {allowSelect && (
          <ListItemSecondaryAction>
            <Checkbox
              edge="end"
              disabled={!voucher.isValid}
              checked={isSelected}
              onChange={this.handleChange}
            />
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(VoucherItem));
