import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  InputLabel
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { dataService, menuService } from "tap-io/client/services";
import { cloudStorage } from "tap-io/storage";
import EnterValue from "tap-io/client/components/common/EnterValue";
import VatRateSelector from "tap-io/client/components/menu/VatRateSelector";

import UploadImage from "../common/UploadImage";
import SearchOrCreateProduct from "../catalogue/SelectOrCreateProduct";
import SelectDeposits from "../deposit/SelectDeposits";

const styles = (theme) => ({
  dialog: {
    //overflow: "visible",
    minHeight: 500
  },
  content: {
    //overflow: "visible"
  },
  priceContainer: {
    display: "flex",
    flexAlign: "row",
    alignItems: "center"
  },
  currency: {
    marginTop: 16,
    marginLeft: 20
  },
  media: {
    height: 0,
    marginTop: 10,
    paddingTop: "56.25%", // 16:9
    backgroundSize: "contain"
  },
  spacing: {
    height: 20
  },
  inputLabel: {
    width: "100%",
    margin: theme.spacing(1, 0, -1, 0)
  }
});

/*import { components } from "react-select";

const addCypressIdToComponent = (Component, id) => (props) => (
  <Component {...props} data-cy={id} />
);

const CUSTOM_CREATABLE_COMPONENTS = {
  Input: addCypressIdToComponent(components.Input, "item-name")
};

components={CUSTOM_CREATABLE_COMPONENTS}*/

class AddItemDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = () => {
    const { catalogue } = this.props;

    return {
      isDisabled: false,
      products: catalogue
        ? catalogue.products.map((product) => {
            return {
              value: product.id,
              label: product.name
            };
          })
        : [],
      name: "",
      description: "",
      price: "",
      vatRate: "",
      depositIds: [],
      productId: null,
      imageFilename: null,
      imageUrl: null
    };
  };

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.props;
    const { imageFilename } = this.state;
    const prevImageFilename = prevState.imageFilename;

    if (isOpen && !prevProps.isOpen) {
      this.setState(this.initialState(), this.refreshItemImageUrl);
    } else if (imageFilename !== prevImageFilename) {
      this.refreshItemImageUrl();
    }
  }

  refreshItemImageUrl = async () => {
    const { menu } = this.props;
    const { imageFilename } = this.state;

    if (menu) {
      try {
        const item = menuService.createMenuItem(menu.id, { imageFilename });
        const imageUrl = await cloudStorage.getBarMenuItemImageUrl(
          menu.barId,
          item
        );

        this.setState({ imageUrl });
      } catch (error) {
        console.warn(error);

        this.setState({ imageUrl: null });
      }
    } else {
      this.setState({ imageUrl: null });
    }
  };

  handleProductChange = (product) => {
    this.setState({ product, vatRate: product?.vatRate || "" });
  };

  handleDescriptionChange = (event) => {
    const value = event.target.value;
    this.setState({ description: value });
  };

  handlePriceChange = (value) => {
    this.setState({ price: value });
  };

  handleVatRateChange = (vatRate) => {
    this.setState({ vatRate });
  };

  handleDepositIdsChange = (depositIds) => {
    this.setState({ depositIds });
  };

  handleImageUploadSuccess = (filename) => {
    this.setState({ imageFilename: filename });
  };

  handleImageUploadError = (error) => {
    if (error) {
      toast.error(error.message);
    }
  };

  handleImageRemove = () => {
    this.setState({ imageFilename: null });
  };

  handleAddItem = async () => {
    const { t, bar, menu, catalogue, onClose } = this.props;
    const { product, description, price, vatRate, depositIds, imageFilename } =
      this.state;

    const toastId = toast(t("menu.adding-item"), { autoClose: false });
    this.setState({ isDisabled: true });

    try {
      if (!product) {
        throw new Error("error.product-is-not-defined");
      }

      if (!catalogue.doesProductExist(product.id)) {
        product.vatRate = vatRate || null;
        await dataService.addCustomProductToCatalogueData(bar.id, product);
      }

      const item = menuService.createMenuItem(menu.id, {});
      item.name = product.name;
      item.vatRate = vatRate || null;
      item.depositIds = depositIds;
      item.description = description.trim();
      item.price = price;
      item.productId = product.id;
      item.imageFilename = imageFilename;
      item.isAvailable = true;

      await menuService.addMenuItem(menu, item);

      toast.update(toastId, {
        render: t("menu.item-added"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false }, onClose);
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(error.message)})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, isOpen, onClose, bar, catalogue, deposits, menu } =
      this.props;
    const { isDisabled, description, price, vatRate, depositIds, imageUrl } =
      this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle>{t("menu.add-a-new-item")}</DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText>
            {t("menu.add-a-new-item-below")}
          </DialogContentText>
          <div className={classes.spacing} />
          <SearchOrCreateProduct
            label={t("label.name")}
            //helperText={t("menu.product-linked-with-this-item")}
            bar={bar}
            catalogue={catalogue}
            onChange={this.handleProductChange}
          />
          <TextField
            inputProps={{
              "data-cy": "item-description"
            }}
            variant="standard"
            margin="dense"
            label={t("menu.description")}
            type="text"
            //helperText={t("menu.description-of-this-item")}
            fullWidth
            value={description}
            disabled={isDisabled}
            onChange={this.handleDescriptionChange}
          />
          <EnterValue
            value={price}
            isDisabled={isDisabled}
            label={t("menu.price")}
            unit={bar.getOrderCurrency()}
            onChange={this.handlePriceChange}
          />
          <VatRateSelector
            fullWidth
            variant="standard"
            margin="dense"
            allowEmpty={true}
            vatRate={vatRate}
            isDisabled={isDisabled}
            onChange={this.handleVatRateChange}
          />
          <div>
            <InputLabel shrink={true} className={classes.inputLabel}>
              {t("menu.deposits")}
            </InputLabel>
            <SelectDeposits
              isDisabled={isDisabled}
              deposits={deposits}
              selectedDepositIds={depositIds}
              onChange={this.handleDepositIdsChange}
            />
          </div>
          {menu && (
            <UploadImage
              inputProps={{
                "data-cy": "item-image-upload"
              }}
              label={t("label.upload-new-image")}
              isDisabled={isDisabled}
              uploadRef={cloudStorage.getBarMenuItemImagesRef(
                menu.barId,
                menu.id
              )}
              onSuccess={this.handleImageUploadSuccess}
              onError={this.handleImageUploadError}
            />
          )}
          {imageUrl && (
            <div>
              <CardMedia
                data-cy="item-image"
                className={classes.media}
                image={imageUrl}
                title={t("label.image")}
              />
              <Button
                data-cy="item-image-remove"
                fullWidth
                disabled={isDisabled}
                onClick={this.handleImageRemove}
              >
                {t("label.remove-image")}
              </Button>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={isDisabled} onClick={onClose} color="secondary">
            {t("label.cancel")}
          </Button>
          <Button
            data-cy="item-add"
            disabled={isDisabled}
            onClick={this.handleAddItem}
            color="primary"
          >
            {t("label.add")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(AddItemDialog));
