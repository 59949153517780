import { Asyncer } from "../../../components/common/Asyncer";
import { Async } from "../../../components/common/useAsync";
import { BaseReport } from "../../models/BaseReport";
import { BarStats } from "../common/BarStats";
import { DownloadIconCSVButton } from "../common/DownloadCSVIconButton";
import { FailedStatCard } from "../common/FailedStatCard";
import { LoadingStatCard } from "../common/LoadingStatCard";
import { StatCard } from "../common/StatCard";

export type AmountOfPreparationsPerBaseProps = {
  async: Async<BaseReport>;
  mark: string;
  t: any;
};

export function AmountOfPreparationsPerBase(
  props: AmountOfPreparationsPerBaseProps
) {
  const { async, mark, t } = props;

  const title = t("stats.stat.preparations-per-base.title");
  const description = t("stats.stat.preparations-per-base.description");

  return (
    <Asyncer
      async={async}
      Busy={<LoadingStatCard title={title} description={description} />}
      Failed={
        <FailedStatCard title={title} description={description} async={async} />
      }
    >
      {(report) => {
        return (
          <StatCard
            title={title}
            description={description}
            action={
              <DownloadIconCSVButton
                title={`${title}-${mark}`}
                csv={report.toCSV(t)}
              />
            }
          >
            <BarStats
              data={report.data}
              x="base"
              formatX={(_, r) => r.name}
              y="amountOfPreparations"
              formatY={report.formatInteger}
              t={t}
            />
          </StatCard>
        );
      }}
    </Asyncer>
  );
}
