import React, { PropsWithChildren, useEffect, useState } from "react";

import { withTranslation } from "react-i18next";

import { List, ListItem, ListItemText } from "@mui/material";

import config from "tap-io/client/env";
import { integrationHelper } from "tap-io/helpers";
import Bar from "tap-io/models/bar/Bar";
import Integration from "tap-io/models/integration/Integration";

import AbiIntegrationItem from "./AbiIntegrationItem";

type IntegrationListProps = {
  t: any;
  bar: Bar;
  integrations: Integration[];
};

function IntegrationList({
  t,
  bar,
  integrations
}: PropsWithChildren<IntegrationListProps>) {
  const [abiIntegration, setAbiIntegration] = useState<any>(null);

  useEffect(() => {
    const abiIntegration = integrations ? integrationHelper.findAbiIntegration(integrations) : null;

    setAbiIntegration(abiIntegration);
  }, [integrations]);

  return (
    <List>
      {config.integrations.abi ? (
        <AbiIntegrationItem bar={bar} integration={abiIntegration} />
      ) : (
        <ListItem>
          <ListItemText primary={t("integration.no-integrations-found")} />
        </ListItem>
      )}
    </List>
  );
}

export default withTranslation("common")(IntegrationList);
