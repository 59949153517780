import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import Moment from "moment";

import DateTimePicker from "@mui/lab/MobileDateTimePicker";

import { TextField } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  dateTime: {
    cursor: "pointer"
  },
  fullWidth: {
    width: "100%"
  }
});

class SelectDateTime extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleChange = (date) => {};

  handleAccept = (date) => {
    const { value, onChange } = this.props;

    if (date && (!value || !date.isSame(value))) {
      onChange(date);
    }
  };

  render() {
    const {
      classes,
      t,
      isDisabled,
      label,
      value,
      inputFormat,
      minutesStep,
      minDate,
      maxDate,
      className,
      fullWidth,
      openTo,
      views
    } = this.props;

    return (
      <DateTimePicker
        disabled={isDisabled}
        color="secondary"
        disableCloseOnSelect={false}
        renderInput={(props) => (
          <TextField
            {...props}
            variant="standard"
            label={label}
            InputProps={{
              ...props.InputProps,
              disableUnderline: true,
              classes: {
                input: classNames(classes.dateTime, className, {
                  [classes.fullWidth]: fullWidth
                })
              }
            }}
          />
        )}
        value={value}
        onChange={this.handleChange}
        onAccept={this.handleAccept}
        inputFormat={inputFormat || "dd D MMMM YYYY HH:mm"}
        ampm={false}
        minutesStep={minutesStep || 60}
        minDate={minDate ? Moment(minDate) : undefined}
        maxDate={maxDate ? Moment(maxDate) : undefined}
        openTo={openTo}
        views={views}
        showTodayButton={true}
        toolbarTitle={t("label.select-date-and-time")}
        okText={t("label.ok")}
        cancelText={t("label.cancel")}
        todayText={t("label.today")}
      />
    );
  }
}

export default withStyles(styles)(withTranslation("common")(SelectDateTime));
