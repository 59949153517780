import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import SeriesLayoutNode from "tap-io/models/zone/SeriesLayoutNode";
import LayoutNodeType from "tap-io/models/zone/LayoutNodeType";

import { LAYOUT_NODE_ICONS } from "../../constants/zones";

const styles = (theme) => ({});

class AddSeriesLayoutNodeMenuItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleAddSeriesLayoutNode = () => {
    const { t, onAdd, layoutNode } = this.props;

    const childNode = new SeriesLayoutNode(
      "",
      t("label.table"),
      [],
      null,
      null
    );
    layoutNode.addChild(childNode);

    if (onAdd) {
      onAdd(childNode);
    }
  };

  render() {
    const { t } = this.props;

    const LayoutNodeIcon = LAYOUT_NODE_ICONS[LayoutNodeType.SERIES];

    return (
      <MenuItem onClick={this.handleAddSeriesLayoutNode}>
        <ListItemIcon>
          <LayoutNodeIcon />
        </ListItemIcon>
        <ListItemText primary={t("zone.add-series-layout-node")} />
      </MenuItem>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(AddSeriesLayoutNodeMenuItem)
);
