import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import withEstimatedPreparationInfo from "./withEstimatedPreparationInfo";

const styles = (theme) => ({
    message: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    }
});

const REFRESH_CLOCK_INTERVAL = 30000;

const CustomerOnTheWay = ({ t, className, variant, remainingTime }) =>
    <Typography variant={variant} className={className}>
        {
            t(remainingTime < -10 ?
                "order.customer-too-late" :
                remainingTime < -5 ?
                    "order.customer-late" :
                    "order.customer-notified")
        }
    </Typography>;

const CustomerArrivingInXMinutes = ({ t, className, variant, remainingTime }) =>
    <Typography variant={variant} className={className}>
        {t("order.customer-arriving-in-x-minutes", { remainingTime })}
    </Typography>;

class OrderEstimatedPreparationDurationLabel extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { classes, t, timeOffset, base, order, variant, estimatedPreparationInfo } = this.props;

        const { remainingTime, isWaitingForPickup } = estimatedPreparationInfo;

        if (!order.isDeliveryMethodPickup) {
            return null;
        }

        return isWaitingForPickup ?
            <CustomerOnTheWay
                t={t}
                className={classes.message}
                variant={variant}
                remainingTime={remainingTime}
            /> :
            <CustomerArrivingInXMinutes
                t={t}
                className={classes.message}
                variant={variant}
                remainingTime={remainingTime}
            />;
    }
}

export default withStyles(styles)(
    withTranslation("common")(
        withEstimatedPreparationInfo()(
            OrderEstimatedPreparationDurationLabel
        )
    )
);
