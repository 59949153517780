import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  smallSpacing: { height: 10 },
  largeSpacing: { height: 40 }
});

class SyncWithStarnetDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      cardNumber: "",
      cardPin: ""
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isOpen } = this.props;
    const wasOpen = prevProps.isOpen;

    if (isOpen && !wasOpen) {
      this.setState({ cardPin: "" });
    }
  }

  handleDialogClick = (event) => {
    // Prevent Dialog 'clickthrough'
    event.stopPropagation();
  };

  handleCardNumberChange = (event) => {
    this.setState({ cardNumber: event.target.value });
  };

  handlePinChange = (event) => {
    this.setState({ cardPin: event.target.value });
  };

  handleConfirmWithoutPin = (event) => {
    const { onConfirm } = this.props;

    // Prevent Dialog 'clickthrough'
    event.stopPropagation();

    onConfirm();
  };

  handleConfirmWithPin = (event) => {
    const { onConfirm } = this.props;
    const { cardNumber, cardPin } = this.state;

    // Prevent Dialog 'clickthrough'
    event.stopPropagation();

    if (!cardNumber) {
      return toast.error("error.starnet-card-number-is-not-defined");
    }
    if (!cardPin) {
      return toast.error("error.starnet-card-pin-is-not-defined");
    }

    onConfirm(cardNumber, cardPin);
  };

  handleCancel = (event) => {
    const { onCancel } = this.props;

    // Prevent Dialog 'clickthrough'
    event.stopPropagation();

    onCancel();
  };

  render() {
    const { classes, t, isOpen } = this.props;
    const { isLoading, cardNumber, cardPin } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={this.handleCancel}
        onClick={this.handleDialogClick}
      >
        <DialogTitle>{t("payment.sync-with-starnet")}</DialogTitle>
        <DialogContent>
          <Typography>
            <strong>{t("payment.sync-without-starnet-payment")}</strong>
          </Typography>
          <div className={classes.smallSpacing} />
          <Button
            onClick={this.handleConfirmWithoutPin}
            color="primary"
            variant="contained"
            fullWidth
          >
            {t("payment.sync-without-starnet-payment")}
          </Button>
          <div className={classes.largeSpacing} />
          <Typography>
            <strong> {t("payment.sync-with-starnet-payment")}</strong>
          </Typography>
          <div className={classes.smallSpacing} />
          <TextField
            fullWidth
            margin="dense"
            label={t("payment.starnet-card-number")}
            disabled={isLoading}
            value={cardNumber}
            onChange={this.handleCardNumberChange}
          />
          <TextField
            fullWidth
            margin="dense"
            label={t("payment.starnet-card-pin")}
            disabled={isLoading}
            value={cardPin}
            onChange={this.handlePinChange}
          />
          <div className={classes.smallSpacing} />
          <Button
            onClick={this.handleConfirmWithPin}
            color="primary"
            variant="contained"
            fullWidth
          >
            {t("payment.sync-with-starnet-payment")}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="secondary">
            {t("label.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(SyncWithStarnetDialog)
);
