import SelectBeginAndEndDateTime from "../../components/common/SelectBeginAndEndDateTime";

function StatPeriodSelect({
  value,
  onChange,
  t
}: {
  value: [begin: Date, end: Date];
  onChange: (period: [begin: Date, end: Date]) => void;
  t: any;
}) {
  return (
    <SelectBeginAndEndDateTime
      isFutureDisabled={true}
      minutesStep={5}
      beginDateTime={value[0]}
      endDateTime={value[1]}
      onChange={({ beginDateTime, endDateTime }) =>
        onChange([beginDateTime, endDateTime])
      }
    />
  );
}

export default StatPeriodSelect;
