import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Link } from "react-router-dom";

import { List, ListItem, ListItemText, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { deliveryHelper } from "tap-io/helpers";
import { barService } from "tap-io/client/services";
import DeliveryMethod from "tap-io/models/delivery/DeliveryMethod";

import * as routes from "../../constants/routes";
import SelectDeliveryMethods from "../delivery/SelectDeliveryMethods";
import SelectDeliveryColors from "../delivery/SelectDeliveryColors";

const styles = (theme) => ({
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main
  }
});

class EditDeliveryMethodsAndColors extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (bar) => {
    return {
      isDisabled: false,
      deliveryMethods: {
        [DeliveryMethod.SERVE]:
          bar && bar.deliveryMethods && bar.deliveryMethods.isServeActive
            ? true
            : false,
        [DeliveryMethod.PICKUP]:
          bar && bar.deliveryMethods && bar.deliveryMethods.isPickupActive
            ? true
            : false
      },
      deliveryColors: bar && bar.deliveryColors ? bar.deliveryColors.colors : []
    };
  };

  componentDidMount() {
    const { bar } = this.props;

    this.setState(this.initialState(bar));
  }

  componentDidUpdate(prevProps, prevState) {
    const { bar } = this.props;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;

    if (barId !== prevBarId) {
      this.setState(this.initialState(bar));
    }
  }

  handleDeliveryMethodChange = async (deliveryMethod, isActive) => {
    await this.updateDeliveryMethod(deliveryMethod, isActive);
  };

  handleDeliveryColorsChange = async (deliveryColor, isActive) => {
    await this.updateDeliveryColor(deliveryColor, isActive);
  };

  updateDeliveryMethod = async (methodName, methodValue) => {
    const { t, bar } = this.props;
    const deliveryMethods = { ...this.state.deliveryMethods };

    deliveryMethods[methodName] = methodValue;

    const isServeActive = deliveryMethods[DeliveryMethod.SERVE];
    const isPickupActive = deliveryMethods[DeliveryMethod.PICKUP];
    if (!isServeActive && !isPickupActive) {
      return toast.error(t("error.select-at-least-one-delivery-method"));
    }

    const toastId = toast(t("settings.saving-settings"), { autoClose: false });
    this.setState({ isDisabled: true });

    try {
      await barService.setBarDeliveryMethods(
        bar,
        deliveryHelper.createDeliveryMethods(isServeActive, isPickupActive)
      );

      this.setState({ isDisabled: false, deliveryMethods });

      toast.update(toastId, {
        render: t("settings.settings-saved"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      this.setState({ isDisabled: false });

      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  updateDeliveryColor = async (colorName, colorValue) => {
    const { t, bar } = this.props;

    const deliveryColors = deliveryHelper.createDeliveryColors(
      this.state.deliveryColors
    );

    if (colorValue) {
      deliveryColors.add(colorName);
    } else {
      deliveryColors.remove(colorName);
    }

    if (deliveryColors.colors.length === 0) {
      return toast.error(t("error.select-at-least-one-delivery-color"));
    }

    const toastId = toast(t("settings.saving-settings"), { autoClose: false });
    this.setState({ isDisabled: true });

    try {
      await barService.setBarDeliveryColors(bar, deliveryColors);

      this.setState({
        isDisabled: false,
        deliveryColors: deliveryColors.colors
      });

      toast.update(toastId, {
        render: t("settings.settings-saved"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      this.setState({ isDisabled: false });

      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, bar, hideLinks } = this.props;
    const { isDisabled, deliveryMethods, deliveryColors } = this.state;

    return (
      <div>
        <List>
          {bar.isUsingZones() ? (
            <div>
              <ListItem>
                <Typography>
                  {t(
                    "delivery.you-are-using-zones-select-delivery-method-per-zone"
                  )}
                </Typography>
              </ListItem>
              {!hideLinks && (
                <Link to={routes.LOGISTICS} className={classes.link}>
                  <ListItem button>
                    <ListItemText
                      primary={
                        <Typography variant="button">
                          {t("settings.manage-zones")}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Link>
              )}
            </div>
          ) : (
            <ListItem>
              <SelectDeliveryMethods
                isDisabled={isDisabled}
                bar={bar}
                isServeActive={deliveryMethods[DeliveryMethod.SERVE]}
                isPickupActive={deliveryMethods[DeliveryMethod.PICKUP]}
                onChange={this.handleDeliveryMethodChange}
              />
            </ListItem>
          )}
          {bar.isUsingBases() ? (
            <div>
              <ListItem>
                <Typography>
                  {t(
                    "delivery.you-are-using-bases-select-delivery-color-per-zone"
                  )}
                </Typography>
              </ListItem>
              {!hideLinks && (
                <Link to={routes.LOGISTICS} className={classes.link}>
                  <ListItem button>
                    <ListItemText
                      primary={
                        <Typography variant="button">
                          {t("settings.manage-bases")}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Link>
              )}
            </div>
          ) : (
            <ListItem>
              <SelectDeliveryColors
                deliveryColors={deliveryColors}
                onChange={this.handleDeliveryColorsChange}
              />
            </ListItem>
          )}
        </List>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditDeliveryMethodsAndColors)
);
