import React, { Component } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material//KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material//KeyboardArrowDown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import withStyles from "@mui/styles/withStyles";

import { serviceOptionHelper } from "tap-io/helpers";

import { LAYOUT_NODE_ICONS } from "../../constants/zones";

const styles = (theme) => ({
  item: {
    position: "relative",
    paddingLeft: theme.spacing()
  },
  icon: {
    //zIndex: 1,
    //minWidth: 35,
    fontSize: 20,
    marginRight: theme.spacing()
  },
  text: {
    zIndex: 1
  },
  errorText: {
    color: theme.palette.error.main
  },
  title: {
    display: "flex",
    alignItems: "center"
  },
  leftSpacing: {
    width: theme.spacing()
  },
  leftButton: {
    padding: 9
  },
  button: {
    zIndex: 1
  },
  background: {
    zIndex: 0,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 8,
    backgroundColor: theme.palette.primary.main,
    opacity: 0.2
  }
});

class LayoutNodeItem extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleClick = (layoutNode) => (event) => {
    const { onClick } = this.props;

    onClick(layoutNode);
  };

  handleToggleIsOpenClick = (event) => {
    const { onToggleIsOpenClick } = this.props;

    onToggleIsOpenClick();
  };

  handleMoreClick = (layoutNode) => (event) => {
    const { onMoreClick } = this.props;

    onMoreClick(event, layoutNode);
  };

  getLayoutNodeIcon = (fieldType) => {
    const { classes } = this.props;

    const LayoutNodeIcon = LAYOUT_NODE_ICONS[fieldType]
      ? LAYOUT_NODE_ICONS[fieldType]
      : HelpOutlineIcon;
    return <LayoutNodeIcon className={classes.icon} />;
  };

  getLayoutNodeDescription = (layoutNode) => {
    const { t } = this.props;

    return layoutNode.serviceOptions
      .map((serviceOption) =>
        serviceOptionHelper.getServiceOptionLabel(serviceOption, t)
      )
      .join(", ");
  };

  render() {
    const { classes, t, isDisabled, isOpen, isSelected, isError, layoutNode } =
      this.props;

    return (
      <ListItem
        className={classes.item}
        disabled={isDisabled}
        onClick={this.handleClick(layoutNode)}
      >
        {isSelected && <div className={classes.background} />}
        {layoutNode.hasChildren ? (
          <ListItemIcon>
            <IconButton
              disabled={isDisabled}
              className={classes.leftButton}
              onClick={this.handleToggleIsOpenClick}
              size="large"
            >
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </ListItemIcon>
        ) : (
          <div className={classes.leftSpacing} />
        )}
        <ListItemText
          className={classNames(classes.text, { [classes.errorText]: isError })}
          primary={
            <span className={classes.title}>
              {this.getLayoutNodeIcon(layoutNode.type)}
              {layoutNode.toString()}
            </span>
          }
          secondary={this.getLayoutNodeDescription(layoutNode)}
        />
        <ListItemSecondaryAction className={classes.button}>
          <IconButton
            disabled={isDisabled}
            onClick={this.handleMoreClick(layoutNode)}
            size="large"
          >
            <MoreVertIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(LayoutNodeItem)
);
