import React, { PureComponent } from "react";

import withStyles from "@mui/styles/withStyles";

import BarHeaderSpacing from "./BarHeaderSpacing";
import BarRoutes from "./BarRoutes";

const styles = (theme) => ({
  content: {
    height: "100%",
    overflowY: "scroll"
  }
});

class BarContent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      drawerWidth,
      isMenuDrawerOpen,
      classes,
      isAppLocked,
      timeOffset,
      deviceSettings,
      mode,
      bar,
      assets,
      subscription,
      base,
      balance,
      pricing,
      allMenus,
      allBases,
      allZones,
      allIntegrations,
      openOrders,
      selectBase,
      createOrder,
      lockApp,
      unlockApp,
      setBarId
    } = this.props;

    return (
      <main>
        <BarHeaderSpacing />
        <div
          className={classes.content}
          style={{
            width: `calc(100% - ${isMenuDrawerOpen ? drawerWidth : 0}px)`,
            marginRight: isMenuDrawerOpen ? drawerWidth : 0
          }}
        >
          <BarRoutes
            contentWidth={`calc(100% - ${isMenuDrawerOpen ? drawerWidth : 0}px)`}
            isAppLocked={isAppLocked}
            timeOffset={timeOffset}
            deviceSettings={deviceSettings}
            mode={mode}
            bar={bar}
            assets={assets}
            base={base}
            balance={balance}
            subscription={subscription}
            pricing={pricing}
            allMenus={allMenus}
            allBases={allBases}
            allZones={allZones}
            allIntegrations={allIntegrations}
            openOrders={openOrders}
            selectBase={selectBase}
            createOrder={createOrder}
            lockApp={lockApp}
            unlockApp={unlockApp}
            setBarId={setBarId}
          />
        </div>
      </main>
    );
  }
}

export default withStyles(styles)(BarContent);
