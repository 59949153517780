import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { hasNativeWrapper, printer } from "../../native";
import { toast } from "react-toastify";

const styles = {};

class PrintOrderDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handlePrintOrderAndClose = async () => {
    const { t, onClose, bar, base, assets, order, deviceSettings } = this.props;

    try {
      if (hasNativeWrapper()) {
        await printer.printReceipt(
          t,
          bar,
          base,
          assets,
          order,
          deviceSettings.printBarLogo,
          deviceSettings.printOrderCustomerFields,
          deviceSettings.printAmountOfCopies
        );
      }
    } catch (error) {
      if (error) {
        toast.error(error.message);
      }
      console.warn(error);
    }

    onClose();
  };

  render() {
    const { classes, t, isOpen, onClose } = this.props;

    return (
      <Dialog onClose={onClose} open={isOpen} disableEscapeKeyDown={true}>
        <DialogTitle>{t("printer.print-order-again")}</DialogTitle>
        <DialogContent>
          {t("printer.print-order-again-description")}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {t("label.cancel")}
          </Button>
          <Button onClick={this.handlePrintOrderAndClose} color="primary">
            {t("label.print")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(PrintOrderDialog));
