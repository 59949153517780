import CustomerField from "../models/bar/CustomerField";
import DateCustomerField from "../models/bar/DateCustomerField";
import EmailCustomerField from "../models/bar/EmailCustomerField";
import SelectCustomerField from "../models/bar/SelectCustomerField";
import TextCustomerField from "../models/bar/TextCustomerField";
import ToggleCustomerField from "../models/bar/ToggleCustomerField";
import { utilsHelper } from "./index";

export default class BarHelper {
  public cleanLocator(locator: string): string {
    return locator.replace(/[^a-z|\d]/gi, "");
  }

  public parseBarUrl(
    orderDomain: string,
    barUrl: string
  ): { orderQueryString: string } {
    const regExp = new RegExp(
      `^https:\/\/${orderDomain.replace(/\./g, "\\.")}\/(.*)$`
    );
    const matches = barUrl.match(regExp);

    if (!matches || matches.length !== 2) {
      throw new Error("error.qr-code-is-invalid");
    }

    const orderQueryString = matches[1];

    return {
      orderQueryString
    };
  }

  public getWaiterUrl(
    orderDomain: string,
    barLocator: string,
    waiterCode: string
  ) {
    return `https://${orderDomain}/${barLocator}?waiterCode=${waiterCode}`;
  }

  public isValidBarMolliePaymentProviderConf(conf: any): boolean {
    return !!(conf && conf.profileId && conf.apiKey);
  }

  public isValidBarCcvPaymentProviderConf(conf: any): boolean {
    return !!(conf && conf.apiKey);
  }

  public isValidBarMultiSafePayPaymentProviderConf(conf: any): boolean {
    return !!(conf && conf.apiKey);
  }

  public isValidBarStarnetPaymentProviderConf(conf: any): boolean {
    return !!(conf && conf.host && conf.apiKey);
  }

  private isValidBarVivaWalletPaymentProviderConf(conf: any): boolean {
    return !!(
      conf &&
      conf.isVerified &&
      conf.merchantId &&
      conf.apiKey &&
      conf.clientId &&
      conf.clientSecret
    );
  }

  public isValidBarVivaWalletOnlinePaymentProviderConf(conf: any): boolean {
    return !!(
      this.isValidBarVivaWalletPaymentProviderConf(conf) && conf.sourceCode
    );
  }

  public isValidBarVivaWalletPosPaymentProviderConf(conf: any): boolean {
    return !!this.isValidBarVivaWalletPaymentProviderConf(conf);
  }

  public isValidBarPayconiqPaymentProviderConf(conf: any): boolean {
    return !!(conf && conf.merchantId && conf.apiKey);
  }

  public isValidBarPayconiqOnlinePaymentProviderConf(conf: any): boolean {
    return !!this.isValidBarPayconiqPaymentProviderConf(conf);
  }

  public isValidBarPayconiqPosPaymentProviderConf(conf: any): boolean {
    return !!this.isValidBarPayconiqPaymentProviderConf(conf);
  }

  public isCustomerFieldValueValid(
    customerField: CustomerField,
    value: any
  ): boolean {
    if (customerField instanceof TextCustomerField) {
      return this.isTextCustomerFieldValueValid(customerField, value);
    } else if (customerField instanceof EmailCustomerField) {
      return this.isEmailCustomerFieldValueValid(customerField, value);
    } else if (customerField instanceof DateCustomerField) {
      return this.isDateCustomerFieldValueValid(customerField, value);
    } else if (customerField instanceof ToggleCustomerField) {
      return this.isToggleCustomerFieldValueValid(customerField, value);
    } else if (customerField instanceof SelectCustomerField) {
      return this.isSelectCustomerFieldValueValid(customerField, value);
    }

    return false;
  }

  public isTextCustomerFieldValueValid(
    customerField: TextCustomerField,
    value: string
  ): boolean {
    return (
      value !== undefined &&
      value !== null &&
      ((value === "" && !customerField.isRequired) || value.trim().length > 0)
    );
  }

  public isEmailCustomerFieldValueValid(
    customerField: EmailCustomerField,
    value: string
  ): boolean {
    return (
      value !== undefined &&
      value !== null &&
      ((value === "" && !customerField.isRequired) ||
        utilsHelper.parseEmail(value) !== undefined)
    );
  }

  public isDateCustomerFieldValueValid(
    customerField: DateCustomerField,
    value: string
  ) {
    // TO FIX: check dateCustomerField requirements as well
    return value !== undefined && value !== null && value !== "";
  }

  public isToggleCustomerFieldValueValid(
    customerField: ToggleCustomerField,
    value: boolean
  ) {
    return customerField.isRequired
      ? value === true
      : value === true || value === false;
  }

  public isSelectCustomerFieldValueValid(
    customerField: SelectCustomerField,
    value: string
  ) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "" &&
      customerField.hasOption(value)
    );
  }

  public getAvailableLocales() {
    return ["nl_BE", "fr_BE", "en_GB", "de_DE", "es_ES"];
  }

  public isValidLocale(locale: string) {
    return this.getAvailableLocales().indexOf(locale) >= 0;
  }
}
