import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { FormControl, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import withStyles from "@mui/styles/withStyles";

import { deliveryHelper } from "tap-io/helpers";

const styles = (theme) => ({
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main
  },
  deliveryColors: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "15px 0 5px 0"
  },
  deliveryColorContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: 50,
    margin: "0 5px",
    cursor: "pointer"
  },
  disabledColorContainer: {
    cursor: "default",
    opacity: 0.2
  },
  deliveryColor: {
    position: "relative",
    width: 40,
    height: 40,
    borderRadius: 20,
    opacity: 0.6
  },
  selectedDeliveryColor: {
    opacity: 1
  },
  selectedIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    color: "white"
  }
});

class EditDeliveryMethods extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      allDeliveryColors: deliveryHelper.getAllDeliveryColors()
    };
  }

  isDeliveryColorSelected = (deliveryColor) => {
    const { deliveryColors } = this.props;

    return deliveryColors && deliveryColors.indexOf(deliveryColor) >= 0;
  };

  handleToggleDeliveryColor = (deliveryColor) => (event) => {
    const { onChange, deliveryColors } = this.props;

    onChange(deliveryColor, deliveryColors.indexOf(deliveryColor) < 0);
  };

  render() {
    const { classes, t, isDisabled } = this.props;
    const { allDeliveryColors } = this.state;

    return (
      <FormControl>
        <Typography>
          {t(
            "delivery.orders-are-automatically-assigned-one-of-following-colors"
          )}
        </Typography>
        <div className={classes.deliveryColors}>
          {allDeliveryColors.map((deliveryColor) => (
            <div
              key={deliveryColor.key}
              className={classNames(classes.deliveryColorContainer, {
                [classes.disabledColorContainer]: isDisabled
              })}
              onClick={
                isDisabled
                  ? undefined
                  : this.handleToggleDeliveryColor(deliveryColor.key)
              }
            >
              <div
                className={classNames(classes.deliveryColor, {
                  [classes.selectedDeliveryColor]: this.isDeliveryColorSelected(
                    deliveryColor.key
                  )
                })}
                style={{ backgroundColor: deliveryColor.value }}
              >
                {this.isDeliveryColorSelected(deliveryColor.key) && (
                  <div className={classes.selectedIconContainer}>
                    <CheckIcon />
                  </div>
                )}
              </div>
              <Typography variant="overline">
                {t(`color.${deliveryColor.key}`)}
              </Typography>
            </div>
          ))}
        </div>
        <Typography>
          {t("delivery.organise-orders-by-color-in-your-pickup-point")}
        </Typography>
      </FormControl>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditDeliveryMethods)
);
