import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import config from "../env";
import theme from "../theme";

const styles = (theme) => ({
  logo: {
    height: 40,
    margin: "5px 0 -6px 0"
  },
  links: {
    fontSize: 12,
    textAlign: "center",
    color: "black"
  },
  link: {
    display: "inline-block",
    margin: 10,
    textDecoration: "none",
    color: "black"
  },
  smallLogo: { marginRight: 5 },
  smallImg: {
    height: 20
  },
  smallLinks: {
    display: "inline-block"
  },
  smallLink: {
    fontSize: 10
  }
});

class Copyright extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, size, color, hideLogo } = this.props;

    return (
      <div>
        {!hideLogo && (
          <a
            href={config.links.promo}
            target="_blank"
            className={size === "small" ? classes.smallLogo : ""}
          >
            <img
              className={classNames(
                classes.logo,
                size === "small" ? classes.smallImg : ""
              )}
              src={theme.assets.logo}
            />
          </a>
        )}
        <div
          className={classNames(
            classes.links,
            size === "small" ? classes.smallLinks : ""
          )}
        >
          <a
            className={classNames(
              classes.link,
              size === "small" ? classes.smallLink : ""
            )}
            style={color ? { color } : {}}
            href={config.links.termsOfUse}
            target="_blank"
          >
            {t("legal.terms-of-use")}
          </a>
          <span className="hideOnMobile" style={color ? { color } : {}}>
            &bull;
          </span>
          <a
            className={classNames(
              classes.link,
              size === "small" ? classes.smallLink : ""
            )}
            style={color ? { color } : {}}
            href={config.links.privacyPolicy}
            target="_blank"
          >
            {t("legal.privacy-policy")}
          </a>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(Copyright));
