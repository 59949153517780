import { useState } from "react";
import { Asyncer } from "../../../components/common/Asyncer";
import { Async } from "../../../components/common/useAsync";
import { FeeReport } from "../../models/FeeReport";
import { OperationalTimeslotReport } from "../../models/OperationalTimeslotReport";
import { BarStats } from "../common/BarStats";
import { DownloadIconCSVButton } from "../common/DownloadCSVIconButton";
import { FailedStatCard } from "../common/FailedStatCard";
import { FailedStatChip } from "../common/FailedStatChip";
import { FilterSwitch } from "../common/FilterSwitch";
import { LoadingStatCard } from "../common/LoadingStatCard";
import { LoadingStatChip } from "../common/LoadingStatChip";
import { StatCard } from "../common/StatCard";
import { StatChip } from "../common/StatChip";

export type RevenuePerFeeProps = {
  async: Async<FeeReport>;
  amount: Async<OperationalTimeslotReport>;
  mark: string;
  t: any;
};

export function RevenuePerFee(props: RevenuePerFeeProps) {
  const { async, amount, mark, t } = props;

  const [isVatActive, setIsVatActive] = useState(true);

  const title = t("stats.stat.value-per-fee.title");
  const description = t("stats.stat.value-per-fee.description");

  return (
    <Asyncer
      async={async}
      Busy={<LoadingStatCard title={title} description={description} />}
      Failed={
        <FailedStatCard title={title} description={description} async={async} />
      }
    >
      {(report) => {
        return (
          <StatCard
            title={title}
            description={description}
            action={
              <DownloadIconCSVButton
                title={`${title}-${mark}`}
                csv={report.toFinancialCSV(t)}
              />
            }
            filters={
              <FilterSwitch
                label={t("stats.input.vat.label")}
                value={isVatActive}
                onChange={setIsVatActive}
              />
            }
            derivatives={
              <Asyncer
                async={amount}
                Busy={
                  <>
                    <LoadingStatChip
                      title={t("stats.stat.value-per-fee.total")}
                    />
                    <LoadingStatChip
                      title={t("stats.stat.value-per-fee.average-per-order")}
                    />
                  </>
                }
                Failed={
                  <>
                    <FailedStatChip
                      title={t("stats.stat.value-per-fee.total")}
                      async={amount}
                    />
                    <FailedStatChip
                      title={t("stats.stat.value-per-fee.average-per-order")}
                      async={amount}
                    />
                  </>
                }
              >
                {(amountReport) => {
                  const total = isVatActive
                    ? report.totalValueInclVat
                    : report.totalValueExclVat;

                  const average = total / amountReport.totalOrders;

                  return (
                    <>
                      <StatChip
                        title={t("stats.stat.value-per-fee.total")}
                        value={report.formatCurrency(total, {
                          currency: "EUR"
                        })}
                      />
                      <StatChip
                        title={t("stats.stat.value-per-fee.average-per-order")}
                        value={report.formatCurrency(average || 0, {
                          currency: "EUR"
                        })}
                      />
                    </>
                  );
                }}
              </Asyncer>
            }
          >
            <BarStats
              data={report.data}
              x="fee"
              formatX={(_, r) => r.name}
              y={isVatActive ? "valueInclVat" : "valueExclVat"}
              formatY={report.formatCurrency}
              t={t}
            />
          </StatCard>
        );
      }}
    </Asyncer>
  );
}
