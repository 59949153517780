enum ParamName {
  BAR_DESCRIPTION = "barDescription",
  ORDER_CONFIRMATION_MESSAGE = "orderConfirmationMessage",
  CUSTOMER_ADDRESSED_AS = "customerAddressedAs",
  CUSTOMER_FIELDS = "customerFields",
  REPLY_TO_EMAIL_ADDRESS = "replyToEmailAddress",
  ORDER_CURRENCY = "orderCurrency",
  MAX_AMOUNT_OF_ITEMS_PER_ORDER = "maxAmountOfItemsPerOrder",
  ALLOW_NOTES = "allowNotes",
  ALLOW_TIPS = "allowTips",
  ALLOW_ONLINE_PAYMENTS = "allowOnlinePayments",
  USE_BASES = "useBases",
  USE_ZONES = "useZones",
  PAYCONIQ_MERCHANT_LINK = "payconiqMerchantLink"
}

export default ParamName;
