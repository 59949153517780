import Moment from "moment-timezone";

import StatsData from "../models/stats/StatsData";
import StatsDataFrame from "../models/stats/StatsDataFrame";
import StatsDataCollection from "../models/stats/StatsDataCollection";
import StatsDataType from "../models/stats/StatsDataType";
import StatsDataTime from "../models/stats/StatsDataTime";
import StatsVersion from "../models/stats/StatsVersion";

export default class StatsHelper {
  constructor() {}

  public dataToStatsData(rawData: any): StatsData {
    if (!rawData) {
      throw new Error("error.raw-data-is-not-defined");
    }

    const { t, n, d } = rawData;
    switch (t) {
      case StatsDataType.COLLECTION:
        return new StatsDataCollection(d, n);
        break;
      case StatsDataType.FRAME:
        return new StatsDataFrame(d, n);
        break;
      default:
        throw new Error("error.data-type-is-invalid");
        break;
    }
  }

  public timestampToStatsDate(timestamp: Date): string {
    return Moment(timestamp).tz("Europe/Brussels").format("YYYY-MM-DD");
  }

  public timestampToStatsDataTime(timestamp: Date): StatsDataTime {
    return new StatsDataTime(
      Moment(timestamp).tz("Europe/Brussels").format("HHmm")
    );
  }

  public isStatsDataFrame(statsData: StatsData): boolean {
    return statsData instanceof StatsDataFrame;
  }

  public isStatsDataCollection(statsData: StatsData): boolean {
    return statsData instanceof StatsDataCollection;
  }

  public getDatesAsString(minDate: string, maxDate: string): Array<string> {
    if (minDate > maxDate) {
      throw new Error("error.min-date-should-come-before-max-date");
    }

    const parsedMinDate = Moment(minDate).startOf("day");
    const parsedMaxDate = Moment(maxDate).startOf("day").add(1, "hour");

    const dates: Array<string> = [];

    let date = parsedMinDate;
    while (date.isBefore(parsedMaxDate)) {
      dates.push(Moment(date).format("YYYY-MM-DD"));
      date.add(1, "day");
    }

    return dates;
  }

  public canStatsBeResetForDate(date: string): boolean {
    const todayDate = Moment().tz("Europe/Brussels").format("YYYY-MM-DD");
    return date < todayDate;
  }

  public hasStatsDataKey(
    statsDataKeys: Array<string>,
    statsDataKey: string
  ): boolean {
    return statsDataKeys.indexOf(statsDataKey) >= 0;
  }

  public getStatsFormat(
    statsDataUnit: string,
    statsDataKeys: Array<string>
  ): string {
    return `v:${StatsVersion.LATEST};u:${statsDataUnit};k:${statsDataKeys
      .sort()
      .join(",")}`;
  }

  public parseStatsFormat(
    statsFormat: string
  ): { statsDataUnit: string; statsDataKeys: Array<string> } | undefined {
    const matches = statsFormat.match(/v:(.+);u:(.+);k:(.+)/);

    if (matches && matches.length === 4) {
      //const statsVersion = matches[1];
      const statsDataUnit: string = matches[2];
      const statsDataKeys: string = matches[3];

      return {
        statsDataUnit,
        statsDataKeys: statsDataKeys.split(",")
      };
    }

    return undefined;
  }
}
