import React, { PureComponent } from "react";
import TestSoundDialog from "./TestSoundDialog";

import withStyles from "@mui/styles/withStyles";

import { soundHelper } from "tap-io/helpers";

const styles = (theme) => ({});

class Bell extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      bellAudio: new Audio("/sounds/bell.mp3"),
      isTested: false,
      isTestSoundDialogOpen: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { isMuted } = this.props;
    const { isTested, isTestSoundDialogOpen } = this.state;

    if (!isMuted && !isTested && !isTestSoundDialogOpen) {
      this.setState({ isTestSoundDialogOpen: true });
    }
  }

  handleTestSoundDialogClose = () => {
    this.setState({ isTested: true, isTestSoundDialogOpen: false });
  };

  playBell = () => {
    const { isMuted } = this.props;
    const { bellAudio, isTested } = this.state;

    if (isTested && !isMuted) {
      try {
        soundHelper.playSound(bellAudio);
      } catch (error) {
        console.warn(error);
      }
    }
  };

  render() {
    const { isTestSoundDialogOpen, bellAudio } = this.state;

    return (
      <TestSoundDialog
        isOpen={isTestSoundDialogOpen}
        onClose={this.handleTestSoundDialogClose}
        testSound={bellAudio}
      />
    );
  }
}

export default withStyles(styles)(Bell);
