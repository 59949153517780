import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { menuService } from "tap-io/client/services";

import UploadImage from "../common/UploadImage";
import { cloudStorage } from "tap-io/storage";

const styles = {
  media: {
    height: 0,
    marginTop: 10,
    paddingTop: "56.25%", // 16:9
    backgroundSize: "contain"
  },
  isHiddenContainer: {
    display: "flex",
    flexAlign: "row",
    alignItems: "center"
  },
  inputLabel: {
    width: "100%"
  }
};

class EditCategoryDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (category) => {
    return {
      isDisabled: false,
      name: category && category.name ? category.name : "",
      isHidden: category ? category.isHidden === true : false,
      imageFilename:
        category && category.imageFilename ? category.imageFilename : null,
      imageUrl: null
    };
  };

  componentDidMount() {
    const { category } = this.props;

    if (category) {
      this.setState(this.initialState(category));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen, category } = this.props;
    const { imageFilename } = this.state;
    const prevImageFilename = prevState.imageFilename;

    if (isOpen && !prevProps.isOpen) {
      this.setState(this.initialState(category), this.refreshCategoryImageUrl);
    } else if (imageFilename !== prevImageFilename) {
      this.refreshCategoryImageUrl();
    }
  }

  refreshCategoryImageUrl = async () => {
    const { menu } = this.props;
    const { imageFilename } = this.state;

    if (menu) {
      try {
        const category = menuService.createMenuCategory(menu.id, {
          imageFilename
        });
        const imageUrl = await cloudStorage.getBarMenuCategoryImageUrl(
          menu.barId,
          category
        );

        this.setState({ imageUrl });
      } catch (error) {
        console.warn(error);

        this.setState({ imageUrl: null });
      }
    } else {
      this.setState({ imageUrl: null });
    }
  };

  handleNameChange = (event) => {
    const val = event.target.value;
    this.setState({ name: val });
  };

  handleIsHiddenChange = (event) => {
    const value = !(event.target.checked === true);
    this.setState({ isHidden: value });
  };

  handleImageUploadSuccess = (filename) => {
    this.setState({ imageFilename: filename });
  };

  handleImageUploadError = (error) => {
    if (error) {
      toast.error(error.message);
    }
  };

  handleImageRemove = () => {
    this.setState({ imageFilename: null });
  };

  handleUpdateCategory = async () => {
    const { t, menu, category, onClose } = this.props;
    const { name, isHidden, imageFilename } = this.state;

    const toastId = toast(t("menu.updating-category"), { autoClose: false });
    this.setState({ isDisabled: true });

    try {
      category.name = name.trim();
      category.isHidden = isHidden;
      category.imageFilename = imageFilename;

      await menuService.updateMenuCategory(menu, category);

      toast.update(toastId, {
        render: t("menu.category-updated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false }, onClose);
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(error.message)})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, isOpen, onClose, menu } = this.props;
    const { isDisabled, name, isHidden, imageUrl } = this.state;

    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>{t("menu.edit-category")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("menu.edit-category-below")}</DialogContentText>
          <TextField
            inputProps={{
              "data-cy": "category-name"
            }}
            variant="standard"
            margin="dense"
            label={t("label.name")}
            //helperText={t("menu.name-of-this-category")}
            fullWidth
            value={name}
            disabled={isDisabled}
            onChange={this.handleNameChange}
          />
          {menu && (
            <UploadImage
              inputProps={{
                "data-cy": "category-image-upload"
              }}
              label={t("label.upload-new-image")}
              isDisabled={isDisabled}
              uploadRef={cloudStorage.getBarMenuCategoryImagesRef(
                menu.barId,
                menu.id
              )}
              onSuccess={this.handleImageUploadSuccess}
              onError={this.handleImageUploadError}
            />
          )}
          {imageUrl && (
            <div>
              <CardMedia
                data-cy="category-image"
                className={classes.media}
                image={imageUrl}
                title={t("label.image")}
              />
              <Button
                data-cy="category-image-remove"
                fullWidth
                disabled={isDisabled}
                onClick={this.handleImageRemove}
              >
                {t("label.remove-image")}
              </Button>
            </div>
          )}
          <div className={classes.isHiddenContainer}>
            <Typography className={classes.inputLabel}>
              {t("menu.show-category-on-order-page")}
            </Typography>
            <Switch
              edge="end"
              disabled={isDisabled}
              checked={!isHidden}
              onChange={this.handleIsHiddenChange}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button disabled={isDisabled} onClick={onClose} color="secondary">
            {t("label.cancel")}
          </Button>
          <Button
            data-cy="category-add"
            disabled={isDisabled}
            onClick={this.handleUpdateCategory}
            color="primary"
          >
            {t("label.update")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(EditCategoryDialog)
);
