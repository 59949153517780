import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import SingleLayoutNode from "tap-io/models/zone/SingleLayoutNode";
import LayoutNodeType from "tap-io/models/zone/LayoutNodeType";

import { LAYOUT_NODE_ICONS } from "../../constants/zones";

const styles = (theme) => ({});

class AddSingleLayoutNodeMenuItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleAddSingleLayoutNode = () => {
    const { t, onAdd, layoutNode } = this.props;

    const childNode = new SingleLayoutNode("", t("zone.node"), null, null);
    layoutNode.addChild(childNode);

    if (onAdd) {
      onAdd(childNode);
    }
  };

  render() {
    const { t } = this.props;

    const LayoutNodeIcon = LAYOUT_NODE_ICONS[LayoutNodeType.SINGLE];

    return (
      <MenuItem onClick={this.handleAddSingleLayoutNode}>
        <ListItemIcon>
          <LayoutNodeIcon />
        </ListItemIcon>
        <ListItemText primary={t("zone.add-single-layout-node")} />
      </MenuItem>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(AddSingleLayoutNodeMenuItem)
);
