import React, { PureComponent } from "react";

import withStyles from "@mui/styles/withStyles";

import SelectPlan from "../subscription/SelectPlan";
import { dataService } from "tap-io/client/services";
import {
  BAR_SIZE_LARGE,
  BAR_SIZE_MEDIUM,
  BAR_SIZE_SMALL
} from "../../constants/bar";
import { PLAN_BASIC, PLAN_FULL_SERVICE, PLAN_PRO } from "../../constants/plans";

const styles = (theme) => ({});

class SelectPlanStep extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      recommendedPlan: null
    };
  }

  componentDidMount() {
    const { setOnNext } = this.props;
    setOnNext(() => {});
  }

  componentDidMount() {
    this.refreshAdministrationData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { bar } = this.props;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;

    if (barId !== prevBarId) {
      this.refreshAdministrationData();
    }
  }

  refreshAdministrationData = async () => {
    const { bar } = this.props;

    if (bar) {
      try {
        const administrationData = await dataService.getAdministrationData(
          bar.id
        );

        let recommendedPlan = null;
        switch (administrationData.barSize) {
          case BAR_SIZE_SMALL:
            recommendedPlan = PLAN_BASIC;
            break;
          case BAR_SIZE_MEDIUM:
            recommendedPlan = PLAN_PRO;
            break;
          case BAR_SIZE_LARGE:
            recommendedPlan = PLAN_FULL_SERVICE;
            break;
        }

        this.setState({ recommendedPlan });
      } catch (error) {
        console.warn(error);

        this.setState({ recommendedPlan: null });
      }
    } else {
      this.setState({ recommendedPlan: null });
    }
  };

  render() {
    const { bar, subscription, onNext } = this.props;
    const { recommendedPlan } = this.state;

    return (
      <SelectPlan
        bar={bar}
        subscription={subscription}
        onSelect={onNext}
        recommendedPlan={recommendedPlan}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(SelectPlanStep);
