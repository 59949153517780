import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import LeakAddIcon from "@mui/icons-material/LeakAdd";
import withStyles from "@mui/styles/withStyles";

import { callNativeMethod, printer } from "../../native";

import EditPrinterSettings from "./EditPrinterSettings";

const styles = (theme) => ({
  drawer: {
    zIndex: 1400
  }
});

class PrinterDrawer extends PureComponent {
  handleOpenPrinterModal = () => {
    callNativeMethod("openPrinterModalHandler");
  };

  handleClose = () => {
    const { t, bar, assets, deviceSettings, onClose } = this.props;

    if (deviceSettings && deviceSettings.printOrderWhenClaimed) {
      printer.printTest(t, bar, assets, deviceSettings.printBarLogo);
    }

    onClose();
  };

  render() {
    const { t, classes, isOpen, bar, base, assets, deviceSettings } =
      this.props;

    return (
      <Drawer
        anchor="bottom"
        open={isOpen}
        onClose={this.handleClose}
        className={classes.drawer}
      >
        <EditPrinterSettings
          bar={bar}
          base={base}
          assets={assets}
          deviceSettings={deviceSettings}
        />
        <List>
          <ListItem button onClick={this.handleOpenPrinterModal}>
            <ListItemIcon>
              <LeakAddIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="button">
                  {t("printer.connect-with-printer")}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Drawer>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(PrinterDrawer)
);

/*
const currentType = printer.getCurrentType();
this.state = {
  types: printer.getTypes(),
  printerType: currentType ? currentType : ""
};

handlePrinterTypeChange = (event) => {
  const { bar, assets } = this.props;
  const printerType = event.target.value;

  printer.setCurrentType(printerType);
  this.setState({ printerType });

  printer.printTest(bar, assets);
};

const { types, printerType } = this.state;
<ListItem>
  <FormControl component="fieldset">
    <FormLabel component="legend">Type printer</FormLabel>
    <RadioGroup
      aria-label="printerType"
      name="printerType"
      value={printerType}
      onChange={this.handlePrinterTypeChange}
    >
      {types.map(type => (
        <FormControlLabel
          key={type.id}
          value={type.id}
          control={<Radio />}
          label={type.label}
        />
      ))}
    </RadioGroup>
  </FormControl>
</ListItem>
*/
