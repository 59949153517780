import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { IconButton, List } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import withStyles from "@mui/styles/withStyles";

import InfiniteScroll from "react-infinite-scroll-component";

import { orderHelper, utilsHelper } from "tap-io/helpers";
import { barService, orderService } from "tap-io/client/services";
import config from "tap-io/client/env";

import OrderItem from "./OrderItem";
import { hasNativeWrapper, printer } from "../../native";

const styles = (theme) => ({
  orderList: {
    //overflowY: "scroll",
    //height: "100%"
  }
});

const ORDERS_INITIAL_BATCH_SIZE = 10;
const ORDERS_ADDITIONAL_BATCH_SIZE = 5;

class CrewOrders extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      crewOrdersMaxCount: ORDERS_INITIAL_BATCH_SIZE
    };

    this.orderListRef = React.createRef();
  }

  componentDidMount() {
    this.refreshCrewOrders();
  }

  componentWillUnmount() {
    if (this.unsubscribeCrewOrders) {
      this.unsubscribeCrewOrders();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { bar, base } = this.props;
    const { crewOrdersMaxCount } = this.state;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;

    const menuIds = base ? base.menuIds : undefined;
    const prevMenuIds = prevProps.base ? prevProps.base.menuIds : undefined;

    const prevCrewOrdersMaxCount = prevState.crewOrdersMaxCount;

    if (
      barId !== prevBarId ||
      !utilsHelper.areArraysEqualShallow(menuIds, prevMenuIds) ||
      crewOrdersMaxCount !== prevCrewOrdersMaxCount
    ) {
      this.refreshCrewOrders();
    }
  }

  refreshCrewOrders = () => {
    const { bar, base } = this.props;
    const { crewOrdersMaxCount } = this.state;

    if (this.unsubscribeCrewOrders) {
      this.unsubscribeCrewOrders();
    }

    if (bar && bar.id) {
      this.unsubscribeCrewOrders = orderService.onCrewOrders(
        bar.id,
        (orders) => {
          this.setState({
            crewOrders: orders
          });
        },
        crewOrdersMaxCount,
        base ? base.menuIds : undefined
      );
    } else {
      this.unsubscribeCrewOrders = undefined;
    }
  };

  handleFetchMoreOrders = () => {
    const { crewOrdersMaxCount } = this.state;

    this.setState({
      crewOrdersMaxCount: crewOrdersMaxCount + ORDERS_ADDITIONAL_BATCH_SIZE
    });
  };

  handlePrintCustomerReceipt = (order) => async (event) => {
    const { t, bar, allBases, deviceSettings, assets } = this.props;

    try {
      if (hasNativeWrapper()) {
        const barUrl = await barService.getBarUrl(
          bar,
          config.hosting.orderDomain
        );
        const orderUrl = orderHelper.getOrderUrl(barUrl, order.id);

        await printer.printCustomerReceipts(
          t,
          bar,
          allBases,
          assets,
          order,
          orderUrl,
          deviceSettings.printBarLogo,
          1
        );
      }
    } catch (error) {
      if (error) {
        toast.error(error.message);
      }
      console.warn(error);
    }
  };

  render() {
    const { classes, t, bar, base, onOrderClick } = this.props;
    const { crewOrdersMaxCount, crewOrders } = this.state;

    // TO FIX: scrollableTarget

    return (
      <List ref={this.orderListRef} className={classes.orderList}>
        {crewOrders && (
          <InfiniteScroll
            dataLength={crewOrders.length}
            next={this.handleFetchMoreOrders}
            hasMore={
              crewOrders === undefined ||
              crewOrdersMaxCount <= crewOrders.length
            }
            loader={<h4>{t("label.loading")}</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>{t("order.all-orders-are-shown")}</b>
              </p>
            }
          >
            {crewOrders.map((order) => (
              <OrderItem
                key={order.id}
                bar={bar}
                base={base}
                order={order}
                onClick={onOrderClick}
                secondaryAction={
                  hasNativeWrapper() && (
                    <IconButton
                      onClick={this.handlePrintCustomerReceipt(order)}
                      size="large"
                    >
                      <PrintIcon />
                    </IconButton>
                  )
                }
              />
            ))}
          </InfiniteScroll>
        )}
      </List>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(CrewOrders)
);
