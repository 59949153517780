import { Asyncer } from "../../../components/common/Asyncer";
import { Async } from "../../../components/common/useAsync";
import { OperationalTimeslotReport } from "../../models/OperationalTimeslotReport";
import { BarStats } from "../common/BarStats";
import { DownloadIconCSVButton } from "../common/DownloadCSVIconButton";
import { FailedStatCard } from "../common/FailedStatCard";
import { LoadingStatCard } from "../common/LoadingStatCard";
import { StatCard } from "../common/StatCard";

export type AmountOfProductsPerHourProps = {
  async: Async<OperationalTimeslotReport>;
  date: Date;
  mark: string;
  t: any;
};

export function AmountOfProductsPerHour(props: AmountOfProductsPerHourProps) {
  const { async, date, mark, t } = props;

  const title = t("stats.stat.products-per-hour.title");
  const description = t("stats.stat.products-per-hour.description");

  return (
    <Asyncer
      async={async}
      Busy={<LoadingStatCard title={title} description={description} />}
      Failed={
        <FailedStatCard title={title} description={description} async={async} />
      }
    >
      {(report) => {
        return (
          <StatCard
            title={title}
            description={description}
            action={
              <DownloadIconCSVButton
                title={`${title}-${mark}`}
                csv={report.toCSV(date, t)}
              />
            }
          >
            <BarStats
              limit={24}
              vertical
              sort={["hour", "ASC"]}
              data={report.activeHours}
              x="hour"
              formatX={report.formatHour(date)}
              y="amountOfProducts"
              formatY={report.formatInteger}
              t={t}
            />
          </StatCard>
        );
      }}
    </Asyncer>
  );
}
