import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { MOLLIE_SIGNUP_LINK } from "../../constants/payments";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    padding: "10px 20px",
    border: "2px solid #8bcdcd",
    borderRadius: 5,
    textDecoration: "none",
    color: "#8bcdcd"
  },
  callToAction: {
    fontWeight: "bold"
  }
});

class SignUpForMollie extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t } = this.props;

    return (
      <a
        className={classes.root}
        href={MOLLIE_SIGNUP_LINK}
        target="_blank"
        rel="noreferrer"
      >
        <Typography variant="body1">
          {t("settings.no-mollie-account-yet")}
        </Typography>
        <Typography variant="button" className={classes.callToAction}>
          {t("settings.sign-up-now")}
        </Typography>
      </a>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(SignUpForMollie)
);
