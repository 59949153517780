import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import { List } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import EditLayoutNodeMenu from "./EditLayoutNodeMenu";
import RecursiveLayoutNodeLevel from "./RecursiveLayoutNodeLevel";
import LayoutNodeItem from "./LayoutNodeItem";

const styles = (theme) => ({
  content: {
    overflowY: "scroll",
    minWidth: 400,
    maxHeight: "100%"
  },
  child: {
    backgroundColor: "red"
  }
});

class LayoutNodeTree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editLayoutNodeMenuAnchor: null,
      layoutNodeBeingEdited: null,
      showLayoutNodeChildren: true
    };
  }

  handleClick = (layoutNode) => {
    const { onSelect } = this.props;

    onSelect(layoutNode);
  };

  handleToggleShowChildrenClick = (event) => {
    const { showLayoutNodeChildren } = this.state;

    this.setState({
      showLayoutNodeChildren: !showLayoutNodeChildren
    });
  };

  handleMoreClick = (event, layoutNode) => {
    this.setState({
      editLayoutNodeMenuAnchor: event.currentTarget,
      layoutNodeBeingEdited: layoutNode
    });
  };

  handleLayoutNodeAdd = (addedLayoutNode) => {
    const { onSelect, onUpdate } = this.props;

    onSelect(addedLayoutNode);
    this.handleEditLayoutNodeMenuClose();

    onUpdate(addedLayoutNode.root);
  };

  handleLayoutNodeRemove = (removedLayoutNode) => {
    const { onSelect, onUpdate } = this.props;

    onSelect(removedLayoutNode.parent);
    this.handleEditLayoutNodeMenuClose();

    onUpdate(removedLayoutNode.root);
  };

  handleEditLayoutNodeMenuClose = () => {
    this.setState({ editLayoutNodeMenuAnchor: null, layoutNode: null });
  };

  render() {
    const { classes, t, isDisabled, maxHeight, rootNode, selectedNode } =
      this.props;
    const {
      editLayoutNodeMenuAnchor,
      layoutNodeBeingEdited,
      showLayoutNodeChildren
    } = this.state;

    return (
      <div className={classes.content}>
        <EditLayoutNodeMenu
          anchor={editLayoutNodeMenuAnchor}
          maxHeight={maxHeight}
          onLayoutNodeAdd={this.handleLayoutNodeAdd}
          onLayoutNodeRemove={this.handleLayoutNodeRemove}
          onClose={this.handleEditLayoutNodeMenuClose}
          layoutNode={layoutNodeBeingEdited}
        />
        <List>
          <LayoutNodeItem
            isDisabled={isDisabled}
            isOpen={showLayoutNodeChildren}
            isSelected={selectedNode === rootNode}
            layoutNode={rootNode}
            onClick={this.handleClick}
            onToggleIsOpenClick={this.handleToggleShowChildrenClick}
            onMoreClick={this.handleMoreClick}
          />
          {showLayoutNodeChildren && (
            <RecursiveLayoutNodeLevel
              isDisabled={isDisabled}
              maxHeight={maxHeight}
              layoutNode={rootNode}
              selectedNode={selectedNode}
              onClick={this.handleClick}
              onMoreClick={this.handleMoreClick}
            />
          )}
        </List>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(LayoutNodeTree)
);
