import React, { PureComponent } from "react";

import AuthContext from "tap-io/client/components/auth/authContext";

import { authService, crewService } from "tap-io/client/services";

const withAuthentication = (Component) => {
  class WithAuthentication extends PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        isReady: false,
        isError: false
      };
    }

    componentDidMount() {
      // Fallback
      const fallbackTimeout = setTimeout(() => {
        this.setState({ isError: true });
      }, 5000);

      this.unsubscribeOnAuthStateChanged = authService.onAuthStateChanged(
        (user) => {
          clearTimeout(fallbackTimeout);

          // Unsubscribe
          if (this.unsubscribeCrew) {
            this.unsubscribeCrew();
            this.unsubscribeCrew = undefined;
          }

          if (user) {
            this.unsubscribeCrew = crewService.onCrew(user.uid, (crew) => {
              if (crew) {
                this.setState(() => ({
                  isReady: true,
                  user,
                  crew
                }));
              } else {
                this.setState(() => ({
                  isReady: true,
                  user,
                  crew: null
                }));
              }
            });
          } else {
            this.setState(() => ({
              isReady: true,
              user: null,
              crew: null
            }));
          }
        }
      );
    }

    componentWillUnmount() {
      if (this.unsubscribeOnAuthStateChanged) {
        this.unsubscribeOnAuthStateChanged();
      }
    }

    render() {
      const { isReady, isError, user, crew } = this.state;

      const auth = {
        user,
        crew
      };

      return (
        <AuthContext.Provider value={auth}>
          {isReady ? (
            <Component {...this.props} />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100vw",
                height: "100vh",
                textAlign: "center"
              }}
            >
              {isError ? (
                <div style={{ margin: 20 }}>
                  <div>Dit lijkt lang te duren 😕</div>
                  <br />
                  <div>
                    Controleer je internetverbinding, sluit je browser en
                    probeer opnieuw
                  </div>
                </div>
              ) : (
                <div>Laden...</div>
              )}
            </div>
          )}
        </AuthContext.Provider>
      );
    }
  }

  return WithAuthentication;
};

export default withAuthentication;
