import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import withStyles from "@mui/styles/withStyles";

import SelectPlanDialog from "./SelectPlanDialog";

const styles = (theme) => ({
  container: {
    display: "flex",
    justifyContent: "center"
  }
});

class CapabilityUnavailableButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isSelectPlanDialogOpen: false
    };
  }

  handleSelectPlan = () => {
    this.setState({ isSelectPlanDialogOpen: true });
  };

  handleSelectPlanDialogClose = () => {
    this.setState({ isSelectPlanDialogOpen: false });
  };

  render() {
    const { classes, t, bar, subscription, label, isDisabled } = this.props;
    const { isSelectPlanDialogOpen } = this.state;

    return (
      <div className={classes.container}>
        <SelectPlanDialog
          isOpen={isSelectPlanDialogOpen}
          onClose={this.handleSelectPlanDialogClose}
          bar={bar}
          subscription={subscription}
        />
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={isDisabled}
          onClick={this.handleSelectPlan}
          startIcon={<LockIcon />}
        >
          {label ? label : t("subscription.available-in-other-plans")}
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(CapabilityUnavailableButton)
);
