import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import withStyles from "@mui/styles/withStyles";

import { baseHelper, utilsHelper } from "tap-io/helpers";
import { scannerService } from "tap-io/client/services";
import ConfirmDialog from "tap-io/client/components/common/ConfirmDialog";
import ServiceOptionName from "tap-io/models/service/ServiceOptionName";

import AddScannerDialog from "./AddScannerDialog";
import EditScannerDialog from "./EditScannerDialog";
import CapabilityMaxAmountIndicator from "../subscription/CapabilityMaxAmountIndicator";

const styles = (theme) => ({
  accentuate: {
    color: "red"
  },
  link: {
    display: "block",
    width: "100%",
    margin: theme.spacing(1),
    textDecoration: "none"
  },
  spacing: {
    height: 20
  }
});

class EditScanners extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isAddScannerDialogOpen: false,
      scannerBeingEdited: null,
      scannerPendingToggleIsActiveConfirmation: null,
      scannerPendingRemovalConfirmation: null
    };
  }

  componentDidMount() {
    this.refreshAllScanners();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { bar } = this.props;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;

    if (barId !== prevBarId) {
      this.refreshAllScanners();
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeAllScanners) {
      this.unsubscribeAllScanners();
      this.unsubscribeAllScanners = undefined;
    }
  }

  refreshAllScanners = () => {
    const { bar } = this.props;

    if (this.unsubscribeAllScanners) {
      this.unsubscribeAllScanners();
      this.unsubscribeAllScanners = undefined;
    }

    if (bar) {
      this.unsubscribeAllScanners = scannerService.onAllScanners(
        bar.id,
        (allScanners) => {
          this.setState({ allScanners });
        }
      );
    } else {
      this.setState({ allScanners: null });
    }
  };

  handleAddScanner = async () => {
    this.setState({ isAddScannerDialogOpen: true });
  };

  handleAddScannerDialogClose = async (scannerId) => {
    const { bar } = this.props;

    this.setState({ isAddScannerDialogOpen: false });

    if (scannerId) {
      const scanner = await scannerService.getById(bar.id, scannerId);
      this.setState({ scannerBeingEdited: scanner });
    }
  };

  handleRemoveScanner = (scanner) => (event) => {
    event.stopPropagation();

    this.setState({
      scannerPendingRemovalConfirmation: scanner
    });
  };

  handleRemoveScannerConfirm = async () => {
    const { t } = this.props;
    const { scannerPendingRemovalConfirmation } = this.state;

    this.setState({
      scannerPendingRemovalConfirmation: null
    });

    const toastId = toast(t("scanner.removing-scanner"), { autoClose: false });

    try {
      await scannerService.removeScanner(scannerPendingRemovalConfirmation);

      toast.update(toastId, {
        render: t("scanner.scanner-removed"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleRemoveScannerCancel = () => {
    this.setState({ scannerPendingRemovalConfirmation: null });
  };

  handleEditScanner = (scanner) => (event) => {
    this.setState({ scannerBeingEdited: scanner });
  };

  handleEditScannerDialogClose = () => {
    this.setState({ scannerBeingEdited: null });
  };

  handleToggleScannerIsActive = (scanner) => (event) => {
    event.preventDefault();

    this.setState({ scannerPendingToggleIsActiveConfirmation: scanner });
  };

  handleToggleScannerIsActiveConfirm = async () => {
    const { scannerPendingToggleIsActiveConfirmation } = this.state;

    this.setState({ scannerPendingToggleIsActiveConfirmation: null });

    await this.toggleScannerIsActive(scannerPendingToggleIsActiveConfirmation);
  };

  handleToggleScannerIsActiveCancel = () => {
    this.setState({ scannerPendingToggleIsActiveConfirmation: null });
  };

  toggleScannerIsActive = async (scanner) => {
    const { t } = this.props;

    const toastId = toast(
      scanner.isActive
        ? t("scanner.activating-scanner")
        : t("scanner.deactivating-scanner"),
      {
        autoClose: false
      }
    );

    try {
      scanner.isActive = !scanner.isActive;
      await scannerService.updateScanner(scanner);

      toast.update(toastId, {
        render: scanner.isActive
          ? t("scanner.scanner-activated")
          : t("scanner.scanner-deactivated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  getBasePerScanner = () => {
    const { allBases } = this.props;
    const { allScanners } = this.state;

    const basePerScanner = {};

    if (allBases && allScanners) {
      allScanners.forEach((scanner) => {
        basePerScanner[scanner.id] = utilsHelper.findInArrayById(
          allBases,
          scanner.baseId
        );
      });
    }

    return basePerScanner;
  };

  render() {
    const { classes, t, bar, subscription, allBases, isDisabled } = this.props;
    const {
      isAddScannerDialogOpen,
      scannerPendingToggleIsActiveConfirmation,
      scannerPendingRemovalConfirmation,
      scannerBeingEdited,
      allScanners
    } = this.state;

    const basePerScanner = this.getBasePerScanner();
    const areAnyBasesUsingScanners =
      allBases &&
      allBases.filter(baseHelper.onlyBasesUsingScannersFilter).length > 0;

    return (
      <div>
        <AddScannerDialog
          isOpen={isAddScannerDialogOpen}
          onClose={this.handleAddScannerDialogClose}
          bar={bar}
          allBases={allBases}
        />
        <EditScannerDialog
          isOpen={scannerBeingEdited !== null}
          onClose={this.handleEditScannerDialogClose}
          bar={bar}
          allBases={allBases}
          scanner={scannerBeingEdited}
        />
        <ConfirmDialog
          isOpen={scannerPendingToggleIsActiveConfirmation !== null}
          title={t(
            scannerPendingToggleIsActiveConfirmation?.isActive
              ? "scanner.deactivate-scanner"
              : "scanner.activate-scanner"
          )}
          description={t(
            scannerPendingToggleIsActiveConfirmation?.isActive
              ? "scanner.are-you-sure-you-want-to-deactivate-this-scanner"
              : "scanner.are-you-sure-you-want-to-activate-this-scanner"
          )}
          confirmButtonLabel={t(
            scannerPendingToggleIsActiveConfirmation?.isActive
              ? "scanner.deactivate-scanner"
              : "scanner.activate-scanner"
          )}
          onConfirm={this.handleToggleScannerIsActiveConfirm}
          onCancel={this.handleToggleScannerIsActiveCancel}
        />
        <ConfirmDialog
          isOpen={scannerPendingRemovalConfirmation !== null}
          title={t("scanner.confirm-removal-of-scanner")}
          description={t(
            "scanner.are-you-sure-you-want-to-remove-this-scanner"
          )}
          confirmButtonLabel={t("label.remove")}
          onConfirm={this.handleRemoveScannerConfirm}
          onCancel={this.handleRemoveScannerCancel}
        />
        <List>
          {allScanners && subscription && (
            <ListItem>
              <CapabilityMaxAmountIndicator
                currentAmount={allScanners.length}
                maxAmount={subscription.capabilities.getMaxAmountOfScanners()}
                amountDescription="scanner.amount-of-scanners-used"
                amountExceededDescription="scanner.max-amount-of-scanners-exceeded-remove-scanners-to-continue"
              />
            </ListItem>
          )}
          {allScanners &&
            allScanners.length > 0 &&
            allScanners.map((scanner) => (
              <ListItem
                key={scanner.id}
                onClick={
                  isDisabled ? undefined : this.handleEditScanner(scanner)
                }
              >
                <ListItemIcon>
                  <IconButton
                    disabled={isDisabled}
                    onClick={this.handleRemoveScanner(scanner)}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemIcon>
                <ListItemText
                  primary={scanner.name}
                  secondary={
                    basePerScanner[scanner.id] ? (
                      basePerScanner[scanner.id].canUseScanner ? (
                        basePerScanner[scanner.id].name
                      ) : (
                        <strong className={classes.accentuate}>
                          {t("scanner.base-does-not-use-scanners")}
                        </strong>
                      )
                    ) : (
                      <strong className={classes.accentuate}>
                        {t("scanner.base-not-found")}
                      </strong>
                    )
                  }
                />
                <ListItemSecondaryAction>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={isDisabled}
                        onChange={this.handleToggleScannerIsActive(scanner)}
                        checked={scanner.isActive}
                      />
                    }
                    label={
                      <Typography variant="caption">
                        {t("scanner.active-question")}
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          {areAnyBasesUsingScanners ? (
            subscription &&
            (!allScanners ||
              subscription.capabilities.getMaxAmountOfScanners() ===
              undefined ||
              allScanners.length <
              subscription.capabilities.getMaxAmountOfScanners()) && (
              <ListItem>
                <Button
                  fullWidth
                  startIcon={<AddIcon />}
                  disabled={isDisabled}
                  onClick={this.handleAddScanner}
                >
                  {t("scanner.add-scanner")}
                </Button>
              </ListItem>
            )
          ) : (
            <ListItem>
              <ListItemText
                primary={t(
                  "scanner.configure-at-least-one-base-to-use-scanners"
                )}
              />
            </ListItem>
          )}
        </List>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditScanners)
);
