import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { zoneService } from "tap-io/client/services";
import { utilsHelper } from "tap-io/helpers";

import EditLayout from "./EditLayout";
import ZoneLayoutNode from "tap-io/models/zone/ZoneLayoutNode";
import CapabilityUnavailableButton from "../subscription/CapabilityUnavailableButton";

const styles = (theme) => ({
  largeDescription: {
    minHeight: 150
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  spacing: {
    height: 15
  }
});

class AddZoneDialog extends Component {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (code, name, bases) => {
    return {
      isDisabled: false,
      layout: new ZoneLayoutNode(
        code ? code : "",
        name ? name : "",
        bases ? bases : null
      )
    };
  };

  componentDidUpdate(prevProps, prevState) {
    const { t, isOpen, code } = this.props;

    if (isOpen && !prevProps.isOpen) {
      this.setState(
        this.initialState(code, code ? `${t("zone.zone")} ${code}` : undefined)
      );
    }
  }

  getZoneBasesLabel = () => {
    const { t, allBases } = this.props;
    const { layout } = this.state;

    if (allBases && layout) {
      const baseNames = layout.allBaseIds.map((baseId) => {
        const base = utilsHelper.findInArrayById(allBases, baseId);

        return base ? base.name : t("label.unknown");
      });

      switch (baseNames.length) {
        case 0:
          return t("zone.there-are-no-bases-linked-to-this-zone");
          break;
        case 1:
          return `${t("zone.base-x-is-linked-to-this-zone", {
            baseName: baseNames[0]
          })}`;
          break;
        default:
          return `${t("zone.bases-x-are-linked-to-this-zone", {
            baseNames: baseNames.join(", ")
          })}`;
          break;
      }
    }

    return "";
  };

  handleLayoutUpdate = (layout) => {
    this.setState({ layout });
  };

  handleAddZone = async () => {
    const { t, onClose, bar } = this.props;
    const { layout } = this.state;

    if (bar.isUsingBases()) {
      if (layout.allBaseIds.length === 0) {
        return toast.error(t("error.select-at-least-one-base"));
      }
    }

    const props = {
      isActive: true,
      //name: name.trim(),
      layout: layout.toJSON()
    };

    /*if (!deliveryMethods.isServeActive && !deliveryMethods.isPickupActive) {
      return toast.error(t("error.select-at-least-one-delivery-method"));
    }
    if (deliveryMethods.isServeActive && layout.children.length === 0) {
      return toast.error(
        t("error.set-at-least-one-layout-node-to-enable-serve-delivery-method")
      );
    }
    if (
      deliveryMethods.isServeActive &&
      !deliveryMethods.isPickupActive &&
      !isLayoutPathRequiredToOrder
    ) {
      return toast.error(
        t(
          "error.table-number-should-be-required-to-order-when-only-serve-delivery-method-is-selected"
        )
      );
    }*/
    //props.deliveryMethods = deliveryMethods.toJSON();
    //props.bases = bases.toJSON();

    const toastId = toast(t("zone.adding-zone"), { autoClose: false });
    this.setState({ isDisabled: true });

    try {
      /*if (layout.children.length === 0 && isLayoutPathRequiredToOrder) {
        throw new Error(
          "error.set-at-least-one-layout-node-when-layout-path-is-required"
        );
      }*/

      const zone = zoneService.createZone(bar.id, layout.code, props);
      await zoneService.addZone(zone);

      toast.update(toastId, {
        render: t("zone.zone-added"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false }, onClose);
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(
          error ? error.message : "label.unknown-error"
        )})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const {
      classes,
      t,
      isOpen,
      onClose,
      bar,
      subscription,
      allBases,
      maxHeight
    } = this.props;
    const { isDisabled, layout } = this.state;

    const layoutHeightExceeded =
      maxHeight !== undefined && layout.height > maxHeight;

    // TO FIX: DialogContent duplicate
    return (
      <Dialog open={isOpen} onClose={onClose} fullScreen>
        <DialogTitle>{t("zone.add-a-new-zone")}</DialogTitle>
        <DialogContent
          className={
            layoutHeightExceeded ? classes.largeDescription : undefined
          }
        >
          <DialogContentText>
            <span>{this.getZoneBasesLabel()}</span>
            <span className={classes.spacing} />
            {layoutHeightExceeded && (
              <>
                <strong className={classes.errorText}>
                  {t(
                    "zone.max-layout-height-is-exceeded-remove-marked-layout-nodes-to-continue"
                  )}
                </strong>
                <span className={classes.spacing} />
                <CapabilityUnavailableButton
                  bar={bar}
                  subscription={subscription}
                  label={t(
                    "zone.larger-layout-height-available-in-other-plans"
                  )}
                />
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogContent className={classes.content}>
          <EditLayout
            isDisabled={isDisabled}
            bar={bar}
            allBases={allBases}
            maxHeight={maxHeight}
            layout={layout}
            onUpdate={this.handleLayoutUpdate}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={isDisabled} onClick={onClose} color="secondary">
            {t("label.cancel")}
          </Button>
          <Button
            disabled={isDisabled}
            onClick={this.handleAddZone}
            color="primary"
          >
            {t("label.add")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(AddZoneDialog));
