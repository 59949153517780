import React, { createRef, PropsWithChildren, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { TransitionGroup, CSSTransition } from 'react-transition-group';

import {
    Theme, Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import Bar from "tap-io/models/bar/Bar";
import Base from "tap-io/models/base/Base";
import Order from "tap-io/models/order/Order";
import FulfilmentMethod from "tap-io/models/fulfilment/FulfilmentMethod";

import OrderCard from "./OrderCard";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cards: {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "flex-start"
        },
        cardEnter: {
            opacity: 0,
            transform: "scale(0.8)"
        },
        cardEnterActive: {
            opacity: 1,
            transform: "scale(1)", //transform: "translateX(0)",
            transition: "opacity 200ms, transform 200ms"
        },
        cardExit: {
            opacity: 1
        },
        cardExitActive: {
            opacity: 0,
            transform: "scale(0.4)",
            transition: "opacity 200ms, transform 200ms"
        },
        noOrders: {
            margin: theme.spacing(0.5)
        }
    })
);

type OrdersAsCardsProps = {
    cardWidth?: number,
    bar: Bar,
    base?: Base,
    orders: Order[],
    fulfilmentMethod: FulfilmentMethod
    timeOffset: number
    selectedOrderId: string | null
    onSelect: (order: Order) => void
};

function OrdersAsCards({
    cardWidth,
    bar,
    base,
    orders,
    timeOffset,
    selectedOrderId,
    onSelect
}: PropsWithChildren<OrdersAsCardsProps>) {
    const { t, i18n } = useTranslation("common");
    const classes = useStyles();

    return (
        <div>
            <TransitionGroup className={classes.cards} component="div">
                {orders?.length &&
                    orders.map(order =>
                        <CSSTransition
                            key={order.id}
                            timeout={200}
                            classNames={{
                                //appear: 'my-appear',
                                //appearActive: 'my-active-appear',
                                //appearDone: 'my-done-appear',
                                enter: classes.cardEnter,
                                enterActive: classes.cardEnterActive,
                                //enterDone: 'my-done-enter',
                                exit: classes.cardExit,
                                exitActive: classes.cardExitActive,
                                //exitDone: 'my-done-exit',
                            }}
                            mountOnEnter={true}
                            unmountOnExit={true}
                        >
                            <OrderCard
                                width={cardWidth}
                                timeOffset={timeOffset}
                                bar={bar}
                                base={base}
                                order={order}
                                onClick={onSelect}
                            />
                        </CSSTransition>
                    )
                }
            </TransitionGroup>
            {!orders?.length &&
                <Typography className={classes.noOrders}>{t("order.no-orders-found")}</Typography>
            }
        </div>
    );
}

export default OrdersAsCards;