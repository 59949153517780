import HomeIcon from "@mui/icons-material/Home";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  IconButton,
  keyframes,
  ListItem,
  ListItemButton,
  Typography,
  useTheme
} from "@mui/material";
import { Dispatch, Fragment, SetStateAction, useState } from "react";

type KeyMatch<T, V> = { [K in keyof T]-?: T[K] extends V ? K : never }[keyof T];
const slideInLeft = keyframes`
from {
  transform: translateX(-100%);
}
to {
  transform: translateX(0);
}
`;
const slideInBottom = keyframes`
from {
  transform: translateY(100%);
}
to {
  transform: translateY(0);
}
`;

type BarStatsProps<
  T extends { [K in X]: string | number } & { [K in Y]: number } & {
    [K in Z]?: T[];
  },
  X extends KeyMatch<T, number | string>,
  Y extends KeyMatch<T, number>,
  Z extends KeyMatch<T, T[] | undefined>
> = {
  data: T[];

  x: X;
  y: Y;
  z?: Z;
  formatX?: (x: T[X], unit: T) => string;
  formatY?: (y: T[Y], unit: T) => string;

  limit?: number;
  sort?: [key: KeyMatch<T, string | number>, direction: "ASC" | "DESC"];

  vertical?: boolean;
  t: any;
};
export function BarStats<
  T extends { [K in X]: string | number } & { [K in Y]: number } & {
    [K in Z]?: T[];
  },
  X extends KeyMatch<T, number | string>,
  Y extends KeyMatch<T, number>,
  Z extends KeyMatch<T, T[] | undefined>
>(props: BarStatsProps<T, X, Y, Z>) {
  const theme = useTheme();
  const {
    x,
    y,
    z,
    formatY = (y) => y.toString(),
    formatX = (x) => x.toString(),
    limit = 5,
    sort = [y, "DESC"],
    vertical,
    t
  } = props;
  const [sortBy, sortDirection] = sort;

  const [isLimited, setIsLimited] = useState(true);
  const [path, setPath] = useState<{ key: number; label: string }[]>([]);

  //Data
  const data = z
    ? path.reduce((data, { key }) => data[key][z], props.data)
    : props.data;
  const sortedData = data.sort((a, b) => {
    switch (sortDirection) {
      case "ASC":
        return a[sortBy] > b[sortBy] ? 1 : -1;
      case "DESC":
        return a[sortBy] < b[sortBy] ? 1 : -1;

      default:
        throw new Error("Unknown sort direction");
    }
  });
  const limitedData = isLimited
    ? sortedData.slice(undefined, limit)
    : sortedData;

  const ceiling = limitedData.reduce(
    (max, unit) =>
      unit[y] > 0
        ? unit[y] > max
          ? unit[y]
          : max
        : unit[y] * -1 > max
        ? unit[y] * -1
        : max,
    0
  );

  if (data.length === 0) {
    return <Typography>{t("stats.message.no-data")}</Typography>;
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing()
      }}
    >
      {z && (
        <>
          <StatsCrumbs path={path} setPath={setPath} />
          <Divider
            sx={{ height: "1px", alignSelf: "stretch" }}
            orientation="horizontal"
          />
        </>
      )}

      <Box
        component="ol"
        sx={{
          marginBlock: 0,
          paddingInline: 0,
          marginInline: 0,
          width: "100%",
          minHeight: vertical ? 200 : "auto",
          display: "flex",
          flexDirection: vertical ? "row" : "column",
          alignItems: vertical ? "stretch" : "stretch",
          gap: theme.spacing(),
          overflow: "auto"
        }}
      >
        {limitedData.map((row, idx) => {
          const label = formatX(row[x], row);
          const value = formatY(row[y], row);

          const hasNestedValues = !!(z && row[z].length);
          const diveIntoNestedData = () =>
            setPath((path) => [...path, { key: idx, label }]);

          return (
            <Fragment key={`${label}${idx}`}>
              {idx !== 0 && (
                <Divider orientation={vertical ? "vertical" : "horizontal"} />
              )}
              <BarStat
                vertical={vertical}
                label={label}
                value={value}
                percentage={(row[y] < 0 ? row[y] * -1 : row[y]) / ceiling}
                negative={row[y] < 0}
                onClick={hasNestedValues ? diveIntoNestedData : undefined}
              />
            </Fragment>
          );
        })}
      </Box>

      {data.length - 1 > limit && (
        <Button onClick={() => setIsLimited((isLimited) => !isLimited)}>
          {t(isLimited ? "label.show-more" : "label.show-less")}
        </Button>
      )}
    </Box>
  );
}

type StatsCrumbsProps = {
  path: { key: number; label: string }[];
  setPath: Dispatch<SetStateAction<{ key: number; label: string }[]>>;
};
function StatsCrumbs(props: StatsCrumbsProps) {
  const { path, setPath } = props;

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} maxItems={4}>
      <IconButton
        color="primary"
        onClick={() => setPath([])}
        disabled={path.length === 0}
      >
        <HomeIcon />
      </IconButton>
      {path.map((item, idx) => {
        const isCurrent = idx === path.length - 1;
        const followCrumb = () => setPath((path) => path.slice(0, idx + 1));

        return (
          <Button key={item.key} disabled={isCurrent} onClick={followCrumb}>
            {item.label}
          </Button>
        );
      })}
    </Breadcrumbs>
  );
}

type BarStatProps = {
  label: string;
  value: string;
  percentage: number;
  vertical?: boolean;
  negative?: boolean;
  onClick?: () => void;
};
function BarStat(props: BarStatProps) {
  const theme = useTheme();
  const { label, value, percentage, vertical, negative, onClick } = props;

  if (onClick) {
    return (
      <ListItemButton
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: vertical ? "nowrap" : "wrap",
          flexDirection: vertical ? "column-reverse" : "row",
          alignItems: "center",
          paddingLeft: vertical ? 0 : theme.spacing(),
          paddingRight: vertical ? 0 : theme.spacing()
        }}
        onClick={onClick}
      >
        <Typography
          align={vertical ? "center" : "left"}
          sx={{ display: "flex", flexDirection: "row" }}
        >
          {label}
          <NavigateNextIcon />
        </Typography>
        <Typography
          sx={{ flex: vertical ? 0 : 1, order: vertical ? 1 : 0 }}
          align={vertical ? "center" : "right"}
        >
          <b>{value}</b>
        </Typography>
        <Bar vertical={vertical} percentage={percentage} negative={negative} />
      </ListItemButton>
    );
  }

  return (
    <ListItem
      sx={{
        display: "flex",
        flexWrap: vertical ? "nowrap" : "wrap",
        flexDirection: vertical ? "column-reverse" : "row",
        alignItems: "center",
        paddingLeft: vertical ? 0 : theme.spacing(),
        paddingRight: vertical ? 0 : theme.spacing()
      }}
    >
      <Typography align="left">{label}</Typography>
      <Typography
        sx={{ flex: vertical ? 0 : 1, order: vertical ? 1 : 0 }}
        align={vertical ? "center" : "right"}
      >
        <b>{value}</b>
      </Typography>
      <Bar vertical={vertical} percentage={percentage} negative={negative} />
    </ListItem>
  );
}

type BarProps = {
  percentage: number;
  vertical?: boolean;
  negative?: boolean;
};
function Bar(props: BarProps) {
  const theme = useTheme();
  const { percentage, vertical, negative } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: vertical ? "column-reverse" : "row",
        flex: 1,
        flexBasis: vertical ? "auto" : "100%"
      }}
    >
      <Box
        sx={{
          flexShrink: 0,
          width: vertical ? "auto" : `${percentage * 90}%`,
          height: vertical ? `${percentage * 90}%` : "auto",
          overflow: "hidden"
        }}
      >
        <Box
          sx={{
            padding: 0.5,
            width: vertical ? "auto" : "100%",
            height: vertical ? "100%" : "auto",
            backgroundColor: negative
              ? theme.palette.error.main
              : theme.palette.primary.main,
            borderRadius: 0.5,
            animation: `${vertical ? slideInBottom : slideInLeft} 1s ease 1`
          }}
        />
      </Box>
    </Box>
  );
}
