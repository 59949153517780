import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { IconButton } from "@mui/material";
import FlipIcon from "@mui/icons-material/Flip";
import VisiblityIcon from "@mui/icons-material/Visibility";
import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";
import { orderHelper } from "tap-io/helpers";

import { scanner } from "../../native";
import { orderService } from "tap-io/client/services";
import OrderDrawer from "./OrderDrawer";

const styles = (theme) => ({
  scannerStartedBullet: {
    position: "absolute",
    top: 10,
    right: 10,
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: theme.palette.secondary.main
  },
  scannerStartedButton: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(1.5),
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: `${theme.palette.secondary.contrastText} !important`
  }
});

class ScanOrdersButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isScannerStarted: false,
      order: null
    };
  }

  componentWillUnmount() {
    this.stopScanner();

    if (this.unsubscribeOrder) {
      this.unsubscribeOrder();
      this.unsubscribeOrder = undefined;
    }
  }

  startScanner = async () => {
    const { t } = this.props;

    try {
      if (scanner.isScannerAvailable() && !this.unsubscribeScanner) {
        this.clearScannerData();
        this.unsubscribeScanner = await scanner.onQRCodeScanned(
          async (data) => {
            this.onQRCodeScanned(data);

            await scanner.hideQRCodeScanner();
          }
        );

        this.setState({ isScannerStarted: true });
      }
    } catch (error) {
      toast.error(
        `${t("label.something-went-wrong")} (${t(
          error ? error.message : "error.unknown-error"
        )})`
      );
    }
  };

  stopScanner = async () => {
    const { t } = this.props;

    try {
      if (this.unsubscribeScanner) {
        await this.unsubscribeScanner();
        this.clearScannerData();

        this.unsubscribeScanner = undefined;

        this.setState({ isScannerStarted: false });
      }
    } catch (error) {
      console.warn(error);

      toast.error(
        `${t("label.something-went-wrong")} (${t(
          error ? error.message : "error.unknown-error"
        )})`
      );
    }
  };

  handleToggleScannerClick = async (event) => {
    const { isScannerStarted } = this.state;

    if (this.isBusy) {
      return;
    }

    this.isBusy = true;

    if (scanner.isScannerAvailable()) {
      if (isScannerStarted) {
        await this.stopScanner();
      } else {
        await this.startScanner();
      }
    }

    this.isBusy = false;
  };

  handleShowScanner = async () => {
    await scanner.showQRCodeScanner();
  };

  onQRCodeScanned = (data) => {
    const { t, bar, base } = this.props;
    const { order } = this.state;

    if (order) {
      return;
    }

    if (this.scannerData === data) {
      return;
    }
    this.scannerData = data;

    if (this.unsubscribeOrder) {
      this.unsubscribeOrder();
      this.unsubscribeOrder = undefined;
    }

    try {
      if (data) {
        const { barLocator, orderId } = orderHelper.parseOrderUrl(
          config.hosting.orderDomain,
          data
        );

        if (orderId) {
          const toastId = toast.info(t("order.fetching-order"));

          this.unsubscribeOrder = orderService.onById(bar, orderId, (order) => {
            toast.dismiss(toastId);

            if (order.containsItemsForBase(base)) {
              this.setState({ order });
            } else {
              toast.error("order.order-does-not-contain-items-for-this-base");
              setTimeout(this.clearScannerData, 3000);
            }
          });
        } else {
          toast.error(t("order.no-order-found"));
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };

  handleClearOrder = () => {
    if (this.unsubscribeOrder) {
      this.unsubscribeOrder();
      this.unsubscribeOrder = undefined;
    }

    this.setState({ order: null }, this.clearScannerData);
  };

  clearScannerData = () => {
    this.scannerData = "";
  };

  render() {
    const { theme, classes, t, timeOffset, bar, base, assets, deviceSettings } =
      this.props;
    const { isScannerStarted, order } = this.state;

    return (
      <>
        <OrderDrawer
          timeOffset={timeOffset}
          bar={bar}
          base={base}
          order={order}
          assets={assets}
          deviceSettings={deviceSettings}
          onClose={this.handleClearOrder}
        />
        <IconButton
          color="inherit"
          onClick={this.handleToggleScannerClick}
          size="large"
        >
          <FlipIcon />
          {isScannerStarted && (
            <span
              className={classNames(
                "pulsatingBackground",
                classes.scannerStartedBullet
              )}
              style={{
                "--background-color-from": "inherit",
                "--background-color-to": theme.palette.secondary.main,
                "--color-from": "inherit",
                "--color-to": theme.palette.secondary.contrastText
              }}
            />
          )}
        </IconButton>
        {isScannerStarted && (
          <IconButton
            className={classNames(classes.scannerStartedButton)}
            onClick={this.handleShowScanner}
            size="large"
          >
            <VisiblityIcon />
          </IconButton>
        )}
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(ScanOrdersButton)
);
