import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { CircularProgress, List, ListItem, ListItemText } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { utilsHelper } from "tap-io/helpers";
import { voucherService } from "tap-io/client/services";

import VoucherItem from "./VoucherItem";

const styles = (theme) => ({
  progress: {
    display: "flex",
    justifyContent: "center",
    padding: "20px 0"
  },
  noVouchers: {
    textAlign: "center"
  },
  minQueryLengthText: {
    display: "block",
    marginTop: 10
  }
});

class SearchedVouchers extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      voucherSearchResults: []
    };
  }

  componentDidMount() {
    this.refreshVouchers();
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchQuery } = this.props;
    const prevSearchQuery = prevProps.searchQuery;

    if (searchQuery !== prevSearchQuery) {
      this.refreshVouchers();
    }
  }

  refreshVouchers = () => {
    const { bar, searchQuery } = this.props;

    if (this.voucherSearchPromise) {
      this.voucherSearchPromise.cancel();
      this.voucherSearchPromise = undefined;
    }

    if (bar && searchQuery) {
      this.setState({ isLoading: true });

      this.voucherSearchPromise = utilsHelper.makePromiseCancelable(
        voucherService.searchVouchers(bar.id, searchQuery)
      );

      this.voucherSearchPromise.promise
        .then((voucherSearchResults) => {
          this.setState({ isLoading: false, voucherSearchResults });
        })
        .catch((error) => {
          console.warn(error);

          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ voucherSearchResults: null });
    }
  };

  render() {
    const {
      classes,
      t,
      selectedVoucherIds,
      onVoucherClick,
      onVoucherSelectChange
    } = this.props;
    const { isLoading, voucherSearchResults } = this.state;

    return (
      <div>
        {isLoading ? (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        ) : (
          <List>
            {voucherSearchResults && voucherSearchResults.length > 0 ? (
              voucherSearchResults.map((voucher) => (
                <VoucherItem
                  key={voucher.id}
                  voucher={voucher}
                  isSelected={selectedVoucherIds.indexOf(voucher.id) >= 0}
                  onClick={onVoucherClick}
                  onChange={onVoucherSelectChange}
                />
              ))
            ) : (
              <ListItem>
                <ListItemText
                  className={classes.noVouchers}
                  primary={t("voucher.no-vouchers-found")}
                  secondary={
                    <span className={classes.minQueryLengthText}>
                      {t("voucher.enter-min-x-characters-of-voucher-code-1")}{" "}
                      <strong>
                        {t("voucher.enter-min-x-characters-of-voucher-code-2", {
                          minQueryLength:
                            voucherService.SEARCH_BY_ID_MIN_QUERY_LENGTH
                        })}
                      </strong>{" "}
                      {t("voucher.enter-min-x-characters-of-voucher-code-3")}
                      <br />
                      <strong>
                        {t(
                          "voucher.warning-the-voucher-code-is-case-sensitive"
                        )}
                      </strong>
                    </span>
                  }
                />
              </ListItem>
            )}
          </List>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(SearchedVouchers)
);
