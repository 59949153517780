import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { subscriptionService } from "tap-io/client/services";

import withAuthorization from "../auth/withAuthorization";
import { PLAN_PRO } from "../../constants/plans";
import PlanRequested from "./PlanRequestedDescription";

const styles = (theme) => ({
  dialog: {
    minWidth: 300
  }
});

class RequestPlanDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  requestPlan = async () => {
    const { t, auth, bar, nameOfPlanToRequest } = this.props;

    if (!auth || !auth.user || !auth.user.uid) {
      return toast.error(
        `${t("label.error")}: ${t("error.no-valid-user-found")}`
      );
    }

    const toastId = toast(t("subscription.requesting-plan"), {
      autoClose: false
    });
    this.setState({ isDisabled: true });

    try {
      await subscriptionService.requestPlan(
        auth.user.uid,
        bar,
        nameOfPlanToRequest,
        (data) => {
          console.log("progress", data);
        }
      );

      toast.update(toastId, {
        render: t("subscription.plan-requested"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.message})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const {
      classes,
      t,
      isOpen,
      onSuccess,
      onCancel,
      subscription,
      nameOfPlanToRequest
    } = this.props;
    const { isDisabled } = this.state;

    return (
      <div>
        <Dialog open={isOpen} onClose={onCancel} className={classes.dialog}>
          <DialogTitle>
            {t(
              nameOfPlanToRequest === PLAN_PRO
                ? "subscription.request-pro"
                : "subscription.request-full-service"
            )}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("subscription.request-plan-description")}
            </DialogContentText>
            {subscription && subscription.isRequested() ? (
              <PlanRequested subscription={subscription} />
            ) : (
              <Button
                fullWidth
                disabled={isDisabled}
                onClick={this.requestPlan}
              >
                {t(
                  nameOfPlanToRequest === PLAN_PRO
                    ? "subscription.i-want-to-request-pro"
                    : "subscription.i-want-to-request-full-service"
                )}
              </Button>
            )}
          </DialogContent>
          <DialogActions>
            {subscription && subscription.isRequested() && (
              <Button disabled={isDisabled} onClick={onSuccess} color="primary">
                {t("label.continue")}
              </Button>
            )}
            <Button disabled={isDisabled} onClick={onCancel} color="secondary">
              {t("label.cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withAuthorization()(
  withStyles(styles)(withTranslation("common")(RequestPlanDialog))
);
