import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import withStyles from "@mui/styles/withStyles";

import AddBasePreparationOptionDialog from "./AddBasePreparationOptionDialog";

const styles = (theme) => ({
  disabledItem: {
    color: "#ddd"
  }
});

class EditBasePreparationOptions extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isAddBasePreparationOptionDialogOpen: false
    };
  }

  handleAddPreparationOption = () => {
    this.setState({ isAddBasePreparationOptionDialogOpen: true });
  };

  handleAddBasePreparationOptionDialogClose = (preparationOption) => {
    const { t, onChange, preparationOptions } = this.props;

    if (preparationOption) {
      try {
        const updatedPreparationOptions = preparationOptions.clone();
        updatedPreparationOptions.add(preparationOption);
        onChange(updatedPreparationOptions);
      } catch (error) {
        console.warn(error);

        toast.error(
          `${t("label.something-went-wrong")} (${t(
            error ? error.message : "label.unknown-error"
          )})`
        );
      }
    }

    this.setState({ isAddBasePreparationOptionDialogOpen: false });
  };

  handleRemovePreparationOption = (preparationOption) => (event) => {
    const { t, onChange, preparationOptions } = this.props;

    try {
      const updatedPreparationOptions = preparationOptions.clone();
      updatedPreparationOptions.remove(preparationOption);
      onChange(updatedPreparationOptions);
    } catch (error) {
      console.warn(error);

      toast.error(
        `${t("label.something-went-wrong")} (${t(
          error ? error.message : "label.unknown-error"
        )})`
      );
    }
  };

  render() {
    const { classes, t, isDisabled, preparationOptions } = this.props;
    const { isAddBasePreparationOptionDialogOpen } = this.state;

    return (
      <>
        <AddBasePreparationOptionDialog
          isDisabled={isDisabled}
          isOpen={isAddBasePreparationOptionDialogOpen}
          onClose={this.handleAddBasePreparationOptionDialogClose}
        />
        <List>
          {preparationOptions && preparationOptions.length > 0 ? (
            preparationOptions.map((preparationOption) => (
              <ListItem
                key={preparationOption.duration}
                className={isDisabled ? classes.disabledItem : undefined}
                disableGutters
              >
                <ListItemText
                  primary={`${preparationOption.duration} ${t(
                    preparationOption.duration === 1 ? "minute" : "minutes"
                  )}`}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    disabled={isDisabled}
                    onClick={this.handleRemovePreparationOption(
                      preparationOption
                    )}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          ) : (
            <ListItem disableGutters>
              <ListItemText primary={t("base.no-preparation-options-found")} />
            </ListItem>
          )}
          <ListItem disableGutters>
            <Button
              fullWidth
              variant="contained"
              disabled={isDisabled}
              onClick={this.handleAddPreparationOption}
            >
              {t("base.add-preparation-option")}
            </Button>
          </ListItem>
        </List>
      </>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(EditBasePreparationOptions)
);
