import { Stat } from "./Stat";

type Data = {
  order: string;
  orderCode: string;
  payment: string;
  type: string;
  item: string;
  name: string;
  amount: number;
  value: number;
  valueExclVat: number;
  vatRate: number;
  vat: number;
  valueInclVat: number;
  currency: string;
  base: string;
  baseName: string;
  baseVersion: string;
  orderLocation: string;
  orderLocationName: string;
  orderDevice: string;
  orderDeviceName: string;
  timestamp: string;
};

export class SaleReport extends Stat<Data> {
  toCSV(t: any) {
    return this.generateCSV(
      {
        order: true,
        orderCode: true,
        payment: true,
        type: true,
        item: true,
        name: true,
        amount: this.formatInteger,
        value: this.formatCurrency,
        valueExclVat: this.formatCurrency,
        vatRate: this.formatFloat,
        vat: this.formatCurrency,
        valueInclVat: this.formatCurrency,
        currency: true,
        base: true,
        baseName: true,
        baseVersion: true,
        orderLocation: true,
        orderLocationName: true,
        orderDevice: true,
        orderDeviceName: true,
        timestamp: true
      },
      t
    );
  }
}
