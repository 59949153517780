import { ReactElement } from "react";
import { StatChip } from "./StatChip";

export type LoadingStatChipProps = {
  title?: string;
  icon?: ReactElement;
};

export function LoadingStatChip(props: LoadingStatChipProps) {
  const { title, icon } = props;

  return <StatChip title={title} value="?" icon={icon} />;
}
