import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import withStyles from "@mui/styles/withStyles";

import DeliveryMethod from "tap-io/models/delivery/DeliveryMethod";
import EditReplyToEmailAddressDialog from "../settings/EditReplyToEmailAddressDialog";

const styles = (theme) => ({
  warning: {
    marginLeft: theme.spacing(),
    verticalAlign: "middle"
  }
});

class SelectDeliveryMethods extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isEditReplyToEmailAddressDialogOpen: false
    };
  }

  handleChange = async (event) => {
    const { onChange, skipReplyToEmailAddressCheck } = this.props;

    const name = event.target.name;
    const value = event.target.checked === true;

    if (
      !skipReplyToEmailAddressCheck &&
      name === DeliveryMethod.PICKUP &&
      value
    ) {
      this.handleEditReplyToEmailAddress();
    } else {
      await onChange(name, value);
    }
  };

  handleEditReplyToEmailAddress = () => {
    this.setState({ isEditReplyToEmailAddressDialogOpen: true });
  };

  handleEditReplyToEmailAddressDialogClose = async (replyToEmailAddress) => {
    const { onChange } = this.props;

    this.setState({ isEditReplyToEmailAddressDialogOpen: false });

    if (replyToEmailAddress) {
      await onChange(DeliveryMethod.PICKUP, true);
    }
  };

  render() {
    const {
      classes,
      t,
      isDisabled,
      bar,
      isServeActive,
      isPickupActive,
      hasServeWarning,
      hasPickupWarning
    } = this.props;
    const { isEditReplyToEmailAddressDialogOpen } = this.state;

    return (
      <div>
        <EditReplyToEmailAddressDialog
          isOpen={isEditReplyToEmailAddressDialogOpen}
          onClose={this.handleEditReplyToEmailAddressDialogClose}
          bar={bar}
        />
        <div>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={isDisabled}
                  checked={isServeActive}
                  onChange={this.handleChange}
                  name={DeliveryMethod.SERVE}
                  color="primary"
                />
              }
              label={
                <span>
                  {t("delivery.serve")}
                  {hasServeWarning && (
                    <ErrorIcon color="error" className={classes.warning} />
                  )}
                </span>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={isDisabled}
                  checked={isPickupActive}
                  onChange={this.handleChange}
                  name={DeliveryMethod.PICKUP}
                  color="primary"
                />
              }
              label={
                <span>
                  {t("delivery.pickup")}
                  {hasPickupWarning && (
                    <ErrorIcon color="error" className={classes.warning} />
                  )}
                </span>
              }
            />
          </FormControl>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(SelectDeliveryMethods)
);
