import React, { PureComponent } from "react";

import { Divider, Drawer } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import BarHeaderSpacing from "./BarHeaderSpacing";

const styles = (theme) => ({
  content: {
    height: "100%",
    overflowY: "scroll"
  }
});

class BarDrawer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes,
      width,
      variant,
      anchor,
      isOpen,
      onClose,
      header,
      content
    } = this.props;

    return (
      <Drawer
        variant={variant}
        anchor={anchor}
        style={{ width }}
        open={isOpen}
        onClose={onClose}
      >
        <BarHeaderSpacing>{header}</BarHeaderSpacing>
        <Divider />
        <div className={classes.content} style={{ width }}>
          {content}
        </div>
      </Drawer>
    );
  }
}

export default withStyles(styles)(BarDrawer);
