import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  CircularProgress,
  List,
  ListItem,
  TextField
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { balanceService } from "tap-io/client/services";

import withAuthorization from "../auth/withAuthorization";

const styles = (theme) => ({
  buttons: {
    justifyContent: "center"
  }
});

class RedeemCoupon extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isRedeeming: false,
      couponCode: ""
    };
  }

  handleCouponCodeChange = (event) => {
    const val = event.target.value;

    this.setState({ couponCode: val });
  };

  handleValidateCouponCode = async () => {
    const { t, bar, auth } = this.props;
    const { couponCode } = this.state;

    if (auth && auth.user && auth.user.uid) {
      const toastId = toast(t("credits.validating-coupon"), {
        autoClose: false
      });
      this.setState({ isRedeeming: true });

      try {
        const data = await balanceService.redeemCoupon(
          auth.user.uid,
          bar,
          couponCode
        );

        toast.update(toastId, {
          render: `${t("credits.coupon-successfully-validated")} (+${
            data && data.creditCount ? data.creditCount : "?"
          } ${t(
            data.creditCount === 1 ? "credits.credit" : "credits.credits"
          )})`,
          type: toast.TYPE.INFO,
          autoClose: 3000
        });

        this.setState({ isRedeeming: false, couponCode: "" });
      } catch (error) {
        console.warn(error);

        this.setState({ isRedeeming: false });
        toast.update(toastId, {
          render: `${t("label.something-went-wrong")} (${error.message})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
      }
    } else {
      toast.error(t("error.no-authorized-user-found"));
    }
  };

  render() {
    const { classes, t } = this.props;
    const { isRedeeming, couponCode } = this.state;

    return (
      <List>
        <ListItem disableGutters>
          <TextField
            variant="standard"
            margin="dense"
            label={t("credits.coupon-code-label")}
            helperText={t("credits.coupon-code-description")}
            fullWidth
            disabled={isRedeeming}
            value={couponCode}
            onChange={this.handleCouponCodeChange}
          />
        </ListItem>
        <ListItem className={classes.buttons}>
          <Button
            color="primary"
            className={classes.button}
            onClick={this.handleValidateCouponCode}
            disabled={couponCode.length === 0 || isRedeeming}
            startIcon={isRedeeming && <CircularProgress size={20} />}
          >
            {t("credits.validate")}
          </Button>
        </ListItem>
      </List>
    );
  }
}

export default withAuthorization()(
  withStyles(styles, { withTheme: true })(
    withTranslation("common")(RedeemCoupon)
  )
);
