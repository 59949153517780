import React, { PureComponent } from "react";

import SensitiveInformation from "./SensitiveInformation";

const withPasswordAuthentication = (authCondition) => (Component) => {
  class WithPasswordAuthentication extends PureComponent {
    constructor(props) {
      super(props);

      this.state = {};
    }

    componentDidUpdate(prevProps, prevState) {
      const { isAppLocked, unlockApp } = this.props;

      if (isAppLocked && !this.alreadyAskedToUnlockApp) {
        this.alreadyAskedToUnlockApp = true;
        unlockApp()
          .then(() => {})
          .catch((error) => {
            console.warn(error);
          });
      }
    }

    render() {
      const { isAppLocked, unlockApp } = this.props;

      return (
        <div>
          {isAppLocked ? (
            <SensitiveInformation unlockApp={unlockApp} />
          ) : (
            <Component {...this.props} />
          )}
        </div>
      );
    }
  }

  return WithPasswordAuthentication;
};

export default withPasswordAuthentication;
