import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import withBarAuthorization from "../components/auth/withBarAuthorization";
import EditMenus from "../components/menu/EditMenus";

const styles = (theme) => ({
  unlockAppButtonContainer: {
    margin: "20px 0"
  }
});

class MenusPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleUnlockApp = async () => {
    const { unlockApp } = this.props;

    try {
      await unlockApp();
    } catch (error) {
      console.warn(error);
    }
  };

  menuFilter = (menu) => {
    const { isAppLocked, mode, bar, base } = this.props;

    if (isAppLocked) {
      return bar && bar.isUsingBases() && mode && mode.isUsingBases
        ? base
          ? base.menuIds.indexOf(menu.id) >= 0
          : false
        : true;
    }

    return true;
  };

  render() {
    const { t, classes, isAppLocked, bar, base, allBases, allMenus } =
      this.props;

    return (
      <div>
        {isAppLocked && (
          <div className={classes.unlockAppButtonContainer}>
            <Button onClick={this.handleUnlockApp}>
              {t("menu.unlock-app-to-edit-all-menus")}
            </Button>
          </div>
        )}
        <EditMenus
          isEditable={!isAppLocked}
          bar={bar}
          base={base}
          menus={allMenus ? allMenus.filter(this.menuFilter) : null}
          allBases={allBases}
        />
      </div>
    );
  }
}

export default withBarAuthorization()(
  withStyles(styles, { withTheme: true })(withTranslation("common")(MenusPage))
);
