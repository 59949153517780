import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import { List } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import withStyles from "@mui/styles/withStyles";

import { LAYOUT_NODE_ICONS } from "../../constants/zones";
import LayoutNodeItem from "./LayoutNodeItem";

const styles = (theme) => ({
  level: {
    display: "flex"
  },
  childAngle: {
    width: 20,
    height: 20,
    margin: "4px 0 0 18px",
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 12
  },
  childBrace: {
    width: 15,
    margin: "12px 0",
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    borderRadius: 4
  },
  children: {
    width: "100%"
  },
  child: {}
});

class RecursiveLayoutNodeLevel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showChildrenForLayoutNodeIndexes: []
    };
  }

  handleToggleShowChildrenClick = (layoutNodeIndex) => () => {
    const showChildrenForLayoutNodeIndexes = [
      ...this.state.showChildrenForLayoutNodeIndexes
    ];

    const index = showChildrenForLayoutNodeIndexes.indexOf(layoutNodeIndex);
    if (index >= 0) {
      showChildrenForLayoutNodeIndexes.splice(index, 1);
    } else {
      showChildrenForLayoutNodeIndexes.push(layoutNodeIndex);
    }

    this.setState({
      showChildrenForLayoutNodeIndexes
    });
  };

  showLayoutNodeChildren = (layoutNodeIndex) => {
    const { showChildrenForLayoutNodeIndexes } = this.state;

    return showChildrenForLayoutNodeIndexes.indexOf(layoutNodeIndex) >= 0;
  };

  handleMoreClick = (layoutNode) => (event) => {
    const { onMoreClick } = this.props;

    onMoreClick(event, layoutNode);
  };

  getLayoutNodeIcon = (fieldType) => {
    const LayoutNodeIcon = LAYOUT_NODE_ICONS[fieldType]
      ? LAYOUT_NODE_ICONS[fieldType]
      : HelpOutlineIcon;
    return <LayoutNodeIcon />;
  };

  render() {
    const {
      classes,
      t,
      isDisabled,
      maxHeight,
      layoutNode,
      selectedNode,
      onClick,
      onMoreClick
    } = this.props;

    return layoutNode.children.length > 0 ? (
      <div className={classes.level}>
        <div className={classes.childAngle} />
        <div className={classes.childBrace} />
        <List className={classes.children}>
          {layoutNode.children.map((child, index) => (
            <div key={index}>
              <LayoutNodeItem
                isDisabled={isDisabled}
                isOpen={this.showLayoutNodeChildren(index)}
                isSelected={selectedNode === child}
                isError={maxHeight && child.depth > maxHeight}
                layoutNode={child}
                onClick={onClick}
                onToggleIsOpenClick={this.handleToggleShowChildrenClick(index)}
                onMoreClick={onMoreClick}
              />
              {this.showLayoutNodeChildren(index) && (
                <WrappedRecursiveLayoutNodeLevel
                  isDisabled={isDisabled}
                  maxHeight={maxHeight}
                  layoutNode={child}
                  selectedNode={selectedNode}
                  onClick={onClick}
                  onMoreClick={onMoreClick}
                />
              )}
            </div>
          ))}
        </List>
      </div>
    ) : null;
  }
}

const WrappedRecursiveLayoutNodeLevel = withStyles(styles, { withTheme: true })(
  withTranslation("common")(RecursiveLayoutNodeLevel)
);

export default WrappedRecursiveLayoutNodeLevel;
