import { useMemo } from "react";

import { Box, Divider, Stack, useTheme } from "@mui/material";
import { createAsync } from "../../components/common/useAsync";
import {
  fetchDepositReport,
  fetchFeeReport,
  fetchFinancialDeviceReport,
  fetchFinancialLocationReport,
  fetchFinancialTimeslotReport,
  fetchOperationalTimeslotReport,
  fetchPaymentReport,
  fetchProductReport,
  fetchSaleReport,
  fetchTransactionReport
} from "../services/StatService";
import { FinancialReportDownloads } from "./cards/FinancialReportDownloads";
import { RevenuePerDevice } from "./cards/RevenuePerDevice";
import { RevenuePerFee } from "./cards/RevenuePerFee";
import { RevenuePerHour } from "./cards/RevenuePerHour";
import { RevenuePerLocation } from "./cards/RevenuePerLocation";
import { ValuePerPaymentMethod } from "./cards/RevenuePerPayment";
import { RevenuePerProduct } from "./cards/RevenuePerProduct";
import { ValuePerDeposit } from "./cards/ValuePerDeposit";
import { StatGroup } from "./common/StatGroup";

function FinancialStats(props: {
  organisationId: string;
  period: [begin: Date, end: Date];
  user: any;
  t: any;
}) {
  const theme = useTheme();
  const { organisationId, period, user, t } = props;

  const filter = useMemo(() => {
    return {
      organisationId,
      eventId: organisationId,
      from: period[0],
      to: period[1]
    };
  }, [organisationId, period]);

  const mark = `${period[0].toLocaleString("nl-BE")}-${period[1].toLocaleString(
    "nl-BE"
  )}`;

  const saleReports = createAsync(fetchSaleReport, true)(user, filter);

  const transactionReports = createAsync(fetchTransactionReport, true)(
    user,
    filter
  );
  const locationReports = createAsync(fetchFinancialLocationReport, true)(
    user,
    filter
  );
  const productReports = createAsync(fetchProductReport, true)(user, filter);
  const depositReports = createAsync(fetchDepositReport, true)(user, filter);
  const feeReports = createAsync(fetchFeeReport, true)(user, filter);
  const deviceReports = createAsync(fetchFinancialDeviceReport, true)(
    user,
    filter
  );
  const paymentReports = createAsync(fetchPaymentReport, true)(user, filter);
  const hourReports = createAsync(fetchFinancialTimeslotReport, true)(
    user,
    filter
  );
  const amountReports = createAsync(fetchOperationalTimeslotReport, true)(
    user,
    filter
  );
  const operationalTimeslotReports = createAsync(
    fetchOperationalTimeslotReport,
    true
  )(user, filter);

  return (
    <>
      <Stack spacing={10}>
        <StatGroup>
          <ValuePerPaymentMethod async={paymentReports} mark={mark} t={t} />
        </StatGroup>

        <StatGroup>
          <RevenuePerProduct
            async={productReports}
            amount={operationalTimeslotReports}
            mark={mark}
            t={t}
          />
          <RevenuePerFee
            async={feeReports}
            amount={operationalTimeslotReports}
            mark={mark}
            t={t}
          />
          <ValuePerDeposit async={depositReports} mark={mark} t={t} />
        </StatGroup>

        <StatGroup>
          <RevenuePerDevice async={deviceReports} mark={mark} t={t} />
          <RevenuePerLocation async={locationReports} mark={mark} t={t} />
          <RevenuePerHour
            async={hourReports}
            date={period[0]}
            mark={mark}
            t={t}
          />
        </StatGroup>

        <StatGroup>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing()
            }}
          >
            <Divider />
            <FinancialReportDownloads
              transaction={transactionReports}
              sale={saleReports}
              product={productReports}
              deposit={depositReports}
              fee={feeReports}
              payment={paymentReports}
              device={deviceReports}
              location={locationReports}
              hour={hourReports}
              date={period[0]}
              mark={mark}
              t={t}
            />
          </Box>
        </StatGroup>
      </Stack>
    </>
  );
}

export default FinancialStats;
