import React, { PureComponent } from "react";

import { Link, Redirect } from "react-router-dom";

import { withTranslation } from "react-i18next";

import { Button, Divider, List, ListItem, TextField } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { toast } from "react-toastify";

import { authService } from "tap-io/client/services";
import { utilsHelper } from "tap-io/helpers";

import AuthContext from "tap-io/client/components/auth/authContext";
import BackgroundWithCard from "../components/auth/BackgroundWithCard";

import * as routes from "../constants/routes";
import withDevice from "../components/auth/withDevice";

const styles = (theme) => ({
  divider: {
    marginTop: 20
  },
  action: {
    margin: 0
  },
  link: {
    textDecoration: "none"
  },
  info: {
    marginTop: 5,
    fontSize: 14,
    color: "#888"
  }
});

class SignInPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      validEmail: false,
      validPassword: false,
      isSigningIn: false
    };
  }

  handleBlur = (event) => {
    utilsHelper.resetScrollPosition();
  };

  handleChange = (name) => (event) => {
    let validEmail = this.state.validEmail;
    let validPassword = this.state.validPassword;

    let val = event.target.value;

    if (name === "email") {
      validEmail = val && val !== "";
    } else if (name === "password") {
      validPassword = val && val !== "";
    }

    this.setState(
      {
        [name]: val,
        validEmail: validEmail,
        validPassword: validPassword
      },
      () => {}
    );
  };

  handleSignIn = async (event) => {
    event.preventDefault();

    const { t, unlockApp } = this.props;
    const { email, password } = this.state;

    this.setState({ isSigningIn: true });
    const toastId = toast(t("auth.signing-in"), { autoClose: false });

    try {
      const auth = await authService.signInWithEmailAndPassword(
        email,
        password
      );

      this.setState({ isSigningIn: false });

      toast.update(toastId, {
        render: t("auth.signed-in-successfully"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      unlockApp(auth.user, password);
    } catch (error) {
      console.warn(error);

      this.setState({ isSigningIn: false });

      if (error && error.message) {
        toast.update(toastId, {
          render: `${t("auth.error-while-signing-in")}: (${error.message})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
      } else {
        toast.update(toastId, {
          render: `${t("auth.error-while-signing-in")} (${t(
            "error.unknown-error"
          )})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
      }
    }
  };

  handleForgotPassword = async () => {
    const { t } = this.props;
    const { validEmail, email } = this.state;

    if (validEmail) {
      const toastId = toast(t("auth.sending-email-with-reset-link"), {
        autoClose: false
      });

      try {
        await authService.resetPasswordForEmail(email);

        toast.update(toastId, {
          render: `${t("auth.email-with-reset-link-sent")} ${t(
            "auth.check-your-mailbox-including-spam-folder"
          )}`,
          type: toast.TYPE.INFO,
          autoClose: 5000
        });
      } catch (error) {
        toast.update(toastId, {
          render: `${t("auth.error-while-resetting-your-password")} (${
            error.message
          })`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
      }
    } else {
      toast.error(t("error.enter-valid-email-address-to-reset-password"));
    }
  };

  render() {
    const { classes, t } = this.props;
    const { email, password, validEmail, validPassword, isSigningIn } =
      this.state;

    return (
      <AuthContext.Consumer>
        {(auth) =>
          auth && auth.user ? (
            <Redirect to={routes.ORDERS} />
          ) : (
            <BackgroundWithCard
              content={
                <List className={classes.client}>
                  <form onSubmit={this.handleSignIn}>
                    <ListItem>
                      <TextField
                        data-cy="email"
                        required
                        fullWidth
                        label={t("auth.email-address")}
                        value={email}
                        onChange={this.handleChange("email")}
                        onBlur={this.handleBlur}
                        variant="standard"
                        margin="normal"
                      />
                    </ListItem>
                    <ListItem>
                      <TextField
                        data-cy="password"
                        required
                        fullWidth
                        label={t("auth.password")}
                        type="password"
                        value={password}
                        onChange={this.handleChange("password")}
                        onBlur={this.handleBlur}
                        variant="standard"
                        margin="normal"
                      />
                    </ListItem>
                    <Button
                      data-cy="sign-in"
                      type="submit"
                      size="medium"
                      disabled={!validEmail || !validPassword || isSigningIn}
                    >
                      {t("auth.sign-in")}
                    </Button>
                  </form>
                  <Divider className={classes.divider} />
                </List>
              }
              actions={
                <div>
                  <div className={classes.info}>{t("auth.no-account-yet")}</div>
                  <a
                    href="https://beyondordering.com/nl/onboarding"
                    target="_blank"
                    rel="noreferrer"
                    className={`${classes.action} ${classes.link}`}
                  >
                    <Button data-cy="register" size="small">
                      {t("auth.register")}
                    </Button>
                  </a>
                  <div className={classes.info}>
                    {t("auth.forgot-password")}
                  </div>
                  <Button
                    data-cy="forgot-password"
                    size="small"
                    className={classes.action}
                    onClick={this.handleForgotPassword}
                  >
                    {t("auth.reset-password")}
                  </Button>
                </div>
              }
            />
          )
        }
      </AuthContext.Consumer>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(withDevice()(SignInPage))
);
