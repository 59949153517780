import React, { PureComponent } from "react";

import { Card, CardActions, CardContent } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import appTheme from "tap-io/client/theme";
import Copyright from "tap-io/client/components/Copyright";
import config from "tap-io/client/env";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "100vw",
    minHeight: "100vh",
    backgroundImage: `url(${appTheme.assets.background})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover"
  },
  card: {
    position: "relative",
    overflow: "visible",
    zIndex: 10,
    width: 300,
    [theme.breakpoints.down(undefined)]: {
      width: "100%",
      margin: "10px 0",
      borderRadius: 0,
      boxShadow: "none",
      background: "rgba(255 ,255, 255, 0.9)"
    }
  },
  logo: {
    width: "100%",
    height: 80,
    margin: theme.spacing(3, 0),
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "auto 100%",
    backgroundImage: `url(${appTheme.assets.logo})`
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  copyright: {
    marginTop: theme.spacing(1)
  }
});

class BackgroundWithCard extends PureComponent {
  render() {
    const { classes, content, actions } = this.props;

    return (
      <div className={classes.root}>
        <a href={config.links.promo} target="blank" className={classes.logo} />
        <Card className={classes.card}>
          <CardContent className={classes.content}>{content}</CardContent>
          <CardActions className={classes.actions}>{actions}</CardActions>
        </Card>
        <div className={classes.copyright}>
          <Copyright hideLogo={true} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(BackgroundWithCard);
