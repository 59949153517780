import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { menuService } from "tap-io/client/services";

const styles = (theme) => ({});

class EditMenuNameDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (menu) => {
    return {
      isDisabled: false,
      name: menu && menu.name ? menu.name : ""
    };
  };

  componentDidMount() {
    const { menu } = this.props;

    if (menu) {
      this.setState(this.initialState(menu));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen, menu } = this.props;

    if (isOpen && !prevProps.isOpen) {
      this.setState(this.initialState(menu));
    }
  }

  handleNameChange = (event) => {
    const val = event.target.value;
    this.setState({ name: val });
  };

  handleUpdateMenuName = async () => {
    const { t, menu, onClose } = this.props;
    const { name } = this.state;

    if (name === undefined || name === "") {
      return toast.error(t("error.name-cannot-be-empty"));
    }

    const toastId = toast(t("label.updating-name"), { autoClose: false });
    this.setState({ isDisabled: true });

    try {
      menu.name = name.trim();

      await menuService.updateMenu(menu);

      toast.update(toastId, {
        render: t("label.name-updated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false }, onClose);
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(error.message)})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { t, isOpen, onClose, menu } = this.props;
    const { isDisabled, name } = this.state;

    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>{t("menu.update-the-name-of-a-menu")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("menu.choose-a-new-new-for-menu-x-below")}
            <strong> {menu ? menu.name : `(${t("label.unknown")})`}</strong>
          </DialogContentText>
          <TextField
            variant="standard"
            margin="dense"
            label={t("label.name")}
            helperText={t("menu.name-of-this-menu")}
            fullWidth
            value={name}
            disabled={isDisabled}
            onChange={this.handleNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={isDisabled} color="secondary">
            {t("label.cancel")}
          </Button>
          <Button
            onClick={this.handleUpdateMenuName}
            disabled={isDisabled}
            color="primary"
          >
            {t("label.edit")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(EditMenuNameDialog)
);
