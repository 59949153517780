import { catalogueHelper } from "tap-io/helpers";
import VatRate from "tap-io/models/menu/VatRate";

const defaultData = {
  "17a70bad-e09b-4257-bdff-95565948feb3": {
    name: "Belle-Vue Gueuze",
    vatRate: VatRate.STANDARD
  },
  "f9aa6ec1-bf2a-4cb8-8626-bc67feb36994": {
    name: "Belle-Vue Kriek",
    vatRate: VatRate.STANDARD
  },
  "2c3996b9-aecc-41bf-a265-908be06297e0": {
    name: "Bucket Corona",
    vatRate: VatRate.STANDARD
  },
  "6dd54699-475a-4b1d-bfd0-61d5f696580a": {
    name: "Bucket Stella Artois",
    vatRate: VatRate.STANDARD
  },
  "b9574b7a-5fc7-402b-99d8-d84a22294324": {
    name: "Corona",
    vatRate: VatRate.STANDARD
  },
  "ac482b2d-0358-442a-bd8f-6d510b65108c": {
    name: "Cubanisto",
    vatRate: VatRate.STANDARD
  },
  "c68af84f-520b-41d0-a645-082e1809f10d": {
    name: "Ginette Natural Blond",
    vatRate: VatRate.STANDARD
  },
  "7c13382e-4320-49b4-9504-7bdf27c946d6": {
    name: "Ginette Natural Fruit",
    vatRate: VatRate.STANDARD
  },
  "b4b979e4-6fb8-46cd-89d2-013d703c91be": {
    name: "Ginette Natural Tripel",
    vatRate: VatRate.STANDARD
  },
  "5edac5b4-23ab-43dc-b7ca-444a028a8cf0": {
    name: "Ginette Natural Wit",
    vatRate: VatRate.STANDARD
  },
  "c07eeebd-9643-4844-8377-a8a0c6abd8c2": {
    name: "Goose Island IPA",
    vatRate: VatRate.STANDARD
  },
  "73123b17-9b9a-4293-ace2-d1bc35899c2a": {
    name: "Hoegaarden 0.0",
    vatRate: VatRate.REDUCED
  },
  "0382f401-e880-4387-bf52-eb60fb480cfc": {
    name: "Hoegaarden Agrum",
    vatRate: VatRate.STANDARD
  },
  "597df420-d08d-4081-b965-d21426c3050b": {
    name: "Hoegaarden Citrus",
    vatRate: VatRate.STANDARD
  },
  "d29292a2-3738-4c19-b377-82d86e0ba1a8": {
    name: "Hoegaarden Rosé",
    vatRate: VatRate.STANDARD
  },
  "7932cf03-5619-4e2b-af8d-dde8b413109d": {
    name: "Hoegaarden Rosé 0.0",
    vatRate: VatRate.REDUCED
  },
  "61882540-fd11-49b5-b4b9-a34fea542797": {
    name: "Hoegaarden Spéciale",
    vatRate: VatRate.STANDARD
  },
  "1f736e29-59d5-4765-b67b-33c6bb166e64": {
    name: "Hoegaarden Wit",
    vatRate: VatRate.STANDARD
  },
  "ccf18d5c-ca2a-4cb3-9a99-39c2882abf5c": {
    name: "Jupiler",
    vatRate: VatRate.STANDARD
  },
  "2fe1f522-f9a5-4801-8ba4-a4f34daa4379": {
    name: "Jupiler 0,0%",
    vatRate: VatRate.REDUCED
  },
  "d3e9b184-a48b-4b99-a227-199080dfba9f": {
    name: "Kwak",
    vatRate: VatRate.STANDARD
  },
  "ac0115eb-3ad7-4e97-b638-933ae9aa2827": {
    name: "Leffe 0.0",
    vatRate: VatRate.REDUCED
  },
  "3a3efc3b-0f29-4275-a2ef-7708fbc2e6c3": {
    name: "Leffe Blond",
    vatRate: VatRate.STANDARD
  },
  "d9e3523d-7b74-45fa-87e4-863f87aa7ca4": {
    name: "Leffe Bruin",
    vatRate: VatRate.STANDARD
  },
  "e0a21f3c-0300-43dd-b378-d768f2bb34d8": {
    name: "Leffe Nectar",
    vatRate: VatRate.STANDARD
  },
  "982cbbf2-2c42-4b23-81d0-b7cfd4ab52bc": {
    name: "Leffe Radieuse",
    vatRate: VatRate.STANDARD
  },
  "23106b3a-948d-4aef-9545-1ef8376c03bd": {
    name: "Leffe Rituel 9°",
    vatRate: VatRate.STANDARD
  },
  "278871a1-24f8-45ff-bbe3-96a9d914b181": {
    name: "Leffe Royale Cascade IPA",
    vatRate: VatRate.STANDARD
  },
  "4003cf08-0d60-4102-8bbf-689f040e0420": {
    name: "Leffe Royale Ella",
    vatRate: VatRate.STANDARD
  },
  "2ae64838-bad7-4137-9439-0cd5346e083f": {
    name: "Leffe Royale Mapuche",
    vatRate: VatRate.STANDARD
  },
  "7edf8833-c673-48dd-acab-d52cbcdaf7a1": {
    name: "Leffe Royale Whitbread Golding",
    vatRate: VatRate.STANDARD
  },
  "247e836a-07bd-4ebe-a61e-76097295cc5a": {
    name: "Leffe Ruby",
    vatRate: VatRate.STANDARD
  },
  "db5f6611-2982-40d4-b6b4-54fe56aef6ce": {
    name: "Leffe Tripel",
    vatRate: VatRate.STANDARD
  },
  "c0d9b370-56d2-434d-a209-395093444952": {
    name: "Leffe Winter",
    vatRate: VatRate.STANDARD
  },
  "16089da1-9b51-4c96-bf45-1334f0a824f1": {
    name: "Pure Blonde",
    vatRate: VatRate.STANDARD
  },
  "37d99cca-dab4-4be1-a263-18d0e5e596f5": {
    name: "Stella Artois",
    vatRate: VatRate.STANDARD
  },
  "e8e9e701-2c26-4b0c-855f-cdb9139b5d93": {
    name: "Tripel Karmeliet",
    vatRate: VatRate.STANDARD
  },
  "b248fc57-2bfd-4c0e-b6b6-1dae86a32aca": {
    name: "Victoria",
    vatRate: VatRate.STANDARD
  },
  "58e79b8e-17d0-4139-824b-b775585bf3a1": {
    name: "Affligem 0.0",
    vatRate: VatRate.REDUCED
  },
  "8cb282ea-2d78-4610-b18e-8b844f6f8336": {
    name: "Affligem Blond",
    vatRate: VatRate.STANDARD
  },
  "1db73410-7621-423c-bab4-53ac83528a09": {
    name: "Affligem Dubbel",
    vatRate: VatRate.STANDARD
  },
  "4b489a1e-28a7-4224-9db2-51959f1e8107": {
    name: "Affligem Hop Selection",
    vatRate: VatRate.STANDARD
  },
  "1133bf2a-7495-4c0a-82ab-d7a69271756e": {
    name: "Affligem Patersvat",
    vatRate: VatRate.STANDARD
  },
  "ea04ca12-609a-43bc-9da8-30ca19a39b69": {
    name: "Affligem Tripel",
    vatRate: VatRate.STANDARD
  },
  "4f8eb8e6-29e3-45f6-a728-dfc94e9d435f": {
    name: "Birra Moretti",
    vatRate: VatRate.STANDARD
  },
  "d5502dd8-bca9-4451-8069-c5bdb4f2a3f5": {
    name: "Brugs Witbier",
    vatRate: VatRate.STANDARD
  },
  "3275ad39-e9b2-4546-bf2f-98201790df69": {
    name: "Ciney Blond",
    vatRate: VatRate.STANDARD
  },
  "600ca1c5-d9f8-4911-b73a-ae7c1f4b3088": {
    name: "Ciney Bruin",
    vatRate: VatRate.STANDARD
  },
  "5a6df4f7-ce5d-4a5a-a9cf-d13e21572a9c": {
    name: "Cristal",
    vatRate: VatRate.STANDARD
  },
  "57bdc9d4-7210-40e3-8b46-ead8bf94f0c9": {
    name: "Desperados",
    vatRate: VatRate.STANDARD
  },
  "7d7f0c10-0f5c-4f98-a276-1af75a842043": {
    name: "Desperados Mojito",
    vatRate: VatRate.STANDARD
  },
  "8d4bf72c-6756-4820-bf35-ba7d4a885373": {
    name: "Desperados Red",
    vatRate: VatRate.STANDARD
  },
  "77eecd20-014b-42ef-aba6-73778e7e53bf": {
    name: "Desperados Sangré",
    vatRate: VatRate.STANDARD
  },
  "4d7b20fe-892f-49d3-9387-64b08ba56f86": {
    name: "Grimbergen Blanche",
    vatRate: VatRate.STANDARD
  },
  "292fd5a9-df1a-48df-a996-599ce1ffdfab": {
    name: "Grimbergen Blond",
    vatRate: VatRate.STANDARD
  },
  "61896baa-cff8-43a7-be8b-508fcc93a120": {
    name: "Grimbergen Dubbel",
    vatRate: VatRate.STANDARD
  },
  "a8c173fa-4d8c-49c3-ad2a-5a7c38a0bcf3": {
    name: "Grimbergen Fleur D'abbeye",
    vatRate: VatRate.STANDARD
  },
  "fe35dcac-ebf6-4291-a7f4-e4c83c84058d": {
    name: "Grimbergen Hopkarakter",
    vatRate: VatRate.STANDARD
  },
  "90e61655-416c-49a9-a1af-1bd0893c8584": {
    name: "Grimbergen Optimo Bruno",
    vatRate: VatRate.STANDARD
  },
  "38abc60e-3bf2-4d3c-9860-82c8a3bd37b7": {
    name: "Grimbergen Rouge",
    vatRate: VatRate.STANDARD
  },
  "6436c2e6-6c3f-4290-8dd4-3dcb7d22e0d0": {
    name: "Grimbergen Tripel",
    vatRate: VatRate.STANDARD
  },
  "92e5baf1-f9c7-4a37-8f70-8da3cd02d23a": {
    name: "Grimbergen Tripel",
    vatRate: VatRate.STANDARD
  },
  "7173fdd4-0b2a-4862-9993-35c5aa5da21d": {
    name: "Grimbergen Winter",
    vatRate: VatRate.STANDARD
  },
  "ff3b3df3-183c-4d8d-be0a-883a099b3e50": {
    name: "Hapkin",
    vatRate: VatRate.STANDARD
  },
  "17c799c4-e7f9-4131-b870-1601a0393784": {
    name: "Heineken",
    vatRate: VatRate.STANDARD
  },
  "d8a7367e-23a8-4fcb-a46c-cd1a843bc6b8": {
    name: "Judas",
    vatRate: VatRate.STANDARD
  },
  "a26d4f23-51a7-4795-ba1f-9e0d0d13cfbf": {
    name: "Lagunitas IPA",
    vatRate: VatRate.STANDARD
  },
  "ea4efb1d-a66a-41fa-aa04-b2ccf5031368": {
    name: "Maes",
    vatRate: VatRate.STANDARD
  },
  "6c417d54-6d34-4f3c-9a39-366e9680a255": {
    name: "Maes 0,0%",
    vatRate: VatRate.REDUCED
  },
  "0ff8c2d7-862d-4070-9acd-142054c5bd3a": {
    name: "Maes Radler Agrum",
    vatRate: VatRate.STANDARD
  },
  "e8767157-8581-4846-b4c5-3433e34db230": {
    name: "Maes Radler Citroen",
    vatRate: VatRate.STANDARD
  },
  "f6908025-485c-4c7b-8421-7c9c12d10e2f": {
    name: "Maes Radler Citroen 0.0",
    vatRate: VatRate.STANDARD
  },
  "760317db-90d0-441b-8728-09fd51105113": {
    name: "Mort Subite Gueuze",
    vatRate: VatRate.STANDARD
  },
  "621bcc9c-ab66-4c57-930e-eae70aec0b7c": {
    name: "Mort Subite Kriek",
    vatRate: VatRate.STANDARD
  },
  "3fb690a5-0a29-4899-9a46-472cc3f8885a": {
    name: "Mort Subite Kriek Tradition",
    vatRate: VatRate.STANDARD
  },
  "bfb5ed82-ff37-41e0-a2c8-59cb13525812": {
    name: "Mort Subite Oude Gueuze",
    vatRate: VatRate.STANDARD
  },
  "19d64bf4-26eb-4b3d-993b-6cd2fd65f237": {
    name: "Mort Subite Oude Kriek",
    vatRate: VatRate.STANDARD
  },
  "c7ed73cf-f9cb-406e-9b54-2f1aa085f365": {
    name: "Mort Subite Wit",
    vatRate: VatRate.STANDARD
  },
  "193f49fd-5a4d-4b9a-8796-48160e76421a": {
    name: "Postel Blond",
    vatRate: VatRate.STANDARD
  },
  "39fb6303-de07-4838-93a6-1fd34ddc682c": {
    name: "Postel Dubbel",
    vatRate: VatRate.STANDARD
  },
  "c8237d47-91b2-4657-b416-fc1c9dad2e9a": {
    name: "Postel Tripel",
    vatRate: VatRate.STANDARD
  },
  "368a3d71-3e81-4d29-99e1-5063c1cab724": {
    name: "Sol",
    vatRate: VatRate.STANDARD
  },
  "1343a2a3-556e-4602-8e4c-37f52a61f120": {
    name: "Strongbow Elderflower",
    vatRate: VatRate.STANDARD
  },
  "49aa10a8-def7-4a2a-bc4f-115f6de3456e": {
    name: "Strongbow Elderflower",
    vatRate: VatRate.STANDARD
  },
  "108883f8-e443-4f91-a7b0-6746e5a9bcf5": {
    name: "Strongbow Gold Apple",
    vatRate: VatRate.STANDARD
  },
  "ca88e7bf-4dde-471e-942c-70a27ef9c803": {
    name: "Strongbow Gold Apple",
    vatRate: VatRate.STANDARD
  },
  "3b638e6f-e0d6-42fd-8301-e1d98172a00e": {
    name: "Strongbow Red Berries",
    vatRate: VatRate.STANDARD
  },
  "2146b58d-268b-4e2c-8c1f-21df119005e9": {
    name: "Strongbow Red Berries",
    vatRate: VatRate.STANDARD
  },
  "a416a1fb-a554-4254-a007-69ab14cf2fa3": {
    name: "Strongbow Red Berries 0.0",
    vatRate: VatRate.REDUCED
  },
  "611ecf8e-3708-4266-baf0-54ba5fa4566b": {
    name: "Strongbow Rose Apple",
    vatRate: VatRate.STANDARD
  },
  "5d13767f-339d-446c-83b8-3ae701a6fd6a": {
    name: "Strongbow Rose Apple",
    vatRate: VatRate.STANDARD
  },
  "cf2a8524-131a-48e6-9f7a-b5b5a040bec0": {
    name: "Watneys Scotch",
    vatRate: VatRate.STANDARD
  },
  "19ace674-0cc1-4dba-83f5-18e15a4b9742": {
    name: "Alosta",
    vatRate: VatRate.STANDARD
  },
  "c1a2ccfc-7d5c-4837-bd3d-6fb755fd5a98": {
    name: "Amer Amer",
    vatRate: VatRate.STANDARD
  },
  "0460b671-4db9-45fe-bdda-78274d4b1ea4": {
    name: "Averbode",
    vatRate: VatRate.STANDARD
  },
  "522ab8ea-ede5-462e-acc3-f088d3e73f26": {
    name: "Bar Belge",
    vatRate: VatRate.STANDARD
  },
  "19ba3345-d869-41ce-aed5-a58d0c8453d8": {
    name: "Bintang",
    vatRate: VatRate.STANDARD
  },
  "d56d9a27-8a3d-44e8-a1cf-373cf014e920": {
    name: "Bittere Bloemen",
    vatRate: VatRate.STANDARD
  },
  "a9f966c8-da0e-487d-a72f-79aa076d40cf": {
    name: "black fuel",
    vatRate: VatRate.STANDARD
  },
  "9e2036c7-ceee-4f12-97dc-18d4fbe3331a": {
    name: "Blinde Mol",
    vatRate: VatRate.STANDARD
  },
  "afbe694a-0533-4348-b97f-b9bb8b923904": {
    name: "Bock Pils",
    vatRate: VatRate.STANDARD
  },
  "ff9b9090-8b11-400e-bab9-e20de79da511": {
    name: "Bon Secours",
    vatRate: VatRate.STANDARD
  },
  "a4bfa384-25f9-4025-b511-9f770f8cfb88": {
    name: "Bourgogne de flandres",
    vatRate: VatRate.STANDARD
  },
  "5c9d34ec-8bef-48db-b1bd-b5c81d50656a": {
    name: "broeder Jacob tripel",
    vatRate: VatRate.STANDARD
  },
  "0697f728-9a6b-4802-9922-fd67dcff465b": {
    name: "bush blond",
    vatRate: VatRate.STANDARD
  },
  "5cd335f4-e39a-467d-bf03-39fd0e390ea8": {
    name: "cesar tripel",
    vatRate: VatRate.STANDARD
  },
  "c5c81dcd-a8a3-4994-be48-e64e6ecb3d91": {
    name: "Cidre Ruwet Apple",
    vatRate: VatRate.STANDARD
  },
  "77b5d36c-a903-45a0-baff-434424de76b5": {
    name: "Cidre Ruwet Elderberry",
    vatRate: VatRate.STANDARD
  },
  "37818c49-f064-42d3-bcc3-b83a3ac92dee": {
    name: "Corsendonck Pater Dubbel",
    vatRate: VatRate.STANDARD
  },
  "45148cad-d540-4d77-8ffd-be51328b90a0": {
    name: "Corsendonk Agnus Tripel",
    vatRate: VatRate.STANDARD
  },
  "d8ca0eeb-cf93-4852-84ff-a88c289a31b8": {
    name: "Crabbelaer",
    vatRate: VatRate.STANDARD
  },
  "74b0d57d-7bed-4c66-9d98-d7a4b970b556": {
    name: "Craft 13 Black pils",
    vatRate: VatRate.STANDARD
  },
  "19cff0e5-5238-4ba0-89d2-0ab56dfa33fc": {
    name: "Craft 13 Export",
    vatRate: VatRate.STANDARD
  },
  "ae481fde-d042-407f-9761-2b2906eb8d93": {
    name: "cuvee des troll",
    vatRate: VatRate.STANDARD
  },
  "eb3d52fd-17f8-4604-8920-145c4a27f811": {
    name: "Cuvée Watou",
    vatRate: VatRate.STANDARD
  },
  "acfd4ae7-c548-4968-b60f-d0698fe600a8": {
    name: "De Poes Bruin",
    vatRate: VatRate.STANDARD
  },
  "b33db416-d290-47f3-8383-0e5a8181988d": {
    name: "DELERIUM tremens",
    vatRate: VatRate.STANDARD
  },
  "75a7f869-c143-4afb-ad3c-dcfe7812ff5d": {
    name: "den toeteler",
    vatRate: VatRate.STANDARD
  },
  "44ae0198-5dba-472d-8975-7482fbb92099": {
    name: "Erdinger weissbier",
    vatRate: VatRate.STANDARD
  },
  "6f877ed5-c401-40ec-989f-c7d6bd4815d8": {
    name: "Export Krüger",
    vatRate: VatRate.STANDARD
  },
  "615b3317-edf9-4f74-b2b2-4b0ec95d1a38": {
    name: "Farang",
    vatRate: VatRate.STANDARD
  },
  "7b70cd4d-7de1-43dd-86d7-58522b968134": {
    name: "Faro Boon",
    vatRate: VatRate.STANDARD
  },
  "83c38427-081d-40e4-a8b3-d3eb650b2898": {
    name: "Gado-Gado van 't vat",
    vatRate: VatRate.STANDARD
  },
  "a376fffe-152e-4354-9a17-582c741fc176": {
    name: "gauloise tripel",
    vatRate: VatRate.STANDARD
  },
  "f90ee29f-4ddd-4d3f-9b05-f70305c5a466": {
    name: "Gentse Tripel",
    vatRate: VatRate.STANDARD
  },
  "b8922731-e491-4beb-9a31-7d424735a4f3": {
    name: "Geuze Boon",
    vatRate: VatRate.STANDARD
  },
  "3e398335-dc71-4378-80ef-8b6d01368fe7": {
    name: "Geuze Mariage Parfait",
    vatRate: VatRate.STANDARD
  },
  "f28475b9-a1a2-4349-bca5-110b62a2b912": {
    name: "Golden Tricky",
    vatRate: VatRate.STANDARD
  },
  "4372195a-6a51-4b7b-94d4-52714196b5c4": {
    name: "Gordon Scotch",
    vatRate: VatRate.STANDARD
  },
  "c26ffbd7-d067-4d81-80ed-7b71753741ef": {
    name: "Gruut Blond",
    vatRate: VatRate.STANDARD
  },
  "f4e44485-6f21-4b04-bd6d-a43fc15e8031": {
    name: "gueuze oud Beersel",
    vatRate: VatRate.STANDARD
  },
  "acd6dcdf-a3c2-4fa5-954f-255487d5bf30": {
    name: "Heritage 75cl",
    vatRate: VatRate.STANDARD
  },
  "1e88cc4c-2217-4588-bd39-86880f31561c": {
    name: "Herkenrode Cister",
    vatRate: VatRate.STANDARD
  },
  "bde59477-32e3-49f9-b8d1-cea11ee32bc0": {
    name: "Herkenrode Noctis",
    vatRate: VatRate.STANDARD
  },
  "efbe717c-316a-4e33-8f44-f7ad2d095368": {
    name: "Herkenrode Vesper",
    vatRate: VatRate.STANDARD
  },
  "573ecdd9-53af-4d27-96cc-7e78b5148da5": {
    name: "Hertenheer",
    vatRate: VatRate.STANDARD
  },
  "b89c23c5-2ec5-4f6d-a28a-128683ded77c": {
    name: "Hik Blond",
    vatRate: VatRate.STANDARD
  },
  "4388868e-1fd2-4f06-b440-bdedb46766eb": {
    name: "Hommelbier",
    vatRate: VatRate.STANDARD
  },
  "26a86864-6cdc-4572-b273-af0767e6c098": {
    name: "Hoogheid",
    vatRate: VatRate.STANDARD
  },
  "0fb12561-6d67-4d0b-87af-ba9363cd50a4": {
    name: "Hop The Brewer",
    vatRate: VatRate.STANDARD
  },
  "fd897815-2b5a-4b7d-a5ad-b9b320aabf87": {
    name: "hopschepper",
    vatRate: VatRate.STANDARD
  },
  "4692bde3-a26e-46d0-9534-35e25bcbb504": {
    name: "Hopverdomme",
    vatRate: VatRate.STANDARD
  },
  "c25eb50c-b35e-4398-a5e2-9fbb357ea82e": {
    name: "Jack's Precious IPA",
    vatRate: VatRate.STANDARD
  },
  "a4f3734f-facc-400e-9ad0-fbd269e4af34": {
    name: "Jour de Fête",
    vatRate: VatRate.STANDARD
  },
  "ce1bbb39-e1eb-4b6e-8632-ef47284d7f1a": {
    name: "Jurist",
    vatRate: VatRate.STANDARD
  },
  "fc4b4ab4-eb64-4afa-ba4d-dee71691e71a": {
    name: "Jurist Pleidooi",
    vatRate: VatRate.STANDARD
  },
  "3368028f-8849-4eae-9793-3b22d9af6e7e": {
    name: "Jurist Pro Deo",
    vatRate: VatRate.STANDARD
  },
  "3b3630d5-4926-4e36-9eda-cff19a10da78": {
    name: "Keikop saison",
    vatRate: VatRate.STANDARD
  },
  "50214f47-6191-489f-894b-d94221eb7445": {
    name: "keikop tripel",
    vatRate: VatRate.STANDARD
  },
  "7464d3be-bd7c-4331-93e0-edc1fd8d4c28": {
    name: "kerel Biere de garde",
    vatRate: VatRate.STANDARD
  },
  "d65f5c82-3ef8-4989-86ad-84f066819e25": {
    name: "kerel grapefruit ipa",
    vatRate: VatRate.STANDARD
  },
  "c6f38a9a-03d2-4871-90e4-9550f83913ad": {
    name: "kerel stout",
    vatRate: VatRate.STANDARD
  },
  "88af9b4c-e063-4f22-a3dc-d4cd4794edaa": {
    name: "Klevere 10",
    vatRate: VatRate.STANDARD
  },
  "d2865e09-f242-4f52-8294-60c4e08f987f": {
    name: "Koekedam",
    vatRate: VatRate.STANDARD
  },
  "b2fa9533-1663-4b49-94eb-d0aedb5924e7": {
    name: "Koeketiene Tripel",
    vatRate: VatRate.STANDARD
  },
  "97bc5375-0163-4205-8350-efb2ed0a06ee": {
    name: "Krab",
    vatRate: VatRate.STANDARD
  },
  "b11bc9b8-6634-4be8-afaf-9f36cbe4fb20": {
    name: "Kriek Boon",
    vatRate: VatRate.STANDARD
  },
  "778ae762-b848-4e09-ae74-6a103d809b25": {
    name: "Kriek Mariage Parfait",
    vatRate: VatRate.STANDARD
  },
  "1b03b395-c4d6-4da7-b6c6-ad239911c6d9": {
    name: "la triple corne",
    vatRate: VatRate.STANDARD
  },
  "606bf64f-e051-46e0-acff-f1d7b912eedd": {
    name: "Lazarus Bourbon Whiskey Infused",
    vatRate: VatRate.STANDARD
  },
  "ff045b14-ee15-40cf-af25-03770d9a33ff": {
    name: "lazarus rum infused",
    vatRate: VatRate.STANDARD
  },
  "23e98154-9ead-4291-940c-af6cf1897e68": {
    name: "L’Arogante",
    vatRate: VatRate.STANDARD
  },
  "d8ca0820-b159-420a-b059-c9caf5c0cc5b": {
    name: "Moinette Blonde 35,50cl",
    vatRate: VatRate.STANDARD
  },
  "2362fa2a-8524-4d73-b35c-1659641251eb": {
    name: "Mokke Blond",
    vatRate: VatRate.STANDARD
  },
  "c9229007-cc47-4756-ab75-eb144262e1ae": {
    name: "molder blond",
    vatRate: VatRate.STANDARD
  },
  "4821d7d0-fe02-4fe2-a466-3550d2ffba07": {
    name: "Moose Blues",
    vatRate: VatRate.STANDARD
  },
  "e8c2c077-d13e-42a0-947a-459cc802da22": {
    name: "noblesse",
    vatRate: VatRate.STANDARD
  },
  "88a446af-aed8-448b-b88b-133f93644f7f": {
    name: "NON alcoholic playground ipa",
    vatRate: VatRate.REDUCED
  },
  "8a697267-6830-4715-a12c-aacbe62e6f72": {
    name: "Nonkel Pater",
    vatRate: VatRate.STANDARD
  },
  "5b5e095b-4611-46b4-99e3-1fe19a750363": {
    name: "Oud bruin",
    vatRate: VatRate.STANDARD
  },
  "1bbcfc62-9d70-4a39-91d1-071336ce8568": {
    name: "Oude Kriek Boon",
    vatRate: VatRate.STANDARD
  },
  "3392de61-dbe1-44dd-82d6-b59a64e9b9da": {
    name: "Ouwen duiker",
    vatRate: VatRate.STANDARD
  },
  "35ed2331-5087-464f-a561-34faa60ed6e2": {
    name: "Paix Dieu",
    vatRate: VatRate.STANDARD
  },
  "aabf0add-12d8-4164-8b5c-6fb5e2d2ed45": {
    name: "paljas",
    vatRate: VatRate.STANDARD
  },
  "2e7a095f-dde2-4842-adf5-62290468df59": {
    name: "Piet-Agoras",
    vatRate: VatRate.STANDARD
  },
  "c220836f-bf39-4fc2-9e1f-d443b7bca2f3": {
    name: "Qantelaar",
    vatRate: VatRate.STANDARD
  },
  "925e7475-d589-4536-97f0-ada2ae51dbcf": {
    name: "Quintine Amber",
    vatRate: VatRate.STANDARD
  },
  "8f045884-b0fe-48cf-b828-dc600c381742": {
    name: "Quintine Blond",
    vatRate: VatRate.STANDARD
  },
  "8c64065e-9c08-432d-ad60-e9082d97323d": {
    name: "Rangsken",
    vatRate: VatRate.STANDARD
  },
  "cb7c24d5-15ec-4b64-a2d3-d2fbbf19667a": {
    name: "RAVELS DONKER",
    vatRate: VatRate.STANDARD
  },
  "eec2069c-fa4b-46ce-9687-dfcd13029eaf": {
    name: "Rebel Local",
    vatRate: VatRate.STANDARD
  },
  "91a343bd-02dc-4663-8d75-e1caf421126f": {
    name: "Redenaar",
    vatRate: VatRate.STANDARD
  },
  "bef9363d-19fe-41ea-acce-8ef3383a1707": {
    name: "reinaert amber",
    vatRate: VatRate.STANDARD
  },
  "9a55179b-f90e-411e-94d1-9fb46e269105": {
    name: "reinaert grand cru",
    vatRate: VatRate.STANDARD
  },
  "ae189729-d9e0-4336-ad66-d0402fb1257c": {
    name: "reinaert tripel",
    vatRate: VatRate.STANDARD
  },
  "828e37f2-ba07-4f3e-bbe1-d534196b6a27": {
    name: "Rodenbach Vintage 75cl",
    vatRate: VatRate.STANDARD
  },
  "84d08f50-cdac-4c58-9a4f-ba85ee8a58b5": {
    name: "Saison De Dottignies",
    vatRate: VatRate.STANDARD
  },
  "a96e0d07-dae8-4c9b-b720-2e72790c7031": {
    name: "saison dupont",
    vatRate: VatRate.STANDARD
  },
  "fbbe6f3b-4ec2-4a6e-b587-15d4b222ba06": {
    name: "Saison Dupont Bio",
    vatRate: VatRate.STANDARD
  },
  "38cb41a1-25ca-4342-be7d-b13eda7d10ee": {
    name: "Salitos",
    vatRate: VatRate.STANDARD
  },
  "e2231a05-01ee-4495-b25b-921c32226169": {
    name: "Schuppenboer",
    vatRate: VatRate.STANDARD
  },
  "cd42bacd-3196-40b2-8aa0-8d826de46746": {
    name: "Schuppenboer ( Nest )",
    vatRate: VatRate.STANDARD
  },
  "a6a10fd0-ade7-43cd-ab08-7c7db57d8af8": {
    name: "Seefbier",
    vatRate: VatRate.STANDARD
  },
  "c65e8d4a-72cf-4798-834a-3bfca37340fb": {
    name: "Space cadet",
    vatRate: VatRate.STANDARD
  },
  "ae1632bb-6ab8-45a4-9b89-60457e0dab37": {
    name: "ST-HUBERTUS TRIPEL",
    vatRate: VatRate.STANDARD
  },
  "a630cb34-cf29-4e9b-b200-b0fb7a0aad2c": {
    name: "Star",
    vatRate: VatRate.REDUCED
  },
  "455dc0fe-a375-4f95-9962-331a87a8ba86": {
    name: "Straffe Hendrik Heritage 75cl",
    vatRate: VatRate.STANDARD
  },
  "ef7965d5-68a9-41e3-a308-f8b71a881a87": {
    name: "Strandgaper",
    vatRate: VatRate.STANDARD
  },
  "d748a0c4-8467-46c6-9c8e-9a65e56cc010": {
    name: "Super Noah",
    vatRate: VatRate.STANDARD
  },
  "a2c00339-445d-48c2-978b-b778fb7be226": {
    name: "T'ij IPA",
    vatRate: VatRate.STANDARD
  },
  "f6f71e6c-2ec5-4736-ba29-14f6c30ea253": {
    name: "tamera tripel",
    vatRate: VatRate.STANDARD
  },
  "36cd0560-b117-42ed-912d-ee1dd45f3a39": {
    name: "Tatsevoet",
    vatRate: VatRate.STANDARD
  },
  "e948f003-80ce-4157-8a4d-35d3c0362281": {
    name: "Taxandria Copper Roast",
    vatRate: VatRate.STANDARD
  },
  "4c22bd06-4bf7-4926-915a-fc2e32cfef9a": {
    name: "Taxandria Golden Ale",
    vatRate: VatRate.STANDARD
  },
  "d7c071aa-6a33-4abf-a73b-51e9f267e408": {
    name: "Tietje",
    vatRate: VatRate.STANDARD
  },
  "59b248db-a4aa-4e17-b82a-3c26b39c6d24": {
    name: "Triporteur Bling Bling",
    vatRate: VatRate.STANDARD
  },
  "c3cfb530-ac89-44d9-99bb-ce6e3f93f281": {
    name: "Triporteur Bling Bling",
    vatRate: VatRate.STANDARD
  },
  "c9c4ed19-4c4b-4d65-8738-de4ba44acd4f": {
    name: "Triporteur From Heaven 33cl",
    vatRate: VatRate.STANDARD
  },
  "04e01d62-c41a-40c4-bcbf-854b40761708": {
    name: "Triporteur Nipple",
    vatRate: VatRate.STANDARD
  },
  "a7df456d-bedc-4430-a6c8-2558e4ab3ddd": {
    name: "Troubadour Magma",
    vatRate: VatRate.STANDARD
  },
  "dfcad97f-0015-4106-b820-80ea50b5711e": {
    name: "Tumulus aura",
    vatRate: VatRate.STANDARD
  },
  "a9738edd-c7ab-4ca2-9546-d570ced140f3": {
    name: "Unwanted Tattoo",
    vatRate: VatRate.STANDARD
  },
  "67df3201-0e6c-4486-8e6b-abd91168c893": {
    name: "Urthel Hop It",
    vatRate: VatRate.STANDARD
  },
  "6a099802-c47f-437a-adc6-cfafb269be9c": {
    name: "Urthel Saisonnière",
    vatRate: VatRate.STANDARD
  },
  "f252140b-512e-4929-a413-f564d0481cb0": {
    name: "urthel Samaranth",
    vatRate: VatRate.STANDARD
  },
  "2e9f295f-4f96-4bbf-b276-14aeca293564": {
    name: "vicaris tripel",
    vatRate: VatRate.STANDARD
  },
  "30c59212-59dd-4bd3-bafd-878ab49f2dbb": {
    name: "Viswijf Blond",
    vatRate: VatRate.STANDARD
  },
  "ebefc86d-e1ce-4588-a71e-4a61fc38bf46": {
    name: "Viven Master IPA",
    vatRate: VatRate.STANDARD
  },
  "5b5c6d66-9c03-4a02-be27-815e6d291d2c": {
    name: "Waterloo tripel",
    vatRate: VatRate.STANDARD
  },
  "6cf8f0fd-e130-4599-855c-478514f669d6": {
    name: "WilDe Beest",
    vatRate: VatRate.STANDARD
  },
  "bf0e1146-0b25-43c8-98d5-5a5d2ae1864c": {
    name: "Winterkoninkske Grand Cru 75cl",
    vatRate: VatRate.STANDARD
  },
  "bafbe496-74d8-46d1-a713-56f1540aa446": {
    name: "XX bitter",
    vatRate: VatRate.STANDARD
  },
  "32a44cc7-0ea7-4072-b572-2cd3af0fec76": {
    name: "Zinnebir",
    vatRate: VatRate.STANDARD
  },
  "fdceda8a-6e0e-4b2b-a5a4-e475823a5f69": {
    name: "Blonde Stoot",
    vatRate: VatRate.STANDARD
  },
  "f7da66ed-d40b-4e47-bc2a-8d0d02fc722e": {
    name: "Westmalle Dubbel",
    vatRate: VatRate.STANDARD
  },
  "0ff4422e-3823-4bd5-9644-596b9d0744a7": {
    name: "Westmalle Tripel",
    vatRate: VatRate.STANDARD
  },
  "1838ed37-a8ce-41f9-b443-2f1f4b5e7429": {
    name: "Artevelde",
    vatRate: VatRate.STANDARD
  },
  "eab7abef-ede6-459a-8945-e91a04b53f49": {
    name: "Artevelde Grand Cru",
    vatRate: VatRate.STANDARD
  },
  "58d983d4-a075-4f27-9805-11f498aa5ce0": {
    name: "Blanche Des Neiges",
    vatRate: VatRate.STANDARD
  },
  "f4424b1d-aedf-41d1-b65e-8dca0a527adc": {
    name: "Delirium Argentum",
    vatRate: VatRate.STANDARD
  },
  "ffaa3c39-76b6-4f5f-888b-9d75f1da0ed9": {
    name: "Delirium Black",
    vatRate: VatRate.STANDARD
  },
  "84f2f480-f5fc-48f0-bfdb-e596ff3c3d70": {
    name: "Delirium Christmas",
    vatRate: VatRate.STANDARD
  },
  "198308b2-9625-4aaa-961b-afba38f7cf98": {
    name: "Delirium Deliria",
    vatRate: VatRate.STANDARD
  },
  "558b3e02-7804-4f2c-b64f-68ac5a79a52a": {
    name: "Delirium Nocturnum",
    vatRate: VatRate.STANDARD
  },
  "cb60066e-52df-484d-9732-083a4c6da0eb": {
    name: "Delirium Red",
    vatRate: VatRate.STANDARD
  },
  "f08c2ba3-cee0-4c74-9b55-fafa2b728813": {
    name: "Delirium Trmenes",
    vatRate: VatRate.STANDARD
  },
  "bee92f1a-fbeb-44ad-a89c-7c32cb6e84ba": {
    name: "La Guillotine",
    vatRate: VatRate.STANDARD
  },
  "d428b8ea-fd95-498f-91e9-a8ab9c7aa2ef": {
    name: "Mongozo",
    vatRate: VatRate.STANDARD
  },
  "ad509050-39f2-4f07-bb34-c90e54c14872": {
    name: "Mongozo Banana",
    vatRate: VatRate.STANDARD
  },
  "89097fde-9792-4984-8af0-f21fa27fdf20": {
    name: "Mongozo Buckwheat White",
    vatRate: VatRate.STANDARD
  },
  "83876d1a-7ef0-49cd-b8a3-0f80b1b23b1e": {
    name: "Mongozo Coconut",
    vatRate: VatRate.STANDARD
  },
  "23fa26ac-1e61-446c-8964-110280ab58fb": {
    name: "Mongozo Mango",
    vatRate: VatRate.STANDARD
  },
  "bda95cec-31d8-4e53-b550-bd98620ca397": {
    name: "St. Idesbald Blond",
    vatRate: VatRate.STANDARD
  },
  "09c9d8ec-7a2b-4ecb-88e7-31b881d36675": {
    name: "St. Idesbald Dubbel",
    vatRate: VatRate.STANDARD
  },
  "abddfa56-14f1-4446-8416-25064e5a2a3a": {
    name: "St. Idesbald Rousse",
    vatRate: VatRate.STANDARD
  },
  "cdded758-c429-41b3-90eb-68e6b9bcbd5c": {
    name: "St. Idesbald Tripel",
    vatRate: VatRate.STANDARD
  },
  "0bbcfb14-2d1c-4dda-bfe8-5a715010bdfb": {
    name: "Villers",
    vatRate: VatRate.STANDARD
  },
  "7ed9c457-8d28-499b-b974-636d63b77283": {
    name: "Villers Tripel",
    vatRate: VatRate.STANDARD
  },
  "ed61b49b-5b15-486d-b5f2-dac12a43fac4": {
    name: "Ara Wak",
    vatRate: VatRate.STANDARD
  },
  "fee3985c-aac7-4315-b8fe-803dd7a46dd3": {
    name: "Baby Lone",
    vatRate: VatRate.STANDARD
  },
  "3b979232-41a1-476f-9d65-064dc4287ae8": {
    name: "Black Swan",
    vatRate: VatRate.STANDARD
  },
  "96a807b8-206a-4f61-ac2e-86ca5b7d6306": {
    name: "Canni Bale",
    vatRate: VatRate.STANDARD
  },
  "1d9c9a7d-346e-4f78-96a4-17269b457e0d": {
    name: "Chock Ablock",
    vatRate: VatRate.STANDARD
  },
  "d8386ca0-4956-41aa-b5d4-a781dfec292b": {
    name: "Dark Sister",
    vatRate: VatRate.STANDARD
  },
  "2c825527-87ee-4ea2-9e1e-1562aae3ba85": {
    name: "Delta IPA",
    vatRate: VatRate.STANDARD
  },
  "bd0b4c5a-1917-4510-aa11-3563123810b0": {
    name: "Grosse Bertha",
    vatRate: VatRate.STANDARD
  },
  "66e8e3da-cce4-436f-ab6d-959dedfe0e17": {
    name: "Juice Junkie",
    vatRate: VatRate.STANDARD
  },
  "8203d596-a552-4121-a79f-c37a1cde9ee3": {
    name: "Jungle Joy",
    vatRate: VatRate.STANDARD
  },
  "a3f67f82-8460-4d23-971f-4feed0e7ddf5": {
    name: "La Shaman",
    vatRate: VatRate.STANDARD
  },
  "5e32f84a-5cc1-4664-b0d2-7ab0ef8822ff": {
    name: "Lime Crime",
    vatRate: VatRate.STANDARD
  },
  "dc3b946b-c90e-48fb-a9ae-453f3fb05742": {
    name: "Lust 4 Lips",
    vatRate: VatRate.STANDARD
  },
  "dc647300-0534-47c2-9ba5-bc8df4442d34": {
    name: "Nord Bliss",
    vatRate: VatRate.STANDARD
  },
  "16d0c2c5-f748-4859-8fcf-4b8dbbdfeedc": {
    name: "Super Sessn",
    vatRate: VatRate.STANDARD
  },
  "2a70b774-4086-4ec8-b6a1-bc8f2b3fdac5": {
    name: "Touch Cookie",
    vatRate: VatRate.STANDARD
  },
  "aa5723eb-b611-4be5-b2fa-caeacb767a23": {
    name: "Touch Love",
    vatRate: VatRate.STANDARD
  },
  "e1cbcd95-d772-4295-b799-d071a0159496": {
    name: "Toy Boy",
    vatRate: VatRate.STANDARD
  },
  "be5cefd4-2e4e-41b9-a181-41fe618ad2d3": {
    name: "Tu Mi Turbi",
    vatRate: VatRate.STANDARD
  },
  "9b8af107-abb1-4655-8aeb-2e6b9c41552b": {
    name: "Whizz Kid",
    vatRate: VatRate.STANDARD
  },
  "92c42412-e7e9-4ec4-b486-249b07905b0f": {
    name: "Wunder Lager",
    vatRate: VatRate.STANDARD
  },
  "23de671d-8a33-47e1-9aca-143c7cce87ca": {
    name: "Carlsberg",
    vatRate: VatRate.STANDARD
  },
  "4a42f4ef-5825-4f88-bc7a-e903ec5affe3": {
    name: "Carlsberg 0,0%",
    vatRate: VatRate.REDUCED
  },
  "59993ee6-4f12-42af-929f-c574522da4d8": {
    name: "Schweppes Ginger ale",
    vatRate: VatRate.REDUCED
  },
  "5a573849-1bfb-4658-a4d9-2ea7c13a8730": {
    name: "Schweppes Ginger Beer",
    vatRate: VatRate.REDUCED
  },
  "58fc91a8-b1d0-4f86-9219-59592ac35519": {
    name: "Schweppes Hibiscus",
    vatRate: VatRate.REDUCED
  },
  "58fd2bcf-1b59-4b81-b963-03122d3c3be8": {
    name: "Schweppes Matcha",
    vatRate: VatRate.REDUCED
  },
  "20f3b580-5304-4e6b-94bc-46aa43ee1c82": {
    name: "Schweppes Orange & Lychee",
    vatRate: VatRate.REDUCED
  },
  "396e922f-0a68-4c6e-ad2a-5a8446a99ca8": {
    name: "Schweppes Orange Blossem & Lavender",
    vatRate: VatRate.REDUCED
  },
  "5fd396cc-8608-4e44-a42d-4f7b224bd25d": {
    name: "Schweppes Original",
    vatRate: VatRate.REDUCED
  },
  "431bd7ef-8168-49f6-baa6-ce7312f04020": {
    name: "Schweppes Pink Pepper",
    vatRate: VatRate.REDUCED
  },
  "14d64b5b-2aa3-493d-8784-cead270530b2": {
    name: "Somersby",
    vatRate: VatRate.STANDARD
  },
  "43b48020-8a1c-4510-a43d-0d1ff0d28161": {
    name: "Clink Offley Rosé",
    vatRate: VatRate.STANDARD
  },
  "e16451c3-f66d-4c50-9c90-8bc03ed9e598": {
    name: "Cosmopolitan",
    vatRate: VatRate.STANDARD
  },
  "db77a980-68b5-402a-b258-e5b5c4c9424c": {
    name: "Crodino",
    vatRate: VatRate.STANDARD
  },
  "1d399606-67a6-489e-a58a-fffbc476bb1d": {
    name: "Crodino Rosso",
    vatRate: VatRate.STANDARD
  },
  "2727b7b4-c709-47ca-8043-a6f48f716c9c": {
    name: "Disaronno Mule",
    vatRate: VatRate.STANDARD
  },
  "23ac8e41-79fa-4833-88dd-e2365a4df0be": {
    name: "Get 27",
    vatRate: VatRate.STANDARD
  },
  "63d76f40-c7d6-4cc1-b27a-8a3138b49f6f": {
    name: "La Vie en Rose",
    vatRate: VatRate.STANDARD
  },
  "1e327179-cec4-418d-ac98-30aab49ac9c8": {
    name: "Lillet",
    vatRate: VatRate.STANDARD
  },
  "e3c6dc74-de3d-4305-b936-463bdeb1a6a7": {
    name: "Limoncello di Capri Spritz",
    vatRate: VatRate.STANDARD
  },
  "276e8996-880d-4e37-b993-acdc048f74ce": {
    name: "Martini Bianco",
    vatRate: VatRate.STANDARD
  },
  "4d943496-2ad4-4cc2-916d-4329be8440d5": {
    name: "Mojito",
    vatRate: VatRate.STANDARD
  },
  "28633ff4-9b29-41fe-8d21-ca5ea2efc88e": {
    name: "Moscow Mule",
    vatRate: VatRate.STANDARD
  },
  "8fea1b70-0c28-4fba-9c50-4b9910a9543b": {
    name: "Picon",
    vatRate: VatRate.STANDARD
  },
  "ac5029fa-2cb6-42b4-a13b-6857e951dcce": {
    name: "Ricard",
    vatRate: VatRate.STANDARD
  },
  "617e4d49-c164-4115-924a-d079ab14969d": {
    name: "Roomer",
    vatRate: VatRate.STANDARD
  },
  "81c6d2fa-1597-4bf7-b1c9-8f83415e67d0": {
    name: "Sherry Dry",
    vatRate: VatRate.STANDARD
  },
  "cf7bad17-a707-432a-a09e-9b210b29cb35": {
    name: "Virgin Mojito",
    vatRate: VatRate.STANDARD
  },
  "21d98d75-b019-459f-b6a9-b01c310ad8cc": {
    name: "Bavik",
    vatRate: VatRate.STANDARD
  },
  "4a2eecf6-4aa6-421e-b1dc-c35ea0142101": {
    name: "Bavik Export",
    vatRate: VatRate.STANDARD
  },
  "de8d60ea-8ebe-498e-b5e2-07c4bf4c9223": {
    name: "Brewmasters Selection Wild Funky Wit",
    vatRate: VatRate.STANDARD
  },
  "67ce440e-d55d-43d1-8de8-3d3960cc4c93": {
    name: "Brewmasters Selection Wild Quadrupel",
    vatRate: VatRate.STANDARD
  },
  "2ccf2fa6-95a0-4ee9-a9fe-c983839a12b4": {
    name: "Brewmasters Selection Wild Tripel Hop",
    vatRate: VatRate.STANDARD
  },
  "a21fa7fe-26b9-4a9d-a1ea-452946099cf8": {
    name: "Ezel",
    vatRate: VatRate.STANDARD
  },
  "fab7911b-60e1-4072-a97a-4132049142c7": {
    name: "ice tea",
    vatRate: VatRate.STANDARD
  },
  "e4b38022-966a-4400-b186-6ff79e043796": {
    name: "Kwaremont",
    vatRate: VatRate.STANDARD
  },
  "c8499773-2fe9-4021-bd8d-727f77203304": {
    name: "Kwaremont 0.3",
    vatRate: VatRate.REDUCED
  },
  "9d06f42b-cac6-4ac5-9193-3ec33bf02f43": {
    name: "Petrus Aged Ale",
    vatRate: VatRate.STANDARD
  },
  "9be58443-958c-43f7-93df-7e077f0a71fb": {
    name: "Petrus Aged Red",
    vatRate: VatRate.STANDARD
  },
  "5ed49759-591d-432a-9a7b-9c80703c89d4": {
    name: "Petrus Blond",
    vatRate: VatRate.STANDARD
  },
  "a0348c1f-02a4-4af2-a474-fdd9392f4f08": {
    name: "Petrus Dubbel",
    vatRate: VatRate.STANDARD
  },
  "9a4a939f-271b-4a6c-b19e-79432beee8a0": {
    name: "Petrus Nitro Cherry & Chocolate",
    vatRate: VatRate.STANDARD
  },
  "6361578d-6800-4623-b9f8-d075d8b010ec": {
    name: "Petrus Nitro Quad",
    vatRate: VatRate.STANDARD
  },
  "8efac76a-caf6-4b32-b16d-0c70f9fbfb29": {
    name: "Petrus Rood Bruin",
    vatRate: VatRate.STANDARD
  },
  "a47f6964-14f7-4240-9277-38f5f75ce2d0": {
    name: "Petrus Tripel",
    vatRate: VatRate.STANDARD
  },
  "07ef25cd-a5c0-4f7d-af16-0f0860e29334": {
    name: "Red By Petrus",
    vatRate: VatRate.STANDARD
  },
  "ed1303e1-97d4-4d45-9f5c-08ac98966516": {
    name: "Wieze",
    vatRate: VatRate.STANDARD
  },
  "644732b8-e0b8-4a9a-b52b-89ebc1a1d747": {
    name: "Wittekerke",
    vatRate: VatRate.STANDARD
  },
  "49fc3e65-05c9-4d96-ac65-87f6a4d163ff": {
    name: "Brugge Tripel",
    vatRate: VatRate.STANDARD
  },
  "c9642da4-5590-4829-b75e-21507366deb5": {
    name: "Steenbrugge Abijd Bock",
    vatRate: VatRate.STANDARD
  },
  "995cc75e-f1de-4ce6-989a-046374625e12": {
    name: "Steenbrugge Blond",
    vatRate: VatRate.STANDARD
  },
  "01357ca3-428b-45f3-95c4-e1b13f379f4e": {
    name: "Steenbrugge Dubbel Bruin",
    vatRate: VatRate.STANDARD
  },
  "451664c9-48b9-4644-a191-8c1627e1a7cc": {
    name: "Steenbrugge Tripel",
    vatRate: VatRate.STANDARD
  },
  "028277ea-dfc9-42ef-b896-178a2b228f94": {
    name: "Steenbrugge Wit",
    vatRate: VatRate.STANDARD
  },
  "52e79539-9de1-48ab-b824-56b90f405b8e": {
    name: "Brugs Tarwebier",
    vatRate: VatRate.STANDARD
  },
  "70b37ba5-074f-4e7d-9930-9c051ce0177c": {
    name: "Brugse Zot",
    vatRate: VatRate.STANDARD
  },
  "9390a489-aea6-451d-bc60-6a3a8061f431": {
    name: "Brugse Zot Dubbel",
    vatRate: VatRate.STANDARD
  },
  "065d63b7-e5bb-496d-9edf-e8bf8fd4bf08": {
    name: "Sportzot",
    vatRate: VatRate.STANDARD
  },
  "263b2ed7-b0f0-4b6f-ae7b-d1225517b2a9": {
    name: "Straffe Hendrik Quadrupel",
    vatRate: VatRate.STANDARD
  },
  "5d2461c0-bb0f-4c40-889e-de6f76a660dc": {
    name: "Straffe Hendrik Tripel",
    vatRate: VatRate.STANDARD
  },
  "08d78a67-dde9-48ab-bca1-10ff069fad68": {
    name: "Cornet",
    vatRate: VatRate.STANDARD
  },
  "30264eaa-0b06-46f9-b4e6-a18ff1feb717": {
    name: "'T IJ van de Duvel",
    vatRate: VatRate.STANDARD
  },
  "d2652547-6cc7-4839-a599-9dd94bab5958": {
    name: "Anniversery Ale XXI",
    vatRate: VatRate.STANDARD
  },
  "60e9e8a7-14bf-40a3-9b5b-2ba6fd075b25": {
    name: "Bel Pils",
    vatRate: VatRate.STANDARD
  },
  "11378a9a-772b-48bf-aa11-30f908f6b249": {
    name: "Bolleke De Koninck",
    vatRate: VatRate.STANDARD
  },
  "169eec85-e44e-4770-928e-4777efb63863": {
    name: "Bravo vintage 2017",
    vatRate: VatRate.STANDARD
  },
  "a399dace-a062-463e-852e-71a4b84ccde1": {
    name: "Bretta Weisse",
    vatRate: VatRate.STANDARD
  },
  "dfbc4c6f-a4f0-4626-8f6f-073b5e8a6d8b": {
    name: "Cherry Chouffe",
    vatRate: VatRate.STANDARD
  },
  "ecf72e20-f44d-4a0c-b7af-6722d5f8f7bf": {
    name: "Chouffe Blanche",
    vatRate: VatRate.STANDARD
  },
  "ef5527d9-9498-4004-9b95-a11fc887874b": {
    name: "Chouffe Bok",
    vatRate: VatRate.STANDARD
  },
  "bafe4bb7-73b4-467b-99b2-365536ac7be5": {
    name: "Chouffe Soleil",
    vatRate: VatRate.STANDARD
  },
  "e600b6cf-80e5-4653-bf68-0570a83b5461": {
    name: "Dark Truth",
    vatRate: VatRate.STANDARD
  },
  "32b5585d-7f28-429f-8480-58f38a15039b": {
    name: "Duvel",
    vatRate: VatRate.STANDARD
  },
  "c22bd5fd-4ce9-4b0c-aea0-e03559fb1a88": {
    name: "Duvel 6,66%",
    vatRate: VatRate.STANDARD
  },
  "20f5d16d-24eb-4334-b72c-9d9c4cbaf6da": {
    name: "Duvel Barrel Aged",
    vatRate: VatRate.STANDARD
  },
  "8e059267-56f2-4b20-99ab-79eb7284dec3": {
    name: "Duvel Tripel Hop Cashmere",
    vatRate: VatRate.STANDARD
  },
  "3a8a7068-ec8b-4837-a217-3594aecf0b32": {
    name: "Duvel Tripel Hop Citra",
    vatRate: VatRate.STANDARD
  },
  "59f4c904-0468-442e-8d67-0c7b03e8e0ca": {
    name: "Hennepin",
    vatRate: VatRate.STANDARD
  },
  "1742939e-f26b-43f3-834d-d7a21f69882d": {
    name: "Houblon Chouffe",
    vatRate: VatRate.STANDARD
  },
  "0350d8e9-64f0-4cb2-9a58-1c32daee1168": {
    name: "Ijwit",
    vatRate: VatRate.STANDARD
  },
  "5a3ec278-31e1-4967-a562-6765c317f520": {
    name: "La Chouffe",
    vatRate: VatRate.STANDARD
  },
  "cf8cee08-d232-4928-8607-67fdcd332f2e": {
    name: "Liefmans Fruitesse - on the Rocks",
    vatRate: VatRate.STANDARD
  },
  "32261bb9-ad99-4075-8b3e-432a3b0e978b": {
    name: "Liefmans Fruitesse 0,0% - on the Rocks",
    vatRate: VatRate.REDUCED
  },
  "b69011c1-116f-453e-b8ad-8758f071eb30": {
    name: "Liefmans Goudenband",
    vatRate: VatRate.STANDARD
  },
  "b752fdc9-bc7c-426b-82df-673876f1475a": {
    name: "Liefmans Kriek Brut",
    vatRate: VatRate.STANDARD
  },
  "2e0d905e-321e-4a6e-9e67-6c666ee54daf": {
    name: "Liefmans Oud Bruin",
    vatRate: VatRate.STANDARD
  },
  "fa2b417b-e2e9-4100-b447-888c4f46b723": {
    name: "Liefmans Yell'Oh - On the Rocks",
    vatRate: VatRate.STANDARD
  },
  "00fc6cd4-2f05-4794-8c78-2d4c4649f6d8": {
    name: "Lost in Spice",
    vatRate: VatRate.STANDARD
  },
  "df93a52f-a095-4d11-b789-d79ca185c5bd": {
    name: "Love Child",
    vatRate: VatRate.STANDARD
  },
  "ec022a1f-859d-44ed-87ec-c148d67eaca8": {
    name: "Manhattan Cask",
    vatRate: VatRate.STANDARD
  },
  "c4e804e6-a0a7-402e-af2b-49f2562d2991": {
    name: "Maredsous Blond",
    vatRate: VatRate.STANDARD
  },
  "8128a102-6fed-44a0-847b-3de509f4f03c": {
    name: "Maredsous Bruin",
    vatRate: VatRate.STANDARD
  },
  "788b45a9-ea66-4616-85f5-8727fde03f91": {
    name: "Maredsous Tripel",
    vatRate: VatRate.STANDARD
  },
  "d05d79f2-a7ae-469d-be5b-2a118a68cc2d": {
    name: "Mc Chouffe",
    vatRate: VatRate.STANDARD
  },
  "44d19375-3c0d-497b-975f-ff9b2b4e88dc": {
    name: "N'ice CHOUFFE",
    vatRate: VatRate.STANDARD
  },
  "9a357969-285a-498d-a63b-52e5996edc78": {
    name: "Scotch Parabola",
    vatRate: VatRate.STANDARD
  },
  "f6013f71-12fd-43cd-8208-79dd030ca9da": {
    name: "Space Camper",
    vatRate: VatRate.STANDARD
  },
  "12e5e369-dd47-452b-8283-4a162bf5a940": {
    name: "Sucaba",
    vatRate: VatRate.STANDARD
  },
  "b179c40c-39c1-47eb-b0a1-5928d2c733eb": {
    name: "Tank 7",
    vatRate: VatRate.STANDARD
  },
  "1f9931ee-eed2-42b1-af44-fda240f99db5": {
    name: "Tree Philosophers",
    vatRate: VatRate.STANDARD
  },
  "d84c53f8-ab24-46c3-81a5-600725839285": {
    name: "Tripel D'Anvers",
    vatRate: VatRate.STANDARD
  },
  "3c860842-280a-4e6f-bb24-6ba05b300a14": {
    name: "Vedett Blond",
    vatRate: VatRate.STANDARD
  },
  "cab788e7-608f-440b-a6bc-2d0205252ce9": {
    name: "Vedett IPA",
    vatRate: VatRate.STANDARD
  },
  "aed09c12-59f4-4fb3-8245-67060e806af8": {
    name: "Vedett Playa",
    vatRate: VatRate.STANDARD
  },
  "68d262e7-29d8-4e26-a8f6-08662ee14d2b": {
    name: "Vedett Session IPA",
    vatRate: VatRate.STANDARD
  },
  "17cbcded-4c86-42d1-9e98-72c7429ebd31": {
    name: "Vedett White",
    vatRate: VatRate.STANDARD
  },
  "d3c7f051-9865-40a5-8394-a70158ec5bfe": {
    name: "Whiskey Barrel stout",
    vatRate: VatRate.STANDARD
  },
  "eccfa045-a722-4d82-aad6-76939ae26b6e": {
    name: "Wild Jo",
    vatRate: VatRate.STANDARD
  },
  "c1d6da07-3819-46d9-8f7e-ab34900e0016": {
    name: "Cécémel",
    vatRate: VatRate.REDUCED
  },
  "0489c795-ec6d-4ea1-a730-27a3586944f5": {
    name: "Cécémel met slagroom",
    vatRate: VatRate.REDUCED
  },
  "f3ac2551-ae03-4251-b03f-93c0bd116e2e": {
    name: "Cécémel warm",
    vatRate: VatRate.REDUCED
  },
  "5f021f91-0eff-48d7-b57d-94e97b4d61c6": {
    name: "Fristi",
    vatRate: VatRate.REDUCED
  },
  "fff5adee-4882-45df-8495-34a59e57f512": {
    name: "Aquarius Orange",
    vatRate: VatRate.REDUCED
  },
  "faa7116f-21f6-4be3-90d1-75cb6e963b83": {
    name: "Bio appel-kers de drie Wilgen",
    vatRate: VatRate.REDUCED
  },
  "0cf90765-44c0-4cf0-8fc9-33ebd52b4e2a": {
    name: "Bio Appelsap De Drie Wilgen",
    vatRate: VatRate.REDUCED
  },
  "81b8ca56-2d4c-430f-ac90-e61521d9df78": {
    name: "BOS Ice Tea Lime & Ginger",
    vatRate: VatRate.REDUCED
  },
  "a9db4390-39e4-4a06-a17e-f5199c580a49": {
    name: "BOS Ice Tea Peach",
    vatRate: VatRate.STANDARD
  },
  "89608d6b-a1f4-4797-a48a-f1837991305c": {
    name: "Bru bruis 0,5l",
    vatRate: VatRate.REDUCED
  },
  "5e36e3ce-77db-48d2-befb-2ff9b5f60586": {
    name: "Bru bruis 1l",
    vatRate: VatRate.REDUCED
  },
  "2abe85c9-73b7-490f-99be-afa12d010163": {
    name: "Bru bruis 25cl",
    vatRate: VatRate.REDUCED
  },
  "27dcb725-e474-474d-b2c3-ac5ebd586b62": {
    name: "Bru plat 0,5l",
    vatRate: VatRate.REDUCED
  },
  "2fb05267-3d71-4a35-85a1-8c6d7d4f411f": {
    name: "Bru plat 1l",
    vatRate: VatRate.REDUCED
  },
  "c160aada-b453-4b66-817f-919ecf43e793": {
    name: "Bru plat 25cl",
    vatRate: VatRate.REDUCED
  },
  "15e638b3-d9b0-4379-8a15-eff27ec97112": {
    name: "Club Maté",
    vatRate: VatRate.REDUCED
  },
  "bed32c1a-5e13-41e0-9507-fe22b7cdc968": {
    name: "Eaulala Bruis",
    vatRate: VatRate.REDUCED
  },
  "48210657-f6e8-4497-bc57-5b29e6338c64": {
    name: "eaulala plat",
    vatRate: VatRate.REDUCED
  },
  "3e1e5835-6e1f-4b22-b28d-94b61978da1e": {
    name: "Franklin Ginger Beer",
    vatRate: VatRate.REDUCED
  },
  "6efb6d6b-f921-4db5-a4d6-333994d35f06": {
    name: "Franklin Tonic Water",
    vatRate: VatRate.REDUCED
  },
  "5a8cb33c-cc08-465b-9f98-d5114712bd51": {
    name: "Fritz Limo Rabarber",
    vatRate: VatRate.STANDARD
  },
  "48636144-93da-44df-8232-f2435e916a18": {
    name: "Fritz-Kola",
    vatRate: VatRate.REDUCED
  },
  "8e6739fd-e567-4183-b07e-3817c18f436d": {
    name: "Fritz-Kola Suikervrij",
    vatRate: VatRate.REDUCED
  },
  "b6cbd661-3eb6-4365-a220-f1a42a96f29b": {
    name: "Fritz-Limo Apple-Cherry-Elderberry",
    vatRate: VatRate.STANDARD
  },
  "e8c1b179-f3c7-46be-a5a7-b7a545281b9d": {
    name: "Fritz-Limo Citroenlimonade",
    vatRate: VatRate.REDUCED
  },
  "28859f6f-ae32-4864-91d1-2d48fcd57fd0": {
    name: "Fritz-Limo Sinaasappellimonade",
    vatRate: VatRate.REDUCED
  },
  "2c6e3212-fc3d-45f6-bce9-a92dd681e606": {
    name: "gerolsteiner orange",
    vatRate: VatRate.REDUCED
  },
  "4f73c54c-8180-4051-9389-e99ba5aa94c5": {
    name: "gerolsteiner plat",
    vatRate: VatRate.REDUCED
  },
  "1329c982-b15e-4640-91d0-43dd3e136bdf": {
    name: "gerolsteiner spuit",
    vatRate: VatRate.REDUCED
  },
  "c4deacc4-426b-4005-bc3c-d92cc1ad9e5f": {
    name: "Gini",
    vatRate: VatRate.REDUCED
  },
  "a7662476-2915-4467-b0d3-c268560e9fef": {
    name: "Kraantjeswater",
    vatRate: VatRate.STANDARD
  },
  "bb761d46-15f1-4e7d-bfa9-5437793ccba4": {
    name: "Lipton Ice tea Zero",
    vatRate: VatRate.STANDARD
  },
  "89c172b4-0a02-452a-8329-2b45d35fe082": {
    name: "Looza Banaan",
    vatRate: VatRate.REDUCED
  },
  "2892aabd-17e9-4185-ac14-0c394d27a588": {
    name: "Looza Tomaat",
    vatRate: VatRate.REDUCED
  },
  "4b0fe8d0-9e2c-4c00-a8b2-a62521ba83a8": {
    name: "Mangajo Acai-Berry & Green Tea",
    vatRate: VatRate.STANDARD
  },
  "55502bee-bab3-41f3-9a02-2c634e3b9c46": {
    name: "Mangajo Goji-Berry & Green Tea",
    vatRate: VatRate.STANDARD
  },
  "71d3a082-b392-42e4-bc61-a2d8c249bebd": {
    name: "Mangajo Lemon & Green Tea",
    vatRate: VatRate.STANDARD
  },
  "434256a0-6210-440d-b115-180759e3fb45": {
    name: "Mangajo Pomegrenate & Green Tea",
    vatRate: VatRate.STANDARD
  },
  "9e24ba59-0a38-427e-bed0-60d089ab1e80": {
    name: "mirinda orange",
    vatRate: VatRate.REDUCED
  },
  "bb4316e9-e646-4b94-b5b4-20ee376752ab": {
    name: "Oasis Tropical",
    vatRate: VatRate.STANDARD
  },
  "be2d876d-b9e0-401d-9147-72424a7709e2": {
    name: "Ordal Bruiswater",
    vatRate: VatRate.STANDARD
  },
  "e3ab0f6b-ab01-4eab-bf24-a4a3ea9c0cfd": {
    name: "ordal orange",
    vatRate: VatRate.STANDARD
  },
  "78d003cc-8b91-4877-a47d-b94a8f5b5989": {
    name: "Oxfam Appelsap",
    vatRate: VatRate.REDUCED
  },
  "2a4ace54-3b46-48d4-a77e-9f7afc1aaef5": {
    name: "Oxfam Sinaasappelsap",
    vatRate: VatRate.REDUCED
  },
  "7f8e3192-dab6-4236-82b3-f0ad4a55ac5f": {
    name: "Oxfam Worldshake",
    vatRate: VatRate.REDUCED
  },
  "cf067af7-db31-4196-926c-7acae0f528b4": {
    name: "Ritchie Citroen-Framboos",
    vatRate: VatRate.REDUCED
  },
  "10878bc5-9297-448a-b956-4964ff6e639d": {
    name: "Ritchie Pompelmoes-Ananas",
    vatRate: VatRate.REDUCED
  },
  "1be0ff89-8167-4bc1-884a-6194b71eb467": {
    name: "San Pellegrino Aranciata",
    vatRate: VatRate.REDUCED
  },
  "7d8f5a23-c699-40eb-9c11-ef27b163998b": {
    name: "San Pellegrino Aranciata Rossa",
    vatRate: VatRate.REDUCED
  },
  "ef12bd27-7dbd-4295-990d-94d2af028ba9": {
    name: "San Pellegrino Limonata",
    vatRate: VatRate.REDUCED
  },
  "915208bd-7a78-431a-b6fe-35e7c58b1cb0": {
    name: "San Pellegrino Sparkling Water 1 liter",
    vatRate: VatRate.REDUCED
  },
  "bcf666cb-82ee-4b31-a2a4-945df5fbe3c4": {
    name: "San Pellegrino Sparkling Water 0.5 liter",
    vatRate: VatRate.REDUCED
  },
  "d52177a9-9a68-4e03-811a-fd473bba9c38": {
    name: "Thomas Henry Ginger Beer",
    vatRate: VatRate.REDUCED
  },
  "f8f6381e-2fd3-4651-9800-d419b86db641": {
    name: "Thomas Henry Pink Grapefruit",
    vatRate: VatRate.REDUCED
  },
  "bd5f88b5-4e3f-4e8d-bb9a-2f1cd0b59771": {
    name: "Thomas Henry Tonic Water",
    vatRate: VatRate.REDUCED
  },
  "3b6f70b4-610a-40da-bd7b-2599e6fba17b": {
    name: "Tönissteiner",
    vatRate: VatRate.REDUCED
  },
  "3b7f0cac-967a-473c-ad67-fcb2cc83a533": {
    name: "Tönissteiner Citroen",
    vatRate: VatRate.REDUCED
  },
  "9cf691c4-259c-40a8-8c04-3c796e05d3b2": {
    name: "Tönissteiner naranja",
    vatRate: VatRate.STANDARD
  },
  "bb901b97-8454-40f3-91a6-db77a7425aed": {
    name: "Tönissteiner Orange",
    vatRate: VatRate.REDUCED
  },
  "429c768f-50eb-496f-9111-3f521e70c79e": {
    name: "Tönissteiner Vruchtenkorf",
    vatRate: VatRate.REDUCED
  },
  "76b6e5a2-f181-49dc-8e08-90d7cd77618c": {
    name: "Val bitter lemon",
    vatRate: VatRate.REDUCED
  },
  "bb66f4eb-eadf-458f-ba89-879e9b5fd07d": {
    name: "Val bruisend",
    vatRate: VatRate.REDUCED
  },
  "2a93edc9-2d40-4cbd-8006-819d72de3010": {
    name: "Val citroen",
    vatRate: VatRate.REDUCED
  },
  "a074b9db-a9c5-428e-b553-d79994225987": {
    name: "Val Lime",
    vatRate: VatRate.REDUCED
  },
  "1dbf6413-34c9-4f0b-8897-3571f9b599d4": {
    name: "Val orange",
    vatRate: VatRate.REDUCED
  },
  "9689233c-09f9-426c-9a7d-2a8a7869f67d": {
    name: "VAL Water",
    vatRate: VatRate.REDUCED
  },
  "3404f8e1-22e1-4ade-8ef5-ca60236f6d4d": {
    name: "Beefeater",
    vatRate: VatRate.STANDARD
  },
  "3627984b-ceb3-4623-8475-df122ded3a93": {
    name: "Bobby's Gin",
    vatRate: VatRate.STANDARD
  },
  "a45225c2-5c43-437d-a03d-43e7d574a392": {
    name: "Bombay Bramble",
    vatRate: VatRate.STANDARD
  },
  "6afcad34-fcfc-4d30-a7b6-63e11d906c3d": {
    name: "Bombay Dry Gin",
    vatRate: VatRate.STANDARD
  },
  "075023a7-45f9-4f40-be1d-c7e0de840870": {
    name: "Bombay Sapphire",
    vatRate: VatRate.STANDARD
  },
  "ce4d13d2-9bed-4a7a-a2db-584b1fc2fb51": {
    name: "Bombay Star of Bombay",
    vatRate: VatRate.STANDARD
  },
  "f8d4254b-36bf-46bb-a81c-cedc05fde6cb": {
    name: "brockmans gin",
    vatRate: VatRate.STANDARD
  },
  "a6b4a86e-5004-4c66-afca-4ccb07cedcae": {
    name: "Bulldog Gin",
    vatRate: VatRate.STANDARD
  },
  "bffe7f14-eb52-4c15-9a16-016042ad481e": {
    name: "Buss N°509 Apple Gin",
    vatRate: VatRate.STANDARD
  },
  "ca24e474-d415-4564-8e69-e300cc464aca": {
    name: "Buss N°509 Cherry Gin",
    vatRate: VatRate.STANDARD
  },
  "09683324-8375-43db-a388-27a9d59a5c05": {
    name: "Buss N°509 Elderflower Gin",
    vatRate: VatRate.STANDARD
  },
  "69e04acd-bd95-417a-9271-2e21e0e2faef": {
    name: "Buss N°509 Grapefruit Gin",
    vatRate: VatRate.STANDARD
  },
  "b373caf9-1f63-454c-934a-b2a7612376fe": {
    name: "Buss N°509 Raspberry Gin",
    vatRate: VatRate.STANDARD
  },
  "4ad08732-3621-4923-b0b9-5f86d1a3e30f": {
    name: "churchill gin",
    vatRate: VatRate.STANDARD
  },
  "5d45b358-de82-491e-b99b-fd92cf0d7b88": {
    name: "Copperhead",
    vatRate: VatRate.STANDARD
  },
  "ecb58f81-6b11-430d-b8c4-9dc476456c74": {
    name: "Dunes grand prix Gin",
    vatRate: VatRate.STANDARD
  },
  "a4c695c7-3ae0-4ba3-a444-a6956d3668f3": {
    name: "Elephant Gin",
    vatRate: VatRate.STANDARD
  },
  "c1a6620d-671b-42e9-8f85-6c4a8b3d6bed": {
    name: "Filliers dry gin 28",
    vatRate: VatRate.STANDARD
  },
  "442bd506-bb69-49b3-a8be-a1019d57311c": {
    name: "gillemore gin",
    vatRate: VatRate.STANDARD
  },
  "570116a9-d463-4f5d-b11e-4af06c5c2562": {
    name: "Gordon's London Dry Gin",
    vatRate: VatRate.STANDARD
  },
  "12b3d06e-9322-45f6-a45f-f249bea38b4d": {
    name: "Gordon's Premium Pink",
    vatRate: VatRate.STANDARD
  },
  "4c0f6054-113f-47c9-963e-4c0b350f6191": {
    name: "haswell gin",
    vatRate: VatRate.STANDARD
  },
  "750e007c-ca14-40a4-b4df-d900d9614bd6": {
    name: "Hendrick's Gin",
    vatRate: VatRate.STANDARD
  },
  "33dd912c-0b33-41c1-9300-5e0a0c6ed704": {
    name: "HTK Gin",
    vatRate: VatRate.STANDARD
  },
  "9ca72a5e-72f8-4c00-832d-e2d2168bf1f6": {
    name: "Mare Gin",
    vatRate: VatRate.STANDARD
  },
  "fc446edb-05cd-4889-a07a-221d800107d1": {
    name: "Marula",
    vatRate: VatRate.STANDARD
  },
  "451c600c-8eb2-4704-8080-01c94b3ea3c6": {
    name: "Matterhorn Alpin Fresh",
    vatRate: VatRate.STANDARD
  },
  "d0765d07-0c12-46ec-9f71-da04f334f8df": {
    name: "matterhorn gin",
    vatRate: VatRate.STANDARD
  },
  "16164483-5e41-4d79-96fc-3542d587bf07": {
    name: "Monkey 47",
    vatRate: VatRate.STANDARD
  },
  "2be6c3e4-b877-43dd-b91f-d10e0fcd31de": {
    name: "Nona Gin NON Alcoholic",
    vatRate: VatRate.REDUCED
  },
  "b860370b-54f2-42c9-b2b7-cc59c76a7ed0": {
    name: "nordes gin",
    vatRate: VatRate.STANDARD
  },
  "32995187-cb61-4869-b9d5-1cab08a9f46f": {
    name: "spring gin",
    vatRate: VatRate.STANDARD
  },
  "6280f5c0-9b1c-477f-b3e9-42b3422c0a0b": {
    name: "Tanqueray Flor De Sevilla",
    vatRate: VatRate.STANDARD
  },
  "6b36ca2e-bea8-4084-843c-a44bdea5e843": {
    name: "Tanqueray London Dry Gin",
    vatRate: VatRate.STANDARD
  },
  "6b207b1a-aa0b-4896-b937-3e4a5c8d179f": {
    name: "Tanqueray Rangpur Gin",
    vatRate: VatRate.STANDARD
  },
  "fe6bac0e-295b-4515-96e1-d1debb66ff5a": {
    name: "Tanqueray Ten",
    vatRate: VatRate.STANDARD
  },
  "dad9fb5a-bde9-4b4f-bc7e-1a01c45ef714": {
    name: "Wilderen Double You Gin",
    vatRate: VatRate.STANDARD
  },
  "80beff87-a57b-4944-befa-1f384f8ffa1c": {
    name: "x-gin",
    vatRate: VatRate.STANDARD
  },
  "4047ce1e-4421-43d6-826d-2c37b4a884da": {
    name: "Gruut Amber",
    vatRate: VatRate.STANDARD
  },
  "3d1dfb21-2009-4cbb-b824-eccf1fd2ae7a": {
    name: "Gruut Blond",
    vatRate: VatRate.STANDARD
  },
  "130bef84-6d5c-462c-83a6-98f19c721043": {
    name: "Gruut Bruin",
    vatRate: VatRate.STANDARD
  },
  "5ff9aa77-19cd-4199-a30b-28f0d7c22210": {
    name: "Gruut Inferno",
    vatRate: VatRate.STANDARD
  },
  "0007feeb-85ec-487a-ba60-9ade8ff1982b": {
    name: "Gruut Wit",
    vatRate: VatRate.STANDARD
  },
  "02649645-198f-4e31-8405-fd443e8534a8": {
    name: "Gildenbier",
    vatRate: VatRate.STANDARD
  },
  "4109d648-96e3-4329-88e1-836fafb34680": {
    name: "Keizer Karel Goud Blond",
    vatRate: VatRate.STANDARD
  },
  "e1af5d7e-7659-49e5-8647-bc0b8030ac29": {
    name: "Keizer Karel Ommegang",
    vatRate: VatRate.STANDARD
  },
  "301d0cef-c295-401b-86c2-7b42fa3f8219": {
    name: "Keizer Karel Robijn Rood",
    vatRate: VatRate.STANDARD
  },
  "54b63330-4795-41df-97d7-186226f97fb5": {
    name: "Mystic Kriek",
    vatRate: VatRate.STANDARD
  },
  "87984c0b-0005-4a07-a7f0-f09a0f5ca050": {
    name: "Primus",
    vatRate: VatRate.STANDARD
  },
  "a1bf4bcb-f771-497d-8c57-c6739cce2bcc": {
    name: "Super 8 Blanche",
    vatRate: VatRate.STANDARD
  },
  "a75aac41-5480-4811-a9a5-2eff786c322d": {
    name: "Super 8 Export",
    vatRate: VatRate.STANDARD
  },
  "8624aff5-41bc-464d-9d4d-c7ada6b007ad": {
    name: "Super 8 IPA",
    vatRate: VatRate.STANDARD
  },
  "1a4cc2f1-b191-4bfb-b093-88dc9c783dc9": {
    name: "Super 8 Saison",
    vatRate: VatRate.STANDARD
  },
  "533eaccc-90b0-415e-8045-1749eb4a9d7d": {
    name: "Tongerlo Blond",
    vatRate: VatRate.STANDARD
  },
  "0c798efe-9563-49b8-ad5d-52a1da1260e1": {
    name: "Tongerlo Christmas",
    vatRate: VatRate.STANDARD
  },
  "bb111df7-7fe6-435f-be59-796059625589": {
    name: "Tongerlo Prior",
    vatRate: VatRate.STANDARD
  },
  "056fe2bc-095b-42e8-9de0-53bb10b018b0": {
    name: "Boscoli",
    vatRate: VatRate.STANDARD
  },
  "135f72b2-3910-496d-82ef-a082594f9cca": {
    name: "Gouden Carolus Ambrio",
    vatRate: VatRate.STANDARD
  },
  "93b0f1ba-c423-4b07-af53-2bfe0e4f497c": {
    name: "Gouden Carolus Christmas",
    vatRate: VatRate.STANDARD
  },
  "a675fa1f-7f04-4c38-a199-1c6dc4ce4bad": {
    name: "Gouden Carolus Classic",
    vatRate: VatRate.STANDARD
  },
  "8221510b-3ac8-4bb2-a328-8f53a416fe0b": {
    name: "Gouden Carolus Hopsinjoor",
    vatRate: VatRate.STANDARD
  },
  "47fe8196-b08c-4781-a091-6517c93b931b": {
    name: "Gouden Carolus Imperial Blond",
    vatRate: VatRate.STANDARD
  },
  "79fa3273-204e-4778-a8d3-7ec639bceda7": {
    name: "Gouden Carolus Imperial Dark",
    vatRate: VatRate.STANDARD
  },
  "46de91f0-f307-4d41-b3f8-59067513574c": {
    name: "Gouden Carolus Indulgence",
    vatRate: VatRate.STANDARD
  },
  "772ddaa5-afe7-48c8-baa5-2de7e69c2a90": {
    name: "Gouden Carolus Tripel",
    vatRate: VatRate.STANDARD
  },
  "819728ca-48e7-44e3-b55c-8e9f4dc1243d": {
    name: "Gouden Carolus ULTRA",
    vatRate: VatRate.STANDARD
  },
  "d9de1031-62f8-44f5-b97e-0a8b8ea686d7": {
    name: "Gouden Carolus Whisky Infused",
    vatRate: VatRate.STANDARD
  },
  "9244ba0a-9fa5-499c-b12c-09cc29e9c118": {
    name: "Maneblusser",
    vatRate: VatRate.STANDARD
  },
  "fbf36a69-a4ce-44e3-9ced-2b8dde35bac5": {
    name: "Maneblusser Wit",
    vatRate: VatRate.STANDARD
  },
  "ceacb93f-cb33-4cf1-a191-9d5555203932": {
    name: "Peterman Graanjenever",
    vatRate: VatRate.STANDARD
  },
  "de777c6f-514f-4b08-9882-00433710198a": {
    name: "Smeets Jenever Chocolade",
    vatRate: VatRate.STANDARD
  },
  "f03b2bb1-f171-4e3e-a871-1baa91d9416e": {
    name: "Smeets jenever vanille",
    vatRate: VatRate.STANDARD
  },
  "74a1d28a-107e-4340-a27f-133dd1518674": {
    name: "Almdudler",
    vatRate: VatRate.REDUCED
  },
  "5cec0480-d806-4d2c-8e07-180b70d0d5d4": {
    name: "Bionade Ginger",
    vatRate: VatRate.REDUCED
  },
  "8fefb9d9-e30b-4625-932b-dfb65e286fbc": {
    name: "Bionade Lemon",
    vatRate: VatRate.REDUCED
  },
  "4a32f1f4-dcaf-480f-8131-b04ef2fb5ff2": {
    name: "Bionade Lychee",
    vatRate: VatRate.REDUCED
  },
  "af649c2f-d131-44b5-96e7-b5b75bed2ead": {
    name: "Bionade Vlierbes",
    vatRate: VatRate.REDUCED
  },
  "72c8facc-8ccd-4a07-a461-b763a585742d": {
    name: "Fever Tree",
    vatRate: VatRate.STANDARD
  },
  "746a86a5-f4ef-4c2c-a739-ce1f5401845e": {
    name: "Fever-Tree  Tonic Citrus",
    vatRate: VatRate.REDUCED
  },
  "9fa77306-e7b2-4f47-b15c-3d0ed8410d49": {
    name: "Fever-tree clementine",
    vatRate: VatRate.REDUCED
  },
  "891beef9-033c-4213-a59c-9b4df98d6980": {
    name: "Fever-Tree Ginger Ale",
    vatRate: VatRate.REDUCED
  },
  "ae5a988d-1b1a-4a3c-a8b3-e775d5404e4d": {
    name: "Fever-Tree Ginger Beer",
    vatRate: VatRate.REDUCED
  },
  "cc238212-be4f-4d3d-a7a9-7af7ce8f00cb": {
    name: "Fever-Tree Orange Ginger Ale",
    vatRate: VatRate.REDUCED
  },
  "cd395c36-55a4-4bdd-8412-9025a1e6a7fd": {
    name: "Fever-Tree Sparkling Lemon",
    vatRate: VatRate.REDUCED
  },
  "e0167c25-2895-4ba0-9806-3ad8bdc9187c": {
    name: "Fever-Tree Tonic Aromatic",
    vatRate: VatRate.REDUCED
  },
  "421370cb-3c35-410f-a760-4a4cf6abff81": {
    name: "Fever-Tree Tonic Elderflower",
    vatRate: VatRate.REDUCED
  },
  "ec8e3390-c42a-4e00-b765-b0d41ec9652a": {
    name: "Fever-Tree Tonic Indian",
    vatRate: VatRate.REDUCED
  },
  "e1cdc802-33c5-4f9c-a2e0-291376307767": {
    name: "Fever-Tree Tonic Lemon",
    vatRate: VatRate.REDUCED
  },
  "f160e433-f091-4d8e-99a8-43ed3347d445": {
    name: "Fever-Tree Tonic Light",
    vatRate: VatRate.REDUCED
  },
  "516ff2f4-544b-486a-be8c-7ec5f1e40a62": {
    name: "Fever-Tree Tonic Light Cucumber",
    vatRate: VatRate.REDUCED
  },
  "639ed0b6-9df9-47bc-b9a6-fb2c324250d5": {
    name: "Fever-Tree Tonic Mediterranean",
    vatRate: VatRate.REDUCED
  },
  "8c092bbc-6c70-45da-abec-948c40629879": {
    name: "Red Bull",
    vatRate: VatRate.REDUCED
  },
  "76f4ad4d-0c7e-4f8d-8228-0344a9a17f55": {
    name: "Red Bull Blue",
    vatRate: VatRate.REDUCED
  },
  "28238ec3-1546-4ffd-b71b-55efdfa1995c": {
    name: "Red Bull Green",
    vatRate: VatRate.REDUCED
  },
  "0ccdbc4d-da7b-4a2d-a836-6889671956bf": {
    name: "Red Bull Sugarfree",
    vatRate: VatRate.REDUCED
  },
  "f7c01589-3814-4fe2-b9f8-9bed7038957e": {
    name: "Red Bull White",
    vatRate: VatRate.REDUCED
  },
  "d991cab2-b25a-4523-9b65-0e2678dba7e8": {
    name: "Red Bull Yellow",
    vatRate: VatRate.REDUCED
  },
  "3320f6b3-2589-4ab4-bf89-6c3960e7ae6c": {
    name: "Bacchus Frambozenbier",
    vatRate: VatRate.STANDARD
  },
  "e6c7024f-b417-4c09-aca3-a3297e467cde": {
    name: "Bacchus Kriekenbier",
    vatRate: VatRate.STANDARD
  },
  "bdb658b5-2c3f-43f6-860e-8f5af06d9b3d": {
    name: "Bacchus Vlaams Oud Bruin",
    vatRate: VatRate.STANDARD
  },
  "6294cde5-9186-468e-8f89-4fa4e76aad22": {
    name: "Barista Chocolate Quad",
    vatRate: VatRate.STANDARD
  },
  "d53ba9a4-6089-4460-846b-233ded4fc04d": {
    name: "Brigand",
    vatRate: VatRate.STANDARD
  },
  "597b2be7-c782-4249-8dcf-636a35fb6974": {
    name: "Cuvée du Château",
    vatRate: VatRate.STANDARD
  },
  "b792264f-e9ed-4ba3-b929-7a143301e227": {
    name: "Filou",
    vatRate: VatRate.STANDARD
  },
  "e35b6a9a-9e06-4e0e-8931-091880772451": {
    name: "Kasteel Blond",
    vatRate: VatRate.STANDARD
  },
  "a308a398-d5f4-48ad-a28c-b105f62fde37": {
    name: "Kasteel Donker",
    vatRate: VatRate.STANDARD
  },
  "83aa0903-ac63-495b-80f6-52a14674f4bd": {
    name: "Kasteel Hoppy",
    vatRate: VatRate.STANDARD
  },
  "1f5eee32-105c-4f6c-a57e-aaecfc09ee1f": {
    name: "Kasteel Rouge",
    vatRate: VatRate.STANDARD
  },
  "b27c49c2-9005-42a9-b426-8a65556ba1a7": {
    name: "Kasteel Tripel",
    vatRate: VatRate.STANDARD
  },
  "4cb9803b-6705-4200-af07-b9198fcad094": {
    name: "Paschendaele Porter",
    vatRate: VatRate.STANDARD
  },
  "8668e460-96bc-447b-a7a4-0db25e2086e7": {
    name: "Passchendaele Blond",
    vatRate: VatRate.STANDARD
  },
  "bb25db94-cbb2-44c4-97a6-6bd675c0518a": {
    name: "Passchendaele Ginger White",
    vatRate: VatRate.STANDARD
  },
  "23ed805a-a9d6-43e7-8b7c-4964eb3f2bd7": {
    name: "Slurfke Blond",
    vatRate: VatRate.STANDARD
  },
  "7f3f447b-2e1c-49b9-82f4-a3d4a175817a": {
    name: "Slurfke Bruin",
    vatRate: VatRate.STANDARD
  },
  "140aefc0-a401-4d24-9477-388edd58bb64": {
    name: "Slurfke Wit",
    vatRate: VatRate.STANDARD
  },
  "2600b4c3-1e43-4aa1-b00d-6f6efacbdd50": {
    name: "St-Louis Gueuze Fond Tradition",
    vatRate: VatRate.STANDARD
  },
  "f02bfd04-584b-4a7f-8379-40ddf19d9daf": {
    name: "St-Louis Kriek Fond Tradition",
    vatRate: VatRate.STANDARD
  },
  "c4bdbc79-8f57-493e-8fd6-86b36bd247a4": {
    name: "St-Louis Kriek Lambic",
    vatRate: VatRate.STANDARD
  },
  "f61cc8d6-e0c1-4a85-a3d5-b565cf901552": {
    name: "St-Louis Premium Framboise",
    vatRate: VatRate.STANDARD
  },
  "c5dd9c9c-e85c-424f-bb5c-cf8bf277e567": {
    name: "St-Louis Premium Gueuze",
    vatRate: VatRate.STANDARD
  },
  "8252ddff-0be4-4a40-be71-0fe7405cbe2a": {
    name: "St-Louis Premium Kriek",
    vatRate: VatRate.STANDARD
  },
  "13593553-d4ba-4810-9a3a-79a689cbc84c": {
    name: "St-Louis Premium Peche",
    vatRate: VatRate.STANDARD
  },
  "07a41e1b-25b0-42de-ad20-6e246f277ceb": {
    name: "Trignac XII",
    vatRate: VatRate.STANDARD
  },
  "7511db4f-a14d-4395-858c-0e9879e5a5d6": {
    name: "La Trappe Blond",
    vatRate: VatRate.STANDARD
  },
  "72287a1f-4a38-4f79-b8e6-907aa80dd67e": {
    name: "La Trappe Bockbier",
    vatRate: VatRate.STANDARD
  },
  "31782b75-a1c6-4cb0-82a2-2862c89f413a": {
    name: "La Trappe Dubbel",
    vatRate: VatRate.STANDARD
  },
  "d8fa75db-e73d-40fc-8e90-783fa97274bc": {
    name: "La Trappe Isid´or",
    vatRate: VatRate.STANDARD
  },
  "20fffb61-65ad-4628-b30a-c68e6caa91bf": {
    name: "La Trappe Oak Aged",
    vatRate: VatRate.STANDARD
  },
  "fc83c03e-5107-43c5-9e08-8b2e7b8a3c90": {
    name: "La Trappe Puur",
    vatRate: VatRate.STANDARD
  },
  "1c61b8e4-152d-41a1-8e31-2d181665a563": {
    name: "La Trappe Quadrupel",
    vatRate: VatRate.STANDARD
  },
  "30096f22-8957-4355-830c-1fb1ed09215a": {
    name: "La Trappe Tripel",
    vatRate: VatRate.STANDARD
  },
  "3213096f-14f4-4819-b393-66116c91d846": {
    name: "La Trappe Witte Trappist",
    vatRate: VatRate.STANDARD
  },
  "6c3a841c-9ea1-4638-8b6f-a383371e9a84": {
    name: "Barbãr Blond",
    vatRate: VatRate.STANDARD
  },
  "1e7c84ff-c60a-4923-a163-0df50cfb48bb": {
    name: "Barbãr Bok",
    vatRate: VatRate.STANDARD
  },
  "4b26a8bd-271b-4440-aadc-11aa47567621": {
    name: "Barbãr Rouge",
    vatRate: VatRate.STANDARD
  },
  "5b9a36e8-3f53-4aa9-826d-06309a43d20a": {
    name: "Belgian Kriek",
    vatRate: VatRate.STANDARD
  },
  "044dc45b-c216-458f-8ed9-47584673d731": {
    name: "Blanche De Bruxelles",
    vatRate: VatRate.STANDARD
  },
  "6c64073d-d010-4b1a-993d-31484e73f3f5": {
    name: "Blanche De Bruxelles Rosée",
    vatRate: VatRate.STANDARD
  },
  "bc04cd4f-09b3-45c4-a602-92558a077ff1": {
    name: "Floreffe Blond",
    vatRate: VatRate.STANDARD
  },
  "28e0249c-fc0b-4e5b-abb3-26295441bd0d": {
    name: "Floreffe Dubbel",
    vatRate: VatRate.STANDARD
  },
  "b40c9419-581d-48ec-933c-cb30d06bcfc4": {
    name: "Floreffe Prima Melior",
    vatRate: VatRate.STANDARD
  },
  "640c510b-7bcc-48d7-be77-5269e5cfbfea": {
    name: "Floreffe Tripel",
    vatRate: VatRate.STANDARD
  },
  "585fefbf-cbf8-4043-b11b-15286a384b6a": {
    name: "Hopus",
    vatRate: VatRate.STANDARD
  },
  "13116dd3-5d23-4fa0-9991-64a3d665fa49": {
    name: "Hopus Primeur",
    vatRate: VatRate.STANDARD
  },
  "c0afacfe-20b7-434c-b8d3-4db75ddfa036": {
    name: "Manneken Pils",
    vatRate: VatRate.STANDARD
  },
  "37be0c9f-9642-4da4-abde-17959b2a5118": {
    name: "Moeder Overste",
    vatRate: VatRate.STANDARD
  },
  "602d3e86-a66f-43c6-bfcc-e44dce8a5783": {
    name: "Saison 1900",
    vatRate: VatRate.STANDARD
  },
  "b93be31a-4721-48b9-aa6b-34b9e794ddf4": {
    name: "Lindemans Apple",
    vatRate: VatRate.STANDARD
  },
  "535180f8-5e3e-4869-be92-c6befc9b85df": {
    name: "Lindemans Blossom Gueuze 37.5cl",
    vatRate: VatRate.STANDARD
  },
  "60cac721-d9b9-42b2-9d0d-601f9b9cf0ef": {
    name: "Lindemans Cassis",
    vatRate: VatRate.STANDARD
  },
  "c75c23d9-094a-46e8-a5ea-679fda74040c": {
    name: "Lindemans Faro Lambic",
    vatRate: VatRate.STANDARD
  },
  "bf7d7c21-f7bc-49a9-b568-926bf35b7212": {
    name: "Lindemans Framboise 37,5cl",
    vatRate: VatRate.STANDARD
  },
  "4cfd577e-b225-4232-b2ad-88a7509b9ba0": {
    name: "Lindemans Geuze 37.5cl",
    vatRate: VatRate.STANDARD
  },
  "a0de5a8d-737e-4b05-8a9c-a7e3355a46a3": {
    name: "Lindemans Ginger Gueuze 37.5cl",
    vatRate: VatRate.STANDARD
  },
  "61726fc1-6b79-4884-858f-381bd7bed784": {
    name: "Lindemans Kriek",
    vatRate: VatRate.STANDARD
  },
  "cfc8ddcf-691c-4255-ad23-32242a0d0598": {
    name: "Lindemans Oude Gueuze Cuvée René",
    vatRate: VatRate.STANDARD
  },
  "5e73029c-f000-46ac-9f80-74b5ccd8d9be": {
    name: "Lindemans Oude Kriek Cuvée René 37.5cl",
    vatRate: VatRate.STANDARD
  },
  "604a7861-cfb8-4866-a681-796ed11b25f1": {
    name: "Lindemans Pecheresse",
    vatRate: VatRate.STANDARD
  },
  "92363e19-6301-4c88-98c2-dc0a0f70b1b6": {
    name: "Lindemans Spontan Basil 37.5cl",
    vatRate: VatRate.STANDARD
  },
  "2361bf6e-de6c-4298-a24c-d543965b2cda": {
    name: "Blauw Export Bier",
    vatRate: VatRate.STANDARD
  },
  "56ce6fdc-2668-4676-9505-b83693a053f3": {
    name: "Bockor",
    vatRate: VatRate.STANDARD
  },
  "20a548e5-35ad-4e59-9b20-f19797f8714b": {
    name: "Cuvée Des Jacobins",
    vatRate: VatRate.STANDARD
  },
  "ea231be4-dee4-4a7d-a5eb-7ddcc2a38ee2": {
    name: "Kriek Max",
    vatRate: VatRate.STANDARD
  },
  "08d418c6-0723-4718-a05d-c3d093aa3d7e": {
    name: "LeFort",
    vatRate: VatRate.STANDARD
  },
  "4af48743-661d-4b99-a1dc-cc409350aef8": {
    name: "Omer",
    vatRate: VatRate.STANDARD
  },
  "262594de-defd-4ca2-835c-29ed28df760c": {
    name: "Rosé Max",
    vatRate: VatRate.STANDARD
  },
  "c97935f1-5532-4395-91c2-46046e226735": {
    name: "Tripel LeFort",
    vatRate: VatRate.STANDARD
  },
  "4cc92a0a-ca6c-4329-8052-202431e18b08": {
    name: "VanderGhinste Roodbruin",
    vatRate: VatRate.STANDARD
  },
  "2728e65a-34f6-44ec-885e-9495c5aacd3a": {
    name: "Brugge Tripel 75cl",
    vatRate: VatRate.STANDARD
  },
  "8935c3f5-35ae-4455-9571-aae213335474": {
    name: "Cornet 75cl",
    vatRate: VatRate.STANDARD
  },
  "8b6dc0ed-2975-4bc4-a7a7-4ed6d94c05b0": {
    name: "Dobbel Palm",
    vatRate: VatRate.STANDARD
  },
  "f4a64475-a589-4cc0-9b28-3bb844f765bd": {
    name: "Estaminet",
    vatRate: VatRate.STANDARD
  },
  "b75e57b4-9042-4e69-ae2c-8e0edd097af9": {
    name: "Palm",
    vatRate: VatRate.STANDARD
  },
  "d86089d2-8d3f-413b-91cf-e334605341d6": {
    name: "Palm 0,0%",
    vatRate: VatRate.REDUCED
  },
  "54b29950-8140-4da3-86d9-914b9622615a": {
    name: "Palm Hop Select",
    vatRate: VatRate.STANDARD
  },
  "91890d47-3e4a-4af9-86ed-f8167bb383c6": {
    name: "Palm Royale",
    vatRate: VatRate.STANDARD
  },
  "9f1789fa-f671-4221-82e5-c19f7afe5639": {
    name: "Rodenbach Grand Cru 75cl",
    vatRate: VatRate.STANDARD
  },
  "bf77b12a-ebd9-4087-8792-5db54f1965a0": {
    name: "7Up",
    vatRate: VatRate.REDUCED
  },
  "c6317137-b526-486e-a58a-3c4256f957bd": {
    name: "Looza Ace",
    vatRate: VatRate.REDUCED
  },
  "5f4c6685-67c0-4590-bc76-7f2135c165da": {
    name: "Looza Ananas",
    vatRate: VatRate.REDUCED
  },
  "0a38e4c7-9975-4c3d-8e84-486de39d98d1": {
    name: "Looza Appel",
    vatRate: VatRate.REDUCED
  },
  "f2f3874d-20a8-4a8a-90ff-562bae0840c3": {
    name: "Looza Appel Kers",
    vatRate: VatRate.REDUCED
  },
  "3db45a84-1a7d-4a17-9fa0-377c45c0841f": {
    name: "Looza Perzik",
    vatRate: VatRate.REDUCED
  },
  "97d1bb39-c46c-4d75-8c67-5491de113667": {
    name: "Looza Pompelmoes",
    vatRate: VatRate.REDUCED
  },
  "e82f9d34-6290-4cc2-aeba-ada72ed834e3": {
    name: "Looza Sinaasappel",
    vatRate: VatRate.REDUCED
  },
  "cca9a4f8-c732-4a63-a650-8e0411a5b306": {
    name: "Pepsi Cola",
    vatRate: VatRate.REDUCED
  },
  "c536587e-7a14-4056-8e7b-e3d34466b4ef": {
    name: "Pepsi Max",
    vatRate: VatRate.REDUCED
  },
  "864637ef-c4ff-42f4-b807-5d4498714c8a": {
    name: "Rodenbach",
    vatRate: VatRate.STANDARD
  },
  "638026f7-3d71-41ac-8179-8f3ce3ee7197": {
    name: "Rodenbach Alexander",
    vatRate: VatRate.STANDARD
  },
  "50c39395-2a2e-430c-a1ee-437f3cf48ba9": {
    name: "Rodenbach Fruitage",
    vatRate: VatRate.STANDARD
  },
  "48a77627-18f7-4cb9-9c67-602da65ccbaf": {
    name: "Rodenbach Grand Cru",
    vatRate: VatRate.STANDARD
  },
  "361aa1fd-839c-44e1-92cc-60f3437973d0": {
    name: "Rodenbach Rosso",
    vatRate: VatRate.STANDARD
  },
  "5438a4a5-a0e9-4c75-81c7-402b8c514591": {
    name: "Rodenbach Vintage",
    vatRate: VatRate.STANDARD
  },
  "743374b5-7d60-4792-bfb6-029bc526471f": {
    name: "Adriaen Brouwer",
    vatRate: VatRate.STANDARD
  },
  "21b99c35-fab9-48d8-b2bd-ccb4e18aded6": {
    name: "Adriaen Brouwer Oaked",
    vatRate: VatRate.STANDARD
  },
  "316ad72c-111b-46eb-854f-6583a57d8d9f": {
    name: "Adriaen Brouwer Tripel",
    vatRate: VatRate.STANDARD
  },
  "6d0a3a38-a31d-47da-9808-fe3b08b3050e": {
    name: "Ename Blond",
    vatRate: VatRate.STANDARD
  },
  "8ae2bcde-95b7-4983-ba94-91f8aeea9333": {
    name: "Ename Dubbel",
    vatRate: VatRate.STANDARD
  },
  "6cd33adb-fe3c-4f5d-a9ba-7ff76cd61244": {
    name: "Ename Pater",
    vatRate: VatRate.STANDARD
  },
  "09157b24-0886-40cd-8898-096e12436ee9": {
    name: "Ename Rouge",
    vatRate: VatRate.STANDARD
  },
  "9853cfcc-5bca-403e-ab8b-7bed7a22b9f1": {
    name: "Ename Tripel",
    vatRate: VatRate.STANDARD
  },
  "d56e79c4-86cc-4929-88a4-4029a483eab7": {
    name: "Gentse Strop",
    vatRate: VatRate.STANDARD
  },
  "c21371e2-49b4-43fb-b62f-b5e1f92bbeee": {
    name: "Ramon NA",
    vatRate: VatRate.REDUCED
  },
  "2d0b4bad-8894-43da-a51a-9a4614210681": {
    name: "Rebelse Strop",
    vatRate: VatRate.STANDARD
  },
  "4b5487e5-4f1d-4e64-91bd-6a3b3891b46f": {
    name: "Roman Black Label",
    vatRate: VatRate.STANDARD
  },
  "26f7b9d2-d649-4e83-a630-106db0f2fda5": {
    name: "Roman Blanche",
    vatRate: VatRate.STANDARD
  },
  "57a8b088-590a-4eb1-bcf1-34753041bcb7": {
    name: "Roman Pils",
    vatRate: VatRate.STANDARD
  },
  "50814c1b-d8c0-4ac1-8038-b9c860da9424": {
    name: "Sloeber",
    vatRate: VatRate.STANDARD
  },
  "7c16d31a-ea38-4ec9-b88f-f27124ab1dc5": {
    name: "Sloeber IPA",
    vatRate: VatRate.STANDARD
  },
  "051a15b4-5946-422c-93cf-ad5cf4d26437": {
    name: "Canada Dry",
    vatRate: VatRate.REDUCED
  },
  "405c491b-5060-4d0e-b558-a0ba3a490668": {
    name: "Orangina",
    vatRate: VatRate.REDUCED
  },
  "963b7a4e-90ff-4c3c-a59a-5a49047e86e0": {
    name: "Schweppes Agrum",
    vatRate: VatRate.STANDARD
  },
  "6b6dd00d-fdea-4b67-92e7-d08b0d37a3d7": {
    name: "Schweppes Agrum",
    vatRate: VatRate.REDUCED
  },
  "2d753482-5250-4a7d-810e-2c7de6c5e69a": {
    name: "Schweppes Bitter Lemon",
    vatRate: VatRate.REDUCED
  },
  "2cfac04a-a19b-45b3-9ade-dd6ea9b9a643": {
    name: "Schweppes Mojito",
    vatRate: VatRate.REDUCED
  },
  "d607b054-9bde-454b-82bd-1fe15015fee5": {
    name: "Schweppes Russchian",
    vatRate: VatRate.REDUCED
  },
  "1c007105-9ec7-4f7b-afff-9ddab591af1f": {
    name: "Schweppes Tonic",
    vatRate: VatRate.STANDARD
  },
  "943039f4-66ae-4ce9-b625-9fcab7fdcb55": {
    name: "St. Bernardus Abt 12",
    vatRate: VatRate.STANDARD
  },
  "d330df58-24a1-4320-8cc1-8d3ef4227ef2": {
    name: "St. Bernardus Christmas Ale",
    vatRate: VatRate.STANDARD
  },
  "46dd810e-9bab-40c1-bc9c-d849c1f9668e": {
    name: "St. Bernardus Extra 4",
    vatRate: VatRate.STANDARD
  },
  "be48a203-45fa-4b07-8529-ed60e28b0df1": {
    name: "St. Bernardus Pater 6",
    vatRate: VatRate.STANDARD
  },
  "aef13609-c0b5-43ce-97cd-89aab0662c8e": {
    name: "St. Bernardus Prior 8",
    vatRate: VatRate.STANDARD
  },
  "f1d9bab2-f9c8-4e1a-ba62-401731e4d3fa": {
    name: "St. Bernardus Tripel",
    vatRate: VatRate.STANDARD
  },
  "378e5e5c-99d3-4cba-abfa-5af5cf95d183": {
    name: "St. Bernardus Wit",
    vatRate: VatRate.STANDARD
  },
  "af715a34-5cbe-4bcc-b936-089aea7f5f55": {
    name: "Watou Tripel",
    vatRate: VatRate.STANDARD
  },
  "2d3faa82-c8ec-4947-b60b-d8c6be18b852": {
    name: "Spa Bruisend",
    vatRate: VatRate.REDUCED
  },
  "cf3ffc15-c201-4e63-832c-16028a881448": {
    name: "Spa Bruisend 1 Liter",
    vatRate: VatRate.REDUCED
  },
  "04fdd92c-4b23-4a97-9636-085a51934543": {
    name: "Spa Citron",
    vatRate: VatRate.REDUCED
  },
  "53a5eed5-4f32-4094-919e-61aa14016dce": {
    name: "Spa Orange",
    vatRate: VatRate.REDUCED
  },
  "fb42517c-b4f6-4d18-bf27-179ece28ab76": {
    name: "Spa Reine",
    vatRate: VatRate.REDUCED
  },
  "2e69bcaf-acf0-46c0-9e94-969ca3c60bfc": {
    name: "Spa Reine 1 Liter",
    vatRate: VatRate.REDUCED
  },
  "5b098a20-45a6-4780-be87-b971b8aa1ad2": {
    name: "Belgian Coast IPA",
    vatRate: VatRate.STANDARD
  },
  "c0968160-3728-40d6-a421-ffa84973a775": {
    name: "Grisette",
    vatRate: VatRate.STANDARD
  },
  "25991a64-188b-4ba6-9a9e-82c63579c25b": {
    name: "Grisette Blond Bio",
    vatRate: VatRate.STANDARD
  },
  "4b20c7bd-bb5d-49af-95b5-5d7b74f21599": {
    name: "Grisette Tripel Bio",
    vatRate: VatRate.STANDARD
  },
  "8a372483-c087-4702-aaf5-3004e888942f": {
    name: "Grisette Wit Bio",
    vatRate: VatRate.STANDARD
  },
  "02a812f8-6677-4a77-b7e7-a1ecbed9d3e8": {
    name: "St. Feuillien Blond",
    vatRate: VatRate.STANDARD
  },
  "84d0e143-abb3-4845-a0ee-53f3d6c3fbb7": {
    name: "St. Feuillien Bruin",
    vatRate: VatRate.STANDARD
  },
  "ae2388a7-73eb-4c18-bff9-abbb943bdbaf": {
    name: "St. Feuillien Cuvée de Noël",
    vatRate: VatRate.STANDARD
  },
  "c08a23b2-d0b4-4a7e-b6f3-80a35f100bf6": {
    name: "St. Feuillien Five",
    vatRate: VatRate.STANDARD
  },
  "f34e283e-0277-45c6-8795-53dfab3363dc": {
    name: "St. Feuillien Grand Cru",
    vatRate: VatRate.STANDARD
  },
  "449a93f9-599d-4cda-b8e7-181172031c02": {
    name: "St. Feuillien Quadrupel",
    vatRate: VatRate.STANDARD
  },
  "c6a09540-b972-4e44-9d74-1d874633ccb8": {
    name: "St. Feuillien Saison",
    vatRate: VatRate.STANDARD
  },
  "e0e3eb16-cfb8-4db1-a558-feeec75167ff": {
    name: "St. Feuillien Tripel",
    vatRate: VatRate.STANDARD
  },
  "799b2ce6-670f-4d31-9acf-d1436f5d5fc0": {
    name: "Baileys Coffee",
    vatRate: VatRate.STANDARD
  },
  "3828cb3a-27a8-49cd-8022-94f0f790614f": {
    name: "Cappuccino",
    vatRate: VatRate.REDUCED
  },
  "98ea03b5-6e6a-4544-8952-98d65794de8e": {
    name: "Cava",
    vatRate: VatRate.STANDARD
  },
  "7e9adc99-bf86-4d18-b4b9-716d1186ffb0": {
    name: "cava rose",
    vatRate: VatRate.STANDARD
  },
  "6d8b5074-8a8b-42e6-9632-ead21b0511c2": {
    name: "Chai Latte",
    vatRate: VatRate.REDUCED
  },
  "11692dea-6b65-44ee-88eb-aa5416a1ff09": {
    name: "Chococcino",
    vatRate: VatRate.REDUCED
  },
  "11008c05-98ef-443e-9863-1f1b7917d286": {
    name: "Coupe Champagne",
    vatRate: VatRate.STANDARD
  },
  "0964a98e-ddbe-4e13-b4fc-c3f9967cb134": {
    name: "cuba libre",
    vatRate: VatRate.STANDARD
  },
  "0651a2db-4dec-4d6d-a0ac-bd4a5eb08f44": {
    name: "Decaf Koffie",
    vatRate: VatRate.REDUCED
  },
  "34e5b89d-5d69-4f69-97ee-c0f30a80e96c": {
    name: "Dubbel Expresso",
    vatRate: VatRate.STANDARD
  },
  "510f226c-35fa-4071-8a9c-8577233de841": {
    name: "Espresso",
    vatRate: VatRate.REDUCED
  },
  "a1c0e0f3-0f6d-4eae-bd28-27de9fec39c1": {
    name: "Fles Cava",
    vatRate: VatRate.STANDARD
  },
  "80851149-d093-4637-9962-e14781fc1d54": {
    name: "Fles cava Rosé",
    vatRate: VatRate.STANDARD
  },
  "d17dfbc4-5c3b-4dd1-bb0d-b5770019de2a": {
    name: "Fles Champagne",
    vatRate: VatRate.STANDARD
  },
  "479bc381-1e56-4110-9f6a-1bc366c7d2ca": {
    name: "Fles rode wijn",
    vatRate: VatRate.STANDARD
  },
  "65f030e1-0e8c-43c4-8340-9f3119b83438": {
    name: "Fles Rosé wijn",
    vatRate: VatRate.STANDARD
  },
  "b7b464a7-ac23-4da5-91c8-e7aa0dd3e468": {
    name: "Fles witte wijn",
    vatRate: VatRate.STANDARD
  },
  "245937b4-98b3-44ba-9fca-ae3948bbd94e": {
    name: "French Coffee",
    vatRate: VatRate.STANDARD
  },
  "a9879929-4a62-4c34-bf01-f15a5b179544": {
    name: "Glühwein",
    vatRate: VatRate.STANDARD
  },
  "59035986-3a0f-4f94-80a1-08a8d73dfc02": {
    name: "Homemade Ice Tea",
    vatRate: VatRate.REDUCED
  },
  "2828b777-2181-4be8-9b12-9eb080ae45a7": {
    name: "Homemade Lemonade",
    vatRate: VatRate.REDUCED
  },
  "60445735-4820-4e9c-9127-da07884e9075": {
    name: "Hot Chocolate",
    vatRate: VatRate.STANDARD
  },
  "cc8b48af-e23c-4550-99f6-3f561a526a85": {
    name: "Hugo",
    vatRate: VatRate.STANDARD
  },
  "797b995d-5a3c-43b6-986e-08d893851fe9": {
    name: "Hugo Rosé",
    vatRate: VatRate.STANDARD
  },
  "c4b49bcc-4c8a-486c-aca0-4a23d2ca8696": {
    name: "Iced Latte",
    vatRate: VatRate.REDUCED
  },
  "cfc710c4-5b6a-422c-aa71-b00226216852": {
    name: "Irish Coffee",
    vatRate: VatRate.STANDARD
  },
  "018183ff-0ddb-49ed-97a9-00ac400a7d37": {
    name: "Italian Coffee",
    vatRate: VatRate.STANDARD
  },
  "fbb5b497-bbb6-4ff9-89d2-718733d71c25": {
    name: "Kirr",
    vatRate: VatRate.STANDARD
  },
  "53fc1d71-c205-4a74-843d-e5267fcd594e": {
    name: "Kirr Royal",
    vatRate: VatRate.STANDARD
  },
  "66658b46-99e2-4fab-a742-2953f3a5fc83": {
    name: "Koffie",
    vatRate: VatRate.REDUCED
  },
  "57192706-e726-47a4-80e9-adc83728f3c0": {
    name: "Koffie Verkeerd",
    vatRate: VatRate.REDUCED
  },
  "614c005e-8c18-492d-a712-76d3021eee06": {
    name: "Latte Macchiato",
    vatRate: VatRate.REDUCED
  },
  "4e422682-0bc7-4d3b-9a4c-3a9b2379b611": {
    name: "Lungo",
    vatRate: VatRate.REDUCED
  },
  "05908cd3-5a89-46eb-a3ff-4860297995df": {
    name: "Pinot de Charentes",
    vatRate: VatRate.STANDARD
  },
  "9a29114c-2b4d-4685-a595-2f721b62ac72": {
    name: "Porto",
    vatRate: VatRate.STANDARD
  },
  "feb62f15-8aa6-4013-b454-32869918cfd1": {
    name: "Porto rood",
    vatRate: VatRate.STANDARD
  },
  "57641fa5-a6e5-4d15-b814-8f471b09a2c1": {
    name: "porto wit",
    vatRate: VatRate.STANDARD
  },
  "3c4fb6e2-1a7c-4c1b-be8e-bf2e4e226289": {
    name: "Prosecco",
    vatRate: VatRate.STANDARD
  },
  "7de72fc1-447d-4cf9-b1d3-afcb5761b257": {
    name: "Ristretto",
    vatRate: VatRate.STANDARD
  },
  "11ab9979-823c-458a-8835-7e630288e655": {
    name: "Rode Wijn",
    vatRate: VatRate.STANDARD
  },
  "7bd7522f-eb7f-4f21-b24f-2c8a4091968d": {
    name: "Rosé Wijn",
    vatRate: VatRate.STANDARD
  },
  "c461e28d-ddd7-42e8-bb8e-2dcb298ab383": {
    name: "Sangria",
    vatRate: VatRate.STANDARD
  },
  "7b814c7a-0ed9-45bf-b8c5-4835ccbc33e6": {
    name: "Thee",
    vatRate: VatRate.REDUCED
  },
  "b8f0281f-f4ec-465f-a982-2a7c076d5978": {
    name: "Vers Fruitsap",
    vatRate: VatRate.REDUCED
  },
  "db14e3d5-f153-4328-bfee-37d1aea4f559": {
    name: "Verse Muntthee",
    vatRate: VatRate.REDUCED
  },
  "f5cb2055-cf81-4abb-8b76-90b72ce7dd59": {
    name: "Witte Wijn",
    vatRate: VatRate.STANDARD
  },
  "b87ec46f-1e25-4408-becd-f115ced8c5ef": {
    name: "absolute wodka",
    vatRate: VatRate.STANDARD
  },
  "3001b9d7-b875-437e-a788-39870337ca26": {
    name: "angostura 1919",
    vatRate: VatRate.STANDARD
  },
  "1ea6e82f-c3d3-4573-81d7-5f09b98c8856": {
    name: "Aperol Spritz",
    vatRate: VatRate.STANDARD
  },
  "e40acb6a-cac8-43e6-9af3-f0da46ee7402": {
    name: "Baileys",
    vatRate: VatRate.STANDARD
  },
  "bec7f49d-ebb8-4064-869b-05ecde636432": {
    name: "Bains",
    vatRate: VatRate.STANDARD
  },
  "898dc503-2868-4d7c-aa46-97a569dbb337": {
    name: "Calvados",
    vatRate: VatRate.STANDARD
  },
  "09929055-422d-4e6d-8b1b-8b4b546dbe43": {
    name: "Campari",
    vatRate: VatRate.STANDARD
  },
  "af99ed81-9225-4e9c-96f1-598b499a3eb6": {
    name: "Captain Morgan gold spiced rum",
    vatRate: VatRate.STANDARD
  },
  "b8faa16b-d6f0-4013-a27c-7f3167f2e42f": {
    name: "Ciroc Vodka",
    vatRate: VatRate.INTERMEDIATE
  },
  "6c0638d0-165b-4565-b4c8-3f2f79cc1aa8": {
    name: "Cointreau",
    vatRate: VatRate.STANDARD
  },
  "9c1dd8ba-fc4c-43ee-b7f5-28b3cdb0cfa7": {
    name: "Disaronno",
    vatRate: VatRate.STANDARD
  },
  "dcfcaacf-16d6-40f5-b608-4e0aebfebc44": {
    name: "Elixir d’anvers",
    vatRate: VatRate.STANDARD
  },
  "f2747c34-a5aa-492c-be19-ddc810e4c407": {
    name: "eristof rood",
    vatRate: VatRate.STANDARD
  },
  "0992dc71-2d60-4d78-bbc5-3c7ab9b9fac8": {
    name: "Eristoff",
    vatRate: VatRate.STANDARD
  },
  "3461f9c8-3c7e-4f45-8b97-b759a83fc625": {
    name: "eristoff lime",
    vatRate: VatRate.STANDARD
  },
  "60cecb09-0b4a-46b3-bf03-3862a9c4d644": {
    name: "Eristoff Rood",
    vatRate: VatRate.STANDARD
  },
  "de259e15-2b29-4839-8ec3-f58dd9b0ae52": {
    name: "eristoff zwart",
    vatRate: VatRate.STANDARD
  },
  "e5524b0c-17de-4264-a98b-b242b9be0677": {
    name: "Filiers Graanjenever",
    vatRate: VatRate.STANDARD
  },
  "1d5bd166-bd45-4d0b-8578-30e7ae0802b4": {
    name: "FILLIERS APPELJENEVER",
    vatRate: VatRate.STANDARD
  },
  "6c6f8b2a-8d52-44fb-b906-79e62faef5da": {
    name: "Flügel",
    vatRate: VatRate.STANDARD
  },
  "473d77f0-24d1-4e65-a8b9-259f648806db": {
    name: "gouden carolus single malt",
    vatRate: VatRate.STANDARD
  },
  "f9e34151-f4bc-42a2-831b-d884f9611b9a": {
    name: "Grand marnier",
    vatRate: VatRate.STANDARD
  },
  "fed99f17-875f-4f23-9c3a-9ca5ac3cd7a2": {
    name: "Grey Goose",
    vatRate: VatRate.STANDARD
  },
  "5e2fff9b-f3c7-42a1-bd17-81edc366099d": {
    name: "Havana Club Anejo 3 Anos",
    vatRate: VatRate.STANDARD
  },
  "196ec939-6f77-41f9-96c2-68ce75649469": {
    name: "Havana Club Especial",
    vatRate: VatRate.STANDARD
  },
  "71ab23fe-c5ea-4a65-9835-6f2fc0c02f75": {
    name: "Havana Club Pacto Navio",
    vatRate: VatRate.STANDARD
  },
  "fd262ee9-0de9-4ddc-87a4-566e0b05cbcd": {
    name: "J&B",
    vatRate: VatRate.STANDARD
  },
  "127f41cb-f7b8-4bec-a491-923dd5f67486": {
    name: "J&B Whiskey",
    vatRate: VatRate.STANDARD
  },
  "4dd7c202-2054-46d9-9462-d07f17bde5ee": {
    name: "Jack Daniels",
    vatRate: VatRate.STANDARD
  },
  "103b23a6-2f54-4d4e-b6ae-60361ea751e9": {
    name: "Jack Daniels",
    vatRate: VatRate.STANDARD
  },
  "2b036b7d-1028-47d4-a462-4963ccc205cb": {
    name: "jameson",
    vatRate: VatRate.STANDARD
  },
  "3a276401-3590-486a-b325-4bd6548e4ee9": {
    name: "Johnny Walker Black label whiskey",
    vatRate: VatRate.STANDARD
  },
  "20419add-dd04-4811-bfca-7a0f19a5863c": {
    name: "Johnny Walker Red Label Whiskey",
    vatRate: VatRate.STANDARD
  },
  "06a6ac64-123a-44c0-b497-58a55307f868": {
    name: "Jägermeister Shot 3cl",
    vatRate: VatRate.STANDARD
  },
  "29e6d8e3-a890-4196-8078-17cb61a36a38": {
    name: "Kruskovac",
    vatRate: VatRate.STANDARD
  },
  "9cf62b4c-d628-4304-b5be-9b707f51fbeb": {
    name: "Limoncello bongiorno",
    vatRate: VatRate.STANDARD
  },
  "e9889099-90b0-4935-9ef6-721cad52baf8": {
    name: "Malibu",
    vatRate: VatRate.STANDARD
  },
  "b079e0ab-bd09-4107-a13b-62d1479d8e51": {
    name: "Martell Vs Cognac",
    vatRate: VatRate.STANDARD
  },
  "96cea64c-60f2-43e0-bdc9-c6968e1be5f2": {
    name: "Mount Gay Black Barrel Rum",
    vatRate: VatRate.STANDARD
  },
  "37397029-a606-432b-b321-6f8c1a881a0c": {
    name: "Pampero Blanco rum",
    vatRate: VatRate.STANDARD
  },
  "006ea43c-3cab-42b0-b808-6e6d64df0d73": {
    name: "Pampero Especial Gold rum",
    vatRate: VatRate.STANDARD
  },
  "8cb54d24-3655-4901-82b1-7b05c74dfab8": {
    name: "Partisan vodka",
    vatRate: VatRate.STANDARD
  },
  "602be009-9750-4e29-9d4d-738ac7234b3d": {
    name: "Pisang",
    vatRate: VatRate.STANDARD
  },
  "11693e03-2fc9-43e7-8d77-2f037ae8ed0a": {
    name: "Plantation Original Dark Rum",
    vatRate: VatRate.STANDARD
  },
  "7728fa63-d323-4837-b91f-2bae7ddfb100": {
    name: "Rum Diplomatico",
    vatRate: VatRate.STANDARD
  },
  "30d83225-f94a-48d7-9e1a-bf409b6894f6": {
    name: "SAFARI",
    vatRate: VatRate.STANDARD
  },
  "22b3ec94-3097-49be-9869-6b32ebe22a3e": {
    name: "sangria Quinti",
    vatRate: VatRate.STANDARD
  },
  "17177ffa-d203-49a3-8e82-68be037607de": {
    name: "smirnoff vodka",
    vatRate: VatRate.STANDARD
  },
  "4a98c37b-35e2-4952-b9b3-f3e141c4d8d4": {
    name: "The Glenlivet 18 Year Single Malt",
    vatRate: VatRate.STANDARD
  },
  "12239390-0d4f-4cb8-a121-7b99ebd62d50": {
    name: "the macallan",
    vatRate: VatRate.STANDARD
  },
  "ccb33a5b-3fc3-4ac1-a3c8-dfc2ea1203d9": {
    name: "The singleton",
    vatRate: VatRate.STANDARD
  },
  "096b3d1e-8a24-4d56-a5f8-c04a82104682": {
    name: "WILLIAM LAWSON",
    vatRate: VatRate.STANDARD
  },
  "b23a33f8-7340-41bf-b1be-b8b409f11f53": {
    name: "William Lawson's",
    vatRate: VatRate.STANDARD
  },
  "1977b486-3403-40e0-a4d3-973fa22c4097": {
    name: "Zubrowka Bison Polish Vodka",
    vatRate: VatRate.STANDARD
  },
  "fc788d4b-1089-4d3f-8424-6218be6f63da": {
    name: "Appletiser",
    vatRate: VatRate.REDUCED
  },
  "7f8c55e8-1d8b-4029-8870-994352f6b913": {
    name: "Aquarius Lemon",
    vatRate: VatRate.REDUCED
  },
  "439445b6-ef2a-47b9-be71-4a91512802d4": {
    name: "Aquarius Red",
    vatRate: VatRate.STANDARD
  },
  "a6f4b34d-0235-446b-886d-b7be43b46d24": {
    name: "Chaudfontaine bruisend",
    vatRate: VatRate.REDUCED
  },
  "7037c40c-99f2-4447-a195-0596e194c0b5": {
    name: "Chaudfontaine plat",
    vatRate: VatRate.REDUCED
  },
  "2326d7be-644e-47c0-978e-41cf120918b3": {
    name: "Coca Cola",
    vatRate: VatRate.REDUCED
  },
  "c59f8233-8163-4ed6-885a-f0f2a8fa4780": {
    name: "Coca Cola Light",
    vatRate: VatRate.REDUCED
  },
  "60055e00-26a9-4cde-87e5-6b78d9710d4a": {
    name: "Coca Cola Zero",
    vatRate: VatRate.REDUCED
  },
  "0c3996b2-7ad5-4a1c-a930-2948178ad7c2": {
    name: "Fanta",
    vatRate: VatRate.REDUCED
  },
  "a7d0d458-6045-422c-ae47-89800593dac1": {
    name: "Fanta Lemon",
    vatRate: VatRate.REDUCED
  },
  "5d8888bb-8fcf-4e0f-85ee-53dd3ba77ba2": {
    name: "Finley Ginger Ale",
    vatRate: VatRate.REDUCED
  },
  "c1e9609e-8b99-4122-a581-2049395c253e": {
    name: "Finley pompelmoes & bloedappelsien",
    vatRate: VatRate.STANDARD
  },
  "fa52731a-8458-499d-9024-15935027fc25": {
    name: "Finley Sea Salt Lemon Tonic",
    vatRate: VatRate.REDUCED
  },
  "a92343b1-c94a-4469-ab9a-99dc83a93d76": {
    name: "Finley Tonic Water",
    vatRate: VatRate.REDUCED
  },
  "8bf53310-088e-4854-a497-390a2fb84e66": {
    name: "Fuze Tea",
    vatRate: VatRate.REDUCED
  },
  "b1324153-4b5e-470b-a8f8-57f9a6f7bb9a": {
    name: "Fuze Tea Mango Chamomile",
    vatRate: VatRate.REDUCED
  },
  "3be041fa-8106-4d8c-842c-6374c898bbe8": {
    name: "Fuze Tea Peach Hibiscus",
    vatRate: VatRate.REDUCED
  },
  "b90235c8-b2f7-4640-bf16-e042c2412505": {
    name: "Fuze Tea Sparkling Black Tea",
    vatRate: VatRate.REDUCED
  },
  "d0d5cc26-a72c-4c52-9d7c-6473e77bcd0f": {
    name: "Honest Framboos & Basilicum",
    vatRate: VatRate.REDUCED
  },
  "c3cacb70-8cf4-4e81-a61b-1fae8248a7b3": {
    name: "Honest Original Lemonade",
    vatRate: VatRate.REDUCED
  },
  "22ceb665-f5e2-49ec-9f6c-fd1d21604b9b": {
    name: "Honest Perzik & Rozemarijn",
    vatRate: VatRate.REDUCED
  },
  "ff58af74-39af-4109-85c9-76efc03294a7": {
    name: "Honest Pink Lemonade",
    vatRate: VatRate.REDUCED
  },
  "f4bea27a-ff14-42d3-8a65-899d21a6de58": {
    name: "Minute Maid Ananas",
    vatRate: VatRate.REDUCED
  },
  "74a58280-5237-4b46-b686-9356194a0d96": {
    name: "Minute Maid Appel",
    vatRate: VatRate.REDUCED
  },
  "e63eff71-bae1-4c99-ad0f-c3c1ea6f9cb2": {
    name: "Minute Maid Appel-Kers",
    vatRate: VatRate.REDUCED
  },
  "d6a190f2-8597-47f2-b53d-138114e293cb": {
    name: "Minute Maid Multivruchten",
    vatRate: VatRate.REDUCED
  },
  "8120798c-6ce2-4948-a387-77783ccf6c95": {
    name: "Minute Maid Pompelmoes",
    vatRate: VatRate.REDUCED
  },
  "59886a34-0bb5-453e-b466-3e8d97f0f88a": {
    name: "Minute Maid Sinaas",
    vatRate: VatRate.REDUCED
  },
  "205cfaa7-52e9-45fe-a55e-145ad491620c": {
    name: "Minute Maid Tomaat",
    vatRate: VatRate.REDUCED
  },
  "ab26dca9-83fb-42d4-87a1-8434a3fd7412": {
    name: "Nordic Mist Agrumes",
    vatRate: VatRate.REDUCED
  },
  "98bf3a5d-baa9-49cf-ae92-d05f5a70579d": {
    name: "Nordic Mist Tonic",
    vatRate: VatRate.REDUCED
  },
  "6dfa4ff1-ee8c-431a-bc6d-f1a1509f0d1d": {
    name: "Sprite",
    vatRate: VatRate.REDUCED
  },
  "ad079c9c-24c6-48ad-89d1-cb349d4f0901": {
    name: "Achel Blond",
    vatRate: VatRate.STANDARD
  },
  "c6bff590-b330-4396-ac1d-192e42b34440": {
    name: "Achel Bruin",
    vatRate: VatRate.STANDARD
  },
  "8bb5204d-a30d-4060-bf94-4d53d2e0241a": {
    name: "Chimay Blauw",
    vatRate: VatRate.STANDARD
  },
  "54a3ecab-f971-4f55-b4ff-4d5870e87f75": {
    name: "Chimay Gold",
    vatRate: VatRate.STANDARD
  },
  "b0542855-9255-43b9-a327-403d5368c73e": {
    name: "Chimay Rood",
    vatRate: VatRate.STANDARD
  },
  "589f23c0-236e-43ae-962f-02179e9c076e": {
    name: "Chimay Tripel",
    vatRate: VatRate.STANDARD
  },
  "8f2d463a-ca88-4c5e-bec8-3d506d168808": {
    name: "Orval",
    vatRate: VatRate.STANDARD
  },
  "25958510-b0ae-4ef8-b63d-fd7002146791": {
    name: "Rochefort 10",
    vatRate: VatRate.STANDARD
  },
  "208196be-1108-4d9e-950c-4e70b69479cd": {
    name: "Rochefort 6",
    vatRate: VatRate.STANDARD
  },
  "16d044b7-cf88-4281-ae96-f80160b038cc": {
    name: "Rochefort 8",
    vatRate: VatRate.STANDARD
  },
  "29544fe0-a053-42a9-ba1d-18b0ed328934": {
    name: "Spencer",
    vatRate: VatRate.STANDARD
  },
  "df14c962-6030-458a-a19b-aef0426a9be0": {
    name: "Tre Fontane",
    vatRate: VatRate.STANDARD
  },
  "6e61c9e4-59aa-4637-9347-0e40d885fcfb": {
    name: "Zundert 10",
    vatRate: VatRate.STANDARD
  },
  "708332ea-c8f1-4fef-97f0-8e09e6f1ee45": {
    name: "Zundert 8",
    vatRate: VatRate.STANDARD
  },
  "2d24e0fd-e93c-44a4-b087-a6f66cedfb78": {
    name: "Lipton Ice Tea",
    vatRate: VatRate.REDUCED
  },
  "faa9a618-210e-4b9d-ba3f-96ecaaae5a45": {
    name: "Lipton Ice Tea Green",
    vatRate: VatRate.REDUCED
  },
  "434a31dc-f5cb-45b6-8c34-872b2e735306": {
    name: "Lipton Ice Tea Peach",
    vatRate: VatRate.REDUCED
  },
  "1060de8f-dc9e-49f2-bf58-823693897d09": {
    name: "Lipton Ice Tea Peach",
    vatRate: VatRate.REDUCED
  },
  "a9269afe-e38e-4fd6-a02d-c0b936beb8d9": {
    name: "Lipton Ice Tea Zero",
    vatRate: VatRate.STANDARD
  },
  "44963ba3-f9c5-4b9c-966c-f6ea81b4e323": {
    name: "Augustijn Blond",
    vatRate: VatRate.STANDARD
  },
  "27fbe2ad-2b55-4aa0-9f7f-70829d045bb2": {
    name: "Augustijn Donker",
    vatRate: VatRate.STANDARD
  },
  "caabf8ab-b420-4869-b46d-8aa7b2d5fd7a": {
    name: "Augustijn Grand Cru",
    vatRate: VatRate.STANDARD
  },
  "af9139e7-3bcd-4fc6-aea4-b1fdcbb0bf90": {
    name: "Baptist Blond",
    vatRate: VatRate.STANDARD
  },
  "b047b802-5ceb-45ce-88cc-b428d704768a": {
    name: "Baptist IPA",
    vatRate: VatRate.STANDARD
  },
  "4a0a5de5-8240-4a87-80f7-b975b56055c8": {
    name: "Baptist Saison",
    vatRate: VatRate.STANDARD
  },
  "cba3d2e5-2e5e-469c-bf99-f12dc7f2c0f6": {
    name: "Baptist Wit",
    vatRate: VatRate.STANDARD
  },
  "a7ee45e6-d6b8-4e77-a402-39621818df70": {
    name: "Fourchette",
    vatRate: VatRate.STANDARD
  },
  "5e119213-61fe-451d-83a0-469127d854e5": {
    name: "Gulden Draak",
    vatRate: VatRate.STANDARD
  },
  "1e5f4b65-043f-4b18-bb15-4f6fccbb21f3": {
    name: "Gulden Draak 9000 Quadruple",
    vatRate: VatRate.STANDARD
  },
  "b3e8723d-9472-42ef-a46e-b6a28b8dc966": {
    name: "Gulden Draak Brewmaster",
    vatRate: VatRate.STANDARD
  },
  "021cdf83-9561-45f1-be2f-ac8337f98942": {
    name: "Gulden Draak Imperial Stout",
    vatRate: VatRate.STANDARD
  },
  "02018508-de4e-44e0-98e5-98b9e2056ec1": {
    name: "Monk's Café Grand Cru",
    vatRate: VatRate.STANDARD
  },
  "ebba6aa6-caf6-46e9-ad8e-d15f122285b9": {
    name: "Piraat",
    vatRate: VatRate.STANDARD
  },
  "051152ab-e2e8-4767-9880-5736120e0f91": {
    name: "Piraat Red",
    vatRate: VatRate.STANDARD
  },
  "0ffc9852-78b5-4072-acf0-9c342c71d28e": {
    name: "Piraat Triple Hop",
    vatRate: VatRate.STANDARD
  },
  "da12d25d-8fa9-4498-a548-efcf72a75778": {
    name: "Vicaris Generaal",
    vatRate: VatRate.STANDARD
  },
  "0d776533-862d-4763-a82c-9d967c4b10f5": {
    name: "Vicaris Lino",
    vatRate: VatRate.STANDARD
  },
  "63ad9032-6597-4227-821c-3392e11de1b9": {
    name: "Vicaris Quinto",
    vatRate: VatRate.STANDARD
  },
  "8183f787-3146-441b-bc5c-bccfbd198bd7": {
    name: "Vicaris Tripel",
    vatRate: VatRate.STANDARD
  },
  "2225d6f3-3307-48cf-b850-9403e5283868": {
    name: "Vicaris Tripel-Gueuze",
    vatRate: VatRate.STANDARD
  },
  "72c012ad-93ad-431d-9782-95180613248c": {
    name: "Vicaris Winter",
    vatRate: VatRate.STANDARD
  },
  "428f81cd-0c9d-4b1b-bce1-65dd2087ec07": {
    name: "Con-Tré Rosé",
    vatRate: VatRate.STANDARD
  },
  "9a266f34-912d-43a5-a534-63540908e26c": {
    name: "Dom Perignon",
    vatRate: VatRate.STANDARD
  },
  "dccb60f1-88ad-4aba-8af6-c37453a81274": {
    name: "Laurent Perrier Brut",
    vatRate: VatRate.STANDARD
  },
  "a6bc5265-2c7f-4071-8183-51de5a23e328": {
    name: "Laurent-Perrier La Cuvée Rosé",
    vatRate: VatRate.STANDARD
  },
  "4b646083-01bd-48f8-8f99-913b24ad9773": {
    name: "moët ice impérial",
    vatRate: VatRate.STANDARD
  },
  "867ada0e-7847-4e70-9d67-093ad18d0320": {
    name: "Pere Ventura Tresor",
    vatRate: VatRate.STANDARD
  },
  "3f8c37aa-e779-4650-8737-ab3d8fe439be": {
    name: "Veuve Clicquot",
    vatRate: VatRate.STANDARD
  }
};

export default catalogueHelper.createDefaultCatalogue(defaultData);
