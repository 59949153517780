import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { ButtonGroup, Button, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  smallSpacing: {
    height: 10
  },
  largeSpacing: {
    height: 30
  }
});

class SelectOrderPreparationOption extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handlePreparationOptionClick = (preparationOption) => (event) => {
    const { onSelect } = this.props;

    onSelect(preparationOption);
  };

  render() {
    const { classes, t, base, isDisabled, variant, size, isCompact } = this.props;

    return (
      <div className={classes.content}>
        <Typography variant={variant || "h6"}>
          {t(isCompact ?
            "order.select-estimated-preparation-duration" :
            "order.select-estimated-preparation-duration-to-claim-order"
          )}
        </Typography>
        <div className={isCompact ? classes.smallSpacing : classes.largeSpacing} />
        <ButtonGroup variant="contained" size={size || "large"} disabled={isDisabled}>
          {base &&
            base.preparationOptions.map((preparationOption, index) => (
              <Button
                key={index}
                color="primary"
                onClick={this.handlePreparationOptionClick(preparationOption)}
              >{`${preparationOption.duration}${isCompact ?
                "'" :
                ` ${t(preparationOption.duration === 1
                  ? "label.minute"
                  : "label.minutes"
                )}`}`}</Button>
            ))}
          <Button
            color="secondary"
            onClick={this.handlePreparationOptionClick(null)}
          >
            {t("label.none")}
          </Button>
        </ButtonGroup>
      </div>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(SelectOrderPreparationOption));
