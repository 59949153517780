import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import { toast } from "react-toastify";

import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  Fab,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PrintIcon from "@mui/icons-material/Print";
import withStyles from "@mui/styles/withStyles";

import { PIN_LENGTH } from "tap-io/constants/payment";
import {
  menuHelper,
  orderHelper,
  serviceOptionHelper,
  utilsHelper
} from "tap-io/helpers";
import {
  barService,
  orderService,
  paymentService
} from "tap-io/client/services";
import EnterPaymentPinDialog from "tap-io/client/components/payment/EnterPaymentPinDialog";
import config from "tap-io/client/env";
import SetDeliveryContact from "tap-io/client/components/delivery/SetDeliveryContact";
import DeliveryMethod from "tap-io/models/delivery/DeliveryMethod";
import SelectServiceOption from "tap-io/client/components/service/SelectServiceOption";
import { deviceStorage } from "tap-io/storage";
import SelectPaymentMethod from "tap-io/client/components/payment/SelectPaymentMethod";
import PaymentMethod from "tap-io/models/payment/PaymentMethod";

import withAuthorization from "../auth/withAuthorization";
import { hasNativeWrapper, printer } from "../../native";
import * as routes from "../../constants/routes";
import SelectOrderServiceOptionDialog from "./SelectOrderServiceOptionDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  tabIndicator: {
    backgroundColor: theme.palette.primary.contrastText
  },
  link: {
    textDecoration: "none"
  },
  noMenu: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "calc(100vh - 100px)"
  },
  noMenuText: {
    margin: 20
  },
  menu: {
    color: theme.palette.primary.contrastText
  },
  tab: {
    display: "flex",
    overflowY: "auto",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    padding: theme.spacing()
  },
  buttons: {
    overflow: "auto",
    flexGrow: 1
  },
  order: {
    overflow: "auto",
    minWidth: "30vw",
    height: "100%"
  },
  card: {
    margin: `${theme.spacing()} 0`,
    borderRadius: 4,
    backgroundColor: theme.palette.background.default
  },
  cardItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  cardSpacing: {
    height: 8
  },
  category: {
    display: "block",
    width: "100%",
    paddingLeft: theme.spacing(),
    paddingTop: theme.spacing(2)
  },
  leftSpacing: {
    minWidth: 36
  },
  total: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  totalLabel: {
    flexGrow: 1
  },
  totalAmount: {
    textAlign: "right"
  },
  item: {
    margin: theme.spacing()
  },
  itemAmountAndName: {},
  itemPrice: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  priceCalculation: {
    flexGrow: 1
  },
  calculatedPrice: {
    fontSize: 16,
    textAlign: "right"
  }
});

class CreateOrderDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (bar, base, allBases, deviceSettings, serviceOption) => {
    // TO FIX: initialize base in all (relevant) modes?
    const defaultBase = base
      ? base
      : deviceSettings && deviceSettings.baseId && allBases
        ? utilsHelper.findInArrayById(allBases, deviceSettings.baseId)
        : null;

    let currentMenuIndex = 0;
    if (defaultBase) {
      this.getActiveMenus(serviceOption).forEach((menu, index) => {
        if (defaultBase.hasMenuId(menu.id)) {
          currentMenuIndex = index;
        }
      });
    }

    return {
      isEnterPaymentPinDialogOpen: false,
      isDisabled: false,
      currentMenuIndex,
      orderName: "",
      orderServiceOption: serviceOption || null,
      orderDeliveryContact: null,
      orderTotal: 0,
      orderItems: [],
      confirmDeferredPayment: bar ? bar.isAllowingOnlinePayments() : false,
      paymentMethod: PaymentMethod.CASH,
      paymentReference: "",
      printCustomerReceipt: deviceSettings
        ? deviceSettings.printCustomerReceipt === true
        : false
    };
  };

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.props;

    if (isOpen && !prevProps.isOpen) {
      this.handleSetNewOrder();
    }
  }

  getAvailableServiceOptions = () => {
    const { bar, allBases } = this.props;

    if (bar.isUsingBases()) {
      const availableServiceOptions =
        serviceOptionHelper.createServiceOptions();

      if (allBases) {
        allBases.forEach((base) => {
          if (base.isActive) {
            base.serviceOptions.forEach((serviceOption) => {
              if (
                bar.deliveryMethods.isDeliveryMethodActive(
                  serviceOption.deliveryMethod
                )
              ) {
                availableServiceOptions.add(serviceOption);
              }
            });
          }
        });
      }

      return availableServiceOptions;
    } else {
      return serviceOptionHelper.createDefaultServiceOptions(
        bar.deliveryMethods
      );
    }
  };

  handleSetNewOrder = (serviceOption) => {
    const { bar, base, allBases, deviceSettings } = this.props;

    this.setState(
      this.initialState(bar, base, allBases, deviceSettings, serviceOption)
    );
    this.order = null;
  };

  handleMenuChange = (event, menuIndex) => {
    this.setState({ currentMenuIndex: menuIndex });
  };

  handleOrderNameChange = (event) => {
    this.setState({ orderName: event.target.value });
  };

  handleOrderServiceOptionChange = (serviceOption) => {
    this.handleSetNewOrder(serviceOption);
  };

  handleOrderDeliveryContactChange = (orderDeliveryContact) => {
    this.setState({ orderDeliveryContact });
  };

  handleConfirmDeferredPaymentChange = (event) => {
    this.setState({
      confirmDeferredPayment: event.target.checked === true
    });
  };

  handlePrintCustomerReceiptChange = async (event) => {
    const { t, bar } = this.props;

    const printCustomerReceipt = event.target.checked === true;

    this.setState({
      printCustomerReceipt
    });

    if (bar) {
      try {
        await deviceStorage.updateDeviceSettings(bar.id, {
          printCustomerReceipt
        });
      } catch (error) {
        toast.error(
          `${t("label.something-went-wrong")}: (${error && error.message ? error.message : t("error.unknown-error")
          })`
        );

        console.warn(error);
      }
    }
  };

  handlePaymentMethodChange = (paymentMethod) => {
    this.setState({ paymentMethod: paymentMethod || null });
  };

  handlePaymentReferenceChange = (event) => {
    const val = event.target.value;
    this.setState({ paymentReference: val });
  };

  handleIncrementItemWithAmount = (menuId, itemId, item, amount) => (event) => {
    const orderItems = [...this.state.orderItems];

    let itemIndex = utilsHelper.findIndexInArrayById(orderItems, itemId);

    if (itemIndex === undefined) {
      itemIndex =
        orderItems.push({
          id: itemId,
          amount: 0,
          menuId,
          price: item.price,
          name: item.name
        }) - 1;
    }

    orderItems[itemIndex].amount += amount;

    if (orderItems[itemIndex].amount <= 0) {
      orderItems.splice(itemIndex, 1);
    }

    const orderTotal = orderItems.reduce((total, orderItem) => {
      return total + orderItem.price * orderItem.amount;
    }, 0);

    this.setState({
      orderItems,
      orderTotal
    });
  };

  handleAddOrder = () => {
    const { t } = this.props;

    this.addCurrentOrder()
      .then(() => { })
      .catch((error) => {
        console.warn(error);
        toast.error(
          error && error.name ? error.name : t("error.unknown-error")
        );
      });
  };

  addCurrentOrder = async () => {
    const { t, bar, base, auth } = this.props;
    const {
      orderName,
      orderServiceOption,
      orderDeliveryContact,
      orderNote,
      orderItems,
      confirmDeferredPayment
    } = this.state;

    const cleanOrderName = orderName
      ? orderName.trim()
      : base
        ? base.name
        : t("label.bar");
    const cleanOrderNote = orderNote ? orderNote.trim() : undefined;

    if (!bar || !bar.id) {
      return toast.error(
        `${t("label.error")}: ${t("error.no-valid-bar-found")}`
      );
    }
    if (!auth || !auth.user || !auth.user.uid) {
      return toast.error(
        `${t("label.error")}: ${t("error.no-valid-user-found")}`
      );
    }

    if (!orderItems || orderItems.length === 0) {
      return toast.error(
        `${t("label.error")}: ${t("error.no-items-selected")}`
      );
    }

    // Service
    if (!orderServiceOption) {
      return toast.error(t("error.service-option-is-not-defined"));
    }

    // Delivery
    const orderDelivery = {};
    if (orderServiceOption.deliveryMethod === DeliveryMethod.PICKUP) {
      if (orderDeliveryContact && orderDeliveryContact.email) {
        if (!utilsHelper.parseEmail(orderDeliveryContact.email)) {
          return toast.error(t("error.delivery-contact-email-is-not-valid"));
        }

        orderDelivery.contact = orderDeliveryContact;
      } else {
        orderDelivery.contact = {};
      }
    }

    // Disable buttons
    this.setDisabled(true);
    // Display message
    const toastId = toast(t("order.adding-order"), { autoClose: false });

    // Add order
    const orderId = await orderService.addOrder(
      auth.user.uid,
      bar.id,
      cleanOrderName,
      orderItems,
      { option: { name: orderServiceOption.name } },
      orderDelivery,
      undefined,
      undefined,
      undefined,
      cleanOrderNote
    );

    // If online payments are enabled, the order might not be automatically added to the queue
    if (bar.isAllowingOnlinePayments()) {
      // Wait until the order is ready
      this.order = await orderService.onOrderReady(bar.id, orderId);

      // Only add it to the queue if it's not queued already
      // (e.g. when it's free, it will already be queued)
      if (!this.order.isStatusQueued()) {
        toast.update(toastId, {
          render: t("order.adding-order-to-queue")
        });
        await orderService.setOrderStatusQueued(this.order);
      }
    }

    // Enable buttons
    this.setDisabled(false);
    // Display message
    toast.update(toastId, {
      render: t("order.order-added"),
      type: toast.TYPE.INFO,
      autoClose: 3000
    });

    if (confirmDeferredPayment && !this.order.isFree()) {
      // Confirm deferred payment
      if (bar.isPinEnabledForDeferredPayments()) {
        this.handleCheckPin();
      } else {
        this.handleCheckoutOrder("");
      }
    } else {
      // Print order receipt (if needed)
      await this.printCustomerReceiptIfNeeded(orderId);

      this.handleSetNewOrder();
    }
  };

  handleCheckoutOrder = async (pin) => {
    const { t, auth } = this.props;
    const { paymentMethod, paymentReference } = this.state;

    if (!auth || !auth.user || !auth.user.uid) {
      return toast.error(
        `${t("label.error")}: ${t("error.no-valid-user-found")}`
      );
    }
    if (!this.order) {
      return toast.error(
        `${t("label.error")}: ${t("error.no-valid-order-found")}`
      );
    }

    const toastId = toast(t("order.checkout-order"), { autoClose: false });
    this.setDisabled(true);

    try {
      await paymentService.confirmDeferredPayment(
        auth.user.uid,
        this.order,
        pin,
        paymentMethod,
        paymentReference,
        (taskId) => {
          console.log("taskId", taskId);
        }
      );

      this.setState({ isEnterPaymentPinDialogOpen: false });
      this.setDisabled(false);

      toast.update(toastId, {
        render: t("order.order-settled"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      // Print order receipt (if needed)
      await this.printCustomerReceiptIfNeeded(this.order.id);

      this.handleSetNewOrder();
    } catch (error) {
      console.warn(error);

      this.setDisabled(false);
      toast.update(toastId, {
        render:
          error && error.text ? error && error.text : t("error.unknown-error"),
        type: toast.TYPE.ERROR,
        autoClose: 3000
      });
    }
  };

  handleCheckPin() {
    this.setState({ isEnterPaymentPinDialogOpen: true });
  }

  handlePinDialogConfirm = async (pin) => {
    await this.handleCheckoutOrder(pin);
  };

  handlePinDialogCancel = () => {
    this.setState({ isEnterPaymentPinDialogOpen: false });
    this.setDisabled(false);

    this.handleSetNewOrder();
  };

  printCustomerReceiptIfNeeded = async (orderId) => {
    const { t, bar, allBases, deviceSettings, assets } = this.props;

    try {
      if (
        hasNativeWrapper() &&
        deviceSettings &&
        deviceSettings.printCustomerReceipt
      ) {
        const order = await orderService.getById(bar.id, orderId);

        const barUrl = await barService.getBarUrl(
          bar,
          config.hosting.orderDomain
        );
        const orderUrl = orderHelper.getOrderUrl(barUrl, orderId);

        await printer.printCustomerReceipts(
          t,
          bar,
          allBases,
          assets,
          order,
          orderUrl,
          deviceSettings.printBarLogo,
          1
        );
      }
    } catch (error) {
      if (error) {
        toast.error(error.message);
      }
      console.warn(error);
    }
  };

  setDisabled = (isDisabled) => {
    this.setState({ isDisabled: isDisabled === true });
  };

  getActiveMenus = (serviceOption) => {
    const { bar, allMenus, allBases } = this.props;
    const { orderServiceOption } = this.state;

    if (!serviceOption) {
      serviceOption = orderServiceOption;
    }

    if (allMenus) {
      const activeMenus = bar.isUsingBases()
        ? (allBases || [])
          .reduce((menuIds, base) => {
            if (
              serviceOption &&
              base &&
              base.isActive &&
              base.serviceOptions.has(serviceOption.name)
            ) {
              base.menuIds.forEach((menuId) => {
                if (menuIds.indexOf(menuId) < 0) {
                  menuIds.push(menuId);
                }
              });
            }

            return menuIds;
          }, [])
          .map((menuId) => utilsHelper.findInArrayById(allMenus, menuId))
          .filter((menu) => !!menu)
        : menuHelper.filterActiveMenus(allMenus);

      return activeMenus.sort((menuA, menuB) => {
        return menuA.name > menuB.name ? 1 : menuA.name < menuB.name ? -1 : 0;
      });
    }

    return [];
  };

  getOrderAmount = () => {
    const { orderItems } = this.state;

    if (orderItems) {
      return orderItems.reduce(
        (totalAmount, orderItem) => totalAmount + orderItem.amount,
        0
      );
    }

    return 0;
  };

  render() {
    const { classes, t, isOpen, onClose, bar } = this.props;
    const {
      isDisabled,
      isEnterPaymentPinDialogOpen,
      currentMenuIndex,
      orderName,
      orderServiceOption,
      orderDeliveryContact,
      orderTotal,
      orderItems,
      confirmDeferredPayment,
      paymentMethod,
      paymentReference,
      printCustomerReceipt
    } = this.state;

    const currency = bar.getOrderCurrency();
    const customerAddressedAs = bar.getCustomerAddressedAs(
      t("label.name"),
      t("order.table-number-or-other-reference")
    );

    const activeMenus = this.getActiveMenus();

    const availableServiceOptions = this.getAvailableServiceOptions();

    const orderAmount = this.getOrderAmount();

    return (
      <div>
        <SelectOrderServiceOptionDialog
          isOpen={isOpen && bar && bar.isUsingBases() && !orderServiceOption}
          serviceOptions={availableServiceOptions}
          orderServiceOption={orderServiceOption}
          onChange={this.handleOrderServiceOptionChange}
          onCancel={onClose}
        />
        <EnterPaymentPinDialog
          isOpen={isEnterPaymentPinDialogOpen}
          isDisabled={isDisabled}
          onCancel={this.handlePinDialogCancel}
          onConfirm={this.handlePinDialogConfirm}
          showRememberPin={true}
          bar={bar}
        />
        <Dialog
          open={isOpen}
          onClose={onClose}
          TransitionComponent={Transition}
          fullScreen
        >
          <AppBar
            position="static"
            className={
              hasNativeWrapper()
                ? "App-subheader-ios App-subheader-ios-fallback"
                : undefined
            }
          >
            <Tabs
              textColor="inherit"
              classes={{
                indicator: classes.tabIndicator
              }}
              value={currentMenuIndex}
              onChange={this.handleMenuChange}
            >
              {activeMenus &&
                activeMenus.map((menu) => (
                  <Tab
                    key={menu.id}
                    className={classes.menu}
                    label={menu.name}
                    disabled={isDisabled}
                  />
                ))}
            </Tabs>
          </AppBar>
          {activeMenus && activeMenus.length > 0 ? (
            activeMenus[currentMenuIndex] && (
              <div className={classes.tab}>
                <div className={classes.buttons}>
                  {activeMenus[currentMenuIndex].elements &&
                    activeMenus[currentMenuIndex].elements.map((element) =>
                      element.type === "category" ? (
                        <Typography
                          key={element.id}
                          variant="overline"
                          className={classes.category}
                        >
                          {element.name}
                        </Typography>
                      ) : (
                        element.type === "item" && (
                          <Fab
                            key={element.id}
                            className={classes.item}
                            variant="extended"
                            disabled={isDisabled || !element.isAvailable}
                            onClick={this.handleIncrementItemWithAmount(
                              activeMenus[currentMenuIndex].id,
                              element.id,
                              element,
                              1
                            )}
                          >
                            {element.name}
                          </Fab>
                        )
                      )
                    )}
                </div>
                <List className={classes.order} disablePadding>
                  <div className={classes.card}>
                    <ListItem divider>
                      <TextField
                        variant="standard"
                        margin="none"
                        placeholder={customerAddressedAs}
                        value={orderName}
                        onChange={this.handleOrderNameChange}
                        disabled={isDisabled}
                        InputProps={{ disableUnderline: true }}
                        fullWidth
                      />
                    </ListItem>
                    <div className={classes.cardSpacing} />
                    <ListItem className={classes.cardItem}>
                      {bar.isUsingBases() ? (
                        <>
                          <ListItemIcon className={classes.leftSpacing}>
                            {orderServiceOption?.deliveryMethod ===
                              DeliveryMethod.SERVE ? (
                              <EventSeatIcon />
                            ) : orderServiceOption?.deliveryMethod ===
                              DeliveryMethod.PICKUP ? (
                              <EmojiPeopleIcon />
                            ) : (
                              <HourglassEmptyIcon />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              orderServiceOption
                                ? serviceOptionHelper.getServiceOptionLabel(
                                  orderServiceOption,
                                  t
                                )
                                : t("label.unknown")
                            }
                          />
                        </>
                      ) : (
                        <SelectServiceOption
                          //isDisabled={bar.isUsingBases()}
                          allServiceOptions={
                            bar.isUsingBases()
                              ? serviceOptionHelper.createServiceOptions([
                                orderServiceOption
                              ])
                              : availableServiceOptions
                          }
                          serviceOption={orderServiceOption}
                          onChange={this.handleOrderServiceOptionChange}
                        />
                      )}
                    </ListItem>
                    {orderServiceOption?.deliveryMethod ===
                      DeliveryMethod.PICKUP && (
                        <ListItem className={classes.cardItem}>
                          <div className={classes.leftSpacing} />
                          <SetDeliveryContact
                            isDisabled={isDisabled}
                            isContactRequired={false}
                            deliveryContact={orderDeliveryContact}
                            onChange={this.handleOrderDeliveryContactChange}
                          />
                        </ListItem>
                      )}
                    <div className={classes.cardSpacing} />
                    {bar && bar.isAllowingOnlinePayments() && (
                      <div>
                        <ListItem className={classes.cardItem}>
                          <ListItemIcon className={classes.leftSpacing}>
                            <MonetizationOnIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={t("payment.instant-order-checkout")}
                          />
                          <Switch
                            onChange={this.handleConfirmDeferredPaymentChange}
                            checked={confirmDeferredPayment}
                            disabled={isDisabled}
                          />
                        </ListItem>
                        {confirmDeferredPayment && (
                          <>
                            <ListItem>
                              <div className={classes.leftSpacing} />
                              <SelectPaymentMethod
                                isDisabled={isDisabled}
                                label={t("payment.payment-method")}
                                paymentMethod={paymentMethod}
                                onChange={this.handlePaymentMethodChange}
                              />
                            </ListItem>
                            <ListItem className={classes.cardItem}>
                              <div className={classes.leftSpacing} />
                              <TextField
                                variant="standard"
                                margin="none"
                                placeholder={t("payment.payment-reference")}
                                helperText={t(
                                  "payment.such-as-cash-register-or-terminal-id"
                                )}
                                fullWidth
                                value={paymentReference}
                                onChange={this.handlePaymentReferenceChange}
                                disabled={isDisabled}
                              />
                            </ListItem>
                          </>
                        )}
                      </div>
                    )}
                    {hasNativeWrapper() && (
                      <>
                        <div className={classes.cardSpacing} />
                        <ListItem className={classes.cardItem}>
                          <ListItemIcon className={classes.leftSpacing}>
                            <PrintIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={t("printer.print-customer-receipt")}
                          />
                          <Switch
                            onChange={this.handlePrintCustomerReceiptChange}
                            checked={printCustomerReceipt}
                            disabled={isDisabled}
                          />
                        </ListItem>
                      </>
                    )}
                  </div>
                  <div className={classes.card}>
                    <ListItem divider>
                      <ListItemText
                        primary={
                          <div className={classes.total}>
                            <span className={classes.totalLabel}>
                              {`${t("order.total")} (${orderAmount} ${t(
                                orderAmount === 1 ? "label.item" : "label.items"
                              )})`}
                            </span>
                            <strong
                              className={classes.totalAmount}
                            >{`${utilsHelper.formatToTwoDecimals(
                              orderTotal
                            )} ${currency}`}</strong>
                          </div>
                        }
                      />
                    </ListItem>
                    {orderItems && orderItems.length > 0 ? (
                      orderItems.map((item) => (
                        <ListItem
                          key={item.id}
                          disabled={isDisabled}
                          className={classes.cardItem}
                          onClick={this.handleIncrementItemWithAmount(
                            item.menuId,
                            item.id,
                            item,
                            -1
                          )}
                        >
                          <ListItemText
                            primary={
                              <span className={classes.itemAmountAndName}>
                                <strong>{item.amount}</strong>
                                {` x `}
                                <strong>{item.name}</strong>
                              </span>
                            }
                            secondary={
                              <span className={classes.itemPrice}>
                                <span className={classes.priceCalculation}>
                                  {`${item.amount
                                    } x ${utilsHelper.formatToTwoDecimals(
                                      item.price
                                    )} ${currency}`}
                                </span>
                                <span className={classes.calculatedPrice}>
                                  {`${utilsHelper.formatToTwoDecimals(
                                    item.amount * item.price
                                  )} ${currency}`}
                                </span>
                              </span>
                            }
                          />
                        </ListItem>
                      ))
                    ) : (
                      <ListItem>
                        <ListItemText secondary={t("order.no-items-found")} />
                      </ListItem>
                    )}
                  </div>
                </List>
              </div>
            )
          ) : (
            <div className={classes.noMenu}>
              <Typography className={classes.noMenuText}>
                {t("menu.no-menus-active")}
              </Typography>
              <Link to={routes.MENUS} className={classes.link}>
                <Button variant="contained" onClick={onClose}>
                  {t("menu.go-to-menus")}
                </Button>
              </Link>
            </div>
          )}
          <DialogActions>
            <Button onClick={onClose} disabled={isDisabled} color="secondary">
              {t("label.close")}
            </Button>
            {activeMenus && activeMenus.length > 0 && (
              <Button
                onClick={this.handleAddOrder}
                disabled={isDisabled}
                color="primary"
              >
                {t("label.add-order")}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(
  withAuthorization()(withTranslation("common")(CreateOrderDialog))
);
