import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";
import { Typography } from "@mui/material";

const styles = (theme) => ({
  content: {
    display: "flex",
    height: 150,
    width: "100%"
  },
  minute: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%"
  },
  barContainer: {
    position: "relative",
    width: 10,
    height: "100%"
  },
  bar: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    borderRadius: 5
  },
  barBackground: {
    height: "100%",
    backgroundColor: theme.palette.background.default
  },
  barForeground: {
    transition: "height 1s ease-in",
    backgroundColor: theme.palette.primary.main
  },
  label: {
    overflow: "hidden",
    fontWeight: "bold",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    height: 30,
    marginTop: 4,
    color: "#aaa"
  },
  amount: {
    height: 25,
    marginTop: 4,
    color: theme.palette.primary.main
  }
});

class OpenOrdersBars extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, openOrdersCountPerMinute } = this.props;

    const maxOpenOrdersCountPerMinute = openOrdersCountPerMinute
      ? openOrdersCountPerMinute.reduce(
          (max, openOrdersPerMinute) =>
            openOrdersPerMinute > max ? openOrdersPerMinute : max,
          0
        )
      : 0;

    return (
      <div>
        {openOrdersCountPerMinute && openOrdersCountPerMinute.length > 0 ? (
          <div className={classes.content}>
            {openOrdersCountPerMinute
              .reverse()
              .map((openOrdersForMinute, index) => (
                <div key={index} className={classes.minute}>
                  <div className={classes.barContainer}>
                    <div
                      className={classNames(classes.bar, classes.barBackground)}
                    />
                    <div
                      className={classNames(classes.bar, classes.barForeground)}
                      style={{
                        height: `${
                          (openOrdersForMinute / maxOpenOrdersCountPerMinute) *
                          100
                        }%`
                      }}
                    />
                  </div>
                  <span className={classes.amount}>
                    {openOrdersForMinute === 0 ? "" : openOrdersForMinute}
                  </span>
                  <span className={classes.label}>
                    {
                      index === 0
                        ? `${openOrdersCountPerMinute.length - 1}+`
                        : index === openOrdersCountPerMinute.length - 1
                        ? t("overview.now")
                        : "" //openOrdersCountPerMinute.length - index - 1
                    }
                  </span>
                </div>
              ))}
          </div>
        ) : (
          <Typography variant="caption">
            {t("overview.no-orders-found")}
          </Typography>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(OpenOrdersBars)
);
