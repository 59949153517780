import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import Moment from "moment";

import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { subscriptionService } from "tap-io/client/services";

import { PLAN_BASIC, PLANS } from "../../constants/plans";
import withAuthorization from "../auth/withAuthorization";
import SelectDate from "tap-io/client/components/common/SelectDate";

const styles = (theme) => ({
  dialog: {
    minWidth: 300
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: 50
  },
  inputLabel: {
    width: "100%"
  },
  date: {
    marginRight: 2,
    fontWeight: "bold",
    textAlign: "right"
  }
});

class EditBarSubscriptionDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (planName) => {
    return {
      isDisabled: false,
      planName: planName ? planName : PLAN_BASIC,
      validUntilMinDate: Moment().add(1, "day").toDate(),
      validUntil: Moment().add(1, "year").endOf("day")
    };
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isOpen, subscription } = this.props;
    const prevIsOpen = prevProps.isOpen;

    if (isOpen && !prevIsOpen) {
      this.setState(
        this.initialState(subscription ? subscription.planName : undefined)
      );
    }
  }

  handlePlanNameChange = (event) => {
    const val = event.target.value;

    this.setState({ planName: val });
  };

  handleValidUntilChange = (date) => {
    this.setState({ validUntil: date.endOf("day").toDate() });
  };

  handleUpdateSubscription = async () => {
    const { t, auth, bar, onClose } = this.props;
    const { planName } = this.state;

    if (!auth || !auth.user || !auth.user.uid) {
      return toast.error(
        `${t("label.error")}: ${t("error.no-valid-user-found")}`
      );
    }

    const toastId = toast(`${t("subscription.updating-plan")}`, {
      autoClose: false
    });
    this.setState({ isDisabled: true });

    try {
      let validUntil = null;
      if (planName !== PLAN_BASIC) {
        const parsedValidUntil = Moment(this.state.validUntil);
        if (
          !parsedValidUntil.isValid() ||
          Moment().diff(parsedValidUntil, "days") >= 0
        ) {
          throw new Error("error.subscription-valid-until-is-invalid");
        }
        validUntil = parsedValidUntil.toDate();
      }

      await subscriptionService.updatePlan(
        auth.user.uid,
        bar,
        planName,
        validUntil
      );

      toast.update(toastId, {
        render: t("subscription.plan-updated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false }, onClose);
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.message})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, isOpen, onClose, bar, subscription } = this.props;
    const { isDisabled, planName, validUntilMinDate, validUntil } = this.state;

    return (
      <Dialog open={isOpen} onClose={onClose} className={classes.dialog}>
        <DialogTitle>{t("subscription.edit-subscription")}</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <FormControl fullWidth>
            <InputLabel>{t("subscription.plan-name")}</InputLabel>
            <Select
              fullWidth
              required
              variant="standard"
              disabled={isDisabled}
              value={planName}
              onChange={this.handlePlanNameChange}
            >
              {PLANS.map((plan) => (
                <MenuItem key={plan.id} value={plan.id}>
                  {t(plan.label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {planName !== PLAN_BASIC && (
            <div className={classes.inputContainer}>
              <Typography className={classes.inputLabel}>
                {t("subscription.subscription-valid-until")}
              </Typography>
              <SelectDate
                isDisabled={isDisabled}
                className={classes.date}
                value={validUntil}
                onChange={this.handleValidUntilChange}
                minDate={validUntilMinDate}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={isDisabled} onClick={onClose} color="secondary">
            {t("label.cancel")}
          </Button>
          <Button
            disabled={isDisabled}
            onClick={this.handleUpdateSubscription}
            color="primary"
          >
            {t("label.update")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withAuthorization()(
  withStyles(styles)(withTranslation("common")(EditBarSubscriptionDialog))
);
