import ParamName from "tap-io/models/bar/ParamName";

export const PARAM_LABELS = {
  [ParamName.BAR_DESCRIPTION]: "settings.param-bar-description-label",
  [ParamName.ORDER_CONFIRMATION_MESSAGE]:
    "settings.param-order-confirmation-message-label",
  [ParamName.CUSTOMER_ADDRESSED_AS]:
    "settings.param-customer-addressed-as-label",
  [ParamName.CUSTOMER_FIELDS]: "settings.param-customer-fields-label",
  [ParamName.REPLY_TO_EMAIL_ADDRESS]:
    "settings.param-reply-to-email-address-label",
  [ParamName.ORDER_CURRENCY]: "settings.param-order-currency-label",
  [ParamName.MAX_AMOUNT_OF_ITEMS_PER_ORDER]:
    "settings.param-max-amount-of-items-per-order-label",
  [ParamName.ALLOW_NOTES]: "settings.param-allow-notes-label",
  [ParamName.ALLOW_TIPS]: "settings.param-allow-tips-label",
  [ParamName.USE_BASES]: "settings.param-use-bases-label",
  [ParamName.USE_ZONES]: "settings.param-use-zones-label",
  [ParamName.ALLOW_ONLINE_PAYMENTS]:
    "settings.param-allow-online-payments-label",
  [ParamName.PAYCONIQ_MERCHANT_LINK]:
    "settings.param-payconiq-merchant-link-label"
};

export const PARAM_DESCRIPTIONS = {
  [ParamName.BAR_DESCRIPTION]: "settings.param-bar-description-description",
  [ParamName.ORDER_CONFIRMATION_MESSAGE]:
    "settings.param-order-confirmation-message-description",
  [ParamName.CUSTOMER_ADDRESSED_AS]:
    "settings.param-customer-addressed-as-description",
  [ParamName.CUSTOMER_FIELDS]: "settings.param-customer-fields-description",
  [ParamName.REPLY_TO_EMAIL_ADDRESS]:
    "settings.param-reply-to-email-address-description",
  [ParamName.ORDER_CURRENCY]: "settings.param-order-currency-description",
  [ParamName.MAX_AMOUNT_OF_ITEMS_PER_ORDER]:
    "settings.param-max-amount-of-items-per-order-description",
  [ParamName.ALLOW_NOTES]: "settings.param-allow-notes-description",
  [ParamName.ALLOW_TIPS]: "settings.param-allow-tips-description",
  [ParamName.USE_BASES]: "settings.param-use-bases-description",
  [ParamName.USE_ZONES]: "settings.param-use-zones-description",
  [ParamName.ALLOW_ONLINE_PAYMENTS]:
    "settings.param-allow-online-payments-description",
  [ParamName.PAYCONIQ_MERCHANT_LINK]:
    "settings.param-payconiq-merchant-link-description"
};
