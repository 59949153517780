import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";
import Moment from "moment";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import SelectAmount from "tap-io/client/components/common/SelectAmount";
import { orderService } from "tap-io/client/services";
import { utilsHelper } from "tap-io/helpers";

import SetOrderReadyForPickupButton from "./SetOrderReadyForPickupButton";

const styles = (theme) => ({
  addMinutesContainer: {
    display: "flex",
    alignItems: "center"
  },
  addMinutesLabel: {
    flexGrow: 1
  },
  smallSpacing: {
    height: 10
  },
  largeSpacing: {
    height: 50
  }
});

class ModifyPreparationOptionDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false,
      minutesToAdd: 5
    };
  }

  handleMinutesToAddChange = (minutesToAdd) => {
    this.setState({ minutesToAdd });
  };

  handleConfirmModifyPreparationDuration = async () => {
    const { t, base, order, onClose } = this.props;
    const { minutesToAdd } = this.state;

    this.setState({ isDisabled: true });

    const toastId = toast(t("order.modifying-preparation-option"), {
      autoClose: false
    });

    try {
      await orderService.increaseOrderPreparationDuration(
        order.barId,
        order.id,
        base.id,
        minutesToAdd
      );

      toast.update(toastId, {
        render: t("order.preparation-option-modified"),
        type: toast.TYPE.INFO,
        autoClose: 5000
      });

      this.setState({ isDisabled: false }, onClose);
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(
          error ? error.message : "error.unknown-error"
        )})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleStopPropagation = (event) => {
    event.stopPropagation();
  };

  render() {
    const { classes, t, timeOffset, isOpen, onClose, base, order } = this.props;
    const { isDisabled, minutesToAdd } = this.state;

    if (!base) {
      return null;
    }
    const preparationEstimatedToBeCompletedAt =
      order.delivery.getPreparationEstimatedToBeCompletedAt(base.id);

    if (preparationEstimatedToBeCompletedAt === null) {
      return null;
    }

    const remainingTimeInSeconds = utilsHelper.calculateRemainingTime(
      preparationEstimatedToBeCompletedAt,
      timeOffset,
      "seconds"
    );

    const preparationOptionCanBeModified = remainingTimeInSeconds > 15; // Must be greater then REFRESH_ESTIMATED_TIME_REMAINING_INTERVAL (milliseconds)

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        onClick={this.handleStopPropagation}
      >
        <DialogTitle>{t("order.modify-preparation-option")}</DialogTitle>
        {order && (
          <DialogContent>
            {preparationOptionCanBeModified ? (
              <>
                <Typography>
                  {t("order.estimated-to-by-prepared-by", {
                    estimatedToBePreparedBy: Moment(
                      preparationEstimatedToBeCompletedAt
                    ).format("H[u]mm")
                  })}
                </Typography>
                <div className={classes.largeSpacing} />
                <Typography>
                  <strong>
                    {t("order.extend-estimated-preparation-duration")}
                  </strong>
                </Typography>
                <Typography>
                  {t(
                    "order.inform-the-customer-that-the-order-pickup-will-be-slightly-delayed"
                  )}
                </Typography>
                <div className={classes.smallSpacing} />
                <div className={classes.addMinutesContainer}>
                  <Typography className={classes.addMinutesLabel}>
                    {t("order.add-minutes-to-preparation-duration")}
                  </Typography>
                  <SelectAmount
                    isDisabled={isDisabled}
                    min={1}
                    max={100}
                    step={1}
                    amount={minutesToAdd}
                    onChange={this.handleMinutesToAddChange}
                  />
                </div>
                <Typography variant="caption">
                  {t("order.modified-estimated-to-by-prepared-by", {
                    estimatedToBePreparedBy: Moment(
                      preparationEstimatedToBeCompletedAt
                    )
                      .add(minutesToAdd, "minutes")
                      .format("H[u]mm")
                  })}
                </Typography>
                <Button
                  fullWidth
                  variant="contained"
                  disabled={isDisabled}
                  onClick={this.handleConfirmModifyPreparationDuration}
                >
                  {t("order.modify-preparation-option")}
                </Button>
                <div className={classes.largeSpacing} />
                <Typography>
                  <strong>{t("order.complete-preparation-immediately")}</strong>
                </Typography>
                <Typography>
                  {t(
                    "order.inform-the-customer-that-the-order-is-already-ready-to-be-picked-up"
                  )}
                </Typography>
                <div className={classes.smallSpacing} />
                <SetOrderReadyForPickupButton
                  fullWidth
                  variant="contained"
                  base={base}
                  order={order}
                  onSet={onClose}
                />
              </>
            ) : (
              <Typography>
                {t("order.preparation-option-cannot-be-modified-anymore")}
              </Typography>
            )}
          </DialogContent>
        )}
        <DialogActions>
          <Button color="primary" disabled={isDisabled} onClick={onClose}>
            {t("label.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(ModifyPreparationOptionDialog)
);
