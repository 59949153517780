import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import withAuthorization from "../auth/withAuthorization";
import { PLAN_PRO } from "../../constants/plans";
import ScheduleMeetingDialog from "../help/ScheduleMeetingDialog";

const styles = (theme) => ({
  planRequested: {},
  spacing: {
    height: 20
  },
  scheduleMeetingButton: {
    display: "block",
    margin: "0 auto"
  }
});

class PlanRequestedDescription extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isScheduleMeetingDialogOpen: false
    };
  }

  handleScheduleMeeting = () => {
    this.setState({ isScheduleMeetingDialogOpen: true });
  };

  handleScheduleMeetingDialogClose = () => {
    this.setState({ isScheduleMeetingDialogOpen: false });
  };

  render() {
    const { classes, t, subscription } = this.props;
    const { isScheduleMeetingDialogOpen } = this.state;

    return (
      <div>
        <ScheduleMeetingDialog
          isOpen={isScheduleMeetingDialogOpen}
          onClose={this.handleScheduleMeetingDialogClose}
        />
        {subscription && subscription.isRequested() && (
          <div className={classes.planRequested}>
            <Typography>
              <strong>
                {t(
                  subscription.planName === PLAN_PRO
                    ? "subscription.pro-plan-requested"
                    : "subscription.full-service-plan-requested"
                )}
              </strong>
              <br />
              <br />
              {t("subscription.plan-requested-general-description")}
              {subscription &&
                !subscription.hadIntakeMeeting &&
                t("subscription.plan-requested-intake-meeting-description")}
            </Typography>
            <div className={classes.spacing} />
            {subscription && !subscription.hadIntakeMeeting && (
              <Button
                variant="contained"
                color="primary"
                className={classes.scheduleMeetingButton}
                onClick={this.handleScheduleMeeting}
              >
                {t("subscription.schedule-a-meeting")}
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withAuthorization()(
  withStyles(styles)(withTranslation("common")(PlanRequestedDescription))
);
