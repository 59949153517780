import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import EditScanners from "../scanner/EditScanners";

const styles = (theme) => ({
  spacing: {
    height: 25
  },
  description: {
    marginLeft: theme.spacing(2)
  }
});

class Scanners extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, bar, subscription, allBases, isDisabled } = this.props;

    return (
      <div title={t("promo.scan-order-links")}>
        <div className={classes.spacing}></div>
        <Typography className={classes.description}>
          {t("promo.share-the-scanner-link-and-pin-with-your-bar-personnel")}
          <br />
          <strong>
            {t("promo.attention-use-only-one-scanner-per-device")}
          </strong>
        </Typography>
        <EditScanners
          bar={bar}
          subscription={subscription}
          allBases={allBases}
          isDisabled={isDisabled}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(Scanners)
);
