import React, { PureComponent } from "react";
import withStyles from "@mui/styles/withStyles";

import withPasswordAuthentication from "../components/auth/withPasswordAuthentication";
import withAuthorization from "../components/auth/withAuthorization";
import SetupWizard from "../components/setup/SetupWizard";
import QuickStart from "../components/setup/QuickStart";

const styles = (theme) => ({});

class BarSetupPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isQuickStartOpen: false
    };
  }

  handleQuickStartClose = () => {
    this.setState({ isQuickStartOpen: false });
  };

  render() {
    const {
      bar,
      balance,
      assets,
      subscription,
      allBases,
      allMenus,
      allZones,
      pricing
    } = this.props;
    const { isQuickStartOpen } = this.state;

    return (
      <div>
        <SetupWizard
          bar={bar}
          balance={balance}
          assets={assets}
          subscription={subscription}
          allBases={allBases}
          allMenus={allMenus}
          allZones={allZones}
          pricing={pricing}
        />
        {isQuickStartOpen && (
          <QuickStart onClose={this.handleQuickStartClose} />
        )}
      </div>
    );
  }
}

export default withPasswordAuthentication()(
  withAuthorization()(withStyles(styles, { withTheme: true })(BarSetupPage))
);
