import React, { PureComponent } from "react";

import withStyles from "@mui/styles/withStyles";

import BellIcon from "react-bell-icon";

const styles = (theme) => ({
  alert: {
    position: "fixed",
    zIndex: 10000,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  bell: {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  title: {
    position: "absolute",
    top: "60%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontWeight: "bold",
    maxWidth: 300,
    height: "auto",
    textTransform: "uppercase",
    overflow: "visible"
  },
  subtitle: {
    position: "absolute",
    top: "80%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: 40,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "rgba(255, 255, 255, 0.8)"
  }
});

class OrderAlert extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      noMove: false
    };
  }

  componentDidMount() {
    document.addEventListener("mousemove", this.onMove);
    document.addEventListener("mousedown", this.onMove);
    document.addEventListener("touchstart", this.onMove);
  }

  componentWillUnmount() {
    document.removeEventListener("mousemove", this.onMove);
    document.removeEventListener("mousedown", this.onMove);
    document.removeEventListener("touchstart", this.onMove);

    if (this.setNoMoveTimeout) {
      clearTimeout(this.setNoMoveTimeout);
    }
    if (this.clearNoMoveTimeout) {
      clearTimeout(this.clearNoMoveTimeout);
    }
  }

  onMove = (event) => {
    const { timeout } = this.props;

    // Allow clearNoMove click to fire (and prevent click-through)
    if (this.clearNoMoveTimeout) {
      clearTimeout(this.clearNoMoveTimeout);
    }
    this.clearNoMoveTimeout = setTimeout(() => {
      this.setState({ noMove: false });
    }, 500);

    if (this.setNoMoveTimeout) {
      clearTimeout(this.setNoMoveTimeout);
    }
    this.setNoMoveTimeout = setTimeout(() => {
      this.setState({ noMove: true });
    }, timeout);
  };

  clearNoMove = (event) => {
    this.setState({ noMove: false });
  };

  render() {
    const { classes, isActive, title, subtitle } = this.props;
    const { noMove } = this.state;

    return (
      <div className={classes.container}>
        {isActive && noMove && (
          <div
            onClick={this.clearNoMove}
            className={`${classes.alert} animatedGradient`}
          >
            <div className={classes.bell}>
              <BellIcon
                width={120}
                height={120}
                animationSpeed={0.9}
                color="rgba(255,255,255,0.8)"
                active={true}
                animate={true}
              />
            </div>
            <svg className={classes.title} viewBox="0 0 100 20">
              <defs>
                <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
                  <stop offset="5%" stopColor="white" />
                  <stop offset="95%" stopColor="white" />
                </linearGradient>
                <pattern
                  id="wave"
                  x="0"
                  y="-1"
                  width="120"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <path
                    id="wavePath"
                    d="M-40 9 Q-30 7 -20 9 T0 9 T20 9 T40 9 T60 9 T80 9 T100 9 T120 9 V20 H-40z"
                    mask="url(#mask)"
                    fill="url(#gradient)"
                  >
                    <animateTransform
                      attributeName="transform"
                      begin="0s"
                      dur="1.5s"
                      type="translate"
                      from="0,0"
                      to="40,0"
                      repeatCount="indefinite"
                    />
                  </path>
                </pattern>
              </defs>
              <text
                textAnchor="middle"
                x="50"
                y="15"
                fontSize="17"
                fill="url(#wave)"
                fillOpacity="0.8"
              >
                {title}
              </text>
              <text
                textAnchor="middle"
                x="50"
                y="15"
                fontSize="17"
                fill="url(#gradient)"
                fillOpacity="0.6"
              >
                {title}
              </text>
            </svg>
            {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(OrderAlert);
