import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import ReceiptIcon from "@mui/icons-material/Receipt";
import LocalBarIcon from "@mui/icons-material/LocalBar";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  countContainer: {
    display: "flex",
    alignItems: "center",
    margin: `0 ${theme.spacing(2)}`
  },
  countIcon: {
    //marginRight: theme.spacing()
  },
  countAmount: {
    width: "100%",
    minWidth: 30,
    textAlign: "right"
  }
});

class OpenOrdersCounters extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getOpenOrdersCount = (menu) => {
    const { openOrders } = this.props;

    return openOrders ? openOrders.length : 0;
  };

  getOpenOrdersItemCount = (menu) => {
    const { openOrders } = this.props;

    return openOrders
      ? openOrders.reduce(
          (itemCount, order) => itemCount + order.getTotalAmount(),
          0
        )
      : 0;
  };

  render() {
    const { classes, t } = this.props;

    return (
      <>
        <div className={classes.countContainer}>
          <ReceiptIcon className={classes.countIcon} />
          <span className={classes.countAmount}>
            {this.getOpenOrdersCount()}
          </span>
        </div>
        <div className={classes.countContainer}>
          <LocalBarIcon className={classes.countIcon} />
          <span className={classes.countAmount}>
            {this.getOpenOrdersItemCount()}
          </span>
        </div>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(OpenOrdersCounters)
);
