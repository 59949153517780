import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import OpenOrders from "./OpenOrders";

const styles = (theme) => ({});

class OpenOrdersPerMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getOpenOrdersForMenu = (menu) => {
    const { openOrdersPerMenu } = this.props;

    if (openOrdersPerMenu && openOrdersPerMenu[menu.id]) {
      return openOrdersPerMenu[menu.id];
    }

    return [];
  };

  getOpenOrdersCountPerMinuteForMenu = (menu) => {
    const { openOrdersCountPerMenuAndMinute } = this.props;

    if (
      openOrdersCountPerMenuAndMinute &&
      openOrdersCountPerMenuAndMinute[menu.id]
    ) {
      return openOrdersCountPerMenuAndMinute[menu.id];
    }

    return [];
  };

  render() {
    const { classes, t, allMenus } = this.props;

    return (
      <div>
        {allMenus &&
          allMenus.map((menu) => (
            <OpenOrders
              key={menu.id}
              openOrders={this.getOpenOrdersForMenu(menu)}
              openOrdersCountPerMinute={this.getOpenOrdersCountPerMinuteForMenu(
                menu
              )}
              title={menu.name}
            />
          ))}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(OpenOrdersPerMenu)
);
