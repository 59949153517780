import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { dataService, menuService } from "tap-io/client/services";
import EnterValue from "tap-io/client/components/common/EnterValue";
import VatRateSelector from "tap-io/client/components/menu/VatRateSelector";

import UploadImage from "../common/UploadImage";
import { cloudStorage } from "tap-io/storage";
import SelectOrCreateProduct from "../catalogue/SelectOrCreateProduct";
import SelectDeposits from "../deposit/SelectDeposits";

const styles = (theme) => ({
  dialog: {
    //overflow: "visible",
    minHeight: 500
  },
  content: {
    //overflow: "visible"
  },
  productContainer: {
    margin: "0 -10px",
    padding: "5px 10px",
    borderRadius: 4,
    backgroundColor: theme.palette.background.default
  },
  priceContainer: {
    display: "flex",
    flexAlign: "row",
    alignItems: "center"
  },
  isHiddenContainer: {
    display: "flex",
    flexAlign: "row",
    alignItems: "center"
  },
  inputLabel: {
    width: "100%"
  },
  inputText: {
    margin: theme.spacing(1, 0, -1, 0)
  },
  currency: {
    marginTop: 16,
    marginLeft: 20
  },
  media: {
    height: 0,
    marginTop: 10,
    paddingTop: "56.25%", // 16:9
    backgroundSize: "contain"
  },
  spacing: {
    height: 20
  }
});

class EditItemDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (item) => {
    const { catalogue } = this.props;

    return {
      isDisabled: false,
      name: item ? item.name : "",
      description: item ? item.description : "",
      price: item ? item.price : "",
      productId: item ? item.productId : "",
      vatRate: item && item.vatRate ? item.vatRate : "",
      isHidden: item ? item.isHidden === true : false,
      imageFilename: item ? item.imageFilename : null,
      imageUrl: null,
      product:
        item && item.productId
          ? catalogue.findProductById(item.productId)
          : null,
      depositIds: item ? item.depositIds : []
    };
  };

  componentDidMount() {
    const { item } = this.props;

    if (item) {
      this.setState(this.initialState(item));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen, item } = this.props;
    const { imageFilename } = this.state;
    const prevImageFilename = prevState.imageFilename;

    if (isOpen && !prevProps.isOpen) {
      this.setState(this.initialState(item), this.refreshItemImageUrl);
    } else if (imageFilename !== prevImageFilename) {
      this.refreshItemImageUrl();
    }
  }

  refreshItemImageUrl = async () => {
    const { menu } = this.props;
    const { imageFilename } = this.state;

    if (menu) {
      try {
        const item = menuService.createMenuItem(menu.id, { imageFilename });
        const imageUrl = await cloudStorage.getBarMenuItemImageUrl(
          menu.barId,
          item
        );

        this.setState({ imageUrl });
      } catch (error) {
        console.warn(error);

        this.setState({ imageUrl: null });
      }
    } else {
      this.setState({ imageUrl: null });
    }
  };

  handleNameChange = (event) => {
    const val = event.target.value;
    this.setState({ name: val });
  };

  handleProductChange = (product) => {
    const toUpdate = { product };
    if (product) {
      toUpdate.name = product.name;
      toUpdate.vatRate = product.vatRate || "";
    }
    this.setState(toUpdate);
  };

  handleVatRateChange = (vatRate) => {
    this.setState({ vatRate });
  };

  handleDescriptionChange = (event) => {
    const val = event.target.value;
    this.setState({ description: val });
  };

  handlePriceChange = (value) => {
    this.setState({ price: value });
  };

  handleIsHiddenChange = (event) => {
    const value = !(event.target.checked === true);
    this.setState({ isHidden: value });
  };

  handleImageUploadSuccess = (filename) => {
    this.setState({ imageFilename: filename });
  };

  handleDepositIdsChange = (depositIds) => {
    this.setState({ depositIds });
  };

  handleImageUploadError = (error) => {
    if (error) {
      toast.error(error.message);
    }
  };

  handleImageRemove = () => {
    this.setState({ imageFilename: null });
  };

  handleUpdateItem = async () => {
    const { t, bar, menu, catalogue, item, onClose } = this.props;
    const {
      name,
      description,
      price,
      imageFilename,
      product,
      vatRate,
      isHidden,
      depositIds
    } = this.state;

    const toastId = toast(t("menu.updating-item"), { autoClose: false });
    this.setState({ isDisabled: true });

    try {
      if (product && !catalogue.doesProductExist(product.id)) {
        await dataService.addCustomProductToCatalogueData(bar.id, product);
      }

      item.name = name.trim();
      item.description = description.trim();
      item.price = price;
      item.productId = product ? product.id : null;
      item.vatRate = vatRate || null;
      item.isHidden = isHidden;
      item.depositIds = depositIds;
      item.imageFilename = imageFilename;

      await menuService.updateMenuItem(menu, item);

      toast.update(toastId, {
        render: t("menu.item-updated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false }, onClose);
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(error.message)})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, isOpen, onClose, bar, menu, catalogue, deposits } =
      this.props;
    const {
      isDisabled,
      name,
      description,
      price,
      product,
      vatRate,
      isHidden,
      depositIds,
      imageUrl
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle>{t("menu.edit-item")}</DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText>{t("menu.edit-item-below")}</DialogContentText>
          <div className={classes.spacing} />
          <div className={classes.productContainer}>
            <SelectOrCreateProduct
              label={t("menu.linked-product")}
              //helperText={t("menu.product-linked-with-this-item")}
              bar={bar}
              catalogue={catalogue}
              product={product}
              onChange={this.handleProductChange}
            />
          </div>
          <TextField
            inputProps={{
              "data-cy": "item-name"
            }}
            variant="standard"
            margin="dense"
            label={t("label.name")}
            //helperText={t("menu.name-of-this-item")}
            fullWidth
            value={name}
            disabled={isDisabled}
            onChange={this.handleNameChange}
          />
          <TextField
            inputProps={{
              "data-cy": "item-description"
            }}
            variant="standard"
            margin="dense"
            label={t("menu.description")}
            type="text"
            //helperText={t("menu.description-of-this-item")}
            fullWidth
            value={description}
            disabled={isDisabled}
            onChange={this.handleDescriptionChange}
          />
          <EnterValue
            value={price}
            isDisabled={isDisabled}
            label={t("menu.price")}
            unit={bar.getOrderCurrency()}
            onChange={this.handlePriceChange}
          />
          <VatRateSelector
            fullWidth
            variant="standard"
            margin="dense"
            allowEmpty={true}
            vatRate={vatRate}
            isDisabled={isDisabled}
            onChange={this.handleVatRateChange}
          />
          <div>
            <InputLabel
              shrink={true}
              className={classNames(classes.inputLabel, classes.inputText)}
            >
              {t("menu.deposits")}
            </InputLabel>
            <SelectDeposits
              isDisabled={isDisabled}
              deposits={deposits}
              selectedDepositIds={depositIds}
              onChange={this.handleDepositIdsChange}
            />
          </div>
          {menu && (
            <UploadImage
              inputProps={{
                "data-cy": "item-image-upload"
              }}
              label={t("label.upload-new-image")}
              isDisabled={isDisabled}
              uploadRef={cloudStorage.getBarMenuItemImagesRef(
                menu.barId,
                menu.id
              )}
              onSuccess={this.handleImageUploadSuccess}
              onError={this.handleImageUploadError}
            />
          )}
          {imageUrl && (
            <div>
              <CardMedia
                data-cy="item-image"
                className={classes.media}
                image={imageUrl}
                title={t("label.image")}
              />
              <Button
                data-cy="item-image-remove"
                fullWidth
                disabled={isDisabled}
                onClick={this.handleImageRemove}
              >
                {t("label.remove-image")}
              </Button>
            </div>
          )}
          <div className={classes.isHiddenContainer}>
            <Typography className={classes.inputLabel}>
              {t("menu.show-item-on-order-page")}
            </Typography>
            <Switch
              edge="end"
              disabled={isDisabled}
              checked={!isHidden}
              onChange={this.handleIsHiddenChange}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button disabled={isDisabled} onClick={onClose} color="secondary">
            {t("label.cancel")}
          </Button>
          <Button
            data-cy="item-add"
            disabled={isDisabled}
            onClick={this.handleUpdateItem}
            color="primary"
          >
            {t("label.update")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(EditItemDialog));
