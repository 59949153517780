import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import { Button, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import LayoutNodeType from "tap-io/models/zone/LayoutNodeType";
import EditSeriesLayoutNode from "./EditSeriesLayoutNode";
import EditSequenceLayoutNode from "./EditSequenceLayoutNode";

import EditSingleLayoutNode from "./EditSingleLayoutNode";
import EditZoneLayoutNode from "./EditZoneLayoutNode";
import ChangeLayoutNodeTypeMenu from "./ChangeLayoutNodeTypeMenu";

const styles = (theme) => ({
  layoutNode: {
    overflowY: "scroll",
    position: "relative",
    width: "100%",
    margin: `${theme.spacing()} 0`,
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    backgroundColor: theme.palette.background.default
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: theme.spacing()
  },
  title: {
    width: "100%",
    fontWeight: "bold"
  }
});

class EditLayoutNode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changeLayoutNodeTypeMenuAnchor: null
    };
  }

  handleLayoutUpdate = (layoutNode) => {
    const { onUpdate } = this.props;

    onUpdate(layoutNode.root);
  };

  handleLayoutNodeTypeChange = (layoutNode) => {
    const { onSelect } = this.props;

    this.handleLayoutUpdate(layoutNode);
    onSelect(layoutNode);

    this.handleChangeLayoutNodeTypeMenuClose();
  };

  handleLayoutNodeTypeClick = (event) => {
    this.setState({ changeLayoutNodeTypeMenuAnchor: event.currentTarget });
  };

  handleChangeLayoutNodeTypeMenuClose = () => {
    this.setState({ changeLayoutNodeTypeMenuAnchor: null });
  };

  render() {
    const { classes, t, isDisabled, bar, allBases, layoutNode } = this.props;
    const { changeLayoutNodeTypeMenuAnchor } = this.state;

    return (
      layoutNode && (
        <div className={classes.layoutNode}>
          <ChangeLayoutNodeTypeMenu
            anchor={changeLayoutNodeTypeMenuAnchor}
            onClose={this.handleChangeLayoutNodeTypeMenuClose}
            onUpdate={this.handleLayoutNodeTypeChange}
            layoutNode={layoutNode}
          />
          <div className={classes.header}>
            <Typography variant="h6" className={classes.title}>
              {layoutNode.toString()}
            </Typography>
            {layoutNode.type !== LayoutNodeType.ZONE && (
              <Button onClick={this.handleLayoutNodeTypeClick}>
                {t(`zone.${layoutNode.type}-layout-node`)}
              </Button>
            )}
          </div>
          {layoutNode.type === LayoutNodeType.ZONE ? (
            <EditZoneLayoutNode
              isDisabled={isDisabled}
              bar={bar}
              allBases={allBases}
              layoutNode={layoutNode}
              onUpdate={this.handleLayoutUpdate}
            />
          ) : layoutNode.type === LayoutNodeType.SERIES ? (
            <EditSeriesLayoutNode
              isDisabled={isDisabled}
              bar={bar}
              allBases={allBases}
              layoutNode={layoutNode}
              onUpdate={this.handleLayoutUpdate}
            />
          ) : layoutNode.type === LayoutNodeType.SEQUENCE ? (
            <EditSequenceLayoutNode
              isDisabled={isDisabled}
              bar={bar}
              allBases={allBases}
              layoutNode={layoutNode}
              onUpdate={this.handleLayoutUpdate}
            />
          ) : layoutNode.type === LayoutNodeType.SINGLE ? (
            <EditSingleLayoutNode
              isDisabled={isDisabled}
              bar={bar}
              allBases={allBases}
              layoutNode={layoutNode}
              onUpdate={this.handleLayoutUpdate}
            />
          ) : null}
        </div>
      )
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditLayoutNode)
);
