import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import GenerateQRCode from "./GenerateQRCode";

const styles = (theme) => ({});

class BarLink extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, bar, assets } = this.props;

    return (
      <div title={t("promo.public-order-page")}>
        <Typography>
          {t("promo.share-this-page-with-your-customers")}
        </Typography>
        <div className={classes.spacing}></div>
        <GenerateQRCode bar={bar} assets={assets} />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(BarLink)
);
