import React, { PureComponent } from "react";
import classNames from "classnames";

import withStyles from "@mui/styles/withStyles";

import { IconButton, InputBase } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";

const styles = (theme) => ({
  bar: {
    display: "flex",
    position: "relative",
    width: "100%"
  },
  icon: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    height: "100%",
    padding: theme.spacing(0, 1)
  },
  fieldRoot: {
    width: "100%"
  },
  fieldInput: {
    width: "100%",
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: theme.spacing(6),
    textOverflow: "ellipsis",
    transition: theme.transitions.create("width"),
    color: "inherit"
  },
  fieldInputWithClearButton: {
    paddingRight: `calc(2em + ${theme.spacing(2)})`
  },
  clearButton: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
    padding: theme.spacing(0, 1.5)
  }
});

class SearchBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}

  handleClear = () => {
    const { onChange } = this.props;
    onChange("");
  };

  handleChange = (event) => {
    const { onChange } = this.props;
    const value = event.target.value;
    onChange(value);
  };

  render() {
    const { classes, placeholder, query } = this.props;

    return (
      <div className={classes.bar}>
        <div className={classes.icon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder={placeholder}
          classes={{
            root: classes.fieldRoot,
            input: classNames(
              classes.fieldInput,
              query ? classes.fieldInputWithClearButton : undefined
            )
          }}
          value={query}
          onChange={this.handleChange}
        />
        {query && (
          <IconButton
            className={classes.clearButton}
            onClick={this.handleClear}
            size="large"
          >
            <CancelIcon />
          </IconButton>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(SearchBar);
