import React, { PureComponent } from "react";

import OrderStatus from "tap-io/models/order/OrderStatus";
import { utilsHelper } from "tap-io/helpers";

const REFRESH_CLOCK_INTERVAL = 10000;

const withEstimatedPreparationInfo = (options) => (Component) => {
    class WithEstimatedPreparationInfo extends PureComponent {
        constructor(props) {
            super(props);

            this.state = {
                estimatedPreparationInfo: this.getEstimatedPreparationInfo()
            };
        }

        componentDidMount() {
            this.checkClock();
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            this.checkClock();
        }

        componentWillUnmount() {
            this.stopClock();
        }

        checkClock = () => {
            const { base, order } = this.props;

            if (
                base &&
                order &&
                order.getStatus(base) === OrderStatus.CLAIMED &&
                order.delivery.getEstimatedPreparationDurationInMinutes(base.id) !== null
            ) {
                this.startClock();
            } else {
                this.stopClock();
            }
        };

        startClock = () => {
            this.refreshEstimatedPreparationInfo();

            // Don't start clock if estimatedPrepartionInfo is set as prop
            if (this.props.estimatedPreparationInfo) {
                return;
            }

            // TO FIX: optimise
            if (this.clockInterval) {
                return;
            }

            this.clockInterval = setInterval(this.refreshEstimatedPreparationInfo, REFRESH_CLOCK_INTERVAL);
        };

        stopClock = () => {
            if (this.clockInterval) {
                clearInterval(this.clockInterval);
                this.clockInterval = undefined;
            }
        };

        refreshEstimatedPreparationInfo = () => {
            const prevRemainingTime = this.state.estimatedPreparationInfo?.remainingTime;

            const estimatedPreparationInfo = this.getEstimatedPreparationInfo();

            if (estimatedPreparationInfo.remainingTime !== prevRemainingTime) {
                this.setState({ estimatedPreparationInfo });
            }
        }

        getEstimatedPreparationInfo = () => {
            const { timeOffset, base, order } = this.props;

            const estimatedPreparationInfo = order.getEstimatedPreparationInfo(
                (date) => {
                    console.log(utilsHelper.calculateRemainingTime(date, timeOffset))
                    console.log(utilsHelper.calculateRemainingTime(date, timeOffset, 'seconds'))

                    return utilsHelper.calculateRemainingTime(date, timeOffset)
                },
                base
            );

            return estimatedPreparationInfo;
        }

        render() {
            const estimatedPreparationInfo = this.props.estimatedPreparationInfo || this.state.estimatedPreparationInfo;

            return <Component {...this.props} estimatedPreparationInfo={estimatedPreparationInfo} />;
        }
    }

    return WithEstimatedPreparationInfo;
};

export default withEstimatedPreparationInfo;
