import React, { PropsWithChildren, useEffect, useState } from "react";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Theme
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import InputIcon from "@mui/icons-material/Input";
import OutputIcon from "@mui/icons-material/Output";
import { createStyles, makeStyles } from "@mui/styles";

import { depositService } from "tap-io/client/services";
import ConfirmDialog from "tap-io/client/components/common/ConfirmDialog";
import Bar from "tap-io/models/bar/Bar";
import Deposit from "tap-io/models/deposit/Deposit";

import AddDepositDialog from "../deposit/AddDepositDialog";
import EditDepositDialog from "../deposit/EditDepositDialog";


const useStyles = makeStyles((theme: Theme) => createStyles({
  value: {
    display: "flex",
    alignItems: "center"
  },
  valueText: {},
  valueIcon: {
    marginRight: theme.spacing(0.5),
    fontSize: 16
  },
  valueDivider: {
    margin: theme.spacing(0, 1)
  }
}));

type EditDepositsProps = {
  bar: Bar;
};

function EditDeposits({ bar }: PropsWithChildren<EditDepositsProps>) {
  const { t, i18n } = useTranslation("common");
  const classes = useStyles();

  const [isAddDepositDialogOpen, setIsAddDepositDialogOpen] = useState(false);
  const [
    depositPendingRemovalConfirmation,
    setDepositPendingRemovalConfirmation
  ] = useState<Deposit | null>(null);
  const [depositBeingEdited, setDepositBeingEdited] = useState<Deposit | null>(
    null
  );
  const [isReady, setIsReady] = useState(false);
  const [deposits, setDeposits] = useState<Deposit[]>([]);

  useEffect(() => {
    if (bar?.id) {
      depositService.onAllDeposits(bar.id, (deposits) => {
        setIsReady(true);
        setDeposits(deposits);
      });
    } else {
      setIsReady(false);
      setDeposits([]);
    }
  }, [bar]);

  const handleAddDeposit = (event) => {
    setIsAddDepositDialogOpen(true);
  };

  const handleAddDepositDialogClose = () => {
    setIsAddDepositDialogOpen(false);
  };

  const handleEditDeposit = (deposit) => (event) => {
    setDepositBeingEdited(deposit);
  };

  const handleEditDepositDialogClose = () => {
    setDepositBeingEdited(null);
  };

  const handleRemoveDeposit = (deposit) => (event) => {
    event.stopPropagation();

    setDepositPendingRemovalConfirmation(deposit);
  };

  const handleRemoveDepositCancel = () => {
    setDepositPendingRemovalConfirmation(null);
  };

  const handleRemoveDepositConfirm = async () => {
    if (!depositPendingRemovalConfirmation) {
      toast.error("error.deposit-is-not-defined");
      return;
    }

    const toastId = toast(t("deposit.removing-deposit"), {
      autoClose: false
    });

    try {
      await depositService.removeDeposit(depositPendingRemovalConfirmation);

      setDepositPendingRemovalConfirmation(null);

      toast.update(toastId, {
        render: t("deposit.deposit-removed"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);
      setDepositPendingRemovalConfirmation(null);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  const handleDepositIsActiveChange = (deposit) => async (event) => {
    event.preventDefault();

    const toastId = toast(
      deposit.isActive
        ? t("deposit.disabling-deposit")
        : t("deposit.enabling-deposit"),
      {
        autoClose: false
      }
    );

    try {
      deposit.isActive = !deposit.isActive;
      await depositService.updateDeposit(deposit);

      toast.update(toastId, {
        render: deposit.isActive
          ? t("deposit.deposit-enabled")
          : t("deposit.deposit-disabled"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  return (
    <div>
      <AddDepositDialog
        isOpen={isAddDepositDialogOpen}
        onClose={handleAddDepositDialogClose}
        bar={bar}
      />
      <EditDepositDialog
        isOpen={depositBeingEdited !== null}
        onClose={handleEditDepositDialogClose}
        bar={bar}
        deposit={depositBeingEdited}
      />
      <ConfirmDialog
        isOpen={depositPendingRemovalConfirmation !== null}
        title={t("deposit.confirm-removal-of-deposit")}
        description={t("deposit.are-you-sure-you-want-to-remove-this-deposit")}
        confirmButtonLabel={t("label.remove")}
        onConfirm={handleRemoveDepositConfirm}
        onCancel={handleRemoveDepositCancel}
      />
      <List>
        {isReady &&
          deposits.map((deposit) => (
            <ListItem key={deposit.id} onClick={handleEditDeposit(deposit)}>
              <ListItemIcon>
                <IconButton onClick={handleRemoveDeposit(deposit)} size="large">
                  <DeleteIcon />
                </IconButton>
              </ListItemIcon>
              <ListItemText
                primary={deposit.name}
                secondary={
                  <span className={classes.value}>
                    <OutputIcon className={classes.valueIcon} /><span className={classes.valueText}>{`${deposit.purchaseValue} ${bar.getOrderCurrency()}`}</span>
                    <span className={classes.valueDivider}>&bull;</span>
                    <InputIcon className={classes.valueIcon} /><span className={classes.valueText}>{`${deposit.tradeInValue} ${bar.getOrderCurrency()}`}</span>
                  </span>
                }
              />
              <ListItemSecondaryAction>
                <Switch
                  checked={deposit.isActive}
                  onChange={handleDepositIsActiveChange(deposit)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        <ListItem>
          <Button fullWidth startIcon={<AddIcon />} onClick={handleAddDeposit}>
            {t("deposit.add-deposit")}
          </Button>
        </ListItem>
      </List>
    </div>
  );
}

export default EditDeposits;
