import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import Select from "react-select";

import withStyles from "@mui/styles/withStyles";

import { utilsHelper } from "tap-io/helpers";

const styles = (theme) => ({
  select: {
    width: "100%"
  }
});

class SelectProduct extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      options: []
    };
  }

  componentDidMount() {
    this.refreshOptions();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { catalogue, searchInProductIds } = this.props;
    const prevCatalogue = prevProps.catalogue;
    const prevSearchInProductIds = prevProps.searchInProductIds;

    if (
      (!prevCatalogue && catalogue) ||
      !utilsHelper.areArraysEqualShallow(
        searchInProductIds,
        prevSearchInProductIds
      )
    ) {
      this.refreshOptions();
    }
  }

  refreshOptions = () => {
    const { catalogue, searchInProductIds } = this.props;

    this.setState({
      options: catalogue
        ? catalogue.products
            .map((product) => {
              return {
                value: product.id,
                label: product.name
              };
            })
            .filter(
              (product) =>
                !searchInProductIds ||
                searchInProductIds.indexOf(product.value) >= 0
            )
        : []
    });
  };

  handleChange = (newValue, actionMeta) => {
    const { catalogue, onSelect } = this.props;

    onSelect(catalogue.getProduct(newValue.value));
  };

  render() {
    const { classes, t, isDisabled, maxMenuHeight } = this.props;
    const { options } = this.state;

    return (
      <Select
        className={classes.select}
        disabled={isDisabled}
        value={null}
        onChange={this.handleChange}
        options={options}
        isClearable={false}
        isSearchable={true}
        maxMenuHeight={maxMenuHeight}
        placeholder={t("catalogue.search-for-a-product")}
      />
    );
  }
}

export default withStyles(styles)(withTranslation("common")(SelectProduct));
