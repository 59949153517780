import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";

export type DownloadCSVButtonProps = {
  title?: string;
  mark?: string;
  csv: string;
};

export function DownloadCSVButton(props: DownloadCSVButtonProps) {
  const { title, mark, csv } = props;

  return (
    <Button
      href={"data:text/csv;charset=utf-8," + encodeURIComponent(csv)}
      download={`${title}${mark ? `-${mark}` : ""}.csv`}
      disabled={!csv}
      endIcon={<DownloadIcon />}
    >
      {title}
    </Button>
  );
}
