import React, { PureComponent } from "react";
import classNames from "classnames";

import { toast } from "react-toastify";

import { withTranslation } from "react-i18next";

import Moment from "moment";

import { QRCode } from "react-qrcode-logo";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";
import { barService, voucherService } from "tap-io/client/services";
import { voucherHelper } from "tap-io/helpers";
import VoucherLog from "tap-io/client/components/voucher/VoucherLog";

import { hasNativeWrapper, printer } from "../../native";

const styles = (theme) => ({
  dialog: {
    minWidth: 400
  },
  orderUrlContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2)
  },
  status: {
    fontWeight: "bold"
  },
  active: {
    color: "green"
  },
  inactive: {
    color: "red"
  },
  link: {
    textDecoration: "none",
    color: "black"
  },
  voucherUrl: {
    fontFamily: "'Anonymous Pro', 'Courier New', Courier, monospace"
  }
});

class ShowVoucherDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      voucherUrl: null
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { voucher } = this.props;

    const voucherId = voucher ? voucher.id : undefined;
    const prevVoucherId = prevProps.voucher ? prevProps.voucher.id : undefined;

    if (voucherId !== prevVoucherId) {
      this.refreshVoucherUrl();
    }
  }

  refreshVoucherUrl = async () => {
    const { bar, voucher } = this.props;

    if (voucher) {
      try {
        const barUrl = await barService.getBarUrl(
          bar,
          config.hosting.orderDomain
        );
        const voucherPin = await voucherService.getVoucherPin(voucher);
        const voucherCode = voucherHelper.encodeVoucherCode(
          voucher.id,
          voucherPin
        );

        const voucherUrl = voucherHelper.getVoucherUrl(barUrl, voucherCode);

        this.setState({ voucherPin, voucherUrl });
      } catch (error) {
        console.warn(error);

        this.setState({ voucherUrl: null });
      }
    } else {
      this.setState({ voucherUrl: null });
    }
  };

  handleIsActiveChange = async (event) => {
    const { t, voucher } = this.props;
    const isActive = event.target.checked === true;

    const toastId = toast(
      t(
        voucher.isActive
          ? "voucher.deactivating-voucher"
          : "voucher.activating-voucher"
      ),
      { autoClose: false }
    );
    this.setState({ isDisabled: true });

    try {
      voucher.isActive = isActive;
      await voucherService.updateVoucher(voucher);

      toast.update(toastId, {
        render: t(
          isActive ? "voucher.voucher-activated" : "voucher.voucher-deactivated"
        ),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.message})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleIsPaidChange = async (event) => {
    const { t, voucher } = this.props;
    const isPaid = event.target.checked === true;

    const toastId = toast(
      t(
        voucher.payment.isPaid
          ? "voucher.setting-voucher-as-unpaid"
          : "voucher.setting-voucher-as-paid"
      ),
      { autoClose: false }
    );
    this.setState({ isDisabled: true });

    try {
      voucher.payment = voucherService.createVoucherPayment({
        ...voucher.payment.toJSON(),
        isPaid
      });
      await voucherService.updateVoucher(voucher);

      toast.update(toastId, {
        render: t(
          isPaid
            ? "voucher.voucher-set-as-paid"
            : "voucher.voucher-set-as-unpaid"
        ),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.message})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handlePrintVoucher = async () => {
    const { t, bar, deviceSettings, assets, voucher } = this.props;
    const { voucherPin, voucherUrl } = this.state;

    try {
      if (hasNativeWrapper() && voucherPin && voucherUrl) {
        await printer.printVoucher(
          t,
          bar,
          assets,
          voucher,
          voucherPin,
          voucherUrl,
          deviceSettings.printBarLogo,
          1
        );
      }
    } catch (error) {
      if (error) {
        toast.error(error.message);
      }
      console.warn(error);
    }
  };

  getTranslator = () => {
    const { t, catalogue } = this.props;

    return (key, options) => {
      const product = catalogue ? catalogue.findProductById(key) : null;

      return product ? product.name : t(key, options);
    };
  };

  render() {
    const { classes, t, isOpen, onClose, bar, voucher } = this.props;
    const { voucherPin, voucherUrl } = this.state;

    const translator = this.getTranslator();

    const voucherValue = voucher ? voucher.originalValueToString(t) : undefined;
    const voucherValidUntilDate = voucher
      ? Moment(voucher.validUntil).format("DD/MM/YYYY")
      : undefined;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle>
          {voucher?.label ? voucher?.label : t("voucher.voucher")} &bull;{" "}
          {voucherValue}
        </DialogTitle>
        <DialogContent>
          {voucher && (
            <div>
              <Typography variant="subtitle2">
                {t("voucher.general")}
              </Typography>
              <List>
                {!voucher.isExpired && (
                  <ListItem>
                    <ListItemText
                      primary={
                        <span>
                          {`${t("voucher.this-voucher-is-x")} `}
                          <span
                            className={classNames(
                              classes.status,
                              voucher.isActive
                                ? classes.active
                                : classes.inactive
                            )}
                          >
                            {t(
                              voucher.isActive
                                ? "label.active"
                                : "label.inactive"
                            )}
                          </span>
                        </span>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Switch
                        onChange={this.handleIsActiveChange}
                        checked={voucher.isActive}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
                {voucher.payment && (
                  <ListItem>
                    <ListItemText
                      primary={
                        <span>
                          {`${t("voucher.this-voucher-is-paid-with")} `}
                          <strong>
                            {t(
                              `payment.payment-method-${voucher.payment.method}`
                            )}
                          </strong>
                        </span>
                      }
                    />
                  </ListItem>
                )}
                {voucher.customer && (
                  <ListItem>
                    <ListItemText
                      primary={t("voucher.customer-email")}
                      secondary={voucher.customer.email}
                    />
                  </ListItem>
                )}
                <ListItem>
                  <ListItemText
                    primary={t("voucher.original-value")}
                    secondary={voucher.originalValueToString(translator)}
                  />
                </ListItem>
                {voucher.isExpired ? (
                  <ListItem>
                    <ListItemText
                      primary={t("voucher.used-value")}
                      secondary={voucher.usedValueToString(translator)}
                    />
                  </ListItem>
                ) : (
                  <ListItem>
                    <ListItemText
                      primary={t("voucher.remaining-value")}
                      secondary={voucher.remainingValueToString(translator)}
                    />
                  </ListItem>
                )}
                <ListItem>
                  <ListItemText
                    primary={t("voucher.code")}
                    secondary={voucher.id}
                  />
                </ListItem>
                {voucherPin && (
                  <ListItem>
                    <ListItemText
                      primary={t("voucher.pin")}
                      secondary={voucherPin}
                    />
                  </ListItem>
                )}
                {hasNativeWrapper() && voucherPin && voucherUrl && (
                  <ListItem>
                    <Button
                      fullWidth
                      variant="contained"
                      startIcon={<PrintIcon />}
                      onClick={this.handlePrintVoucher}
                    >
                      {t("voucher.print-voucher")}
                    </Button>
                  </ListItem>
                )}
              </List>
            </div>
          )}
          {voucher && voucher.isValid && voucherUrl && (
            <div>
              <Typography variant="subtitle2">
                {t("voucher.voucher-url")}
              </Typography>
              <div className={classes.orderUrlContainer}>
                <DialogContentText>
                  {t("voucher.link-for-order-page-with-voucher-of-below", {
                    voucherValue,
                    voucherValidUntilDate
                  })}
                  {voucher && voucher.mustBeRedeemedInOneGo && (
                    <span>
                      <br />
                      <br />
                      <strong>
                        {t("voucher.voucher-must-be-redeemed-in-one-go")}
                      </strong>
                      <br />
                      <br />
                    </span>
                  )}
                </DialogContentText>
                <a
                  className={classes.link}
                  href={voucherUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography variant="caption" className={classes.voucherUrl}>
                    {voucherUrl}
                  </Typography>
                </a>
                <QRCode className={classes.qr} value={voucherUrl} />
              </div>
            </div>
          )}
          <Typography variant="subtitle2">{t("voucher.logs")}</Typography>
          <VoucherLog bar={bar} voucher={voucher} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {t("label.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(ShowVoucherDialog));
