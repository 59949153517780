import { Box } from "@mui/material";
import { Asyncer } from "../../../components/common/Asyncer";
import { Async } from "../../../components/common/useAsync";
import { DepositReport } from "../../models/DepositReport";
import { FeeReport } from "../../models/FeeReport";
import { FinancialDeviceReport } from "../../models/FinancialDeviceReport";
import { FinancialLocationReport } from "../../models/FinancialLocationReport";
import { FinancialTimeslotReport } from "../../models/FinancialTimeslotReport";
import { PaymentReport } from "../../models/PaymentReport";
import { ProductReport } from "../../models/ProductReport";
import { SaleReport } from "../../models/SaleReport";
import { TransactionReport } from "../../models/TransactionReport";
import { DownloadCSVButton } from "../common/DownloadCSVButton";
import { StatCard } from "../common/StatCard";

export type FinancialReportDownloadsProps = {
  transaction: Async<TransactionReport>;
  sale: Async<SaleReport>;

  product: Async<ProductReport>;
  deposit: Async<DepositReport>;
  fee: Async<FeeReport>;
  payment: Async<PaymentReport>;
  device: Async<FinancialDeviceReport>;
  location: Async<FinancialLocationReport>;
  hour: Async<FinancialTimeslotReport>;

  date: Date;
  mark: string;
  t: any;
};

export function FinancialReportDownloads(props: FinancialReportDownloadsProps) {
  const {
    transaction,
    sale,
    product,
    deposit,
    fee,
    payment,
    device,
    location,
    hour,
    date,
    mark,
    t
  } = props;

  const title = t("stats.stat.downloads.title");
  const description = t("stats.stat.downloads.description");

  return (
    <StatCard title={title} description={description}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-evenly"
        }}
      >
        <Asyncer async={transaction}>
          {(report) => (
            <DownloadCSVButton
              title={t("stats.stat.financial-transaction.title")}
              mark={mark}
              csv={report.toCSV(t)}
            />
          )}
        </Asyncer>

        <Asyncer async={sale}>
          {(report) => (
            <DownloadCSVButton
              title={t("stats.stat.financial-sale.title")}
              mark={mark}
              csv={report.toCSV(t)}
            />
          )}
        </Asyncer>

        <Asyncer async={product}>
          {(report) => (
            <DownloadCSVButton
              title={t("stats.stat.financial-product.title")}
              mark={mark}
              csv={report.toCSV(t)}
            />
          )}
        </Asyncer>

        <Asyncer async={deposit}>
          {(report) => (
            <DownloadCSVButton
              title={t("stats.stat.financial-deposit.title")}
              mark={mark}
              csv={report.toCSV(t)}
            />
          )}
        </Asyncer>

        <Asyncer async={fee}>
          {(report) => (
            <DownloadCSVButton
              title={t("stats.stat.financial-fee.title")}
              mark={mark}
              csv={report.toCSV(t)}
            />
          )}
        </Asyncer>
        <Asyncer async={payment}>
          {(report) => (
            <DownloadCSVButton
              title={t("stats.stat.financial-payment.title")}
              mark={mark}
              csv={report.toCSV(t)}
            />
          )}
        </Asyncer>

        <Asyncer async={device}>
          {(report) => (
            <DownloadCSVButton
              title={t("stats.stat.financial-device.title")}
              mark={mark}
              csv={report.toCSV(t)}
            />
          )}
        </Asyncer>

        <Asyncer async={location}>
          {(report) => (
            <DownloadCSVButton
              title={t("stats.stat.financial-location.title")}
              mark={mark}
              csv={report.toCSV(t)}
            />
          )}
        </Asyncer>

        <Asyncer async={hour}>
          {(report) => (
            <DownloadCSVButton
              title={t("stats.stat.financial-timeslot.title")}
              mark={mark}
              csv={report.toCSV(date, t)}
            />
          )}
        </Asyncer>
      </Box>
    </StatCard>
  );
}
