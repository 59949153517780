import DownloadIcon from "@mui/icons-material/Download";
import { IconButton } from "@mui/material";

export type DownloadCSVIconButtonProps = {
  title?: string;
  csv: string;
};

export function DownloadIconCSVButton(props: DownloadCSVIconButtonProps) {
  const { title, csv } = props;

  return (
    <IconButton
      href={"data:text/csv;charset=utf-8," + encodeURIComponent(csv)}
      download={`${title}.csv`}
      disabled={!csv}
    >
      <DownloadIcon />
    </IconButton>
  );
}
