import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import BorderOuterIcon from "@mui/icons-material/BorderOuter";
import withStyles from "@mui/styles/withStyles";

const styles = {};

class BaseInfo extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, bar, base, allBases, allZones } = this.props;

    const zonesForBase =
      base && allZones
        ? allZones.filter((zone) => zone.baseIds.indexOf(base.id) >= 0)
        : [];

    return (
      <List>
        {bar.isUsingZones() && (
          <div>
            <Typography>{t("base.you-serve-following-zones")}</Typography>
            {zonesForBase.length > 0 ? (
              zonesForBase.map((zone) => (
                <ListItem key={zone.id}>
                  <ListItemIcon>
                    <BorderOuterIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={zone.name}
                    secondary={`${t("zone.zone")} ${zone.code}`}
                  />
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemText secondary={t("zone.no-zones-found")} />
              </ListItem>
            )}
          </div>
        )}
      </List>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(BaseInfo));
