import React, { PropsWithChildren } from "react";

import { barService } from "tap-io/client/services";
import Bar from "tap-io/models/bar/Bar";

import EditAsset from "./EditAsset";

type EditLogoProps = {
  bar: Bar;
  url: string;
};

function EditLogo({ bar, url }: PropsWithChildren<EditLogoProps>) {
  return (
    <EditAsset
      bar={bar}
      assetUrl={url}
      setAssetFileName={(fileName) =>
        barService.setBarLogoFilename(bar, fileName)
      }
    />
  );
}

export default EditLogo;
