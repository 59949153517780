import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import ConfirmDialog from "tap-io/client/components/common/ConfirmDialog";

const styles = (theme) => ({});

class ConfirmDeactivatePaymentProvider extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, isOpen, paymentProvider, onConfirm, onCancel } =
      this.props;

    return (
      <ConfirmDialog
        isOpen={isOpen}
        title={t("payment.confirm-deactivate-payment-provider", {
          paymentProviderName: t(`payment.payment-provider-${paymentProvider}`)
        })}
        description={
          <span>
            {t("payment.are-you-sure-you-want-to-deactivate-payment-provider", {
              paymentProviderName: t(
                `payment.payment-provider-${paymentProvider}`
              )
            })}
          </span>
        }
        confirmButtonLabel={t("label.disable")}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(ConfirmDeactivatePaymentProvider)
);
