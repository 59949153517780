import React, { PropsWithChildren, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { Checkbox, FormControl, FormControlLabel, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import Deposit from "tap-io/models/deposit/Deposit";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: "block"
    },
    description: {
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(4)
    }
  })
);

type SelectDepositsProps = {
  isDisabled: boolean;
  deposits: Deposit[];
  selectedDepositIds: string[];
  onChange: (depositIds: string[]) => void;
};

function SelectDeposits({
  isDisabled,
  deposits,
  selectedDepositIds,
  onChange
}: PropsWithChildren<SelectDepositsProps>) {
  const { t, i18n } = useTranslation("common");
  const classes = useStyles();

  useEffect(() => {
    const selectedDeposits = deposits.filter(
      (deposit) => deposit?.id && selectedDepositIds.indexOf(deposit.id) >= 0
    );

    if (selectedDeposits.length !== selectedDepositIds.length) {
      onChange(selectedDeposits.map((deposit) => deposit.id!));
    }
  }, []);

  const handleChange = (deposit) => (event) => {
    if (event.target.checked === true) {
      if (selectedDepositIds.indexOf(deposit.id) < 0) {
        onChange([...selectedDepositIds, deposit.id]);
      }
    } else {
      const index = selectedDepositIds.indexOf(deposit.id);

      if (index >= 0) {
        onChange([
          ...selectedDepositIds.slice(0, index),
          ...selectedDepositIds.slice(index + 1)
        ]);
      }
    }
  };

  return (
    <FormControl className={classes.form}>
      {deposits.map((deposit) => (
        <div key={deposit.id}>
          <FormControlLabel
            value={deposit.id}
            control={
              <Checkbox
                disabled={isDisabled}
                checked={
                  !!(deposit?.id && selectedDepositIds.indexOf(deposit.id) >= 0)
                }
                onChange={handleChange(deposit)}
                name={deposit.id}
                color="primary"
              />
            }
            label={deposit.name}
          />
        </div>
      ))}
    </FormControl>
  );
}

export default SelectDeposits;
