import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import { Button, Typography } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import withStyles from "@mui/styles/withStyles";

import * as routes from "../../constants/routes";

import DownloadTableNumbersDialog from "./DownloadTableNumbersDialog";
import ShowZones from "../zone/ShowZones";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  zonesContainer: {},
  noZonesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  description: {
    marginBottom: 20,
    textAlign: "left"
  },
  fab: {
    margin: theme.spacing(1)
  },
  extendedIcon: {
    display: "flex",
    marginRight: theme.spacing(1)
  },
  link: {
    textDecoration: "none"
  }
});

class TableNumbers extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDownloadTableNumbersDialogOpen: false
    };
  }

  handleDownloadTableNumbers = () => {
    this.setState({ isDownloadTableNumbersDialogOpen: true });
  };

  handleDownloadTableNumbersDialogClose = () => {
    this.setState({ isDownloadTableNumbersDialogOpen: false });
  };

  render() {
    const { classes, t, bar, allZones, hideLinks } = this.props;
    const { isDownloadTableNumbersDialogOpen } = this.state;

    return (
      <div className={classes.container}>
        <DownloadTableNumbersDialog
          isOpen={isDownloadTableNumbersDialogOpen}
          onClose={this.handleDownloadTableNumbersDialogClose}
          bar={bar}
        />
        {bar.isUsingZones() ? (
          <div className={classes.zonesContainer}>
            <Typography variant="body1">
              {`${t("promo.table-numbers-description-1")} `}
              <strong>{t("promo.table-numbers-description-2")}</strong>
              {`, ${t("promo.table-numbers-description-3")} `}
              <strong>{t("promo.table-numbers-description-4")}</strong>
              {` ${t("common:promo.table-numbers-description-5")}`}
            </Typography>
            <ShowZones bar={bar} allZones={allZones} />
            {!hideLinks && (
              <Link to={routes.LOGISTICS} className={classes.link}>
                <Button fullWidth>{t("promo.setup-zones")}</Button>
              </Link>
            )}
          </div>
        ) : (
          <div className={classes.noZonesContainer}>
            <Typography variant="body1" className={classes.description}>
              {`${t("promo.table-numbers-download-1")} `}
              <strong>{t("promo.table-numbers-download-2")}</strong>
              {`. ${t("promo.table-numbers-download-3")} `}
              <strong>{t("promo.table-numbers-download-4")}</strong>.
            </Typography>
            <Button
              className={classes.fab}
              onClick={this.handleDownloadTableNumbers}
            >
              <div className={classes.extendedIcon}>
                <ArrowDownwardIcon />
              </div>
              {`${t("promo.download-table-numbers")}...`}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(TableNumbers)
);
