import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import withStyles from "@mui/styles/withStyles";

import { barService } from "tap-io/client/services";
import { utilsHelper } from "tap-io/helpers";
import { deviceStorage } from "tap-io/storage";

import withBarAuthorization from "../components/auth/withBarAuthorization";
import { CACHE_KEY_BAR_ID } from "../constants/cache";
import BackgroundWithCard from "../components/auth/BackgroundWithCard";

const styles = (theme) => ({
  title: {
    paddingBottom: theme.spacing()
  }
});

class SelectBarPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { bars: null };
  }

  componentDidMount() {
    this.refreshBars();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { barIds } = this.props;
    const prevBarIds = prevProps.barIds;

    if (!utilsHelper.areArraysEqualShallow(barIds, prevBarIds)) {
      this.refreshBars();
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeBars) {
      this.unsubscribeBars();
      this.unsubscribeBars = undefined;
    }
  }

  checkBarIdInCache = async () => {
    const { barIds, setBarId } = this.props;

    if (barIds) {
      const barId = await deviceStorage.getDeviceCacheItem(
        "",
        CACHE_KEY_BAR_ID
      );

      if (barId && barIds.indexOf(barId) >= 0) {
        setBarId(barId);

        return barId;
      } else {
        await deviceStorage.clearDeviceCacheItem("", CACHE_KEY_BAR_ID);
      }
    }

    return null;
  };

  refreshBars = async () => {
    const { barIds, barId } = this.props;

    if (this.unsubscribeBars) {
      this.unsubscribeBars();
      this.unsubscribeBars = undefined;
    }

    if (barIds && !barId) {
      const cachedBarId = await this.checkBarIdInCache();

      if (!cachedBarId) {
        this.unsubscribeBars = barService.onBarsWithIds(barIds, (bars) =>
          this.setState({ bars })
        );
      }
    } else {
      this.setState({ bars: null });
    }
  };

  handleBarClick = (bar) => async (event) => {
    const { setBarId } = this.props;

    setBarId(bar.id);
    await deviceStorage.setDeviceCacheItem("", CACHE_KEY_BAR_ID, bar.id);
  };

  render() {
    const { classes, t } = this.props;
    const { bars } = this.state;

    return bars ? (
      <BackgroundWithCard
        content={
          <List>
            <ListItem>
              <Typography className={classes.title} variant="h6">
                {t("bar.select-a-bar")}
              </Typography>
            </ListItem>
            {bars.map((bar) => (
              <ListItemButton key={bar.id} onClick={this.handleBarClick(bar)}>
                <ListItemText primary={bar.name} />
                <NavigateNextIcon />
              </ListItemButton>
            ))}
          </List>
        }
      />
    ) : null;
  }
}

export default withBarAuthorization()(
  withStyles(styles, { withTheme: true })(
    withTranslation("common")(SelectBarPage)
  )
);
