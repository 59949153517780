import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({});

class SelectBase extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleBaseChange = (event) => {
    const { t, onSelect } = this.props;

    const value = event.target.value;

    if (onSelect) {
      onSelect(value ? value : null);
    }
  };

  render() {
    const { classes, t, isDisabled, allBases, basesFilter, selectedBaseId } =
      this.props;

    return (
      <div>
        {allBases && allBases.length > 0 ? (
          <FormControl variant="standard" fullWidth>
            <InputLabel>{t("base.select-a-base")}</InputLabel>
            <Select
              fullWidth
              variant="standard"
              disabled={isDisabled}
              value={selectedBaseId}
              onChange={this.handleBaseChange}
            >
              {allBases
                .filter(basesFilter ? basesFilter : () => true)
                .map((base) => (
                  <MenuItem key={base.id} value={base.id}>
                    {base.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        ) : (
          <Typography>{t("base.no-bases-found")}</Typography>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(SelectBase));
