import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import withStyles from "@mui/styles/withStyles";

import OrderDialog from "tap-io/client/components/order/OrderDialog";

import withAuthorization from "../auth/withAuthorization";
import BarDrawer from "./BarDrawer";
import SearchBar from "../common/SearchBar";
import SearchedOrders from "../order/SearchedOrders";
import AllOrders from "../order/AllOrders";
import UnpaidOrders from "../order/UnpaidOrders";
import WhichOrders from "../order/WhichOrders";
import { WHICH_ORDERS_ALL, WHICH_ORDERS_UNPAID } from "../../constants/orders";
import { hasNativeWrapper, printer } from "../../native";

const styles = (theme) => ({
  header: {
    display: "flex",
    width: "100%",
    padding: "0 8px"
  },
  subheader: {
    position: "sticky",
    zIndex: 1,
    top: 0,
    backgroundColor: theme.palette.background.paper
  }
});

class BarOrderDrawer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      whichOrdersBeingShown: WHICH_ORDERS_ALL,
      orderBeingShown: null,
      orderSearchQuery: ""
    };
  }

  handleOrderSearchQueryChange = (orderSearchQuery) => {
    this.setState({ orderSearchQuery });
  };

  handleWhichOrdersBeingShownChange = (whichOrdersBeingShown) => {
    this.setState({ whichOrdersBeingShown });
  };

  handleOrderClick = (order) => {
    if (order.hasBeenQueued()) {
      this.setState({ orderBeingShown: order });
    }
  };

  handleOrderDialogClose = () => {
    this.setState({ orderBeingShown: null });
  };

  handlePrintReceipt = async () => {
    const { t, bar, base, assets, deviceSettings } = this.props;
    const { orderBeingShown } = this.state;

    try {
      if (hasNativeWrapper()) {
        await printer.printReceipt(
          t,
          bar,
          base,
          assets,
          orderBeingShown,
          deviceSettings.printBarLogo,
          deviceSettings.printOrderCustomerFields,
          deviceSettings.printAmountOfCopies
        );
      }
    } catch (error) {
      if (error) {
        toast.error(error.message);
      }
      console.warn(error);
    }
  };

  render() {
    const { classes, t, auth, width, isOpen, onClose, bar, base } = this.props;
    const { whichOrdersBeingShown, orderBeingShown, orderSearchQuery } =
      this.state;

    return (
      <div>
        <OrderDialog
          isOpen={orderBeingShown !== null}
          onClose={this.handleOrderDialogClose}
          auth={auth}
          bar={bar}
          base={base}
          order={orderBeingShown}
          onPrintReceipt={hasNativeWrapper() && this.handlePrintReceipt}
        />
        <BarDrawer
          variant="temporary"
          anchor="left"
          width={width}
          isOpen={isOpen}
          onClose={onClose}
          header={
            <div className={classes.header}>
              <SearchBar
                placeholder={t("order.search-by-confirmation-code")}
                query={orderSearchQuery}
                onChange={this.handleOrderSearchQueryChange}
              />
              <IconButton onClick={onClose} size="large">
                <ChevronLeftIcon />
              </IconButton>
            </div>
          }
          content={
            orderSearchQuery ? (
              <SearchedOrders
                bar={bar}
                base={base}
                searchQuery={orderSearchQuery}
                onOrderClick={this.handleOrderClick}
              />
            ) : (
              <div>
                {bar.isAllowingOnlinePayments() && (
                  <div className={classes.subheader}>
                    <WhichOrders
                      whichOrders={whichOrdersBeingShown}
                      onChange={this.handleWhichOrdersBeingShownChange}
                    />
                  </div>
                )}
                {whichOrdersBeingShown === WHICH_ORDERS_UNPAID ? (
                  <UnpaidOrders
                    bar={bar}
                    base={base}
                    onOrderClick={this.handleOrderClick}
                  />
                ) : (
                  <AllOrders
                    bar={bar}
                    base={base}
                    onOrderClick={this.handleOrderClick}
                  />
                )}
              </div>
            )
          }
        />
      </div>
    );
  }
}

export default withStyles(styles)(
  withAuthorization()(withTranslation("common")(BarOrderDrawer))
);
