import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import withStyles from "@mui/styles/withStyles";
import CapabilityUnavailableButton from "../subscription/CapabilityUnavailableButton";

const styles = (theme) => ({
  button: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: 10,
    padding: 20,
    borderRadius: 4,
    background: theme.palette.background.paper,
    cursor: "pointer"
  },
  activeButton: {
    border: `2px solid ${theme.palette.primary.main}`
  },
  disabledButton: {
    opacity: 0.4,
    cursor: "default"
  },
  unavailableButton: {
    cursor: "default"
  },
  link: {
    textDecoration: "none",
    color: theme.palette.common.black
  },
  icon: {
    marginBottom: 10
  },
  content: {
    width: "100%",
    marginLeft: 20,
    textAlign: "left"
  },
  description: {
    marginTop: 10
  },
  fill: {
    height: "100%"
  },
  goButton: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0 10px -8px",
    color: theme.palette.primary.main
  },
  unavailableContainer: {
    marginTop: 20
  }
});

class Mode extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes,
      t,
      isDisabled,
      isActive,
      isAvailable,
      bar,
      subscription,
      Icon,
      title,
      description,
      onClick
    } = this.props;

    return (
      <div
        className={classNames(classes.button, {
          [classes.activeButton]: isActive,
          [classes.disabledButton]: isDisabled && !isActive,
          [classes.unavailableButton]: !isAvailable && !isActive
        })}
        onClick={isDisabled || !isAvailable ? undefined : onClick}
      >
        <Icon fontSize="large" className={classes.icon} />
        <div className={classes.content}>
          <Typography variant="h6">{title}</Typography>
          <Typography className={classes.description}>{description}</Typography>
          <div className={classes.fill} />
          {isActive && (
            <div className={classes.goButton}>
              <NavigateNextIcon />
              <Typography variant="button">{t("label.go")}</Typography>
            </div>
          )}
          {!isDisabled && !isAvailable && (
            <div className={classes.unavailableContainer}>
              <CapabilityUnavailableButton
                bar={bar}
                subscription={subscription}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(Mode));
