import { ReactNode } from "react";
import { Async, ResultOfAsync } from "./useAsync";

type AsyncerProps<A extends Async<any>> = {
  async: A;
  Idle?: ReactNode;
  Busy?: ReactNode;
  Failed?: ReactNode;
  children: (data: ResultOfAsync<A>) => ReactNode;
};
export function Asyncer<A extends Async<any>>(props: AsyncerProps<A>) {
  const { async, Idle, Busy, Failed, children } = props;
  const { data, status } = async;

  switch (status) {
    case "idle":
      return <>{Idle}</>;
    case "busy":
      return <>{Busy}</>;
    case "failed":
      return <>{Failed}</>;
    case "ready":
      return <>{children(data)}</>;

    default:
      return null;
  }
}
