import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import ShowTip from "./ShowTip";

const styles = (theme) => ({});

class ShowVouchersTip extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { t, variant, label } = this.props;

    return (
      <ShowTip
        variant={variant}
        label={label ? label : t("voucher.vouchers")}
        tipTitle={t("help.vouchers-title")}
        tipIcon="/icons/vouchers.png"
        tipDescription={t("help.vouchers-description")}
      />
    );
  }
}

export default withStyles(styles)(withTranslation("common")(ShowVouchersTip));
