import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Dialog, Slide, IconButton, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import withStyles from "@mui/styles/withStyles";

import OrderStatus from "tap-io/models/order/OrderStatus";

import { hasNativeWrapper } from "../../native";
import ClaimOrder from "./ClaimOrder";
import CompleteOrder from "./CompleteOrder";
import OrderAccordion from "./OrderAccordion";
import OrderCompleted from "./OrderCompleted";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  container: {
    height: "auto",
    maxHeight: "100vh"
  },
  orderDrawer: {
    padding: theme.spacing(2)
    //background: "transparent"
  },
  closeOrderDrawerButton: {
    display: "flex",
    justifyContent: "center"
  },
  closeOrderDrawerIcon: {
    fontSize: 36
  },
  spacing: {
    height: 40
  },
  center: {
    textAlign: "center"
  }
});

class OrderDrawer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes,
      t,
      timeOffset,
      bar,
      base,
      assets,
      deviceSettings,
      order,
      onClose
    } = this.props;

    const orderStatus = order?.getStatus(base);

    const printOrderWhenClaimed =
      hasNativeWrapper() &&
      deviceSettings &&
      deviceSettings.printOrderWhenClaimed;

    const selectOrderPreparationOptionWhenClaimed =
      order?.isFulfilmentMethodAsSoonAsPossible &&
      order?.isDeliveryMethodPickup;

    // Show Order contents when Order is still queued and not printed or when a preparation option needs to be selected;
    // or when the Order is not claimed or complete (e.g. cancelled, error)
    const isOrderAccordionInitiallyOpen =
      orderStatus === OrderStatus.QUEUED
        ? (!printOrderWhenClaimed || selectOrderPreparationOptionWhenClaimed)
        : orderStatus !== OrderStatus.CLAIMED &&
        orderStatus !== OrderStatus.COMPLETE;

    return (
      <Dialog
        anchor="top"
        scroll="body"
        fullScreen
        TransitionComponent={Transition}
        open={!!order}
        onClose={onClose}
        classes={{ container: classes.container, paper: classes.orderDrawer }}
      >
        <div className={classes.closeOrderDrawerButton}>
          <IconButton color="primary" onClick={onClose} size="large">
            <CancelIcon className={classes.closeOrderDrawerIcon} />
          </IconButton>
        </div>
        {order && (
          <>
            {orderStatus === OrderStatus.QUEUED ? (
              <ClaimOrder
                bar={bar}
                assets={assets}
                deviceSettings={deviceSettings}
                base={base}
                order={order}
                onDone={onClose}
              />
            ) : orderStatus === OrderStatus.CLAIMED ? (
              <CompleteOrder
                timeOffset={timeOffset}
                bar={bar}
                assets={assets}
                deviceSettings={deviceSettings}
                base={base}
                order={order}
                onDone={onClose}
              />
            ) : orderStatus === OrderStatus.COMPLETE ? (
              <OrderCompleted bar={bar} order={order} />
            ) : (
              <Typography className={classes.center}>
                {t("order.please-find-the-order-below")}
              </Typography>
            )}
            <div className={classes.spacing} />
            <OrderAccordion
              bar={bar}
              base={base}
              order={order}
              assets={assets}
              deviceSettings={deviceSettings}
              isInitiallyOpen={isOrderAccordionInitiallyOpen}
            />
          </>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(OrderDrawer));
