import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import LockIcon from "@mui/icons-material/Lock";
import withStyles from "@mui/styles/withStyles";

import BarMenuItem from "./BarMenuItem";

const styles = (theme) => ({});

class CrewMenuItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleUnlockApp = () => {
    const { unlockApp, onClicked } = this.props;

    unlockApp();

    if (onClicked) {
      onClicked();
    }
  };

  render() {
    const { t } = this.props;

    return (
      <BarMenuItem
        icon={<LockIcon />}
        label={t("label.admin")}
        onClick={this.handleUnlockApp}
      />
    );
  }
}

export default withStyles(styles)(withTranslation("common")(CrewMenuItem));
