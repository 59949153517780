import { useState } from "react";
import { Asyncer } from "../../../components/common/Asyncer";
import { Async } from "../../../components/common/useAsync";
import { PaymentReport } from "../../models/PaymentReport";
import { BarStats } from "../common/BarStats";
import { DownloadIconCSVButton } from "../common/DownloadCSVIconButton";
import { FailedStatCard } from "../common/FailedStatCard";
import { FilterSwitch } from "../common/FilterSwitch";
import { LoadingStatCard } from "../common/LoadingStatCard";
import { StatCard } from "../common/StatCard";

export type ValuePerPaymentMethodProps = {
  async: Async<PaymentReport>;
  mark: string;
  t: any;
};

export function ValuePerPaymentMethod(props: ValuePerPaymentMethodProps) {
  const { async, mark, t } = props;

  const [isInternalActive, setIsInternalActive] = useState(true);

  const title = t("stats.stat.value-per-paymentoption.title");
  const description = t("stats.stat.value-per-paymentoption.description");

  return (
    <Asyncer
      async={async}
      Busy={<LoadingStatCard title={title} description={description} />}
      Failed={
        <FailedStatCard title={title} description={description} async={async} />
      }
    >
      {(report) => {
        return (
          <StatCard
            title={title}
            description={description}
            action={
              <DownloadIconCSVButton
                title={`${title}-${mark}`}
                csv={report.toCSV(t)}
              />
            }
            filters={
              <FilterSwitch
                label={t("stats.input.internal.label")}
                description={t("stats.input.internal.description")}
                value={isInternalActive}
                onChange={setIsInternalActive}
              />
            }
          >
            <BarStats
              data={
                isInternalActive
                  ? report.data
                  : report.data.filter(({ provider }) => provider !== "bo")
              }
              x="paymentOption"
              formatX={report.formatPaymentOption(t)}
              y="value"
              formatY={report.formatCurrency}
              t={t}
            />
          </StatCard>
        );
      }}
    </Asyncer>
  );
}
