import React, { PureComponent } from "react";
import { Redirect, withRouter } from "react-router-dom";

import AuthContext from "tap-io/client/components/auth/authContext";

import * as routes from "../../constants/routes";

const withAuthorization = (authCondition) => (Component) => {
  class WithAuthorization extends PureComponent {
    render() {
      return (
        <AuthContext.Consumer>
          {(auth) =>
            auth && auth.user ? (
              <Component auth={auth} {...this.props} />
            ) : (
              <Redirect to={routes.SIGN_IN} />
            )
          }
        </AuthContext.Consumer>
      );
    }
  }

  return withRouter(WithAuthorization);
};

export default withAuthorization;
