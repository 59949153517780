import { Stat } from "./Stat";

type Data = {
  deposit: string;
  name: string;
  version: string;
  amountDistributed: number;
  amountCollected: number;
  amountUnCollected: number;
  inclusionAmount: number;
  value: number;
  currency: string;
};

export class DepositReport extends Stat<Data> {
  get totalValue() {
    return this.data.reduce((total, report) => total + report.value, 0);
  }

  toCSV(t: any) {
    return this.generateCSV(
      {
        deposit: true,
        name: true,
        version: true,
        amountDistributed: this.formatInteger,
        amountCollected: this.formatInteger,
        amountUnCollected: this.formatInteger,
        inclusionAmount: this.formatInteger,
        value: this.formatCurrency,
        currency: true
      },
      t
    );
  }

  toCollectionCSV(t: any) {
    return this.generateCSV(
      {
        deposit: true,
        name: true,
        version: true,
        amountDistributed: this.formatInteger,
        amountCollected: this.formatInteger,
        amountUnCollected: this.formatInteger,
        inclusionAmount: false,
        value: false,
        currency: false
      },
      t
    );
  }

  toInclusionCSV(t: any) {
    return this.generateCSV(
      {
        deposit: true,
        name: true,
        version: true,
        amountCollected: false,
        amountDistributed: false,
        amountUnCollected: false,
        inclusionAmount: this.formatInteger,
        value: false,
        currency: false
      },
      t
    );
  }

  toFinancialCSV(t: any) {
    return this.generateCSV(
      {
        deposit: true,
        name: true,
        version: true,
        amountDistributed: false,
        amountCollected: false,
        amountUnCollected: false,
        inclusionAmount: false,
        value: this.formatCurrency,
        currency: true
      },
      t
    );
  }
}
