import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Switch,
  TextField
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";
import { barService, dataService } from "tap-io/client/services";
import { utilsHelper } from "tap-io/helpers";
import { deviceStorage } from "tap-io/storage";

import withAuthorization from "../auth/withAuthorization";
import {
  BAR_SIZE_SMALL,
  BAR_SIZES,
  BAR_TYPE_OTHER,
  BAR_TYPES
} from "../../constants/bar";
import ShowBasesTip from "../help/ShowBasesTip";
import { CACHE_KEY_SHOW_INFO_SPLASH } from "../../constants/cache";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  list: {
    width: 500
  },
  spacing: {
    width: 10
  }
});

class CreateBarStep extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false,
      showErrors: false,
      isValidPhoneNumber: false,
      barName: "",
      barType: "",
      barTypeOther: "",
      barSize: "",
      //useBases: false,
      contact: {
        firstName: "",
        lastName: "",
        phoneNumber: ""
      }
    };
  }

  componentDidMount() {
    const { setOnNext } = this.props;
    setOnNext(this.handleCreateBar);
  }

  handleTextChange = (key) => (event) => {
    const val = event.target.value;

    this.setState({ [key]: val });
  };

  handleToggle = (key) => (event) => {
    const val = event.target.checked === true;

    this.setState({ [key]: val });
  };

  handleContactChange = (contactField) => (event) => {
    const val = event.target.value;

    this.setState({ contact: { ...this.state.contact, [contactField]: val } });
  };

  handleContactPhoneNumberChange = (event) => {
    const phoneNumber = event.target.value;

    const parsedPhoneNumber = utilsHelper.parsePhoneNumber(phoneNumber);
    const isValidPhoneNumber = parsedPhoneNumber !== undefined;

    this.setState({
      isValidPhoneNumber,
      contact: {
        ...this.state.contact,
        phoneNumber: parsedPhoneNumber ? parsedPhoneNumber : phoneNumber
      }
    });
  };

  handleCreateBar = async () => {
    const { auth } = this.props;
    const {
      isValidPhoneNumber,
      barName,
      barType,
      barTypeOther,
      barSize,
      //useBases,
      contact
    } = this.state;

    if (!barName) {
      this.setState({ showErrors: true });
      throw new Error("error.name-cannot-be-empty");
    }

    const barParams = {};
    /*if (barSize === BAR_SIZE_SMALL) {
      barParams.useBases = useBases === true;
    }*/

    if (!isValidPhoneNumber) {
      this.setState({ showErrors: true });
      throw new Error("error.phone-number-invalid");
    }

    this.setState({ isDisabled: true });

    if (auth && auth.user && auth.user.uid) {
      try {
        // TO FIX: "unknown" id
        const administrationData = dataService.createAdministrationData(
          "unknown",
          {}
        );
        administrationData.barType = barType;
        if (barType === BAR_TYPE_OTHER) {
          administrationData.barTypeOther = barTypeOther;
        }
        administrationData.barSize = barSize;
        administrationData.contactFirstName = contact.firstName;
        administrationData.contactLastName = contact.lastName;
        administrationData.contactPhoneNumber = contact.phoneNumber;

        const result = await barService.createBar(
          auth.user.uid,
          barName,
          barParams,
          administrationData,
          (data) => {
            console.log("progress", data);
          }
        );

        await deviceStorage.setDeviceCacheItem(
          result.barId,
          CACHE_KEY_SHOW_INFO_SPLASH,
          true
        );
        //this.setState({ isDisabled: false }); // Component is unmounted when bar has been created
      } catch (error) {
        console.warn(error);
        this.setState({ isDisabled: false });

        if (error && error.message === "error.string-cannot-be-empty") {
          this.setState({ showErrors: true });
          throw new Error("error.not-all-required-fields-are-completed");
        } else {
          throw error;
        }
      }
    } else {
      throw new Error("error.no-authorized-user-found");
    }
  };

  render() {
    const { classes, t } = this.props;
    const {
      isDisabled,
      showErrors,
      isValidPhoneNumber,
      barName,
      barType,
      barTypeOther,
      barSize,
      //useBases,
      contact
    } = this.state;

    return (
      <div className={classes.container}>
        <List className={classes.list}>
          <ListSubheader>{t("settings.bar-details-label")}</ListSubheader>
          <ListItem>
            <TextField
              data-cy="bar-name"
              variant="standard"
              margin="dense"
              label={t("settings.bar-name-label")}
              helperText={t("settings.bar-name-description")}
              required
              fullWidth
              disabled={isDisabled}
              error={showErrors && !barName}
              value={barName}
              onChange={this.handleTextChange("barName")}
            />
          </ListItem>
          <ListItem>
            <FormControl fullWidth variant="standard">
              <InputLabel error={showErrors && !barType}>
                {t("settings.bar-type-label")}
              </InputLabel>
              <Select
                fullWidth
                required
                variant="standard"
                disabled={isDisabled}
                error={showErrors && !barType}
                value={barType}
                onChange={this.handleTextChange("barType")}
              >
                {BAR_TYPES.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {t(type.label)}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={showErrors && !barType}>
                {t("settings.bar-type-description")}
              </FormHelperText>
            </FormControl>
          </ListItem>
          {barType === BAR_TYPE_OTHER && (
            <ListItem>
              <TextField
                data-cy="bar-name"
                variant="standard"
                margin="dense"
                label={t("settings.bar-type-option-other-label")}
                helperText={t("settings.bar-type-option-other-description")}
                required
                fullWidth
                disabled={isDisabled}
                error={showErrors && !barTypeOther}
                value={barTypeOther}
                onChange={this.handleTextChange("barTypeOther")}
              />
            </ListItem>
          )}
          <ListItem>
            <FormControl fullWidth variant="standard">
              <InputLabel error={showErrors && !barSize}>
                {t("settings.bar-size-label")}
              </InputLabel>
              <Select
                fullWidth
                required
                variant="standard"
                disabled={isDisabled}
                error={showErrors && !barSize}
                value={barSize}
                onChange={this.handleTextChange("barSize")}
              >
                {BAR_SIZES.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {t(type.label)}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={showErrors && !barSize}>
                {t("settings.bar-size-description")}
              </FormHelperText>
            </FormControl>
          </ListItem>
        </List>
        <List>
          <ListSubheader>
            {t("settings.bar-contact-details-label")}
          </ListSubheader>
          <ListItem>
            <TextField
              variant="standard"
              margin="dense"
              label={t("settings.bar-contact-details-first-name")}
              required
              disabled={isDisabled}
              error={showErrors && !contact.firstName}
              value={contact.firstName}
              onChange={this.handleContactChange("firstName")}
            />
            <div className={classes.spacing}></div>
            <TextField
              variant="standard"
              margin="dense"
              label={t("settings.bar-contact-details-last-name")}
              required
              fullWidth
              disabled={isDisabled}
              error={showErrors && !contact.lastName}
              value={contact.lastName}
              onChange={this.handleContactChange("lastName")}
            />
          </ListItem>
          <ListItem>
            <TextField
              variant="standard"
              margin="dense"
              label={t("settings.bar-contact-details-phone-number")}
              required
              fullWidth
              disabled={isDisabled}
              error={contact.phoneNumber ? !isValidPhoneNumber : showErrors}
              value={contact.phoneNumber}
              onChange={this.handleContactPhoneNumberChange}
            />
          </ListItem>
          <ListItem>
            <FormHelperText>
              {`${t("settings.bar-contact-details-description-1")} `}
              <a
                href={config.links.privacyPolicy}
                target="_blank"
                rel="noreferrer"
              >
                {t("settings.bar-contact-details-description-2")}
              </a>
              .
            </FormHelperText>
          </ListItem>
        </List>
      </div>
    );
  }
}

export default withAuthorization()(
  withStyles(styles, { withTheme: true })(
    withTranslation("common")(CreateBarStep)
  )
);

/*
{barSize && (
  <div>
    {barSize === BAR_SIZE_SMALL ? (
      <ListItem>
        <ListItemText
          primary={
            <ShowBasesTip label={t("settings.bar-use-bases-label")} />
          }
          secondary={t("settings.bar-use-bases-description")}
        />
        <Switch
          checked={useBases}
          onChange={this.handleToggle("useBases")}
          disabled={isDisabled}
        />
      </ListItem>
    ) : (
      <ListItem>
        <ListItemText
          primary={
            <ShowBasesTip label={t("settings.bar-use-bases-label")} />
          }
        />
        <ListItemSecondaryAction>
          <CheckIcon />
        </ListItemSecondaryAction>
      </ListItem>
    )}
  </div>
)}
*/
