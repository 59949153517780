import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  TextField
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import LockOpenIcon from "@mui/icons-material/LockOpen";

import { toast } from "react-toastify";

import { authService } from "tap-io/client/services";

import withAuthorization from "./withAuthorization";

const styles = (theme) => ({});

class VerifyPassword extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      password: ""
    };
  }

  handlePasswordChange = (event) => {
    const val = event.target.value;

    this.setState({ password: val });
  };

  handleVerifyPassword = async (event) => {
    // Prevent page refresh
    event.preventDefault();

    const { t, auth, onVerified } = this.props;
    const { password } = this.state;

    if (!password) {
      return toast.error(t("error.password-cannot-be-empty"));
    }

    if (auth && auth.user && auth.user.email) {
      this.setState({ isLoading: true });

      try {
        await authService.verifyPassword(auth.user, password);

        this.setState({ isLoading: false });

        onVerified();
      } catch (error) {
        console.warn(error);

        toast.error(t("error.password-incorrect"));

        this.setState({ isLoading: false });
      }
    } else {
      toast.error(t("error.no-authorized-user-found"));
    }
  };

  handleForgotPassword = () => {
    const { t, auth } = this.props;

    if (auth && auth.user && auth.user.email) {
      const toastId = toast(t("auth.sending-email-with-reset-link"), {
        autoClose: false
      });

      authService
        .resetPasswordForEmail(auth.user.email)
        .then((data) => {
          toast.update(toastId, {
            render: `${t("auth.email-with-reset-link-sent")} ${t(
              "auth.check-your-mailbox-including-spam-folder"
            )}`,
            type: toast.TYPE.INFO,
            autoClose: 5000
          });
        })
        .catch((error) => {
          toast.update(toastId, {
            render: `${t("auth.error-while-resetting-your-password")} (${
              error.message
            })`,
            type: toast.TYPE.ERROR,
            autoClose: 5000
          });
        });
    } else {
      toast.error(t("error.no-authorized-user-found"));
    }
  };

  render() {
    const { t, auth } = this.props;
    const { isLoading, password } = this.state;

    return (
      <div>
        {auth && auth.user ? (
          <List>
            <form onSubmit={this.handleVerifyPassword}>
              <ListItem>
                <ListItemText
                  primary={
                    <span>
                      {`${t("auth.you-are-signed-in-as")} `}
                      <strong>{auth.user.email}</strong>
                    </span>
                  }
                />
              </ListItem>
              <ListItem>
                <TextField
                  autoFocus
                  fullWidth
                  variant="standard"
                  margin="dense"
                  label={t("auth.your-password")}
                  helperText={t("auth.password-of-your-account")}
                  type="password"
                  value={password}
                  onChange={this.handlePasswordChange}
                />
              </ListItem>
              <ListItem>
                <Button
                  startIcon={
                    isLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <LockOpenIcon />
                    )
                  }
                  disabled={isLoading || password.length === 0}
                  variant="contained"
                  fullWidth
                  onClick={this.handleVerifyPassword}
                >
                  {t("auth.verify")}
                </Button>
              </ListItem>
              <ListItem>
                <Button
                  disabled={isLoading}
                  fullWidth
                  onClick={this.handleForgotPassword}
                >
                  {t("auth.forgot-password")}
                </Button>
              </ListItem>
            </form>
          </List>
        ) : (
          <CircularProgress />
        )}
      </div>
    );
  }
}

export default withAuthorization()(
  withStyles(styles, { withTheme: true })(
    withTranslation("common")(VerifyPassword)
  )
);
