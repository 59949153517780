import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

// TO FIX: https://github.com/fris-fruitig/react-firebase-file-uploader/pull/55
import FileUploader from "react-firebase-file-uploader";

import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  disabledText: {
    color: theme.palette.action.disabled
  },
  uploadButton: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "100%",
    cursor: "pointer"
  }
});

class UploadImage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  setUploadState = (isUploading, progress) => {
    const { onUpdate } = this.props;

    const uploadState = { isUploading };

    if (isUploading) {
      if (progress !== undefined) {
        uploadState.progress = progress;
      }
    } else {
      uploadState.progress = null;
    }

    this.setState({ ...uploadState });

    if (onUpdate) {
      onUpdate(uploadState);
    }
  };

  handleUploadStart = () => this.setUploadState(true, 0);

  handleProgress = (progress) => this.setUploadState(true, progress);

  handleUploadError = (error) => {
    const { onError } = this.props;

    this.setUploadState(false);

    onError(error);
  };

  // TO FIX: filename is unresolved promise
  handleUploadSuccess = (filename, task) => {
    const { onSuccess } = this.props;

    this.setUploadState(false);

    onSuccess(task.snapshot.ref.name);
  };

  render() {
    const { classes, t, isDisabled, uploadRef, label, inputProps } = this.props;
    const { isUploading, progress } = this.state;

    return (
      <List>
        <ListItem
          button={!isDisabled && !isUploading}
          className={classes.button}
        >
          <ListItemIcon>
            <CameraAltIcon
              color={isDisabled ? "disabled" : "primary"}
              className={classes.leftIcon}
            />
          </ListItemIcon>
          <ListItemText
            className={classNames({ [classes.disabledText]: isDisabled })}
            primary={
              isUploading
                ? `${t("label.uploading")}... ${progress}%`
                : label === undefined
                ? t("label.add-new-image")
                : label
            }
          />
          <label className={classes.uploadButton}>
            <FileUploader
              hidden
              //accept="image/*"
              accept="image/png, image/gif, image/jpeg"
              randomizeFilename
              disabled={isDisabled}
              storageRef={uploadRef}
              onUploadStart={this.handleUploadStart}
              onUploadError={this.handleUploadError}
              onUploadSuccess={this.handleUploadSuccess}
              onProgress={this.handleProgress}
              {...inputProps}
            />
          </label>
        </ListItem>
      </List>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(UploadImage));
