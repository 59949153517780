import { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import ContentCard from "tap-io/client/components/common/ContentCard";
import { barService } from "tap-io/client/services";
//import { integrationHelper } from "tap-io/helpers";
import ConfirmDialog from "tap-io/client/components/common/ConfirmDialog";
import config from "tap-io/client/env";
import theme from "tap-io/client/theme";
import { cloudStorage } from "tap-io/storage";

import withBarAuthorization from "../components/auth/withBarAuthorization";
import withPasswordAuthentication from "../components/auth/withPasswordAuthentication";
import SettingsCard from "../components/common/SettingsCard";
import EditBusiness from "../components/settings/EditBusiness";
import EditCcv from "../components/settings/EditCcv";
import EditColors from "../components/settings/EditColors";
import EditCover from "../components/settings/EditCover";
import EditDeferred from "../components/settings/EditDeferred";
import EditDeliveryMethodsAndColors from "../components/settings/EditDeliveryMethodsAndColors";
import EditDepositSettings from "../components/settings/EditDepositSettings";
import EditDeviceSettings from "../components/settings/EditDeviceSettings";
import EditFeeSettings from "../components/settings/EditFeeSettings";
import EditIntegrations from "../components/settings/EditIntegrations";
import EditLocales from "../components/settings/EditLocales";
import EditLogo from "../components/settings/EditLogo";
import EditMollie from "../components/settings/EditMollie";
import EditMultiSafePay from "../components/settings/EditMultiSafePay";
import EditName from "../components/settings/EditName";
import EditParams from "../components/settings/EditParams";
import EditPayconiq from "../components/settings/EditPayconiq";
import EditPin from "../components/settings/EditPin";
import EditStarnet from "../components/settings/EditStarnet";
import EditVivaWallet from "../components/settings/EditVivaWallet";
import EditVoucher from "../components/settings/EditVoucher";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  subtitle: {
    padding: "0 16px",
    textAlign: "left"
  },
  text: {
    padding: 16,
    textAlign: "left"
  },
  accentuate: {
    color: "red"
  },
  assets: {
    display: "flex"
  },
  asset: {
    width: "100%"
  }
});

class SettingsPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmDenyOnlinePaymentsDialogOpen: false
    };
  }

  componentDidMount() {
    this.refreshCover();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { bar } = this.props;

    const coverFileName = bar?.getCoverFileName();
    const prevCoverFileName = prevProps.bar?.getCoverFileName();

    if (coverFileName !== prevCoverFileName) {
      this.refreshCover();
    }
  }

  refreshCover = async () => {
    const { bar } = this.props;

    const coverFileName = bar.getCoverFileName();
    let coverImageUrl = null;

    if (coverFileName) {
      try {
        coverImageUrl = await cloudStorage.getBarCoverImageUrl(bar);
      } catch (error) {
        console.warn(error);
      }
    }

    this.setState({ coverImageUrl });
  };

  handleBarIsAllowingOnlinePaymentsChange = (allowOnlinePayments) => {
    if (allowOnlinePayments) {
      this.updateBarIsAllowingOnlinePayments(allowOnlinePayments);
    } else {
      this.setState({ isConfirmDenyOnlinePaymentsDialogOpen: true });
    }
  };

  handleConfirmToDenyOnlinePayments = () => {
    this.setState({ isConfirmDenyOnlinePaymentsDialogOpen: false });

    this.updateBarIsAllowingOnlinePayments(false);
  };

  handleCancelToDenyOnlinePayments = () => {
    this.setState({ isConfirmDenyOnlinePaymentsDialogOpen: false });
  };

  updateBarIsAllowingOnlinePayments = async (allowOnlinePayments) => {
    const { t, bar } = this.props;

    const toastId = toast(
      allowOnlinePayments
        ? t("settings.enabling-online-payments")
        : t("settings.disabling-online-payments"),
      { autoClose: false }
    );

    try {
      const barParams = barService.createBarParams(bar, {
        allowOnlinePayments
      });
      await barService.updateBarParams(bar, barParams);

      toast.update(toastId, {
        render: allowOnlinePayments
          ? t("settings.online-payments-enabled")
          : t("settings.online-payments-disabled"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const {
      classes,
      t,
      bar,
      subscription,
      balance,
      deviceSettings,
      assets,
      allIntegrations
    } = this.props;
    const { isConfirmDenyOnlinePaymentsDialogOpen, coverImageUrl } = this.state;

    const barIsAllowingOnlinePayments = bar.isAllowingOnlinePayments();

    // const abiIntegration =
    //   allIntegrations && integrationHelper.findAbiIntegration(allIntegrations);
    //const hasAbiIntegration = !!abiIntegration;
    //const hasActiveAbiIntegration = abiIntegration && abiIntegration.isActive;

    return (
      <div className={classes.container}>
        <ConfirmDialog
          isOpen={isConfirmDenyOnlinePaymentsDialogOpen}
          title={t("settings.confirm-to-deny-online-payments")}
          description={t(
            "settings.are-you-sure-you-want-to-deny-online-payments"
          )}
          confirmButtonLabel={t("label.disable")}
          onConfirm={this.handleConfirmToDenyOnlinePayments}
          onCancel={this.handleCancelToDenyOnlinePayments}
        />
        <ContentCard title={t("settings.general-settings")}>
          <EditName bar={bar} />
          <EditParams
            bar={bar}
            areNotesAllowed={true /*!hasActiveAbiIntegration*/}
          />
          <EditLocales bar={bar} />
        </ContentCard>
        <ContentCard title={t("settings.device-settings")}>
          <EditDeviceSettings bar={bar} deviceSettings={deviceSettings} />
        </ContentCard>
        <ContentCard
          title={t("settings.fee-settings")}
          description={t("settings.fee-description")}
        >
          <EditFeeSettings
            bar={bar}
            subscription={subscription}
            isEditable={true /*!hasActiveAbiIntegration*/}
          />
        </ContentCard>
        <ContentCard
          title={t("settings.deposit-settings")}
          description={t("settings.deposit-description")}
        >
          <EditDepositSettings bar={bar} subscription={subscription} />
        </ContentCard>
        <SettingsCard
          hideCardEnabledToggle={bar.isAllowingOnlinePayments()}
          isCardEnabled={barIsAllowingOnlinePayments}
          onIsEnabledChange={this.handleBarIsAllowingOnlinePaymentsChange}
          title={t("settings.param-online-payments-label")}
          description={t("settings.param-online-payments-description")}
        >
          {barIsAllowingOnlinePayments && (
            <div>
              {!bar.areTherePaymentProvidersAvailable() && (
                <Typography className={classes.text}>
                  <strong className={classes.accentuate}>
                    {t("settings.online-payments-no-providers-warning")}
                  </strong>
                </Typography>
              )}
              <EditPin bar={bar} />
              <EditDeferred bar={bar} />
              <EditVoucher bar={bar} />
              {config.paymentProviders.mollie && (
                <EditMollie bar={bar} showSignUp={true} />
              )}
              {config.paymentProviders.vivawallet && (
                <EditVivaWallet bar={bar} />
              )}
              {config.paymentProviders.starnet && <EditStarnet bar={bar} />}
              {config.paymentProviders.ccv && <EditCcv bar={bar} />}
              {config.paymentProviders.multisafepay && <EditMultiSafePay bar={bar} />}
              {config.paymentProviders.payconiq && <EditPayconiq bar={bar} />}
              {/* {config.paymentProviders.starnet && hasAbiIntegration && (
                <EditStarnet bar={bar} isDisabled={!hasActiveAbiIntegration} />
              )} */}
            </div>
          )}
        </SettingsCard>
        <ContentCard title={t("settings.delivery-methods-and-colors")}>
          <EditDeliveryMethodsAndColors bar={bar} />
        </ContentCard>
        <ContentCard title={t("settings.personal-settings")}>
          <EditBusiness align="left" bar={bar} balance={balance} />
        </ContentCard>
        <ContentCard title={t("settings.integrations")}>
          <EditIntegrations
            align="left"
            bar={bar}
            allIntegrations={allIntegrations}
          />
        </ContentCard>
        <ContentCard title={t("settings.look-and-feel")}>
          <div className={classes.assets}>
            <div className={classes.asset}>
              <Typography variant="body1" className={classes.subtitle}>
                {t("settings.logo")}
              </Typography>
              <EditLogo bar={bar} url={assets?.logoImageUrl} />
            </div>
            <div className={classes.asset}>
              <Typography variant="body1" className={classes.subtitle}>
                {t("settings.cover")}
              </Typography>
              <EditCover bar={bar} url={coverImageUrl} />
            </div>
          </div>
          {theme.editableColors.length > 0 && (
            <div>
              <Typography variant="body1" className={classes.subtitle}>
                {t("settings.colors")}
              </Typography>
              <EditColors bar={bar} />
            </div>
          )}
        </ContentCard>
      </div>
    );
  }
}

export default withPasswordAuthentication()(
  withBarAuthorization()(
    withStyles(styles, { withTheme: true })(
      withTranslation("common")(SettingsPage)
    )
  )
);
