import { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Switch,
  TextField
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { barService } from "tap-io/client/services";
import { barHelper, utilsHelper } from "tap-io/helpers";

import PaymentProvider from "tap-io/models/payment/PaymentProvider";

import ConfirmDeactivatePaymentProvider from "../payment/ConfirmDeactivatePaymentProvider";

const styles = (theme) => ({
  accentuate: {
    color: "red"
  },
  buttons: {
    justifyContent: "flex-end"
  },
  link: {
    textDecoration: "none"
  }
});

class EditMultiSafePay extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (conf, isReady) => {
    const mergedConf = {
      apiKey: conf && conf.apiKey ? conf.apiKey : ""
    };

    return {
      isConfirmDeactivateMultiSafePayPaymentsDialogOpen: false,
      isReady: isReady === true,
      isValid: barHelper.isValidBarMultiSafePayPaymentProviderConf(conf),
      isDisabled: false,
      isAPIKeyRevealed: false,
      conf: mergedConf,
      savedConf: mergedConf
    };
  };

  initConf = () => {
    const { bar } = this.props;

    if (this.unsubscribeMultiSafePayConf) {
      this.unsubscribeMultiSafePayConf();
      this.unsubscribeMultiSafePayConf = undefined;
    }

    this.unsubscribeMultiSafePayConf =
      barService.onBarMultiSafePayPaymentProviderConf(bar, (conf) => {
        this.setState(this.initialState(conf, true));
      });
  };

  componentDidMount() {
    const { setOnSave } = this.props;

    if (setOnSave) {
      setOnSave(this.handleSaveConf);
    }

    this.initConf();
  }

  componentDidUpdate(prevProps, prevState) {
    const { bar } = this.props;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;

    if (barId !== prevBarId) {
      this.initConf();
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeMultiSafePayConf) {
      this.unsubscribeMultiSafePayConf();
      this.unsubscribeMultiSafePayConf = undefined;
    }
  }

  handleChange = (key) => (event) => {
    const { conf } = this.state;
    const val = event.target.value;

    const newConf = { ...conf, [key]: val };
    const isValid =
      barHelper.isValidBarMultiSafePayPaymentProviderConf(newConf);

    this.setState({ isValid, conf: newConf });
  };

  handleRevealAPIKey = (event) => {
    this.setState({ isAPIKeyRevealed: true });
  };

  handleObfuscateAPIKey = (event) => {
    this.setState({ isAPIKeyRevealed: false });
  };

  hasConfChanged = () => {
    const { conf, savedConf } = this.state;

    return !utilsHelper.areObjectsEqualShallow(conf, savedConf);
  };

  handleSaveConf = async () => {
    const { t, bar } = this.props;
    const { conf } = this.state;

    const toastId = toast(t("settings.saving-multisafepay-configuration"), {
      autoClose: false
    });
    this.setState({ isDisabled: true });

    try {
      await barService.setBarMultiSafePayPaymentProviderConf(bar, conf);

      toast.update(toastId, {
        render: t("settings.multisafepay-configuration-saved"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
      this.setState({ isDisabled: false });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleRestoreConf = () => {
    this.initConf();
  };

  handleAllowMultiSafePayPayments = async (event) => {
    const allowMultiSafePayPayments = event.target.checked === true;

    if (allowMultiSafePayPayments) {
      this.updateAllowMultiSafePayPayments(allowMultiSafePayPayments);
    } else {
      this.setState({
        isConfirmDeactivateMultiSafePayPaymentsDialogOpen: true
      });
    }
  };

  handleConfirmDeactivateMultiSafePayPayments = () => {
    this.setState({ isConfirmDeactivateMultiSafePayPaymentsDialogOpen: false });

    this.updateAllowMultiSafePayPayments(false);
  };

  handleCancelDeactivateMultiSafePayPayments = () => {
    this.setState({ isConfirmDeactivateMultiSafePayPaymentsDialogOpen: false });
  };

  updateAllowMultiSafePayPayments = async (allowMultiSafePayPayments) => {
    const { t, bar } = this.props;

    if (allowMultiSafePayPayments) {
      await this.handleSaveConf();
    }

    const toastId = toast(
      allowMultiSafePayPayments
        ? t("settings.enabling-multisafepay-payments")
        : t("settings.disabling-multisafepay-payments"),
      { autoClose: false }
    );
    this.setState({ isDisabled: true });

    try {
      await barService.setBarMultiSafePayPaymentProviderIsActive(
        bar,
        allowMultiSafePayPayments
      );

      toast.update(toastId, {
        render: allowMultiSafePayPayments
          ? t("settings.multisafepay-payments-enabled")
          : t("settings.multisafepay-payments-disabled"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, bar, setOnSave } = this.props;
    const {
      isConfirmDeactivateMultiSafePayPaymentsDialogOpen,
      isReady,
      isValid,
      isDisabled,
      isAPIKeyRevealed,
      conf
    } = this.state;
    const { apiKey } = conf;

    const allowMultiSafePayPayments = bar.isAllowingMultiSafePayPayments();

    return (
      <div>
        <ConfirmDeactivatePaymentProvider
          isOpen={isConfirmDeactivateMultiSafePayPaymentsDialogOpen}
          paymentProvider={PaymentProvider.MULTISAFEPAY}
          onConfirm={this.handleConfirmDeactivateMultiSafePayPayments}
          onCancel={this.handleCancelDeactivateMultiSafePayPayments}
        />
        {isReady ? (
          <List>
            <ListItem>
              <ListItemText
                primary={t("settings.multisafepay-payments-label")}
                secondary={
                  !isValid && (
                    <span className={classes.accentuate}>
                      {t(
                        "settings.complete-configuration-below-to-enable-payments"
                      )}
                    </span>
                  )
                }
              />
              <Switch
                onChange={this.handleAllowMultiSafePayPayments}
                checked={allowMultiSafePayPayments}
                disabled={
                  isDisabled || (!isValid && !allowMultiSafePayPayments)
                }
              />
            </ListItem>
            {allowMultiSafePayPayments && (
              <ListItem>
                <ListItemText
                  primary={t(
                    "settings.problems-with-multisafepay-online-payments"
                  )}
                  secondary={
                    <a
                      className={classes.link}
                      href="https://status.multisafepay.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("settings.check-multisafepay-status-page")}
                    </a>
                  }
                />
              </ListItem>
            )}
            <ListItem>
              <TextField
                variant="standard"
                margin="dense"
                label={t("settings.multisafepay-live-api-key-label")}
                helperText={t("settings.multisafepay-live-api-key-description")}
                fullWidth
                type={isAPIKeyRevealed ? undefined : "password"}
                value={apiKey}
                onChange={this.handleChange("apiKey")}
                onFocus={this.handleRevealAPIKey}
                onBlur={this.handleObfuscateAPIKey}
                disabled={isDisabled || allowMultiSafePayPayments}
                autoComplete="new-password"
                required
              />
            </ListItem>
            {!allowMultiSafePayPayments && !setOnSave && (
              <ListItem className={classes.buttons}>
                {this.hasConfChanged() && (
                  <Button
                    color="secondary"
                    className={classes.button}
                    disabled={isDisabled}
                    onClick={this.handleRestoreConf}
                  >
                    {t("label.restore")}
                  </Button>
                )}
                <Button
                  color="primary"
                  className={classes.button}
                  disabled={isDisabled}
                  onClick={this.handleSaveConf}
                >
                  {t("label.save")}
                </Button>
              </ListItem>
            )}
          </List>
        ) : (
          <CircularProgress />
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditMultiSafePay)
);
