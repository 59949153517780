import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import { Button, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import ContentCard from "tap-io/client/components/common/ContentCard";

import * as routes from "../constants/routes";
import withPasswordAuthentication from "../components/auth/withPasswordAuthentication";
import withBarAuthorization from "../components/auth/withBarAuthorization";
import BarLink from "../components/promo/BarLink";
import BarWaiterLink from "../components/promo/BarWaiterLink";
import TableNumbers from "../components/promo/TableNumbers";
import Vouchers from "../components/voucher/Vouchers";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  settingsLink: {
    display: "block",
    width: "100%",
    margin: theme.spacing(1),
    textDecoration: "none"
  }
});

class BarPromoPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes,
      t,
      bar,
      subscription,
      assets,
      allMenus,
      allZones,
      deviceSettings
    } = this.props;

    return (
      <div className={classes.container}>
        <ContentCard title={t("promo.public-order-page")}>
          <BarLink bar={bar} assets={assets} />
        </ContentCard>
        <ContentCard title={t("promo.order-page-for-waiters")}>
          <BarWaiterLink bar={bar} />
        </ContentCard>
        <ContentCard title={t("promo.table-numbers")}>
          <TableNumbers bar={bar} allZones={allZones} />
        </ContentCard>
        <ContentCard title={t("promo.vouchers")}>
          {bar.isAllowingVoucherPayments() ? (
            <Vouchers
              bar={bar}
              subscription={subscription}
              assets={assets}
              allMenus={allMenus}
              deviceSettings={deviceSettings}
            />
          ) : (
            <div>
              <Typography>
                {t("promo.allow-voucher-payments-in-settings-to-use-vouchers")}
              </Typography>
              <Link to={routes.SETTINGS} className={classes.settingsLink}>
                <Button fullWidth>{t("promo.go-to-settings")}</Button>
              </Link>
            </div>
          )}
        </ContentCard>
      </div>
    );
  }
}

export default withPasswordAuthentication()(
  withBarAuthorization()(
    withStyles(styles, { withTheme: true })(
      withTranslation("common")(BarPromoPage)
    )
  )
);
