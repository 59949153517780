import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import LayoutNodeType from "tap-io/models/zone/LayoutNodeType";
import SequenceLayoutNode from "tap-io/models/zone/SequenceLayoutNode";
import WholeNumbersLayoutNodeSequence from "tap-io/models/zone/WholeNumbersLayoutNodeSequence";

import { LAYOUT_NODE_ICONS } from "../../constants/zones";

const styles = (theme) => ({});

class SequenceLayoutNodeTypeMenuItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleChangeLayoutNodeType = () => {
    const { t, onUpdate, layoutNode } = this.props;

    try {
      if (layoutNode.parent) {
        const newLayoutNode = new SequenceLayoutNode(
          layoutNode.code,
          layoutNode.name,
          new WholeNumbersLayoutNodeSequence(1, 10, 1),
          layoutNode.bases,
          layoutNode.children
        );

        layoutNode.parent.replaceChild(layoutNode, newLayoutNode);

        onUpdate(newLayoutNode);
      }
    } catch (error) {
      toast.error(
        t(error && error.message ? error.message : "label.unknown-error")
      );
    }
  };

  render() {
    const { t } = this.props;

    const LayoutNodeIcon = LAYOUT_NODE_ICONS[LayoutNodeType.SEQUENCE];

    return (
      <MenuItem onClick={this.handleChangeLayoutNodeType}>
        <ListItemIcon>
          <LayoutNodeIcon />
        </ListItemIcon>
        <ListItemText primary={t("zone.sequence-layout-node")} />
      </MenuItem>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(SequenceLayoutNodeTypeMenuItem)
);
