import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const MAX_AMOUNT_OF_ORDERS = 5;

const styles = (theme) => ({
  items: {
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "scroll"
  },
  item: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 3, 0.5, 0),
    "&:first-child": {},
    "&:last-child": {
      paddingRight: 0
    },
    fontWeight: "bold"
  },
  itemName: {
    marginLeft: theme.spacing(1),
    fontSize: 16,
    whiteSpace: "nowrap"
  },
  itemAmount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  bumper: {
    flexGrow: 1,
    minWidth: 30
  }
});

class TopItemsInQueueRightNow extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getTopItemsInQueueRightNow = () => {
    const { base, openOrders } = this.props;

    const itemsAsMap = openOrders
      ? openOrders.reduce((itemsAsMap, order) => {
        // Only queued
        if (order.isStatusQueued(base)) {
          order.getItems(base).forEach((item) => {
            const id = item.productId ? item.productId : item.id;

            if (!itemsAsMap[id]) {
              itemsAsMap[id] = {
                id,
                names: [item.name],
                amount: 0
              };
            }

            if (itemsAsMap[id].names.indexOf(item.name) < 0) {
              itemsAsMap[id].names.push(item.name);
            }
            itemsAsMap[id].amount += item.amount;
          });
        }

        return itemsAsMap;
      }, {})
      : {};

    return Object.keys(itemsAsMap)
      .map((id) => {
        return {
          id,
          name: itemsAsMap[id].names.join(", "),
          amount: itemsAsMap[id].amount
        };
      })
      .sort((itemA, itemB) => itemB.amount - itemA.amount)
      .slice(0, MAX_AMOUNT_OF_ORDERS);
  };

  render() {
    const { classes, t, color } = this.props;

    const items = this.getTopItemsInQueueRightNow();

    return (
      <div
        className={classes.items}
      >
        <div className={classes.bumper} />
        {items.length > 0 ? (
          items.map((item) => (
            <div key={item.id} className={classes.item}>
              <span className={classes.itemAmount} style={{ backgroundColor: color, color: color ? "black" : undefined }}>{item.amount}</span>
              <span className={classes.itemName}>{item.name}</span>
            </div>
          ))
        ) : (
          <Typography variant="caption">{t("order.no-items-found")}</Typography>
        )}
        <div className={classes.bumper} />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(TopItemsInQueueRightNow)
);
