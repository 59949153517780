import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import withStyles from "@mui/styles/withStyles";

import { serviceOptionHelper } from "tap-io/helpers";

import SelectServiceOptions from "../service/SelectServiceOptions";

const styles = (theme) => ({
  bases: {
    display: "flex",
    flexDirection: "column"
  },
  warning: {
    verticalAlign: "middle"
  },
  serviceOptions: {
    paddingLeft: theme.spacing(2)
  },
  serviceOptionDescription: {
    color: theme.palette.text.disabled
  }
});

class SelectLayoutNodeBases extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  isLayoutNodeBaseValid = (baseId) => {
    const { layoutNodeBases } = this.props;

    const layoutNodeBase = layoutNodeBases.get(baseId);
    return !layoutNodeBase || layoutNodeBase.hasAnyServiceOption;
  };

  handleLayoutNodeBaseToggle = (baseId) => (event) => {
    const { onChange, layoutNodeBases } = this.props;

    const updatedLayoutNodeBases = layoutNodeBases.clone();
    if (updatedLayoutNodeBases.has(baseId)) {
      updatedLayoutNodeBases.clear(baseId);
    } else {
      updatedLayoutNodeBases.set(baseId);
    }
    onChange(updatedLayoutNodeBases);
  };

  handleServiceOptionChange = (baseId) => (serviceOption, isActive) => {
    const { onChange, layoutNodeBases } = this.props;

    const updatedLayoutNodeBases = layoutNodeBases.clone();

    const layoutNodeBase = updatedLayoutNodeBases.get(baseId);
    if (layoutNodeBase) {
      if (isActive) {
        layoutNodeBase.serviceOptions.add(serviceOption);
      } else {
        layoutNodeBase.serviceOptions.remove(serviceOption);
      }
    }

    onChange(updatedLayoutNodeBases);
  };

  getDisabledServiceOptions = (
    availableServiceOptions,
    currentServiceOptions
  ) => {
    const disabledServiceOptions =
      serviceOptionHelper.createInvertedServiceOptions(availableServiceOptions);

    currentServiceOptions.forEach((serviceOption) => {
      if (disabledServiceOptions.has(serviceOption.name)) {
        disabledServiceOptions.remove(serviceOption);
      }
    });

    return disabledServiceOptions;
  };

  render() {
    const { classes, t, isDisabled, bar, allBases, layoutNodeBases } =
      this.props;

    return (
      <div>
        {allBases && allBases.length > 0 ? (
          <div className={classes.bases}>
            {allBases.map((base) => (
              <div key={base.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={layoutNodeBases.has(base.id)}
                      disabled={isDisabled}
                      onChange={this.handleLayoutNodeBaseToggle(base.id)}
                      name={base.id}
                      color="primary"
                    />
                  }
                  label={base.name}
                />
                {!this.isLayoutNodeBaseValid(base.id) && (
                  <ErrorIcon color="error" className={classes.warning} />
                )}
                {layoutNodeBases.has(base.id) && (
                  <div className={classes.serviceOptions}>
                    <SelectServiceOptions
                      isDisabled={isDisabled}
                      allServiceOptions={serviceOptionHelper.createAllServiceOptions()}
                      disabledServiceOptions={this.getDisabledServiceOptions(
                        base.serviceOptions,
                        layoutNodeBases.get(base.id)?.serviceOptions
                      )}
                      serviceOptions={
                        layoutNodeBases.get(base.id)?.serviceOptions
                      }
                      onChange={this.handleServiceOptionChange(base.id)}
                      getIcon={(serviceOption, isActive) =>
                        isActive &&
                        !base.serviceOptions.has(serviceOption.name) ? (
                          <ErrorIcon
                            color="error"
                            className={classes.warning}
                          />
                        ) : null
                      }
                      getDescription={(serviceOption) =>
                        base.serviceOptions.has(serviceOption.name) ? null : (
                          <Typography
                            variant="caption"
                            className={classes.serviceOptionDescription}
                          >
                            {t(
                              "zone.service-option-not-available-for-this-base"
                            )}
                          </Typography>
                        )
                      }
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <Typography>{t("base.no-bases-found")}</Typography>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(SelectLayoutNodeBases)
);
