import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    smallSpacing: {
        height: 10
    },
    largeSpacing: {
        height: 30
    }
});

class NoOrderPreparationOption extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {};
    }

    handleNoPreparationOptionClick = (event) => {
        const { onSelect } = this.props;

        onSelect(null);
    };

    render() {
        const { classes, t, isDisabled, size, variant, isCompact } = this.props;

        return (
            <div className={classes.content}>
                <Button
                    color="primary"
                    size={size}
                    variant="contained"
                    disabled={isDisabled}
                    onClick={this.handleNoPreparationOptionClick}
                >
                    {t("order.claim-order")}
                </Button>
            </div>
        );
    }
}

export default withStyles(styles, {
    withTheme: true
})(withTranslation("common")(NoOrderPreparationOption));
