import { Stat } from "./Stat";

type Data = {
  device: string;
  name: string;
  version: string;
  valueExclVat: number;
  vat: number;
  valueInclVat: number;
  currency: string;
};

export class FinancialDeviceReport extends Stat<Data> {
  toCSV(t: any) {
    return this.generateCSV(
      {
        device: true,
        name: true,
        version: true,
        valueExclVat: this.formatCurrency,
        vat: this.formatCurrency,
        valueInclVat: this.formatCurrency,
        currency: true
      },
      t
    );
  }
}
