import React from "react";

import { withTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import { toast } from "react-toastify";

import {
  List,
  ListItem,
  ListItemText,
  Switch,
  Typography
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import * as routes from "../../constants/routes";

import { barService } from "tap-io/client/services";
import PaymentProvider from "tap-io/models/payment/PaymentProvider";

import ConfirmDeactivatePaymentProvider from "../payment/ConfirmDeactivatePaymentProvider";

const styles = (theme) => ({
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main
  }
});

class EditVoucher extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmDeactivateVoucherPaymentsDialogOpen: false
    };
  }

  handleAllowVoucherPayments = async (event) => {
    const allowVoucherPayments = event.target.checked === true;

    if (allowVoucherPayments) {
      this.updateAllowVoucherPayments(allowVoucherPayments);
    } else {
      this.setState({ isConfirmDeactivateVoucherPaymentsDialogOpen: true });
    }
  };

  handleConfirmDeactivateVoucherPayments = () => {
    this.setState({ isConfirmDeactivateVoucherPaymentsDialogOpen: false });

    this.updateAllowVoucherPayments(false);
  };

  handleCancelDeactivateVoucherPayments = () => {
    this.setState({ isConfirmDeactivateVoucherPaymentsDialogOpen: false });
  };

  updateAllowVoucherPayments = async (allowVoucherPayments) => {
    const { t, bar } = this.props;

    const toastId = toast(
      allowVoucherPayments
        ? t("settings.enabling-voucher-payments")
        : t("settings.disabling-voucher-payments"),
      { autoClose: false }
    );
    this.setState({ isDisabled: true });

    try {
      await barService.setBarVoucherPaymentProviderIsActive(
        bar,
        allowVoucherPayments
      );

      toast.update(toastId, {
        render: allowVoucherPayments
          ? t("settings.voucher-payments-enabled")
          : t("settings.voucher-payments-disabled"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { t, bar, classes } = this.props;
    const { isDisabled, isConfirmDeactivateVoucherPaymentsDialogOpen } =
      this.state;

    return (
      <div>
        <ConfirmDeactivatePaymentProvider
          isOpen={isConfirmDeactivateVoucherPaymentsDialogOpen}
          paymentProvider={PaymentProvider.VOUCHER}
          onConfirm={this.handleConfirmDeactivateVoucherPayments}
          onCancel={this.handleCancelDeactivateVoucherPayments}
        />
        <List>
          <ListItem>
            <ListItemText
              primary={t("settings.voucher-payments-label")}
              secondary={t("settings.voucher-payments-description")}
            />
            <Switch
              onChange={this.handleAllowVoucherPayments}
              checked={bar.isAllowingVoucherPayments()}
              disabled={isDisabled}
            />
          </ListItem>
          {bar.isAllowingVoucherPayments() && (
            <Link to={routes.PROMO} className={classes.link}>
              <ListItem button>
                <ListItemText
                  primary={
                    <Typography variant="button">
                      {t("settings.generate-and-manage-vouchers")}
                    </Typography>
                  }
                />
              </ListItem>
            </Link>
          )}
        </List>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditVoucher)
);
