import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import withStyles from "@mui/styles/withStyles";

import { barService } from "tap-io/client/services";
import { integrationHelper } from "tap-io/helpers";
import ConfirmDialog from "tap-io/client/components/common/ConfirmDialog";
import ContentCard from "tap-io/client/components/common/ContentCard";

import withBarAuthorization from "../components/auth/withBarAuthorization";
import withPasswordAuthentication from "../components/auth/withPasswordAuthentication";
import SettingsCard from "../components/common/SettingsCard";
import EditBases from "../components/base/EditBases";
import EditZones from "../components/zone/EditZones";
import CapabilityUnavailableButton from "../components/subscription/CapabilityUnavailableButton";
import Scanners from "../components/promo/Scanners";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  capabilityButton: {
    marginTop: 10
  },
  basesTitle: {
    display: "flex",
    alignItems: "center"
  },
  lockIcon: {
    marginLeft: theme.spacing(0.5),
    fontSize: 20,
    color: theme.palette.text.primary
  },
  zonesNotAvailable: {
    margin: 16,
    fontWeight: "bold",
    color: "red"
  }
});

class BarLogisticsPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmDialogOpen: false,
      confirmDialogTitle: "",
      confirmDialogDescription: "",
      confirmDialogConfirmButtonLabel: "",
      confirmCallback: null
    };
  }

  showConfirmDialog = (title, description, confirmButtonLabel, callback) => {
    this.setState({
      isConfirmDialogOpen: true,
      confirmDialogTitle: title ? title : "",
      confirmDialogDescription: description ? description : "",
      confirmDialogConfirmButtonLabel: confirmButtonLabel
        ? confirmButtonLabel
        : undefined,
      confirmCallback: callback ? callback : null
    });
  };

  hideConfirmDialog = () => {
    this.setState({
      isConfirmDialogOpen: false,
      confirmDialogTitle: "",
      confirmDialogDescription: "",
      confirmDialogConfirmButtonLabel: "",
      confirmCallback: null
    });
  };

  handleConfirm = () => {
    const { confirmCallback } = this.state;

    if (confirmCallback) {
      confirmCallback();
    }

    this.hideConfirmDialog();
  };

  handleCancel = () => {
    this.hideConfirmDialog();
  };

  handleUseBasesToggle = (useBases) => {
    const { t } = this.props;

    this.showConfirmDialog(
      t(
        useBases ? "base.enable-multiple-bases" : "base.disable-multiple-bases"
      ),
      t(
        useBases
          ? "base.are-you-sure-you-want-to-enable-multiple-bases"
          : "base.are-you-sure-you-want-to-disable-multiple-bases"
      ),
      t(
        useBases ? "base.enable-multiple-bases" : "base.disable-multiple-bases"
      ),
      async () => {
        await this.toggleUseBases(useBases);
      }
    );
  };

  handleUseZonesToggle = (useZones) => {
    const { t } = this.props;

    this.showConfirmDialog(
      t(useZones ? "zone.enable-zones" : "zone.disable-zones"),
      t(
        useZones
          ? "zone.are-you-sure-you-want-to-enable-zones"
          : "zone.are-you-sure-you-want-to-disable-zones"
      ),
      t(useZones ? "zone.enable-zones" : "zone.disable-zones"),
      async () => {
        await this.toggleUseZones(useZones);
      }
    );
  };

  toggleUseBases = async (useBases) => {
    const { t, bar } = this.props;

    const toastId = toast(
      useBases
        ? t("base.enabling-multiple-bases")
        : t("base.disabling-multiple-bases"),
      { autoClose: false }
    );

    try {
      const barParams = barService.createBarParams(bar, { useBases });
      await barService.updateBarParams(bar, barParams);

      toast.update(toastId, {
        render: useBases
          ? t("base.multiple-bases-enabled")
          : t("base.multiple-bases-disabled"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(error.message)})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  toggleUseZones = async (useZones) => {
    const { t, bar } = this.props;

    const toastId = toast(
      useZones ? t("zone.enabling-zones") : t("zone.disabling-zones"),
      { autoClose: false }
    );

    try {
      const barParams = barService.createBarParams(bar, {});
      barParams.setUseZones(useZones, t("order.number-of-your-table"));
      await barService.updateBarParams(bar, barParams);

      toast.update(toastId, {
        render: useZones ? t("zone.zones-enabled") : t("zone.zones-disabled"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(error.message)})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const {
      classes,
      t,
      bar,
      subscription,
      allBases,
      allZones,
      allIntegrations
    } = this.props;
    const {
      isConfirmDialogOpen,
      confirmDialogTitle,
      confirmDialogDescription,
      confirmDialogConfirmButtonLabel
    } = this.state;

    const areBasesAvailable =
      subscription && subscription.capabilities.areBasesAvailable();
    const areZonesAvailable =
      subscription && subscription.capabilities.areZonesAvailable();
    const areScannersAvailable =
      subscription && subscription.capabilities.areScannersAvailable();

    const isAbiIntegrationActive =
      allIntegrations &&
      integrationHelper.findAbiIntegration(allIntegrations)?.isActive;
    const areBasesEditable = !isAbiIntegrationActive;
    const areScannersDisabled = isAbiIntegrationActive;

    return (
      <div className={classes.container}>
        <ConfirmDialog
          isOpen={isConfirmDialogOpen}
          title={confirmDialogTitle}
          description={confirmDialogDescription}
          confirmButtonLabel={confirmDialogConfirmButtonLabel}
          onConfirm={this.handleConfirm}
          onCancel={this.handleCancel}
        />
        <SettingsCard
          hideCardEnabledToggle={bar.isUsingBases()}
          isDisabled={
            (!bar.isUsingBases() && !areBasesAvailable) || !areBasesEditable
          }
          isCardEnabled={bar.isUsingBases()}
          onIsEnabledChange={this.handleUseBasesToggle}
          title={
            <span className={classes.basesTitle}>
              {t("settings.param-use-bases-label")}
              {!areBasesEditable && <LockIcon className={classes.lockIcon} />}
            </span>
          }
          description={t("settings.param-use-bases-description")}
        >
          {!areBasesAvailable && (
            <div className={classes.capabilityButton}>
              <CapabilityUnavailableButton
                bar={bar}
                subscription={subscription}
              />
            </div>
          )}
          {bar.isUsingBases() && (
            <EditBases
              bar={bar}
              subscription={subscription}
              allBases={allBases}
              isEditable={areBasesEditable}
            />
          )}
        </SettingsCard>
        <SettingsCard
          hideCardEnabledToggle={bar.isUsingZones()}
          isDisabled={
            !bar.isUsingBases() || (!bar.isUsingZones() && !areZonesAvailable)
          }
          isCardEnabled={bar.isUsingZones()}
          onIsEnabledChange={this.handleUseZonesToggle}
          title={t("settings.param-use-zones-label")}
          description={t("settings.param-use-zones-description")}
        >
          {!areZonesAvailable && (
            <div className={classes.capabilityButton}>
              <CapabilityUnavailableButton
                bar={bar}
                subscription={subscription}
              />
            </div>
          )}
          {areZonesAvailable && !bar.isUsingBases() && (
            <Typography className={classes.zonesNotAvailable}>
              {t("zone.only-possible to-use-zones-if-bases-are-used")}
            </Typography>
          )}
          {bar.isUsingZones() && (
            <EditZones
              bar={bar}
              subscription={subscription}
              allBases={allBases}
              allZones={allZones}
            />
          )}
        </SettingsCard>
        <ContentCard
          title={t("promo.scanners")}
          description={t("promo.scanners-description")}
          isResponsive={false}
        >
          {areScannersAvailable ? (
            <Scanners
              bar={bar}
              subscription={subscription}
              allBases={allBases}
              isDisabled={areScannersDisabled}
            />
          ) : (
            <div className={classes.capabilityButton}>
              <CapabilityUnavailableButton
                bar={bar}
                subscription={subscription}
              />
            </div>
          )}
        </ContentCard>
      </div>
    );
  }
}

export default withPasswordAuthentication()(
  withBarAuthorization()(
    withStyles(styles, { withTheme: true })(
      withTranslation("common")(BarLogisticsPage)
    )
  )
);
