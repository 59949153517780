import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { CircularProgress, List, ListItem, ListItemText } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import OrderItem from "./OrderItem";
import { utilsHelper } from "tap-io/helpers";
import { orderService } from "tap-io/client/services";

const styles = (theme) => ({
  progress: {
    display: "flex",
    justifyContent: "center",
    padding: "20px 0"
  },
  noOrders: {
    textAlign: "center"
  },
  minQueryLengthText: {
    display: "block",
    marginTop: 10
  }
});

class SearchedOrders extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      orderSearchResults: []
    };
  }

  componentDidMount() {
    this.refreshOrders();
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchQuery } = this.props;
    const prevSearchQuery = prevProps.searchQuery;

    if (searchQuery !== prevSearchQuery) {
      this.refreshOrders();
    }
  }

  refreshOrders = () => {
    const { bar, base, searchQuery } = this.props;

    this.setState({ isLoading: true });

    if (this.orderSearchPromise) {
      this.orderSearchPromise.cancel();
      this.orderSearchPromise = undefined;
    }

    if (bar && searchQuery) {
      this.orderSearchPromise = utilsHelper.makePromiseCancelable(
        orderService.searchOrdersByConfirmationCode(
          bar.id,
          searchQuery,
          base ? base.menuIds : undefined
        )
      );

      this.orderSearchPromise.promise
        .then((orderSearchResults) => {
          this.setState({ isLoading: false, orderSearchResults });
        })
        .catch((error) => {
          console.warn(error);

          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ orderSearchResults: null });
    }
  };

  render() {
    const { classes, t, bar, base, onOrderClick } = this.props;
    const { isLoading, orderSearchResults } = this.state;

    return (
      <div>
        {isLoading ? (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        ) : (
          <List>
            {orderSearchResults && orderSearchResults.length > 0 ? (
              orderSearchResults.map((order) => (
                <OrderItem
                  key={order.id}
                  bar={bar}
                  base={base}
                  order={order}
                  onClick={onOrderClick}
                />
              ))
            ) : (
              <ListItem>
                <ListItemText
                  className={classes.noOrders}
                  primary={t("order.no-orders-found")}
                  secondary={
                    <span className={classes.minQueryLengthText}>
                      {t("order.enter-min-x-characters-of-confirmation-code-1")}{" "}
                      <strong>
                        {t(
                          "order.enter-min-x-characters-of-confirmation-code-2",
                          {
                            minQueryLength:
                              orderService.SEARCH_BY_CONFIRMATION_CODE_MIN_QUERY_LENGTH
                          }
                        )}
                      </strong>{" "}
                      {t("order.enter-min-x-characters-of-confirmation-code-3")}
                    </span>
                  }
                />
              </ListItem>
            )}
          </List>
        )}
      </div>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(SearchedOrders));
