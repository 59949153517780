import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import AutorenewIcon from "@mui/icons-material/Autorenew";
import withStyles from "@mui/styles/withStyles";

import { deviceStorage } from "tap-io/storage";

import { CACHE_KEY_BAR_ID } from "../../constants/cache";
import BarMenuItem from "./BarMenuItem";

const styles = (theme) => ({});

class BarSoundControlsMenuItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSwitchBar = async () => {
    const { setBarId } = this.props;

    await deviceStorage.clearDeviceCacheItem("", CACHE_KEY_BAR_ID);
    setBarId(null);
  };

  render() {
    const { t } = this.props;

    return (
      <BarMenuItem
        icon={<AutorenewIcon />}
        label={t("label.switch-bar")}
        onClick={this.handleSwitchBar}
      />
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(BarSoundControlsMenuItem)
);
