import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { subscriptionService } from "tap-io/client/services";

import PlanExpiredDescription from "./PlanExpiredDescription";
import PlanRequestedDescription from "./PlanRequestedDescription";
import PlanActiveDescription from "./PlanActiveDescription";
import { PLAN_FULL_SERVICE } from "../../constants/plans";
import SelectPlanDialog from "./SelectPlanDialog";
import RequestPlanRenewalButton from "./RequestPlanRenewalButton";
import DowngradePlanButton from "./DowngradePlanButton";

const styles = (theme) => ({
  dialog: {
    //maxWidth: 450
  },
  validUntil: {
    display: "block",
    margin: "10px 0"
  },
  isAboutToExpire: {
    display: "block",
    margin: "10px 0",
    color: theme.palette.error.main
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(4)
  },
  spacing: {
    height: 15
  },
  accentuate: {
    color: theme.palette.error.main
  }
});

class ShowCurrentPlanDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isSelectPlanDialogOpen: false
    };
  }

  handleSelectPlan = () => {
    this.setState({ isSelectPlanDialogOpen: true });
  };

  handleSelectPlanDialogClose = () => {
    this.setState({ isSelectPlanDialogOpen: false });
  };

  handleOnBusy = (isBusy) => {
    this.setState({ isBusy });
  };

  render() {
    const { classes, t, isOpen, onClose, bar, subscription } = this.props;
    const { isSelectPlanDialogOpen, isBusy } = this.state;

    const showRequestPlanRenewalAndDowngradePlanButtons =
      !subscription.isRequested() &&
      (subscription.isExpired() ||
        subscriptionService.isSubscriptionAboutToExpire(subscription));

    return (
      <div>
        <SelectPlanDialog
          isOpen={isSelectPlanDialogOpen}
          onClose={this.handleSelectPlanDialogClose}
          bar={bar}
          subscription={subscription}
        />
        <Dialog
          open={isOpen}
          onClose={onClose}
          classes={{ paper: classes.dialog }}
        >
          <DialogTitle>{t("subscription.your-current-plan")}</DialogTitle>
          <DialogContent>
            {subscription && (
              <>
                {subscription.isRequested() ? (
                  <PlanRequestedDescription subscription={subscription} />
                ) : subscription.isExpired() ? (
                  <PlanExpiredDescription subscription={subscription} />
                ) : (
                  <PlanActiveDescription subscription={subscription} />
                )}
                <div>
                  <div className={classes.buttons}>
                    {showRequestPlanRenewalAndDowngradePlanButtons ? (
                      <>
                        <RequestPlanRenewalButton
                          isDisabled={isBusy}
                          bar={bar}
                          subscription={subscription}
                          onBusy={this.handleOnBusy}
                        />
                        <div className={classes.spacing} />
                        <DowngradePlanButton
                          isDisabled={isBusy}
                          bar={bar}
                          subscription={subscription}
                          onBusy={this.handleOnBusy}
                        />
                        <br />
                        <span className={classes.accentuate}>
                          {t(
                            "subscription.alert-some-features-you-currently-use-might-not-be-available-when-you-downgrade"
                          )}
                        </span>
                      </>
                    ) : (
                      !subscription.isRequested() &&
                      subscription.planName !== PLAN_FULL_SERVICE && (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={this.handleSelectPlan}
                        >
                          {t("subscription.change-plan")}
                        </Button>
                      )
                    )}
                  </div>
                </div>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="secondary">
              {t("label.close")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(ShowCurrentPlanDialog)
);
