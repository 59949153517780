import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import NoMeetingRoomIcon from "@mui/icons-material/NoMeetingRoom";
import withStyles from "@mui/styles/withStyles";

import { baseService, menuService } from "tap-io/client/services";
import ConfirmDialog from "tap-io/client/components/common/ConfirmDialog";

const styles = {
  baseActiveColor: {
    color: "green"
  },
  baseInactiveColor: {
    color: "red"
  },
  smallSpacing: {
    height: 20
  },
  largeSpacing: {
    height: 50
  }
};

class SelectCurrentBase extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmToggleBaseIsActiveDialogOpen: false,
      basePendingSelectionConfirmation: null
    };
  }

  handleToggleBaseIsActive = (event) => {
    this.setState({ isConfirmToggleBaseIsActiveDialogOpen: true });
  };

  handleToggleBaseIsActiveConfirm = async () => {
    this.setState({ isConfirmToggleBaseIsActiveDialogOpen: false });

    await this.toggleBaseIsActive();
  };

  handleToggleBaseIsActiveCancel = () => {
    this.setState({ isConfirmToggleBaseIsActiveDialogOpen: false });
  };

  toggleBaseIsActive = async () => {
    const { t, base } = this.props;
    const isActive = !base.isActive;

    const toastId = toast(
      isActive ? t("base.opening-base") : t("base.closing-base"),
      {
        autoClose: false
      }
    );

    this.setState({ isLoading: true });

    try {
      base.isActive = !base.isActive;
      await baseService.updateBase(
        base,
        menuService.addSetMenuIsActiveByIdInBatch
      );

      toast.update(toastId, {
        render: isActive ? t("base.base-opened") : t("base.base-closed"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
      this.setState({ isLoading: false });
    } catch (error) {
      console.warn(error);

      this.setState({ isLoading: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleSelectBase = (baseToSelect) => (event) => {
    const { base } = this.props;

    if (base) {
      this.setState({ basePendingSelectionConfirmation: baseToSelect });
    } else {
      this.selectBase(baseToSelect.id);
    }
  };

  handleSelectBaseConfirm = () => {
    const { basePendingSelectionConfirmation } = this.state;

    this.setState({ basePendingSelectionConfirmation: null });

    if (basePendingSelectionConfirmation) {
      this.selectBase(basePendingSelectionConfirmation.id);
    }
  };

  handleSelectBaseCancel = () => {
    this.setState({ basePendingSelectionConfirmation: null });
  };

  selectBase = (id) => {
    const { onSelectBase } = this.props;
    if (onSelectBase) {
      onSelectBase(id);
    }
  };

  render() {
    const { classes, t, base, allBases, isEditable, allowSwitchToOtherBase } =
      this.props;
    const {
      isLoading,
      isConfirmToggleBaseIsActiveDialogOpen,
      basePendingSelectionConfirmation
    } = this.state;

    const otherBases =
      (!base || allowSwitchToOtherBase) && allBases
        ? base
          ? allBases.filter((_base) => _base.id !== base.id)
          : allBases
        : undefined;

    return (
      <div>
        <ConfirmDialog
          isOpen={isConfirmToggleBaseIsActiveDialogOpen}
          title={t(base?.isActive ? "base.close-base" : "base.open-base")}
          description={t(
            base?.isActive
              ? "base.are-you-sure-you-want-to-close-this-base"
              : "base.are-you-sure-you-want-to-open-this-base"
          )}
          confirmButtonLabel={t(
            base?.isActive ? "base.close-base" : "base.open-base"
          )}
          onConfirm={this.handleToggleBaseIsActiveConfirm}
          onCancel={this.handleToggleBaseIsActiveCancel}
        />
        <ConfirmDialog
          isOpen={basePendingSelectionConfirmation !== null}
          title={t("base.select-other-base")}
          description={
            <span>
              {t("base.are-you-sure-you-want-select-base-x")}{" "}
              <strong>
                {basePendingSelectionConfirmation
                  ? basePendingSelectionConfirmation.name
                  : `(${t("label.unknown")})`}
              </strong>
              ?
            </span>
          }
          confirmButtonLabel={t("base.select-other-base")}
          onConfirm={this.handleSelectBaseConfirm}
          onCancel={this.handleSelectBaseCancel}
        />
        {base && (
          <div>
            <Typography>
              {`${t("base.your-current-base-is")} `}
              <strong>{base.name}</strong>
            </Typography>
            <Typography>
              {`${t("base.this-base-is")} `}
              <strong
                className={
                  base.isActive
                    ? classes.baseActiveColor
                    : classes.baseInactiveColor
                }
              >
                {base.isActive ? t("base.opened") : t("base.closed")}
              </strong>
              .
            </Typography>
            <div className={classes.smallSpacing}></div>
            <Button
              fullWidth
              variant="contained"
              disabled={isLoading || (!isEditable && !base?.externalId)}
              onClick={this.handleToggleBaseIsActive}
              startIcon={
                isLoading ? (
                  <CircularProgress size={24} />
                ) : base.isActive ? (
                  <NoMeetingRoomIcon />
                ) : (
                  <MeetingRoomIcon />
                )
              }
            >
              {`${t("base.base")} ${
                base.isActive ? t("base.close") : t("base.open")
              }`}
            </Button>
          </div>
        )}
        {base && otherBases && <div className={classes.largeSpacing}></div>}
        {otherBases && (
          <div>
            <Typography>
              {otherBases.length > 0
                ? base
                  ? t("base.switch-to-other-base")
                  : t("base.select-a-base")
                : base
                ? t("base.no-other-bases-found")
                : t("base.no-bases-found")}
            </Typography>
            <List>
              {otherBases.map((otherBase) => (
                <ListItem
                  key={otherBase.id}
                  button
                  disabled={!isEditable && !otherBase?.externalId}
                  onClick={this.handleSelectBase(otherBase)}
                >
                  <ListItemIcon>
                    {otherBase.isActive ? (
                      <MeetingRoomIcon className={classes.baseActiveColor} />
                    ) : (
                      <NoMeetingRoomIcon
                        className={classes.baseInactiveColor}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={otherBase.name}></ListItemText>
                </ListItem>
              ))}
            </List>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(SelectCurrentBase));
