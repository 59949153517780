import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { List, ListItem, MenuItem, Select, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { barService } from "tap-io/client/services";
import { barHelper } from "tap-io/helpers";

const styles = (theme) => ({
  label: {
    width: "100%"
  },
  locale: {
    minWidth: 150
  }
});

class EditLocales extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      appLocale: "",
      orderLocale: ""
    };
  }

  componentDidMount() {
    const { bar } = this.props;

    this.setState({
      appLocale: bar.getAppLocale(),
      orderLocale: bar.getOrderLocale()
    });
  }

  handleAppLocaleChange = (event) => {
    const locale = event.target.value;

    this.setState({ appLocale: locale }, this.requestSave);
  };

  handleOrderLocaleChange = (event) => {
    const locale = event.target.value;

    this.setState({ orderLocale: locale }, this.requestSave);
  };

  requestSave = () => {
    if (this.saveTimeout) {
      clearTimeout(this.saveTimeout);
    }

    this.saveTimeout = setTimeout(() => {
      this.saveTimeout = undefined;
      this.handleSave();
    }, 1000);
  };

  handleSave = async () => {
    const { t, bar } = this.props;
    const { appLocale, orderLocale } = this.state;

    const toastId = toast(t("settings.updating-locales"), {
      autoClose: false
    });

    try {
      await barService.setBarLocales(bar, appLocale, orderLocale);

      toast.update(toastId, {
        render: t("settings.locales-updated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t } = this.props;
    const { appLocale, orderLocale } = this.state;

    return (
      <List>
        <ListItem>
          <Typography className={classes.label}>
            {t("settings.app-locale")}
          </Typography>
          <Select
            variant="standard"
            className={classes.locale}
            value={appLocale}
            onChange={this.handleAppLocaleChange}
          >
            {barHelper.getAvailableLocales().map((locale) => (
              <MenuItem key={locale} value={locale}>
                {t(`locale.${locale.replace("_", "-").toLowerCase()}`)}
              </MenuItem>
            ))}
          </Select>
        </ListItem>
        <ListItem>
          <Typography className={classes.label}>
            {t("settings.order-locale")}
          </Typography>
          <Select
            variant="standard"
            className={classes.locale}
            value={orderLocale}
            onChange={this.handleOrderLocaleChange}
          >
            {barHelper.getAvailableLocales().map((locale) => (
              <MenuItem key={locale} value={locale}>
                {t(`locale.${locale.replace("_", "-").toLowerCase()}`)}
              </MenuItem>
            ))}
          </Select>
        </ListItem>
      </List>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditLocales)
);
