import React, { PureComponent } from "react";

import withStyles from "@mui/styles/withStyles";

import EditZones from "../zone/EditZones";

const styles = (theme) => ({
  container: {
    minWidth: 600
  }
});

class EditZonesStep extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { setOnNext } = this.props;

    setOnNext(this.checkZones);
  }

  checkZones = () => {
    const { allZones } = this.props;

    if (!allZones || allZones.length === 0) {
      throw new Error("error.create-at-least-one-zone");
    }
  };

  render() {
    const { classes, bar, subscription, allBases, allZones } = this.props;

    return (
      <div className={classes.container}>
        <EditZones
          bar={bar}
          subscription={subscription}
          allBases={allBases}
          allZones={allZones}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(EditZonesStep);
