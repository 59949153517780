import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import ReactMoment from "react-moment";

import {
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import RestoreIcon from "@mui/icons-material/Restore";
import withStyles from "@mui/styles/withStyles";

import { invoiceService } from "tap-io/client/services";

const INVOICE_INITIAL_BATCH_SIZE = 5;

const styles = (theme) => ({
  spinner: {
    justifyContent: "center"
  }
});

class Invoices extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false,
      invoiceMaxCount: INVOICE_INITIAL_BATCH_SIZE
    };
  }

  componentDidMount() {
    this.refreshInvoices();
  }

  componentWillUnmount() {
    if (this.unsubscribeInvoices) {
      this.unsubscribeInvoices();
      this.unsubscribeInvoices = undefined;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { bar } = this.props;
    const { invoiceMaxCount } = this.state;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;

    const prevInvoiceMaxCount = prevState.invoiceMaxCount;

    if (barId !== prevBarId || invoiceMaxCount !== prevInvoiceMaxCount) {
      this.refreshInvoices();
    }
  }

  refreshInvoices() {
    const { bar } = this.props;
    const { invoiceMaxCount } = this.state;

    if (this.unsubscribeInvoices) {
      this.unsubscribeInvoices();
      this.unsubscribeInvoices = undefined;
    }

    if (bar && bar.id) {
      this.setState({ isDisabled: true });

      this.unsubscribeInvoices = invoiceService.onBarInvoices(
        bar.id,
        invoiceMaxCount,
        (invoices) => {
          this.setState({ isDisabled: false, invoices });
        }
      );
    }
  }

  handleFetchMoreInvoices = () => {
    const { invoiceMaxCount } = this.state;

    this.setState({
      invoiceMaxCount: invoiceMaxCount + INVOICE_INITIAL_BATCH_SIZE
    });
  };

  getInvoiceDescription = (invoice) => {
    const { t } = this.props;

    const useStats =
      invoice.stats.itemCount ||
      invoice.stats.voucherCount ||
      invoice.stats.feeCount;

    if (useStats) {
      const itemCountAsText = `${invoice.stats.itemCount} ${t(
        invoice.stats.itemCount === 1
          ? "label.consumption"
          : "label.consumptions"
      )}`;
      const voucherCountAsText = `${invoice.stats.voucherCount} ${t(
        invoice.stats.voucherCount === 1
          ? "voucher.voucher"
          : "voucher.vouchers"
      )}`;
      const feeCountAsText = `${invoice.stats.feeCount} ${t(
        invoice.stats.feeCount === 1 ? "fee.fee" : "fee.fees"
      )}`;

      return `${itemCountAsText}, ${voucherCountAsText}, ${feeCountAsText}`.toLowerCase();
    }

    return `${invoice.creditAmount} ${t(
      invoice.creditAmount === 1 ? "label.consumption" : "label.consumptions"
    )}`;
  };

  render() {
    const { classes, t, bar } = this.props;
    const { isDisabled, invoiceMaxCount, invoices } = this.state;

    return (
      <List>
        {invoices ? (
          <div>
            {invoices.length > 0 ? (
              invoices.map((invoice) => (
                <ListItem key={invoice.id}>
                  <ListItemText
                    primary={
                      <span>
                        {t("invoice.service-fee-for")}{" "}
                        <strong>{this.getInvoiceDescription(invoice)}</strong>
                      </span>
                    }
                    secondary={
                      <ReactMoment format="D MMMM YYYY">
                        {invoice.timestamp}
                      </ReactMoment>
                    }
                  />
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemText primary={t("invoice.no-invoices-found")} />
              </ListItem>
            )}
          </div>
        ) : (
          <ListItem className={classes.spinner}>
            <CircularProgress />
          </ListItem>
        )}
        {invoices && invoiceMaxCount <= invoiceMaxCount.length && (
          <ListItem
            button
            disabled={isDisabled}
            onClick={this.handleFetchMoreInvoices}
          >
            <ListItemIcon>
              <RestoreIcon />
            </ListItemIcon>
            <ListItemText primary={t("label.load-more-items")} />
          </ListItem>
        )}
      </List>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(Invoices));
