import React, { PureComponent } from "react";

import withStyles from "@mui/styles/withStyles";

import EditBusiness from "../settings/EditBusiness";

const styles = (theme) => ({
  spinnerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 50
  },
  spinner: {
    margin: 20
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  list: {
    width: 400
  },
  spacing: {
    width: 10
  },
  checkIcon: {
    marginLeft: 20,
    color: "green"
  }
});

class EditBusinessStep extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    this.updateBusinessData = () => {
      console.warn("Business data update function not set");
    };
  }

  componentDidMount() {
    const { setOnNext } = this.props;
    setOnNext(this.updateBusinessData);
  }

  render() {
    const { bar, balance } = this.props;

    return (
      <EditBusiness
        bar={bar}
        balance={balance}
        setOnUpdate={(onUpdate) => (this.updateBusinessData = onUpdate)}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(EditBusinessStep);
