export abstract class Stat<Data extends Record<string, unknown>> {
  readonly data: Data[];

  constructor(data: Data[]) {
    this.data = data;
  }

  formatInteger(value: number) {
    return new Intl.NumberFormat("nl-BE").format(value);
  }
  formatFloat(value: number) {
    return new Intl.NumberFormat("nl-BE", {
      minimumSignificantDigits: 2,
      maximumSignificantDigits: 2
    }).format(value);
  }
  formatCurrency(value: number, data: any) {
    return new Intl.NumberFormat("nl-BE", {
      style: "currency",
      currency: data.currency || "EUR"
    }).format(value / 100);
  }
  formatHour(reference: Date) {
    const offset = reference.getTimezoneOffset() / 60;

    return (hour: number) =>
      new Intl.NumberFormat("nl-BE", {
        style: "unit",
        unit: "hour",
        unitDisplay: "narrow"
      }).format((hour - offset) % 24);
  }

  protected generateCSV(
    settings: {
      [K in keyof Data]: ((value: Data[K], data: Data) => string) | boolean;
    },
    t: any
  ) {
    const keys = Object.keys(settings).filter((key) => settings[key] !== false);
    const header = keys.map((key) => t(`stats.keys.${key}`)).join(";");
    const rows = this.data.map((data) => {
      return keys
        .map((key) => {
          const value = data[key] as Data[keyof Data];
          const handler = (
            settings[key] !== true ? settings[key] : JSON.stringify
          ) as (value: Data[keyof Data], data: Data) => string;
          if (value === null) return "";
          if (value === "N/A") return "N/A";
          if (value === "unknown") return "UNKNOWN";
          return handler(value, data);
        })
        .join(";");
    });

    return [header, ...rows].join("\r\n");
  }
}

export type DataOf<S extends Stat<any>> = S extends Stat<infer Data>
  ? Data
  : never;
