import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import PrintIcon from "@mui/icons-material/Print";
import withStyles from "@mui/styles/withStyles";

import PrinterDrawer from "../printer/PrinterDrawer";
import BarMenuItem from "./BarMenuItem";

const styles = (theme) => ({});

class PrinterMenuItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isPrinterDrawerOpen: false
    };
  }

  handleOpenPrinterDrawer = () => {
    const { onClicked } = this.props;

    this.setState({ isPrinterDrawerOpen: true });

    if (onClicked) {
      onClicked();
    }
  };

  handlePrinterDrawerClose = () => {
    const { onClose } = this.props;

    this.setState({ isPrinterDrawerOpen: false });

    if (onClose) {
      onClose();
    }
  };

  render() {
    const { t, bar, base, assets, deviceSettings } = this.props;
    const { isPrinterDrawerOpen } = this.state;

    return (
      <>
        <PrinterDrawer
          isOpen={isPrinterDrawerOpen}
          onClose={this.handlePrinterDrawerClose}
          bar={bar}
          base={base}
          assets={assets}
          deviceSettings={deviceSettings}
        />
        <BarMenuItem
          onClick={this.handleOpenPrinterDrawer}
          icon={<PrintIcon />}
          label={t("label.printers")}
        />
      </>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(PrinterMenuItem));
