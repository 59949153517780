import {
  callAsyncNativeMethod,
  hasNativeMethod,
  subscribeToNativeMethod
} from "./index";

const START_QR_CODE_SCANNER_HANDLER_METHOD_NAME = "startQRCodeScannerHandler";
const STOP_QR_CODE_SCANNER_HANDLER_METHOD_NAME = "stopQRCodeScannerHandler";
const SHOW_QR_CODE_SCANNER_HANDLER_METHOD_NAME = "showQRCodeScannerHandler";
const HIDE_QR_CODE_SCANNER_HANDLER_METHOD_NAME = "hideQRCodeScannerHandler";

export const isScannerAvailable = () => {
  return (
    hasNativeMethod(START_QR_CODE_SCANNER_HANDLER_METHOD_NAME) &&
    hasNativeMethod(STOP_QR_CODE_SCANNER_HANDLER_METHOD_NAME) &&
    hasNativeMethod(SHOW_QR_CODE_SCANNER_HANDLER_METHOD_NAME) &&
    hasNativeMethod(HIDE_QR_CODE_SCANNER_HANDLER_METHOD_NAME)
  );
};

export const onQRCodeScanned = (cb) => {
  if (isScannerAvailable()) {
    return subscribeToNativeMethod(
      START_QR_CODE_SCANNER_HANDLER_METHOD_NAME,
      STOP_QR_CODE_SCANNER_HANDLER_METHOD_NAME,
      undefined,
      cb
    );
  }

  return () => {};
};

export const showQRCodeScanner = () => {
  if (isScannerAvailable()) {
    return callAsyncNativeMethod(SHOW_QR_CODE_SCANNER_HANDLER_METHOD_NAME);
  }
};

export const hideQRCodeScanner = () => {
  if (isScannerAvailable()) {
    return callAsyncNativeMethod(HIDE_QR_CODE_SCANNER_HANDLER_METHOD_NAME);
  }
};
