import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  TextField
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import withStyles from "@mui/styles/withStyles";

import { barService } from "tap-io/client/services";
import SelectAmount from "tap-io/client/components/common/SelectAmount";
import ScanQRCodeDialog from "tap-io/client/components/common/ScanQRCodeDialog";
import ParamName from "tap-io/models/bar/ParamName";
import PaymentCurrency from "tap-io/models/payment/PaymentCurrency";
import EnumSelector from "tap-io/client/components/common/EnumSelector";

import { hasNativeWrapper } from "../../native";
import { PARAM_DESCRIPTIONS, PARAM_LABELS } from "../../constants/params";
import EditCustomerFieldsDialog from "./EditCustomerFieldsDialog";

const styles = (theme) => ({
  payconiqMerchantLink: {
    maxWidth: 300,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whitespace: "nowrap"
  },
  payconiqMerchantLinkLabel: {
    verticalAlign: "middle"
  },
  payconiqMerchantLinkIcon: {
    verticalAlign: "middle",
    marginLeft: 10,
    fontSize: 18
  }
});

class EditParams extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (bar) => {
    const barParams = bar && bar.params;

    return {
      isEditCustomerFieldsDialogOpen: false,
      isScanQRCodeDialogOpen: false,
      params: {
        barDescription:
          barParams && barParams.barDescription !== undefined
            ? barParams.barDescription
            : "",
        orderConfirmationMessage:
          barParams && barParams.orderConfirmationMessage !== undefined
            ? barParams.orderConfirmationMessage
            : "",
        customerAddressedAs:
          barParams && barParams.customerAddressedAs !== undefined
            ? barParams.customerAddressedAs
            : "",
        customerFields:
          barParams && barParams.customCustomerFields
            ? barParams.customCustomerFields
            : [],
        replyToEmailAddress:
          barParams && barParams.replyToEmailAddress !== undefined
            ? barParams.replyToEmailAddress
            : "",
        orderCurrency:
          barParams && barParams.orderCurrency !== undefined
            ? barParams.orderCurrency
            : "",
        maxAmountOfItemsPerOrder:
          barParams && barParams.maxAmountOfItemsPerOrder
            ? barParams.maxAmountOfItemsPerOrder
            : 20,
        allowNotes: barParams && barParams.allowNotes ? true : false,
        allowTips: barParams && barParams.allowTips ? true : false,
        payconiqMerchantLink:
          barParams && barParams.payconiqMerchantLink !== undefined
            ? barParams.payconiqMerchantLink
            : ""
      }
    };
  };

  componentDidMount() {
    const { bar } = this.props;

    this.setState(this.initialState(bar));
  }

  componentDidUpdate(prevProps, prevState) {
    const { bar } = this.props;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;

    // Reply-to e-mailaddress can be updated in EditReplyToEmailAddressDialog as well
    const replyToEmailAddress =
      bar && bar.params ? bar.params.replyToEmailAddress : undefined;
    const prevReplyToEmailAddress =
      prevProps.bar && prevProps.bar.params
        ? prevProps.bar.params.replyToEmailAddress
        : undefined;

    if (
      barId !== prevBarId ||
      replyToEmailAddress !== prevReplyToEmailAddress
    ) {
      this.setState(this.initialState(bar));
    }
  }

  handleEditCustomerFields = () => {
    this.setState({ isEditCustomerFieldsDialogOpen: true });
  };

  handleEditCustomerFieldsDialogClose = (data) => {
    if (data) {
      const { params } = this.state;

      this.setState({
        isEditCustomerFieldsDialogOpen: false,
        params: {
          ...params,
          customerFields: data.customerFields ? data.customerFields : [],
          replyToEmailAddress: data.replyToEmailAddress
            ? data.replyToEmailAddress
            : ""
        }
      });

      // Automatically save
      this.requestSave();
    } else {
      this.setState({
        isEditCustomerFieldsDialogOpen: false
      });
    }
  };

  handleTextChange = (param) => (event) => {
    const { params } = this.state;

    const val = event.target.value;

    this.setState({ params: { ...params, [param]: val } });
  };

  handleEnumChange = (param) => (event) => {
    const { params } = this.state;

    const val = event.target.value;

    this.setState({ params: { ...params, [param]: val } }, this.requestSave);
  };

  handleAmountChange = (param) => (amount) => {
    const { params } = this.state;

    this.setState({ params: { ...params, [param]: amount } }, this.requestSave);
  };

  handleToggle = (param) => (event) => {
    const { params } = this.state;

    const val = event.target.checked === true;

    this.setState({ params: { ...params, [param]: val } }, this.requestSave);
  };

  handleScanPayconiqMerchantLink = () => {
    this.setState({ isScanQRCodeDialogOpen: true });
  };

  handleQRCodeScanned = (data) => {
    const { params } = this.state;

    this.setState(
      {
        isScanQRCodeDialogOpen: false,
        params: { ...params, payconiqMerchantLink: data ? data : null }
      },
      this.requestSave
    );
  };

  handleScanQRCodeDialogClose = () => {
    this.setState({ isScanQRCodeDialogOpen: false });
  };

  handleRemovePayconiqMerchantLink = () => {
    const { params } = this.state;

    this.setState(
      { params: { ...params, payconiqMerchantLink: null } },
      this.requestSave
    );
  };

  requestSave = () => {
    if (this.saveTimeout) {
      clearTimeout(this.saveTimeout);
    }

    this.saveTimeout = setTimeout(() => {
      this.saveTimeout = undefined;
      this.handleSave();
    }, 1000);
  };

  handleSave = async () => {
    const { t, bar } = this.props;
    const { params } = this.state;
    const { customerFields } = params;

    const toastId = toast(t("settings.saving-settings"), { autoClose: false });

    try {
      const barParams = barService.createBarParams(bar, {
        ...params,
        customerFields: customerFields.map((customerField) =>
          customerField.toJSON()
        )
      });
      await barService.updateBarParams(bar, barParams);

      toast.update(toastId, {
        render: t("settings.settings-saved"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, bar, areNotesAllowed } = this.props;
    const { isEditCustomerFieldsDialogOpen, isScanQRCodeDialogOpen, params } =
      this.state;

    const {
      barDescription,
      orderConfirmationMessage,
      customerAddressedAs,
      customerFields,
      replyToEmailAddress,
      orderCurrency,
      maxAmountOfItemsPerOrder,
      allowNotes,
      allowTips,
      payconiqMerchantLink
    } = params;

    const isBarUsingBases = bar.isUsingBases();
    const isBarUsingZones = bar.isUsingZones();
    const isBarAllowingOnlinePayments = bar.isAllowingOnlinePayments();

    return (
      <div>
        <EditCustomerFieldsDialog
          isOpen={isEditCustomerFieldsDialogOpen}
          onCancel={this.handleEditCustomerFieldsDialogClose}
          onSave={this.handleEditCustomerFieldsDialogClose}
          bar={bar}
          customerFields={customerFields}
          replyToEmailAddress={replyToEmailAddress}
        />
        <ScanQRCodeDialog
          isOpen={isScanQRCodeDialogOpen}
          onClose={this.handleScanQRCodeDialogClose}
          onScan={this.handleQRCodeScanned}
        />
        <List>
          <ListItem>
            <TextField
              variant="standard"
              margin="dense"
              label={t(PARAM_LABELS[ParamName.BAR_DESCRIPTION])}
              helperText={t(PARAM_DESCRIPTIONS[ParamName.BAR_DESCRIPTION])}
              multiline
              rows="4"
              fullWidth
              value={barDescription}
              onChange={this.handleTextChange(ParamName.BAR_DESCRIPTION)}
              onBlur={this.requestSave}
            />
          </ListItem>
          <ListItem>
            <TextField
              variant="standard"
              margin="dense"
              label={t(PARAM_LABELS[ParamName.ORDER_CONFIRMATION_MESSAGE])}
              helperText={t(
                PARAM_DESCRIPTIONS[ParamName.ORDER_CONFIRMATION_MESSAGE]
              )}
              multiline
              rows="2"
              fullWidth
              value={orderConfirmationMessage}
              onChange={this.handleTextChange(
                ParamName.ORDER_CONFIRMATION_MESSAGE
              )}
              onBlur={this.requestSave}
            />
          </ListItem>
          <ListItem>
            <TextField
              variant="standard"
              margin="dense"
              label={t(PARAM_LABELS[ParamName.CUSTOMER_ADDRESSED_AS])}
              helperText={
                isBarUsingZones
                  ? t(
                    "settings.customer-addressed-as-not-editable-when-zones-are-enabled"
                  )
                  : t(PARAM_DESCRIPTIONS[ParamName.CUSTOMER_ADDRESSED_AS])
              }
              disabled={isBarUsingZones}
              fullWidth
              value={customerAddressedAs}
              onChange={this.handleTextChange(ParamName.CUSTOMER_ADDRESSED_AS)}
              onBlur={this.requestSave}
            />
          </ListItem>
          <ListItem button onClick={this.handleEditCustomerFields}>
            <ListItemIcon>
              <PlaylistAddIcon />
            </ListItemIcon>
            <ListItemText
              primary={`${t(PARAM_LABELS[ParamName.CUSTOMER_FIELDS])}${bar && bar.hasCustomerFields() > 0
                ? ` (${bar.getCustomerFields().length})`
                : ""
                }`}
            />
          </ListItem>
          <ListItem>
            {isBarAllowingOnlinePayments ?
              <EnumSelector
                variant="standard"
                margin="dense"
                label={t(PARAM_LABELS[ParamName.ORDER_CURRENCY])}
                helperText={t(
                  "settings.contact-support-to-change-order-currency-when-online-payments-are-enabled"
                )}
                fullWidth
                disabled
                value={orderCurrency}
                onChange={this.handleEnumChange(ParamName.ORDER_CURRENCY)}
                enumeration={PaymentCurrency}
                renderLabel={(currency) => t(`payment.currency-${currency.toLowerCase()}`)}
              /> :
              <TextField
                variant="standard"
                margin="dense"
                label={t(PARAM_LABELS[ParamName.ORDER_CURRENCY])}
                helperText={t(PARAM_DESCRIPTIONS[ParamName.ORDER_CURRENCY])}
                fullWidth
                value={orderCurrency}
                onChange={this.handleTextChange(ParamName.ORDER_CURRENCY)}
                onBlur={this.requestSave}
              />
            }
          </ListItem>
          <ListItem>
            <ListItemText
              primary={t(PARAM_LABELS[ParamName.MAX_AMOUNT_OF_ITEMS_PER_ORDER])}
            />
            <SelectAmount
              min={1}
              max={50}
              amount={maxAmountOfItemsPerOrder}
              onChange={this.handleAmountChange(
                ParamName.MAX_AMOUNT_OF_ITEMS_PER_ORDER
              )}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={t(PARAM_LABELS[ParamName.ALLOW_NOTES])}
              secondary={
                areNotesAllowed
                  ? t(PARAM_DESCRIPTIONS[ParamName.ALLOW_NOTES])
                  : t("settings.allow-notes-not-editable")
              }
            />
            <Switch
              onChange={this.handleToggle(ParamName.ALLOW_NOTES)}
              checked={allowNotes}
              disabled={!areNotesAllowed}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={t(PARAM_LABELS[ParamName.ALLOW_TIPS])}
              secondary={
                isBarUsingBases
                  ? t("settings.allow-tips-not-editable-when-bases-are-enabled")
                  : t(PARAM_DESCRIPTIONS[ParamName.ALLOW_TIPS])
              }
            />
            <Switch
              onChange={this.handleToggle(ParamName.ALLOW_TIPS)}
              checked={allowTips}
              disabled={isBarUsingBases}
            />
          </ListItem>
          {!hasNativeWrapper() && !isBarAllowingOnlinePayments && (
            <ListItem>
              <ListItemText
                primary={
                  <span>
                    <span className={classes.payconiqMerchantLinkLabel}>
                      {t(PARAM_LABELS[ParamName.PAYCONIQ_MERCHANT_LINK])}
                    </span>
                    {payconiqMerchantLink && (
                      <CheckCircleIcon
                        className={classes.payconiqMerchantLinkIcon}
                      />
                    )}
                  </span>
                }
                secondary={t(
                  PARAM_DESCRIPTIONS[ParamName.PAYCONIQ_MERCHANT_LINK]
                )}
                classes={{
                  secondary: classes.payconiqMerchantLink
                }}
              />
              {payconiqMerchantLink ? (
                <IconButton
                  color="default"
                  className={classes.button}
                  onClick={this.handleRemovePayconiqMerchantLink}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              ) : (
                <IconButton
                  color="default"
                  className={classes.button}
                  onClick={this.handleScanPayconiqMerchantLink}
                  size="large"
                >
                  <PhotoCameraIcon />
                </IconButton>
              )}
            </ListItem>
          )}
        </List>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditParams)
);
