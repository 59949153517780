import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { belgium, checkVAT } from "jsvat";

import {
  Button,
  CircularProgress,
  FormHelperText,
  List,
  ListItem,
  ListSubheader,
  TextField,
  Typography
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";
import { utilsHelper } from "tap-io/helpers";
import { barService, dataService } from "tap-io/client/services";

import withAuthorization from "../auth/withAuthorization";

const styles = (theme) => ({
  spinnerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 50
  },
  spinner: {
    margin: 20
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  list: {
    width: "100%"
  },
  spacing: {
    width: 10
  },
  checkIcon: {
    marginLeft: 20,
    color: "green"
  },
  buttonsList: {
    width: "100%"
  },
  buttons: {
    justifyContent: "flex-end"
  }
});

class EditBusiness extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (businessData) => {
    return {
      isLoading: false,
      isDisabled: false,
      showErrors: false,
      vatNumberBeforeEditing: null,
      isValidVATNumber:
        businessData && businessData.vatNumber
          ? this.parseVATNumber(businessData && businessData.vatNumber) !==
            undefined
          : false,
      name: businessData && businessData.name ? businessData.name : "",
      vatNumber:
        businessData && businessData.vatNumber ? businessData.vatNumber : "",
      address: {
        streetName:
          businessData && businessData.addressStreetName
            ? businessData.addressStreetName
            : "",
        houseNumber:
          businessData && businessData.addressHouseNumber
            ? businessData.addressHouseNumber
            : "",
        boxName:
          businessData && businessData.addressBoxName
            ? businessData.addressBoxName
            : "",
        zipCode:
          businessData && businessData.addressZipCode
            ? businessData.addressZipCode
            : "",
        cityName:
          businessData && businessData.addressCityName
            ? businessData.addressCityName
            : ""
      },
      contact: {
        firstName:
          businessData && businessData.contactFirstName
            ? businessData.contactFirstName
            : "",
        lastName:
          businessData && businessData.contactLastName
            ? businessData.contactLastName
            : "",
        emailAddress:
          businessData && businessData.contactEmailAddress
            ? businessData.contactEmailAddress
            : ""
      }
    };
  };

  componentDidMount() {
    const { setOnUpdate } = this.props;

    if (setOnUpdate) {
      setOnUpdate(this.updateBusiness);
    }

    this.refreshBusinessData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { bar } = this.props;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;

    if (barId !== prevBarId) {
      this.refreshBusinessData();
    }
  }

  componentWillUnmount() {
    if (this.checkVATNumberTimeout) {
      clearTimeout(this.checkVATNumberTimeout);
      this.checkVATNumberTimeout = undefined;
    }
  }

  refreshBusinessData = async () => {
    const { auth, bar } = this.props;

    if (bar) {
      this.setState({ isLoading: true });

      try {
        this.businessData = await dataService.getBusinessData(bar.id);
        const newState = this.initialState(this.businessData);

        // Prefill contact info if not present (and available)
        if (
          !this.businessData.contactFirstName &&
          !this.businessData.contactLastName &&
          !this.businessData.contactEmailAddress
        ) {
          const administrationData = await dataService.getAdministrationData(
            bar.id
          );
          const emailAddress =
            auth && auth.user && auth.user.email ? auth.user.email : "";

          newState.contact.firstName = administrationData.contactFirstName;
          newState.contact.lastName = administrationData.contactLastName;
          newState.contact.emailAddress = emailAddress;
        }

        this.setState(newState);
      } catch (error) {
        console.warn(error);

        this.businessData = null;
        this.setState(this.initialState());
      }
    } else {
      this.businessData = null;
      this.setState(this.initialState());
    }
  };

  isVatNumberRequired = () => {
    const { balance } = this.props;

    return balance && balance.allowCreditDebt;
  };

  hasConfChanged = () => {
    const { name, vatNumber, address, contact } = this.state;

    return (
      this.businessData &&
      (name !== this.businessData.name ||
        vatNumber !== this.businessData.vatNumber ||
        !utilsHelper.areObjectsEqualShallow(
          this.businessData.address,
          address
        ) ||
        !utilsHelper.areObjectsEqualShallow(this.businessData.contact, contact))
    );
  };

  handleVATNumberChange = (event) => {
    if (this.checkVATNumberTimeout) {
      clearTimeout(this.checkVATNumberTimeout);
      this.checkVATNumberTimeout = undefined;
    }

    const vatNumber = event.target.value;

    const parsedVATNumber = this.parseVATNumber(vatNumber);
    const isValidVATNumber = parsedVATNumber !== undefined;

    this.setState(
      {
        isValidVATNumber,
        vatNumber: parsedVATNumber ? parsedVATNumber : vatNumber
      },
      () => {
        if (isValidVATNumber) {
          this.checkVATNumberTimeout = setTimeout(this.checkVATNumber, 2000);
        }
      }
    );
  };

  handleVATNumberFocus = (event) => {
    const { vatNumber } = this.state;

    this.setState({ vatNumberBeforeEditing: vatNumber });
  };

  handleVATNumberBlur = (event) => {
    if (this.checkVATNumberTimeout) {
      clearTimeout(this.checkVATNumberTimeout);
      this.checkVATNumberTimeout = undefined;
    }

    const { isValidVATNumber, vatNumberBeforeEditing, vatNumber } = this.state;

    if (isValidVATNumber && vatNumber !== vatNumberBeforeEditing) {
      this.checkVATNumber();
    }

    this.setState({ vatNumberBeforeEditing: null });
  };

  checkVATNumber = async () => {
    const { t, auth } = this.props;
    const { vatNumber } = this.state;

    const toastId = toast(t("settings.business-fetching-info"), {
      autoClose: false
    });
    this.setState({ isDisabled: true });

    try {
      const validatedInfo = await barService.validateVATNumber(
        config.functions.api,
        auth.user,
        vatNumber
      );

      if (validatedInfo && validatedInfo.isValid) {
        this.setState({
          name: validatedInfo.companyName ? validatedInfo.companyName : "",
          address: {
            streetName:
              validatedInfo.companyAddress &&
              validatedInfo.companyAddress.streetName
                ? validatedInfo.companyAddress.streetName
                : "",
            houseNumber:
              validatedInfo.companyAddress &&
              validatedInfo.companyAddress.houseNumber
                ? validatedInfo.companyAddress.houseNumber
                : "",
            boxName:
              validatedInfo.companyAddress &&
              validatedInfo.companyAddress.boxName
                ? validatedInfo.companyAddress.boxName
                : "",
            zipCode:
              validatedInfo.companyAddress &&
              validatedInfo.companyAddress.zipCode
                ? validatedInfo.companyAddress.zipCode
                : "",
            cityName:
              validatedInfo.companyAddress &&
              validatedInfo.companyAddress.cityName
                ? validatedInfo.companyAddress.cityName
                : ""
          }
        });

        toast.dismiss(toastId);
      } else {
        toast.update(toastId, {
          render: t("settings.business-no-info-found"),
          type: toast.TYPE.WARNING,
          autoClose: 5000
        });
      }

      this.setState({ isDisabled: false });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  parseVATNumber = (vatNumber) => {
    const result = checkVAT(vatNumber, [belgium]);

    if (result && result.isValid) {
      return result.value;
    }
  };

  handleNameChange = (event) => {
    const val = event.target.value;

    this.setState({ name: val });
  };

  handleAddressChange = (addressField) => (event) => {
    const val = event.target.value;

    this.setState({ address: { ...this.state.address, [addressField]: val } });
  };

  handleContactChange = (contactField) => (event) => {
    const val = event.target.value;

    this.setState({ contact: { ...this.state.contact, [contactField]: val } });
  };

  updateBusiness = async () => {
    const { bar } = this.props;
    const { isValidVATNumber, name, vatNumber, address, contact } = this.state;

    if (!this.businessData) {
      throw new Error("label.unknown-error");
    }

    if (!isValidVATNumber && (vatNumber || this.isVatNumberRequired())) {
      this.setState({ showErrors: true });
      throw new Error("error.vat-number-is-invalid");
    }

    const emailAddress = utilsHelper.parseEmail(contact.emailAddress);

    this.setState({ isDisabled: true });

    try {
      this.businessData.name = name;
      if (vatNumber) {
        this.businessData.vatNumber = vatNumber;
      }
      this.businessData.addressStreetName = address.streetName;
      this.businessData.addressHouseNumber = address.houseNumber;
      this.businessData.addressBoxName = address.boxName;
      this.businessData.addressZipCode = address.zipCode;
      this.businessData.addressCityName = address.cityName;
      this.businessData.contactFirstName = contact.firstName;
      this.businessData.contactLastName = contact.lastName;
      this.businessData.contactEmailAddress = emailAddress;

      await dataService.setBusinessData(this.businessData);

      this.setState({ isDisabled: false });
    } catch (error) {
      console.warn(error);
      this.setState({ isDisabled: false });

      if (error && error.message === "error.string-cannot-be-empty") {
        this.setState({ showErrors: true });
        throw new Error("error.not-all-required-fields-are-completed");
      } else {
        throw error;
      }
    }
  };

  handleUpdateBusiness = async () => {
    const { t } = this.props;

    const toastId = toast(t("settings.updating-business"), {
      autoClose: false
    });
    try {
      await this.updateBusiness();

      toast.update(toastId, {
        render: t("settings.business-updated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(error.message)})`,
        type: toast.TYPE.WARNING,
        autoClose: 5000
      });
    }
  };

  handleRestoreBusiness = () => {
    this.refreshBusinessData();
  };

  render() {
    const { classes, t, setOnUpdate, balance, align } = this.props;
    const {
      isLoading,
      isDisabled,
      showErrors,
      isValidVATNumber,
      name,
      vatNumber,
      address,
      contact
    } = this.state;

    const isVatNumberRequired = this.isVatNumberRequired();

    return (
      <div>
        {isLoading ? (
          <div className={classes.spinnerContainer}>
            <Typography>{t("label.fetching-data")}</Typography>
            <CircularProgress className={classes.spinner} />
          </div>
        ) : (
          <div
            className={classes.contentContainer}
            style={{ justifyContent: align ? align : "center" }}
          >
            <List className={classes.list}>
              <ListSubheader disableSticky>
                {t("settings.business-details-label")}
              </ListSubheader>
              <ListItem>
                <TextField
                  variant="standard"
                  margin="dense"
                  label={`${t("settings.business-vat-number-label")}${
                    isVatNumberRequired ? "" : ` (${t("label.optional")})`
                  }`}
                  helperText={t("settings.business-vat-number-description")}
                  required={isVatNumberRequired}
                  fullWidth
                  disabled={isDisabled}
                  error={showErrors && vatNumber && !isValidVATNumber}
                  value={vatNumber}
                  onChange={this.handleVATNumberChange}
                  onFocus={this.handleVATNumberFocus}
                  onBlur={this.handleVATNumberBlur}
                />
                {isValidVATNumber && (
                  <CheckIcon className={classes.checkIcon} />
                )}
              </ListItem>
              <ListItem>
                <TextField
                  variant="standard"
                  margin="dense"
                  label={t("settings.business-name-label")}
                  helperText={t("settings.business-name-description")}
                  required
                  fullWidth
                  disabled={isDisabled}
                  error={showErrors && !name}
                  value={name}
                  onChange={this.handleNameChange}
                />
              </ListItem>
              <ListItem>
                <TextField
                  variant="standard"
                  margin="dense"
                  label={t("settings.business-address-street-label")}
                  required
                  fullWidth
                  error={showErrors && !address.streetName}
                  value={address.streetName}
                  disabled={isDisabled}
                  onChange={this.handleAddressChange("streetName")}
                />
                <div className={classes.spacing}></div>
                <TextField
                  variant="standard"
                  margin="dense"
                  label={t("settings.business-address-number-label")}
                  required
                  error={showErrors && !address.houseNumber}
                  value={address.houseNumber}
                  disabled={isDisabled}
                  onChange={this.handleAddressChange("houseNumber")}
                />
                <div className={classes.spacing}></div>
                <TextField
                  variant="standard"
                  margin="dense"
                  label={t("settings.business-address-box-label")}
                  value={address.boxName}
                  disabled={isDisabled}
                  onChange={this.handleAddressChange("boxName")}
                />
              </ListItem>
              <ListItem>
                <TextField
                  variant="standard"
                  margin="dense"
                  label={t("settings.business-address-zip-label")}
                  required
                  error={showErrors && !address.zipCode}
                  value={address.zipCode}
                  disabled={isDisabled}
                  onChange={this.handleAddressChange("zipCode")}
                />
                <div className={classes.spacing}></div>
                <TextField
                  variant="standard"
                  margin="dense"
                  label={t("settings.business-address-city-label")}
                  required
                  fullWidth
                  error={showErrors && !address.cityName}
                  value={address.cityName}
                  disabled={isDisabled}
                  onChange={this.handleAddressChange("cityName")}
                />
              </ListItem>
            </List>
            <List className={classes.list}>
              <ListSubheader disableSticky>
                {t("settings.business-contact-details-label")}
              </ListSubheader>
              <ListItem>
                <TextField
                  variant="standard"
                  margin="dense"
                  label={t("settings.business-contact-details-first-name")}
                  required
                  error={showErrors && !contact.firstName}
                  value={contact.firstName}
                  disabled={isDisabled}
                  onChange={this.handleContactChange("firstName")}
                />
                <div className={classes.spacing}></div>
                <TextField
                  variant="standard"
                  margin="dense"
                  label={t("settings.business-contact-details-last-name")}
                  required
                  fullWidth
                  error={showErrors && !contact.lastName}
                  value={contact.lastName}
                  disabled={isDisabled}
                  onChange={this.handleContactChange("lastName")}
                />
              </ListItem>
              <ListItem>
                <TextField
                  variant="standard"
                  margin="dense"
                  label={t("settings.business-contact-details-email-address")}
                  required
                  fullWidth
                  error={showErrors && !contact.emailAddress}
                  value={contact.emailAddress}
                  disabled={isDisabled}
                  onChange={this.handleContactChange("emailAddress")}
                />
              </ListItem>
              <ListItem>
                <FormHelperText>
                  {`${t("settings.business-contact-details-description-1")} `}
                  <a
                    href={config.links.privacyPolicy}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("settings.business-contact-details-description-2")}
                  </a>
                  .
                </FormHelperText>
              </ListItem>
            </List>
            {!setOnUpdate && (
              <List className={classes.buttonsList}>
                <ListItem className={classes.buttons}>
                  {this.hasConfChanged() && (
                    <Button
                      color="secondary"
                      className={classes.button}
                      disabled={isDisabled}
                      onClick={this.handleRestoreBusiness}
                    >
                      {t("label.restore")}
                    </Button>
                  )}
                  <Button
                    color="primary"
                    className={classes.button}
                    disabled={isDisabled}
                    onClick={this.handleUpdateBusiness}
                  >
                    {t("label.save")}
                  </Button>
                </ListItem>
              </List>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withAuthorization()(
  withStyles(styles, { withTheme: true })(
    withTranslation("common")(EditBusiness)
  )
);
