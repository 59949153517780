import { Stat } from "./Stat";

type Data = {
  hour: number;
  valueExclVat: number;
  vat: number;
  valueInclVat: number;
  currency: string;
};

export class FinancialTimeslotReport extends Stat<Data> {
  get totalValueExclVat() {
    return this.data.reduce((total, report) => total + report.valueExclVat, 0);
  }
  get totalVat() {
    return this.data.reduce((total, report) => total + report.vat, 0);
  }
  get totalValueInclVat() {
    return this.data.reduce((total, report) => total + report.valueInclVat, 0);
  }

  get activeHours() {
    const activeHoursStart =
      this.data.findIndex((p) => p.valueInclVat !== 0) - 2;
    const activeHoursEnd =
      this.data.length -
      1 -
      this.data.findIndex(
        (_, idx) => this.data[this.data.length - 1 - idx].valueInclVat !== 0
      ) +
      2;
    const activeHours =
      activeHoursStart >= 0
        ? activeHoursStart === -1
          ? []
          : this.data.filter(
              (_, idx) => activeHoursStart < idx && idx < activeHoursEnd
            )
        : [];
    return activeHours;
  }

  toCSV(reference: Date, t: any) {
    return this.generateCSV(
      {
        hour: this.formatHour(reference),
        valueExclVat: this.formatCurrency,
        vat: this.formatCurrency,
        valueInclVat: this.formatCurrency,
        currency: true
      },
      t
    );
  }
}
