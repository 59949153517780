import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Redirect } from "react-router-dom";

import { toast } from "react-toastify";

import { Button, Step, StepLabel, Stepper, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { utilsHelper } from "tap-io/helpers";

import { hasNativeWrapper } from "../../native";
import * as routes from "../../constants/routes";
import SetupStep from "./SetupStep";
import CreateBarStep from "./CreateBarStep";
import EditBusinessStep from "./EditBusinessStep";
import SelectPlanStep from "./SelectPlanStep";
import EditBasesStep from "./EditBasesStep";
import EditMenusStep from "./EditMenusStep";
import EditZonesStep from "./EditZonesStep";
import EditScannersStep from "./EditScannersStep";
import EditLookAndFeelStep from "./EditLookAndFeelStep";
import DownloadPromoStep from "./DownloadPromoStep";
import {
  SETUP_LABEL_CONFIGURATION,
  SETUP_LABEL_CREDITS,
  SETUP_LABEL_GENERAL_INFORMATION,
  SETUP_LABEL_ORDER_PAGE,
  SETUP_LABEL_PAYMENTS
} from "../../constants/setup";
import EditPaymentProvidersStep from "./EditPaymentProvidersStep";
import TopUpCreditsStep from "./TopUpCreditsStep";
import WelcomeStep from "./WelcomeStep";

const STEP_CREATE_BAR = {
  label: SETUP_LABEL_GENERAL_INFORMATION,
  component: CreateBarStep,
  title: "setup.step-create-bar-title",
  description: "setup.step-create-bar-description",
  isPreviousDisabled: true
};

const STEP_EDIT_BUSINESS = {
  label: SETUP_LABEL_GENERAL_INFORMATION,
  component: EditBusinessStep,
  title: "setup.step-edit-business-title",
  description: "setup.step-edit-business-description",
  isPreviousDisabled: true
};

const STEP_SELECT_PLAN = {
  label: SETUP_LABEL_GENERAL_INFORMATION,
  component: SelectPlanStep,
  title: "setup.step-select-plan-title",
  description: "setup.step-select-plan-description"
};

const STEP_EDIT_BASES = {
  label: SETUP_LABEL_CONFIGURATION,
  component: EditBasesStep,
  title: "setup.step-edit-bases-title",
  description: "setup.step-edit-bases-description"
};

const STEP_EDIT_MENUS = {
  label: SETUP_LABEL_CONFIGURATION,
  component: EditMenusStep,
  title: "setup.step-edit-menus-title",
  description: "setup.step-edit-menus-description"
};

const STEP_EDIT_ZONES = {
  label: SETUP_LABEL_CONFIGURATION,
  component: EditZonesStep,
  title: "setup.step-edit-zones-title",
  description: "setup.step-edit-zones-description"
};

const STEP_EDIT_SCANNERS = {
  label: SETUP_LABEL_CONFIGURATION,
  component: EditScannersStep,
  title: "setup.step-edit-scanners-title",
  description: "setup.step-edit-scanners-description"
};

const STEP_DOWNLOAD_PROMO = {
  label: SETUP_LABEL_ORDER_PAGE,
  component: DownloadPromoStep,
  title: "setup.step-download-promo-title",
  description: "setup.step-download-promo-description"
};

const STEP_EDIT_LOOK_AND_FEEL = {
  label: SETUP_LABEL_ORDER_PAGE,
  component: EditLookAndFeelStep,
  title: "setup.step-edit-look-and-feel-title",
  description: "setup.step-edit-look-and-feel-description"
};

const STEP_EDIT_PAYMENT_PROVIDERS = {
  label: SETUP_LABEL_PAYMENTS,
  component: EditPaymentProvidersStep,
  title: "setup.step-edit-payment-providers-title",
  description: "setup.step-edit-payment-providers-description"
};

const STEP_TOP_UP_CREDITS = {
  label: SETUP_LABEL_CREDITS,
  component: TopUpCreditsStep,
  title: "setup.step-top-up-credits-title",
  description: "setup.step-top-up-credits-description"
};

const STEP_WELCOME = {
  label: null,
  component: WelcomeStep,
  image: "/img/logo.png",
  title: "setup.step-welcome-title",
  description: "setup.step-welcome-description"
};

// Setup labels
const SETUP_LABELS = [
  SETUP_LABEL_GENERAL_INFORMATION,
  SETUP_LABEL_CONFIGURATION,
  SETUP_LABEL_ORDER_PAGE,
  SETUP_LABEL_PAYMENTS
];
if (!hasNativeWrapper()) {
  SETUP_LABELS.push(SETUP_LABEL_CREDITS);
}
const OPTIONAL_SETUP_LABELS = [SETUP_LABEL_PAYMENTS, SETUP_LABEL_CREDITS];

const styles = (theme) => ({
  header: {
    zIndex: 100,
    position: "sticky",
    top: 0,
    minHeight: 89, // TO FIX: still needed?
    background: "rgba(250,250,250,.85)",
    webkitBackground: "rgba(250,250,250,.75)",
    webkitBackdropFilter: "saturate(180%) blur(20px)",
    backdropFilter: "saturate(180%) blur(20px)"
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "calc(100vh - 145px)"
    /*[theme.breakpoints.down(800)]: {
      minHeight: "calc(100vh - 145px)"
    },
    [theme.breakpoints.up(800)]: {
      minHeight: "calc(100vh - 165px)"
    }*/
  },
  footer: {
    zIndex: 100,
    position: "sticky",
    bottom: 0,
    padding: 10,
    textAlign: "center",
    background: "rgba(250,250,250,.85)",
    webkitBackground: "rgba(250,250,250,.75)",
    webkitBackdropFilter: "saturate(180%) blur(20px)",
    backdropFilter: "saturate(180%) blur(20px)"
  },
  stepper: {
    padding: theme.spacing(3)
  },
  button: {
    margin: "0 10px"
  }
});

class SetupWizard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false,
      setupSteps: [
        STEP_CREATE_BAR,
        STEP_EDIT_BUSINESS,
        STEP_SELECT_PLAN,
        STEP_EDIT_BASES,
        STEP_EDIT_MENUS,
        STEP_EDIT_ZONES,
        STEP_EDIT_SCANNERS,
        STEP_EDIT_LOOK_AND_FEEL,
        STEP_DOWNLOAD_PROMO,
        STEP_EDIT_PAYMENT_PROVIDERS,
        STEP_TOP_UP_CREDITS,
        STEP_WELCOME
      ],
      activeStepIndex: 0,
      skipStepIndexes: []
    };

    this.stepNextHandlers = [];
  }

  componentDidMount() {
    this.checkIfBarIsSetAndSkipFirstStepIfNeeded();
    this.checkIfBarIsUsingBasesAndSkipStepIfNeeded();
  }

  componentDidUpdate(prevProps, prevState) {
    const { bar } = this.props;
    const prevBar = prevProps.bar;

    this.checkIfBarIsSetAndSkipFirstStepIfNeeded();

    if (bar && !prevBar) {
      this.checkIfBarIsUsingBasesAndSkipStepIfNeeded();
    }
  }

  checkIfBarIsSetAndSkipFirstStepIfNeeded = () => {
    const { bar } = this.props;
    const { activeStepIndex } = this.state;

    if (bar && bar.id && activeStepIndex === 0) {
      this.setState({ activeStepIndex: 1 });
    }
  };

  checkIfBarIsUsingBasesAndSkipStepIfNeeded = () => {
    const { bar } = this.props;
    const { setupSteps } = this.state;

    if (bar && !bar.isUsingBases()) {
      this.setState({
        setupSteps: setupSteps.filter(
          (setupStep) => setupStep.component !== EditBasesStep
        )
      });
    }
  };

  setActiveStepIndex = (newActiveStepIndex) => {
    const { activeStepIndex, skipStepIndexes } = this.state;

    if (skipStepIndexes.indexOf(newActiveStepIndex) >= 0) {
      return this.setActiveStepIndex(
        newActiveStepIndex > activeStepIndex
          ? newActiveStepIndex + 1
          : newActiveStepIndex - 1
      );
    } else {
      this.setState({ activeStepIndex: newActiveStepIndex });
      utilsHelper.resetScrollPosition();
    }
  };

  handleBack = () => {
    const { activeStepIndex } = this.state;

    this.setActiveStepIndex(activeStepIndex - 1);
  };

  handleNext = async () => {
    const { t } = this.props;
    const { activeStepIndex } = this.state;

    if (this.stepNextHandlers[activeStepIndex]) {
      this.setState({ isDisabled: true });
      const toastId = toast(`${t("label.please-wait")}...`, {
        autoClose: false
      });

      try {
        await this.stepNextHandlers[activeStepIndex]();

        this.setState({ isDisabled: false });
        toast.dismiss(toastId);
      } catch (error) {
        console.warn(error);

        this.setState({ isDisabled: false });
        toast.update(toastId, {
          render: `${t("label.something-went-wrong")} (${t(
            error ? error.message : "error.unknown-error"
          )})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });

        // Don't go to the next step
        return;
      }
    }

    this.setActiveStepIndex(activeStepIndex + 1);
  };

  render() {
    const {
      classes,
      t,
      bar,
      balance,
      assets,
      subscription,
      allBases,
      allMenus,
      allZones,
      pricing
    } = this.props;
    const { isDisabled, setupSteps, activeStepIndex } = this.state;

    if (activeStepIndex === setupSteps.length) {
      return <Redirect to={routes.ORDERS} />;
    }

    const activeStep = setupSteps[activeStepIndex];

    return (
      <div>
        <div className={classes.header}>
          {activeStep.label && (
            <Stepper
              className={classes.stepper}
              activeStep={SETUP_LABELS.indexOf(activeStep.label)}
            >
              {SETUP_LABELS.map((label, index) => (
                <Step key={index}>
                  <StepLabel
                    optional={
                      OPTIONAL_SETUP_LABELS.indexOf(label) >= 0 && (
                        <Typography variant="caption">
                          {t("setup.optional")}
                        </Typography>
                      )
                    }
                  >
                    {t(label)}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          )}
        </div>
        <div>
          <div className={classes.content}>
            <SetupStep
              image={activeStep.image}
              title={t(activeStep.title)}
              description={t(activeStep.description)}
            >
              <activeStep.component
                setOnNext={(onNext) =>
                  (this.stepNextHandlers[activeStepIndex] = onNext)
                }
                onNext={this.handleNext}
                bar={bar}
                balance={balance}
                assets={assets}
                subscription={subscription}
                allBases={allBases}
                allMenus={allMenus}
                allZones={allZones}
                pricing={pricing}
              />
            </SetupStep>
          </div>
          <div className={classes.footer}>
            <Button
              data-cy="setup-previous"
              disabled={isDisabled || activeStep.isPreviousDisabled}
              onClick={this.handleBack}
              className={classes.button}
            >
              {t("setup.previous")}
            </Button>
            <Button
              data-cy="setup-next"
              variant="contained"
              color="primary"
              disabled={isDisabled || activeStep.isNextDisabled}
              onClick={this.handleNext}
              className={classes.button}
            >
              {t(
                activeStepIndex === setupSteps.length - 1
                  ? "setup.complete"
                  : "setup.next"
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(SetupWizard));
