import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Switch } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { utilsHelper } from "tap-io/helpers";
import { baseService, menuService } from "tap-io/client/services";
import ConfirmDialog from "tap-io/client/components/common/ConfirmDialog";

import OpenOrders from "./OpenOrders";

const styles = (theme) => ({});

class OpenOrdersPerBase extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      basePendingToggleIsActiveConfirmation: null
    };
  }

  getOpenOrdersForBase = (base) => {
    const { openOrdersPerMenu } = this.props;

    if (openOrdersPerMenu) {
      return base.menuIds.reduce(
        (openOrdersForBase, menuId) =>
          openOrdersPerMenu[menuId]
            ? [...openOrdersForBase, ...openOrdersPerMenu[menuId]]
            : openOrdersForBase,
        []
      );
    }

    return [];
  };

  getOpenOrdersCountPerMinuteForBase = (base) => {
    const { openOrdersCountPerMenuAndMinute } = this.props;

    if (openOrdersCountPerMenuAndMinute) {
      let baseHasOpenOrders = false;
      const openOrdersCountPerMinuteForBase = base.menuIds.reduce(
        (openOrdersPerMinuteForBase, menuId) => {
          if (
            openOrdersCountPerMenuAndMinute[menuId] &&
            openOrdersCountPerMenuAndMinute[menuId].length > 0
          ) {
            openOrdersCountPerMenuAndMinute[menuId].forEach(
              (openOrdersPerMenu, minute) => {
                openOrdersPerMinuteForBase[minute] += openOrdersPerMenu;
              }
            );
            baseHasOpenOrders = true;
          }

          return openOrdersPerMinuteForBase;
        },
        utilsHelper.generateArray(31, 0)
      );

      return baseHasOpenOrders ? openOrdersCountPerMinuteForBase : [];
    }

    return [];
  };

  handleBaseIsActiveClick = (event) => {
    event.stopPropagation();
  };

  handleToggleBaseIsActive = (base) => (event) => {
    event.preventDefault();

    this.setState({ basePendingToggleIsActiveConfirmation: base });
  };

  handleToggleBaseIsActiveConfirm = async () => {
    const { basePendingToggleIsActiveConfirmation } = this.state;

    this.setState({ basePendingToggleIsActiveConfirmation: null });

    await this.toggleBaseIsActive(basePendingToggleIsActiveConfirmation);
  };

  handleToggleBaseIsActiveCancel = () => {
    this.setState({ basePendingToggleIsActiveConfirmation: null });
  };

  toggleBaseIsActive = async (base) => {
    const { t } = this.props;

    const toastId = toast(
      base.isActive ? t("base.closing-base") : t("base.opening-base"),
      {
        autoClose: false
      }
    );

    try {
      base.isActive = !base.isActive;
      await baseService.updateBase(
        base,
        menuService.addSetMenuIsActiveByIdInBatch
      );

      toast.update(toastId, {
        render: base.isActive ? t("base.base-opened") : t("base.base-closed"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, allBases, isEditable } = this.props;
    const { basePendingToggleIsActiveConfirmation } = this.state;

    return (
      <div>
        <ConfirmDialog
          isOpen={basePendingToggleIsActiveConfirmation !== null}
          title={t(
            basePendingToggleIsActiveConfirmation?.isActive
              ? "base.close-base"
              : "base.open-base"
          )}
          description={t(
            basePendingToggleIsActiveConfirmation?.isActive
              ? "base.are-you-sure-you-want-to-close-this-base"
              : "base.are-you-sure-you-want-to-open-this-base"
          )}
          confirmButtonLabel={t(
            basePendingToggleIsActiveConfirmation?.isActive
              ? "base.close-base"
              : "base.open-base"
          )}
          onConfirm={this.handleToggleBaseIsActiveConfirm}
          onCancel={this.handleToggleBaseIsActiveCancel}
        />
        {allBases &&
          allBases.map((base) => (
            <OpenOrders
              key={base.id}
              base={base}
              openOrders={this.getOpenOrdersForBase(base)}
              openOrdersCountPerMinute={this.getOpenOrdersCountPerMinuteForBase(
                base
              )}
              headerLeft={
                <Switch
                  disabled={!isEditable && !base?.externalId}
                  onClick={this.handleBaseIsActiveClick}
                  onChange={this.handleToggleBaseIsActive(base)}
                  checked={base.isActive}
                />
              }
              title={base.name}
            />
          ))}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(OpenOrdersPerBase)
);
