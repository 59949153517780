import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Menu, MenuItem } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import AddMenuCategoryDialog from "./AddMenuCategoryDialog";
import AddMenuItemDialog from "./AddMenuItemDialog";

const styles = (theme) => ({});

class AddElementMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isAddMenuCategoryDialogOpen: false,
      isAddMenuItemDialogOpen: false
    };
  }

  handleAddCategory = (event) => {
    const { onClose } = this.props;

    this.setState({ isAddMenuCategoryDialogOpen: true });
    onClose();
  };

  handleAddItem = (event) => {
    const { onClose } = this.props;

    this.setState({ isAddMenuItemDialogOpen: true });
    onClose();
  };

  handleAddMenuCategoryDialogClose = () => {
    this.setState({ isAddMenuCategoryDialogOpen: false });
  };

  handleAddMenuItemDialogClose = () => {
    this.setState({ isAddMenuItemDialogOpen: false });
  };

  render() {
    const { t, anchorElement, onClose, bar, menu, catalogue, deposits } =
      this.props;
    const { isAddMenuCategoryDialogOpen, isAddMenuItemDialogOpen } = this.state;

    return (
      <div>
        <AddMenuCategoryDialog
          isOpen={isAddMenuCategoryDialogOpen}
          onClose={this.handleAddMenuCategoryDialogClose}
          menu={menu}
        />
        <AddMenuItemDialog
          isOpen={isAddMenuItemDialogOpen}
          onClose={this.handleAddMenuItemDialogClose}
          bar={bar}
          menu={menu}
          catalogue={catalogue}
          deposits={deposits}
        />
        <Menu
          anchorEl={anchorElement}
          open={Boolean(anchorElement)}
          onClose={onClose}
        >
          <MenuItem
            data-cy="menu-add-category"
            onClick={this.handleAddCategory}
          >
            {t("menu.category")}
          </MenuItem>
          <MenuItem data-cy="menu-add-item" onClick={this.handleAddItem}>
            {t("menu.item")}
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(AddElementMenu)
);
