import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import withStyles from "@mui/styles/withStyles";

import EditLayoutNodeBasics from "./EditLayoutNodeBasics";

const styles = (theme) => ({
  smallSpacing: {
    height: 10
  },
  largeSpacing: {
    height: 20
  },
  inputContainer: {
    display: "flex",
    alignItems: "center"
  },
  inputLabel: {
    width: "100%"
  },
  newSeriesItem: {
    display: "flex",
    alignItems: "center",
    height: 40,
    padding: `0 ${theme.spacing(2)}`,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 20,
    backgroundColor: theme.palette.background.paper
  },
  newSeriesItemName: {
    whitespace: "nowrap"
  },
  newSeriesItemText: {
    marginTop: 2,
    marginLeft: 4,
    marginRight: 10
  },
  newSeriesItemInput: {
    fontSize: 14
  },
  newSeriesItemButton: {},
  seriesItems: {
    display: "flex",
    flexWrap: "wrap",
    margin: `0 -${theme.spacing()}`
  },
  seriesItem: {
    display: "flex",
    alignItems: "center",
    height: 40,
    margin: theme.spacing(),
    padding: `0 ${theme.spacing(1.5)}`,
    borderRadius: 20,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  seriesItemText: {
    width: "100%"
  },
  removeSeriesItemButton: {
    marginRight: 10,
    padding: 0,
    color: theme.palette.primary.contrastText
  }
});

class EditSeriesLayoutNode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newSeriesItem: ""
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { layoutNode } = this.props;
    const prevLayoutNode = prevProps.layoutNode;

    if (layoutNode !== prevLayoutNode) {
      this.setState({ newSeriesItem: "" });
    }
  }

  sortSeries = (series) => {
    // Check if both items are numbers
    // It that is the case, sort them numerically
    // Otherwise, sort them alphabetically
    return series.sort((itemA, itemB) => {
      return !isNaN(itemA) && !isNaN(itemB)
        ? itemA - itemB
        : itemA === itemB
        ? 0
        : itemA < itemB
        ? -1
        : 1;
    });
  };

  isNewSeriesItemValid = () => {
    const { layoutNode } = this.props;
    const { series } = layoutNode;
    const { newSeriesItem } = this.state;

    return (
      newSeriesItem && newSeriesItem.length && series.indexOf(newSeriesItem) < 0
    );
  };

  handleNewSeriesItemChange = (event) => {
    this.setState({ newSeriesItem: event.target.value });
  };

  handleNewSeriesItemKeyPress = (event) => {
    const { newSeriesItem } = this.state;
    if (event.key === "Enter") {
      event.preventDefault();

      if (!newSeriesItem) {
        this.setState(
          { newSeriesItem: this.predictNextSeriesItem() },
          this.handleAddSeriesItem
        );
      } else {
        this.handleAddSeriesItem();
      }
    }
  };

  predictNextSeriesItem = () => {
    const { layoutNode } = this.props;

    let lastNumericItem = 0;
    for (let i = layoutNode.series.length - 1; i >= 0; i--) {
      if (!isNaN(layoutNode.series[i])) {
        lastNumericItem = parseInt(layoutNode.series[i]);
        break;
      }
    }

    return `${lastNumericItem + 1}`;
  };

  handleAddSeriesItem = () => {
    const { t, layoutNode, onUpdate } = this.props;
    const { newSeriesItem } = this.state;

    try {
      if (this.isNewSeriesItemValid()) {
        layoutNode.series = this.sortSeries([
          ...layoutNode.series,
          newSeriesItem
        ]);

        onUpdate(layoutNode);

        this.setState({
          newSeriesItem: ""
        });
      }
    } catch (error) {
      toast.error(
        t(error && error.message ? error.message : "label.unknown-error")
      );
    }
  };

  handleRemoveSeriesItem = (index) => (event) => {
    const { t, layoutNode, onUpdate } = this.props;

    try {
      const series = [...layoutNode.series];
      series.splice(index, 1);
      layoutNode.series = series;

      onUpdate(layoutNode);
    } catch (error) {
      toast.error(
        t(error && error.message ? error.message : "label.unknown-error")
      );
    }
  };

  render() {
    const { classes, t, isDisabled, bar, allBases, layoutNode, onUpdate } =
      this.props;
    const { name, series } = layoutNode;
    const { newSeriesItem } = this.state;

    return (
      <div>
        <EditLayoutNodeBasics
          isDisabled={isDisabled}
          bar={bar}
          allBases={allBases}
          layoutNode={layoutNode}
          onUpdate={onUpdate}
        />
        <div className={classes.largeSpacing} />
        <div className={classes.newSeriesItem}>
          <span className={classes.newSeriesItemName}>{name}</span>
          <TextField
            className={classes.newSeriesItemText}
            variant="standard"
            margin="none"
            InputProps={{
              disableUnderline: true,
              classes: {
                input: classes.newSeriesItemInput
              }
            }}
            fullWidth
            disabled={isDisabled}
            placeholder={t("zone.series-layout-node-new-item")}
            value={newSeriesItem}
            onChange={this.handleNewSeriesItemChange}
            onKeyPress={this.handleNewSeriesItemKeyPress}
          />
          <IconButton
            color="primary"
            size="small"
            className={classes.newSeriesItemButton}
            disabled={!this.isNewSeriesItemValid()}
            onClick={this.handleAddSeriesItem}
          >
            <AddIcon />
          </IconButton>
        </div>
        <div className={classes.smallSpacing} />
        <div className={classes.seriesItems}>
          {series.map((item, index) => (
            <div key={index} className={classes.seriesItem}>
              <IconButton
                size="small"
                className={classes.removeSeriesItemButton}
                onClick={this.handleRemoveSeriesItem(index)}
              >
                <CloseIcon />
              </IconButton>
              <span
                className={classes.seriesItemText}
              >{`${name} ${item}`}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditSeriesLayoutNode)
);
