import React, { PureComponent } from "react";

import { Divider, Menu } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import AddSeriesLayoutNodeMenuItem from "./AddSeriesLayoutNodeMenuItem";
import RemoveLayoutNodeMenuItem from "./RemoveLayoutNodeMenuItem";
import AddSingleLayoutNodeMenuItem from "./AddSingleLayoutNodeMenuItem";
import AddSequenceLayoutNodeMenuItem from "./AddSequenceLayoutNodeMenuItem";
import LayoutNodeType from "tap-io/models/zone/LayoutNodeType";

const styles = (theme) => ({
  menu: {
    minWidth: 250
  }
});

class EditLayoutNodeMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes,
      anchor,
      maxHeight,
      onClose,
      onLayoutNodeAdd,
      onLayoutNodeRemove,
      layoutNode
    } = this.props;

    if (!layoutNode) {
      return null;
    }

    const isOpen = Boolean(anchor);

    const isAddLayoutNodeAvailable =
      maxHeight === undefined || layoutNode.depth < maxHeight;
    const isRemoveLayoutNodeAvailable = layoutNode.type !== LayoutNodeType.ZONE;

    // TO FIX: MenuItems wrapped in a <div /> container to circumvent ForwardRef error
    return (
      <Menu anchorEl={anchor} open={isOpen} onClose={onClose}>
        <div className={classes.menu}>
          {isAddLayoutNodeAvailable && (
            <div>
              <AddSingleLayoutNodeMenuItem
                layoutNode={layoutNode}
                onAdd={onLayoutNodeAdd}
              />
              <AddSequenceLayoutNodeMenuItem
                layoutNode={layoutNode}
                onAdd={onLayoutNodeAdd}
              />
              <AddSeriesLayoutNodeMenuItem
                layoutNode={layoutNode}
                onAdd={onLayoutNodeAdd}
              />
            </div>
          )}
          {isRemoveLayoutNodeAvailable && (
            <div>
              {isAddLayoutNodeAvailable && <Divider />}
              <RemoveLayoutNodeMenuItem
                layoutNode={layoutNode}
                onRemove={onLayoutNodeRemove}
              />
            </div>
          )}
        </div>
      </Menu>
    );
  }
}

export default withStyles(styles)(EditLayoutNodeMenu);
