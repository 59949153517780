export const REGISTER = "/register";
export const SIGN_IN = "/signin";
export const SETUP = "/setup";
export const MODES = "/modes";
export const ORDERS = "/orders";
export const CHECKOUT = "/checkout";
export const VOUCHERS = "/vouchers";
export const OVERVIEW = "/overview";
export const MENUS = "/menus";
export const SETTINGS = "/settings";
export const LOGISTICS = "/logistics";
export const BALANCE = "/balance";
export const PROMO = "/promo";
export const STATS = "/stats";
export const ADMIN = "/admin";
export const MGMT = "/mgmt";
export const UNAVAILABLE = "/unavailable";
