import * as printer from "./printer";
import * as scanner from "./scanner";

const promises = {};
const callbacks = {};

function generateId() {
  return `${new Date().getTime()}_${Math.random()}`;
}

function resolvePromise(promiseId, data) {
  const promise = promises[promiseId];

  if (promise) {
    promise.resolve(data);
  }

  delete promises[promiseId];
}

function rejectPromise(promiseId, error) {
  const promise = promises[promiseId];

  if (promise) {
    promise.reject(new Error(error));
  }

  delete promises[promiseId];
}

function runCallback(callbackId, data) {
  const callback = callbacks[callbackId];

  if (callback) {
    callback(data);
  }
}

function cancelCallback(callbackId) {
  delete callbacks[callbackId];
}

const hasNativeWrapper = () => {
  return window.webkit?.messageHandlers?.nativeWrapperAvailableHandler;
};

const getNativeWrapperVersion = () => {
  return window.nativeWrapperVersion || null;
};

const hasNativeMethod = (methodName) => {
  return hasNativeWrapper() && window.webkit.messageHandlers[methodName];
};

const callNativeMethod = (methodName, methodParams) => {
  if (hasNativeMethod(methodName)) {
    window.webkit.messageHandlers[methodName].postMessage(
      methodParams ? methodParams : {}
    );
  }
};

const callAsyncNativeMethod = async (methodName, methodParams) => {
  const promise = new Promise(function (resolve, reject) {
    const promiseId = generateId();
    promises[promiseId] = { resolve, reject };

    callNativeMethod(methodName, {
      ...(methodParams ? methodParams : {}),
      promiseId
    });
  });

  return promise;
};

const subscribeToNativeMethod = async (
  subscribeMethodName,
  unsubscribeMethodName,
  methodParams,
  callback
) => {
  const callbackId = generateId();
  callbacks[callbackId] = callback;

  await callAsyncNativeMethod(subscribeMethodName, {
    ...(methodParams ? methodParams : {}),
    callbackId
  });

  return async () => {
    await callAsyncNativeMethod(unsubscribeMethodName, {
      ...(methodParams ? methodParams : {}),
      callbackId
    });
  };
};

window.resolveNativePromise = resolvePromise;
window.rejectNativePromise = rejectPromise;
window.runNativeCallback = runCallback;
window.cancelNativeCallback = cancelCallback;

export {
  hasNativeWrapper,
  getNativeWrapperVersion,
  hasNativeMethod,
  callNativeMethod,
  callAsyncNativeMethod,
  subscribeToNativeMethod,
  printer,
  scanner
};
