export const WHICH_ORDERS_ALL = "all";
export const WHICH_ORDERS_UNPAID = "unpaid";

export const WHICH_ORDERS_LABELS = {
  [WHICH_ORDERS_ALL]: "order.all",
  [WHICH_ORDERS_UNPAID]: "order.unpaid"
};

export const WHICH_ORDERS = [
  { id: WHICH_ORDERS_ALL, label: WHICH_ORDERS_LABELS[WHICH_ORDERS_ALL] },
  { id: WHICH_ORDERS_UNPAID, label: WHICH_ORDERS_LABELS[WHICH_ORDERS_UNPAID] }
];
