import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import withAuthorization from "../auth/withAuthorization";
import EditBusiness from "../settings/EditBusiness";
import { toast } from "react-toastify";

const styles = (theme) => ({
  dialog: {
    minWidth: 300
  }
});

class VerifyBarBusinessDataDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    this.updateBusinessData = () => {
      console.warn("Business data update function not set");
    };
  }

  handleConfirmBarBusinessData = async () => {
    const { t, onAccept } = this.props;

    try {
      await this.updateBusinessData();

      onAccept();
    } catch (error) {
      toast.error(`${t("label.something-went-wrong")} (${error.message})`);
    }
  };

  render() {
    const { classes, t, isOpen, onReject, bar, balance } = this.props;

    return (
      <Dialog open={isOpen} onClose={onReject} className={classes.dialog}>
        <DialogTitle>{t("data.verify-bar-business-data")}</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <EditBusiness
            bar={bar}
            balance={balance}
            setOnUpdate={(onUpdate) => (this.updateBusinessData = onUpdate)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onReject} color="secondary">
            {t("label.cancel")}
          </Button>
          <Button onClick={this.handleConfirmBarBusinessData} color="primary">
            {t("label.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withAuthorization()(
  withStyles(styles)(withTranslation("common")(VerifyBarBusinessDataDialog))
);
