export const BAR_TYPE_FESTIVAL = "festival";
export const BAR_TYPE_THEATRE = "theatre";
export const BAR_TYPE_FOOD_FESTIVITIES = "foodFestivities";
export const BAR_TYPE_PARTY = "party";
export const BAR_TYPE_CATERING = "catering";
export const BAR_TYPE_BANQUET_HALL = "banquetHall";
export const BAR_TYPE_MUSIC_CLUB = "musicClub";
export const BAR_TYPE_FOOD_SERVICE_INDUSTRY = "foodServiceIndustry";
export const BAR_TYPE_ASSOCIATION = "association";
export const BAR_TYPE_YOUTH_MOVEMENT = "youthMovement";
export const BAR_TYPE_SUMMER_BAR = "summerBar";
export const BAR_TYPE_POP_UP_EVENT = "popUpEvent";
export const BAR_TYPE_SPORTS_CLUB = "sportsClub";
export const BAR_TYPE_OTHER = "other";

export const BAR_TYPES = [
  { id: BAR_TYPE_FESTIVAL, label: "settings.bar-type-option-festival" },
  { id: BAR_TYPE_THEATRE, label: "settings.bar-type-option-theatre" },
  {
    id: BAR_TYPE_FOOD_FESTIVITIES,
    label: "settings.bar-type-option-food-festivities"
  },
  { id: BAR_TYPE_PARTY, label: "settings.bar-type-option-party" },
  { id: BAR_TYPE_CATERING, label: "settings.bar-type-option-catering" },
  { id: BAR_TYPE_BANQUET_HALL, label: "settings.bar-type-option-banquet-hall" },
  { id: BAR_TYPE_MUSIC_CLUB, label: "settings.bar-type-option-music-club" },
  {
    id: BAR_TYPE_FOOD_SERVICE_INDUSTRY,
    label: "settings.bar-type-option-food-service-industry"
  },
  { id: BAR_TYPE_ASSOCIATION, label: "settings.bar-type-option-association" },
  {
    id: BAR_TYPE_YOUTH_MOVEMENT,
    label: "settings.bar-type-option-youth-movement"
  },
  { id: BAR_TYPE_SUMMER_BAR, label: "settings.bar-type-option-summer-bar" },
  { id: BAR_TYPE_POP_UP_EVENT, label: "settings.bar-type-option-pop-up-event" },
  { id: BAR_TYPE_SPORTS_CLUB, label: "settings.bar-type-option-sports-club" },
  { id: BAR_TYPE_OTHER, label: "settings.bar-type-option-other" }
];

export const BAR_SIZE_SMALL = "s";
export const BAR_SIZE_MEDIUM = "m";
export const BAR_SIZE_LARGE = "l";

export const BAR_SIZES = [
  { id: BAR_SIZE_SMALL, label: "settings.bar-size-option-small" },
  { id: BAR_SIZE_MEDIUM, label: "settings.bar-size-option-medium" },
  { id: BAR_SIZE_LARGE, label: "settings.bar-size-option-large" }
];
