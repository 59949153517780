import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch
} from "@mui/material";

export type FilterSwitchProps = {
  label?: string;
  description?: string;
  value: boolean;
  onChange: (value: boolean) => void;
};

export function FilterSwitch(props: FilterSwitchProps) {
  const { label, description, value, onChange } = props;

  return (
    <FormControl>
      <FormControlLabel
        sx={{ marginLeft: 1, marginRight: 0 }}
        labelPlacement="start"
        label={label}
        componentsProps={{
          typography: { textAlign: "left", flex: 1 }
        }}
        checked={value}
        onChange={(e, checked) => onChange(checked)}
        control={<Switch />}
      />
      {description && (
        <FormHelperText sx={{ margin: 0, marginLeft: 1 }}>
          {description}
        </FormHelperText>
      )}
    </FormControl>
  );
}
