import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import ReactMoment from "react-moment";

import {
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AdjustIcon from "@mui/icons-material/Adjust";
import RestoreIcon from "@mui/icons-material/Restore";
import withStyles from "@mui/styles/withStyles";

import { balanceService } from "tap-io/client/services";

const BALANCE_LOG_INITIAL_BATCH_SIZE = 10;
const BALANCE_LOG_ADDITIONAL_BATCH_SIZE = 10;

const styles = (theme) => ({
  spinner: {
    justifyContent: "center"
  },
  plus: {
    color: "green"
  },
  minus: {
    color: "red"
  },
  zero: {
    color: "grey"
  },
  entryLabel: {
    display: "block",
    fontWeight: "bold"
  },
  entryBalance: {
    display: "block"
  }
});

class BalanceLog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false,
      balanceLogMaxCount: BALANCE_LOG_INITIAL_BATCH_SIZE
    };
  }

  componentDidMount() {
    this.refreshBalanceLog();
  }

  componentWillUnmount() {
    if (this.unsubscribeBarBalanceLog) {
      this.unsubscribeBarBalanceLog();
      this.unsubscribeBarBalanceLog = undefined;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { bar } = this.props;
    const { balanceLogMaxCount } = this.state;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;

    const prevBalanceLogMaxCount = prevState.balanceLogMaxCount;

    if (barId !== prevBarId || balanceLogMaxCount !== prevBalanceLogMaxCount) {
      this.refreshBalanceLog();
    }
  }

  refreshBalanceLog = () => {
    const { bar } = this.props;
    const { balanceLogMaxCount } = this.state;

    if (this.unsubscribeBarBalanceLog) {
      this.unsubscribeBarBalanceLog();
      this.unsubscribeBarBalanceLog = undefined;
    }

    if (bar && bar.id) {
      this.setState({ isDisabled: true });

      this.unsubscribeBarBalanceLog = balanceService.onBalanceLogById(
        bar.id,
        balanceLogMaxCount,
        (balanceLog) => {
          this.setState({ isDisabled: false, balanceLog });
        }
      );
    }
  };

  handleFetchMoreBalanceLogs = () => {
    const { balanceLogMaxCount } = this.state;

    this.setState({
      balanceLogMaxCount: balanceLogMaxCount + BALANCE_LOG_ADDITIONAL_BATCH_SIZE
    });
  };

  getEntryLabel = (entry) => {
    const { t } = this.props;

    switch (entry.type) {
      case "welcome":
        return `${t("credits.welcome-gift")}! 🎁`;
      case "order":
        return `${t("label.order")}${
          entry.totalAmount
            ? ` (${entry.totalAmount} ${
                entry.totalAmount === 1
                  ? t("label.consumption")
                  : t("label.consumptions")
              })`
            : ""
        }`;
      case "vouchers":
        return `${t("voucher.vouchers")}${
          entry.totalAmount
            ? ` (${entry.totalAmount} ${(entry.totalAmount === 1
                ? t("voucher.voucher")
                : t("voucher.vouchers")
              ).toLowerCase()})`
            : ""
        }`;
      case "fees":
        return `${t("fee.fees")}${
          entry.totalAmount
            ? ` (${entry.totalAmount} ${(entry.totalAmount === 1
                ? t("fee.fee")
                : t("fee.fees")
              ).toLowerCase()})`
            : ""
        }`;
      case "payment":
        return t("credits.credits-purchased");
      case "coupon":
        return t("credits.coupon");
      case "invoice":
        return t("invoice.invoice");
      default:
        return `(${t("label.unknown")})`;
    }
  };

  getEntryBalance = (entry) => {
    const { t } = this.props;

    return `${t("credits.new-balance")}: ${
      entry.previousCreditCount + entry.amount
    } (${entry.amount > 0 ? `+${entry.amount}` : entry.amount})`;
  };

  render() {
    const { classes, t, bar } = this.props;
    const { isDisabled, balanceLogMaxCount, balanceLog } = this.state;

    return (
      <List>
        {balanceLog ? (
          <div>
            {balanceLog.length > 0 ? (
              balanceLog.map((entry) => (
                <ListItem key={entry.id}>
                  <ListItemIcon>
                    {entry.amount === 0 ? (
                      <AdjustIcon className={classes.zero} />
                    ) : entry.amount > 0 ? (
                      <AddCircleIcon className={classes.plus} />
                    ) : (
                      <RemoveCircleIcon className={classes.minus} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <span>
                        <span className={classes.entryLabel}>
                          {this.getEntryLabel(entry)}
                        </span>
                        <span className={classes.entryBalance}>
                          {this.getEntryBalance(entry)}
                        </span>
                      </span>
                    }
                    secondary={
                      <ReactMoment format="DD/MM/YYYY, HH:mm">
                        {entry.timestamp}
                      </ReactMoment>
                    }
                  />
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemText primary={t("credits.no-items-found")} />
              </ListItem>
            )}
          </div>
        ) : (
          <ListItem className={classes.spinner}>
            <CircularProgress />
          </ListItem>
        )}
        {balanceLog && balanceLogMaxCount <= balanceLog.length && (
          <ListItemButton
            disabled={isDisabled}
            onClick={this.handleFetchMoreBalanceLogs}
          >
            <ListItemIcon>
              <RestoreIcon />
            </ListItemIcon>
            <ListItemText primary={t("label.load-more-items")} />
          </ListItemButton>
        )}
      </List>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(BalanceLog));
