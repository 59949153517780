import config from "tap-io/client/env";
import { authHelper, serviceHelper } from "tap-io/helpers";
import { BaseReport } from "../models/BaseReport";
import { DepositReport } from "../models/DepositReport";
import { FeeReport } from "../models/FeeReport";
import { FinancialDeviceReport } from "../models/FinancialDeviceReport";
import { FinancialLocationReport } from "../models/FinancialLocationReport";
import { FinancialTimeslotReport } from "../models/FinancialTimeslotReport";
import { OperationalDeviceReport } from "../models/OperationalDeviceReport";
import { OperationalLocationReport } from "../models/OperationalLocationReport";
import { OperationalTimeslotReport } from "../models/OperationalTimeslotReport";
import { PaymentReport } from "../models/PaymentReport";
import { ProductReport } from "../models/ProductReport";
import { SaleReport } from "../models/SaleReport";
import { ServiceReport } from "../models/ServiceReport";
import { DataOf, Stat } from "../models/Stat";
import { TransactionReport } from "../models/TransactionReport";

export type PeriodFilter = {
  from: Date;
  to: Date;
};
export type OrganisationFilter = {
  organisationId: string;
};
export type EventFilter = OrganisationFilter & {
  eventId: string;
};
export type BaseFilter = EventFilter & {
  baseId: string;
};

function fetchReport<T extends Stat<any>>(
  type: string,
  Class: new (data: DataOf<T>[]) => T
) {
  return async function (
    user: any,
    filter: PeriodFilter & (OrganisationFilter | EventFilter | BaseFilter)
  ): Promise<T> {
    try {
      let f = {} as any;

      if (filter.from) f.from = filter.from.toISOString();
      if (filter.to) f.to = filter.to.toISOString();
      if (filter.organisationId) f.organisationId = filter.organisationId;
      if ((filter as any).eventId) f.eventId = (filter as any).eventId;
      if ((filter as any).baseId) f.baseId = (filter as any).baseId;

      const url = await authHelper.generateAuthorizedLink(
        user,
        `${config.functions.statsApi}/report/${type}`,
        f
      );
      const result = await serviceHelper.fetchJSON(url);

      if (!result?.code || result.code !== 200) {
        console.error(result);
        throw new Error("error.failed-to-fetch-report");
      }

      const reports = result.data ? new Class(result.data) : new Class([]);
      return reports;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}

//Current version of babel does weird things when passing the class instead of a callblack so now we have to wrap every constructor
export const fetchFeeReport = fetchReport("fee", FeeReport);
export const fetchProductReport = fetchReport("product", ProductReport);
export const fetchSaleReport = fetchReport("sale", SaleReport);
export const fetchDepositReport = fetchReport("deposit", DepositReport);
export const fetchBaseReport = fetchReport("base", BaseReport);
export const fetchServiceReport = fetchReport("service", ServiceReport);
export const fetchPaymentReport = fetchReport("payment", PaymentReport);
export const fetchTransactionReport = fetchReport(
  "transaction",
  TransactionReport
);

export const fetchOperationalLocationReport = fetchReport(
  "location/operational",
  OperationalLocationReport
);
export const fetchFinancialLocationReport = fetchReport(
  "location/financial",
  FinancialLocationReport
);
export const fetchOperationalDeviceReport = fetchReport(
  "device/operational",
  OperationalDeviceReport
);
export const fetchFinancialDeviceReport = fetchReport(
  "device/financial",
  FinancialDeviceReport
);
export const fetchOperationalTimeslotReport = fetchReport(
  "timeslot/operational",
  OperationalTimeslotReport
);
export const fetchFinancialTimeslotReport = fetchReport(
  "timeslot/financial",
  FinancialTimeslotReport
);
