import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { Button, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import CurrentPlanButton from "../subscription/CurrentPlanButton";

const styles = (theme) => ({
  root: {
    zIndex: 1000,
    position: "sticky",
    top: 20,
    pointerEvents: "none"
  },
  header: {
    display: "flex",
    alignItems: "center",
    ...theme.mixins.toolbar
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  container: {
    maxWidth: "70%",
    marginTop: -5,
    padding: "10px 10px 5px 10px",
    borderRadius: 5,
    backgroundColor: "orange",
    pointerEvents: "auto"
  },
  warningContainer: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText
  },
  errorContainer: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  divider: {
    opacity: 0.5,
    width: "100%",
    margin: `${theme.spacing(1.5)} 0 ${theme.spacing(0.5)} 0`,
    borderBottom: "1px solid black"
  },
  warningDivider: {
    borderColor: theme.palette.warning.contrastText
  },
  errorDivider: {
    borderColor: theme.palette.error.contrastText
  },
  spacing: {
    height: 10
  }
});

class BarNotLiveOrBaseNotActiveWarning extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleUnlockApp = () => {
    const { unlockApp } = this.props;

    unlockApp()
      .then(() => {})
      .catch((error) => {
        console.warn(error);
      });
  };

  render() {
    const {
      classes,
      t,
      drawerWidth,
      isMenuDrawerOpen,
      isAppLocked,
      bar,
      subscription,
      base,
      selectBase,
      unlockApp
    } = this.props;

    const isBarNotLive = bar && !bar.isLive;
    const isBaseNotActive = bar && bar.isUsingBases() && base && !base.isActive;

    const isBarNotLiveBecauseSubscriptionExpired =
      isBaseNotActive && subscription && subscription.isExpired();

    const areActionButtonsShown =
      isBarNotLiveBecauseSubscriptionExpired ||
      (isBarNotLive ? isAppLocked : isBaseNotActive);

    return (
      (isBarNotLive || isBaseNotActive) && (
        <div className={classes.root}>
          <div className={classes.header}></div>
          <div
            className={classes.content}
            style={{
              width: `calc(100% - ${isMenuDrawerOpen ? drawerWidth : 0}px)`,
              marginRight: isMenuDrawerOpen ? drawerWidth : 0
            }}
          >
            <div
              className={classNames(
                classes.container,
                isBarNotLiveBecauseSubscriptionExpired
                  ? classes.errorContainer
                  : classes.warningContainer
              )}
            >
              <Typography variant="body1">
                <strong>
                  {t(
                    isBarNotLive
                      ? "bar.order-page-is-unpublished"
                      : "base.base-closed"
                  )}
                </strong>
                <br />
                {t("bar.placed-orders-can-be-handled")}
              </Typography>
              {areActionButtonsShown && (
                <>
                  <div
                    className={classNames(
                      classes.divider,
                      isBarNotLiveBecauseSubscriptionExpired
                        ? classes.errorDivider
                        : classes.warningDivider
                    )}
                  />
                  <div className={classes.actionButtons}>
                    {isBarNotLiveBecauseSubscriptionExpired ? (
                      <>
                        <Typography>
                          {t(
                            "bar.order-page-is-offline-because-subscription-is-expired-renew-or-downgrade"
                          )}
                        </Typography>
                        <div className={classes.spacing} />
                        <CurrentPlanButton
                          isAllowedToShowPlan={!isAppLocked}
                          requestPermissionToShowPlan={unlockApp}
                          bar={bar}
                          subscription={subscription}
                          label={t(
                            "subscription.renew-or-downgrade-subscription"
                          )}
                          hideIcon
                          fullWidth
                        />
                      </>
                    ) : (
                      <Button
                        fullWidth
                        className={classes.actionButton}
                        onClick={
                          isBarNotLive ? this.handleUnlockApp : selectBase
                        }
                      >
                        {t(
                          isBarNotLive
                            ? "bar.open-admin-to-publish-order-page"
                            : "base.open-base-settings"
                        )}
                      </Button>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(BarNotLiveOrBaseNotActiveWarning)
);
