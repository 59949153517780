import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Checkbox,
  FormControlLabel,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import withStyles from "@mui/styles/withStyles";

import { utilsHelper } from "tap-io/helpers";
import {
  FIELD_DAYS,
  FIELD_DESCRIPTIONS,
  FIELD_ICONS
} from "tap-io/client/constants/fields";
import SelectCustomerFieldOption from "tap-io/models/bar/SelectCustomerFieldOption";
import CustomerFieldType from "tap-io/models/bar/CustomerFieldType";
import SelectDate from "tap-io/client/components/common/SelectDate";

const styles = (theme) => ({
  textField: {
    marginRight: 20
  },
  descriptionItem: {
    marginTop: -10,
    paddingTop: 0,
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(8.5)
  },
  toggleItem: {
    marginTop: -10,
    paddingTop: 0,
    paddingLeft: theme.spacing(7)
  },
  dateForm: {
    width: "100%"
  },
  dateCheckbox: {
    marginLeft: 1
  },
  dateField: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginLeft: theme.spacing(3)
  },
  dateEmpty: {
    marginTop: -16
  },
  dayLabel: {
    marginRight: 12
  },
  dayCheckbox: {
    paddingRight: 4
  },
  exceptDaysDescription: {
    marginTop: -20
  }
});

class EditCustomerFieldsDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getFieldIcon = (fieldType, color) => {
    const FieldIcon = FIELD_ICONS[fieldType]
      ? FIELD_ICONS[fieldType]
      : HelpOutlineIcon;
    return <FieldIcon color={color} />;
  };

  handleRemoveField = (event) => {
    const { field, onRemove } = this.props;

    onRemove(field);
  };

  handleFieldNameChange = (event) => {
    const { field, onUpdate } = this.props;

    const val = event.target.value;

    const updatedField = field.clone();
    updatedField.name = val;
    onUpdate(updatedField);
  };

  handleFieldDescriptionChange = (event) => {
    const { field, onUpdate } = this.props;

    const val = event.target.value;

    const updatedField = field.clone();
    updatedField.description = val;
    onUpdate(updatedField);
  };

  handleIsRequiredChange = (event) => {
    const { field, onUpdate } = this.props;

    const val = event.target.checked === true;

    const updatedField = field.clone();
    updatedField.isRequired = val;
    onUpdate(updatedField);
  };

  handleSendOrderConfirmationChange = (event) => {
    const { field, onUpdate } = this.props;

    const val = event.target.checked === true;

    const updatedField = field.clone();
    updatedField.sendOrderConfirmationToThisAddress = val;
    onUpdate(updatedField);
  };

  handleMinDateSetEnabled = (event) => {
    const { field, onUpdate } = this.props;

    const val = event.target.checked === true;

    const updatedField = field.clone();
    updatedField.minDate = val
      ? field.maxDate
        ? field.maxDate
        : new Date().toISOString().substr(0, 10)
      : null;
    onUpdate(updatedField);
  };

  handleMinDateChange = (date) => {
    const { field, onUpdate } = this.props;

    const newDate = date.endOf("day").toDate().toISOString().substr(0, 10);

    const updatedField = field.clone();
    updatedField.minDate = newDate;
    if (field.maxDate && field.maxDate < newDate) {
      updatedField.maxDate = newDate;
    }
    onUpdate(updatedField);
  };

  handleMaxDateSetEnabled = (event) => {
    const { field, onUpdate } = this.props;

    const val = event.target.checked === true;

    const updatedField = field.clone();
    updatedField.maxDate = val
      ? field.minDate
        ? field.minDate
        : new Date().toISOString().substr(0, 10)
      : null;
    onUpdate(updatedField);
  };

  handleMaxDateChange = (date) => {
    const { field, onUpdate } = this.props;

    const newDate = date.endOf("day").toDate().toISOString().substr(0, 10);

    const updatedField = field.clone();
    updatedField.maxDate = newDate;
    if (field.minDate && field.minDate > newDate) {
      updatedField.minDate = newDate;
    }
    onUpdate(updatedField);
  };

  handleExceptDaysChange = (dayIndex) => (event) => {
    const { t, field, onUpdate } = this.props;

    const val = event.target.checked === true;

    try {
      const exceptDays = { ...field.exceptDays };
      exceptDays[dayIndex] = val !== true;

      const updatedField = field.clone();
      updatedField.exceptDays = exceptDays;
      onUpdate(updatedField);
    } catch (error) {
      console.warn(error);

      toast.error(
        error && error.message ? error.message : t("error.unknown-error")
      );
    }
  };

  handleFieldOptionChange = (optionId) => (event) => {
    const { field, onUpdate } = this.props;

    const val = event.target.value;

    const updatedField = field.clone();
    updatedField.options = field.options.map((option) => {
      if (option && option.id === optionId) {
        option.label = val;
      }

      return option;
    });
    onUpdate(updatedField);
  };

  handleAddFieldOption = (event) => {
    const { field, onUpdate } = this.props;

    const updatedField = field.clone();
    updatedField.options.push(
      new SelectCustomerFieldOption({
        id: utilsHelper.generateUUID(),
        label: ""
      })
    );
    onUpdate(updatedField);
  };

  handleRemoveFieldOption = (optionId) => (event) => {
    const { field, onUpdate } = this.props;

    const updatedField = field.clone();
    updatedField.options = field.options.filter((option) => {
      return option && option.id !== optionId;
    });
    onUpdate(updatedField);
  };

  render() {
    const { classes, t, field, isDisabled, disableGutters } = this.props;

    return (
      <div key={field.id}>
        <ListItem disableGutters={disableGutters}>
          <ListItemIcon>
            {this.getFieldIcon(field.type, "primary")}
          </ListItemIcon>
          <TextField
            className={classes.textField}
            variant="standard"
            margin="dense"
            label={t("settings.field-name")}
            helperText={t(FIELD_DESCRIPTIONS[field.type])}
            fullWidth
            disabled={isDisabled}
            value={field.name}
            onChange={this.handleFieldNameChange}
          />
          {!isDisabled && (
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                onClick={this.handleRemoveField}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
        <ListItem
          disableGutters={disableGutters}
          className={classes.descriptionItem}
        >
          <TextField
            variant="standard"
            margin="dense"
            label={t("settings.field-description-label")}
            helperText={`${t("settings.field-description-description")} (${t(
              "label.optional"
            )})`}
            fullWidth
            disabled={isDisabled}
            value={field.description}
            onChange={this.handleFieldDescriptionChange}
          />
        </ListItem>
        {(field.type === CustomerFieldType.TEXT ||
          field.type === CustomerFieldType.EMAIL ||
          field.type === CustomerFieldType.TOGGLE) && (
          <ListItem
            disableGutters={disableGutters}
            className={classes.toggleItem}
          >
            <ListItemText
              primary={
                <Typography variant="caption">
                  {t("settings.this-is-a-required-field")}
                </Typography>
              }
            />
            <Switch
              disabled={isDisabled}
              onChange={this.handleIsRequiredChange}
              checked={field.isRequired}
            />
          </ListItem>
        )}
        {field.type === CustomerFieldType.EMAIL && (
          <ListItem
            disableGutters={disableGutters}
            className={classes.toggleItem}
          >
            <ListItemText
              primary={
                <Typography variant="caption">
                  {t(
                    "settings.send-an-order-confirmation-to-this-email-address"
                  )}
                </Typography>
              }
            />
            <Switch
              disabled={isDisabled}
              onChange={this.handleSendOrderConfirmationChange}
              checked={field.sendOrderConfirmationToThisAddress}
            />
          </ListItem>
        )}
        {field.type === CustomerFieldType.DATE && (
          <div>
            <ListItem disableGutters={disableGutters}>
              <FormControlLabel
                className={classes.dateForm}
                classes={{
                  label: classNames(classes.dateField, {
                    [classes.dateEmpty]: !field.minDate
                  })
                }}
                control={
                  <Checkbox
                    className={classes.dateCheckbox}
                    disabled={isDisabled}
                    checked={!!field.minDate}
                    onChange={this.handleMinDateSetEnabled}
                  />
                }
                label={
                  <SelectDate
                    isDisabled={isDisabled || !field.minDate}
                    fullWidth={true}
                    label={t("settings.customers-can-select-a-date-from")}
                    value={field.minDate === undefined ? null : field.minDate}
                    onChange={this.handleMinDateChange}
                  />
                }
              />
            </ListItem>
            <ListItem disableGutters={disableGutters}>
              <FormControlLabel
                className={classes.dateForm}
                classes={{
                  label: classNames(classes.dateField, {
                    [classes.dateEmpty]: !field.maxDate
                  })
                }}
                control={
                  <Checkbox
                    className={classes.dateCheckbox}
                    disabled={isDisabled}
                    checked={!!field.maxDate}
                    onChange={this.handleMaxDateSetEnabled}
                  />
                }
                label={
                  <SelectDate
                    isDisabled={isDisabled || !field.maxDate}
                    fullWidth={true}
                    label={t("settings.customers-can-select-a-date-until")}
                    value={field.maxDate === undefined ? null : field.maxDate}
                    onChange={this.handleMaxDateChange}
                  />
                }
              />
            </ListItem>
            <ListItem disableGutters={disableGutters}>
              <ListItemIcon>
                <ViewWeekIcon />
              </ListItemIcon>
              {FIELD_DAYS.map((day) => (
                <FormControlLabel
                  key={day.index}
                  className={classes.dayLabel}
                  control={
                    <Checkbox
                      className={classes.dayCheckbox}
                      disabled={isDisabled}
                      checked={
                        field.exceptDays && field.exceptDays[day.index]
                          ? !field.exceptDays[day.index]
                          : true
                      }
                      onChange={this.handleExceptDaysChange(day.index)}
                    />
                  }
                  label={t(day.label)}
                />
              ))}
            </ListItem>
            <ListItem
              disableGutters={disableGutters}
              className={classes.exceptDaysDescription}
            >
              <ListItemIcon>
                <div></div>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="caption">
                    {t("settings.customers-can-select-following-days")}
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
          </div>
        )}
        {field.type === CustomerFieldType.SELECT && (
          <div>
            {field.options &&
              field.options.map((option) => (
                <ListItem key={option.id} disableGutters={disableGutters}>
                  <ListItemIcon>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <TextField
                    className={classes.textField}
                    variant="standard"
                    margin="dense"
                    label={t("settings.option-label")}
                    helperText={t("settings.option-example")}
                    fullWidth
                    disabled={isDisabled}
                    value={option.label}
                    onChange={this.handleFieldOptionChange(option.id)}
                  />
                  {!isDisabled && (
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={this.handleRemoveFieldOption(option.id)}
                        size="large"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
            {!isDisabled && (
              <ListItem
                button
                disableGutters={disableGutters}
                onClick={this.handleAddFieldOption}
              >
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary={t("settings.add-option")} />
              </ListItem>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(EditCustomerFieldsDialog)
);
