import React, { PureComponent } from "react";

import { Link } from "react-router-dom";

import { withTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { integrationHelper } from "tap-io/helpers";

import * as routes from "../../constants/routes";

import SelectCurrentBase from "./SelectCurrentBase";
import BaseInfo from "./BaseInfo";

const styles = {
  spacing: {
    height: 20
  },
  link: {
    width: "100%",
    textDecoration: "none"
  }
};

class SelectBaseDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes,
      t,
      isOpen,
      onClose,
      isAppLocked,
      mode,
      bar,
      base,
      allBases,
      allZones,
      allIntegrations,
      onSelectBase,
      hideLinks
    } = this.props;

    const isAbiIntegrationActive =
      allIntegrations &&
      integrationHelper.findAbiIntegration(allIntegrations)?.isActive;
    const areBasesEditable = !isAbiIntegrationActive;

    return (
      <Dialog
        onClose={onClose}
        open={isOpen}
        disableEscapeKeyDown={bar && bar.isUsingBases() && !base}
      >
        <DialogTitle>{t("base.select-base")}</DialogTitle>
        <DialogContent>
          <SelectCurrentBase
            base={base}
            allBases={allBases}
            isEditable={areBasesEditable}
            allowSwitchToOtherBase={!isAppLocked}
            onSelectBase={onSelectBase}
          />
          <div className={classes.spacing} />
          <BaseInfo
            bar={bar}
            base={base}
            allBases={allBases}
            allZones={allZones}
          />
        </DialogContent>
        <DialogActions>
          {!hideLinks && !isAppLocked && (
            <Link
              to={routes.LOGISTICS}
              className={classes.link}
              onClick={onClose}
            >
              <Button fullWidth>
                {t(
                  bar.isUsingZones()
                    ? "label.edit-bases-and-zones"
                    : "base.edit-bases"
                )}
              </Button>
            </Link>
          )}
          {(!bar.isUsingBases() ||
            !mode.isUsingBases ||
            base ||
            !allBases ||
            allBases.length === 0) && (
            <Button onClick={onClose} color="primary">
              {t("label.close")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(SelectBaseDialog));
