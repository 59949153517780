import React, { PureComponent } from "react";

import { Redirect } from "react-router-dom";

import { hasNativeWrapper } from "../../native";
import * as routes from "../../constants/routes";

const withDevice = (options) => (Component) => {
  class WithDevice extends PureComponent {
    isSmartphone() {
      return hasNativeWrapper() && /iPhone|iPod/.test(navigator.userAgent);
    }

    render() {
      if (this.isSmartphone()) {
        return <Redirect to={routes.UNAVAILABLE} />;
      }

      return <Component {...this.props} />;
    }
  }

  return WithDevice;
};

export default withDevice;
