import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import Moment from "moment";

import { List, ListItem, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { utilsHelper } from "tap-io/helpers";
import SelectDateTime from "tap-io/client/components/common/SelectDateTime";

const styles = (theme) => ({
  picker: {
    display: "flex"
  },
  maxDaysBetweenDatesLabel: {
    display: "block",
    textAlign: "center"
  },
  select: {
    "& > *": {
      width: "100%"
    }
  }
});

class SelectBeginAndEndDateTime extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleBeginDateTimeChange = (dateTime) => {
    const { parseBeginDateTime, maxDaysBetweenDates, endDateTime, onChange } = this.props;

    if (dateTime) {
      try {
        const beginDateTime = parseBeginDateTime ?
          parseBeginDateTime(dateTime.toDate()) :
          dateTime.toDate();

        if (utilsHelper.isValidDate(beginDateTime)) {
          const newDateTimes = { beginDateTime, endDateTime };

          if (
            maxDaysBetweenDates !== undefined &&
            Moment(endDateTime).diff(dateTime, "days") > maxDaysBetweenDates
          ) {
            newDateTimes.endDateTime = dateTime
              .add(maxDaysBetweenDates, "days")
              .toDate();
          } else {
            if (beginDateTime > endDateTime) {
              newDateTimes.endDateTime = Moment(beginDateTime)
                .endOf("day")
                .toDate();
            }
          }

          onChange(newDateTimes);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  handleEndDateTimeChange = (dateTime) => {
    const { parseEndDateTime, maxDaysBetweenDates, beginDateTime, onChange } = this.props;

    if (dateTime) {
      try {
        const endDateTime = parseEndDateTime ?
          parseEndDateTime(dateTime.toDate()) :
          dateTime.toDate();

        if (utilsHelper.isValidDate(endDateTime)) {
          const newDateTimes = { beginDateTime, endDateTime };

          if (
            maxDaysBetweenDates !== undefined &&
            dateTime.diff(Moment(beginDateTime), "days") > maxDaysBetweenDates
          ) {
            newDateTimes.beginDateTime = dateTime
              .add(-maxDaysBetweenDates, "days")
              .toDate();
          } else {
            if (endDateTime < beginDateTime) {
              newDateTimes.beginDateTime = Moment(endDateTime)
                .startOf("day")
                .toDate();
            }
          }

          onChange(newDateTimes);
        }
      } catch (error) {
        console.warn(error);
      }
    }
  };

  render() {
    const {
      classes,
      t,
      isDisabled,
      isFutureDisabled,
      maxDaysBetweenDates,
      minutesStep
    } = this.props;
    const { beginDateTime, endDateTime } = this.props;

    return (
      <List>
        <form className={classes.picker} noValidate>
          <ListItem className={classes.select}>
            <SelectDateTime
              isDisabled={isDisabled}
              fullWidth={true}
              label={t("stats.from").toUpperCase()}
              value={beginDateTime}
              onChange={this.handleBeginDateTimeChange}
              minutesStep={minutesStep}
              maxDate={isFutureDisabled ? new Date() : undefined}
            />
          </ListItem>
          <ListItem className={classes.select}>
            <SelectDateTime
              isDisabled={isDisabled}
              fullWidth={true}
              label={t("stats.until").toUpperCase()}
              value={endDateTime}
              onChange={this.handleEndDateTimeChange}
              minutesStep={minutesStep}
              maxDate={isFutureDisabled ? new Date() : undefined}
            />
          </ListItem>
        </form>
        {maxDaysBetweenDates !== undefined && (
          <Typography
            variant="caption"
            className={classes.maxDaysBetweenDatesLabel}
          >
            {t(
              maxDaysBetweenDates === 1
                ? "stats.max-one-day-between-from-and-until"
                : "stats.max-x-days-between-from-and-until",
              { dayCount: maxDaysBetweenDates }
            )}
          </Typography>
        )}
      </List>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(SelectBeginAndEndDateTime)
);
