import React, { PureComponent } from "react";
import classNames from "classnames";

import {
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  menuItem: {
    width: "100%",
    minHeight: 44,
    textAlign: "left"
  },
  unClickableMenuItem: {
    cursor: "default",
    backgroundColor: `${theme.palette.background.paper} !important`
  }
});

class BarMenuItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, icon, label, description, secondaryAction, onClick } =
      this.props;

    return (
      <MenuItem
        className={classNames(classes.menuItem, {
          [classes.unClickableMenuItem]: !onClick
        })}
        onClick={onClick}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} secondary={description} />
        <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>
      </MenuItem>
    );
  }
}

export default withStyles(styles)(BarMenuItem);
