import React, { PureComponent } from "react";

import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    margin: "10px 0"
  },
  icon: {
    width: 20,
    height: 20,
    minWidth: 20,
    marginRight: 10,
    backgroundImage: "url(/img/list-item.png)",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center"
  },
  feature: {
    minHeight: 24,
    fontSize: 14,
    textAlign: "left"
  }
});

class PlanFeature extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, feature } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.icon} />
        <div className={classes.feature}>{feature}</div>
      </div>
    );
  }
}

export default withStyles(styles)(PlanFeature);
