import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Switch } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import withStyles from "@mui/styles/withStyles";

import { barService } from "tap-io/client/services";
import ConfirmDialog from "tap-io/client/components/common/ConfirmDialog";
import BarMenuItem from "./BarMenuItem";

const styles = (theme) => ({
  accentuate: {
    color: theme.palette.error.main
  }
});

class BarLiveControlsMenuItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isConfirmSetLiveDialogOpen: false
    };
  }

  handleShowConfirmSetLiveDialog = () => {
    this.setState({ isConfirmSetLiveDialogOpen: true });
  };

  handleSetIsLiveConfirm = () => {
    this.setState({ isConfirmSetLiveDialogOpen: false });

    const { bar } = this.props;
    if (bar) {
      barService
        .toggleBarIsLive(bar)
        .then(() => {})
        .catch((error) => console.warn(error));
    }
  };

  handleSetIsLiveCancel = () => {
    this.setState({ isConfirmSetLiveDialogOpen: false });
  };

  render() {
    const { classes, t, bar, subscription, isDisabled } = this.props;
    const { isConfirmSetLiveDialogOpen } = this.state;

    const isSubscriptionExpired = subscription && subscription.isExpired();

    return (
      <>
        <ConfirmDialog
          isOpen={isConfirmSetLiveDialogOpen}
          title={t(
            bar && bar.isLive
              ? "bar.confirm-unpublishing-order-page"
              : "bar.confirm-publishing-order-page"
          )}
          description={t(
            bar && bar.isLive
              ? "bar.are-you-sure-you-want-to-unpublish-order-page"
              : "bar.are-you-sure-you-want-to-publish-order-page"
          )}
          confirmButtonLabel={t(
            bar && bar.isLive ? "bar.unpublish" : "bar.publish"
          )}
          onConfirm={this.handleSetIsLiveConfirm}
          onCancel={this.handleSetIsLiveCancel}
        />
        <BarMenuItem
          icon={bar && bar.isLive ? <VisibilityIcon /> : <VisibilityOffIcon />}
          label={t("label.live")}
          descriptin={
            isSubscriptionExpired ? (
              <span className={classes.accentuate}>
                {t("bar.renew-or-downgrade-subscription-to-publish-order-page")}
              </span>
            ) : null
          }
          secondaryAction={
            <Switch
              edge="end"
              checked={bar && bar.isLive}
              disabled={isDisabled || isSubscriptionExpired}
              onChange={this.handleShowConfirmSetLiveDialog}
            />
          }
          onClick={this.handleSignOut}
        />
      </>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(BarLiveControlsMenuItem)
);
