import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { baseService, menuService } from "tap-io/client/services";
import { deliveryHelper, serviceOptionHelper } from "tap-io/helpers";
import BasePreparationOptions from "tap-io/models/base/BasePreparationOptions";
import ServiceOptionName from "tap-io/models/service/ServiceOptionName";

import SelectDeliveryColors from "../delivery/SelectDeliveryColors";
import SelectServiceOptions from "../service/SelectServiceOptions";
import CapabilityUnavailableButton from "../subscription/CapabilityUnavailableButton";
import EditBasePreparationOptions from "./EditBasePreparationOptions";

const styles = (theme) => ({
  inputContainer: {
    display: "flex",
    alignItems: "center"
  },
  inputLabel: {
    width: "100%"
  },
  largeSpacing: {
    height: 25
  },
  smallSpacing: {
    height: 10
  },
  link: {
    marginLeft: -theme.spacing(),
    textDecoration: "none"
  },
  capabilityButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  accentuate: {
    color: theme.palette.error.main
  }
});

class EditBaseDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (base) => {
    return {
      isDisabled: false,
      name: base ? base.name : "",
      deliveryColors: base
        ? base.deliveryColors
        : deliveryHelper.createDeliveryColors([]),
      serviceOptions: base
        ? base.serviceOptions
        : serviceOptionHelper.createDefaultServiceOptions(
          deliveryHelper.createDeliveryMethods(true, false)
        ),
      preparationOptions: base
        ? base.preparationOptions
        : new BasePreparationOptions()
    };
  };

  componentDidMount() {
    const { base } = this.props;

    if (base) {
      this.setState(this.initialState(base));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen, base } = this.props;

    if (isOpen && !prevProps.isOpen) {
      this.setState(this.initialState(base));
    }
  }

  handleNameChange = (event) => {
    const val = event.target.value;
    this.setState({ name: val });
  };

  handleDeliveryColorsChange = async (deliveryColor, isActive) => {
    const deliveryColors = deliveryHelper.createDeliveryColors(
      this.state.deliveryColors.colors
    );

    if (isActive) {
      deliveryColors.add(deliveryColor);
    } else {
      deliveryColors.remove(deliveryColor);
    }

    this.setState({ deliveryColors });
  };

  handleServiceOptionChange = (serviceOption, isActive) => {
    const serviceOptions = this.state.serviceOptions.clone();

    if (isActive) {
      serviceOptions.add(serviceOption);
    } else {
      serviceOptions.remove(serviceOption);
    }

    this.setState({ serviceOptions });
  };

  handlePreparationOptionsChange = (preparationOptions) => {
    this.setState({ preparationOptions });
  };

  handleUpdateBase = async () => {
    const { t, bar, base, onClose } = this.props;
    const { name, deliveryColors, serviceOptions, preparationOptions } =
      this.state;

    const toastId = toast(t("base.updating-base"), { autoClose: false });
    this.setState({ isDisabled: true });

    try {
      const parsedName = name.trim();

      if (deliveryColors.colors.length === 0) {
        throw new Error("error.select-at-least-one-delivery-color");
      }
      if (serviceOptions.length === 0) {
        throw new Error("error.select-at-least-one-service-option");
      }

      // TOFIX: multiple menus
      if (base.menuIds.length === 1) {
        const menu = await menuService.getById(bar.id, base.menuIds[0]);
        menu.name = parsedName;
        await menuService.updateMenu(menu);
      }

      toast.update(toastId, {
        render: t("base.updating-base")
      });

      base.name = parsedName;
      base.deliveryColors = deliveryColors;
      base.serviceOptions = serviceOptions;
      base.preparationOptions = preparationOptions;

      await baseService.updateBase(
        base,
        menuService.addSetMenuIsActiveByIdInBatch
      );
      toast.update(toastId, {
        render: t("base.base-updated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false }, onClose);
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(
          error ? error.message : "error.unknown-error"
        )})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, isOpen, onClose, bar, base, subscription } = this.props;
    const {
      isDisabled,
      name,
      deliveryColors,
      serviceOptions,
      preparationOptions
    } = this.state;

    const disabledServiceOptions =
      serviceOptionHelper.createInvertedServiceOptions(
        serviceOptionHelper.createServiceOptionsByName(
          subscription?.capabilities.getAvailableServiceOptionsByName() || []
        )
      );
    const arePreparationOptionsAvailable =
      serviceOptions.has(ServiceOptionName.PICKUP) ||
      serviceOptions.has(ServiceOptionName.EXPRESS_PICKUP);

    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>{t("base.edit-base")}</DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText>{t("base.edit-base-below")}</DialogContentText>
          <TextField
            variant="standard"
            margin="dense"
            label={t("label.name")}
            fullWidth
            value={name}
            disabled={isDisabled || !base?.isEditable}
            onChange={this.handleNameChange}
          />
          <div>
            <div className={classes.largeSpacing}></div>
            <Typography variant="body1">
              <strong>
                {t(
                  "base.select-below-the-delivery-colors-allowed-for-this-base"
                )}
              </strong>
            </Typography>
            <div className={classes.smallSpacing}></div>
            <SelectDeliveryColors
              isDisabled={isDisabled}
              deliveryColors={deliveryColors.colors}
              onChange={this.handleDeliveryColorsChange}
            />
          </div>
          <div className={classes.largeSpacing}></div>
          <Typography variant="body1">
            <strong>
              {t("base.select-below-the-service-options-for-this-base")}
            </strong>
          </Typography>
          <div className={classes.smallSpacing}></div>
          <SelectServiceOptions
            isDisabled={isDisabled}
            allServiceOptions={serviceOptionHelper.createAllServiceOptions()}
            disabledServiceOptions={disabledServiceOptions}
            serviceOptions={serviceOptions}
            onChange={this.handleServiceOptionChange}
          />
          {disabledServiceOptions.length > 0 && (
            <div className={classes.capabilityButton}>
              <CapabilityUnavailableButton
                bar={bar}
                subscription={subscription}
                label={t(
                  "service.disabled-service-options-available-in-other-plans"
                )}
              />
              <div className={classes.largeSpacing}></div>
            </div>
          )}
          <div className={classes.smallSpacing}></div>
          <Typography variant="body1">
            <strong>
              {t("base.edit-below-the-preparation-options-for-this-base")}
            </strong>
            {!arePreparationOptionsAvailable && (
              <>
                <br />
                <span className={classes.accentuate}>
                  {t("base.preparation-options-only-available-for-pickup")}
                </span>
              </>
            )}
          </Typography>
          {arePreparationOptionsAvailable &&
            <EditBasePreparationOptions
              isDisabled={!arePreparationOptionsAvailable}
              preparationOptions={preparationOptions}
              onChange={this.handlePreparationOptionsChange}
            />
          }
        </DialogContent>
        <DialogActions>
          <Button disabled={isDisabled} onClick={onClose} color="secondary">
            {t("label.cancel")}
          </Button>
          <Button
            disabled={isDisabled}
            onClick={this.handleUpdateBase}
            color="primary"
          >
            {t("label.update")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(EditBaseDialog));
