import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import Moment from "moment";

import { toast } from "react-toastify";

import {
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { barService, subscriptionService } from "tap-io/client/services";

import { PLAN_LABELS } from "../../constants/plans";
import withAuthorization from "../auth/withAuthorization";
import BarInfoDialog from "./BarInfoDialog";

const styles = (theme) => ({
  spinner: {
    display: "block",
    margin: "0 auto"
  }
});

class RequestedSubscriptions extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isReady: false,
      isDisabled: false,
      barBeingShown: null,
      subscriptions: []
    };
  }

  componentDidMount() {
    this.refreshRequestedSubscriptions();
  }

  componentWillUnmount() {
    if (this.unsubscribeRequestedSubscriptions) {
      this.unsubscribeRequestedSubscriptions();
    }
  }

  refreshRequestedSubscriptions = () => {
    if (this.unsubscribeRequestedSubscriptions) {
      this.unsubscribeRequestedSubscriptions();
    }

    this.unsubscribeRequestedSubscriptions =
      subscriptionService.onRequestedSubscriptions((subscriptions) => {
        this.setState({ isReady: true, subscriptions });
      });
  };

  handleShowBarInfo = (subscription) => async (event) => {
    const { t } = this.props;

    const toastId = toast(t("mgmt.fetching-bar"), { autoClose: false });
    this.setState({ isDisabled: true });

    try {
      const bar = await barService.getBarById(subscription.barId);

      toast.dismiss(toastId);
      this.setState({ isDisabled: false, barBeingShown: bar });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleShowBarInfoDialogClose = () => {
    this.setState({ barBeingShown: null });
  };

  render() {
    const { classes, t, auth, onBarAccessChanged } = this.props;
    const { isReady, isDisabled, subscriptions, barBeingShown } = this.state;

    return (
      <div>
        <BarInfoDialog
          isOpen={barBeingShown !== null}
          onClose={this.handleShowBarInfoDialogClose}
          onBarAccessChanged={onBarAccessChanged}
          auth={auth}
          bar={barBeingShown}
        />
        <List>
          {!isReady ? (
            <CircularProgress className={classes.spinner} />
          ) : !subscriptions || subscriptions.length === 0 ? (
            <Typography>{t("mgmt.no-subscriptions-found")}</Typography>
          ) : (
            subscriptions.map((subscription) => (
              <ListItem
                key={subscription.barId}
                disabled={isDisabled}
                onClick={this.handleShowBarInfo(subscription)}
                button
              >
                <ListItemText
                  primary={t(PLAN_LABELS[subscription.planName])}
                  secondary={t("subscription.subscription-requested-on", {
                    dateTime: Moment(subscription.timestamp).format("LLLL")
                  })}
                />
              </ListItem>
            ))
          )}
        </List>
      </div>
    );
  }
}

export default withAuthorization()(
  withStyles(styles, { withTheme: true })(
    withTranslation("common")(RequestedSubscriptions)
  )
);
