import React, { PureComponent } from "react";

import withStyles from "@mui/styles/withStyles";

import Scanners from "../promo/Scanners";

const styles = (theme) => ({
  container: {
    minWidth: 600
  }
});

class EditScannersStep extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { setOnNext } = this.props;

    setOnNext(this.checkScanners);
  }

  checkScanners = () => {};

  render() {
    const { classes, bar, subscription, allBases } = this.props;

    return (
      <div className={classes.container}>
        <Scanners bar={bar} subscription={subscription} allBases={allBases} />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(EditScannersStep);
