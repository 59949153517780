import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Button, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import OrderDialog from "tap-io/client/components/order/OrderDialog";
import ModeName from "tap-io/models/mode/ModeName";

import { barService } from "tap-io/client/services";
import config from "tap-io/client/env";
import { orderHelper } from "tap-io/helpers";
import withMode from "../components/auth/withMode";
import CrewOrders from "../components/order/CrewOrders";
import withBarAuthorization from "../components/auth/withBarAuthorization";
import { hasNativeWrapper, printer } from "../native";

const styles = (theme) => ({
  content: {
    margin: theme.spacing(2)
  },
  title: {
    textAlign: "left"
  },
  largeSpacing: {
    height: 40
  },
  smallSpacing: {
    height: 10
  }
});

class CheckoutPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      orderBeingShown: null
    };
  }

  handleOrderClick = (order) => {
    if (order.hasBeenQueued()) {
      this.setState({ orderBeingShown: order });
    }
  };

  handlePrintCustomerReceipt = async (event) => {
    const { t, bar, allBases, deviceSettings, assets } = this.props;
    const { orderBeingShown } = this.state;

    try {
      if (hasNativeWrapper()) {
        const barUrl = await barService.getBarUrl(
          bar,
          config.hosting.orderDomain
        );
        const orderUrl = orderHelper.getOrderUrl(barUrl, orderBeingShown.id);

        await printer.printCustomerReceipts(
          t,
          bar,
          allBases,
          assets,
          orderBeingShown,
          orderUrl,
          deviceSettings.printBarLogo,
          1
        );
      }
    } catch (error) {
      if (error) {
        toast.error(error.message);
      }
      console.warn(error);
    }
  };

  handleOrderDialogClose = () => {
    this.setState({ orderBeingShown: null });
  };

  render() {
    const {
      classes,
      t,
      auth,
      bar,
      base,
      assets,
      allBases,
      deviceSettings,
      createOrder
    } = this.props;
    const { orderBeingShown } = this.state;

    return (
      <div className={classes.content}>
        <OrderDialog
          isOpen={orderBeingShown !== null}
          onClose={this.handleOrderDialogClose}
          auth={auth}
          bar={bar}
          base={base}
          order={orderBeingShown}
          onPrintReceipt={hasNativeWrapper() && this.handlePrintCustomerReceipt}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={createOrder}
        >
          {t("checkout.create-orders")}
        </Button>
        <div className={classes.largeSpacing} />
        <Typography variant="h6" className={classes.title}>
          {t("checkout.crew-orders-below")}
        </Typography>
        <div className={classes.smallSpacing} />
        <CrewOrders
          bar={bar}
          base={base}
          assets={assets}
          allBases={allBases}
          deviceSettings={deviceSettings}
          onOrderClick={this.handleOrderClick}
        />
      </div>
    );
  }
}

export default withBarAuthorization()(
  withMode(ModeName.CHECKOUT)(
    withStyles(styles, { withTheme: true })(
      withTranslation("common")(CheckoutPage)
    )
  )
);
