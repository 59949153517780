import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Button } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { orderService } from "tap-io/client/services";

const styles = (theme) => ({});

class SetOrderReadyForPickupButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false
    };
  }

  handleSetReadyForPickup = async () => {
    const { t, base, order, onSet } = this.props;

    this.setState({ isDisabled: true });

    const toastId = toast(t("order.modifying-preparation-option"), {
      autoClose: false
    });

    try {
      await orderService.setOrderReadyForPickup(order.barId, order.id, base.id);

      toast.update(toastId, {
        render: t("order.preparation-option-modified"),
        type: toast.TYPE.INFO,
        autoClose: 5000
      });

      this.setState({ isDisabled: false }, onSet);
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(
          error ? error.message : "error.unknown-error"
        )})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, fullWidth, variant, size, isDisabled } = this.props;

    return (
      <Button
        fullWidth={fullWidth}
        variant={variant}
        size={size}
        disabled={isDisabled || this.state.isDisabled}
        onClick={this.handleSetReadyForPickup}
      >
        {t("order.order-is-ready-to-be-picked-up")}
      </Button>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(SetOrderReadyForPickupButton)
);
