import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({});

class EditFeeZoneCondition extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { t, classes, condition } = this.props;

    return <div></div>;
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditFeeZoneCondition)
);
