import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  red: {
    color: theme.palette.error.main
  }
});

class RemoveLayoutNodeMenuItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleRemoveLayoutNode = () => {
    const { onRemove, layoutNode } = this.props;

    if (layoutNode.parent) {
      layoutNode.parent.removeChild(layoutNode);
    }

    if (onRemove) {
      onRemove(layoutNode);
    }
  };

  render() {
    const { t, classes } = this.props;

    return (
      <MenuItem onClick={this.handleRemoveLayoutNode}>
        <ListItemIcon>
          <DeleteIcon className={classes.red} />
        </ListItemIcon>
        <ListItemText
          className={classes.red}
          primary={t("zone.remove-layout-node")}
        />
      </MenuItem>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(RemoveLayoutNodeMenuItem)
);
