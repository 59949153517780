import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import WebViewDialog from "tap-io/client/components/common/WebViewDialog";

const styles = (theme) => ({});

class ScheduleMeetingDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { t, isOpen, onClose } = this.props;

    return (
      <WebViewDialog
        isOpen={isOpen}
        onClose={onClose}
        title={t("subscription.schedule-a-meeting-title")}
        url="https://calendly.com/beyondordering/intake-meeting"
      />
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(ScheduleMeetingDialog)
);
