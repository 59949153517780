import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import Creatable from "react-select/creatable";

import { Button, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { catalogueHelper } from "tap-io/helpers";
import EditProductDialog from "./EditProductDialog";

const styles = (theme) => ({
  caption: {
    display: "block",
    color: theme.palette.text.secondary
  },
  label: {
    marginBottom: theme.spacing(0.5)
  },
  helperText: {
    marginTop: theme.spacing(0.5)
  },
  editButton: {
    marginLeft: -theme.spacing(1)
  }
});

/*import { components } from "react-select";

const addCypressIdToComponent = (Component, id) => (props) => (
  <Component {...props} data-cy={id} />
);

const CUSTOM_CREATABLE_COMPONENTS = {
  Input: addCypressIdToComponent(components.Input, "item-name")
};

components={CUSTOM_CREATABLE_COMPONENTS}*/

class SelectOrCreateProduct extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isEditProductDialogOpen: false,
      options: []
    };
  }

  componentDidMount() {
    this.refreshOptionsAndValue();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { catalogue, product } = this.props;
    const { options } = this.state;

    const prevCatalogue = prevProps.catalogue;

    const productId = product ? product.id : undefined;
    const prevProductId = prevProps.product ? prevProps.product.id : undefined;

    if (!prevCatalogue && catalogue) {
      this.refreshOptionsAndValue();
    } else if (productId !== prevProductId) {
      this.refreshValue();
    }
  }

  refreshOptionsAndValue = () => {
    this.refreshOptions(this.refreshValue);
  };

  refreshOptions = (cb) => {
    const { catalogue } = this.props;

    const options = catalogue
      ? catalogue.products.map((product) => this.productToOption(product))
      : [];

    this.setState(
      {
        options
      },
      cb
    );
  };

  refreshValue = (cb) => {
    const { product } = this.props;
    const { options } = this.state;

    const value = product
      ? this.findOptionByProductId(options, product.id)
      : undefined;

    this.setState(
      {
        value
      },
      cb
    );
  };

  productToOption = (product) => {
    return {
      value: product.id,
      label: product.name
    };
  };

  findOptionByProductId = (options, productId) => {
    let option = undefined;

    if (options && productId) {
      const foundOptions = options.filter(
        (option) => option.value === productId
      );
      if (foundOptions.length > 0) {
        option = foundOptions[0];
      }
    }

    return option;
  };

  handleProductChange = (newValue, actionMeta) => {
    const { catalogue, onChange } = this.props;

    let product = null;
    switch (actionMeta.action) {
      case "create-option":
        product = catalogueHelper.createProduct(
          newValue && newValue.label ? newValue.label : ""
        );
        break;
      case "select-option":
        product = catalogue.getProduct(newValue.value);
        break;
    }
    onChange(product);
  };

  handleEditProduct = () => {
    this.setState({ isEditProductDialogOpen: true });
  };

  handleEditProductDialogClose = async () => {
    const { onChange, product } = this.props;
    const { options } = this.state;

    onChange(product);

    const value = this.productToOption(product);

    this.setState({
      isEditProductDialogOpen: false,
      options: options.map((option) => {
        if (option.value === value.value) {
          return value;
        }

        return option;
      }),
      value
    });
  };

  render() {
    const { classes, t, bar, label, helperText, product } = this.props;
    const { isEditProductDialogOpen, isDisabled, options, value } = this.state;

    return (
      <div>
        <EditProductDialog
          isOpen={isEditProductDialogOpen}
          onClose={this.handleEditProductDialogClose}
          bar={bar}
          product={product}
        />
        <div>
          {label && (
            <Typography
              className={classNames(classes.caption, classes.label)}
              variant="caption"
            >
              {label}
            </Typography>
          )}
          <Creatable
            disabled={isDisabled}
            onChange={this.handleProductChange}
            value={value}
            options={options}
            isClearable={true}
            isSearchable={true}
            maxMenuHeight={250}
            placeholder={t("catalogue.name-of-this-product")}
            formatCreateLabel={(inputValue) =>
              t("catalogue.add-product-x", { name: inputValue })
            }
          />
          {helperText && (
            <Typography
              className={classNames(classes.caption, classes.helperText)}
              variant="caption"
            >
              {helperText}
            </Typography>
          )}
        </div>
        {product && product.isCustom && (
          <Button
            color="primary"
            className={classes.editButton}
            onClick={this.handleEditProduct}
          >
            {t("catalogue.edit-product")}...
          </Button>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(SelectOrCreateProduct)
);
