import React, { PropsWithChildren } from "react";

import { barService } from "tap-io/client/services";
import Bar from "tap-io/models/bar/Bar";

import EditAsset from "./EditAsset";

type EditCoverProps = {
  bar: Bar;
  url: string;
};

function EditCover({ bar, url }: PropsWithChildren<EditCoverProps>) {
  return (
    <EditAsset
      bar={bar}
      assetUrl={url}
      setAssetFileName={(fileName) =>
        barService.setBarCoverFilename(bar, fileName)
      }
    />
  );
}

export default EditCover;
