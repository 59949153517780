import { Box, useTheme } from "@mui/material";
import { Asyncer } from "../../../components/common/Asyncer";
import { Async } from "../../../components/common/useAsync";
import { BaseReport } from "../../models/BaseReport";
import { OperationalTimeslotReport } from "../../models/OperationalTimeslotReport";
import { FailedStatChip } from "../common/FailedStatChip";
import { LoadingStatChip } from "../common/LoadingStatChip";
import { StatCard } from "../common/StatCard";
import { StatChip } from "../common/StatChip";

export type OperationalGeneralProps = {
  hour: Async<OperationalTimeslotReport>;
  base: Async<BaseReport>;
  mark: string;
  t: any;
};

export function OperationalGeneral(props: OperationalGeneralProps) {
  const theme = useTheme();
  const { hour, base, t } = props;

  const title = t("stats.stat.operational-general.title");
  const description = t("stats.stat.operational-general.description");

  return (
    <StatCard title={title} description={description}>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: theme.spacing(2)
        }}
      >
        <Asyncer
          async={hour}
          Busy={
            <LoadingStatChip
              title={t("stats.stat.operational-general.total-orders")}
            />
          }
          Failed={
            <FailedStatChip
              title={t("stats.stat.operational-general.total-orders")}
              async={hour}
            />
          }
        >
          {(report) => {
            const totalOrders = report.totalOrders;

            return (
              <StatChip
                title={t("stats.stat.operational-general.total-orders")}
                value={report.formatInteger(totalOrders)}
              />
            );
          }}
        </Asyncer>
        <Asyncer
          async={base}
          Busy={
            <LoadingStatChip
              title={t(
                "stats.stat.operational-general.total-base-preparations"
              )}
            />
          }
          Failed={
            <FailedStatChip
              title={t(
                "stats.stat.operational-general.total-base-preparations"
              )}
              async={base}
            />
          }
        >
          {(report) => {
            const totalAmountOfPreparations = report.totalAmountOfPreparations;

            return (
              <StatChip
                title={t(
                  "stats.stat.operational-general.total-base-preparations"
                )}
                value={report.formatInteger(totalAmountOfPreparations)}
              />
            );
          }}
        </Asyncer>
        <Asyncer
          async={hour}
          Busy={
            <LoadingStatChip
              title={t("stats.stat.operational-general.total-products")}
            />
          }
          Failed={
            <FailedStatChip
              title={t("stats.stat.operational-general.total-products")}
              async={hour}
            />
          }
        >
          {(report) => {
            const totalAmountOfProducts = report.totalProducts;

            return (
              <StatChip
                title={t("stats.stat.operational-general.total-products")}
                value={report.formatInteger(totalAmountOfProducts)}
              />
            );
          }}
        </Asyncer>
      </Box>
    </StatCard>
  );
}
