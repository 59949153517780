import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import SelectBeginAndEndDateTime from "../common/SelectBeginAndEndDateTime";
import Moment from "moment";
import DownloadReport from "../stats/DownloadReport";

const MAX_DAYS_BETWEEN_DATES = 100;

const styles = (theme) => ({
  dialog: {
    minWidth: 550
  }
});

class DownloadVoucherReportDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      beginDate: Moment().startOf("day").toDate(),
      endDate: Moment().endOf("day").toDate()
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.props;

    if (isOpen && !prevProps.isOpen) {
    }
  }

  handleDatesChange = (dates) => {
    const { beginDateTime, endDateTime } = dates;

    this.setState({ beginDate: beginDateTime, endDate: endDateTime });
  };

  render() {
    const { classes, t, isOpen, onClose, bar } = this.props;
    const { beginDate, endDate } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle>{t("voucher.download-voucher-report")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("voucher.download-voucher-report-below")}
          </DialogContentText>
          <div>
            <SelectBeginAndEndDateTime
              maxDaysBetweenDates={MAX_DAYS_BETWEEN_DATES}
              beginDateTime={beginDate}
              endDateTime={endDate}
              onChange={this.handleDatesChange}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {t("label.cancel")}
          </Button>
          <DownloadReport
            onClick={onClose}
            bar={bar}
            icon={null}
            label={t("voucher.download-voucher-overview")}
            reportFor="vouchers"
            beginDateTime={beginDate.toISOString()}
            endDateTime={endDate.toISOString()}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(DownloadVoucherReportDialog)
);
