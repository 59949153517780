import React, { PropsWithChildren, useEffect, useState } from "react";

import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import Bar from "tap-io/models/bar/Bar";
import Subscription from "tap-io/models/subscription/Subscription";

import EditDeposits from "../deposit/EditDeposits";
import CapabilityUnavailableButton from "../subscription/CapabilityUnavailableButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    capabilityButton: {
      marginTop: 10
    }
  })
);

type EditDepositSettingsProps = {
  bar: Bar;
  subscription: Subscription;
};

function EditDepositSettings({
  bar,
  subscription
}: PropsWithChildren<EditDepositSettingsProps>) {
  const classes = useStyles();

  const [areDepositsAvailable, setAreDepositsAvailable] = useState(false);

  useEffect(() => {
    setAreDepositsAvailable(
      subscription && subscription.capabilities.areDepositsAvailable()
    );
  }, [subscription]);

  return (
    <div>
      {areDepositsAvailable ? (
        <EditDeposits bar={bar} />
      ) : (
        <div className={classes.capabilityButton}>
          <CapabilityUnavailableButton bar={bar} subscription={subscription} />
        </div>
      )}
    </div>
  );
}

export default EditDepositSettings;
