import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="lg" fullScreen={false} />;

const styles = (theme) => ({
  dialog: {},
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20
  },
  spinner: {
    marginRight: 10
  },
  hidden: {
    visibility: "hidden",
    height: 0,
    minHeight: "0px !important"
  },
  iframe: {
    minWidth: 400,
    minHeight: 400
  }
});

class WebViewDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isReady: false
    };
  }

  handleSetIsReady = () => {
    this.setState({ isReady: true });
  };

  render() {
    const { classes, t, isOpen, onClose, title, url } = this.props;
    const { isReady } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={this.handleCancel}
        classes={{ paper: classes.dialog }}
        fullScreen
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {!isReady && (
            <div className={classes.loading}>
              <CircularProgress size={16} className={classes.spinner} />
              <Typography>{t("label.loading")}</Typography>
            </div>
          )}
          <iframe
            className={classNames(classes.iframe, {
              [classes.hidden]: !isReady
            })}
            src={url}
            onLoad={this.handleSetIsReady}
            frameBorder="0"
            width="100%"
            height="100%"
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={onClose}>
            {t("label.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withMobileDialog()(
  withStyles(styles)(withTranslation("common")(WebViewDialog))
);
