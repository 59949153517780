import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import InfiniteScroll from "react-infinite-scroll-component";

import { List } from "@mui/material";

import withStyles from "@mui/styles/withStyles";

import { voucherService } from "tap-io/client/services";

import VoucherItem from "./VoucherItem";

const VOUCHERS_INITIAL_BATCH_SIZE = 10;
const VOUCHERS_ADDITIONAL_BATCH_SIZE = 5;

const styles = (theme) => ({
  voucherList: {
    //overflowY: "scroll",
    //maxHeight: 300
  }
});

class AllVouchers extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      allVouchersMaxCount: VOUCHERS_INITIAL_BATCH_SIZE
    };

    this.voucherListRef = React.createRef();
  }

  componentDidMount() {
    const { initiallySelectedVoucherIds } = this.props;

    if (
      initiallySelectedVoucherIds &&
      initiallySelectedVoucherIds.length > VOUCHERS_INITIAL_BATCH_SIZE
    ) {
      this.setState(
        {
          allVouchersMaxCount: initiallySelectedVoucherIds.length
        },
        () => {
          this.refreshAllVouchers(initiallySelectedVoucherIds);
        }
      );
    } else {
      this.refreshAllVouchers(initiallySelectedVoucherIds);
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeAllVouchers) {
      this.unsubscribeAllVouchers();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { bar } = this.props;
    const { allVouchersMaxCount } = this.state;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;

    const prevAllVouchersMaxCount = prevState.allVouchersMaxCount;

    if (
      barId !== prevBarId ||
      allVouchersMaxCount !== prevAllVouchersMaxCount
    ) {
      this.refreshAllVouchers();
    }
  }

  refreshAllVouchers(selectedVoucherIds) {
    const { bar, onVoucherSelectChange } = this.props;
    const { allVouchersMaxCount } = this.state;

    if (this.unsubscribeAllVouchers) {
      this.unsubscribeAllVouchers();
    }

    if (bar && bar.id) {
      this.unsubscribeAllVouchers = voucherService.onAllVouchers(
        bar,
        allVouchersMaxCount,
        (vouchers) => {
          this.setState({ allVouchers: vouchers });

          if (selectedVoucherIds && selectedVoucherIds.length > 0) {
            vouchers.forEach((voucher) => {
              onVoucherSelectChange(
                voucher,
                selectedVoucherIds.indexOf(voucher.id) >= 0
              );
            });
          }
        }
      );
    } else {
      this.unsubscribeAllVouchers = undefined;
    }
  }

  handleFetchMoreVouchers = () => {
    const { allVouchersMaxCount } = this.state;

    this.setState({
      allVouchersMaxCount: allVouchersMaxCount + VOUCHERS_ADDITIONAL_BATCH_SIZE
    });
  };

  render() {
    const {
      classes,
      t,
      scrollableTarget,
      allowSelection,
      selectedVoucherIds,
      onVoucherClick,
      onVoucherSelectChange
    } = this.props;
    const { allVouchersMaxCount, allVouchers } = this.state;

    return (
      <List ref={this.voucherListRef} className={classes.voucherList}>
        {allVouchers && (
          <InfiniteScroll
            scrollableTarget={
              scrollableTarget
                ? scrollableTarget
                : this.voucherListRef && this.voucherListRef.current
                ? this.voucherListRef.current.parentElement
                : null
            }
            dataLength={allVouchers.length}
            next={this.handleFetchMoreVouchers}
            hasMore={
              allVouchers === undefined ||
              allVouchersMaxCount <= allVouchers.length
            }
            loader={<h4>{t("label.loading")}</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>{t("voucher.all-vouchers-are-shown")}</b>
              </p>
            }
          >
            {allVouchers.map((voucher) => (
              <VoucherItem
                key={voucher.id}
                voucher={voucher}
                allowSelect={allowSelection}
                isSelected={
                  selectedVoucherIds &&
                  selectedVoucherIds.indexOf(voucher.id) >= 0
                }
                onClick={onVoucherClick}
                onChange={onVoucherSelectChange}
              />
            ))}
          </InfiniteScroll>
        )}
      </List>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(AllVouchers));
