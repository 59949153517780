import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { List, ListSubheader } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import BarLink from "../promo/BarLink";
import TableNumbers from "../promo/TableNumbers";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  list: {
    width: 400
  }
});

class DownloadPromoStep extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { setOnNext } = this.props;
    setOnNext(() => {});
  }

  render() {
    const { classes, t, bar, assets, allZones } = this.props;

    return (
      <div className={classes.container}>
        <List className={classes.list}>
          <ListSubheader>{t("setup.general-qr-code")}</ListSubheader>
          <BarLink bar={bar} assets={assets} />
        </List>
        <List className={classes.list}>
          <ListSubheader>{t("setup.table-numbers")}</ListSubheader>
          <TableNumbers bar={bar} allZones={allZones} hideLinks={true} />
        </List>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(DownloadPromoStep)
);
