import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Switch
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { balanceService } from "tap-io/client/services";

import withAuthorization from "../auth/withAuthorization";
import VerifyBarBusinessDataDialog from "../data/VerifyBarBusinessDataDialog";

const styles = (theme) => ({
  dialog: {
    minWidth: 300
  }
});

class EditBarBalanceDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = () => {
    return {
      isDisabled: false,
      isVerifyBarBusinessDataDialogOpen: false
    };
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isOpen } = this.props;
    const prevIsOpen = prevProps.isOpen;

    if (isOpen && !prevIsOpen) {
      this.setState(this.initialState());
    }
  }

  handleDoNotChargeCreditsChange = async (event) => {
    const { t, auth, bar, onClose } = this.props;

    const doNotChargeCredits = event.target.checked !== true;

    if (!auth || !auth.user || !auth.user.uid) {
      return toast.error(
        `${t("label.error")}: ${t("error.no-valid-user-found")}`
      );
    }

    const toastId = toast(`${t("balance.updating-balance-props")}`, {
      autoClose: false
    });
    this.setState({ isDisabled: true });

    try {
      await balanceService.updateDoNotChargeCredits(
        auth.user.uid,
        bar,
        doNotChargeCredits
      );

      toast.update(toastId, {
        render: t("balance.balance-props-updated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false }, onClose);
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.message})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  updateAllowCreditDebt = async (allowCreditDebt) => {
    const { t, auth, bar, onClose } = this.props;

    if (!auth || !auth.user || !auth.user.uid) {
      return toast.error(
        `${t("label.error")}: ${t("error.no-valid-user-found")}`
      );
    }

    const toastId = toast(`${t("balance.updating-balance-props")}`, {
      autoClose: false
    });
    this.setState({ isDisabled: true });

    try {
      await balanceService.updateAllowCreditDebt(
        auth.user.uid,
        bar,
        allowCreditDebt
      );

      toast.update(toastId, {
        render: t("balance.balance-props-updated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false }, onClose);
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.message})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleAllowCreditDebt = async (event) => {
    const allowCreditDebt = event.target.checked === true;

    if (allowCreditDebt) {
      this.setState({ isVerifyBarBusinessDataDialogOpen: true });
    } else {
      await this.updateAllowCreditDebt(false);
    }
  };

  handleAcceptBarBusinessDataAndAllowCreditDebt = async () => {
    this.setState({ isVerifyBarBusinessDataDialogOpen: false });

    await this.updateAllowCreditDebt(true);
  };

  handleRejectBarBusinessData = () => {
    this.setState({ isVerifyBarBusinessDataDialogOpen: false });
  };

  render() {
    const { classes, t, isOpen, onClose, bar, balance } = this.props;
    const { isDisabled, isVerifyBarBusinessDataDialogOpen } = this.state;

    return (
      <div>
        <VerifyBarBusinessDataDialog
          isOpen={isVerifyBarBusinessDataDialogOpen}
          onAccept={this.handleAcceptBarBusinessDataAndAllowCreditDebt}
          onReject={this.handleRejectBarBusinessData}
          bar={bar}
          balance={balance}
        />
        <Dialog open={isOpen} onClose={onClose} className={classes.dialog}>
          <DialogTitle>{t("balance.edit-balance")}</DialogTitle>
          <DialogContent>
            <DialogContentText></DialogContentText>
            {balance && (
              <List>
                <ListItem>
                  <ListItemText primary={t("balance.charge-credits")} />
                  <Switch
                    onChange={this.handleDoNotChargeCreditsChange}
                    checked={!balance.doNotChargeCredits}
                    disabled={isDisabled}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText primary={t("balance.allow-credit-debt")} />
                  <Switch
                    onChange={this.handleAllowCreditDebt}
                    checked={balance.allowCreditDebt}
                    disabled={isDisabled}
                  />
                </ListItem>
              </List>
            )}
          </DialogContent>
          <DialogActions>
            <Button disabled={isDisabled} onClick={onClose} color="secondary">
              {t("label.close")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withAuthorization()(
  withStyles(styles)(withTranslation("common")(EditBarBalanceDialog))
);
