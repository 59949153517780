import React, { PropsWithChildren, useState } from "react";

import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  CardMedia,
  List,
  ListItem,
  ListItemText,
  Theme
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import { cloudStorage } from "tap-io/storage";
import Bar from "tap-io/models/bar/Bar";

import UploadImage from "../common/UploadImage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mediaContainer: {
      padding: "0 16px",
      textAlign: "center"
    },
    media: {
      height: 0,
      marginTop: 10,
      paddingTop: "56.25%", // 16:9
      backgroundSize: "contain"
    }
  })
);

type EditAssetProps = {
  bar: Bar;
  assetUrl: string;
  setAssetFileName: (fileName) => Promise<null>;
};

function EditAsset({
  bar,
  assetUrl,
  setAssetFileName
}: PropsWithChildren<EditAssetProps>) {
  const { t, i18n } = useTranslation("common");
  const classes = useStyles();

  const [isDisabled, setIsDisabled] = useState(false);

  const handleImageUploadSuccess = async (filename) => {
    const toastId = toast(t("settings.adding-asset"), {
      autoClose: false
    });
    setIsDisabled(true);

    try {
      await setAssetFileName(filename);

      toast.update(toastId, {
        render: t("settings.asset-added"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
      setIsDisabled(false);
    } catch (error) {
      console.warn(error);

      setIsDisabled(false);
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  const handleImageUploadError = (error) => {
    if (error) {
      toast.error(error.message);
    }
  };

  const handleAssetRemove = async () => {
    const toastId = toast(t("settings.removing-asset"), {
      autoClose: false
    });
    setIsDisabled(true);

    try {
      await setAssetFileName(null);

      toast.update(toastId, {
        render: t("settings.asset-removed"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
      setIsDisabled(false);
    } catch (error) {
      console.warn(error);

      setIsDisabled(false);
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  return (
    <div>
      {bar?.id && (
        <UploadImage
          label={t("settings.upload-new-asset")}
          isDisabled={isDisabled}
          uploadRef={cloudStorage.getBarAssetsRef(bar.id)}
          onSuccess={handleImageUploadSuccess}
          onError={handleImageUploadError}
        />
      )}
      <List>
        {assetUrl ? (
          <div className={classes.mediaContainer}>
            <CardMedia className={classes.media} image={assetUrl} />
            <Button disabled={isDisabled} onClick={handleAssetRemove}>
              {t("settings.remove-asset")}
            </Button>
          </div>
        ) : (
          <ListItem>
            <ListItemText primary={t("settings.no-asset-found")} />
          </ListItem>
        )}
      </List>
    </div>
  );
}

export default EditAsset;
