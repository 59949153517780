import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import withStyles from "@mui/styles/withStyles";

import { integrationService } from "tap-io/client/services";
import { integrationHelper } from "tap-io/helpers";

import IntegrationList from "../integration/IntegrationList";

const styles = (theme) => ({
  isLoadingContainer: {
    display: "flex",
    justifyContent: "center"
  }
});

class EditIntegrations extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (integrations) => {
    return {
      isAddIntegrationDialogOpen: false,
      integrationBeingShown: null,
      integrationBeingEdited: null,
      integrationPendingRemovalConfirmation: null,
      isReady: false,
      integrations
    };
  };

  handleAddIntegration = (event) => {
    this.setState({ isAddIntegrationDialogOpen: true });
  };

  handleAddIntegrationDialogClose = async (integrationId) => {
    const { bar } = this.props;

    const addedIntegration = integrationId
      ? await integrationService.getIntegration(bar.id, integrationId)
      : null;

    this.setState({
      isAddIntegrationDialogOpen: false,
      integrationBeingShown: addedIntegration
    });
  };

  handleShowIntegrationDialogClose = () => {
    this.setState({ integrationBeingShown: null });
  };

  handleIntegrationClick = (integration) => {
    this.setState({ integrationBeingEdited: integration });
  };

  handleEditIntegrationDialogClose = () => {
    this.setState({ integrationBeingEdited: null });
  };

  handleRemoveIntegration = (integration) => {
    this.setState({
      integrationPendingRemovalConfirmation: integration
    });
  };

  handleRemoveIntegrationConfirm = async () => {
    const { t } = this.props;
    const { integrationPendingRemovalConfirmation } = this.state;

    this.setState({ integrationPendingRemovalConfirmation: null });

    const toastId = toast(t("integration.removing-integratino"), {
      autoClose: false
    });

    try {
      await integrationService.removeIntegration(
        integrationPendingRemovalConfirmation
      );

      toast.update(toastId, {
        render: t("integration.integration-removed"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleRemoveIntegrationCancel = () => {
    this.setState({ integrationPendingRemovalConfirmation: null });
  };

  hasPartnerIntegrations = () => {
    const { integrations } = this.state;

    if (integrations) {
      for (let i = 0; i < integrations.length; i++) {
        if (integrationHelper.isPartnerIntegration(integrations[i])) {
          return true;
        }
      }
    }

    return false;
  };

  /*hasAbiStarnetIntegrations = () => {
    const { integrations } = this.state;

    if (integrations) {
      for (let i = 0; i < integrations.length; i++) {
        if (integrationHelper.isAbiIntegration(integrations[i])) {
          return true;
        }
      }
    }

    return false;
  };*/

  render() {
    const { classes, t, bar, allIntegrations } = this.props;
    const {
      isAddIntegrationDialogOpen,
      integrationBeingShown,
      integrationBeingEdited,
      integrationPendingRemovalConfirmation,
      isReady,
      integrations
    } = this.state;

    return (
      <div>
        <IntegrationList bar={bar} integrations={allIntegrations} />
      </div>
    );

    /*return (
      <div>
        <IntegrationSettings bar={bar} />
        <AddIntegrationDialog
          isOpen={isAddIntegrationDialogOpen}
          onClose={this.handleAddIntegrationDialogClose}
          bar={bar}
        />
        <ShowIntegrationDialog
          isOpen={integrationBeingShown !== null}
          onClose={this.handleShowIntegrationDialogClose}
          integration={integrationBeingShown}
        />
        <EditIntegrationDialog
          isOpen={integrationBeingEdited !== null}
          onClose={this.handleEditIntegrationDialogClose}
          bar={bar}
          integration={integrationBeingEdited}
        />
        <ConfirmDialog
          isOpen={integrationPendingRemovalConfirmation !== null}
          title={t("integration.confirm-removal-of-integration")}
          description={t(
            "integration.are-you-sure-you-want-to-remove-this-integration"
          )}
          confirmButtonLabel={t("label.remove")}
          onConfirm={this.handleRemoveIntegrationConfirm}
          onCancel={this.handleRemoveIntegrationCancel}
        />
        {isReady ? (
          <div>
            {this.hasAbiStarnetIntegrations() && (
              <AbiStarnetIntegrationsSettings bar={bar} />
            )}
            {this.hasPartnerIntegrations() && (
              <PartnerIntegrationsSettings bar={bar} />
            )}
            <IntegrationList
              bar={bar}
              integrations={integrations}
              onIntegrationClick={this.handleIntegrationClick}
              onIntegrationRemove={this.handleRemoveIntegration}
            />
            <Button fullWidth onClick={this.handleAddIntegration}>
              {t("integration.add-integration")}
            </Button>
          </div>
        ) : (
          <div className={classes.isLoadingContainer}>
            <CircularProgress />
          </div>
        )}
      </div>
    );*/
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditIntegrations)
);
