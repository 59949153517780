import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, IconButton } from "@mui/material";
import { Async } from "../../../components/common/useAsync";
import { StatCard } from "./StatCard";

export type FailedStatCardProps = {
  title?: string;
  description?: string;
  async: Async<any>;
};

export function FailedStatCard(props: FailedStatCardProps) {
  const { title, description, async } = props;

  return (
    <StatCard title={title} description={description}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <IconButton color="error" onClick={async.load}>
          <RefreshIcon />
        </IconButton>
      </Box>
    </StatCard>
  );
}
