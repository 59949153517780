import React, { PureComponent } from "react";

import withStyles from "@mui/styles/withStyles";

import { Route, Switch } from "react-router-dom";

import { cloudStorage, deviceStorage } from "tap-io/storage";
import { utilsHelper } from "tap-io/helpers";
import {
  authService,
  balanceService,
  barService,
  baseService,
  integrationService,
  menuService,
  orderService,
  subscriptionService,
  zoneService
} from "tap-io/client/services";
import { bugsnagHelper } from "tap-io/client/helpers";
import withAuthContext from "tap-io/client/components/hoc/withAuthContext";
import ModeName from "tap-io/models/mode/ModeName";
import config from "tap-io/client/env";

import { hasNativeWrapper } from "../../native";
import SetupPage from "../../pages/SetupPage";
import Bell from "../sound/Bell";
import BarSetting from "./BarSetting";
import VerifyPasswordDialog from "../auth/VerifyPasswordDialog";
import * as routes from "../../constants/routes";
import { DEFAULT_MODE, MODES } from "../../constants/modes";
import withDevice from "../auth/withDevice";
import SelectBarPage from "../../pages/SelectBarPage";

const styles = (theme) => ({});

class BarWrapper extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isVerifyPasswordDialogOpen: false,
      isAppLocked: true,
      timeOffset: 0
    };

    this.bellRef = React.createRef();
  }

  componentDidMount() {
    const { setUnlockApp, barId } = this.props;

    setUnlockApp(this.unlockApp);

    if (barId) {
      this.setBarId(barId);
    }

    // Pricing
    this.unsubscribePricing = balanceService.onPricing((pricing) => {
      this.setState({ pricing });
    });

    // Time
    this.setTimeOffset();
  }

  componentDidUpdate(prevProps, prevState) {
    // barId
    const propsBarId = this.props.barId;
    const prevPropsBarId = prevProps.barId;
    if (propsBarId !== prevPropsBarId) {
      this.setBarId(propsBarId);
    }

    const { mode, bar, base } = this.state;

    const modeName = mode ? mode.name : undefined;
    const prevModeName = prevState.mode ? prevState.mode.name : undefined;
    const barId = bar ? bar.id : undefined;
    const prevBarId = prevState.bar ? prevState.bar.id : undefined;
    const menuIds =
      bar && bar.isUsingBases() && base ? base.menuIds : undefined;
    const prevMenuIds =
      prevState.bar && prevState.bar.isUsingBases() && prevState.base
        ? prevState.base.menuIds
        : undefined;

    if (
      modeName !== prevModeName ||
      barId !== prevBarId ||
      !utilsHelper.areArraysEqualShallow(menuIds, prevMenuIds)
    ) {
      this.refreshOpenOrders();
    }
  }

  componentWillUnmount() {
    const { setUnlockApp } = this.props;

    setUnlockApp(null);

    this.clearBarId();

    if (this.unsubscribePricing) {
      this.unsubscribePricing();
      this.unsubscribePricing = undefined;
    }
  }

  unlockApp = (authenticatedUser, password) => {
    const { isAppLocked, isVerifyPasswordDialogOpen } = this.state;

    return new Promise((resolve, reject) => {
      if (isAppLocked) {
        if (authenticatedUser && password) {
          // Unlock with provided user & password
          authService
            .verifyPassword(authenticatedUser, password)
            .then((isVerified) => {
              this.setState(
                {
                  isAppLocked: !isVerified,
                  isVerifyPasswordDialogOpen: false
                },
                resolve
              );
            })
            .catch(reject);
        } else {
          // Show dialog to request user & password
          if (isVerifyPasswordDialogOpen) {
            return reject("isVerifyPasswordDialogOpen === TRUE");
          }

          this.setState({ isVerifyPasswordDialogOpen: true });

          this.verifyPasswordDialogSuccessCallback = resolve;
          this.verifyPasswordDialogCancelCallback = reject;
        }
      } else {
        resolve();
      }
    });
  };

  lockApp = () => {
    return new Promise((resolve, reject) => {
      this.setState({ isAppLocked: true }, resolve);
    });
  };

  handlePasswordVerificationSuccess = () => {
    this.setState(
      {
        isVerifyPasswordDialogOpen: false,
        isAppLocked: false
      },
      this.verifyPasswordDialogSuccessCallback
    );
    this.verifyPasswordDialogSuccessCallback = null;
  };

  handlePasswordVerificationCancel = () => {
    this.setState(
      {
        isVerifyPasswordDialogOpen: false
      },
      this.verifyPasswordDialogCancelCallback
    );
    this.verifyPasswordDialogCancelCallback = null;
  };

  setBarId = async (barId) => {
    const { auth, setThemeColors, setLocale } = this.props;

    await this.clearBarId();

    // bar
    if (barId) {
      this.unsubscribeBar = barService.onBarById(barId, async (bar) => {
        // Bugsnag
        bugsnagHelper.setBar(bar);

        const prevBar = this.state.bar;

        this.setState({ bar });

        // Color
        const prevPrimaryColor = prevBar
          ? prevBar.getPrimaryColor()
          : undefined;
        const primaryColor = bar.getPrimaryColor();
        const prevSecondaryColor = prevBar
          ? prevBar.getSecondaryColor()
          : undefined;
        const secondaryColor = bar.getSecondaryColor();
        if (
          primaryColor !== prevPrimaryColor ||
          secondaryColor !== prevSecondaryColor
        ) {
          setThemeColors(primaryColor, secondaryColor);
        }

        // Language
        const appLocale = bar.getAppLocale();
        const prevAppLocale = prevBar ? prevBar.getAppLocale() : undefined;
        if (appLocale !== prevAppLocale) {
          setLocale(appLocale);
        }
        bar.locale = appLocale;

        // Assets
        const prevLogoFileName = prevBar
          ? prevBar.getLogoFileName()
          : undefined;
        const logoFileName = bar.getLogoFileName();
        if (logoFileName !== prevLogoFileName) {
          let logoImageUrl = null;

          if (logoFileName) {
            try {
              logoImageUrl = await cloudStorage.getBarLogoImageUrl(bar);
            } catch (error) {
              console.warn(error);
            }
          }

          this.setState({ assets: { ...this.state.assets, logoImageUrl } });
        }

        // Assets for printing (base64)
        if (hasNativeWrapper()) {
          // Logo
          if (logoFileName !== prevLogoFileName) {
            let logoImageBase64 = null;

            if (logoFileName && auth && auth.user) {
              try {
                logoImageBase64 = await barService.getBarLogoImageAsBase64(
                  config.functions.api,
                  auth.user,
                  bar
                );
              } catch (error) {
                console.warn(error);
              }
            }

            this.setState({
              assets: { ...this.state.assets, logoImageBase64 }
            });
          }

          // Payconiq Merchant QR Code
          const prevPayconiqMerchantLink = prevBar
            ? prevBar.params.payconiqMerchantLink
            : undefined;
          const payconiqMerchantLink = bar.params.payconiqMerchantLink;
          if (payconiqMerchantLink !== prevPayconiqMerchantLink) {
            let payconiqMerchantQRCodeBase64 = null;

            if (payconiqMerchantLink && auth && auth.user) {
              try {
                payconiqMerchantQRCodeBase64 =
                  await barService.getBarPayconiqMerchantQRCodeImageAsBase64(
                    config.functions.api,
                    auth.user,
                    bar
                  );
              } catch (error) {
                console.warn(error);
              }
            }

            this.setState({
              assets: { ...this.state.assets, payconiqMerchantQRCodeBase64 }
            });
          }
        }

        if (!bar.isUsingBases()) {
          this.clearBaseId();
        }
      });

      // Settings
      this.unsubscribeDeviceSettings = deviceStorage.onDeviceSettings(
        barId,
        (deviceSettings) => {
          const prevDeviceSettings = this.state.deviceSettings;

          const modeName = deviceSettings ? deviceSettings.modeName : undefined;
          const prevModeName = prevDeviceSettings
            ? prevDeviceSettings.modeName
            : undefined;
          const baseId = deviceSettings ? deviceSettings.baseId : undefined;
          const prevBaseId = prevDeviceSettings
            ? prevDeviceSettings.baseId
            : undefined;

          const mode =
            modeName && MODES[modeName] ? MODES[modeName] : DEFAULT_MODE;

          this.setState(
            {
              deviceSettings: deviceSettings || {},
              mode
            },
            this.clearDeviceSettingIfModeIsUnavailable
          );

          // TO FIX ?
          if (mode.name === ModeName.ORDER) {
            if (baseId !== prevBaseId || modeName !== prevModeName) {
              if (this.unsubscribeBase) {
                this.unsubscribeBase();
                this.unsubscribeBase = undefined;
              }

              if (baseId) {
                this.unsubscribeBase = baseService.onBaseById(
                  barId,
                  baseId,
                  (base) => {
                    // Clear stored baseId if base does not exist (anymore)
                    if (!base) {
                      this.clearBaseId();
                    }

                    this.setState({ base: base ? base : null });
                  }
                );
              } else {
                this.setState({ base: null });
              }
            }
          } else {
            if (this.unsubscribeBase) {
              this.unsubscribeBase();
              this.unsubscribeBase = undefined;
            }

            this.setState({ base: null });
          }
        }
      );

      // Subscription
      this.unsubscribeSubscription = subscriptionService.onSubscriptionById(
        barId,
        (subscription) => {
          this.setState(
            { subscription },
            this.clearDeviceSettingIfModeIsUnavailable
          );
        }
      );

      // Menu
      this.unsubscribeMenus = menuService.onAllMenus(barId, (allMenus) => {
        this.setState({ allMenus });
      });

      // Bases
      this.unsubscribeBases = baseService.onAllBases(barId, (allBases) => {
        this.setState({ allBases });
      });

      // Zones
      this.unsubscribeZones = zoneService.onAllZones(barId, (allZones) => {
        this.setState({ allZones });
      });

      // Integrations
      this.unsubscribeIntegrations = integrationService.onAllIntegrations(
        barId,
        (allIntegrations) => {
          this.setState({ allIntegrations });
        }
      );

      // Balance
      this.unsubscribeBalance = balanceService.onBalanceById(
        barId,
        (balance) => {
          this.setState({ balance });
        }
      );
    }
  };

  clearBarId = () => {
    return new Promise((resolve, reject) => {
      // Bugsnag
      bugsnagHelper.clearBar();

      // Unsubscribe
      if (this.unsubscribeBar) {
        this.unsubscribeBar();
        this.unsubscribeBar = undefined;
      }
      if (this.unsubscribeDeviceSettings) {
        this.unsubscribeDeviceSettings();
        this.unsubscribeDeviceSettings = undefined;
      }
      if (this.unsubscribeSubscription) {
        this.unsubscribeSubscription();
        this.unsubscribeSubscription = undefined;
      }
      if (this.unsubscribeMenus) {
        this.unsubscribeMenus();
        this.unsubscribeMenus = undefined;
      }
      if (this.unsubscribeZones) {
        this.unsubscribeZones();
        this.unsubscribeZones = undefined;
      }
      if (this.unsubscribeIntegrations) {
        this.unsubscribeIntegrations();
        this.unsubscribeIntegrations = undefined;
      }
      if (this.unsubscribeBases) {
        this.unsubscribeBases();
        this.unsubscribeBases = undefined;
      }
      if (this.unsubscribeBase) {
        this.unsubscribeBase();
        this.unsubscribeBase = undefined;
      }
      if (this.unsubscribeBalance) {
        this.unsubscribeBalance();
        this.unsubscribeBalance = undefined;
      }
      if (this.unsubscribeOpenOrders) {
        this.unsubscribeOpenOrders();
        this.unsubscribeOpenOrders = undefined;
      }

      this.setState(
        {
          mode: null,
          bar: null,
          deviceSettings: null,
          subscription: null,
          allMenus: null,
          allBases: null,
          base: null,
          allZones: null,
          allIntegrations: null,
          balance: null,
          assets: null,
          openOrders: null,
          latestOrderTimestamp: null
        },
        resolve
      );
    });
  };

  setTimeOffset = async () => {
    const { auth } = this.props;

    if (auth && auth.user) {
      const timeOffset = await barService.getTimeOffset(
        config.functions.api,
        auth.user
      );

      this.setState({ timeOffset });
    }
  };

  playBell = () => {
    if (this.bellRef && this.bellRef.current) {
      this.bellRef.current.playBell();
    } else {
      console.warn("this.bellRef is not defined");
    }
  };

  setMuted = (isMuted) => {
    const { bar } = this.state;

    if (bar) {
      deviceStorage
        .setDeviceSetting(bar.id, "isMuted", isMuted)
        .then(() => {})
        .catch((error) => {
          console.warn("setDeviceSetting isMuted error:", error);
        });
    }
  };

  toggleMuted = () => {
    const { deviceSettings } = this.state;
    this.setMuted(!(deviceSettings && deviceSettings.isMuted === true));
  };

  setBaseId = async (baseId) => {
    const { bar } = this.state;

    if (bar) {
      await deviceStorage.setDeviceSetting(bar.id, "baseId", baseId);
    }
  };

  clearBaseId = async () => {
    const { bar } = this.state;

    if (bar) {
      await deviceStorage.clearDeviceSetting(bar.id, "baseId");
    }
  };

  refreshOpenOrders = () => {
    const { mode, bar, base } = this.state;

    if (this.unsubscribeOpenOrders) {
      this.unsubscribeOpenOrders();
    }

    if (
      mode &&
      mode.fetchOpenOrders &&
      bar &&
      bar.id &&
      (!bar.isUsingBases() || base)
    ) {
      this.unsubscribeOpenOrders = orderService.onOpenOrders(
        bar.id,
        (openOrders) => {
          const { latestOrderTimestamp } = this.state;

          const newState = { openOrders };

          if (openOrders && openOrders.length > 0) {
            const latestOrder = openOrders[openOrders.length - 1];
            if (
              !latestOrderTimestamp ||
              latestOrder.timestamp > latestOrderTimestamp
            ) {
              newState.latestOrderTimestamp = latestOrder.timestamp;
              this.playBell();
            }
          }

          this.setState(newState);
        },
        base ? base.menuIds : undefined
      );
    } else {
      this.setState({
        openOrders: null,
        latestOrderTimestamp: null
      });
    }
  };

  clearDeviceSettingIfModeIsUnavailable = async () => {
    const { bar, subscription, mode } = this.state;

    if (bar && subscription && mode) {
      if (!subscription.capabilities.isModeAvailable(mode.name)) {
        await deviceStorage.clearDeviceSetting(bar.id, "modeName");
      }
    }
  };

  render() {
    const { barIds, barId, setBarId } = this.props;
    const {
      isVerifyPasswordDialogOpen,
      isAppLocked,
      timeOffset,
      deviceSettings,
      mode,
      bar,
      base,
      assets,
      subscription,
      allMenus,
      allBases,
      allZones,
      allIntegrations,
      balance,
      pricing,
      openOrders
    } = this.state;

    // TO FIX: wildcard route

    const isAbleToSelectBar = barIds && barIds.length > 0;
    const isBarSelected = !!barId;

    return isAbleToSelectBar && !isBarSelected ? (
      <SelectBarPage barIds={barIds} barId={barId} setBarId={setBarId} />
    ) : (
      <div>
        <VerifyPasswordDialog
          isOpen={isVerifyPasswordDialogOpen}
          onSuccess={this.handlePasswordVerificationSuccess}
          onCancel={this.handlePasswordVerificationCancel}
        />
        <Switch>
          <Route
            exact
            path={`*${routes.SETUP}`}
            render={(params) => (
              <SetupPage
                isAppLocked={isAppLocked}
                bar={bar}
                assets={assets}
                subscription={subscription}
                allBases={allBases}
                allMenus={allMenus}
                allZones={allZones}
                pricing={pricing}
                lockApp={this.lockApp}
                unlockApp={this.unlockApp}
              />
            )}
          />
          <Route
            render={(params) => (
              <div>
                {deviceSettings && (
                  <Bell ref={this.bellRef} isMuted={deviceSettings.isMuted} />
                )}
                <BarSetting
                  barIds={barIds}
                  isAppLocked={isAppLocked}
                  timeOffset={timeOffset}
                  deviceSettings={deviceSettings}
                  mode={mode}
                  bar={bar}
                  assets={assets}
                  base={base}
                  subscription={subscription}
                  balance={balance}
                  pricing={pricing}
                  allMenus={allMenus}
                  allBases={allBases}
                  allZones={allZones}
                  allIntegrations={allIntegrations}
                  openOrders={openOrders}
                  setBarId={setBarId}
                  lockApp={this.lockApp}
                  unlockApp={this.unlockApp}
                  setBaseId={this.setBaseId}
                  toggleMuted={this.toggleMuted}
                />
              </div>
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withStyles(styles)(withAuthContext(withDevice()(BarWrapper)));
