import { useMemo } from "react";

import { Alert, Box, Divider, Stack, Tab, Tabs, useTheme } from "@mui/material";

import { createAsync } from "../../components/common/useAsync";
import { StatGroup } from "./common/StatGroup";
import { AmountOfDepositsCollected } from "./cards/AmountOfDepositsCollected";
import { AmountOfDepositsDistributed } from "./cards/AmountOfDepositsDistributed";
import { AmountOfDepositsUnCollected } from "./cards/AmountOfDepositsUnCollected";
import { AmountOfOrdersPerDevice } from "./cards/AmountOfOrdersPerDevice";
import { AmountOfOrdersPerHour } from "./cards/AmountOfOrdersPerHour";
import { AmountOfOrdersPerLocation } from "./cards/AmountOfOrdersPerLocation";
import { AmountOfOrdersPerService } from "./cards/AmountOfOrdersPerService";
import { AmountOfPreparationsPerBase } from "./cards/AmountOfPreparationsPerBase";
import { AmountOfProductsPerBase } from "./cards/AmountOfProductsPerBase";
import { AmountOfProductsPerDevice } from "./cards/AmountOfProductsPerDevice";
import { AmountOfProductsPerHour } from "./cards/AmountOfProductsPerHour";
import { AmountOfProductsPerLocation } from "./cards/AmountOfProductsPerLocation";
import { AmountOfProductsPerService } from "./cards/AmountOfProductsPerService";
import { AmountOfProductsSold } from "./cards/AmountOfProductsSold";
import { InclusionOfProducts } from "./cards/InclusionOfProducts";
import { OperationalGeneral } from "./cards/OperationalGeneral";
import { OperationalReportDownloads } from "./cards/OperationalReportDownloads";
import {
  fetchBaseReport,
  fetchDepositReport,
  fetchOperationalDeviceReport,
  fetchOperationalLocationReport,
  fetchOperationalTimeslotReport,
  fetchProductReport,
  fetchServiceReport
} from "../services/StatService";

function OperationalStats(props: {
  organisationId: string;
  baseId: string | undefined;
  period: [begin: Date, end: Date];
  user: any;
  t: any;
}) {
  const theme = useTheme();
  const { organisationId, baseId, period, user, t } = props;

  const filter = useMemo(() => {
    return {
      organisationId,
      eventId: organisationId,
      baseId: baseId,
      from: period[0],
      to: period[1]
    };
  }, [organisationId, period, baseId]);

  const mark = `${period[0].toLocaleString("nl-BE")}-${period[1].toLocaleString(
    "nl-BE"
  )}${baseId ? `-${baseId}` : ""} `;

  const baseReports = createAsync(fetchBaseReport, true)(user, filter);

  const locationReports = createAsync(fetchOperationalLocationReport, true)(
    user,
    filter
  );

  const productReports = createAsync(fetchProductReport, true)(user, filter);

  const deviceReports = createAsync(fetchOperationalDeviceReport, true)(
    user,
    filter
  );

  const serviceReports = createAsync(fetchServiceReport, true)(user, filter);

  const hourReports = createAsync(fetchOperationalTimeslotReport, true)(
    user,
    filter
  );

  const depositReports = createAsync(fetchDepositReport, true)(user, filter);

  return (
    <>
      <Stack spacing={10}>
        <StatGroup>
          <OperationalGeneral
            hour={hourReports}
            base={baseReports}
            mark={mark}
            t={t}
          />
        </StatGroup>

        <StatGroup>
          <AmountOfProductsSold async={productReports} mark={mark} t={t} />
          <InclusionOfProducts async={productReports} mark={mark} t={t} />
        </StatGroup>

        {!baseId && (
          <>
            <StatGroup>
              <AmountOfDepositsDistributed
                async={depositReports}
                mark={mark}
                t={t}
              />
              <AmountOfDepositsCollected
                async={depositReports}
                mark={mark}
                t={t}
              />
              <AmountOfDepositsUnCollected
                async={depositReports}
                mark={mark}
                t={t}
              />
            </StatGroup>
          </>
        )}

        {!baseId && (
          <>
            <StatGroup>
              <AmountOfPreparationsPerBase
                async={baseReports}
                mark={mark}
                t={t}
              />
              <AmountOfProductsPerBase async={baseReports} mark={mark} t={t} />
            </StatGroup>
          </>
        )}

        <StatGroup>
          <AmountOfOrdersPerLocation
            async={locationReports}
            mark={mark}
            t={t}
          />
          <AmountOfProductsPerLocation
            async={locationReports}
            mark={mark}
            t={t}
          />
        </StatGroup>

        <StatGroup>
          <AmountOfOrdersPerService async={serviceReports} mark={mark} t={t} />
          <AmountOfProductsPerService
            async={serviceReports}
            mark={mark}
            t={t}
          />
        </StatGroup>

        <StatGroup>
          <AmountOfOrdersPerDevice async={deviceReports} mark={mark} t={t} />
          <AmountOfProductsPerDevice async={deviceReports} mark={mark} t={t} />
        </StatGroup>

        <StatGroup>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing()
            }}
          >
            <AmountOfOrdersPerHour
              async={hourReports}
              date={period[0]}
              mark={mark}
              t={t}
            />
            <AmountOfProductsPerHour
              async={hourReports}
              date={period[0]}
              mark={mark}
              t={t}
            />
          </Box>
        </StatGroup>

        <StatGroup>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing()
            }}
          >
            <Divider />
            <OperationalReportDownloads
              product={productReports}
              deposit={depositReports}
              base={baseReports}
              location={locationReports}
              device={deviceReports}
              hour={hourReports}
              date={period[0]}
              mark={mark}
              t={t}
            />
          </Box>
        </StatGroup>
      </Stack>
    </>
  );
}

export default OperationalStats;
