import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import withStyles from "@mui/styles/withStyles";

import LayoutNodeType from "tap-io/models/zone/LayoutNodeType";

import EditLayoutNodeCodeDialog from "./EditLayoutNodeCodeDialog";
import SelectLayoutNodeBases from "./SelectLayoutNodeBases";
import { ZONE_MAX_BASES_COUNT } from "../../constants/zones";

const styles = (theme) => ({
  smallSpacing: {
    height: 10
  },
  largeSpacing: {
    height: 20
  },
  inputContainer: {
    display: "flex",
    alignItems: "center"
  },
  inputLabel: {
    width: "100%"
  },
  inputFieldWithButton: {
    display: "flex",
    width: "100%"
  },
  inputButton: {
    marginLeft: theme.spacing()
  },
  customOptions: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  }
});

class EditLayoutNodeBasics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditLayoutNodeCodeDialogOpen: false
    };
  }

  handleEditCode = () => {
    this.setState({
      isEditLayoutNodeCodeDialogOpen: true
    });
  };

  handleEditLayoutNodeCodeDialogClose = () => {
    this.setState({ isEditLayoutNodeCodeDialogOpen: false });
  };

  handleNameChange = (event) => {
    const { layoutNode, onUpdate } = this.props;

    layoutNode.name = event.target.value;
    onUpdate(layoutNode);
  };

  handleUseCustomBasesChange = (event) => {
    const { layoutNode, onUpdate } = this.props;

    const value = event.target.checked === true;
    layoutNode.customBases = value ? null : layoutNode.bases.clone();
    onUpdate(layoutNode);
  };

  handleBasesChange = (bases) => {
    const { onUpdate, layoutNode } = this.props;
    layoutNode.customBases = bases;
    onUpdate(layoutNode);
  };

  render() {
    const { classes, t, isDisabled, bar, allBases, layoutNode, onUpdate } =
      this.props;
    const { parent, type, code, name, bases, customBases } = layoutNode;
    const { isEditLayoutNodeCodeDialogOpen } = this.state;

    const basesToEdit = customBases ? customBases : bases;

    return (
      <div>
        <EditLayoutNodeCodeDialog
          isOpen={isEditLayoutNodeCodeDialogOpen}
          onClose={this.handleEditLayoutNodeCodeDialogClose}
          layoutNode={layoutNode}
          onUpdate={onUpdate}
        />
        <div className={classes.inputContainer}>
          <Typography className={classes.inputLabel}>
            {t("zone.code-for-this-node")}
          </Typography>
          <div className={classes.inputFieldWithButton}>
            <TextField
              variant="standard"
              margin="dense"
              placeholder={t("zone.node-code")}
              fullWidth
              disabled
              value={code}
            />
            {type !== LayoutNodeType.ZONE && (
              <IconButton
                className={classes.inputButton}
                onClick={this.handleEditCode}
                size="large"
              >
                <EditIcon />
              </IconButton>
            )}
          </div>
        </div>
        <div className={classes.inputContainer}>
          <Typography className={classes.inputLabel}>
            {t("zone.name-for-this-node")}
          </Typography>
          <TextField
            variant="standard"
            margin="dense"
            placeholder={t("zone.node-name")}
            fullWidth
            disabled={isDisabled}
            value={name}
            onChange={this.handleNameChange}
          />
        </div>
        <div className={classes.largeSpacing}></div>
        {bar.isUsingBases() && (
          <div>
            <div className={classes.largeSpacing}></div>
            <div>
              <Typography variant="body1">
                {t("zone.select-bases-service-options-linked-to-this-node")}
              </Typography>
              <div className={classes.smallSpacing}></div>
              {parent && (
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={customBases === null}
                        onChange={this.handleUseCustomBasesChange}
                        color="primary"
                      />
                    }
                    label={t("zone.use-bases-of-parent-node")}
                  />
                </FormControl>
              )}
              <div className={classes.customOptions}>
                <SelectLayoutNodeBases
                  bar={bar}
                  allBases={allBases}
                  layoutNodeBases={basesToEdit}
                  isDisabled={parent && customBases === null}
                  onChange={this.handleBasesChange}
                  maxSelectedBases={ZONE_MAX_BASES_COUNT}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditLayoutNodeBasics)
);
