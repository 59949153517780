import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Helmet } from "react-helmet";

import { toast } from "react-toastify";

import withStyles from "@mui/styles/withStyles";

import FulfilmentMethod from "tap-io/models/fulfilment/FulfilmentMethod";

import withBarAuthorization from "../auth/withBarAuthorization";
import BarHeader from "./BarHeader";
import BarOrderDrawer from "./BarOrderDrawer";
import BarCrewDrawer from "./BarCrewDrawer";
import BarBasicMenu from "./BarBasicMenu";
import SelectBaseDialog from "../base/SelectBaseDialog";
import CreateOrderDialog from "../order/CreateOrderDialog";
import CreditsWarning from "../credits/CreditsWarning";
import BarNotLiveOrBaseNotActiveWarning from "./BarNotLiveOrBaseNotActiveWarning";
import BarContent from "./BarContent";
import { hasNativeWrapper, printer } from "../../native";

const styles = (theme) => ({});

const DRAWER_WIDTH = 360;

class BarSetting extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOrderDrawerOpen: false,
      isMenuDrawerOpen: false,
      isCreateOrderDialogOpen: false,
      isSelectBaseDialogOpen: false
    };
  }

  componentDidMount() {
    const { isAppLocked } = this.props;

    this.setState({ isMenuDrawerOpen: !isAppLocked });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { bar, isAppLocked, deviceSettings, mode, allBases, openOrders } =
      this.props;
    const { isMenuDrawerOpen, isSelectBaseDialogOpen } = this.state;
    const prevOpenOrders = prevProps.openOrders;

    if (!isAppLocked && !isMenuDrawerOpen) {
      this.setState({ isMenuDrawerOpen: true });
    }

    // When deviceSettings are loaded but baseId is not set, open the SelectBaseDialog (if not already open and if the bar & mode is using bases and if bases are available)
    if (
      bar &&
      bar.isUsingBases() &&
      mode &&
      mode.isUsingBases &&
      deviceSettings &&
      !deviceSettings.baseId &&
      allBases &&
      allBases.length > 0 &&
      !isSelectBaseDialogOpen
    ) {
      this.setState({ isSelectBaseDialogOpen: true });
    }

    this.checkNewlyClaimedOrdersAndPrintIfNeeded(openOrders, prevOpenOrders);
  }

  checkNewlyClaimedOrdersAndPrintIfNeeded = async (
    openOrders,
    prevOpenOrders
  ) => {
    const { t, bar, base, assets, deviceSettings } = this.props;

    if (
      deviceSettings &&
      deviceSettings.printOrderWhenClaimed &&
      openOrders &&
      prevOpenOrders
    ) {
      const prevQueuedOrderIdsMap = {};

      for (let i = 0; i < prevOpenOrders.length; i++) {
        const prevOrder = prevOpenOrders[i];

        if (prevOrder.isStatusQueued(base)) {
          // This order was (and might still be) queued
          prevQueuedOrderIdsMap[prevOrder.id] = true;
        }
      }

      for (let i = 0; i < openOrders.length; i++) {
        const order = openOrders[i];

        if (prevQueuedOrderIdsMap[order.id] && order.isStatusClaimed(base)) {
          // This order was queued and is claimed now
          if (
            order.fulfilment.method === FulfilmentMethod.AS_SOON_AS_POSSIBLE ||
            deviceSettings.printOrderWhenClaimedWithScanner
          ) {
            await printer.printReceipt(
              t,
              bar,
              base,
              assets,
              order,
              deviceSettings.printBarLogo,
              deviceSettings.printOrderCustomerFields,
              deviceSettings.printAmountOfCopies
            );
          }
        }
      }
    }
  };

  handleOrdersButtonClick = (event) => {
    this.setState({ isOrderDrawerOpen: true });
  };

  handleOrderDrawerClose = () => {
    this.setState({ isOrderDrawerOpen: false });
  };

  handleCreateOrderButtonClick = (event) => {
    const { t, balance } = this.props;

    if (balance.isSufficient()) {
      this.handleCreateOrder();
    } else {
      toast.error(
        t("credits.your-credit-balance-is-insufficient-top-up-to-create-orders")
      );
    }
  };

  handleCreateOrder = () => {
    this.setState({
      isCreateOrderDialogOpen: true
    });
  };

  handleCreateOrderDialogClose = () => {
    this.setState({ isCreateOrderDialogOpen: false });
  };

  handleSettingsButtonClick = (event) => {
    const { settingsMenuAnchor } = this.state;

    if (settingsMenuAnchor) {
      this.setState({ settingsMenuAnchor: null });
    } else {
      this.setState({ settingsMenuAnchor: event.currentTarget });
    }
  };

  handleMenuDrawerClose = () => {
    const { lockApp } = this.props;

    this.setState({ isMenuDrawerOpen: false });

    lockApp()
      .then(() => {})
      .catch((error) => console.warn(error));
  };

  handleSettingsMenuClose = () => {
    this.setState({ settingsMenuAnchor: null });
  };

  handleSelectBaseDialogOpenClick = () => {
    this.setState({ isSelectBaseDialogOpen: true });
  };

  handleSelectBaseDialogClose = () => {
    this.setState({ isSelectBaseDialogOpen: false });
  };

  handleSelectBase = (baseId) => {
    const { setBaseId } = this.props;
    setBaseId(baseId);

    this.handleSelectBaseDialogClose();
  };

  handleUnlockApp = () => {
    const { unlockApp } = this.props;

    unlockApp()
      .then(() => {
        this.setState({ isMenuDrawerOpen: true });
      })
      .catch((error) => console.warn(error));
  };

  render() {
    const {
      barIds,
      isAppLocked,
      timeOffset,
      deviceSettings,
      mode,
      bar,
      assets,
      base,
      subscription,
      balance,
      pricing,
      allMenus,
      allBases,
      allZones,
      allIntegrations,
      openOrders,
      setBarId,
      toggleMuted,
      lockApp,
      unlockApp
    } = this.props;
    const {
      isOrderDrawerOpen,
      isMenuDrawerOpen,
      settingsMenuAnchor,
      isCreateOrderDialogOpen,
      isSelectBaseDialogOpen
    } = this.state;

    return bar ? (
      <div>
        <Helmet htmlAttributes={{ lang: bar.getAppLang() }}>
          {bar && bar.name && <title>{bar.name}</title>}
        </Helmet>
        <BarHeader
          drawerWidth={DRAWER_WIDTH}
          isOrderDrawerOpen={isOrderDrawerOpen}
          isMenuDrawerOpen={isMenuDrawerOpen}
          onOrdersButtonClick={this.handleOrdersButtonClick}
          onCreateOrderButtonClick={this.handleCreateOrderButtonClick}
          onSettingsButtonClick={this.handleSettingsButtonClick}
          onSelectBaseButtonClick={this.handleSelectBaseDialogOpenClick}
          isAppLocked={isAppLocked}
          timeOffset={timeOffset}
          mode={mode}
          bar={bar}
          subscription={subscription}
          base={base}
          balance={balance}
          assets={assets}
          deviceSettings={deviceSettings}
          openOrders={openOrders}
          unlockApp={unlockApp}
        />
        <BarOrderDrawer
          width={DRAWER_WIDTH}
          isOpen={isOrderDrawerOpen}
          onClose={this.handleOrderDrawerClose}
          bar={bar}
          base={base}
          assets={assets}
          deviceSettings={deviceSettings}
        />
        <BarCrewDrawer
          width={DRAWER_WIDTH}
          isOpen={isMenuDrawerOpen}
          onClose={this.handleMenuDrawerClose}
          barIds={barIds}
          mode={mode}
          bar={bar}
          subscription={subscription}
          base={base}
          assets={assets}
          balance={balance}
          deviceSettings={deviceSettings}
          setBarId={setBarId}
          toggleMuted={toggleMuted}
        />
        <BarBasicMenu
          anchor={settingsMenuAnchor}
          onClose={this.handleSettingsMenuClose}
          mode={mode}
          bar={bar}
          base={base}
          assets={assets}
          balance={balance}
          deviceSettings={deviceSettings}
          toggleMuted={toggleMuted}
          unlockApp={this.handleUnlockApp}
        />
        <SelectBaseDialog
          isOpen={isSelectBaseDialogOpen}
          onClose={this.handleSelectBaseDialogClose}
          onSelectBase={this.handleSelectBase}
          isAppLocked={isAppLocked}
          mode={mode}
          bar={bar}
          base={base}
          allBases={allBases}
          allZones={allZones}
          allIntegrations={allIntegrations}
        />
        <CreateOrderDialog
          isOpen={isCreateOrderDialogOpen}
          onClose={this.handleCreateOrderDialogClose}
          deviceSettings={deviceSettings}
          bar={bar}
          assets={assets}
          base={base}
          allMenus={allMenus}
          allBases={allBases}
        />
        <CreditsWarning
          drawerWidth={DRAWER_WIDTH}
          isMenuDrawerOpen={isMenuDrawerOpen}
          balance={balance}
        />
        <BarNotLiveOrBaseNotActiveWarning
          drawerWidth={DRAWER_WIDTH}
          isMenuDrawerOpen={isMenuDrawerOpen}
          isAppLocked={isAppLocked}
          bar={bar}
          subscription={subscription}
          base={base}
          unlockApp={unlockApp}
          selectBase={this.handleSelectBaseDialogOpenClick}
        />
        <BarContent
          drawerWidth={DRAWER_WIDTH}
          isMenuDrawerOpen={isMenuDrawerOpen}
          isAppLocked={isAppLocked}
          timeOffset={timeOffset}
          deviceSettings={deviceSettings}
          mode={mode}
          bar={bar}
          assets={assets}
          subscription={subscription}
          base={base}
          balance={balance}
          pricing={pricing}
          allMenus={allMenus}
          allBases={allBases}
          allZones={allZones}
          allIntegrations={allIntegrations}
          openOrders={openOrders}
          selectBase={this.handleSelectBaseDialogOpenClick}
          createOrder={this.handleCreateOrder}
          setBarId={setBarId}
          lockApp={lockApp}
          unlockApp={unlockApp}
        />
      </div>
    ) : null;
  }
}

export default withBarAuthorization()(
  withStyles(styles)(withTranslation("common")(BarSetting))
);
