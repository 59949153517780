import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import withStyles from "@mui/styles/withStyles";

import VerifyPassword from "./VerifyPassword";
import { authService } from "tap-io/client/services";

const styles = {
  titleIcon: {
    verticalAlign: "middle",
    marginRight: 10
  },
  titleText: {
    display: "inline-block",
    verticalAlign: "middle"
  }
};

class VerifyPasswordDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleSignOut = () => {
    authService.signOut();
  };

  render() {
    const { classes, t, isOpen, onSuccess, onCancel } = this.props;

    return (
      <Dialog onClose={onCancel} open={isOpen}>
        <DialogTitle>
          <LockOpenIcon className={classes.titleIcon} />
          <span className={classes.titleText}>{t("auth.unlock")}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("auth.sensitive-information-password-required")}
          </DialogContentText>
          <VerifyPassword onVerified={onSuccess} />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleSignOut} color="secondary">
            {t("auth.sign-out")}
          </Button>
          <Button onClick={onCancel} color="primary">
            {t("label.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(VerifyPasswordDialog)
);
