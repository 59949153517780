import { Stat } from "./Stat";

type Data = {
  serviceOption: string;
  fulfillment: string;
  delivery: string;
  amountOfOrders: number;
  amountOfProducts: number;
};

export class ServiceReport extends Stat<Data> {
  formatServiceOption(t: any) {
    return (serviceOption: string) => t(`service.${serviceOption}`);
  }

  toCSV(t: any) {
    return this.generateCSV(
      {
        serviceOption: this.formatServiceOption(t),
        fulfillment: false,
        delivery: false,
        amountOfOrders: this.formatInteger,
        amountOfProducts: this.formatInteger
      },
      t
    );
  }
}
