import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import ContentCard from "tap-io/client/components/common/ContentCard";
import { deviceStorage } from "tap-io/storage";

import withPasswordAuthentication from "../components/auth/withPasswordAuthentication";
import withMgmtAuthorization from "../components/auth/withMgmtAuthorization";
import RequestedSubscriptions from "../components/mgmt/RequestedSubscriptions";
import Bars from "../components/mgmt/Bars";
import Stats from "../components/mgmt/Stats";
import { CACHE_KEY_BAR_ID } from "../constants/cache";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
  }
});

class BarMgmtPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleBarAccessChanged = async (
    barOfWhichAccessHasBeenChanged,
    wasAccessGranted
  ) => {
    const { setBarId, bar } = this.props;

    if (wasAccessGranted || barOfWhichAccessHasBeenChanged.id === bar.id) {
      await deviceStorage.clearDeviceCacheItem("", CACHE_KEY_BAR_ID);
      setBarId(null);
    }
  };

  render() {
    const { classes, t, bar } = this.props;

    return (
      <div className={classes.container}>
        <ContentCard title={t("label.requested-subscriptions")} maxWidth="100%">
          <RequestedSubscriptions
            onBarAccessChanged={this.handleBarAccessChanged}
          />
        </ContentCard>
        <ContentCard title={t("label.bars")} maxWidth="100%">
          <Bars bar={bar} onBarAccessChanged={this.handleBarAccessChanged} />
        </ContentCard>
        <Stats bar={bar} />
      </div>
    );
  }
}

export default withPasswordAuthentication()(
  withMgmtAuthorization()(
    withStyles(styles, { withTheme: true })(
      withTranslation("common")(BarMgmtPage)
    )
  )
);
