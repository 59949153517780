import React, { PropsWithChildren, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
    ListItem,
    ListItemText,
    Theme,
    Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import { utilsHelper } from "../../../helpers";
import Bar from "../../../models/bar/Bar";
import Order from "../../../models/order/Order";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        total: {
            color: "rgba(0, 0, 0, 0.6)"
        }
    })
);

type OrderDepositProps = {
    bar: Bar;
    amount: number;
    total: number;
};

function OrderDeposit({
    bar,
    amount,
    total
}: OrderDepositProps) {
    const { t, i18n } = useTranslation("common");
    const classes = useStyles();

    return (
        <ListItem>
            <ListItemText
                primary={`${t("order.deposit")} (${amount})`}
            />
            <span className={classes.total}>
                {`${utilsHelper.formatToTwoDecimals(total)} ${bar.getOrderCurrency()}`}
            </span>
        </ListItem>
    );
}

type OrderDepositsProps = {
    bar: Bar;
    order: Order;
};

function OrderDeposits({
    bar,
    order
}: OrderDepositsProps) {
    const { t, i18n } = useTranslation("common");

    return (
        <>
            {order.deposits.hasDistributed &&
                <OrderDeposit
                    bar={bar}
                    amount={order.deposits.distributedAmount}
                    total={order.deposits.distributedTotal}
                />
            }
            {order.deposits.hasCollected &&
                <OrderDeposit
                    bar={bar}
                    amount={order.deposits.collectedAmount}
                    total={-order.deposits.collectedTotal}
                />
            }

        </>
    );
}

export default OrderDeposits;
