import React, { PureComponent } from "react";

import withStyles from "@mui/styles/withStyles";

import SelectProduct from "./SelectProduct";

const styles = {};

class SelectProductFromMenus extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes,
      isDisabled,
      catalogue,
      menus,
      excludeProductIds,
      onSelect
    } = this.props;

    const productIds = menus
      .reduce((productIds, menu) => {
        return [...productIds, ...menu.items.map((item) => item.productId)];
      }, [])
      .filter((productId, index, self) => {
        return (
          productId &&
          self.indexOf(productId) == index &&
          (!excludeProductIds || excludeProductIds.indexOf(productId) < 0)
        );
      });

    return (
      <SelectProduct
        isDisabled={isDisabled}
        catalogue={catalogue}
        searchInProductIds={productIds}
        onSelect={onSelect}
      />
    );
  }
}

export default withStyles(styles)(SelectProductFromMenus);
