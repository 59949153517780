import React, { PureComponent } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { withTranslation } from "react-i18next";

import { soundHelper } from "tap-io/helpers";

const styles = (theme) => ({});

class TestSoundDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isTested: false
    };
  }

  handlePlay = () => {
    const { testSound } = this.props;

    try {
      soundHelper.playSound(testSound);
      this.setState({ isTested: true });
    } catch (error) {
      console.warn(error);
    }
  };

  handleClose = (event, reason) => {
    const { onClose } = this.props;

    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      onClose();
    }
  };

  render() {
    const { t, isOpen } = this.props;
    const { isTested } = this.state;

    return (
      <Dialog
        onClose={this.handleClose}
        open={isOpen}
        disableEscapeKeyDown={true}
      >
        <DialogTitle>{t("sounds.test-sound")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("sounds.press-button-below-to-test")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handlePlay} color="primary">
            {t("sounds.test")}
          </Button>
          {isTested && (
            <Button onClick={this.handleClose} color="secondary">
              {t("label.close")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(TestSoundDialog));
