import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography
} from "@mui/material";

import PrintIcon from "@mui/icons-material/Print";
import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";
import { catalogueHelper, voucherHelper } from "tap-io/helpers";
import {
  barService,
  dataService,
  voucherService
} from "tap-io/client/services";

import { hasNativeWrapper, printer } from "../../native";
import AuthorizedAppLink from "../auth/AuthorizedAppLink";
import ShowVoucherDialog from "./ShowVoucherDialog";
import SearchBar from "../common/SearchBar";
import AllVouchers from "./AllVouchers";
import SearchedVouchers from "./SearchedVouchers";
import defaultCatalogue from "../../constants/catalogue";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  dialog: {
    minWidth: 650
  },
  searchBar: {
    paddingLeft: theme.spacing(1)
  },
  voucherActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 40,
    paddingRight: 24
  },
  amountOfVouchersSelectedLabel: {
    minWidth: 250
  },
  voucherActionButtons: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%"
  }
});

class ManageVouchersDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      voucherSearchQuery: "",
      selectedVouchers: {},
      voucherBeingShown: null
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isOpen, selectedVoucherIds, bar } = this.props;
    const { voucherSearchQuery } = this.state;

    const prevIsOpen = prevProps.isOpen;
    const prevVoucherSearchQuery = prevState.voucherSearchQuery;

    if (isOpen && !prevIsOpen) {
      this.setState({
        voucherSearchQuery: "",
        selectedVouchers: {},
        initiallySelectedVoucherIds: selectedVoucherIds || []
      });

      if (this.unsubscribeCatalogueData) {
        this.unsubscribeCatalogueData();
      }
      this.unsubscribeCatalogueData = dataService.onCatalogueData(
        bar.id,
        (catalogueData) => {
          this.setState({
            catalogue: catalogueHelper.createCatalogue(
              defaultCatalogue,
              catalogueData
            )
          });
        }
      );
    }

    if (!isOpen && prevIsOpen) {
      if (this.unsubscribeCatalogueData) {
        this.unsubscribeCatalogueData();
        this.unsubscribeCatalogueData = undefined;
      }
    }

    if (
      (voucherSearchQuery && !prevVoucherSearchQuery) ||
      (!voucherSearchQuery && prevVoucherSearchQuery)
    ) {
      this.setState({ selectedVouchers: {} });
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeCatalogueData) {
      this.unsubscribeCatalogueData();
      this.unsubscribeCatalogueData = undefined;
    }
  }

  handleVoucherSearchQueryChange = (voucherSearchQuery) => {
    this.setState({ voucherSearchQuery });
  };

  handleShowVoucher = (voucher) => {
    this.setState({ voucherBeingShown: voucher });
  };

  handleShowVoucherDialogClose = () => {
    this.setState({ voucherBeingShown: null });
  };

  handleCheckVoucherChange = (voucher, isChecked) => {
    let selectedVouchers = { ...this.state.selectedVouchers };

    if (isChecked) {
      selectedVouchers[voucher.id] = voucher;
    } else {
      delete selectedVouchers[voucher.id];
    }
    this.setState({ selectedVouchers });
  };

  handlePrintSelectedVouchers = async () => {
    const { t, bar, deviceSettings, assets } = this.props;
    const { selectedVouchers } = this.state;

    if (Object.keys(selectedVouchers).length > 0) {
      const barUrl = await barService.getBarUrl(
        bar,
        config.hosting.orderDomain
      );

      for (let voucherId in selectedVouchers) {
        const voucher = selectedVouchers[voucherId];
        const pin = await voucherService.getVoucherPin(voucher);
        const voucherCode = voucherHelper.encodeVoucherCode(voucher.id, pin);

        const voucherUrl = voucherHelper.getVoucherUrl(barUrl, voucherCode);

        await printer.printVoucher(
          t,
          bar,
          assets,
          voucher,
          pin,
          voucherUrl,
          deviceSettings.printBarLogo,
          1
        );
      }
    }
  };

  render() {
    const { classes, t, isOpen, onClose, bar, assets, deviceSettings } =
      this.props;
    const {
      catalogue,
      initiallySelectedVoucherIds,
      voucherSearchQuery,
      selectedVouchers,
      voucherBeingShown
    } = this.state;

    const selectedVoucherIds = Object.keys(selectedVouchers);

    return (
      <div>
        <ShowVoucherDialog
          isOpen={voucherBeingShown !== null}
          onClose={this.handleShowVoucherDialogClose}
          catalogue={catalogue}
          bar={bar}
          voucher={voucherBeingShown}
          assets={assets}
          deviceSettings={deviceSettings}
        />
        <Dialog
          open={isOpen}
          onClose={onClose}
          classes={{ paper: classes.dialog }}
          TransitionComponent={Transition}
          fullScreen
        >
          <DialogTitle>{t("voucher.manage-vouchers")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("voucher.manage-vouchers-below")}
            </DialogContentText>
            <div className={classes.searchBar}>
              <SearchBar
                placeholder={t(
                  "voucher.search-by-voucher-code-or-customer-email"
                )}
                query={voucherSearchQuery}
                onChange={this.handleVoucherSearchQueryChange}
              />
            </div>
            {voucherSearchQuery ? (
              <SearchedVouchers
                bar={bar}
                searchQuery={voucherSearchQuery}
                selectedVoucherIds={selectedVoucherIds}
                onVoucherClick={this.handleShowVoucher}
                onVoucherSelectChange={this.handleCheckVoucherChange}
              />
            ) : (
              <AllVouchers
                bar={bar}
                allowSelection={true}
                initiallySelectedVoucherIds={initiallySelectedVoucherIds}
                selectedVoucherIds={selectedVoucherIds}
                onVoucherClick={this.handleShowVoucher}
                onVoucherSelectChange={this.handleCheckVoucherChange}
              />
            )}
          </DialogContent>
          <div className={classes.voucherActions}>
            <Typography className={classes.amountOfVouchersSelectedLabel}>
              {`${
                selectedVoucherIds.length === 0
                  ? t("label.none")
                  : selectedVoucherIds.length
              } ${t(
                selectedVoucherIds.length === 1
                  ? "voucher.voucher"
                  : "voucher.vouchers"
              ).toLowerCase()} ${t("label.selected")}`}
            </Typography>
            <div className={classes.voucherActionButtons}>
              <AuthorizedAppLink
                api={config.functions.promoApi}
                bar={bar}
                endpoint="vouchers"
                params={{ voucherIds: selectedVoucherIds }}
                text={t("label.download")}
                isDisabled={selectedVoucherIds.length === 0}
                onClick={onClose}
              />
              {hasNativeWrapper() && (
                <Button
                  startIcon={<PrintIcon />}
                  disabled={selectedVoucherIds.length === 0}
                  onClick={this.handlePrintSelectedVouchers}
                >
                  {t("label.print")}
                </Button>
              )}
            </div>
          </div>
          <DialogActions>
            <Button onClick={onClose} color="secondary">
              {t("label.close")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(ManageVouchersDialog)
);
