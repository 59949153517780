import React, { PropsWithChildren, useState } from "react";

import { withTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useTheme
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import ConfirmDialog from "tap-io/client/components/common/ConfirmDialog";
import { integrationService } from "tap-io/client/services";
import Integration from "tap-io/models/integration/Integration";

type SetIntegrationDialogProps = {
  t: any;
  title: string;
  isOpen: boolean;
  isDisabled: boolean;
  onSave: () => void;
  onCancel: () => void;
  integration?: Integration;
};

function SetIntegrationDialog({
  t,
  title,
  isOpen,
  isDisabled,
  onSave,
  onCancel,
  integration,
  children
}: PropsWithChildren<SetIntegrationDialogProps>) {
  const theme = useTheme();
  const [
    isConfirmToRemoveIntegrationDialogOpen,
    setIsConfirmToRemoveIntegrationDialogOpen
  ] = useState(false);

  const handleConfirmToRemoveIntegration = (event) => {
    setIsConfirmToRemoveIntegrationDialogOpen(true);
  };

  const handleConfirmIntegrationRemoval = async (event) => {
    if (integration) {
      setIsConfirmToRemoveIntegrationDialogOpen(false);
      await integrationService.removeIntegration(integration);
      onCancel();
    }
  };

  const handleCancelIntegrationRemoval = (event) => {
    setIsConfirmToRemoveIntegrationDialogOpen(false);
  };

  return (
    <>
      <ConfirmDialog
        isOpen={isConfirmToRemoveIntegrationDialogOpen}
        title={t("integration.confirm-removal-of-integration")}
        description={t(
          "integration.are-you-sure-you-want-to-remove-this-integration"
        )}
        confirmButtonLabel={t("label.remove")}
        onConfirm={handleConfirmIntegrationRemoval}
        onCancel={handleCancelIntegrationRemoval}
      />
      <Dialog open={isOpen} onClose={onCancel}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {integration && (
            <IconButton
              disabled={isDisabled}
              onClick={handleConfirmToRemoveIntegration}
              size="large"
              sx={{ color: theme.palette.error.main }}
            >
              <DeleteIcon />
            </IconButton>
          )}
          <div style={{ flexGrow: 1 }} />
          <Button color="secondary" disabled={isDisabled} onClick={onCancel}>
            {t("label.cancel")}
          </Button>
          <Button color="primary" disabled={isDisabled} onClick={onSave}>
            {integration ? t("label.update") : t("label.create")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withTranslation("common")(SetIntegrationDialog);
