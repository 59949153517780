import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  SortableContainer,
  SortableElement,
  sortableHandle
} from "react-sortable-hoc";

import arrayMove from "array-move";

import {
  Button,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import LocalDrinkIcon from "@mui/icons-material/LocalDrink";
import withStyles from "@mui/styles/withStyles";

import { menuService } from "tap-io/client/services";
import { utilsHelper } from "tap-io/helpers";

import AddElementMenu from "./AddElementMenu";
import EditCategoryDialog from "./EditMenuCategoryDialog";
import EditItemDialog from "./EditMenuItemDialog";
import AvailabilityToggle from "./AvailabilityToggle";

const styles = (theme) => ({
  header: {
    marginBottom: 55
  },
  leftHeaderItem: {
    top: "50%",
    left: 8,
    position: "absolute",
    transform: "translateY(-50%)"
  },
  element: {
    listStyleType: "none"
  },
  hiddenElement: {
    opacity: 0.4
  },
  headerHelpText: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    top: 25,
    left: 16,
    right: 16
  },
  helpText: {
    fontSize: 12
  },
  leftHelpText: {
    width: "100%",
    paddingRight: 10,
    textAlign: "left"
  },
  rightHelpText: {
    width: "60%",
    paddingLeft: 10,
    textAlign: "right"
  },
  help: {
    margin: "10px 16px"
  },
  price: {
    fontWeight: "bold",
    color: "black"
  },
  icon: {
    verticalAlign: "middle",
    marginTop: -5,
    marginLeft: 5,
    fontSize: 16
  }
});

const SortableMenuList = SortableContainer(
  ({
    classes,
    t,
    bar,
    elements,
    isEditable,
    isSorting,
    isSaving,
    isSelecting,
    isElementSelected,
    onElementEdit,
    onElementSelect,
    onElementRemove,
    onItemAvailabilityToggle
  }) => {
    return (
      <div>
        {elements &&
          elements.map((element, index) => (
            <SortableMenuElement
              index={index}
              key={element.id}
              disabled={!isSorting}
              classes={classes}
              t={t}
              element={element}
              currency={bar.getOrderCurrency()}
              isEditable={isEditable}
              isSorting={isSorting}
              isSaving={isSaving}
              isSelecting={isSelecting}
              isElementSelected={isElementSelected}
              onElementEdit={onElementEdit}
              onElementSelect={onElementSelect}
              onElementRemove={onElementRemove}
              onItemAvailabilityToggle={onItemAvailabilityToggle}
            />
          ))}
      </div>
    );
  }
);

const SortableMenuElement = SortableElement(
  ({
    classes,
    t,
    element,
    currency,
    isEditable,
    isSorting,
    isSaving,
    isSelecting,
    isSelected,
    isElementSelected,
    onElementEdit,
    onElementSelect,
    onElementRemove,
    onItemAvailabilityToggle
  }) => (
    <ListItem
      classes={{
        container: classNames(classes.element, {
          [classes.hiddenElement]: element.isHidden
        })
      }}
      data-cy={`menu-element menu-${element.type}`}
      onClick={
        !isEditable || isSelecting || isSorting
          ? undefined
          : onElementEdit(element)
      }
    >
      {isSelecting ? (
        <Checkbox
          checked={isElementSelected(element)}
          onChange={onElementSelect(element)}
        />
      ) : (
        isSorting && !isSaving && <DragHandle />
      )}
      {element.type === "category" ? (
        <ListItemText
          primary={<Typography variant="overline">{element.name}</Typography>}
        />
      ) : element.type === "item" ? (
        <ListItemText
          primary={
            <span>
              {element.name}
              {
                element.hasDeposits && <LocalDrinkIcon className={classes.icon} />
              }
            </span>
          }
          secondary={
            <span>
              {element.description && (
                <>
                  <span>{element.description}</span>
                  <br />
                </>
              )}
              <span
                className={classes.price}
              >{`${utilsHelper.formatToTwoDecimals(
                element.price
              )} ${currency}`}</span>
            </span>
          }
        />
      ) : null}
      <ListItemSecondaryAction>
        {isSelecting
          ? null
          : isSorting
            ? isEditable && (
              <IconButton
                data-cy="menu-element-remove"
                onClick={onElementRemove(element)}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            )
            : element.type === "item" && (
              <AvailabilityToggle
                isAvailable={element.isAvailable}
                onToggle={onItemAvailabilityToggle(element)}
              />
            )}
      </ListItemSecondaryAction>
    </ListItem>
  )
);

const DragHandle = sortableHandle(() => (
  <ListItemIcon data-cy="menu-drag-element-handle">
    <MenuIcon />
  </ListItemIcon>
));

const MenuHeader = ({
  classes,
  leftItem,
  leftHelpText,
  rightItem,
  rightHelpText
}) => (
  <ListItem className={classes.header}>
    <div className={classes.leftHeaderItem}>{leftItem}</div>
    <div className={classNames(classes.headerHelpText)}>
      <Typography
        variant="caption"
        className={classNames(classes.leftHelpText, classes.helpText)}
      >
        {leftHelpText}
      </Typography>
      <Typography
        variant="caption"
        className={classNames(classes.rightHelpText, classes.helpText)}
      >
        {rightHelpText}
      </Typography>
    </div>
    <ListItemSecondaryAction>{rightItem}</ListItemSecondaryAction>
  </ListItem>
);

const StartSortingButton = ({ t, onStartSorting }) => (
  <Button data-cy="menu-edit" onClick={onStartSorting} color="primary">
    {t("label.edit")}
  </Button>
);

const SaveSortingButton = ({ t, onSaveSorting }) => (
  <Button data-cy="menu-ready" onClick={onSaveSorting} color="primary">
    {t("label.done")}
  </Button>
);

const RevertSortingButton = ({ t, onRevertSorting }) => (
  <Button data-cy="menu-cancel" onClick={onRevertSorting} color="secondary">
    {t("label.cancel")}
  </Button>
);

class EditMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      categoryBeingEdited: null,
      itemBeingEdited: null,
      selectedElementIds: []
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { isSelecting } = this.props;
    const prevIsSelecting = prevProps.isSelecting;

    if (isSelecting && !prevIsSelecting) {
      this.setState({ selectedElementIds: [] });
      this.handleRevertSorting();
    }
  }

  handleStartSorting = () => {
    const { menu } = this.props;
    this.setState({ isSorting: true, elements: menu.elements });
  };

  handleSaveSorting = async () => {
    const { t, menu } = this.props;
    const { elements } = this.state;

    const toastId = toast(t("menu.saving-menu"), { autoClose: false });
    this.setState({ isSaving: true });

    try {
      menu.elements = elements;

      await menuService.updateMenu(menu);

      toast.update(toastId, {
        render: t("menu.menu-saved"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isSorting: false, isSaving: false, elements: null });
    } catch (error) {
      console.warn(error);

      this.setState({ isSaving: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(error.message)})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleRevertSorting = () => {
    this.setState({ isSorting: false, elements: null });
  };

  handleOrderChange = ({ oldIndex, newIndex }) => {
    const { elements } = this.state;

    if (oldIndex === newIndex) {
      return;
    }

    const newElements = arrayMove(elements, oldIndex, newIndex);

    this.setState({ elements: newElements });
  };

  handleAddElement = (event) => {
    this.setState({ addElementMenuAnchor: event.currentTarget });
  };

  handleAddElementMenuClose = () => {
    this.setState({ addElementMenuAnchor: null });
  };

  handleToggleItemAvailability = (item) => async (isAvailable, event) => {
    event.preventDefault();

    const { t, menu } = this.props;

    const toastId = toast(t("menu.updating-item-availability"), {
      autoClose: false
    });
    try {
      item.isAvailable = !item.isAvailable;

      await menuService.updateMenuItem(menu, item);

      toast.update(toastId, {
        render: t("menu.item-availability-updated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("error.error-updating-item-availability")} (${t(
          error.message
        )})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleRemoveElement = (elementToRemove) => (event) => {
    const { elements } = this.state;

    const newElements = elements.filter(
      (element) => element.id !== elementToRemove.id
    );
    this.setState({ elements: newElements });
  };

  handleSelectElement = (element) => (event) => {
    const selectedElementIds = [...this.state.selectedElementIds];

    if (element && element.id) {
      const currentIndex = selectedElementIds.indexOf(element.id);
      if (event.target.checked) {
        if (currentIndex < 0) {
          selectedElementIds.push(element.id);
          this.setSelectedElementIds(selectedElementIds);
        }
      } else {
        if (currentIndex >= 0) {
          selectedElementIds.splice(currentIndex, 1);
          this.setSelectedElementIds(selectedElementIds);
        }
      }
    }
  };

  handleToggleSelectAllElements = (event) => {
    const { menu } = this.props;
    const { selectedElementIds } = this.state;

    if (selectedElementIds.length === menu.elements.length) {
      this.setSelectedElementIds([]);
    } else {
      this.setSelectedElementIds(menu.elements.map((element) => element.id));
    }
  };

  setSelectedElementIds(selectedElementIds) {
    const { menu, onSelectionChange } = this.props;

    this.setState({ selectedElementIds });

    if (onSelectionChange) {
      onSelectionChange(
        menu.elements.filter(
          (element) => selectedElementIds.indexOf(element.id) >= 0
        )
      );
    }
  }

  handleEditElement = (element) => (event) => {
    return element.type === "category"
      ? this.handleEditCategory(element)
      : this.handleEditItem(element);
  };

  handleEditCategory = (category) => {
    this.setState({ categoryBeingEdited: category });
  };

  handleEditItem = (item) => {
    this.setState({ itemBeingEdited: item });
  };

  handleEditCategoryDialogClose = () => {
    this.setState({ categoryBeingEdited: null });
  };

  handleEditItemDialogClose = () => {
    this.setState({ itemBeingEdited: null });
  };

  isElementSelected = (element) => {
    const { selectedElementIds } = this.state;

    return selectedElementIds.indexOf(element.id) >= 0;
  };

  render() {
    const { classes, t, bar, menu, catalogue, deposits, isSelecting } =
      this.props;
    const {
      addElementMenuAnchor,
      isSorting,
      isSaving,
      categoryBeingEdited,
      itemBeingEdited,
      selectedElementIds
    } = this.state;

    const elements = isSorting
      ? this.state.elements
      : menu
        ? menu.elements
        : undefined;

    const isEditable = menu.isEditable && this.props.isEditable;

    return (
      <div>
        <AddElementMenu
          anchorElement={addElementMenuAnchor}
          onClose={this.handleAddElementMenuClose}
          bar={bar}
          menu={menu}
          catalogue={catalogue}
          deposits={deposits}
        />
        <EditCategoryDialog
          isOpen={categoryBeingEdited !== null}
          onClose={this.handleEditCategoryDialogClose}
          menu={menu}
          category={categoryBeingEdited}
        />
        <EditItemDialog
          isOpen={itemBeingEdited !== null}
          onClose={this.handleEditItemDialogClose}
          bar={bar}
          menu={menu}
          catalogue={catalogue}
          deposits={deposits}
          item={itemBeingEdited}
        />
        <List>
          {!isSelecting &&
            (isEditable ? (
              <MenuHeader
                classes={classes}
                leftItem={
                  isSorting
                    ? !isSaving && (
                      <SaveSortingButton
                        t={t}
                        onSaveSorting={this.handleSaveSorting}
                      />
                    )
                    : elements &&
                    elements.length > 0 && (
                      <StartSortingButton
                        t={t}
                        onStartSorting={this.handleStartSorting}
                      />
                    )
                }
                leftHelpText={
                  isSorting
                    ? t("menu.change-order")
                    : elements &&
                    elements.length > 0 &&
                    t("menu.change-order-and-remove-categories-or-items")
                }
                rightItem={
                  isSorting ? (
                    !isSaving && (
                      <RevertSortingButton
                        t={t}
                        onRevertSorting={this.handleRevertSorting}
                      />
                    )
                  ) : (
                    <IconButton
                      data-cy="menu-add"
                      onClick={this.handleAddElement}
                      size="large"
                    >
                      <AddIcon />
                    </IconButton>
                  )
                }
                rightHelpText={
                  isSorting
                    ? t("menu.remove-category-or-item")
                    : t("menu.add-category-or-item")
                }
              />
            ) : (
              <MenuHeader
                classes={classes}
                leftItem={
                  isSorting
                    ? !isSaving && (
                      <SaveSortingButton
                        t={t}
                        onSaveSorting={this.handleSaveSorting}
                      />
                    )
                    : elements &&
                    elements.length > 0 && (
                      <StartSortingButton
                        t={t}
                        onStartSorting={this.handleStartSorting}
                      />
                    )
                }
                leftHelpText={
                  (isSorting || (elements && elements.length > 0)) &&
                  t("menu.change-order")
                }
                rightItem={
                  isSorting &&
                  !isSaving && (
                    <RevertSortingButton
                      t={t}
                      onRevertSorting={this.handleRevertSorting}
                    />
                  )
                }
              />
            ))}
          {isSelecting && (
            <ListItem>
              <Button onClick={this.handleToggleSelectAllElements}>
                {selectedElementIds.length === elements.length
                  ? t("label.deselect-all")
                  : t("label.select-all")}
              </Button>
            </ListItem>
          )}
          <SortableMenuList
            lockAxis="y"
            transitionDuration={500}
            useDragHandle={true}
            onSortEnd={this.handleOrderChange}
            classes={classes}
            t={t}
            bar={bar}
            elements={elements}
            isEditable={isEditable}
            isSorting={isSorting}
            isSaving={isSaving}
            isSelecting={isSelecting}
            isElementSelected={this.isElementSelected}
            onElementEdit={this.handleEditElement}
            onElementSelect={this.handleSelectElement}
            onElementRemove={this.handleRemoveElement}
            onItemAvailabilityToggle={this.handleToggleItemAvailability}
          />
        </List>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditMenu)
);
