import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import CopyToClipboard from "react-copy-to-clipboard";

import { ListItemButton, ListItemText } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import withStyles from "@mui/styles/withStyles";

import withAuthorization from "../auth/withAuthorization";

const styles = (theme) => ({
  copyToClipboardIcon: {
    marginRight: theme.spacing(0.5),
    fontSize: 12
  }
});

class CopyToClipboardButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleCopiedToClipboard = () => {
    const { t } = this.props;
    toast.info(t("label.copied-to-clipboard"));
  };

  render() {
    const { classes, t, label, description, value } = this.props;

    return (
      <CopyToClipboard text={value} onCopy={this.handleCopiedToClipboard}>
        <ListItemButton>
          <ListItemText
            primary={label || value}
            secondary={
              <span>
                {description && (
                  <>
                    {description}
                    <br />
                  </>
                )}
                <span>
                  <FileCopyIcon className={classes.copyToClipboardIcon} />
                  {t("label.click-or-tap-to-copy-to-clipboard")}
                </span>
              </span>
            }
          />
        </ListItemButton>
      </CopyToClipboard>
    );
  }
}

export default withAuthorization()(
  withStyles(styles)(withTranslation("common")(CopyToClipboardButton))
);
