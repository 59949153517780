// TO FIX: react-firebase-file-uploader (https://github.com/fris-fruitig/react-firebase-file-uploader/pull/55/files)

import React, { PureComponent } from "react";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import Moment from "moment";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "typeface-roboto";

import { CssBaseline } from "@mui/material";

import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider
} from "@mui/material/styles";

import { Helmet } from "react-helmet";

import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { ToastContainer } from "react-toastify";

import * as routes from "./constants/routes";

import appTheme from "tap-io/client/theme";

import { authService, crewService } from "tap-io/client/services";

import { hasNativeWrapper } from "./native";

import CheckConnection from "tap-io/client/components/utils/CheckConnection";

import RegisterPage from "./pages/RegisterPage";
import SignInPage from "./pages/SignInPage";
import UnavailablePage from "./pages/UnavailablePage";

import BarWrapper from "./components/bar/BarWrapper";

import withAnalytics from "tap-io/client/components/hoc/withAnalytics";
import withBugsnag from "tap-io/client/components/hoc/withBugsnag";
import withTranslations from "tap-io/client/components/hoc/withTranslations";

import withAuthentication from "./components/auth/withAuthentication";

const DEFAULT_COLOR_PRIMARY = "#34b9c8";
const DEFAULT_COLOR_SECONDARY = "#faeb52";

class App extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      theme: this.createTheme(DEFAULT_COLOR_PRIMARY, DEFAULT_COLOR_SECONDARY),
      locale: "nl_BE"
    };
  }

  componentDidMount() {
    authService.onAuthStateChanged((user) => {
      const prevUser = this.state.user;

      this.setState({ user });

      const prevUserId = prevUser ? prevUser.uid : undefined;
      const userId = user ? user.uid : undefined;

      if (userId !== prevUserId) {
        // Unsubscribe
        if (this.unsubscribeCrew) {
          this.unsubscribeCrew();
          this.unsubscribeCrew = undefined;
        }

        if (user) {
          this.unsubscribeCrew = crewService.onBarIdsForUser(
            user.uid,
            (barIds) => {
              this.setState({
                barIds,
                barId: barIds.length === 1 ? barIds[0] : null
              });
            }
          );
        } else {
          this.setState({ barId: null });
        }
      }
    });
  }

  tryToUnlockApp = (authenticatedUser, password) => {
    if (this.tryToUnlockAppTimeout) {
      clearTimeout(this.tryToUnlockAppTimeout);
      this.tryToUnlockAppTimeout = null;
    }

    if (this.unlockApp) {
      this.unlockApp(authenticatedUser, password);
    } else {
      this.tryToUnlockAppTimeout = setTimeout(() => {
        this.tryToUnlockApp(authenticatedUser, password);
      }, 100);
    }
  };

  createTheme = (primaryColor, secondaryColor) => {
    return createTheme(appTheme.options(primaryColor, secondaryColor));
  };

  setThemeColors = (primaryColor, secondaryColor) => {
    this.setState({
      theme: this.createTheme(primaryColor, secondaryColor)
    });
  };

  setLocale = (locale) => {
    const { changeLanguage } = this.props;

    Moment.locale(locale);
    changeLanguage(locale);
    this.setState({ locale });
  };

  setBarId = (barId) => {
    const { barIds } = this.state;

    if (barId) {
      if (barIds && barIds.indexOf(barId) >= 0) {
        this.setState({ barId });
      } else {
        throw new Error("error.bar-id-is-not-valid");
      }
    } else {
      this.setState({ barId: null });
    }
  };

  render() {
    const { theme, locale, barIds, barId } = this.state;

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment} locale={locale}>
            <div className="App">
              {appTheme.faviconFolder && (
                <Helmet>
                  <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={`${appTheme.faviconFolder}/apple-touch-icon.png`}
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href={`${appTheme.faviconFolder}/favicon-32x32.png`}
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href={`${appTheme.faviconFolder}/favicon-16x16.png`}
                  />
                  <link rel="manifest" href="/favicon-abi/site.webmanifest" />
                  <link
                    rel="mask-icon"
                    href={`${appTheme.faviconFolder}/safari-pinned-tab.svg`}
                    color="#000000"
                  />
                  <link
                    rel="shortcut icon"
                    href={`${appTheme.faviconFolder}/favicon.ico`}
                  />
                  <meta name="msapplication-TileColor" content="#ffffff" />
                  <meta
                    name="msapplication-config"
                    content={`${appTheme.faviconFolder}/browserconfig.xml`}
                  />
                  <meta name="theme-color" content="#ffffff" />
                </Helmet>
              )}
              <CssBaseline />
              <ToastContainer
                position="top-center"
                className={
                  hasNativeWrapper() ? "App-toast-container-ios" : undefined
                }
              />
              <CheckConnection />
              <Switch>
                <Route
                  exact
                  path={`*${routes.REGISTER}`}
                  render={(params) => (
                    <RegisterPage unlockApp={this.tryToUnlockApp} {...params} />
                  )}
                />
                <Route
                  exact
                  path={`*${routes.SIGN_IN}`}
                  render={(params) => (
                    <SignInPage unlockApp={this.tryToUnlockApp} {...params} />
                  )}
                />
                <Route
                  exact
                  path={`*${routes.UNAVAILABLE}`}
                  render={(params) => <UnavailablePage {...params} />}
                />
                <Route
                  render={(params) => (
                    <BarWrapper
                      barIds={barIds}
                      barId={barId}
                      setBarId={this.setBarId}
                      setThemeColors={this.setThemeColors}
                      setLocale={this.setLocale}
                      setUnlockApp={(unlockApp) => (this.unlockApp = unlockApp)}
                    />
                  )}
                />
              </Switch>
            </div>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

const AppWithoutRouter = withRouter(
  withTranslations(withBugsnag(withAnalytics(withAuthentication(App))))
);

const AppWithRouter = () => (
  <BrowserRouter>
    <AppWithoutRouter />
  </BrowserRouter>
);

export default AppWithRouter;
