import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { dataService } from "tap-io/client/services";
import VatRateSelector from "tap-io/client/components/menu/VatRateSelector";

const styles = (theme) => ({});

class EditProductDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (product) => {
    return {
      isDisabled: false,
      name: product ? product.name : "",
      vatRate: product && product.vatRate ? product.vatRate : ""
    };
  };

  componentDidMount() {
    const { product } = this.props;

    if (product) {
      this.setState(this.initialState(product));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen, product } = this.props;

    if (isOpen && !prevProps.isOpen) {
      this.setState(this.initialState(product));
    }
  }

  handleNameChange = (event) => {
    const val = event.target.value;
    this.setState({ name: val });
  };

  handleVatRateChange = (vatRate) => {
    this.setState({ vatRate });
  };

  handleUpdateProduct = async () => {
    const { t, bar, product, onClose } = this.props;
    const { name, vatRate } = this.state;

    const toastId = toast(t("catalogue.updating-product"), {
      autoClose: false
    });

    this.setState({ isDisabled: true });

    try {
      product.name = name;
      product.vatRate = vatRate || null;
      await dataService.updateCustomProductInCatalogueData(bar.id, product);

      toast.update(toastId, {
        render: t("catalogue.product-updated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false }, onClose);
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { t, isOpen, onClose } = this.props;
    const { isDisabled, name, vatRate } = this.state;

    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>{t("catalogue.edit-product")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("catalogue.edit-product-below")}
          </DialogContentText>
          <TextField
            variant="standard"
            margin="dense"
            label={t("label.name")}
            helperText={t("catalogue.name-of-this-product")}
            fullWidth
            value={name}
            disabled={isDisabled}
            onChange={this.handleNameChange}
          />
          <VatRateSelector
            fullWidth
            variant="standard"
            margin="dense"
            allowEmpty={true}
            vatRate={vatRate}
            isDisabled={isDisabled}
            onChange={this.handleVatRateChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={isDisabled} color="secondary">
            {t("label.cancel")}
          </Button>
          <Button
            onClick={this.handleUpdateProduct}
            disabled={isDisabled}
            color="primary"
          >
            {t("label.update")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(EditProductDialog));
