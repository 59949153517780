import CallSplitIcon from "@mui/icons-material/CallSplit";
import PlusOneIcon from "@mui/icons-material/PlusOne";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

import LayoutNodeType from "tap-io/models/zone/LayoutNodeType";

export const ZONE_MAX_BASES_COUNT = 10; // This is a Firestore limit for the "in" query -- used in menuService.onAllElementsFromActiveMenusWithIds()

export const LAYOUT_NODE_ICONS = {
  [LayoutNodeType.ZONE]: CallSplitIcon,
  [LayoutNodeType.SINGLE]: RadioButtonCheckedIcon,
  [LayoutNodeType.SERIES]: FormatListNumberedIcon,
  [LayoutNodeType.SEQUENCE]: PlusOneIcon
};
