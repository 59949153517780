import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import EnterValue from "tap-io/client/components/common/EnterValue";

const styles = (theme) => ({
  inputContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: 50
  },
  inputLabel: {
    width: "100%"
  },
  value: {
    maxWidth: 120
  }
});

class SelectCurrencyVoucherValue extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, bar, isDisabled, value, onChange } = this.props;

    return (
      <div className={classes.inputContainer}>
        <Typography className={classes.inputLabel}>
          {`${t("voucher.value-per-voucher")} `}
        </Typography>
        <div className={classes.value}>
          <EnterValue
            isDisabled={isDisabled}
            unit={bar?.params?.orderCurrency}
            min={0} // TO FIX
            max={5000}
            initialValue={10}
            value={value}
            onChange={onChange}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(SelectCurrencyVoucherValue)
);
