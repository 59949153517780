import React, { PureComponent } from "react";

import withStyles from "@mui/styles/withStyles";

import EditBases from "../base/EditBases";

const styles = (theme) => ({
  container: {
    minWidth: 600
  }
});

class EditBasesStep extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { setOnNext } = this.props;

    setOnNext(this.checkBases);
  }

  checkBases = () => {
    const { allBases } = this.props;

    if (!allBases || allBases.length === 0) {
      throw new Error("error.create-at-least-one-base");
    }
  };

  render() {
    const { classes, bar, subscription, allBases } = this.props;

    return (
      <div className={classes.container}>
        <EditBases
          bar={bar}
          subscription={subscription}
          allBases={allBases}
          isEditable={true}
          hideLinks={true}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(EditBasesStep);
