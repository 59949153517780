import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import AlarmIcon from "@mui/icons-material/Alarm";
import withStyles from "@mui/styles/withStyles";

import withEstimatedPreparationInfo from "./withEstimatedPreparationInfo";
import ModifyPreparationOptionDialog from "./ModifyPreparationOptionDialog";
import OrderDeliveryIcon from "./OrderDeliveryIcon";

const styles = (theme) => ({
  estimatedPreparationDurationLabel: {
    display: "flex",
    alignItems: "center",
    padding: "1px 8px",
    borderRadius: 16,
    fontWeight: "bold",
    textTransform: "uppercase",
    cursor: "pointer",
    backgroundColor: "black",
    color: "white"
  },
  transparent: {
    backgroundColor: "transparent"
  },
  noWarning: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText
  },
  customerTooLate: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  customerLate: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText
  },
  minutes: {
    marginLeft: theme.spacing(0.5)
  },
  isWaitingForPickupWarning: {
    marginTop: theme.spacing(),
    fontSize: 16,
    fontWeight: "bold"
  }
});

class OrderEstimatedPreparationDurationLabel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isModifyPreparationOptionDialogOpen: false
    };
  }

  handleModifyPreparationOption = (event) => {
    event.stopPropagation();

    this.setState({ isModifyPreparationOptionDialogOpen: true });
  };

  handleModifyPreparationOptionDialogClose = () => {
    this.setState({ isModifyPreparationOptionDialogOpen: false }, this.calculateRemainingTime);
  };

  render() {
    const { classes, t, timeOffset, base, order, color, orientation, showDetails, isTransparent, estimatedPreparationInfo } = this.props;
    const { isModifyPreparationOptionDialogOpen } = this.state;

    const { isWaitingForPickup, timeInMinutes, customerLate, customerTooLate } = estimatedPreparationInfo;

    if (!order.isDeliveryMethodPickup) {
      return null;
    }

    const fontSize = showDetails ? 32 : undefined;
    const style = {
      flexDirection: orientation === "vertical" ? "column" : "row",
      borderRadius: fontSize ? fontSize / 2 : undefined,
      fontSize: fontSize || undefined,
      color
    };

    return (
      <>
        <ModifyPreparationOptionDialog
          isOpen={isModifyPreparationOptionDialogOpen}
          onClose={this.handleModifyPreparationOptionDialogClose}
          timeOffset={timeOffset}
          base={base}
          order={order}
        />
        <div
          className={classNames(
            classes.estimatedPreparationDurationLabel,
            isTransparent ? classes.transparent :
              customerTooLate
                ? classes.customerTooLate
                : customerLate
                  ? classes.customerLate
                  : classes.noWarning
          )}
          style={style}
          onClick={isWaitingForPickup && showDetails ? undefined : this.handleModifyPreparationOption}
        >
          {isWaitingForPickup ? (
            <OrderDeliveryIcon size="small" fontSize={fontSize} order={order} />
          ) : (
            <AlarmIcon fontSize="small" style={{ fontSize }} />
          )}
          <span className={classes.minutes}>
            {isWaitingForPickup ?
              t("order.waiting-for-pickup") :
              `${timeInMinutes} ${t("order.min-until-ready")}`
            }
          </span>
          {showDetails &&
            <span className={classes.isWaitingForPickupWarning}>
              {t(
                isWaitingForPickup ?
                  customerTooLate ?
                    "order.customer-too-late" :
                    customerLate ?
                      "order.customer-late" :
                      "order.customer-notified" :
                  "order.customer-waiting"
              )}
            </span>
          }
        </div>
      </>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(
    withEstimatedPreparationInfo()(
      OrderEstimatedPreparationDurationLabel
    )
  )
);
