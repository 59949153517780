import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import withStyles from "@mui/styles/withStyles";

import FulfilmentMethod from "tap-io/models/fulfilment/FulfilmentMethod";

import OpenOrdersForFulfilmentMethod from "./OpenOrdersForFulfilmentMethod";

const styles = (theme) => ({
  noOpenOrders: {
    display: "block",
    marginTop: theme.spacing(2)
  },
  fulfilmentMethods: {
    display: "flex",
    flexWrap: "wrap"
  },
  fulfilmentMethod: {
    width: "100%"
  },
  fulfilmentMethodName: {
    fontWeight: "bold"
  }
});

const OpenOrdersAccordion = ({
  classes,
  t,
  cardWidth,
  timeOffset,
  bar,
  deviceSettings,
  base,
  assets,
  fulfilmentMethod,
  openOrders
}) => {
  const deliveryMethods = base ?
    base.serviceOptions.getDeliveryMethods(fulfilmentMethod).activeDeliveryMethods :
    bar.deliveryMethods.activeDeliveryMethods;


  return (
    <Accordion
      className={classes.fulfilmentMethod}
      defaultExpanded={fulfilmentMethod === FulfilmentMethod.AS_SOON_AS_POSSIBLE}
    >
      <AccordionSummary
        className={classes.fulfilmentMethodName}
        expandIcon={<ExpandMoreIcon />}
      >
        {t(`order.${fulfilmentMethod}-${deliveryMethods.sort().join("-")}-orders`)} ({openOrders?.length || 0})
      </AccordionSummary>
      <AccordionDetails>
        <OpenOrdersForFulfilmentMethod
          cardWidth={cardWidth}
          timeOffset={timeOffset}
          bar={bar}
          deviceSettings={deviceSettings}
          base={base}
          assets={assets}
          openOrders={openOrders}
          fulfilmentMethod={fulfilmentMethod}
        />
      </AccordionDetails>
    </Accordion>
  );
}

class AllOpenOrders extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes,
      t,
      cardWidth,
      timeOffset,
      bar,
      base,
      assets,
      deviceSettings,
      fulfilmentMethods,
      openOrdersPerFulfilmentMethod
    } = this.props;

    return (
      <div className={classes.fulfilmentMethods}>
        {fulfilmentMethods.isAsSoonAsPossibleActive && (
          <OpenOrdersAccordion
            classes={classes}
            t={t}
            cardWidth={cardWidth}
            timeOffset={timeOffset}
            bar={bar}
            deviceSettings={deviceSettings}
            base={base}
            assets={assets}
            fulfilmentMethod={FulfilmentMethod.AS_SOON_AS_POSSIBLE}
            openOrders={
              openOrdersPerFulfilmentMethod[
              FulfilmentMethod.AS_SOON_AS_POSSIBLE
              ]
            }
          />
        )}
        {fulfilmentMethods.isOnDemandActive && (
          <OpenOrdersAccordion
            classes={classes}
            t={t}
            cardWidth={cardWidth}
            timeOffset={timeOffset}
            bar={bar}
            deviceSettings={deviceSettings}
            base={base}
            assets={assets}
            fulfilmentMethod={FulfilmentMethod.ON_DEMAND}
            openOrders={
              openOrdersPerFulfilmentMethod[FulfilmentMethod.ON_DEMAND]
            }
          />
        )}
        {fulfilmentMethods.isAutomaticActive && (
          <OpenOrdersAccordion
            classes={classes}
            t={t}
            cardWidth={cardWidth}
            timeOffset={timeOffset}
            bar={bar}
            deviceSettings={deviceSettings}
            base={base}
            assets={assets}
            fulfilmentMethod={FulfilmentMethod.AUTOMATIC}
            openOrders={
              openOrdersPerFulfilmentMethod[FulfilmentMethod.AUTOMATIC]
            }
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(AllOpenOrders)
);
