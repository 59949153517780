import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { List, ListItem } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { utilsHelper } from "tap-io/helpers";
import SelectDate from "tap-io/client/components/common/SelectDate";

const styles = (theme) => ({
  fullWidth: {
    width: "100%"
  }
});

class SelectMonthDate extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleMonthDateChange = (date) => {
    const { onChange } = this.props;

    if (date) {
      try {
        const monthDate = date.startOf("month").toDate();
        if (utilsHelper.isValidDate(monthDate)) {
          onChange(monthDate);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  render() {
    const { classes, t, isDisabled, isFutureDisabled, fullWidth } = this.props;
    const { monthDate } = this.props;

    return (
      <List className={fullWidth ? classes.fullWidth : undefined}>
        <ListItem>
          <SelectDate
            isDisabled={isDisabled}
            fullWidth={true}
            label={t("stats.month").toUpperCase()}
            value={monthDate}
            onChange={this.handleMonthDateChange}
            inputFormat="MMMM YYYY"
            openTo="month"
            views={["year", "month"]}
            maxDate={isFutureDisabled ? new Date() : undefined}
          />
        </ListItem>
      </List>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(SelectMonthDate));
