import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Stepper, Step, StepLabel } from "@mui/material";
import AdjustIcon from "@mui/icons-material/Adjust";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import LocalBarIcon from "@mui/icons-material/LocalBar";
import CheckIcon from "@mui/icons-material/Check";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import ErrorIcon from "@mui/icons-material/Error";
import withStyles from "@mui/styles/withStyles";
import { styled } from "@mui/material/styles";

import OrderStatus from "tap-io/models/order/OrderStatus";

const styles = (theme) => ({
  error: {
    display: "block",
    textAlign: "center",
    backgroundColor: "#ffebee",
    color: "#c62828"
  }
});

const StepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  })
}));

function StepIcon(props) {
  const { active, completed, className, icon } = props;

  return (
    <StepIconRoot ownerState={{ completed, active }} className={className}>
      {icon}
    </StepIconRoot>
  );
}

const STATUS_STEP_CREATED = {
  icon: <AdjustIcon />,
  label: "order.status-created-short",
  props: {}
};
const STATUS_STEP_QUEUED = {
  icon: <HourglassEmptyIcon />,
  label: "order.status-queued-short",
  props: {}
};
const STATUS_STEP_CLAIMED = {
  icon: <LocalBarIcon />,
  label: "order.status-claimed-short",
  props: {}
};
const STATUS_STEP_COMPLETE = {
  icon: <CheckIcon />,
  label: "order.status-complete-short",
  props: {}
};
const STATUS_STEP_CANCELLED = {
  icon: <DoDisturbIcon />,
  label: "order.status-cancelled-short",
  props: {}
};
const STATUS_STEP_ERROR = {
  icon: <ErrorIcon />,
  label: "order.status-error",
  props: {
    error: true
  }
};

class OrderProgress extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getStatusSteps = () => {
    const { base, order } = this.props;

    const status = order.getStatus(base);

    return [
      STATUS_STEP_CREATED,
      STATUS_STEP_QUEUED,
      STATUS_STEP_CLAIMED,
      status === OrderStatus.ERROR
        ? STATUS_STEP_ERROR
        : status === OrderStatus.CANCELLED
        ? STATUS_STEP_CANCELLED
        : STATUS_STEP_COMPLETE
    ];
  };

  getCurrentStatusStepIndex = () => {
    const { base, order } = this.props;

    const status = order.getStatus(base);

    switch (status) {
      case OrderStatus.COMPLETE:
      case OrderStatus.CANCELLED:
      case OrderStatus.ERROR:
        return 3;
      case OrderStatus.CLAIMED:
        return 2;
      case OrderStatus.QUEUED:
        return 1;
      //case OrderStatus.CREATED:
      //default:
      //return 0;
    }

    return 0;
  };

  render() {
    const { classes, t } = this.props;

    return (
      <Stepper
        activeStep={this.getCurrentStatusStepIndex()}
        orientation="horizontal"
      >
        {this.getStatusSteps().map((statusStep, index) => (
          <Step key={index}>
            <StepLabel
              StepIconComponent={StepIcon}
              StepIconProps={{ icon: statusStep.icon }}
              {...statusStep.props}
            >
              {t(statusStep.label)}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(OrderProgress));
