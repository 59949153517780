import React, { PropsWithChildren, useState, useEffect } from "react";
import classNames from "classnames";

import { toast } from "react-toastify";

import { Typography, TextField, Theme, SvgIcon } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import { utilsHelper } from "../../../helpers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "center"
    },
    text: {
      minWidth: 100
    },
    unit: {
      margin: theme.spacing(),
      fontWeight: "bold"
    }
  })
);

type EnterValueProps = {
  isDisabled?: boolean;
  label?: string;
  unit?: string;
  min?: number;
  max?: number;
  value?: number;
  initialValue?: number;
  onChange?: (amount: number) => void;
};

function EnterValue({
  isDisabled,
  label,
  unit,
  min,
  max,
  value,
  initialValue,
  onChange
}: PropsWithChildren<EnterValueProps>) {
  const classes = useStyles();

  const [enteredValue, setEnteredValue] = useState(
    `${value || initialValue || min || 0}`
  );

  useEffect(() => {
    if (value !== undefined && value !== null && value !== enteredValue) {
      setEnteredValue(`${value}`);
    }
  }, [value]);

  useEffect(() => {
    if (enteredValue.substr(-1) === ",") {
      return;
    }

    try {
      const newValue = parseFloat(enteredValue.replace(",", ".") || 0);

      if (!isNaN(newValue)) {
        updateValue(newValue);
      }
    } catch (error) {
      if (error) {
        toast.error(error.message);
      }
    }
  }, [enteredValue]);

  const updateValue = (updatedValue) => {
    if (min !== undefined && updatedValue < min) {
      updatedValue = min;
    }
    if (max !== undefined && updatedValue > max) {
      updatedValue = max;
    }
    const parsedValue = utilsHelper.roundToTwoDecimals(updatedValue);

    setEnteredValue(`${parsedValue}`.replace(".", ","));
    if (onChange) {
      onChange(parsedValue);
    }
  };

  const handleValueChange = (event) => {
    const updatedEnteredValue = event.target.value
      .replace(/^0+(?=\d)/, "") // Remove leading zeros
      .replace(".", ","); // Replace dot with comma

    if (
      updatedEnteredValue.match(/^\d+,?\d{0,2}$/) ||
      updatedEnteredValue === ""
    ) {
      setEnteredValue(updatedEnteredValue);
    }
  };

  return (
    <div className={classes.container}>
      <TextField
        className={classes.text}
        variant="standard"
        margin="dense"
        label={label}
        fullWidth
        value={enteredValue}
        disabled={isDisabled}
        onChange={handleValueChange}
      />
      {unit && <Typography className={classes.unit}>{unit}</Typography>}
    </div>
  );
}

export default EnterValue;
