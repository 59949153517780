import React, { PureComponent } from "react";

import withStyles from "@mui/styles/withStyles";

import EditMenus from "../menu/EditMenus";

const styles = (theme) => ({
  container: {
    width: "100vw"
  }
});

class EditMenusStep extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { setOnNext } = this.props;
    setOnNext(() => {});
  }

  render() {
    const { classes, bar, allBases, allMenus } = this.props;

    return (
      <div className={classes.container}>
        <EditMenus
          isEditable={true}
          bar={bar}
          menus={allMenus}
          allBases={allBases}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(EditMenusStep);
