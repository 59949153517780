import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import withStyles from "@mui/styles/withStyles";

import { authService } from "tap-io/client/services";

import withAuthorization from "../auth/withAuthorization";
import BarMenuItem from "./BarMenuItem";

const styles = (theme) => ({});

class AccountMenuItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleSignOut = () => {
    const { t, onClicked } = this.props;

    authService
      .signOut()
      .then(() => {})
      .catch((error) => {
        console.warn(error);

        toast.error(
          error && error.message ? error.message : t("error.unknown-error")
        );
      });

    if (onClicked) {
      onClicked();
    }
  };

  render() {
    const { auth, t } = this.props;

    return (
      <BarMenuItem
        icon={<ExitToAppIcon />}
        label={t("auth.sign-out")}
        description={
          auth && auth.user && auth.user.email ? auth.user.email : undefined
        }
        onClick={this.handleSignOut}
      />
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(withAuthorization()(AccountMenuItem))
);
