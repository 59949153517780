import React, { PureComponent } from "react";

import { Menu } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import SingleLayoutNodeTypeMenuItem from "./SingleLayoutNodeTypeMenuItem";
import SequenceLayoutNodeTypeMenuItem from "./SequenceLayoutNodeTypeMenuItem";
import SeriesLayoutNodeTypeMenuItem from "./SeriesLayoutNodeTypeMenuItem";

const styles = (theme) => ({
  menu: {
    minWidth: 250
  }
});

class ChangeLayoutNodeTypeMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, anchor, onClose, onUpdate, layoutNode } = this.props;

    if (!layoutNode) {
      return null;
    }

    const isOpen = Boolean(anchor);

    // TO FIX: MenuItems wrapped in a <div /> container to circumvent ForwardRef error
    return (
      <Menu anchorEl={anchor} open={isOpen} onClose={onClose}>
        <div className={classes.menu}>
          <SingleLayoutNodeTypeMenuItem
            layoutNode={layoutNode}
            onUpdate={onUpdate}
          />
          <SequenceLayoutNodeTypeMenuItem
            layoutNode={layoutNode}
            onUpdate={onUpdate}
          />
          <SeriesLayoutNodeTypeMenuItem
            layoutNode={layoutNode}
            onUpdate={onUpdate}
          />
        </div>
      </Menu>
    );
  }
}

export default withStyles(styles)(ChangeLayoutNodeTypeMenu);
