import { useState } from "react";
import { Asyncer } from "../../../components/common/Asyncer";
import { Async } from "../../../components/common/useAsync";
import { FinancialLocationReport } from "../../models/FinancialLocationReport";
import { BarStats } from "../common/BarStats";
import { DownloadIconCSVButton } from "../common/DownloadCSVIconButton";
import { FailedStatCard } from "../common/FailedStatCard";
import { FilterSwitch } from "../common/FilterSwitch";
import { LoadingStatCard } from "../common/LoadingStatCard";
import { StatCard } from "../common/StatCard";

export type RevenuePerLocationProps = {
  async: Async<FinancialLocationReport>;
  mark: string;
  t: any;
};

export function RevenuePerLocation(props: RevenuePerLocationProps) {
  const { async, mark, t } = props;

  const [isVatActive, setIsVatActive] = useState(true);

  const title = t("stats.stat.value-per-location.title");
  const description = t("stats.stat.value-per-location.description");

  return (
    <Asyncer
      async={async}
      Busy={<LoadingStatCard title={title} description={description} />}
      Failed={
        <FailedStatCard title={title} description={description} async={async} />
      }
    >
      {(report) => {
        return (
          <StatCard
            title={title}
            description={description}
            action={
              <DownloadIconCSVButton
                title={`${title}-${mark}`}
                csv={report.toCSV(t)}
              />
            }
            filters={
              <FilterSwitch
                label={t("stats.input.vat.label")}
                value={isVatActive}
                onChange={setIsVatActive}
              />
            }
          >
            <BarStats
              data={report.tree}
              x="location"
              formatX={(_, r) => r.name}
              y={isVatActive ? "valueInclVat" : "valueExclVat"}
              formatY={report.formatCurrency}
              z="subLocations"
              t={t}
            />
          </StatCard>
        );
      }}
    </Asyncer>
  );
}
