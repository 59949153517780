import { LinearProgress } from "@mui/material";
import { StatCard } from "./StatCard";

export type LoadingStatCardProps = {
  title?: string;
  description?: string;
};

export function LoadingStatCard(props: LoadingStatCardProps) {
  const { title, description } = props;

  return (
    <StatCard title={title} description={description}>
      <LinearProgress />
    </StatCard>
  );
}
