import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { QRCode } from "react-qrcode-logo";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";
import { barService } from "tap-io/client/services";

const styles = {
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  linkAndQr: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  link: {
    textDecoration: "none",
    color: "black"
  },
  zoneUrl: {
    fontFamily: "'Anonymous Pro', 'Courier New', Courier, monospace"
  }
};

class ShowBarZoneLinkDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      barZoneUrl: null
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.props;

    if (isOpen && !prevProps.isOpen) {
      this.refreshBarZoneUrl();
    }
  }

  refreshBarZoneUrl = async () => {
    const { bar, zone } = this.props;

    const barZoneUrl = await barService.getBarZoneUrl(
      bar,
      config.hosting.orderDomain,
      zone.code
    );

    this.setState({ barZoneUrl });
  };

  render() {
    const { classes, t, isOpen, onClose, zone } = this.props;
    const { barZoneUrl } = this.state;

    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>
          {t("promo.order-page-for-zone-x", {
            zoneName: zone ? zone.name : `(${t("label.unknown")})`
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("promo.below-you-can-find-link-to-order-page-for-zone-x", {
              zoneName: zone ? zone.name : `(${t("label.unknown")})`
            })}
          </DialogContentText>
          <div className={classes.content}>
            {barZoneUrl ? (
              <div className={classes.linkAndQr}>
                <a
                  className={classes.link}
                  href={barZoneUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography variant="caption" className={classes.zoneUrl}>
                    {barZoneUrl}
                  </Typography>
                </a>
                <QRCode className={classes.qr} value={barZoneUrl} />
              </div>
            ) : (
              <CircularProgress />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {t("label.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(ShowBarZoneLinkDialog)
);
