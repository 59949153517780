import { Stat } from "./Stat";

type Data = {
  product: string;
  name: string;
  version: string;
  amountSold: number;
  inclusionAmount: number;
  valueExclVat: number;
  vatRate: number;
  vat: number;
  valueInclVat: number;
  currency: string;
};

export class ProductReport extends Stat<Data> {
  get totalValueInclVat() {
    return this.data.reduce((total, report) => total + report.valueInclVat, 0);
  }
  get totalValueExclVat() {
    return this.data.reduce((total, report) => total + report.valueExclVat, 0);
  }

  toCSV(t: any) {
    return this.generateCSV(
      {
        product: true,
        name: true,
        version: true,
        amountSold: this.formatInteger,
        inclusionAmount: this.formatInteger,
        valueExclVat: this.formatCurrency,
        vatRate: this.formatFloat,
        vat: this.formatCurrency,
        valueInclVat: this.formatCurrency,
        currency: true
      },
      t
    );
  }

  toSoldCSV(t: any) {
    return this.generateCSV(
      {
        product: true,
        name: true,
        version: true,
        amountSold: this.formatInteger,
        inclusionAmount: false,
        valueExclVat: false,
        vatRate: false,
        vat: false,
        valueInclVat: false,
        currency: false
      },
      t
    );
  }

  toInclusionCSV(t: any) {
    return this.generateCSV(
      {
        product: true,
        name: true,
        version: true,
        amountSold: false,
        inclusionAmount: this.formatInteger,
        valueExclVat: false,
        vatRate: false,
        vat: false,
        valueInclVat: false,
        currency: false
      },
      t
    );
  }

  toFinancialCSV(t: any) {
    return this.generateCSV(
      {
        product: true,
        name: true,
        version: true,
        amountSold: false,
        inclusionAmount: false,
        valueExclVat: this.formatCurrency,
        vatRate: this.formatFloat,
        vat: this.formatCurrency,
        valueInclVat: this.formatCurrency,
        currency: true
      },
      t
    );
  }
}
