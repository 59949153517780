import { Box } from "@mui/material";
import { Asyncer } from "../../../components/common/Asyncer";
import { Async } from "../../../components/common/useAsync";
import { FeeReport } from "../../models/FeeReport";
import { DownloadCSVButton } from "../common/DownloadCSVButton";
import { StatCard } from "../common/StatCard";

export type MiscReportDownloadsProps = {
  fee: Async<FeeReport>;
  mark: string;
  t: any;
};

export function MiscReportDownloads(props: MiscReportDownloadsProps) {
  const { fee, mark, t } = props;

  const title = t("stats.stat.downloads.title");
  const description = t("stats.stat.downloads.description");

  return (
    <StatCard title={title} description={description}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-evenly"
        }}
      >
        <Asyncer async={fee}>
          {(report) => (
            <DownloadCSVButton
              title={t("stats.stat.fee.title")}
              mark={mark}
              csv={report.toCSV(t)}
            />
          )}
        </Asyncer>
      </Box>
    </StatCard>
  );
}
