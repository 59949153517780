import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const INFO_HEIGHT = 50;

const styles = (theme) => ({
  card: {
    position: "relative",
    overflow: "visible",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: INFO_HEIGHT,
    borderRadius: 10,
    border: `2px solid #eee`
  },
  planInfo: {
    position: "absolute",
    top: -INFO_HEIGHT,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: INFO_HEIGHT
  },
  link: {
    textDecoration: "none"
  },
  content: {
    //height: "100%", // filler class is used (legacy Safari issue)
  },
  filler: {
    height: "100%"
  },
  list: {
    display: "flex",
    flexDirection: "column",
    width: 300,
    [theme.breakpoints.down(undefined)]: {
      width: "100%"
    }
  },
  highlightedBorder: {
    borderColor: theme.palette.primary.main
  },
  planRequested: {
    textAlign: "center"
  }
});

class Plan extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes,
      t,
      isDisabled,
      isRecommended,
      isActive,
      isRequested,
      onClick,
      planName,
      planLink,
      features
    } = this.props;

    return (
      <Card
        className={classNames({
          [classes.card]: true,
          [classes.highlightedBorder]: isRecommended || isActive
        })}
      >
        <div className={classes.planInfo}>
          {isRecommended ? (
            <Typography variant="overline" color="primary">
              {t("subscription.recommended-plan")}
            </Typography>
          ) : (
            isActive && (
              <Typography variant="overline" color="primary">
                {t("subscription.current-plan")}
              </Typography>
            )
          )}
        </div>
        <CardHeader
          title={<Typography variant="h6">{planName}</Typography>}
          subheader={
            <a
              className={classes.link}
              href={planLink}
              target="_blank"
              rel="noreferrer"
            >
              {t("subscription.more-info-about-this-plan")}
            </a>
          }
        />
        <CardContent className={classes.content}>{features}</CardContent>
        <div className={classes.filler} />
        <CardActions>
          {isRequested ? (
            <Button fullWidth disabled={isDisabled} onClick={onClick}>
              {t("subscription.plan-requested")}
            </Button>
          ) : isActive ? (
            <Button fullWidth disabled>
              {t("subscription.plan-active")}
            </Button>
          ) : (
            <Button
              fullWidth
              color="primary"
              variant={isRecommended ? "contained" : "text"}
              disabled={isDisabled}
              onClick={onClick}
            >
              {t("subscription.select-plan", { planName })}
            </Button>
          )}
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(Plan)
);
