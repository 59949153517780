import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import { fulfilmentHelper } from "tap-io/helpers";
import FulfilmentMethod from "tap-io/models/fulfilment/FulfilmentMethod";
import ModeName from "tap-io/models/mode/ModeName";

import withBarAuthorization from "../components/auth/withBarAuthorization";
import withMode from "../components/auth/withMode";
import InfoSplash from "../components/help/InfoSplash";
import TopItemsInQueueRightNowCard from "../components/order/TopItemsInQueueRightNowCard";
import AllOpenOrders from "../components/order/AllOpenOrders";
import OrderAlert from "../components/order/OrderAlert";

const SUBHEADER_HEIGHT = 78;
const MARGIN = 20;

const styles = (theme) => ({
  content: {
    position: "relative",
    minHeight: "calc(100vh - 100px)",
    margin: MARGIN,
    marginBottom: SUBHEADER_HEIGHT + MARGIN
  },
  splash: {
    position: "absolute",
    width: "100%",
    height: "100%"
  },
  spacing: {
    height: 10
  }
});

class OrdersPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  parseOpenOrders = () => {
    const { openOrders, base } = this.props;

    let queuedOrdersCount = 0;
    const openOrdersPerFulfilmentMethod = {};

    if (openOrders) {
      openOrders.forEach((order) => {
        if (!openOrdersPerFulfilmentMethod[order.fulfilment.method]) {
          openOrdersPerFulfilmentMethod[order.fulfilment.method] = [];
        }

        openOrdersPerFulfilmentMethod[order.fulfilment.method].push(order);

        if (order.isStatusQueued(base)) {
          queuedOrdersCount++;
        }
      });
    }

    return {
      queuedOrdersCount,
      openOrdersPerFulfilmentMethod
    }
  };

  getFulfilmentMethods = (orderFulfilmentMethodsAsArray) => {
    const { bar, base } = this.props;

    const orderFulfilmentMethods =
      fulfilmentHelper.createFulfilmentMethodsFromArray(
        orderFulfilmentMethodsAsArray
      );

    if (bar.isUsingBases() && base) {
      return fulfilmentHelper.mergeFulfilmentMethods(
        orderFulfilmentMethods,
        base.serviceOptions.fulfilmentMethods
      );
    }

    const defaultFulfilmentMethods =
      fulfilmentHelper.createFulfilmentMethodsFromArray([
        FulfilmentMethod.AS_SOON_AS_POSSIBLE
      ]);

    return fulfilmentHelper.mergeFulfilmentMethods(
      orderFulfilmentMethods,
      defaultFulfilmentMethods
    );
  };

  render() {
    const {
      theme,
      classes,
      t,
      contentWidth,
      isAppLocked,
      timeOffset,
      bar,
      deviceSettings,
      assets,
      base,
      openOrders
    } = this.props;

    const { queuedOrdersCount, openOrdersPerFulfilmentMethod } = this.parseOpenOrders();
    const fulfilmentMethods = this.getFulfilmentMethods(
      Object.keys(openOrdersPerFulfilmentMethod)
    );

    const hasOpenOrders = openOrders && openOrders.length > 0;

    return (
      <>
        {hasOpenOrders && (
          <TopItemsInQueueRightNowCard
            base={base}
            openOrders={openOrders}
            width={contentWidth}
            height={SUBHEADER_HEIGHT}
          />
        )}
        <div className={classes.content}>
          <div className={classes.splash}>
            <InfoSplash bar={bar} />
          </div>
          <OrderAlert
            isActive={queuedOrdersCount > 0}
            timeout={90000}
            title={
              queuedOrdersCount > 0
                ? queuedOrdersCount === 1
                  ? t("order.one-queued-order")
                  : t("order.x-queued-orders", { orderCount: queuedOrdersCount })
                : t("order.no-queued-orders")
            }
            subtitle={base ? base.name : undefined}
          />
          <AllOpenOrders
            cardWidth={`calc(${isAppLocked ? "33" : "50"}% - ${theme.spacing(2)})`}
            timeOffset={timeOffset}
            bar={bar}
            deviceSettings={deviceSettings}
            base={base}
            assets={assets}
            fulfilmentMethods={fulfilmentMethods}
            openOrdersPerFulfilmentMethod={openOrdersPerFulfilmentMethod}
          />
        </div>
      </>

    );
  }
}

// {(!allOrders || allOrders.length === 0) && <OrdersQuickStartSplash />}

export default withBarAuthorization()(
  withMode(ModeName.ORDER)(
    withStyles(styles, { withTheme: true })(
      withTranslation("common")(OrdersPage)
    )
  )
);
