import { utilsHelper } from "../../helpers";
import { isEnum } from "../../helpers/ValidationHelper";
import DeliveryColors from "../delivery/DeliveryColors";
import DeliveryMethods from "../delivery/DeliveryMethods";
import Locale from "../locale/Locale";
import PropsModel from "../model/PropsModel";
import PaymentCurrency from "../payment/PaymentCurrency";
import PaymentProvider from "../payment/PaymentProvider";
import CustomerField from "./CustomerField";
import EmailCustomerField from "./EmailCustomerField";
import Integrations from "./Integrations";
import Params from "./Params";

const DEFAULT_COLOR_PRIMARY: string = "#34b9c8";
const DEFAULT_COLOR_SECONDARY: string = "#faeb52";
const DEFAULT_LOCALE: Locale = Locale.NL_BE;
const DEFAULT_ORDER_CURRENCY: PaymentCurrency = PaymentCurrency.EUR;

class Bar extends PropsModel {
  private readonly _name: string;
  private readonly _params: Params;
  private readonly _onlinePayments: any;
  private readonly _deliveryMethods: DeliveryMethods;
  private readonly _deliveryColors: DeliveryColors;
  private readonly _integrations: Integrations;
  private readonly _logo: string;
  private readonly _cover: string;
  private readonly _timestamp: Date;
  private readonly _locales: { app: Locale; order: Locale };
  private _colors: { primary: string; secondary: string };
  private _isLive: boolean;
  private _playSound: boolean;

  constructor(id: string, data: any) {
    if (!id) {
      throw new Error("error.id-is-not-defined");
    }
    super(id);

    if (!data) {
      throw new Error("error.data-is-not-defined");
    }
    if (!data.name) {
      throw new Error("error.data-name-is-not-defined");
    }
    if (!data.params) {
      throw new Error("error.data-params-is-not-defined");
    }
    if (!data.colors) {
      throw new Error("error.data-colors-is-not-defined");
    }
    if (!data.timestamp) {
      throw new Error("error.data-timestamp-is-not-defined");
    }

    this._name = data.name;
    this._params = new Params(data.params);
    this._onlinePayments = this.parseOnlinePayments(data.onlinePayments);
    this._deliveryMethods = new DeliveryMethods(data.deliveryMethods);
    this._deliveryColors = new DeliveryColors(data.deliveryColors);
    this._integrations = new Integrations(data.integrations);
    this._logo = data.logo;
    this._cover = data.cover;
    this._colors = data.colors;
    this._isLive = data.isLive === true;
    this._playSound = data.playSound === true;
    this._timestamp = data.timestamp;

    this._locales = data.locales || {
      app: DEFAULT_LOCALE,
      order: DEFAULT_LOCALE
    };

    /*super.setAllProps([
      "name",
      "params",
      "onlinePayments",
      "logo",
      "cover",
      "colors",
      "isLive",
      "playSound",
      "timestamp",
      "locales"
    ]);*/
  }

  // TO FIX
  private parseOnlinePayments(onlinePayments: any) {
    if (onlinePayments?.paymentProviders) {
      for (let paymentProvider in onlinePayments.paymentProviders) {
        const paymentProviderConf =
          onlinePayments.paymentProviders[paymentProvider];

        if (
          paymentProvider === PaymentProvider.PAYCONIQ &&
          paymentProviderConf
        ) {
          if (
            paymentProviderConf.isActive &&
            !paymentProviderConf.isOnlineActive &&
            !paymentProviderConf.isPosActive
          ) {
            paymentProviderConf.isOnlineActive = true;
          }
        }
      }
    }

    return onlinePayments;
  }

  private getColor(colorName: string): string {
    switch (colorName) {
      case "primary":
        return this._colors && this._colors["primary"]
          ? this._colors["primary"]
          : DEFAULT_COLOR_PRIMARY;
      case "secondary":
        return this._colors && this._colors["secondary"]
          ? this._colors["secondary"]
          : DEFAULT_COLOR_SECONDARY;
    }

    throw new Error("colorName is invalid");
  }

  private getLocale(localeName: string): Locale {
    if (localeName !== "app" && localeName !== "order") {
      throw new Error("localeName is invalid");
    }

    return this._locales[localeName];
  }

  public get name(): string {
    return this._name;
  }

  public get params() {
    return this._params;
  }

  public get paymentProviders() {
    return this._onlinePayments ? this._onlinePayments.paymentProviders : {};
  }

  public get deliveryMethods(): DeliveryMethods {
    return this._deliveryMethods;
  }

  public get deliveryColors(): DeliveryColors {
    return this._deliveryColors;
  }

  public get integrations(): Integrations {
    return this._integrations;
  }

  public get isLive(): boolean {
    return this._isLive;
  }

  public set isLive(isLive: boolean) {
    this._isLive = isLive;
    super.setPropUpdated("isLive");
  }

  public get playSound(): boolean {
    return this._playSound;
  }

  public get timestamp() {
    return this._timestamp;
  }

  public isAllowingOnlinePayments(): boolean {
    return this.params.allowOnlinePayments;
  }

  public isAllowingTips(): boolean {
    return this.params.allowTips;
  }

  public isUsingBases(): boolean {
    return this.params.useBases;
  }

  public isUsingZones(): boolean {
    return this.params.useZones;
  }

  public getLogoFileName(): string {
    return this._logo;
  }

  public getCoverFileName(): string {
    return this._cover;
  }

  protected get colors() {
    return this._colors;
  }

  public areTherePaymentProvidersAvailable(): boolean {
    const paymentProviders = this.paymentProviders;

    for (let provider in paymentProviders) {
      if (
        paymentProviders[provider] &&
        paymentProviders[provider].isActive === true
      ) {
        return true;
      }
    }

    return false;
  }

  public areThereOnlinePaymentProvidersAvailable(): boolean {
    const paymentProviders = this.paymentProviders;

    for (let provider in paymentProviders) {
      if (paymentProviders[provider]) {
        if (
          provider === PaymentProvider.VIVAWALLET ||
          provider === PaymentProvider.PAYCONIQ
        ) {
          if (paymentProviders[provider].isOnlineActive) {
            return true;
          }
        } else {
          if (paymentProviders[provider].isActive) {
            return true;
          }
        }
      }
    }

    return false;
  }

  public getAvailableOnlinePaymentProvidersCount(): number {
    const paymentProviders = this.paymentProviders;

    let count = 0;

    for (let provider in paymentProviders) {
      if (paymentProviders[provider]) {
        if (
          provider === PaymentProvider.VIVAWALLET ||
          provider === PaymentProvider.PAYCONIQ
        ) {
          if (paymentProviders[provider].isOnlineActive) {
            count++;
          }
        } else {
          if (paymentProviders[provider].isActive) {
            count++;
          }
        }
      }
    }

    return count;
  }

  public isAllowingPaymentsFromProvider(
    paymentProvider: PaymentProvider,
    isActiveFieldName: string = "isActive"
  ): boolean {
    const paymentProviders = this.paymentProviders;

    return paymentProviders[paymentProvider]
      ? paymentProviders[paymentProvider][isActiveFieldName] === true
      : false;
  }

  public isAllowingDeferredPayments(): boolean {
    return this.isAllowingPaymentsFromProvider(PaymentProvider.DEFERRED);
  }

  public isAllowingVoucherPayments(): boolean {
    return this.isAllowingPaymentsFromProvider(PaymentProvider.VOUCHER);
  }

  public isAllowingMolliePayments(): boolean {
    return this.isAllowingPaymentsFromProvider(PaymentProvider.MOLLIE);
  }

  public isAllowingVivaWalletPayments(): boolean {
    return this.isAllowingPaymentsFromProvider(PaymentProvider.VIVAWALLET);
  }

  public isAllowingVivaWalletOnlinePayments(): boolean {
    return this.isAllowingPaymentsFromProvider(
      PaymentProvider.VIVAWALLET,
      "isOnlineActive"
    );
  }

  public isAllowingVivaWalletPosPayments(): boolean {
    return this.isAllowingPaymentsFromProvider(
      PaymentProvider.VIVAWALLET,
      "isPosActive"
    );
  }

  public isAllowingCcvPayments(): boolean {
    return this.isAllowingPaymentsFromProvider(PaymentProvider.CCV);
  }

  public isAllowingMultiSafePayPayments(): boolean {
    return this.isAllowingPaymentsFromProvider(PaymentProvider.MULTISAFEPAY);
  }

  public isAllowingStarnetPayments(): boolean {
    return this.isAllowingPaymentsFromProvider(PaymentProvider.STARNET);
  }

  public isAllowingPayconiqPayments(): boolean {
    return this.isAllowingPaymentsFromProvider(PaymentProvider.PAYCONIQ);
  }

  public isAllowingPayconiqOnlinePayments(): boolean {
    return this.isAllowingPaymentsFromProvider(
      PaymentProvider.PAYCONIQ,
      "isOnlineActive"
    );
  }

  public isAllowingPayconiqPosPayments(): boolean {
    return this.isAllowingPaymentsFromProvider(
      PaymentProvider.PAYCONIQ,
      "isPosActive"
    );
  }

  public isPinEnabledForDeferredPayments(): boolean {
    const paymentProviders = this.paymentProviders;

    return (
      paymentProviders[PaymentProvider.DEFERRED] &&
      paymentProviders[PaymentProvider.DEFERRED].isPinEnabled === true
    );
  }

  public getPrimaryColor(): string {
    return this.getColor("primary");
  }

  public getSecondaryColor(): string {
    return this.getColor("secondary");
  }

  public getAppLocale(): Locale {
    return this.getLocale("app");
  }

  public getOrderLocale(): Locale {
    return this.getLocale("order");
  }

  public getAppLang(): string {
    return utilsHelper.getLangFromLocale(this.getAppLocale());
  }

  public getOrderLang(): string {
    return utilsHelper.getLangFromLocale(this.getOrderLocale());
  }

  public set locale(locale: Locale) {
    this.params.locale = locale;
  }

  public getPaymentCurrency(): PaymentCurrency | null {
    const orderCurrency = this.getOrderCurrency();

    return isEnum(orderCurrency) ? (orderCurrency as PaymentCurrency) : null;
  }

  public getOrderCurrency(): string {
    return this.params.orderCurrency
      ? this.params.orderCurrency
      : DEFAULT_ORDER_CURRENCY;
  }

  public getCustomerAddressedAs(
    nameFallback: string,
    tableNumberOrOtherRefTranslation: string
  ): string {
    return this.params
      ? this.params.useZones === true
        ? tableNumberOrOtherRefTranslation //"Tafelnummer of andere referentie"
        : this.params.customerAddressedAs
        ? this.params.customerAddressedAs
        : nameFallback
      : nameFallback;
  }

  public hasCustomerFields(): boolean {
    return !!(
      this.params &&
      this.params.customerFields &&
      this.params.customerFields.length > 0
    );
  }

  public getCustomerFields(): CustomerField[] {
    return this.params && this.params.customerFields
      ? this.params.customerFields
      : [];
  }

  public getCustomerFieldToSendOrderConfirmation(): EmailCustomerField | null {
    const customerFields = this.getCustomerFields();

    for (let i = 0; i < customerFields.length; i++) {
      const customerField = customerFields[i];

      if (
        customerField instanceof EmailCustomerField &&
        customerField.sendOrderConfirmationToThisAddress
      ) {
        return customerField;
      }
    }

    return null;
  }

  public hasCustomerFieldToSendOrderConfirmation(): boolean {
    return this.getCustomerFieldToSendOrderConfirmation() !== null;
  }

  public getMaxAmountOfItemsPerOrder(): number {
    return this.params && this.params.maxAmountOfItemsPerOrder;
  }
}

export default Bar;
