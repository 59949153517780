import * as routes from "./routes";
import ModeName from "tap-io/models/mode/ModeName";

export const MODES = {
  [ModeName.ORDER]: {
    name: ModeName.ORDER,
    fetchAllOrders: true,
    fetchOpenOrders: true,
    showCreateOrderButton: true,
    showScanOrdersButton: true,
    isUsingBases: true,
    defaultRoute: routes.ORDERS,
    routes: [routes.ORDERS, routes.MENUS]
  },
  [ModeName.CHECKOUT]: {
    name: ModeName.CHECKOUT,
    defaultRoute: routes.CHECKOUT,
    routes: [routes.CHECKOUT]
  },
  [ModeName.VOUCHER]: {
    name: ModeName.VOUCHER,
    defaultRoute: routes.VOUCHERS,
    routes: [routes.VOUCHERS]
  },
  [ModeName.OVERVIEW]: {
    name: ModeName.OVERVIEW,
    defaultRoute: routes.OVERVIEW,
    routes: [routes.OVERVIEW]
  }
};

export const DEFAULT_MODE = MODES[ModeName.ORDER];
