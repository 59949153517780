import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Button, IconButton, Switch } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import ClearIcon from "@mui/icons-material/Clear";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import LockIcon from "@mui/icons-material/Lock";
import withStyles from "@mui/styles/withStyles";

import {
  dataService,
  depositService,
  menuService
} from "tap-io/client/services";
import { catalogueHelper, menuHelper, utilsHelper } from "tap-io/helpers";
import ConfirmDialog from "tap-io/client/components/common/ConfirmDialog";
import ContentCard from "tap-io/client/components/common/ContentCard";

import AddMenuDialog from "./AddMenuDialog";
import EditMenuNameDialog from "./EditMenuNameDialog";
import EditMenu from "./EditMenu";
import defaultCatalogue from "../../constants/catalogue";

const styles = (theme) => ({
  menus: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  inactiveMenu: {
    opacity: 0.4
  },
  addIcon: {
    padding: 12
  },
  menuName: {
    display: "flex",
    alignItems: "center"
  },
  lockIcon: {
    marginLeft: theme.spacing(0.5),
    fontSize: 20,
    color: theme.palette.text.primary
  }
});

class EditMenus extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isAddMenuDialogOpen: false,
      menuPendingRemovalConfirmation: null,
      menuOfWhichNameIsBeingEdited: null,
      menuBeingSelectedFrom: null,
      menuClipboard: [],
      menuIdsLinkedToABase: [],
      catalogue: null,
      deposits: []
    };
  }

  componentDidMount() {
    const { bar } = this.props;

    const menuIdsLinkedToABase = this.getMenuIdsLinkedToABase();
    this.setState({ menuIdsLinkedToABase });

    this.unsubscribeCatalogueData = dataService.onCatalogueData(
      bar.id,
      (catalogueData) => {
        this.setState({
          catalogue: catalogueHelper.createCatalogue(
            defaultCatalogue,
            catalogueData
          )
        });
      }
    );

    this.unsubscribeDeposits = depositService.onAllDeposits(
      bar.id,
      (deposits) => {
        this.setState({ deposits: deposits || [] });
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { t } = this.props;

    // Check if the menus that are linked to a base have changed
    const menuIdsLinkedToABase = this.getMenuIdsLinkedToABase();
    const prevMenuIdsLinkedToABase = prevState.menuIdsLinkedToABase;

    if (
      !utilsHelper.areArraysEqualShallow(
        menuIdsLinkedToABase,
        prevMenuIdsLinkedToABase
      )
    ) {
      this.setState({ menuIdsLinkedToABase });
    }

    // Check the clipboard status, and update the toast displaying clipboard content accordingly
    const { menuClipboard } = this.state;
    const prevMenuClipboard = prevState.menuClipboard;

    if (menuClipboard.length !== prevMenuClipboard.length) {
      if (menuClipboard.length === 0) {
        if (this.clipboardToastId) {
          toast.dismiss(this.clipboardToastId);
          this.clipboardToastId = undefined;
        }
      } else {
        const clipboardToastText = `${t("label.clipboard")}: ${
          menuClipboard.length
        } ${t(menuClipboard.length === 1 ? "label.item" : "label.items")}`;

        if (this.clipboardToastId) {
          toast.update(this.clipboardToastId, {
            render: clipboardToastText
          });
        } else {
          this.clipboardToastId = toast(clipboardToastText, {
            autoClose: false,
            closeOnClick: false,
            onClose: () => {
              this.handleCancelSelectFromMenu();
              this.clipboardToastId = undefined;
            }
          });
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeCatalogueData) {
      this.unsubscribeCatalogueData();
      this.unsubscribeCatalogueData = undefined;
    }
    if (this.unsubscribeDeposits) {
      this.unsubscribeDeposits();
      this.unsubscribeDeposits = undefined;
    }
  }

  getMenuIdsLinkedToABase = () => {
    const { bar, allBases } = this.props;

    return bar.isUsingBases() && allBases
      ? utilsHelper.removeArrayDuplicates(
          allBases
            .map((base) => base.menuIds)
            .reduce(
              (menuIds, menuIdsForBase) => menuIds.concat(menuIdsForBase),
              []
            )
        )
      : [];
  };

  handleAddMenu = () => {
    this.setState({ isAddMenuDialogOpen: true });
  };

  handleAddMenuDialogClose = () => {
    this.setState({ isAddMenuDialogOpen: false });
  };

  handleRemoveMenu = (menu) => (event) => {
    this.setState({
      menuPendingRemovalConfirmation: menu
    });
  };

  handleRemoveMenuConfirm = async () => {
    const { t } = this.props;
    const { menuPendingRemovalConfirmation } = this.state;

    this.setState({ menuPendingRemovalConfirmation: null });

    const toastId = toast(t("menu.removing-menu"), { autoClose: false });

    try {
      await menuService.removeMenu(menuPendingRemovalConfirmation);

      toast.update(toastId, {
        render: t("menu.menu-removed"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleRemoveMenuCancel = () => {
    this.setState({ menuPendingRemovalConfirmation: null });
  };

  handleChangeMenu = (menu) => (event) => {
    this.setState({ menuOfWhichNameIsBeingEdited: menu });
  };

  handleEditMenuNameDialogClose = () => {
    this.setState({ menuOfWhichNameIsBeingEdited: null });
  };

  handleToggleMenuIsActive = (menu) => (event) => {
    menuService.toggleMenuIsActive(menu);
  };

  handleSelectFromMenu = (menu) => (event) => {
    this.setState({ menuBeingSelectedFrom: menu });
  };

  handleMenuSelectionChange = (selection) => {
    this.setState({
      menuClipboard: selection
    });
  };

  handlePasteToMenu = (menu) => async (event) => {
    const { t } = this.props;
    const { menuClipboard } = this.state;

    const toastId = toast(t("label.copying"), { autoClose: false });

    try {
      const elementsToAdd = menuClipboard.map((element) =>
        menuHelper.isCategoryMenuElement(element)
          ? menuService.createMenuCategory(element.menuId, element)
          : menuHelper.isItemMenuElement(element)
          ? menuService.createMenuItem(element.menuId, element)
          : null
      );

      await menuService.addMenuElements(menu, elementsToAdd);

      toast.update(toastId, {
        render: t("label.copied"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleCancelSelectFromMenu = () => {
    this.setState({ menuBeingSelectedFrom: null, menuClipboard: [] });
  };

  getMenusLinkedToABaseFirstCompareFunction = (menuA, menuB) => {
    const { base } = this.props;
    const { menuIdsLinkedToABase } = this.state;

    const currentBaseMenuIds = base ? base.menuIds : [];

    const menuALinkedToCurrentBase = currentBaseMenuIds.indexOf(menuA.id) >= 0;
    const menuBLinkedToCurrentBase = currentBaseMenuIds.indexOf(menuB.id) >= 0;
    const menuALinkedToABase = menuIdsLinkedToABase.indexOf(menuA.id) >= 0;
    const menuBLinkedToABase = menuIdsLinkedToABase.indexOf(menuB.id) >= 0;

    return menuALinkedToCurrentBase
      ? menuBLinkedToCurrentBase
        ? 0
        : -1
      : menuBLinkedToCurrentBase
      ? 1
      : menuALinkedToABase
      ? menuBLinkedToABase
        ? 0
        : -1
      : menuBLinkedToABase
      ? 1
      : 0;
  };

  isMenuLinkedToABase = (menu) => {
    const { menuIdsLinkedToABase } = this.state;
    return menuIdsLinkedToABase.indexOf(menu.id) >= 0;
  };

  isSelectingFromMenu = (menu) => {
    const { menuBeingSelectedFrom } = this.state;

    return menuBeingSelectedFrom && menuBeingSelectedFrom.id === menu.id;
  };

  isClipboardEmpty = () => {
    const { menuClipboard } = this.state;

    return !menuClipboard || menuClipboard.length === 0;
  };

  render() {
    const { classes, t, isEditable, bar, base, menus } = this.props;
    const {
      isAddMenuDialogOpen,
      menuPendingRemovalConfirmation,
      menuOfWhichNameIsBeingEdited,
      catalogue,
      deposits
    } = this.state;

    const currentBaseMenuIds = bar.isUsingBases() && base ? base.menuIds : [];

    return (
      <div className={classes.menus}>
        <AddMenuDialog
          isOpen={isAddMenuDialogOpen}
          onClose={this.handleAddMenuDialogClose}
          bar={bar}
        />
        <EditMenuNameDialog
          isOpen={menuOfWhichNameIsBeingEdited !== null}
          onClose={this.handleEditMenuNameDialogClose}
          menu={menuOfWhichNameIsBeingEdited}
        />
        <ConfirmDialog
          isOpen={menuPendingRemovalConfirmation !== null}
          title={t("menu.confirm-removal-of-menu")}
          description={t("menu.are-you-sure-you-want-to-remove-this-menu")}
          confirmButtonLabel={t("label.remove")}
          onConfirm={this.handleRemoveMenuConfirm}
          onCancel={this.handleRemoveMenuCancel}
        />
        {menus &&
          menus
            .sort(this.getMenusLinkedToABaseFirstCompareFunction)
            .map((menu) => (
              <ContentCard
                key={menu.id}
                title={
                  <span className={classes.menuName}>
                    {menu.name ? menu.name : t("menu.menu")}
                    {!menu.isEditable && (
                      <LockIcon className={classes.lockIcon} />
                    )}
                  </span>
                }
                subtitle={
                  currentBaseMenuIds.indexOf(menu.id) >= 0
                    ? t("menu.linked-to-current-base")
                    : this.isMenuLinkedToABase(menu)
                    ? t("menu.linked-to-a-base")
                    : menu.isActive
                    ? undefined
                    : t("menu.menu-is-not-active")
                }
                headerLeft={
                  !bar.isUsingBases() && (
                    <Switch
                      onChange={this.handleToggleMenuIsActive(menu)}
                      checked={menu.isActive}
                    />
                  )
                }
                headerRight={
                  isEditable &&
                  menu.isEditable && (
                    <div>
                      {this.isSelectingFromMenu(menu) ? (
                        <div>
                          <IconButton
                            onClick={this.handleCancelSelectFromMenu}
                            size="large"
                          >
                            <ClearIcon />
                          </IconButton>
                        </div>
                      ) : (
                        <div>
                          {!this.isClipboardEmpty() && (
                            <Button
                              color="primary"
                              startIcon={<AddToPhotosIcon />}
                              onClick={this.handlePasteToMenu(menu)}
                            >
                              {t("label.paste")}
                            </Button>
                          )}
                          {!this.isMenuLinkedToABase(menu) && (
                            <IconButton
                              onClick={this.handleChangeMenu(menu)}
                              size="large"
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                          <IconButton
                            onClick={this.handleSelectFromMenu(menu)}
                            size="large"
                          >
                            <LibraryAddCheckIcon />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  )
                }
                footer={
                  isEditable &&
                  !this.isSelectingFromMenu(menu) &&
                  !this.isMenuLinkedToABase(menu) &&
                  menus.length > 1 && (
                    <Button
                      color="primary"
                      fullWidth
                      onClick={this.handleRemoveMenu(menu)}
                    >
                      {t("menu.remove-menu")}
                    </Button>
                  )
                }
              >
                <div
                  className={classNames({
                    [classes.inactiveMenu]: !menu.isActive
                  })}
                >
                  <EditMenu
                    bar={bar}
                    menu={menu}
                    catalogue={catalogue}
                    deposits={deposits}
                    isEditable={isEditable}
                    isSelecting={this.isSelectingFromMenu(menu)}
                    onSelectionChange={this.handleMenuSelectionChange}
                  />
                </div>
              </ContentCard>
            ))}
        {isEditable && !bar.isUsingBases() && (
          <ContentCard
            maxWidth="100%"
            title={t("menu.new-menu")}
            subtitle={t("menu.add-a-new-menu")}
            headerRight={
              <div className={classes.addIcon}>
                <AddIcon />
              </div>
            }
            onClick={this.handleAddMenu}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditMenus)
);
