import { FormControl, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { baseService } from "tap-io/client/services";
import Base from "tap-io/models/base/Base";

function BaseSelector({
  organisationId,
  allBases,
  value,
  onChange,
  isDisabled,
  t
}: {
  organisationId: string;
  allBases?: Base[];
  value?: string;
  onChange: (baseId: string | undefined) => void;
  isDisabled?: boolean;
  t: any;
}) {
  const [bases, setBases] = useState<Base[]>([]);

  useEffect(() => {
    if (allBases) {
      setBases(allBases);
    } else {
      return baseService.onAllBases(organisationId, (bases) => setBases(bases));
    }
  }, [organisationId, allBases]);

  return (
    <FormControl sx={{ width: "100%", textAlign: "left" }}>
      <Select
        displayEmpty
        variant="outlined"
        fullWidth
        value={value || "undefined"}
        onChange={(e) => {
          const value = e.target.value;
          onChange(value === "undefined" ? undefined : value);
        }}
        disabled={isDisabled}
      >
        <MenuItem value="undefined">
          {t("stats.input.base-selector.all-bases")}
        </MenuItem>
        {bases?.map((base) => (
          <MenuItem key={base.id} value={base.id}>
            {base.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default BaseSelector;
