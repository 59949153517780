import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from "@mui/material";
import CropFreeIcon from "@mui/icons-material/CropFree";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";

import AuthorizedAppLink from "../auth/AuthorizedAppLink";
import ShowZoneLinkDialog from "./ShowBarZoneLinkDialog";

const styles = (theme) => ({});

class ShowZones extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      zoneBeingShown: null
    };
  }

  handleShowZoneLink = (zone) => (event) => {
    this.setState({ zoneBeingShown: zone });
  };

  handleShowZoneLinkDialogClose = () => {
    this.setState({ zoneBeingShown: null });
  };

  render() {
    const { t, bar, allZones } = this.props;
    const { zoneBeingShown } = this.state;

    return (
      <div>
        <ShowZoneLinkDialog
          isOpen={zoneBeingShown !== null}
          onClose={this.handleShowZoneLinkDialogClose}
          bar={bar}
          zone={zoneBeingShown}
        />
        <List>
          {allZones &&
            allZones.map((zone) => (
              <ListItem key={zone.code}>
                <ListItemIcon>
                  <IconButton
                    onClick={this.handleShowZoneLink(zone)}
                    size="large"
                  >
                    <CropFreeIcon />
                  </IconButton>
                </ListItemIcon>
                <ListItemText primary={`${zone.name} (${zone.code})`} />
                <ListItemSecondaryAction>
                  {zone.layout.hasChildren && (
                    <AuthorizedAppLink
                      api={config.functions.promoApi}
                      bar={bar}
                      endpoint="layoutlocations"
                      params={{ zoneCode: zone.code }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      icon={<ArrowDownwardIcon />}
                    />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(ShowZones)
);
