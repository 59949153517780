import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Switch } from "@mui/material";
import VolumeMuteIcon from "@mui/icons-material/VolumeMute";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import withStyles from "@mui/styles/withStyles";
import BarMenuItem from "./BarMenuItem";

const styles = (theme) => ({});

class BarSoundControlsMenuItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t, isMuted, toggleMuted } = this.props;

    return (
      <BarMenuItem
        icon={isMuted ? <VolumeMuteIcon /> : <VolumeUpIcon />}
        label={t("label.sounds")}
        secondaryAction={
          <Switch edge="end" checked={!isMuted} onChange={toggleMuted} />
        }
      />
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(BarSoundControlsMenuItem)
);
