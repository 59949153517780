import React, { PureComponent } from "react";

import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import withStyles from "@mui/styles/withStyles";

import { feeService } from "tap-io/client/services";
import ConfirmDialog from "tap-io/client/components/common/ConfirmDialog";
import FeeType from "tap-io/models/fee/FeeType";

import AddFeeDialog from "../fee/AddFeeDialog";
import EditFeeDialog from "../fee/EditFeeDialog";

const styles = (theme) => ({
  feeName: {
    display: "flex",
    alignItems: "center"
  },
  lockIcon: {
    marginLeft: theme.spacing(0.5),
    fontSize: 16,
    color: theme.palette.text.primary
  }
});

class EditFees extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isAddFeeDialogOpen: false,
      feePendingRemovalConfirmation: null,
      feeBeingEdited: null,
      isReady: false,
      fees: []
    };
  }

  componentDidMount() {
    this.initFees();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { bar } = this.props;

    const barId = bar?.id;
    const prevBarId = prevProps.bar?.id;

    if (barId !== prevBarId) {
      this.initFees();
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeFees) {
      this.unsubscribeFees();
      this.unsubscribeFees = undefined;
    }
  }

  initFees = () => {
    const { bar } = this.props;

    if (this.unsubscribeFees) {
      this.unsubscribeFees();
      this.unsubscribeFees = undefined;
    }

    this.unsubscribeFees = feeService.onAllFees(bar.id, (fees) => {
      this.setState({
        isReady: true,
        fees
      });
    });
  };

  handleAddFee = (event) => {
    this.setState({ isAddFeeDialogOpen: true });
  };

  handleAddFeeDialogClose = () => {
    this.setState({ isAddFeeDialogOpen: false });
  };

  handleEditFee = (fee) => (event) => {
    this.setState({ feeBeingEdited: fee });
  };

  handleEditFeeDialogClose = () => {
    this.setState({ feeBeingEdited: null });
  };

  handleRemoveFee = (fee) => (event) => {
    event.stopPropagation();

    this.setState({
      feePendingRemovalConfirmation: fee
    });
  };

  handleRemoveFeeCancel = () => {
    this.setState({ feePendingRemovalConfirmation: null });
  };

  handleRemoveFeeConfirm = async () => {
    const { t } = this.props;
    const { feePendingRemovalConfirmation } = this.state;

    this.setState({ feePendingRemovalConfirmation: null });

    const toastId = toast(t("fee.removing-fee"), {
      autoClose: false
    });

    try {
      await feeService.removeFee(feePendingRemovalConfirmation);

      toast.update(toastId, {
        render: t("fee.fee-removed"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleFeeIsActiveChange = (fee) => async (event) => {
    event.preventDefault();

    const { t } = this.props;

    const toastId = toast(
      fee.isActive ? t("fee.disabling-fee") : t("fee.enabling-fee"),
      {
        autoClose: false
      }
    );

    try {
      fee.isActive = !fee.isActive;
      await feeService.updateFee(fee);

      toast.update(toastId, {
        render: fee.isActive ? t("fee.fee-enabled") : t("fee.fee-disabled"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { t, classes, bar, subscription, isEditable } = this.props;
    const {
      fees,
      isAddFeeDialogOpen,
      feePendingRemovalConfirmation,
      feeBeingEdited,
      isReady
    } = this.state;

    return (
      <div>
        <AddFeeDialog
          isOpen={isAddFeeDialogOpen}
          onClose={this.handleAddFeeDialogClose}
          bar={bar}
          subscription={subscription}
        />
        <EditFeeDialog
          isOpen={feeBeingEdited !== null}
          onClose={this.handleEditFeeDialogClose}
          bar={bar}
          subscription={subscription}
          fee={feeBeingEdited}
        />
        <ConfirmDialog
          isOpen={feePendingRemovalConfirmation !== null}
          title={t("fee.confirm-removal-of-fee")}
          description={t("fee.are-you-sure-you-want-to-remove-this-fee")}
          confirmButtonLabel={t("label.remove")}
          onConfirm={this.handleRemoveFeeConfirm}
          onCancel={this.handleRemoveFeeCancel}
        />
        <List>
          {isReady &&
            fees.map((fee) => (
              <ListItem
                key={fee.id}
                onClick={
                  isEditable && fee.isEditable
                    ? this.handleEditFee(fee)
                    : undefined
                }
              >
                {isEditable && (
                  <ListItemIcon>
                    <IconButton
                      onClick={this.handleRemoveFee(fee)}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={
                    <span className={classes.feeName}>
                      {fee.name}
                      {!fee.isEditable && (
                        <LockIcon className={classes.lockIcon} />
                      )}
                    </span>
                  }
                  secondary={`${t(`fee.${fee.type}`)} (${
                    fee.type === FeeType.FLAT
                      ? `${fee.value} ${bar.getOrderCurrency()}`
                      : ""
                  }${
                    fee.type === FeeType.VARIABLE
                      ? `${Math.ceil(fee.value * 100)}%`
                      : ""
                  })`}
                />
                <ListItemSecondaryAction>
                  <Switch
                    disabled={!isEditable || !fee.isEditable}
                    checked={fee.isActive}
                    onChange={this.handleFeeIsActiveChange(fee)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          {isEditable && (
            <ListItem>
              <Button
                fullWidth
                startIcon={<AddIcon />}
                onClick={this.handleAddFee}
              >
                {t("fee.add-fee")}
              </Button>
            </ListItem>
          )}
        </List>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditFees)
);
