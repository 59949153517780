import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import ConfirmDialog from "tap-io/client/components/common/ConfirmDialog";

const styles = (theme) => ({
  message: {
    display: "block",
    marginTop: theme.spacing(),
    fontWeight: "bold",
    color: theme.palette.error.main
  }
});

class ConfirmOverrideSubOrdersDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, isOpen, message, onConfirm, onCancel } = this.props;

    return (
      <ConfirmDialog
        isOpen={isOpen}
        title={t("order.confirm-override-suborders")}
        description={
          <span>
            {t("order.are-you-sure-you-want-to-override-suborders")}
            {message && <span className={classes.message}>{message}</span>}
          </span>
        }
        confirmButtonLabel={t("order.override-suborders")}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(ConfirmOverrideSubOrdersDialog)
);
