import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import AssignmentIcon from "@mui/icons-material/Assignment";
import withStyles from "@mui/styles/withStyles";

import * as routes from "../../constants/routes";
import BarLinkMenuItem from "./BarLinkMenuItem";

const styles = (theme) => ({});

class MenusMenuItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleItemClicked = () => {
    const { onClicked } = this.props;

    if (onClicked) {
      onClicked();
    }
  };

  render() {
    const { t } = this.props;

    return (
      <BarLinkMenuItem
        to={routes.MENUS}
        icon={<AssignmentIcon />}
        label={t("label.menus")}
        onClicked={this.handleItemClicked}
      />
    );
  }
}

export default withStyles(styles)(withTranslation("common")(MenusMenuItem));
