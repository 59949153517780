import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Typography, Button } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { baseService, orderService } from "tap-io/client/services";
import OrderStatus from "tap-io/models/order/OrderStatus";

import { hasNativeWrapper, printer } from "../../native";
import ConfirmOverrideSubOrdersDialog from "./ConfirmOverrideSubOrdersDialog";
import SelectOrderPreparationOption from "./SelectOrderPreparationOption";
import ClaimAndCompleteOrder from "./ClaimAndCompleteOrder";
import SkipOrderPreparationOption from "./SkipOrderPreparationOption";

const styles = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  spacing: {
    height: 25
  },
  center: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
});

class ClaimOrder extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false,
      isConfirmOverrideSubOrdersDialogOpen: false,
      preparationOption: null
    };
  }

  handleClaimOrder = async (event) => {
    await this.handleSelectPreparationOption(null);
  };

  handleSelectPreparationOption = async (preparationOption) => {
    const { order } = this.props;

    if (order.hasSubOrders) {
      this.setState({
        isConfirmOverrideSubOrdersDialogOpen: true,
        preparationOption
      });
    } else {
      await this.claimOrder(preparationOption);
    }
  };

  handleConfirmOverrideSubOrders = async () => {
    const { preparationOption } = this.state;

    this.setState({
      isConfirmOverrideSubOrdersDialogOpen: false,
      preparationOption: null
    });

    await this.claimOrder(preparationOption);
  };

  handleCancelOverrideSubOrders = () => {
    this.setState({
      isConfirmOverrideSubOrdersDialogOpen: false,
      preparationOption: null
    });
  };

  claimOrder = async (preparationOption) => {
    const { t, base, order, onDone } = this.props;

    const toastId = toast(t("order.claiming-order"), { autoClose: false });
    this.setState({ isDisabled: true });

    try {
      await orderService.setOrderStatusClaimed(
        order,
        base,
        undefined,
        baseService.getNextSequenceNumber,
        preparationOption?.duration || null
      );

      //await this.handlePrintReceiptIfNeeded();

      toast.dismiss(toastId);

      onDone();
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleClaimAndCompleteOrderBusy = () => {
    this.setState({ isDisabled: true });
  };

  handleClaimAndCompleteOrderDone = () => {
    const { onDone } = this.props;

    onDone(); //this.setState({ isDisabled: false }, onDone);
  };

  handleClaimAndCompleteOrderError = () => {
    this.setState({ isDisabled: false });
  };

  /*handlePrintReceiptIfNeeded = async () => {
    const { t, bar, base, order, deviceSettings, assets } = this.props;

    try {
      if (
        hasNativeWrapper() &&
        deviceSettings &&
        deviceSettings.printOrderWhenClaimed
      ) {
        await printer.printReceipt(
          t,
          bar,
          base,
          assets,
          order,
          deviceSettings.printBarLogo,
          deviceSettings.printOrderCustomerFields,
          deviceSettings.printAmountOfCopies
        );
      }
    } catch (error) {
      if (error) {
        toast.error(error.message);
      }
      console.warn(error);
    }
  };*/

  render() {
    const { classes, t, bar, assets, deviceSettings, base, order, onDone } =
      this.props;
    const { isDisabled, isConfirmOverrideSubOrdersDialogOpen } = this.state;

    return (
      <>
        <ConfirmOverrideSubOrdersDialog
          isOpen={isConfirmOverrideSubOrdersDialogOpen}
          message={t("order.override-suborders-by-claiming-order")}
          onConfirm={this.handleConfirmOverrideSubOrders}
          onCancel={this.handleCancelOverrideSubOrders}
        />
        <div className={classes.content}>
          {order?.getStatus(base) === OrderStatus.QUEUED ? (
            order?.isFulfilmentMethodAsSoonAsPossible &&
              order?.isDeliveryMethodPickup ? (
              base && base.preparationOptions.length > 0 ? (
                <SelectOrderPreparationOption
                  isDisabled={isDisabled}
                  base={base}
                  order={order}
                  onSelect={this.handleSelectPreparationOption}
                />
              ) : (
                <SkipOrderPreparationOption
                  isDisabled={isDisabled}
                  order={order}
                  onSelect={this.handleSelectPreparationOption}
                />
              )
            ) : (
              <div className={classes.center}>
                <Typography variant="h6">
                  {t("order.claim-and-complete-order-below")}
                </Typography>
                <div className={classes.spacing} />
                <Button
                  variant="contained"
                  size="large"
                  disabled={isDisabled}
                  onClick={this.handleClaimOrder}
                >
                  {t("order.only-claim-order-and-complete-later")}
                </Button>
                <div className={classes.spacing} />
                <ClaimAndCompleteOrder
                  size="small"
                  bar={bar}
                  assets={assets}
                  deviceSettings={deviceSettings}
                  base={base}
                  order={order}
                  onBusy={this.handleClaimAndCompleteOrderBusy}
                  onDone={this.handleClaimAndCompleteOrderDone}
                  onError={this.handleClaimAndCompleteOrderError}
                />
              </div>
            )
          ) : (
            <Typography>{t("order.cannot-claim-order")}</Typography>
          )}
        </div>
      </>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(ClaimOrder));
