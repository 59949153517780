import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { Switch, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  availability: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    height: 54
  },
  availabilityText: {
    marginBottom: -6
  },
  helpText: {
    fontSize: 12
  }
});

class AvailabilityToggle extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleChange = (event) => {
    const { onToggle } = this.props;
    const val = event.target.checked;
    onToggle(val, event);
  };

  render() {
    const { classes, t, isAvailable } = this.props;

    return (
      <div className={classes.availability}>
        <Typography
          variant="caption"
          className={classNames(classes.availabilityText, classes.helpText)}
        >
          {`${t("menu.available")}?`}
        </Typography>
        <Switch
          data-cy="menu-element-toggle-availability"
          onChange={this.handleChange}
          checked={isAvailable}
        />
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(AvailabilityToggle)
);
