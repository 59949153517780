import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import Moment from "moment";

import { IconButton, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import withStyles from "@mui/styles/withStyles";

import ModeName from "tap-io/models/mode/ModeName";
import { orderService } from "tap-io/client/services";
import ContentCard from "tap-io/client/components/common/ContentCard";
import { integrationHelper, utilsHelper } from "tap-io/helpers";

import withBarAuthorization from "../components/auth/withBarAuthorization";
import withMode from "../components/auth/withMode";
import OpenOrdersPerBase from "../components/overview/OpenOrdersPerBase";
import OpenOrdersPerMenu from "../components/overview/OpenOrdersPerMenu";
import OpenOrders from "../components/overview/OpenOrders";

const REFRESH_TIMER_INTERVAL = 60000; // Seconds

const styles = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 20
  },
  refreshHeader: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    textAlign: "left"
  },
  refreshTime: {
    width: "100%",
    marginLeft: theme.spacing(2)
  }
});

class OverviewPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.restartRefreshTimer();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { bar } = this.props;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;

    if (barId !== prevBarId) {
      this.restartRefreshTimer();
    }
  }

  componentWillUnmount() {
    if (this.refreshTimerTimeout) {
      clearTimeout(this.refreshTimerTimeout);
      this.refreshTimerTimeout = undefined;
    }
  }

  restartRefreshTimer = () => {
    if (this.refreshTimerTimeout) {
      clearTimeout(this.refreshTimerTimeout);
      this.refreshTimerTimeout = undefined;
    }

    this.setState({ nextRefreshDate: null });

    const refreshTimer = async () => {
      await this.refreshOpenOrders();

      this.refreshTimerTimeout = setTimeout(
        refreshTimer,
        REFRESH_TIMER_INTERVAL
      );

      this.setState({
        nextRefreshDate: Moment()
          .add(REFRESH_TIMER_INTERVAL / 1000, "seconds")
          .toDate()
      });
    };

    refreshTimer();
  };

  refreshOpenOrders = async () => {
    const { bar } = this.props;

    if (bar && bar.id) {
      const openOrders = await orderService.getOpenOrders(bar.id);

      const openOrdersPerMenu = {};
      const openOrdersCountPerMinute = utilsHelper.generateArray(31, 0);
      const openOrdersCountPerMenuAndMinute = {};

      const now = new Date();
      openOrders.forEach((order) => {
        order.menuIds.forEach((menuId) => {
          if (!openOrdersPerMenu[menuId]) {
            openOrdersPerMenu[menuId] = [];
          }
          openOrdersPerMenu[menuId].push(order);

          const minutesAgo = Moment(now).diff(order.timestamp, "minutes");
          const minutesIndex = minutesAgo > 30 ? 30 : minutesAgo;

          if (!openOrdersCountPerMinute[minutesIndex]) {
            openOrdersCountPerMinute[minutesIndex] = [];
          }
          openOrdersCountPerMinute[minutesIndex]++;

          if (!openOrdersCountPerMenuAndMinute[menuId]) {
            openOrdersCountPerMenuAndMinute[menuId] = utilsHelper.generateArray(
              31,
              0
            );
          }
          if (!openOrdersCountPerMenuAndMinute[menuId][minutesIndex]) {
            openOrdersCountPerMenuAndMinute[menuId][minutesIndex] = [];
          }
          openOrdersCountPerMenuAndMinute[menuId][minutesIndex]++;
        });
      });

      this.setState({
        openOrders,
        openOrdersPerMenu,
        openOrdersCountPerMinute,
        openOrdersCountPerMenuAndMinute
      });
    } else {
      this.setState({
        openOrders: [],
        openOrdersPerMenu: {},
        openOrdersCountPerMinute: [],
        openOrdersCountPerMenuAndMinute: {}
      });
    }
  };

  handleManualRefresh = () => {
    this.restartRefreshTimer();
  };

  render() {
    const { classes, t, bar, allMenus, allBases, allIntegrations } = this.props;
    const {
      nextRefreshDate,
      openOrders,
      openOrdersPerMenu,
      openOrdersCountPerMinute,
      openOrdersCountPerMenuAndMinute
    } = this.state;

    const isAbiIntegrationActive =
      allIntegrations &&
      integrationHelper.findAbiIntegration(allIntegrations)?.isActive;
    const areBasesEditable = !isAbiIntegrationActive;

    return (
      <div className={classes.content}>
        <div className={classes.refreshHeader}>
          <Typography variant="caption" className={classes.refreshTime}>
            {nextRefreshDate &&
              t("overview.next-update-at", {
                updateTime: Moment(nextRefreshDate).format("HH:mm:ss")
              })}
          </Typography>
          <IconButton
            className={classes.refreshButton}
            onClick={this.handleManualRefresh}
            size="large"
          >
            <RefreshIcon />
          </IconButton>
        </div>
        <ContentCard title={t("overview.open-orders")} isResponsive={false}>
          <OpenOrders
            bar={bar}
            openOrders={openOrders}
            openOrdersCountPerMinute={openOrdersCountPerMinute}
            title={t("overview.all-open-orders")}
          />
        </ContentCard>
        <ContentCard
          title={t(
            bar.isUsingBases()
              ? "overview.open-orders-per-base"
              : "overview.open-orders-per-menu"
          )}
          isResponsive={false}
        >
          {bar.isUsingBases() ? (
            <OpenOrdersPerBase
              bar={bar}
              allBases={allBases}
              openOrders={openOrders}
              openOrdersPerMenu={openOrdersPerMenu}
              openOrdersCountPerMenuAndMinute={openOrdersCountPerMenuAndMinute}
              isEditable={areBasesEditable}
            />
          ) : (
            <OpenOrdersPerMenu
              bar={bar}
              allMenus={allMenus}
              openOrdersPerMenu={openOrdersPerMenu}
              openOrdersCountPerMenuAndMinute={openOrdersCountPerMenuAndMinute}
            />
          )}
        </ContentCard>
      </div>
    );
  }
}

export default withBarAuthorization()(
  withMode(ModeName.OVERVIEW)(
    withStyles(styles, { withTheme: true })(
      withTranslation("common")(OverviewPage)
    )
  )
);
