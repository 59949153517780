export const PLAN_BASIC = "basic";
export const PLAN_PRO = "pro";
export const PLAN_FULL_SERVICE = "full-service";

export const PLAN_LABELS = {
  [PLAN_BASIC]: "subscription.plan-basic",
  [PLAN_PRO]: "subscription.plan-pro",
  [PLAN_FULL_SERVICE]: "subscription.plan-full-service"
};

export const PLANS = [
  { id: PLAN_BASIC, label: PLAN_LABELS[PLAN_BASIC] },
  { id: PLAN_PRO, label: PLAN_LABELS[PLAN_PRO] },
  { id: PLAN_FULL_SERVICE, label: PLAN_LABELS[PLAN_FULL_SERVICE] }
];
