import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { List, ListItem, TextField } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { barService } from "tap-io/client/services";

const styles = (theme) => ({});

class EditName extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (bar) => {
    return {
      name: bar && bar.name ? bar.name : ""
    };
  };

  componentDidMount() {
    const { bar } = this.props;

    this.setState(this.initialState(bar));
  }

  componentDidUpdate(prevProps, prevState) {
    const { bar } = this.props;

    const barId = bar ? bar.id : undefined;
    const prevBarId = prevProps.bar ? prevProps.bar.id : undefined;

    if (barId !== prevBarId) {
      this.setState(this.initialState(bar));
    }
  }

  handleNameChange = (event) => {
    const val = event.target.value;

    this.setState({ name: val });
  };

  requestSave = () => {
    if (this.saveTimeout) {
      clearTimeout(this.saveTimeout);
    }

    this.saveTimeout = setTimeout(() => {
      this.saveTimeout = undefined;
      this.handleSave();
    }, 1000);
  };

  handleSave = async () => {
    const { t, bar } = this.props;
    const { name } = this.state;

    // Don't save if nothing changed
    if (name === bar.name) {
      return;
    }

    const toastId = toast(t("settings.saving-name"), { autoClose: false });

    try {
      await barService.setBarName(bar, name);

      toast.update(toastId, {
        render: t("settings.name-saved"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { t } = this.props;
    const { name } = this.state;

    return (
      <List>
        <ListItem>
          <TextField
            variant="standard"
            margin="dense"
            label={t("settings.bar-name-label")}
            helperText={t("settings.bar-name-description")}
            fullWidth
            value={name}
            onChange={this.handleNameChange}
            onBlur={this.requestSave}
          />
        </ListItem>
      </List>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditName)
);
