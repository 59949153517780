import React, { PureComponent } from "react";
import classNames from "classnames";

import { withTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import { Button, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import * as routes from "../../constants/routes";

import { hasNativeWrapper } from "../../native";

const styles = (theme) => ({
  root: {
    zIndex: 1000,
    position: "sticky",
    top: 20,
    pointerEvents: "none"
  },
  header: {
    display: "flex",
    alignItems: "center",
    ...theme.mixins.toolbar
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  warning: {
    maxWidth: "70%",
    marginTop: -5,
    padding: "10px 10px 5px 10px",
    borderRadius: 5,
    pointerEvents: "auto"
  },
  warningCreditsLow: {
    backgroundColor: "orange"
  },
  warningCreditsRanOut: {
    backgroundColor: "red"
  },
  warningText: {
    paddingBottom: 10,
    borderBottom: "1px solid rgba(255, 255, 255, 0.5)",
    color: "white"
  },
  topUpLink: {
    display: "block",
    textDecoration: "none"
  },
  topUpButton: {
    color: "white"
  }
});

class CreditsWarning extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, drawerWidth, isMenuDrawerOpen, balance } = this.props;

    const isLowOnCredits = balance && balance.isLowOnCredits();
    const hasCredits = balance && balance.hasCredits();

    return (
      balance &&
      !balance.doNotChargeCredits &&
      !balance.allowCreditDebt &&
      (isLowOnCredits || !hasCredits) && (
        <div className={classes.root}>
          <div className={classes.header}></div>
          <div
            className={classes.content}
            style={{
              width: `calc(100% - ${isMenuDrawerOpen ? drawerWidth : 0}px)`,
              marginRight: isMenuDrawerOpen ? drawerWidth : 0
            }}
          >
            <div
              className={classNames(
                classes.warning,
                hasCredits
                  ? classes.warningCreditsLow
                  : classes.warningCreditsRanOut
              )}
            >
              <Typography variant="body1" className={classes.warningText}>
                {hasCredits
                  ? `${t("label.attention")} ${t("credits.only-x-remain-1", {
                      creditCount: balance.creditCount
                    })} ${
                      balance.creditCount === 1
                        ? t("credits.credit")
                        : t("credits.credits")
                    } ${t("credits.only-x-remain-2")} ${t(
                      "credits.customers-wont-be-able-to-place-orders-when-you-run-out-of-credits"
                    )}`
                  : `${t("credits.you-ran-out-of-credits")} ${t(
                      "credits.no-orders-can-be-placed-anymore"
                    )}`}
              </Typography>
              {!hasNativeWrapper() && (
                <Link to={routes.BALANCE} className={classes.topUpLink}>
                  <Button fullWidth className={classes.topUpButton}>
                    {t("credits.top-up-credits-now")}
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </div>
      )
    );
  }
}

export default withStyles(styles)(withTranslation("common")(CreditsWarning));
