import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import ShowTip from "./ShowTip";

const styles = (theme) => ({});

class ShowZonesTip extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { t, variant, label } = this.props;

    return (
      <ShowTip
        variant={variant}
        label={label ? label : t("zone.zones")}
        tipTitle={t("help.zones-title")}
        tipIcon="/icons/zones.png"
        tipDescription={t("help.zones-description")}
        tipImage="/img/zones.png"
      />
    );
  }
}

export default withStyles(styles)(withTranslation("common")(ShowZonesTip));
