import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  spacing: {
    height: 30
  }
});

class SkipOrderPreparationOption extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleNoPreparationOptionClick = (event) => {
    const { onSelect } = this.props;

    onSelect(null);
  };

  render() {
    const { classes, t, isDisabled, size, variant, isCompact } = this.props;

    return (
      <div className={classes.content}>
        {!isCompact &&
          <>
            <Typography variant={variant || "h6"}>{t("order.skip-estimated-preparation-duration")}</Typography>
            <div className={classes.spacing} />
          </>
        }
        <Button
          color="primary"
          size={size}
          variant="contained"
          disabled={isDisabled}
          onClick={this.handleNoPreparationOptionClick}
        >
          {t("order.claim-order")}
        </Button>
      </div>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(SkipOrderPreparationOption));
