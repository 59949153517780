import { Asyncer } from "../../../components/common/Asyncer";
import { Async } from "../../../components/common/useAsync";
import { DepositReport } from "../../models/DepositReport";
import { BarStats } from "../common/BarStats";
import { DownloadIconCSVButton } from "../common/DownloadCSVIconButton";
import { FailedStatCard } from "../common/FailedStatCard";
import { LoadingStatCard } from "../common/LoadingStatCard";
import { StatCard } from "../common/StatCard";
import { StatChip } from "../common/StatChip";

export type ValuePerDepositProps = {
  async: Async<DepositReport>;
  mark: string;
  t: any;
};

export function ValuePerDeposit(props: ValuePerDepositProps) {
  const { async, mark, t } = props;

  const title = t("stats.stat.value-per-deposit.title");
  const description = t("stats.stat.value-per-deposit.description");

  return (
    <Asyncer
      async={async}
      Busy={<LoadingStatCard title={title} description={description} />}
      Failed={
        <FailedStatCard title={title} description={description} async={async} />
      }
    >
      {(report) => {
        const total = report.totalValue;

        return (
          <StatCard
            title={title}
            description={description}
            action={
              <DownloadIconCSVButton
                title={`${title}-${mark}`}
                csv={report.toFinancialCSV(t)}
              />
            }
            derivatives={
              <StatChip
                title={t("stats.stat.value-per-deposit.total")}
                value={report.formatCurrency(total, { curreny: "EUR" })}
              />
            }
          >
            <BarStats
              data={report.data}
              x="deposit"
              formatX={(_, r) => r.name}
              y="value"
              formatY={report.formatCurrency}
              t={t}
            />
          </StatCard>
        );
      }}
    </Asyncer>
  );
}
