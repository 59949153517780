import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import EventSeatIcon from "@mui/icons-material/EventSeat";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({});

class OrderDeliveryIcon extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, order, size, fontSize } = this.props;

    return order.isDeliveryMethodServe ? (
      <EventSeatIcon fontSize={size} style={{ fontSize }} />
    ) : order.isDeliveryMethodPickup ? (
      <EmojiPeopleIcon fontSize={size} style={{ fontSize }} />
    ) : (
      <HourglassEmptyIcon fontSize={size} style={{ fontSize }} />
    );
  }
}

export default withStyles(styles)(withTranslation("common")(OrderDeliveryIcon));
