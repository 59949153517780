import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button } from "@mui/material";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import ErrorIcon from "@mui/icons-material/Error";
import withStyles from "@mui/styles/withStyles";

import { subscriptionService } from "tap-io/client/services";

import ShowCurrentPlanDialog from "./ShowCurrentPlanDialog";

const styles = (theme) => ({});

class CurrentPlanButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isShowCurrentPlanDialogOpen: false
    };
  }

  handleCheckAndShowPlan = async () => {
    const { isAllowedToShowPlan, requestPermissionToShowPlan } = this.props;

    if (isAllowedToShowPlan) {
      this.handleShowCurrentPlan();
    } else {
      try {
        await requestPermissionToShowPlan();
        this.handleShowCurrentPlan();
      } catch (error) {
        console.warn(error);
      }
    }
  };

  handleShowCurrentPlan = () => {
    this.setState({ isShowCurrentPlanDialogOpen: true });
  };

  handleShowCurrentPlanDialogClose = () => {
    this.setState({ isShowCurrentPlanDialogOpen: false });
  };

  render() {
    const { classes, t, bar, subscription, color, fullWidth, hideIcon, label } =
      this.props;
    const { isShowCurrentPlanDialogOpen } = this.state;

    return (
      <>
        <ShowCurrentPlanDialog
          isOpen={isShowCurrentPlanDialogOpen}
          onClose={this.handleShowCurrentPlanDialogClose}
          bar={bar}
          subscription={subscription}
        />
        <Button
          color={color ? color : "inherit"}
          fullWidth={fullWidth}
          onClick={this.handleCheckAndShowPlan}
          startIcon={
            hideIcon ? null : subscription.isRequested() ? (
              <SyncAltIcon />
            ) : subscriptionService.isSubscriptionAboutToExpire(
                subscription
              ) ? (
              <ErrorIcon color="error" />
            ) : (
              <LoyaltyIcon />
            )
          }
        >
          {label
            ? label
            : subscription.isExpired()
            ? t(`subscription.plan-x-expired`, {
                planName: t(`subscription.plan-${subscription.planName}`)
              })
            : subscription.isRequested()
            ? t(`subscription.plan-x-requested`, {
                planName: t(`subscription.plan-${subscription.planName}`)
              })
            : t(`subscription.plan-${subscription.planName}`)}
        </Button>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(CurrentPlanButton));
