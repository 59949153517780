import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { dataService } from "tap-io/client/services";
import { catalogueHelper } from "tap-io/helpers";

import withPasswordAuthentication from "../auth/withPasswordAuthentication";
import GenerateVouchersDialog from "./GenerateVouchersDialog";
import ManageVouchersDialog from "./ManageVouchersDialog";
import defaultCatalogue from "../../constants/catalogue";
import ManageVoucherTemplatesDialog from "./ManageVoucherTemplatesDialog";
import CapabilityUnavailableButton from "../subscription/CapabilityUnavailableButton";
//import GenerateVouchersFromCsvDialog from "./GenerateVouchersFromCsvDialog";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  description: {
    width: "100%"
  },
  smallSpacing: {
    height: 10
  },
  largeSpacing: {
    height: 25
  }
});

class Vouchers extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isGenerateVouchersDialogOpen: false,
      //isGenerateVouchersFromCsvDialogOpen: false,
      isManageVouchersDialogOpen: false,
      isManageVoucherTemplatesDialogOpen: false,
      catalogue: null,
      selectedVoucherIds: null
    };
  }

  componentDidMount() {
    const { bar } = this.props;

    this.unsubscribeCatalogueData = dataService.onCatalogueData(
      bar.id,
      (catalogueData) => {
        this.setState({
          catalogue: catalogueHelper.createCatalogue(
            defaultCatalogue,
            catalogueData
          )
        });
      }
    );
  }

  componentWillUnmount() {
    if (this.unsubscribeCatalogueData) {
      this.unsubscribeCatalogueData();
      this.unsubscribeCatalogueData = undefined;
    }
  }

  handleGenerateVouchers = () => {
    this.setState({ isGenerateVouchersDialogOpen: true });
  };

  /*handleGenerateVouchersFromCsv = () => {
    this.setState({ isGenerateVouchersFromCsvDialogOpen: true });
  };*/

  handleGenerateVouchersDialogClose = (voucherIds) => {
    this.setState({
      isGenerateVouchersDialogOpen: false,
      isManageVouchersDialogOpen:
        voucherIds && voucherIds.length > 0 ? true : false,
      selectedVoucherIds: voucherIds ? voucherIds : null
    });
  };

  /*handleGenerateVouchersFromCsvDialogClose = (voucherIds) => {
    this.setState({
      isGenerateVouchersFromCsvDialogOpen: false,
      isManageVouchersDialogOpen:
        voucherIds && voucherIds.length > 0 ? true : false,
      selectedVoucherIds: voucherIds ? voucherIds : null
    });
  };*/

  handleManageVouchers = () => {
    this.setState({ isManageVouchersDialogOpen: true });
  };

  handleManageVouchersDialogClose = () => {
    this.setState({
      isManageVouchersDialogOpen: false,
      selectedVoucherIds: null
    });
  };

  handleManageVoucherTemplates = () => {
    this.setState({ isManageVoucherTemplatesDialogOpen: true });
  };

  handleManageVoucherTemplatesDialogClose = () => {
    this.setState({ isManageVoucherTemplatesDialogOpen: false });
  };

  render() {
    const { classes, t, bar, subscription, assets, allMenus, deviceSettings } =
      this.props;
    const {
      isGenerateVouchersDialogOpen,
      //isGenerateVouchersFromCsvDialogOpen,
      isManageVouchersDialogOpen,
      isManageVoucherTemplatesDialogOpen,
      catalogue,
      selectedVoucherIds
    } = this.state;

    return (
      <div className={classes.container}>
        <GenerateVouchersDialog
          isOpen={isGenerateVouchersDialogOpen}
          onClose={this.handleGenerateVouchersDialogClose}
          bar={bar}
          subscription={subscription}
          catalogue={catalogue}
          allMenus={allMenus}
        />
        <ManageVouchersDialog
          isOpen={isManageVouchersDialogOpen}
          onClose={this.handleManageVouchersDialogClose}
          bar={bar}
          assets={assets}
          deviceSettings={deviceSettings}
          selectedVoucherIds={selectedVoucherIds}
        />
        <ManageVoucherTemplatesDialog
          isOpen={isManageVoucherTemplatesDialogOpen}
          onClose={this.handleManageVoucherTemplatesDialogClose}
          bar={bar}
        />
        <Typography className={classes.description}>
          {t("voucher.generate-vouchers-description")}
        </Typography>
        <div className={classes.smallSpacing}></div>
        <Button onClick={this.handleGenerateVouchers}>
          {t("voucher.generate-vouchers-label")}
        </Button>
        <div className={classes.largeSpacing}></div>
        <Typography className={classes.description}>
          {t("voucher.manage-vouchers-description")}
        </Typography>
        <div className={classes.smallSpacing}></div>
        <Button onClick={this.handleManageVouchers}>
          {t("voucher.manage-vouchers-label")}
        </Button>
        <div className={classes.largeSpacing}></div>
        <Typography className={classes.description}>
          {t("voucher.manage-voucher-templates-description")}
        </Typography>
        <div className={classes.smallSpacing}></div>
        {subscription &&
        subscription.capabilities.areVoucherTemplatesAvailable() ? (
          <Button onClick={this.handleManageVoucherTemplates}>
            {t("voucher.manage-voucher-templates-label")}
          </Button>
        ) : (
          <CapabilityUnavailableButton bar={bar} subscription={subscription} />
        )}
      </div>
    );
  }
}

export default withPasswordAuthentication()(
  withStyles(styles, { withTheme: true })(withTranslation("common")(Vouchers))
);

/*
<GenerateVouchersFromCsvDialog
  isOpen={isGenerateVouchersFromCsvDialogOpen}
  onClose={this.handleGenerateVouchersFromCsvDialogClose}
  bar={bar}
  catalogue={catalogue}
  allMenus={allMenus}
/>
<Button onClick={this.handleGenerateVouchersFromCsv}>{t("voucher.generate-vouchers-from-csv-label")}</Button>
*/
