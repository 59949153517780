import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Button,
  Divider,
  ListItem,
  ListItemText,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import withStyles from "@mui/styles/withStyles";

import { deviceStorage } from "../../../storage";

const styles = (theme) => ({
  infoMessage: {
    flexDirection: "column",
    marginBottom: 10
  }
});

class InfoMessage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hideInfoMessage: true
    };
  }

  componentDidMount() {
    const { bar } = this.props;

    const cacheKey = this.getCacheKey();
    deviceStorage
      .getDeviceCacheItem(bar.id, cacheKey)
      .then((wasInfoMessageShown) => {
        this.setState({ hideInfoMessage: wasInfoMessageShown === true });
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  getCacheKey = () => {
    const { messageKey } = this.props;

    if (!messageKey) {
      throw new Error("messageKey is required");
    }

    return `wasInfoMessageShown_${messageKey}`;
  };

  handleSetInfoMessageShown = () => {
    const { bar } = this.props;

    this.setState({ hideInfoMessage: true });

    const cacheKey = this.getCacheKey();
    deviceStorage
      .setDeviceCacheItem(bar.id, cacheKey, true)
      .then(() => {})
      .catch((error) => {
        console.warn(error);
      });
  };

  render() {
    const { classes, t, text } = this.props;
    const { hideInfoMessage } = this.state;

    return (
      <div>
        {!hideInfoMessage && (
          <div>
            <ListItem className={classes.infoMessage}>
              <ListItemText
                primary={<Typography variant="subtitle2">{text}</Typography>}
              />
              <Button
                fullWidth
                variant="contained"
                startIcon={<CloseIcon />}
                onClick={this.handleSetInfoMessageShown}
              >
                {t("label.i-understand")}
              </Button>
            </ListItem>
            <Divider />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(InfoMessage));
