import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import withStyles from "@mui/styles/withStyles";
import { Typography } from "@mui/material";

import SelectAmount from "tap-io/client/components/common/SelectAmount";
import WholeNumbersLayoutNodeSequence from "tap-io/models/zone/WholeNumbersLayoutNodeSequence";
import EditLayoutNodeBasics from "./EditLayoutNodeBasics";

const styles = (theme) => ({
  smallSpacing: {
    height: 10
  },
  largeSpacing: {
    height: 20
  },
  inputContainer: {
    display: "flex",
    alignItems: "center"
  },
  inputLabel: {
    width: "100%"
  },
  seriesItems: {
    display: "flex",
    flexWrap: "wrap",
    margin: `${theme.spacing()} -${theme.spacing()}`
  },
  seriesItem: {
    display: "flex",
    alignItems: "center",
    height: 40,
    margin: theme.spacing(),
    padding: `0 ${theme.spacing(1.5)}`,
    borderRadius: 20,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
});

class EditSequenceLayoutNode extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleSequenceBeginChange = (sequenceBegin) => {
    const { t, onUpdate, layoutNode } = this.props;

    try {
      layoutNode.sequence = new WholeNumbersLayoutNodeSequence(
        sequenceBegin,
        sequenceBegin + (layoutNode.sequence.end - layoutNode.sequence.begin),
        layoutNode.sequence.incrementor
      );
      onUpdate(layoutNode);
    } catch (error) {
      toast.error(
        t(error && error.message ? error.message : "label.unknown-error")
      );
    }
  };

  handleSequenceAmountChange = (sequenceAmount) => {
    const { t, onUpdate, layoutNode } = this.props;

    try {
      layoutNode.sequence = new WholeNumbersLayoutNodeSequence(
        layoutNode.sequence.begin,
        layoutNode.sequence.begin +
          (sequenceAmount - 1) * layoutNode.sequence.incrementor,
        layoutNode.sequence.incrementor
      );
      onUpdate(layoutNode);
    } catch (error) {
      toast.error(
        t(error && error.message ? error.message : "label.unknown-error")
      );
    }
  };

  handleSequenceIncrementorChange = (sequenceIncrementor) => {
    const { t, onUpdate, layoutNode } = this.props;

    try {
      layoutNode.sequence = new WholeNumbersLayoutNodeSequence(
        layoutNode.sequence.begin,
        layoutNode.sequence.begin +
          (layoutNode.sequence.end - layoutNode.sequence.begin) *
            (sequenceIncrementor / layoutNode.sequence.incrementor),
        sequenceIncrementor
      );
      onUpdate(layoutNode);
    } catch (error) {
      toast.error(
        t(error && error.message ? error.message : "label.unknown-error")
      );
    }
  };

  render() {
    const { classes, t, isDisabled, bar, allBases, layoutNode, onUpdate } =
      this.props;
    const { name, sequence } = layoutNode;

    return (
      <div>
        <EditLayoutNodeBasics
          isDisabled={isDisabled}
          bar={bar}
          allBases={allBases}
          layoutNode={layoutNode}
          onUpdate={onUpdate}
        />
        <div className={classes.largeSpacing} />
        <div className={classes.inputContainer}>
          <Typography className={classes.inputLabel}>
            {t("zone.sequence-layout-node-amount")}
          </Typography>
          <SelectAmount
            isDisabled={isDisabled}
            allowInput={true}
            min={1}
            max={1000}
            step={1}
            amount={(sequence.end - sequence.begin) / sequence.incrementor + 1}
            onChange={this.handleSequenceAmountChange}
          />
        </div>
        <div className={classes.smallSpacing} />
        <Typography variant="caption">
          {t("label.advanced-settings")}
        </Typography>
        <div className={classes.inputContainer}>
          <Typography className={classes.inputLabel}>
            {t("zone.sequence-layout-node-begin")}
          </Typography>
          <SelectAmount
            isDisabled={isDisabled}
            allowInput={true}
            min={1}
            max={1000}
            step={1}
            amount={sequence.begin}
            onChange={this.handleSequenceBeginChange}
          />
        </div>
        <div className={classes.inputContainer}>
          <Typography className={classes.inputLabel}>
            {t("zone.sequence-layout-node-incrementor")}
          </Typography>
          <SelectAmount
            isDisabled={isDisabled}
            allowInput={true}
            min={1}
            max={1000}
            step={1}
            amount={sequence.incrementor}
            onChange={this.handleSequenceIncrementorChange}
          />
        </div>
        <div className={classes.smallSpacing} />
        <div className={classes.seriesItems}>
          {layoutNode.sequence.getGeneratedSequence().map((item, index) => (
            <div key={index} className={classes.seriesItem}>
              {`${name} ${item}`}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditSequenceLayoutNode)
);
