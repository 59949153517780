import { PureComponent } from "react";

import { Redirect, Route, Switch } from "react-router-dom";

import * as routes from "../../constants/routes";
import BalancePage from "../../pages/BalancePage";
import CheckoutPage from "../../pages/CheckoutPage";
import LogisticsPage from "../../pages/LogisticsPage";
import MenusPage from "../../pages/MenusPage";
import MgmtPage from "../../pages/MgmtPage";
import ModesPage from "../../pages/ModesPage";
import OrdersPage from "../../pages/OrdersPage";
import OverviewPage from "../../pages/OverviewPage";
import PromoPage from "../../pages/PromoPage";
import SettingsPage from "../../pages/SettingsPage";
import StatsPage from "../../pages/StatsPage";
import VouchersPage from "../../pages/VouchersPage";

class BarRoutes extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  // TO FIX: wildcard route

  render() {
    return (
      <Switch>
        <Route
          exact
          path={`*${routes.MODES}`}
          render={(params) => <ModesPage {...params} {...this.props} />}
        />
        <Route
          exact
          path={`*${routes.ORDERS}`}
          render={(params) => <OrdersPage {...this.props} />}
        />
        <Route
          exact
          path={`*${routes.CHECKOUT}`}
          render={(params) => <CheckoutPage {...this.props} />}
        />
        <Route
          exact
          path={`*${routes.VOUCHERS}`}
          render={(params) => <VouchersPage {...this.props} />}
        />
        <Route
          exact
          path={`*${routes.OVERVIEW}`}
          render={(params) => <OverviewPage {...this.props} />}
        />
        <Route
          exact
          path={`*${routes.MENUS}`}
          render={(params) => <MenusPage {...this.props} />}
        />
        <Route
          exact
          path={`*${routes.SETTINGS}`}
          render={(params) => <SettingsPage {...this.props} />}
        />
        <Route
          exact
          path={`*${routes.LOGISTICS}`}
          render={(params) => <LogisticsPage {...this.props} />}
        />
        <Route
          exact
          path={`*${routes.PROMO}`}
          render={(params) => <PromoPage {...this.props} />}
        />
        <Route
          exact
          path={`*${routes.BALANCE}`}
          render={(params) => <BalancePage {...this.props} />}
        />
        <Route
          exact
          path={`*${routes.STATS}`}
          render={(params) => <StatsPage {...this.props} />}
        />
        <Route
          exact
          path={`*${routes.MGMT}`}
          render={(params) => <MgmtPage {...this.props} />}
        />
        <Redirect to={routes.MODES} />
      </Switch>
    );
  }
}

export default BarRoutes;
