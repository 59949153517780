import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Typography, Button } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";
import { scanner } from "../native";
import { deviceStorage } from "tap-io/storage";
import { barHelper, scannerHelper } from "tap-io/helpers";

import BackgroundWithCard from "../components/auth/BackgroundWithCard";

const styles = (theme) => ({
  spacing: {
    height: 20
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main
  }
});

const OPEN_SCANNER_ACTION = "openScannerAction";

class UnavailablePage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.checkCachedAction();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { redirectUrl, redirectTarget } = this.state;

    if (redirectUrl && redirectTarget) {
      window.open(redirectUrl, redirectTarget);
    }
  }

  checkCachedAction = async () => {
    const { t } = this.props;

    if (!scanner.isScannerAvailable()) {
      return;
    }

    const cachedAction = await deviceStorage.getDeviceCacheItem(
      "",
      "cachedAction"
    );

    if (!cachedAction) {
      return;
    }

    switch (cachedAction) {
      case OPEN_SCANNER_ACTION:
        this.setState({
          redirectUrl: `https://${config.hosting.scanDomain}`,
          redirectTarget: "_self"
        });
        break;
      default:
        toast.error(t("error.cached-action-is-not-valid"));
        break;
    }
  };

  scanQRCode = async () => {
    const { t } = this.props;

    try {
      if (!scanner.isScannerAvailable()) {
        throw new Error("error.scanner-not-available");
      }

      this.unsubscribeScanner = await scanner.onQRCodeScanned(async (data) => {
        this.unsubscribeScanner();

        await scanner.hideQRCodeScanner();

        await this.checkQRData(data);
      });

      await scanner.showQRCodeScanner();
    } catch (error) {
      toast.error(
        `${t("label.something-went-wrong")} (${t(
          error ? error.message : "error.unknown-error"
        )})`
      );
    }
  };

  checkQRData = async (qrData) => {
    const { t } = this.props;

    // Check if qrData contains an orderUrl
    try {
      const { orderQueryString } = barHelper.parseBarUrl(
        config.hosting.orderDomain,
        qrData
      );

      //await deviceStorage.setDeviceCacheItem("", "cachedQRCode", qrData);

      return this.setState({ redirectUrl: qrData, redirectTarget: "_blank" });
    } catch (error) {
      console.warn(error);
    }

    try {
      const { barLocator, scannerId } = scannerHelper.parseScannerUrl(
        config.hosting.scanDomain,
        qrData
      );
      //const barUrl = `https://${config.hosting.scanDomain}/${barLocator}`;
      //const parsedScannerUrl = scannerHelper.getScannerUrl(barUrl, scannerId);

      await deviceStorage.setDeviceCacheItem(
        "",
        "cachedAction",
        OPEN_SCANNER_ACTION
      );

      return this.setState({ redirectUrl: qrData, redirectTarget: "_self" });
    } catch (error) {
      console.warn(error);
    }

    toast.error(t("error.qr-code-is-not-valid"));
  };

  render() {
    const { t, classes } = this.props;

    return (
      <BackgroundWithCard
        content={
          <Typography>
            {t(
              scanner.isScannerAvailable()
                ? "label.scan-a-qr-code-of-an-order-page-or-scanner-below"
                : "label.app-is-not-yet-available"
            )}
          </Typography>
        }
        actions={
          <>
            {scanner.isScannerAvailable() ? (
              <Button onClick={this.scanQRCode}>
                {t("label.scan-qr-code")}
              </Button>
            ) : (
              <a
                href={`https://${config.hosting.orderDomain}`}
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                <Typography variant="button">
                  {t("label.i-want-to-place-an-order")}
                </Typography>
              </a>
            )}
          </>
        }
      />
    );
  }
}

export default withStyles(styles)(withTranslation("common")(UnavailablePage));

/*

*/

/*
<a href="mailto:support@watdrinkje.be" target="_blank" rel="noreferrer" className={classes.link}>
  <Typography variant="button">
    {t("label.contact-us")}
  </Typography>
</a>
*/
