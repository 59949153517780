import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Divider, List } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import withStyles from "@mui/styles/withStyles";

import withAuthorization from "../auth/withAuthorization";
import { hasNativeWrapper } from "../../native";
import BarDrawer from "./BarDrawer";
import BarLiveControlsMenuItem from "../navigation/BarLiveControlsMenuItem";
import BarSoundControlsMenuItem from "../navigation/BarSoundControlsMenuItem";
import AccountMenuItem from "../navigation/AccountMenuItem";
import AppMenuItem from "../navigation/AppMenuItem";
import HelpMenuItem from "../navigation/HelpMenuItem";
import OrdersMenuItem from "../navigation/OrdersMenuItem";
import MenusMenuItem from "../navigation/MenusMenuItem";
import PromoMenuItem from "../navigation/PromoMenuItem";
import SettingsMenuItem from "../navigation/SettingsMenuItem";
import LogisticsMenuItem from "../navigation/LogisticsMenuItem";
import BalanceMenuItem from "../navigation/BalanceMenuItem";
import StatsMenuItem from "../navigation/StatsMenuItem";
import PrinterMenuItem from "../navigation/PrinterMenuItem";
import MgmtMenuItem from "../navigation/MgmtMenuItem";
import ModesMenuItem from "../navigation/ModesMenuItem";
import CheckoutMenuItem from "../navigation/CheckoutMenuItem";
import VouchersMenuItem from "../navigation/VouchersMenuItem";
import OverviewMenuItem from "../navigation/OverviewMenuItem";
import * as routes from "../../constants/routes";
import BarMenuItem from "../navigation/BarMenuItem";
import BarSwitchMenuItem from "../navigation/BarSwitchMenuItem";

const styles = (theme) => ({});

class BarCrewDrawer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      t,
      width,
      isOpen,
      onClose,
      auth,
      barIds,
      mode,
      bar,
      subscription,
      base,
      balance,
      assets,
      deviceSettings,
      setBarId,
      toggleMuted
    } = this.props;

    return (
      <BarDrawer
        variant="persistent"
        anchor="right"
        width={width}
        isOpen={isOpen}
        onClose={onClose}
        header={
          <BarMenuItem
            icon={<LockIcon />}
            label={t("auth.lock")}
            onClick={onClose}
          />
        }
        content={
          <List>
            <BarLiveControlsMenuItem
              bar={bar}
              subscription={subscription}
              isDisabled={!balance || !balance.isSufficient()}
            />
            <BarSoundControlsMenuItem
              isMuted={deviceSettings && deviceSettings.isMuted}
              toggleMuted={toggleMuted}
            />
            <Divider />
            <ModesMenuItem />
            {mode && (
              <div>
                {mode.routes.indexOf(routes.ORDERS) >= 0 && <OrdersMenuItem />}
                {mode.routes.indexOf(routes.CHECKOUT) >= 0 && (
                  <CheckoutMenuItem />
                )}
                {mode.routes.indexOf(routes.VOUCHERS) >= 0 && (
                  <VouchersMenuItem />
                )}
                {mode.routes.indexOf(routes.OVERVIEW) >= 0 && (
                  <OverviewMenuItem />
                )}
              </div>
            )}
            <MenusMenuItem />
            <Divider />
            <SettingsMenuItem />
            <LogisticsMenuItem />
            <PromoMenuItem />
            <StatsMenuItem />
            {hasNativeWrapper() ? (
              <PrinterMenuItem
                bar={bar}
                base={base}
                assets={assets}
                deviceSettings={deviceSettings}
              />
            ) : (
              <BalanceMenuItem />
            )}
            {auth && auth.crew && auth.crew.isMgmt && <MgmtMenuItem />}
            <Divider />
            <HelpMenuItem />
            {barIds && barIds.length > 1 && (
              <BarSwitchMenuItem setBarId={setBarId} />
            )}
            <AccountMenuItem />
            <AppMenuItem />
          </List>
        }
      />
    );
  }
}

export default withStyles(styles)(
  withAuthorization()(withTranslation("common")(BarCrewDrawer))
);
