import React, { PureComponent } from "react";

import { IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import RefreshIcon from "@mui/icons-material/Refresh";
import withStyles from "@mui/styles/withStyles";

import { utilsHelper } from "tap-io/helpers";

import { callNativeMethod, hasNativeWrapper } from "../../native";
import Version from "tap-io/client/components/common/Version";
import BarMenuItem from "./BarMenuItem";

const styles = (theme) => ({});

class AppMenuItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  reloadApp = () => {
    const { onClicked } = this.props;

    if (hasNativeWrapper()) {
      callNativeMethod("reloadAppHandler");
    } else {
      utilsHelper.reloadPage();
    }

    if (onClicked) {
      onClicked();
    }
  };

  render() {
    return (
      <BarMenuItem
        icon={<InfoIcon />}
        label={<Version />}
        secondaryAction={
          <IconButton edge="end" onClick={this.reloadApp} size="large">
            <RefreshIcon />
          </IconButton>
        }
        onClick={this.handleSignOut}
      />
    );
  }
}

export default withStyles(styles)(AppMenuItem);
