import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Redirect } from "react-router-dom";

import { Button, Typography } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import withStyles from "@mui/styles/withStyles";

import { deviceStorage } from "tap-io/storage";
import ModeName from "tap-io/models/mode/ModeName";

import Mode from "../components/mode/Mode";

const styles = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "calc(100vh - 104px)",
    margin: 20
  },
  title: {
    marginBottom: 10
  }
});

class ModesPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      targetRoute: null
    };
  }

  componentDidMount() {
    const { history, mode } = this.props;

    const historyAction = history ? history.action : null;

    const currentModeName = mode ? mode.name : null;

    if (historyAction === "POP" && currentModeName) {
      this.handleSetMode(currentModeName);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { mode } = this.props;

    const modeName = mode ? mode.name : undefined;
    const prevModeName = prevProps.mode ? prevProps.mode.name : undefined;

    if (mode && modeName !== prevModeName) {
      this.setState({ targetRoute: mode.defaultRoute });
    }
  }

  handleModeClick = (modeName) => (event) => {
    this.handleSetMode(modeName);
  };

  handleSetMode = async (modeName) => {
    const { bar, mode } = this.props;

    const currentModeName = mode ? mode.name : undefined;

    if (mode && modeName === currentModeName) {
      this.setState({ targetRoute: mode.defaultRoute });
    } else {
      await deviceStorage.setDeviceSetting(bar.id, "modeName", modeName);
    }
  };

  handleUnlockApp = async () => {
    const { unlockApp } = this.props;

    try {
      await unlockApp();
    } catch (error) {
      console.warn(error);
    }
  };

  render() {
    const { classes, t, isAppLocked, mode, bar, subscription } = this.props;
    const { targetRoute } = this.state;

    if (targetRoute) {
      return <Redirect to={targetRoute} />;
    }

    const isDisabled = isAppLocked && mode;
    const currentModeName = mode ? mode.name : null;

    return (
      <div className={classes.content}>
        <Typography variant="h5" className={classes.title}>
          {t("mode.what-do-you-want-to-use-this-device-for")}
        </Typography>
        {isAppLocked && (
          <div className={classes.unlockAppButtonContainer}>
            <Button onClick={this.handleUnlockApp}>
              {t("mode.unlock-app-to-change-mode")}
            </Button>
          </div>
        )}
        <div className={classes.buttons}>
          <Mode
            bar={bar}
            subscription={subscription}
            Icon={DashboardIcon}
            isDisabled={isDisabled && currentModeName !== ModeName.ORDER}
            isActive={currentModeName === ModeName.ORDER}
            isAvailable={
              subscription &&
              subscription.capabilities.isModeAvailable(ModeName.ORDER)
            }
            title={t("mode.order-mode")}
            description={t("mode.use-this-device-to-handle-orders")}
            onClick={this.handleModeClick(ModeName.ORDER)}
          />
          <Mode
            bar={bar}
            subscription={subscription}
            Icon={AddToPhotosIcon}
            isDisabled={isDisabled && currentModeName !== ModeName.CHECKOUT}
            isActive={currentModeName === ModeName.CHECKOUT}
            isAvailable={
              subscription &&
              subscription.capabilities.isModeAvailable(ModeName.CHECKOUT)
            }
            title={t("mode.checkout-mode")}
            description={t(
              "mode.use-this-device-to-take-new-orders-from-customers"
            )}
            onClick={this.handleModeClick(ModeName.CHECKOUT)}
          />
          <Mode
            bar={bar}
            subscription={subscription}
            Icon={ConfirmationNumberIcon}
            isDisabled={isDisabled && currentModeName !== ModeName.VOUCHER}
            isActive={currentModeName === ModeName.VOUCHER}
            isAvailable={
              subscription &&
              subscription.capabilities.isModeAvailable(ModeName.VOUCHER)
            }
            title={t("mode.voucher-mode")}
            description={t(
              "mode.use-this-device-to-generate-and-print-vouchers"
            )}
            onClick={this.handleModeClick(ModeName.VOUCHER)}
          />
          <Mode
            bar={bar}
            subscription={subscription}
            Icon={ZoomOutMapIcon}
            isDisabled={isDisabled && currentModeName !== ModeName.OVERVIEW}
            isActive={currentModeName === ModeName.OVERVIEW}
            isAvailable={
              subscription &&
              subscription.capabilities.isModeAvailable(ModeName.OVERVIEW)
            }
            title={t("mode.overview-mode")}
            description={t(
              "mode.use-this-device-to-get-an-overview-of-the-bar"
            )}
            onClick={this.handleModeClick(ModeName.OVERVIEW)}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation("common")(ModesPage));
