import React, { PureComponent } from "react";

import withStyles from "@mui/styles/withStyles";

import { deviceStorage } from "tap-io/storage";

import { CACHE_KEY_SHOW_INFO_SPLASH } from "../../constants/cache";

const styles = (theme) => ({
  container: {
    width: "100%",
    height: "100%"
  },
  background: {
    width: "100%",
    height: "100%",
    backgroundImage: "url(/img/splash.png)",
    backgroundSize: "100% auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center"
  }
});

class InfoSplash extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showInfoSplash: false
    };
  }

  componentDidMount() {
    const { bar } = this.props;

    deviceStorage
      .getDeviceCacheItem(bar.id, CACHE_KEY_SHOW_INFO_SPLASH)
      .then((showInfoSplash) => {
        this.setState({ showInfoSplash: showInfoSplash === true });

        this.handleClearShowInfoSplash();
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  handleClearShowInfoSplash = async () => {
    const { bar } = this.props;

    await deviceStorage.clearDeviceCacheItem(
      bar.id,
      CACHE_KEY_SHOW_INFO_SPLASH
    );
  };

  render() {
    const { classes } = this.props;
    const { showInfoSplash } = this.state;

    if (!showInfoSplash) {
      return null;
    }

    return (
      <div
        className={classes.container}
        onClick={this.handleClearShowInfoSplash}
      >
        <div className={classes.background} />
      </div>
    );
  }
}

export default withStyles(styles)(InfoSplash);
