import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import config from "tap-io/client/env";

import { hasNativeWrapper } from "../native";

import ContentCard from "tap-io/client/components/common/ContentCard";

import withPasswordAuthentication from "../components/auth/withPasswordAuthentication";
import withBarAuthorization from "../components/auth/withBarAuthorization";
import TopUpWithMollie from "../components/credits/TopUpWithMollie";
import RedeemCoupon from "../components/credits/RedeemCoupon";
import BalanceLog from "../components/credits/BalanceLog";
import Transactions from "../components/credits/Transactions";
import Invoices from "../components/invoice/Invoices";

const styles = (theme) => ({
  subscription: {
    marginTop: 50,
    padding: 10
  },
  warning: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  link: {
    textDecoration: "none"
  },
  title: {
    fontWeight: "bold"
  },
  spacing: {
    height: 40
  }
});

class BarBalancePage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, bar, balance, pricing } = this.props;

    return (
      <div className={classes.container}>
        <ContentCard
          title={t("credits.top-up-credits")}
          subtitle={
            <a
              className={classes.link}
              href="https://watdrinkje.be/faq#credits"
              target="_blank"
              rel="noreferrer"
            >
              {t("credits.more-information-about-credits")}
            </a>
          }
        >
          <Typography variant="subtitle1" className={classes.title}>
            {t("credits.purchase-credits")}
          </Typography>
          {balance && (
            <div>
              {hasNativeWrapper() ? (
                <Typography>
                  {`${t(
                    "credits.not-possible-to-top-up-credits-in-app-visit-web-version"
                  )} `}
                  <a
                    className={classes.link}
                    href={`https://${config.hosting.appDomain}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {config.hosting.appDomain}
                  </a>
                </Typography>
              ) : balance.doNotChargeCredits ? (
                <Typography>
                  {t("credits.you-have-a-subscription-credits-are-disabled")}
                </Typography>
              ) : balance.allowCreditDebt ? (
                <Typography>
                  {t(
                    "credits.you-have-a-subscription-credits-are-charged-via-invoice"
                  )}
                </Typography>
              ) : (
                <TopUpWithMollie
                  bar={bar}
                  balance={balance}
                  pricing={pricing}
                />
              )}
              <div className={classes.spacing} />
              <Typography variant="subtitle1" className={classes.title}>
                {t("credits.redeem-coupon")}
              </Typography>
              <RedeemCoupon bar={bar} />
            </div>
          )}
        </ContentCard>
        <ContentCard title={t("credits.invoices")}>
          <Invoices bar={bar} />
        </ContentCard>
        <ContentCard title={t("credits.balance")}>
          <BalanceLog bar={bar} />
        </ContentCard>
        <ContentCard title={t("credits.transactions")}>
          <Transactions bar={bar} />
        </ContentCard>
      </div>
    );
  }
}

export default withPasswordAuthentication()(
  withBarAuthorization()(
    withStyles(styles, { withTheme: true })(
      withTranslation("common")(BarBalancePage)
    )
  )
);
