import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import LayoutNodeTree from "./LayoutNodeTree";
import EditLayoutNode from "./EditLayoutNode";

const styles = (theme) => ({
  content: {
    display: "flex",
    height: "100%"
  },
  spacing: {
    width: 20
  }
});

class EditLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedNode: null
    };
  }

  componentDidMount() {
    const { layout } = this.props;

    this.setState({ selectedNode: layout });
  }

  handleSelect = (layoutNode) => {
    this.setState({ selectedNode: layoutNode });
  };

  render() {
    const {
      classes,
      t,
      isDisabled,
      bar,
      allBases,
      maxHeight,
      layout,
      onUpdate
    } = this.props;
    const { selectedNode } = this.state;

    return (
      <div className={classes.content}>
        <LayoutNodeTree
          isDisabled={isDisabled}
          maxHeight={maxHeight}
          rootNode={layout}
          selectedNode={selectedNode}
          onUpdate={onUpdate}
          onSelect={this.handleSelect}
        />
        <div className={classes.spacing} />
        <EditLayoutNode
          isDisabled={isDisabled}
          bar={bar}
          allBases={allBases}
          onUpdate={onUpdate}
          onSelect={this.handleSelect}
          layoutNode={selectedNode}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditLayout)
);
