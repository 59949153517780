import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { WHICH_ORDERS } from "../../constants/orders";

const styles = (theme) => ({
  buttons: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(1)
  },
  button: {
    margin: `0 ${theme.spacing(1)}`
  }
});

class WhichOrders extends PureComponent {
  constructor(props) {
    super(props);
  }

  handleChange = (whichOrders) => (event) => {
    const { onChange } = this.props;

    onChange(whichOrders);
  };

  render() {
    const { classes, t, whichOrders } = this.props;

    return (
      <div className={classes.buttons}>
        {WHICH_ORDERS.map((whichOrdersEntry) => (
          <Button
            key={whichOrdersEntry.id}
            className={classes.button}
            onClick={this.handleChange(whichOrdersEntry.id)}
            size="small"
            color="primary"
            variant={
              whichOrders === whichOrdersEntry.id ? "contained" : undefined
            }
            fullWidth
          >
            {t(whichOrdersEntry.label)}
          </Button>
        ))}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(WhichOrders)
);
