import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { List, ListItem, ListItemText, Switch } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { deviceStorage } from "tap-io/storage";

import SelectAmount from "tap-io/client/components/common/SelectAmount";
import ServiceOptionName from "tap-io/models/service/ServiceOptionName";

const styles = (theme) => ({});

const MIN_PRINT_AMOUNT_OF_COPIES = 1;
const MAX_PRINT_AMOUNT_OF_COPIES = 5;

class EditPrinterSettings extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      printOrderWhenClaimed: false,
      printOrderWhenClaimedWithScanner: false,
      printBarLogo: false,
      printOrderCustomerFields: false,
      printAmountOfCopies: MIN_PRINT_AMOUNT_OF_COPIES
    };
  }

  componentDidMount() {
    const { deviceSettings } = this.props;

    if (deviceSettings) {
      this.setState({
        printOrderWhenClaimed: deviceSettings.printOrderWhenClaimed === true,
        printOrderWhenClaimedWithScanner:
          deviceSettings.printOrderWhenClaimedWithScanner === true,
        printBarLogo: deviceSettings.printBarLogo === true,
        printOrderCustomerFields:
          deviceSettings.printOrderCustomerFields === true,
        printAmountOfCopies:
          deviceSettings.printAmountOfCopies > MIN_PRINT_AMOUNT_OF_COPIES
            ? deviceSettings.printAmountOfCopies
            : MIN_PRINT_AMOUNT_OF_COPIES
      });
    }
  }

  handlePrintOrderWhenClaimedChange = (event) => {
    this.setState({
      printOrderWhenClaimed: event.target.checked === true
    });

    // Automatically save
    this.requestSave();
  };

  handlePrintOrderWhenClaimedWithScannerChange = (event) => {
    this.setState({
      printOrderWhenClaimedWithScanner: event.target.checked === true
    });

    // Automatically save
    this.requestSave();
  };

  handlePrintBarLogoChange = (event) => {
    this.setState({
      printBarLogo: event.target.checked === true
    });

    // Automatically save
    this.requestSave();
  };

  handlePrintOrderCustomerFieldsChange = (event) => {
    this.setState({
      printOrderCustomerFields: event.target.checked === true
    });

    // Automatically save
    this.requestSave();
  };

  handlePrintAmountOfCopiesChange = (printAmountOfCopies) => {
    this.setState({ printAmountOfCopies });

    // Automatically save
    this.requestSave();
  };

  requestSave = () => {
    if (this.saveTimeout) {
      clearTimeout(this.saveTimeout);
    }

    this.saveTimeout = setTimeout(() => {
      this.saveTimeout = null;
      this.handleSave();
    }, 1000);
  };

  handleSave = async () => {
    const { t, bar } = this.props;
    const {
      printOrderWhenClaimed,
      printOrderWhenClaimedWithScanner,
      printBarLogo,
      printOrderCustomerFields,
      printAmountOfCopies
    } = this.state;

    if (bar) {
      const toastId = toast(t("settings.save-settings"), { autoClose: false });

      try {
        await deviceStorage.updateDeviceSettings(bar.id, {
          printOrderWhenClaimed,
          printOrderWhenClaimedWithScanner,
          printBarLogo,
          printOrderCustomerFields,
          printAmountOfCopies
        });

        toast.update(toastId, {
          render: t("label.settings-saved"),
          type: toast.TYPE.INFO,
          autoClose: 3000
        });
      } catch (error) {
        toast.update(toastId, {
          render: `${t("label.something-went-wrong")}: (${t(
            error ? error.message : "label.unknown-error"
          )})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
        console.warn(error);
      }
    }
  };

  render() {
    const { t, base } = this.props;
    const {
      printOrderWhenClaimed,
      printOrderWhenClaimedWithScanner,
      printBarLogo,
      printOrderCustomerFields,
      printAmountOfCopies
    } = this.state;

    return (
      <div>
        <List>
          <div>
            <ListItem>
              <ListItemText
                primary={t("printer.print-order-when-claimed")}
                secondary={t(
                  "printer.automatically-print-order-with-printer-when-opened"
                )}
              />
              <Switch
                onChange={this.handlePrintOrderWhenClaimedChange}
                checked={printOrderWhenClaimed}
              />
            </ListItem>
            {base && base.serviceOptions.has(ServiceOptionName.SHOW_AND_GO) && (
              <ListItem>
                <ListItemText
                  primary={t("printer.print-order-when-claimed-with-scanner")}
                  secondary={t(
                    "printer.automatically-print-order-with-printer-when-scanned"
                  )}
                />
                <Switch
                  onChange={this.handlePrintOrderWhenClaimedWithScannerChange}
                  checked={printOrderWhenClaimedWithScanner}
                />
              </ListItem>
            )}
            {(printOrderWhenClaimed || printOrderWhenClaimedWithScanner) && (
              <div>
                <ListItem>
                  <ListItemText
                    primary={t("printer.print-logo")}
                    secondary={t("printer.print-logo-at-top-of-receipt")}
                  />
                  <Switch
                    onChange={this.handlePrintBarLogoChange}
                    checked={printBarLogo}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={t("printer.print-customer-fields")}
                    secondary={t(
                      "printer.print-customer-fields-at-top-of-receipt"
                    )}
                  />
                  <Switch
                    onChange={this.handlePrintOrderCustomerFieldsChange}
                    checked={printOrderCustomerFields}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={t("printer.print-count")}
                    secondary={t("printer.amount-of-times-receipt-is-printed")}
                  />
                  <SelectAmount
                    min={MIN_PRINT_AMOUNT_OF_COPIES}
                    max={MAX_PRINT_AMOUNT_OF_COPIES}
                    amount={printAmountOfCopies}
                    onChange={this.handlePrintAmountOfCopiesChange}
                  />
                </ListItem>
              </div>
            )}
          </div>
        </List>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditPrinterSettings)
);
