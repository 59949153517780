import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Button } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import withStyles from "@mui/styles/withStyles";

import PrintOrderDialog from "./PrintOrderDialog";

import withAuthorization from "../auth/withAuthorization";
import { hasNativeWrapper, printer } from "../../native";
import { toast } from "react-toastify";

const styles = (theme) => ({});

class PrintOrderButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isPrintOrderDialogOpen: false
    };
  }

  handlePrintOrderIfAvailable = () => {
    const { base, order } = this.props;

    if (hasNativeWrapper()) {
      if (order.hasBeenClaimed(base)) {
        this.setState({ isPrintOrderDialogOpen: true });
      } else {
        this.handlePrintOrder();
      }
    }
  };

  handlePrintOrder = async () => {
    const { t, bar, base, assets, order, deviceSettings } = this.props;

    try {
      if (hasNativeWrapper()) {
        await printer.printReceipt(
          t,
          bar,
          base,
          assets,
          order,
          deviceSettings.printBarLogo,
          deviceSettings.printOrderCustomerFields,
          deviceSettings.printAmountOfCopies
        );
      }
    } catch (error) {
      if (error) {
        toast.error(error.message);
      }
      console.warn(error);
    }
  };

  handlePrintOrderDialogClose = () => {
    this.setState({ isPrintOrderDialogOpen: false });
  };

  render() {
    const { classes, t, bar, base, order, assets, deviceSettings, isDisabled } =
      this.props;
    const { isPrintOrderDialogOpen } = this.state;

    if (hasNativeWrapper()) {
      return (
        <>
          <PrintOrderDialog
            isOpen={isPrintOrderDialogOpen}
            onClose={this.handlePrintOrderDialogClose}
            bar={bar}
            base={base}
            order={order}
            assets={assets}
            deviceSettings={deviceSettings}
          />
          <Button
            disabled={isDisabled}
            color="primary"
            variant="contained"
            startIcon={<PrintIcon />}
            onClick={this.handlePrintOrderIfAvailable}
          >
            {t("label.print")}
          </Button>
        </>
      );
    }

    return null;
  }
}

export default withAuthorization()(
  withStyles(styles)(withTranslation("common")(PrintOrderButton))
);
