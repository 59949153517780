import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  sequenceNumberLabel: {
    display: "inline-block",
    padding: "1px 8px",
    borderRadius: 16,
    fontWeight: "bold",
    textTransform: "uppercase",
    backgroundColor: "black",
    color: "white"
  }
});

class OrderSequenceNumberLabel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, base, order, isCompact, fontSize } = this.props;

    if (!base) {
      return null;
    }
    const sequenceNumber = order.fulfilment.getSequenceNumber(base.id);

    if (sequenceNumber === null) {
      return null;
    }

    const style = fontSize ? { fontSize, borderRadius: fontSize / 2 } : undefined;

    return (
      <div className={classes.sequenceNumberLabel} style={style}>
        {!isCompact && <span>{t("order.sequence-number")}</span>}{" "}
        {sequenceNumber}
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(OrderSequenceNumberLabel)
);
