import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import withStyles from "@mui/styles/withStyles";

import SelectProductFromMenus from "../catalogue/SelectProductFromMenus";
import SelectAmount from "tap-io/client/components/common/SelectAmount";

const styles = (theme) => ({
  inputContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: 50
  },
  inputLabel: {
    width: "100%"
  },
  secondaryAction: {
    right: 0
  }
});

class SelectProductVoucherValue extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      products: {}
    };
  }

  componentDidMount() {
    const { value, onChange } = this.props;

    if (!value) {
      onChange([]);
    }

    this.fetchProducts();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { value } = this.props;
    const prevValue = prevProps.value;

    if (value && prevValue && value.length !== prevValue.length) {
      this.fetchProducts();
    }
  }

  fetchProducts = () => {
    const { value, catalogue } = this.props;
    const { products } = this.state;

    if (value) {
      const productsToAdd = {};

      value.forEach((entry) => {
        if (!products[entry.productId]) {
          productsToAdd[entry.productId] = catalogue.getProduct(
            entry.productId
          );
        }
      });

      if (Object.keys(productsToAdd).length > 0) {
        this.setState({ products: { ...products, ...productsToAdd } });
      }
    }
  };

  handleAddEntry = (product) => {
    const { value, onChange } = this.props;

    onChange([
      ...value,
      {
        productId: product.id,
        amount: 1,
        discountPercentage: 100
      }
    ]);
  };

  handleRemoveEntry = (entryToRemove) => (event) => {
    const { value, onChange } = this.props;

    onChange(
      value.filter((entry) => entry.productId !== entryToRemove.productId)
    );
  };

  handleEntryAmountChange = (entryToUpdate) => (amount) => {
    const { value, onChange } = this.props;

    onChange(
      value
        .map((entry) => {
          if (entry.productId === entryToUpdate.productId) {
            if (amount === 0) {
              return null;
            }

            entry.amount = amount;
          }

          return entry;
        })
        .filter((entry) => entry !== null)
    );
  };

  render() {
    const { classes, t, isDisabled, bar, catalogue, allMenus, value } =
      this.props;
    const { products } = this.state;

    return (
      <div>
        <SelectProductFromMenus
          isDisabled={isDisabled}
          catalogue={catalogue}
          menus={allMenus}
          excludeProductIds={value ? value.map((entry) => entry.productId) : []}
          onSelect={this.handleAddEntry}
        />
        {bar && bar.params && bar.params.currency && (
          <Typography>{bar.params.currency}</Typography>
        )}
        <List>
          {value && value.length > 0 ? (
            value.map((entry) => (
              <ListItem key={entry.productId} disableGutters>
                <ListItemIcon>
                  <IconButton
                    edge="start"
                    disabled={isDisabled}
                    onClick={this.handleRemoveEntry(entry)}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemIcon>
                <ListItemText
                  primary={
                    products[entry.productId]
                      ? products[entry.productId].name
                      : ""
                  }
                />
                <ListItemSecondaryAction className={classes.secondaryAction}>
                  <SelectAmount
                    isDisabled={isDisabled}
                    min={0}
                    max={100}
                    step={1}
                    amount={entry.amount}
                    onChange={this.handleEntryAmountChange(entry)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))
          ) : (
            <Typography variant="caption">
              {t("voucher.add-products-above")}
            </Typography>
          )}
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(SelectProductVoucherValue)
);
