import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import withStyles from "@mui/styles/withStyles";

import { zoneService } from "tap-io/client/services";
import ConfirmDialog from "tap-io/client/components/common/ConfirmDialog";

import AddZoneDialog from "./AddZoneDialog";
import EditZoneDialog from "./EditZoneDialog";
import { serviceOptionHelper } from "tap-io/helpers";
import CapabilityMaxAmountIndicator from "../subscription/CapabilityMaxAmountIndicator";

const styles = (theme) => ({
  description: {
    display: "flex",
    flexDirection: "column"
  },
  accentuate: {
    color: "red"
  }
});

class EditZones extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      codeForNewZoneBeingAdded: null,
      zonePendingToggleIsActiveConfirmation: null,
      zonePendingRemovalConfirmation: null,
      zoneBeingEdited: null
    };
  }

  handleAddZone = async () => {
    const { t, bar } = this.props;

    const toastId = toast(t("zone.generating-code-for-new-zone"), {
      autoClose: false
    });

    try {
      const newZoneCode = await zoneService.getNextFreeZoneCode(bar.id);

      toast.dismiss(toastId);

      this.setState({ codeForNewZoneBeingAdded: newZoneCode });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleAddZoneDialogClose = () => {
    this.setState({ codeForNewZoneBeingAdded: null });
  };

  handleRemoveZone = (zone) => (event) => {
    event.stopPropagation();

    this.setState({
      zonePendingRemovalConfirmation: zone
    });
  };

  handleRemoveZoneConfirm = async () => {
    const { t } = this.props;
    const { zonePendingRemovalConfirmation } = this.state;

    this.setState({
      zonePendingRemovalConfirmation: null
    });

    const toastId = toast(t("zone.removing-zone"), { autoClose: false });

    try {
      await zoneService.removeZone(zonePendingRemovalConfirmation);

      toast.update(toastId, {
        render: t("zone.zone-removed"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleRemoveZoneCancel = () => {
    this.setState({ zonePendingRemovalConfirmation: null });
  };

  handleEditZone = (zone) => (event) => {
    this.setState({ zoneBeingEdited: zone });
  };

  handleToggleZoneIsActive = (zone) => (event) => {
    event.preventDefault();

    this.setState({ zonePendingToggleIsActiveConfirmation: zone });
  };

  handleToggleZoneIsActiveConfirm = async () => {
    const { zonePendingToggleIsActiveConfirmation } = this.state;

    this.setState({ zonePendingToggleIsActiveConfirmation: null });

    await this.toggleZoneIsActive(zonePendingToggleIsActiveConfirmation);
  };

  handleToggleZoneIsActiveCancel = () => {
    this.setState({ zonePendingToggleIsActiveConfirmation: null });
  };

  handleEditZoneDialogClose = () => {
    this.setState({ zoneBeingEdited: null });
  };

  getZoneDeliveryDescription = (zone) => {
    const { t } = this.props;

    return zone.serviceOptions
      .map((serviceOption) =>
        serviceOptionHelper.getServiceOptionLabel(serviceOption, t)
      )
      .join(", ");
  };

  toggleZoneIsActive = async (zone) => {
    const { t } = this.props;

    const toastId = toast(
      zone.isActive ? t("zone.closing-zone") : t("zone.opening-zone"),
      {
        autoClose: false
      }
    );

    try {
      zone.isActive = !zone.isActive;
      await zoneService.updateZone(zone);

      toast.update(toastId, {
        render: zone.isActive ? t("zone.zone-opened") : t("zone.zone-closed"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, bar, subscription, allBases, allZones } = this.props;
    const {
      codeForNewZoneBeingAdded,
      zonePendingToggleIsActiveConfirmation,
      zonePendingRemovalConfirmation,
      zoneBeingEdited
    } = this.state;

    const maxHeight = subscription ? subscription.capabilities.getMaxLayoutHeight() : 0;

    return (
      <div>
        <AddZoneDialog
          isOpen={codeForNewZoneBeingAdded !== null}
          onClose={this.handleAddZoneDialogClose}
          bar={bar}
          subscription={subscription}
          allBases={allBases}
          maxHeight={maxHeight}
          code={codeForNewZoneBeingAdded}
        />
        <EditZoneDialog
          isOpen={zoneBeingEdited !== null}
          onClose={this.handleEditZoneDialogClose}
          bar={bar}
          subscription={subscription}
          allBases={allBases}
          maxHeight={maxHeight}
          zone={zoneBeingEdited}
        />
        <ConfirmDialog
          isOpen={zonePendingToggleIsActiveConfirmation !== null}
          title={t(
            zonePendingToggleIsActiveConfirmation?.isActive
              ? "zone.close-zone"
              : "zone.open-zone"
          )}
          description={t(
            zonePendingToggleIsActiveConfirmation?.isActive
              ? "zone.are-you-sure-you-want-to-close-this-zone"
              : "zone.are-you-sure-you-want-to-open-this-zone"
          )}
          confirmButtonLabel={t(
            zonePendingToggleIsActiveConfirmation?.isActive
              ? "zone.close-zone"
              : "zone.open-zone"
          )}
          onConfirm={this.handleToggleZoneIsActiveConfirm}
          onCancel={this.handleToggleZoneIsActiveCancel}
        />
        <ConfirmDialog
          isOpen={zonePendingRemovalConfirmation !== null}
          title={t("zone.confirm-removal-of-zone")}
          description={t("zone.are-you-sure-you-want-to-remove-this-zone")}
          confirmButtonLabel={t("label.remove")}
          onConfirm={this.handleRemoveZoneConfirm}
          onCancel={this.handleRemoveZoneCancel}
        />
        <List>
          {allZones && subscription && (
            <ListItem>
              <CapabilityMaxAmountIndicator
                currentAmount={allZones.length}
                maxAmount={subscription.capabilities.getMaxAmountOfZones()}
                amountDescription="zone.amount-of-zones-used"
                amountExceededDescription="zone.max-amount-of-zones-exceeded-remove-zones-to-continue"
              />
            </ListItem>
          )}
          {allZones &&
            allZones.map((zone) => (
              <ListItem key={zone.id} onClick={this.handleEditZone(zone)}>
                <ListItemIcon>
                  <IconButton
                    onClick={this.handleRemoveZone(zone)}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemIcon>
                <ListItemText
                  primary={`${zone.name} (${zone.code})`}
                  secondary={
                    <span className={classes.description}>
                      <span>{this.getZoneDeliveryDescription(zone)}</span>
                      {zone.baseIds.length === 0 && (
                        <strong className={classes.accentuate}>
                          {t("zone.no-bases-selected")}
                        </strong>
                      )}
                    </span>
                  }
                />
                <ListItemSecondaryAction>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={
                          !!(maxHeight && zone.layout.height > maxHeight)
                        }
                        onChange={this.handleToggleZoneIsActive(zone)}
                        checked={zone.isActive}
                      />
                    }
                    label={
                      <Typography variant="caption">
                        {t(
                          maxHeight && zone.layout.height > maxHeight
                            ? "zone.update-zone-to-open-it"
                            : "zone.opened-question"
                        )}
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          {subscription &&
            allZones &&
            allZones.length <
              subscription.capabilities.getMaxAmountOfZones() && (
              <ListItem>
                <Button
                  fullWidth
                  startIcon={<AddIcon />}
                  onClick={this.handleAddZone}
                >
                  {t("zone.add-zone")}
                </Button>
              </ListItem>
            )}
        </List>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditZones)
);
