import { Asyncer } from "../../../components/common/Asyncer";
import { Async } from "../../../components/common/useAsync";
import { DepositReport } from "../../models/DepositReport";
import { BarStats } from "../common/BarStats";
import { DownloadIconCSVButton } from "../common/DownloadCSVIconButton";
import { FailedStatCard } from "../common/FailedStatCard";
import { LoadingStatCard } from "../common/LoadingStatCard";
import { StatCard } from "../common/StatCard";

export type AmountOfDepositsCollectedProps = {
  async: Async<DepositReport>;
  mark: string;
  t: any;
};

export function AmountOfDepositsCollected(
  props: AmountOfDepositsCollectedProps
) {
  const { async, mark, t } = props;

  const title = t("stats.stat.deposits-collected.title");
  const description = t("stats.stat.deposits-collected.description");

  return (
    <Asyncer
      async={async}
      Busy={<LoadingStatCard title={title} description={description} />}
      Failed={
        <FailedStatCard title={title} description={description} async={async} />
      }
    >
      {(report) => {
        return (
          <StatCard
            title={title}
            description={description}
            action={
              <DownloadIconCSVButton
                title={`${title}-${mark}`}
                csv={report.toCollectionCSV(t)}
              />
            }
          >
            <BarStats
              data={report.data}
              x="deposit"
              formatX={(_, r) => r.name}
              y="amountCollected"
              formatY={report.formatInteger}
              t={t}
            />
          </StatCard>
        );
      }}
    </Asyncer>
  );
}
