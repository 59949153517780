import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { List, ListSubheader, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import TopUpWithMollie from "../credits/TopUpWithMollie";
import RedeemCoupon from "../credits/RedeemCoupon";
import { hasNativeWrapper } from "../../native";
import config from "tap-io/client/env";

const styles = (theme) => ({
  container: {},
  content: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  warning: {
    fontWeight: "bold",
    color: "red",
    margin: "20px 0"
  },
  list: {
    width: 400
  },
  left: {
    textAlign: "left"
  },
  link: {
    textDecoration: "none"
  }
});

class TopUpCreditsStep extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { setOnNext } = this.props;
    setOnNext(() => {});
  }

  render() {
    const { classes, t, bar, subscription, pricing } = this.props;

    return (
      <div className={classes.container}>
        <a
          className={classes.link}
          href="https://watdrinkje.be/faq#credits"
          target="_blank"
          rel="noreferrer"
        >
          {t("credits.more-information-about-credits")}
        </a>
        {subscription && subscription.isRequested() ? (
          <Typography className={classes.warning}>
            {t("setup.subscription-requested-credits-info")}
          </Typography>
        ) : (
          <div className={classes.content}>
            <List className={classes.list}>
              <ListSubheader>{t("setup.top-up-credits")}</ListSubheader>
              <div className={classes.left}>
                {hasNativeWrapper() ? (
                  <Typography>
                    {`${t(
                      "credits.not-possible-to-top-up-credits-in-app-visit-web-version"
                    )} `}
                    <a
                      className={classes.link}
                      href={`https://${config.hosting.appDomain}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {config.hosting.appDomain}
                    </a>
                  </Typography>
                ) : (
                  <TopUpWithMollie bar={bar} pricing={pricing} />
                )}
              </div>
            </List>
            <List className={classes.list}>
              <ListSubheader>{t("setup.redeem-coupon")}</ListSubheader>
              <div className={classes.left}>
                <RedeemCoupon bar={bar} />
              </div>
            </List>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(TopUpCreditsStep));
