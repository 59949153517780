import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { voucherService } from "tap-io/client/services";
import VoucherType from "tap-io/models/voucher/VoucherType";
import SelectAmount from "tap-io/client/components/common/SelectAmount";

import {
  DEFAULT_VOUCHER_TEMPLATE_VOUCHER_MAX_AGE_IN_DAYS,
  DEFAULT_VOUCHER_TEMPLATE_VOUCHER_VALUE
} from "../../constants/vouchers";

const styles = (theme) => ({
  dialog: {
    minWidth: 500
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0"
  },
  inputLabel: {
    width: "100%"
  },
  voucherValue: {
    margin: "-5px -10px",
    padding: "5px 10px",
    borderRadius: 4,
    backgroundColor: theme.palette.background.default
  }
});

class EditVoucherTemplateDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (voucherTemplate) => {
    return {
      isDisabled: false,
      name: voucherTemplate ? voucherTemplate.name : "",
      voucherType: voucherTemplate
        ? voucherTemplate.voucherType
        : VoucherType.CURRENCY,
      voucherValue: voucherTemplate
        ? voucherTemplate.voucherValue
        : DEFAULT_VOUCHER_TEMPLATE_VOUCHER_VALUE,
      voucherMaxAgeInDays: voucherTemplate
        ? voucherTemplate.voucherMaxAgeInDays
        : DEFAULT_VOUCHER_TEMPLATE_VOUCHER_MAX_AGE_IN_DAYS,
      isVoucherValuePredetermined: voucherTemplate
        ? voucherTemplate.voucherValue !== null
        : true,
      combineMultipleVouchers: voucherTemplate
        ? voucherTemplate.combineMultipleVouchers
        : false
    };
  };

  componentDidUpdate(prevProps, prevState) {
    const { isOpen, voucherTemplate } = this.props;

    if (isOpen && !prevProps.isOpen) {
      this.setState(this.initialState(voucherTemplate));
    }
  }

  handleNameChange = (event) => {
    const val = event.target.value;
    this.setState({ name: val });
  };

  handleVoucherMaxAgeInDaysChange = (voucherMaxAgeInDays) => {
    this.setState({ voucherMaxAgeInDays });
  };

  handleVoucherValueChange = (event) => {
    const value = event.target.value;

    this.setState({ voucherValue: value });
  };

  handleVoucherValueBlur = (event) => {
    const { voucherValue } = this.state;

    const parsedVoucherValue = parseFloat(voucherValue);

    this.setState({
      voucherValue:
        isNaN(parsedVoucherValue) || parsedVoucherValue <= 0
          ? DEFAULT_VOUCHER_TEMPLATE_VOUCHER_VALUE
          : voucherValue
    });
  };

  handleIsVoucherValuePredeterminedChange = (event) => {
    const isVoucherValuePredetermined = event.target.checked === true;

    this.setState({
      isVoucherValuePredetermined,
      voucherValue: isVoucherValuePredetermined
        ? DEFAULT_VOUCHER_TEMPLATE_VOUCHER_VALUE
        : null,
      combineMultipleVouchers: false
    });
  };

  handleCombineMultipleVouchersChange = (event) => {
    this.setState({ combineMultipleVouchers: event.target.checked === true });
  };

  handleUpdateVoucherTemplate = async () => {
    const { t, onClose, voucherTemplate } = this.props;
    const {
      name,
      voucherType,
      voucherValue,
      voucherMaxAgeInDays,
      isVoucherValuePredetermined,
      combineMultipleVouchers
    } = this.state;

    const toastId = toast(t("voucher.updating-voucher-template"), {
      autoClose: false
    });
    this.setState({ isDisabled: true });

    try {
      voucherTemplate.name = name.trim();
      voucherTemplate.voucherType = voucherType;
      voucherTemplate.voucherValue = isVoucherValuePredetermined
        ? voucherValue
        : null;
      voucherTemplate.voucherMaxAgeInDays = voucherMaxAgeInDays;
      voucherTemplate.combineMultipleVouchers = combineMultipleVouchers;

      await voucherService.updateVoucherTemplate(voucherTemplate);

      toast.update(toastId, {
        render: t("voucher.voucher-template-updated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false }, onClose);
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.message})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, isOpen, onClose, bar } = this.props;
    const {
      isDisabled,
      name,
      voucherMaxAgeInDays,
      voucherValue,
      isVoucherValuePredetermined,
      combineMultipleVouchers
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle>{t("voucher.edit-voucher-template")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("voucher.edit-voucher-template-below")}
          </DialogContentText>
          <TextField
            variant="standard"
            margin="dense"
            label={t("label.name")}
            helperText={t("voucher.name-for-this-voucher-template")}
            fullWidth
            value={name}
            onChange={this.handleNameChange}
          />
          <div className={classes.inputContainer}>
            <Typography className={classes.inputLabel}>
              {t("voucher.voucher-max-age-in-days")}
            </Typography>
            <SelectAmount
              isDisabled={isDisabled}
              allowInput={true}
              min={1}
              max={366}
              amount={voucherMaxAgeInDays}
              onChange={this.handleVoucherMaxAgeInDaysChange}
            />
          </div>
          <div className={classes.voucherValue}>
            <div className={classes.inputContainer}>
              <div className={classes.inputLabel}>
                <Typography>
                  {t("voucher.is-voucher-value-predetermined-label")}
                </Typography>
                <Typography variant="caption">
                  {t("voucher.is-voucher-value-predetermined-description")}
                </Typography>
              </div>
              <Switch
                edge="end"
                disabled={isDisabled}
                checked={isVoucherValuePredetermined}
                onChange={this.handleIsVoucherValuePredeterminedChange}
              />
            </div>
            {isVoucherValuePredetermined && (
              <div className={classes.inputContainer}>
                <Typography className={classes.inputLabel}>
                  {`${t("voucher.value-per-voucher")} `}
                  {bar && bar.params && bar.params.orderCurrency ? (
                    <span>
                      (<strong>{bar.params.orderCurrency}</strong>)
                    </span>
                  ) : (
                    ""
                  )}
                </Typography>
                <TextField
                  variant="standard"
                  type="number"
                  color="secondary"
                  disabled={isDisabled}
                  InputProps={{ inputProps: { min: 0 } }}
                  value={`${voucherValue}`}
                  onChange={this.handleVoucherValueChange}
                  onBlur={this.handleVoucherValueBlur}
                />
              </div>
            )}
          </div>
          {isVoucherValuePredetermined && (
            <div className={classes.inputContainer}>
              <div className={classes.inputLabel}>
                <Typography>
                  {t("voucher.combine-multiple-vouchers-label")}
                </Typography>
                <Typography variant="caption">
                  {t("voucher.combine-multiple-vouchers-description")}
                </Typography>
              </div>
              <Switch
                edge="end"
                disabled={isDisabled}
                checked={combineMultipleVouchers}
                onChange={this.handleCombineMultipleVouchersChange}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={isDisabled} onClick={onClose} color="secondary">
            {t("label.cancel")}
          </Button>
          <Button
            disabled={isDisabled}
            onClick={this.handleUpdateVoucherTemplate}
            color="primary"
          >
            {t("label.update")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(EditVoucherTemplateDialog)
);
