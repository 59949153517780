import React, { PropsWithChildren, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { depositService } from "tap-io/client/services";
import Bar from "tap-io/models/bar/Bar";
import Deposit from "tap-io/models/deposit/Deposit";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
  Theme
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      minWidth: 400
    },
    largeSpacing: {
      height: 25
    },
    smallSpacing: {
      height: 10
    }
  })
);

type EditDepositDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  bar: Bar;
  deposit: Deposit | null;
};

function EditDepositDialog({
  isOpen,
  onClose,
  bar,
  deposit
}: PropsWithChildren<EditDepositDialogProps>) {
  const { t, i18n } = useTranslation("common");
  const classes = useStyles();

  const [isDisabled, setIsDisabled] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [name, setName] = useState("");
  const [purchaseValue, setPurchaseValue] = useState(0);
  const [tradeInValue, setTradeInValue] = useState(0);

  useEffect(() => {
    if (isOpen) {
      setInitialState();
    }
  }, [isOpen]);

  const setInitialState = () => {
    setIsDisabled(false);
    setIsActive(deposit?.isActive || true);
    setName(deposit?.name || "");
    setPurchaseValue(deposit?.purchaseValue || 0);
    setTradeInValue(deposit?.tradeInValue || 0);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePurchaseValueChange = (event) => {
    const value = event.target.value.replace(/^0+(?=\d)/, ""); // Remove leading zeros
    setPurchaseValue(value);
  };

  const handleTradeInValueChange = (event) => {
    const value = event.target.value.replace(/^0+(?=\d)/, ""); // Remove leading zeros
    setTradeInValue(value);
  };

  const handleUpdateDeposit = async () => {
    const toastId = toast(t("deposit.updating-deposit"), { autoClose: false });
    setIsDisabled(true);

    try {
      if (!deposit) {
        throw new Error("error.deposit-is-not-defined");
      }

      const parsedName = name.trim();
      const parsedPurchaseValue = parseFloat(`${purchaseValue}`);
      const parsedTradeInValue = parseFloat(`${tradeInValue}`);

      deposit.isActive = isActive;
      deposit.name = parsedName;
      deposit.purchaseValue = parsedPurchaseValue;
      deposit.tradeInValue = parsedTradeInValue;

      await depositService.updateDeposit(deposit);
      toast.update(toastId, {
        render: t("deposit.deposit-updated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      setIsDisabled(false);
      onClose();
    } catch (error: any) {
      console.warn(error);

      setIsDisabled(false);
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(
          error && error.message ? error.message : "error.unknown-error"
        )})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };
  return (
    <Dialog open={isOpen} onClose={onClose} classes={{ paper: classes.dialog }}>
      <DialogTitle>{t("deposit.edit-deposit")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("deposit.edit-deposit-below")}</DialogContentText>
        <TextField
          variant="standard"
          margin="dense"
          label={t("deposit.name")}
          fullWidth
          value={name}
          disabled={isDisabled}
          onChange={handleNameChange}
        />
        <div className={classes.smallSpacing} />
        <TextField
          variant="standard"
          label={t("deposit.purchase-value")}
          type="number"
          inputProps={{
            step: 0.01,
            min: 0.01,
            max: 100
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {bar.getOrderCurrency()}
              </InputAdornment>
            )
          }}
          fullWidth
          value={`${purchaseValue}`}
          disabled={isDisabled}
          onChange={handlePurchaseValueChange}
        />
        <div className={classes.smallSpacing} />
        <TextField
          variant="standard"
          label={t("deposit.trade-in-value")}
          type="number"
          inputProps={{
            step: 0.01,
            min: 0.01,
            max: 100
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {bar.getOrderCurrency()}
              </InputAdornment>
            )
          }}
          fullWidth
          value={`${tradeInValue}`}
          disabled={isDisabled}
          onChange={handleTradeInValueChange}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isDisabled} onClick={onClose} color="secondary">
          {t("label.cancel")}
        </Button>
        <Button
          disabled={isDisabled}
          onClick={handleUpdateDeposit}
          color="primary"
        >
          {t("label.update")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditDepositDialog;
