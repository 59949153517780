import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";
import EditLayoutNodeBasics from "./EditLayoutNodeBasics";

const styles = (theme) => ({});

class EditSingleLayoutNode extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, t, isDisabled, bar, allBases, layoutNode, onUpdate } =
      this.props;

    return (
      <EditLayoutNodeBasics
        isDisabled={isDisabled}
        bar={bar}
        allBases={allBases}
        layoutNode={layoutNode}
        onUpdate={onUpdate}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditSingleLayoutNode)
);
