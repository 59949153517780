import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  amount: {
    fontWeight: "bold"
  },
  error: {
    color: theme.palette.error.main
  }
});

class CapabilityMaxAmountIndicator extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes,
      t,
      currentAmount,
      maxAmount,
      amountDescription,
      amountExceededDescription
    } = this.props;

    const isError = maxAmount !== undefined && currentAmount > maxAmount;

    return (
      <div className={isError ? classes.error : undefined}>
        <Typography className={classes.amount}>
          {t(amountDescription, {
            amount: `${currentAmount} / ${
              maxAmount === undefined ? "∞" : maxAmount
            }`
          })}
        </Typography>
        {isError && <Typography>{t(amountExceededDescription)}</Typography>}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(CapabilityMaxAmountIndicator)
);
