import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import withStyles from "@mui/styles/withStyles";
import { utilsHelper } from "tap-io/helpers";
import {
  FIELD_ICONS,
  FIELD_LABELS,
  FIELD_TYPES
} from "tap-io/client/constants/fields";
import TextCustomerField from "tap-io/models/bar/TextCustomerField";
import EmailCustomerField from "tap-io/models/bar/EmailCustomerField";
import DateCustomerField from "tap-io/models/bar/DateCustomerField";
import SelectCustomerField from "tap-io/models/bar/SelectCustomerField";
import SelectCustomerFieldOption from "tap-io/models/bar/SelectCustomerFieldOption";
import ToggleCustomerField from "tap-io/models/bar/ToggleCustomerField";
import CustomerFieldType from "tap-io/models/bar/CustomerFieldType";
import ParamName from "tap-io/models/bar/ParamName";

import { PARAM_DESCRIPTIONS, PARAM_LABELS } from "../../constants/params";
import EditCustomerField from "./EditCustomerField";

const styles = (theme) => ({
  content: {
    paddingTop: 0 // Sticky subheader
  },
  subheader: {
    backgroundColor: theme.palette.background.paper
  },
  divider: {
    margin: "20px 15px"
  },
  typeButton: {
    width: "100%"
  },
  spacing: {
    height: 20
  }
});

class EditCustomerFieldsDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (customerFields, replyToEmailAddress) => {
    return {
      customerFields: customerFields ? customerFields : [],
      replyToEmailAddress: replyToEmailAddress ? replyToEmailAddress : ""
    };
  };

  componentDidUpdate(prevProps, prevState) {
    const { isOpen, customerFields, replyToEmailAddress } = this.props;

    if (isOpen && !prevProps.isOpen) {
      this.setState(this.initialState(customerFields, replyToEmailAddress));
    }
  }

  getFieldIcon = (fieldType, color) => {
    const FieldIcon = FIELD_ICONS[fieldType]
      ? FIELD_ICONS[fieldType]
      : HelpOutlineIcon;
    return <FieldIcon color={color} />;
  };

  handleAddField = (type) => (event) => {
    const { customerFields } = this.state;

    const fieldProps = {
      id: utilsHelper.generateUUID(),
      name: "",
      isRequired: false
    };

    let field;

    switch (type) {
      case CustomerFieldType.TEXT:
        field = new TextCustomerField(fieldProps);
        break;
      case CustomerFieldType.EMAIL:
        field = new EmailCustomerField({
          ...fieldProps,
          sendOrderConfirmationToThisAddress: false
        });
        break;
      case CustomerFieldType.DATE:
        field = new DateCustomerField(fieldProps);
        /*field.minDate = null;
        field.maxDate = null;*/
        break;
      case CustomerFieldType.TOGGLE:
        field = new ToggleCustomerField(fieldProps);
        /*field.minDate = null;
        field.maxDate = null;*/
        break;
      case CustomerFieldType.SELECT:
        field = new SelectCustomerField({
          ...fieldProps,
          options: [
            new SelectCustomerFieldOption({
              id: utilsHelper.generateUUID(),
              label: ""
            })
          ]
        });
        break;
      default:
        break;
    }

    this.setState({
      customerFields: [...customerFields, field]
    });
  };

  handleUpdateField = (fieldToUpdate) => {
    const { customerFields } = this.state;

    const newFields = customerFields.map((field) =>
      field.id === fieldToUpdate.id ? fieldToUpdate : field
    );
    this.setState({ customerFields: newFields });
  };

  handleRemoveField = (fieldToRemove) => {
    const { customerFields } = this.state;

    const newFields = customerFields.filter((field) => {
      return field.id !== fieldToRemove.id;
    });

    this.setState({ customerFields: newFields });
  };

  isReplyToEmailAddressRequired = () => {
    const { bar } = this.props;
    const { customerFields } = this.state;

    return (
      bar.deliveryMethods.isPickupActive ||
      (customerFields &&
        customerFields.filter(
          (field) =>
            field.type === "email" && field.sendOrderConfirmationToThisAddress
        ).length > 0)
    );
  };

  handleReplyToEmailAddressChange = (event) => {
    const val = event.target.value;

    this.setState({
      replyToEmailAddress: val
    });
  };

  handleCancel = () => {
    const { onCancel } = this.props;

    onCancel();
  };

  handleSave = () => {
    const { t, onSave } = this.props;
    const { customerFields, replyToEmailAddress } = this.state;

    const parsedReplyToEmailAddress = utilsHelper.parseEmail(
      replyToEmailAddress.trim().toLowerCase()
    );

    if (this.isReplyToEmailAddressRequired() && !parsedReplyToEmailAddress) {
      return toast.error(t("error.enter-a-valid-reply-to-email-address"));
    }

    const fieldsToSave = customerFields
      .map((field) => {
        if (field && field.name) {
          field.name = field.name.trim();
          if (field.name.length > 0) {
            return field;
          }
        }

        return undefined;
      })
      .filter((field) => field !== undefined);

    const replyToEmailAddressToSave = parsedReplyToEmailAddress
      ? parsedReplyToEmailAddress
      : "";

    onSave({
      customerFields: fieldsToSave,
      replyToEmailAddress: replyToEmailAddressToSave
    });
  };

  render() {
    const { classes, t, isOpen, bar } = this.props;
    const { customerFields, replyToEmailAddress } = this.state;

    const hasDefaultCustomerFields =
      bar &&
      bar.params &&
      bar.params.defaultCustomerFields &&
      bar.params.defaultCustomerFields.length > 0;

    return (
      <Dialog open={isOpen} onClose={this.handleCancel} fullWidth={true}>
        <DialogTitle>{t("settings.edit-customer-fields")}</DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText>
            {t("settings.edit-customer-fields-below")}
            <br />
            <strong>
              {`${t("label.warning")}: '${t(
                FIELD_LABELS[CustomerFieldType.DATE]
              )}', '${t(FIELD_LABELS[CustomerFieldType.TOGGLE])}' ${t(
                "label.and"
              )} '${t(FIELD_LABELS[CustomerFieldType.SELECT])}' ${t(
                "settings.x-customer-fields-are-required-to-be-completed-by-your-customer"
              )}.`}
            </strong>
          </DialogContentText>
          <List>
            <ListItem disableGutters>
              {FIELD_TYPES.map((fieldType, index) => (
                <Button
                  key={index}
                  startIcon={this.getFieldIcon(fieldType)}
                  className={classes.typeButton}
                  onClick={this.handleAddField(fieldType)}
                >
                  {t(FIELD_LABELS[fieldType])}
                </Button>
              ))}
            </ListItem>
            {(replyToEmailAddress || this.isReplyToEmailAddressRequired()) && (
              <ListItem disableGutters>
                <TextField
                  variant="standard"
                  margin="dense"
                  label={t(PARAM_LABELS[ParamName.REPLY_TO_EMAIL_ADDRESS])}
                  helperText={t(
                    PARAM_DESCRIPTIONS[ParamName.REPLY_TO_EMAIL_ADDRESS]
                  )}
                  fullWidth
                  value={replyToEmailAddress}
                  onChange={this.handleReplyToEmailAddressChange}
                />
              </ListItem>
            )}
            {hasDefaultCustomerFields && (
              <ListSubheader disableGutters className={classes.subheader}>
                {t("settings.custom-customer-fields")}
              </ListSubheader>
            )}
            {customerFields && customerFields.length > 0 ? (
              customerFields.map((field, index) => (
                <div key={field.id}>
                  <EditCustomerField
                    field={field}
                    disableGutters={true}
                    onUpdate={this.handleUpdateField}
                    onRemove={this.handleRemoveField}
                  />
                  {index < customerFields.length - 1 && (
                    <Divider className={classes.divider} />
                  )}
                </div>
              ))
            ) : (
              <ListItem disableGutters>
                <ListItemText primary={t("settings.no-fields-found")} />
              </ListItem>
            )}
            {hasDefaultCustomerFields && (
              <div>
                <div className={classes.spacing} />
                <ListSubheader disableGutters className={classes.subheader}>
                  {t("settings.default-customer-fields")}
                </ListSubheader>
                {bar.params.defaultCustomerFields.map((field, index) => (
                  <div key={field.id}>
                    <EditCustomerField
                      field={field}
                      disableGutters={true}
                      isDisabled={true}
                      onUpdate={this.handleUpdateField}
                      onRemove={this.handleRemoveField}
                    />
                    {index < customerFields.length - 1 && (
                      <Divider className={classes.divider} />
                    )}
                  </div>
                ))}
              </div>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="secondary">
            {t("label.cancel")}
          </Button>
          <Button onClick={this.handleSave} color="primary">
            {t("label.save")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(
  withTranslation("common")(EditCustomerFieldsDialog)
);
