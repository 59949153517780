import { Stat } from "./Stat";

type Data = {
  device: string;
  name: string;
  version: string;
  amountOfOrders: number;
  amountOfProducts: number;
};

export class OperationalDeviceReport extends Stat<Data> {
  toCSV(t: any) {
    return this.generateCSV(
      {
        device: true,
        name: true,
        version: true,
        amountOfOrders: this.formatInteger,
        amountOfProducts: this.formatInteger
      },
      t
    );
  }
}
