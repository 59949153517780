import React, { PureComponent } from "react";
import classNames from "classnames";

import { alpha } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";

import OrderDeliveryLabel from "tap-io/client/components/order/OrderDeliveryLabel";

import OrderEstimatedPreparationDurationLabel from "./OrderEstimatedPreparationDurationLabel";
import OrderSequenceNumberLabel from "./OrderSequenceNumberLabel";


const styles = (theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        zIndex: 10,
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        paddingTop: 80,
        borderRadius: 6,
        backgroundColor: "rgba(235, 235, 235, 0.95)",
        "@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))": {
            backdropFilter: "saturate(180%) blur(20px)",
            backgroundColor: "rgba(235, 235, 235, 0.75)",
        },
        transition: "opacity 0.2s ease-in-out",
        cursor: "pointer"
    },
    waitingContainer: {
        backgroundColor: alpha(theme.palette.success.main, 0.95),
        "@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))": {
            backdropFilter: "saturate(180%) blur(20px)",
            backgroundColor: alpha(theme.palette.success.main, 0.85)
        }
    },
    lateContainer: {
        backgroundColor: alpha(theme.palette.warning.main, 0.95),
        "@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))": {
            backdropFilter: "saturate(180%) blur(20px)",
            backgroundColor: alpha(theme.palette.warning.main, 0.85)
        }
    },
    tooLateContainer: {
        backgroundColor: alpha(theme.palette.error.main, 0.95),
        "@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))": {
            backdropFilter: "saturate(180%) blur(20px)",
            backgroundColor: alpha(theme.palette.error.main, 0.85)
        }
    },
    isHidden: {
        opacity: 0,
        pointerEvents: "none",
        cursor: "default"
    },
    horizontalSpacing: {
        width: 10
    },
    verticalSpacing: {
        height: 20
    },
    orderDetails: {
        display: "flex"
    }
});

const INITIALLY_HIDDEN_TIMEOUT = 1000;
const HIDDEN_TIMEOUT = 10000;

class OrderCardOverlay extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false
        };
    }

    componentDidMount() {
        this.setInitiallyVisibleTimeout = setTimeout(() => {
            this.setState({ isVisible: true })
        }, INITIALLY_HIDDEN_TIMEOUT);
    }

    componentWillUnmount() {
        if (this.setInitiallyVisibleTimeout) {
            clearTimeout(this.setInitiallyVisibleTimeout);
        }
        if (this.setVisibleTimeout) {
            clearTimeout(this.setVisibleTimeout);
        }
    }

    handleSetHidden = () => {
        if (this.setVisibleTimeout) {
            clearTimeout(this.setVisibleTimeout);
        }

        this.setState({ isVisible: false });

        this.setVisibleTimeout = setTimeout(() => {
            this.setState({ isVisible: true })
        }, HIDDEN_TIMEOUT);
    }

    render() {
        const { classes, timeOffset, bar, base, order, estimatedPreparationInfo } = this.props;
        const { isVisible } = this.state

        if (!order.isDeliveryMethodPickup) {
            return null;
        }

        return (
            <div className={classNames(
                classes.container,
                {
                    [classes.isHidden]: !isVisible,
                    [classes.lateContainer]: estimatedPreparationInfo?.customerLate,
                    [classes.tooLateContainer]: estimatedPreparationInfo?.customerTooLate
                }
            )} onClick={this.handleSetHidden}>
                <OrderEstimatedPreparationDurationLabel
                    timeOffset={timeOffset}
                    order={order}
                    bar={bar}
                    base={base}
                    estimatedPreparationInfo={estimatedPreparationInfo}
                    orientation="vertical"
                    color={estimatedPreparationInfo?.customerLate || estimatedPreparationInfo?.customerTooLate ? undefined : "black"}
                    showDetails={true}
                    isTransparent={true}
                />
                <div className={classes.verticalSpacing} />
                <div className={classes.orderDetails}>
                    <OrderSequenceNumberLabel
                        order={order}
                        base={base}
                        isCompact={true}
                        fontSize={18}
                    />
                    <div className={classes.horizontalSpacing} />
                    <OrderDeliveryLabel
                        order={order}
                        base={base}
                        hideDeliveryMethod={true}
                        hideColorText={true}
                        fontSize={18}
                    />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(OrderCardOverlay);
