import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Button, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { orderService } from "tap-io/client/services";
import OrderStatus from "tap-io/models/order/OrderStatus";

import { hasNativeWrapper, printer } from "../../native";
import ConfirmOverrideSubOrdersDialog from "./ConfirmOverrideSubOrdersDialog";

const styles = (theme) => ({
});

class ClaimAndCompleteOrder extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: false,
      isConfirmOverrideSubOrdersDialogOpen: false
    };
  }

  handleClaimAndCompleteOrder = async () => {
    const { order } = this.props;

    if (order.hasSubOrders) {
      this.setState({
        isConfirmOverrideSubOrdersDialogOpen: true
      });
    } else {
      await this.claimAndCompleteOrder();
    }
  };

  handleConfirmOverrideSubOrders = async () => {
    this.setState({
      isConfirmOverrideSubOrdersDialogOpen: false
    });

    await this.claimAndCompleteOrder();
  };

  handleCancelOverrideSubOrders = () => {
    this.setState({
      isConfirmOverrideSubOrdersDialogOpen: false
    });
  };

  claimAndCompleteOrder = async () => {
    const { t, base, order, onBusy, onDone, onError } = this.props;

    onBusy();

    const toastId = toast(t("order.claiming-order"), { autoClose: false });
    this.setState({ isDisabled: true });

    try {
      await orderService.setOrderStatusClaimed(order, base);

      //await this.handlePrintReceiptIfNeeded();

      toast.update(toastId, {
        render: t("order.completing-order")
      });

      await orderService.setOrderStatusComplete(order, base);

      toast.dismiss(toastId);

      onDone(); //this.setState({ isDisabled: false }, onDone);
    } catch (error) {
      console.warn(error);

      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });

      this.setState({ isDisabled: false }, onError);
    }
  };

  /*handlePrintReceiptIfNeeded = async () => {
    const { t, bar, base, order, deviceSettings, assets } = this.props;

    try {
      if (
        hasNativeWrapper() &&
        deviceSettings &&
        deviceSettings.printOrderWhenClaimed
      ) {
        await printer.printReceipt(
          t,
          bar,
          base,
          assets,
          order,
          deviceSettings.printBarLogo,
          deviceSettings.printOrderCustomerFields,
          deviceSettings.printAmountOfCopies
        );
      }
    } catch (error) {
      if (error) {
        toast.error(error.message);
      }
      console.warn(error);
    }
  };*/

  render() {
    const { classes, t, base, order, variant, size } = this.props;
    const { isDisabled, isConfirmOverrideSubOrdersDialogOpen } = this.state;

    return (
      <>
        <ConfirmOverrideSubOrdersDialog
          isOpen={isConfirmOverrideSubOrdersDialogOpen}
          message={t("order.override-suborders-by-claiming-order")}
          onConfirm={this.handleConfirmOverrideSubOrders}
          onCancel={this.handleCancelOverrideSubOrders}
        />
        {order?.getStatus(base) === OrderStatus.QUEUED ? (
          <Button
            disabled={isDisabled}
            variant={variant}
            size={size}
            onClick={this.handleClaimAndCompleteOrder}
          >
            {t("order.claim-and-complete-order")}
          </Button>
        ) : (
          <Typography>
            {t("order.cannot-claim-and-complete-order")}
          </Typography>
        )}
      </>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(withTranslation("common")(ClaimAndCompleteOrder));
