import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { List, ListItem, ListItemText } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { barService } from "tap-io/client/services";

import withAuthorization from "../auth/withAuthorization";
import { DEFERRED_PAYMENTS_PIN_LENGTH } from "../../constants/payments";
import EnterPinDialog from "tap-io/client/components/common/EnterPinDialog";

const styles = (theme) => ({});

const STATE_ENTER_CURRENT_PIN = "enterCurrentPin";
const STATE_ENTER_NEW_PIN = "enterNewPin";
const STATE_REPEAT_NEW_PIN = "repeatNewPin";

class EditPin extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = () => {
    return {
      isEnterPaymentPinDialogOpen: false,
      editPinState: null,
      oldPin: null,
      newPin: null,
      pin: ""
    };
  };

  handleUpdatePin = () => {
    const { bar } = this.props;

    this.setState({
      editPinState: bar.isPinEnabledForDeferredPayments()
        ? STATE_ENTER_CURRENT_PIN
        : STATE_ENTER_NEW_PIN,
      isEnterPaymentPinDialogOpen: true
    });
  };

  handleEnterPaymentPinDialogConfirm = async (pin) => {
    const { t } = this.props;
    const { editPinState, oldPin, newPin } = this.state;

    this.setState({ isEnterPaymentPinDialogOpen: false }, () => {
      switch (editPinState) {
        case STATE_ENTER_CURRENT_PIN:
          this.setState({
            oldPin: pin,
            editPinState: STATE_ENTER_NEW_PIN,
            isEnterPaymentPinDialogOpen: true
          });
          break;
        case STATE_ENTER_NEW_PIN:
          if (pin === oldPin) {
            this.setState({
              oldPin: null,
              newPin: null,
              editPinState: null
            });

            toast.error(t("settings.old-pin-and-new-pin-must-be-different"));
          } else {
            this.setState({
              newPin: pin,
              editPinState: STATE_REPEAT_NEW_PIN,
              isEnterPaymentPinDialogOpen: true
            });
          }
          break;
        case STATE_REPEAT_NEW_PIN:
          this.setState({
            oldPin: null,
            newPin: null,
            editPinState: null
          });

          if (pin === newPin) {
            this.updatePin(oldPin, newPin);
          } else {
            toast.error(t("settings.pins-do-not-match"));
          }
          break;
        default:
          this.setState({
            oldPin: null,
            newPin: null,
            editPinState: null
          });
          break;
      }
    });
  };

  updatePin = async (oldPin, newPin) => {
    const { t, auth, bar } = this.props;

    if (auth && auth.user && auth.user.uid) {
      const toastId = toast(t("settings.updating-pin"), { autoClose: false });

      try {
        await barService.updateBarPin(
          auth.user.uid,
          bar,
          oldPin,
          newPin,
          (taskId) => {
            console.log("taskId", taskId);
          }
        );

        toast.update(toastId, {
          render: t("settings.pin-updated"),
          type: toast.TYPE.INFO,
          autoClose: 3000
        });
      } catch (error) {
        console.warn(error);

        toast.update(toastId, {
          render:
            error && error.text
              ? error && error.text
              : t("error.unknown-error"),
          type: toast.TYPE.ERROR,
          autoClose: 3000
        });
      }
    } else {
      toast.error(t("error.no-authorized-user-found"));
    }
  };

  handleEnterPaymentPinDialogCancel = () => {
    this.setState({
      isEnterPaymentPinDialogOpen: false,
      oldPin: null,
      newPin: null
    });
  };

  render() {
    const { t, bar } = this.props;
    const { isEnterPaymentPinDialogOpen, editPinState } = this.state;

    return (
      <div>
        <EnterPinDialog
          isOpen={isEnterPaymentPinDialogOpen}
          onCancel={this.handleEnterPaymentPinDialogCancel}
          onConfirm={this.handleEnterPaymentPinDialogConfirm}
          bar={bar}
          title={
            editPinState === STATE_ENTER_NEW_PIN
              ? t("settings.enter-your-new-pin")
              : editPinState === STATE_REPEAT_NEW_PIN
              ? t("settings.repeat-your-new-pin")
              : t("settings.enter-your-current-pin")
          }
          pinLength={DEFERRED_PAYMENTS_PIN_LENGTH}
        />
        <List>
          <ListItem button onClick={this.handleUpdatePin}>
            <ListItemText
              primary={
                bar.isPinEnabledForDeferredPayments()
                  ? t("settings.edit-pin")
                  : t("settings.create-pin")
              }
              secondary={t("settings.pin-description")}
            />
          </ListItem>
        </List>
      </div>
    );
  }
}

export default withAuthorization()(
  withStyles(styles, { withTheme: true })(withTranslation("common")(EditPin))
);
