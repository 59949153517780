import { Box } from "@mui/material";
import { Asyncer } from "../../../components/common/Asyncer";
import { Async } from "../../../components/common/useAsync";
import { BaseReport } from "../../models/BaseReport";
import { DepositReport } from "../../models/DepositReport";
import { OperationalDeviceReport } from "../../models/OperationalDeviceReport";
import { OperationalLocationReport } from "../../models/OperationalLocationReport";
import { OperationalTimeslotReport } from "../../models/OperationalTimeslotReport";
import { ProductReport } from "../../models/ProductReport";
import { DownloadCSVButton } from "../common/DownloadCSVButton";
import { StatCard } from "../common/StatCard";

export type OperationalReportDownloadsProps = {
  product: Async<ProductReport>;
  deposit: Async<DepositReport>;
  base: Async<BaseReport>;
  location: Async<OperationalLocationReport>;
  device: Async<OperationalDeviceReport>;
  hour: Async<OperationalTimeslotReport>;

  date: Date;
  mark: string;
  t: any;
};

export function OperationalReportDownloads(
  props: OperationalReportDownloadsProps
) {
  const { product, deposit, base, location, device, hour, date, mark, t } =
    props;

  const title = t("stats.stat.downloads.title");
  const description = t("stats.stat.downloads.description");

  return (
    <StatCard title={title} description={description}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-evenly"
        }}
      >
        <Asyncer async={product}>
          {(report) => (
            <DownloadCSVButton
              title={t("stats.stat.operational-product.title")}
              mark={mark}
              csv={report.toCSV(t)}
            />
          )}
        </Asyncer>
        <Asyncer async={deposit}>
          {(report) => (
            <DownloadCSVButton
              title={t("stats.stat.operational-deposit.title")}
              mark={mark}
              csv={report.toCollectionCSV(t)}
            />
          )}
        </Asyncer>
        <Asyncer async={base}>
          {(report) => (
            <DownloadCSVButton
              title={t("stats.stat.operational-base.title")}
              mark={mark}
              csv={report.toCSV(t)}
            />
          )}
        </Asyncer>
        <Asyncer async={location}>
          {(report) => (
            <DownloadCSVButton
              title={t("stats.stat.operational-location.title")}
              mark={mark}
              csv={report.toCSV(t)}
            />
          )}
        </Asyncer>
        <Asyncer async={device}>
          {(report) => (
            <DownloadCSVButton
              title={t("stats.stat.operational-device.title")}
              mark={mark}
              csv={report.toCSV(t)}
            />
          )}
        </Asyncer>
        <Asyncer async={hour}>
          {(report) => (
            <DownloadCSVButton
              title={t("stats.stat.operational-timeslot.title")}
              mark={mark}
              csv={report.toCSV(date, t)}
            />
          )}
        </Asyncer>
      </Box>
    </StatCard>
  );
}
