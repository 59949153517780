import { Stat } from "./Stat";

type Data = {
  order: string;
  orderCode: string;
  payment: string;
  transaction: string;
  provider: string;
  method: string;
  reference: string;
  value: number;
  currency: string;
  timestamp: string;
};

export class TransactionReport extends Stat<Data> {
  formatProvider(t: any) {
    return (provider: string) => t(`payment.payment-provider-${provider}`);
  }
  formatMethod(t: any) {
    return (method: string) => {
      let formattedMethod = t(`payment.payment-method-${method}`);
      if (formattedMethod === `payment.payment-method-${method}`) {
        formattedMethod = method.charAt(0).toUpperCase() + method.slice(1);
      }
      return formattedMethod;
    };
  }

  toCSV(t: any) {
    return this.generateCSV(
      {
        order: true,
        orderCode: true,
        payment: true,
        transaction: true,
        provider: this.formatProvider(t),
        method: this.formatMethod(t),
        reference: true,
        value: this.formatCurrency,
        currency: true,
        timestamp: true
      },
      t
    );
  }
}
