import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import withStyles from "@mui/styles/withStyles";

import ShowTip from "./ShowTip";

const styles = (theme) => ({});

class ShowBasesTip extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { t, variant, label } = this.props;

    return (
      <ShowTip
        variant={variant}
        label={label ? label : t("base.bases")}
        tipTitle={t("help.bases-title")}
        tipIcon="/icons/bases.png"
        tipDescription={t("help.bases-description")}
        tipImage="/img/bases.png"
      />
    );
  }
}

export default withStyles(styles)(withTranslation("common")(ShowBasesTip));
