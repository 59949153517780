import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import EditMollie from "../settings/EditMollie";
import ShowVouchersTip from "../help/ShowVouchersTip";
import { barService } from "tap-io/client/services";

const styles = (theme) => ({
  container: {},
  vouchersDescription: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 600,
    padding: 10
  }
});

class EditPaymentProvidersStep extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    this.saveMollieConf = () => {
      console.warn("Mollie conf save function not set");
    };
  }

  componentDidMount() {
    const { setOnNext, bar } = this.props;
    setOnNext(async () => {
      // Save Mollie configuration
      await this.saveMollieConf();

      // Update onlinePayments accordingly
      // UPDATE: always enabled by default
      /*const { bar } = this.props;

      const allowOnlinePayments =
        bar.isAllowingMolliePayments() ||
        bar.areTherePaymentProvidersAvailable();
      const barParams = barService.createBarParams(bar, {
        allowOnlinePayments
      });
      await barService.updateBarParams(bar, barParams);*/
    });
  }

  render() {
    const { classes, t, bar } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.vouchersDescription}>
          <Typography>
            {t(
              "setup.de-facto-association-can-use-vouchers-as-an-alternative-description"
            )}
          </Typography>
          <ShowVouchersTip
            variant="button"
            label={t("setup.more-info-about-vouchers")}
          />
        </div>
        <EditMollie
          bar={bar}
          showSignUp={true}
          setOnSave={(onSave) => (this.saveMollieConf = onSave)}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditPaymentProvidersStep)
);
