import React, { PropsWithChildren, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  Theme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import { utilsHelper } from "tap-io/helpers";
import OrdersAsCards from "./OrdersAsCards";
import BarAssetsProps from "../bar/BarAssetsProps";
import BarDeviceSettingsProps from "../bar/BarDeviceSettingsProps";
import Bar from "tap-io/models/bar/Bar";
import Order from "tap-io/models/order/Order";
import FulfilmentMethod from "tap-io/models/fulfilment/FulfilmentMethod";
import Base from "tap-io/models/base/Base";
import ConfirmDialog from "tap-io/client/components/common/ConfirmDialog";

import OrderDrawer from "./OrderDrawer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cards: {
      margin: theme.spacing(0, -0.5)
    },
    list: {

    },
    deliveryMethods: {
      display: "flex"
    },
    status: {
      overflow: "hidden",
      border: "1px solid #ddd",
      borderRadius: 4
    },
    statusTitle: {
      textTransform: "uppercase",
      backgroundColor: "#ddd"
    },
    statusQueued: {
      width: "40%",
      marginRight: theme.spacing(0.5)
    },
    statusClaimed: {
      width: "60%",
      marginLeft: theme.spacing(0.5)
    },
    noOpenOrders: {
      display: "block",
      margin: `${theme.spacing(1)} ${theme.spacing(1)}`
    },
    spacing: {
      height: 40
    }
  })
);

type OpenOrdersForFulfilmentMethodProps = {
  cardWidth?: number,
  assets: BarAssetsProps,
  deviceSettings: BarDeviceSettingsProps,
  bar: Bar,
  openOrders: Order[],
  fulfilmentMethod: FulfilmentMethod,
  timeOffset: number,
  base?: Base
};

const maxAmountOfOpenOrdersDisplayed = 3;

function OpenOrdersForFulfilmentMethod({
  cardWidth,
  assets,
  deviceSettings,
  bar,
  openOrders,
  fulfilmentMethod,
  timeOffset,
  base
}: OpenOrdersForFulfilmentMethodProps) {
  const { t, i18n } = useTranslation("common");
  const classes = useStyles();

  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
  const [pickupOrderIdPendingSelection, setPickupOrderIdPendingSelection] = useState<string | null>(null);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

  useEffect(() => {
    const foundOrder =
      openOrders && selectedOrderId
        ? utilsHelper.findInArrayById(openOrders, selectedOrderId)
        : null;

    setSelectedOrderId(foundOrder ? foundOrder.id : null);
    setSelectedOrder(foundOrder);
  }, [openOrders, selectedOrderId]);

  const handleSelectOrder = (order: Order) => {
    if (selectedOrderId === order.id) {
      setSelectedOrderId(null);
    } else {
      if (base && order.isFulfilmentMethodOnDemand) {
        setPickupOrderIdPendingSelection(order?.id || null);
      } else {
        setSelectedOrderId(order?.id || null);
      }
    }
  };

  const handleSelectPickupOrderConfirm = () => {
    setPickupOrderIdPendingSelection(null);
    setSelectedOrderId(pickupOrderIdPendingSelection);
  };

  const handleSelectPickupOrderCancel = () => {
    setPickupOrderIdPendingSelection(null);
  };

  const handleClearSelectedOrder = () => {
    setSelectedOrderId(null);
  };

  /*const sortedOrders = () => {
    return openOrders ?
      openOrders.sort((orderA, orderB) =>
        orderA.isStatusClaimed(base) ?
          orderB.isStatusClaimed(base) ?
            (orderA.getStatusTimestamp()?.getTime() || 0) - (orderB.getStatusTimestamp()?.getTime() || 0) :
            -1
          :
          orderB.isStatusClaimed(base) ?
            1 :
            (orderB.priority.number - orderA.priority.number ||
              orderA.sequenceTimestamp.getTime() - orderB.sequenceTimestamp.getTime()
            )
      ) :
      [];
  };*/

  const sortedOrders = () => {
    return openOrders ?
      openOrders.sort((orderA, orderB) =>
        orderB.priority.number - orderA.priority.number ||
        orderA.sequenceTimestamp.getTime() - orderB.sequenceTimestamp.getTime()
      ) :
      [];
  };

  return (
    <div>
      <ConfirmDialog
        isOpen={pickupOrderIdPendingSelection !== null}
        title={t(
          "order.confirm-opening-of-pickup-order-that-should-be-scanned"
        )}
        description={t(
          "order.are-you-sure-you-want-to-open-this-order-that-should-be-scanned"
        )}
        confirmButtonLabel={t("label.open")}
        onConfirm={handleSelectPickupOrderConfirm}
        onCancel={handleSelectPickupOrderCancel}
      />
      <OrderDrawer
        timeOffset={timeOffset}
        bar={bar}
        base={base}
        order={selectedOrder}
        assets={assets}
        deviceSettings={deviceSettings}
        onClose={handleClearSelectedOrder}
      />
      <div className={classes.cards}>
        <OrdersAsCards
          cardWidth={cardWidth}
          bar={bar}
          orders={sortedOrders()}
          fulfilmentMethod={fulfilmentMethod}
          timeOffset={timeOffset}
          base={base}
          selectedOrderId={selectedOrderId}
          onSelect={handleSelectOrder}
        />
      </div>
    </div>
  );
}

export default OpenOrdersForFulfilmentMethod;