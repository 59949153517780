import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import withStyles from "@mui/styles/withStyles";

import { scannerService } from "tap-io/client/services";
import { baseHelper } from "tap-io/helpers";

import withAuthorization from "../auth/withAuthorization";
import SelectBase from "../base/SelectBase";
import EditPin from "../settings/EditPin";

const styles = (theme) => ({
  dialog: {
    minWidth: 400
  },
  spacing: {
    height: 15
  },
  inputContainer: {
    display: "flex",
    alignItems: "center"
  },
  inputLabel: {
    width: "100%"
  },
  inputInfo: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 10
  },
  inputInfoIcon: {
    marginTop: -2,
    marginRight: 5
  },
  accentuate: {
    fontWeight: "bold",
    color: theme.palette.error.main
  }
});

class AddScannerDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = () => {
    return {
      isDisabled: false,
      name: "",
      baseId: "",
      isActive: true,
      //allowCreatingOrders: false,
      allowCashPayments: false
    };
  };

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.props;

    if (isOpen && !prevProps.isOpen) {
      this.setState(this.initialState());
    }
  }

  handleNameChange = (event) => {
    const val = event.target.value;
    this.setState({ name: val });
  };

  handleBaseIdChange = (baseId) => {
    this.setState({ baseId });
  };

  handleIsActiveChange = (event) => {
    this.setState({ isActive: event.target.checked === true });
  };

  handleAllowCashPaymentsChange = (event) => {
    this.setState({ allowCashPayments: event.target.checked === true });
  };

  /*handleAllowCreatingOrdersChange = (event) => {
    this.setState({ allowCreatingOrders: event.target.checked === true });
  };*/

  handleAddScanner = async () => {
    const { t, auth, bar, onClose } = this.props;
    const { name, baseId, isActive, /*allowCreatingOrders,*/ allowCashPayments } = this.state;

    if (!auth || !auth.user || !auth.user.uid) {
      return toast.error(
        `${t("label.error")}: ${t("error.no-valid-user-found")}`
      );
    }

    const toastId = toast(t("scanner.adding-scanner"), { autoClose: false });
    this.setState({ isDisabled: true });

    try {
      const parsedName = name.trim();

      const scannerId = await scannerService.createScanner(
        auth.user.uid,
        bar,
        parsedName,
        baseId,
        isActive === true,
        //allowCreatingOrders === true,
        allowCashPayments === true
      );

      toast.update(toastId, {
        render: t("scanner.scanner-added"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false }, () => {
        onClose(scannerId);
      });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${t(
          error ? error.message : "error.unknown-error"
        )})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  handleClose = (event) => {
    // Prevent event from being passed down as property of onClose (must be undefined or voucherIds)
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { classes, t, isOpen, bar, allBases } = this.props;
    const { isDisabled, name, baseId, isActive, /*allowCreatingOrders,*/ allowCashPayments } =
      this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={this.handleClose}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle>{t("scanner.add-a-new-scanner")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("scanner.add-a-new-scanner-below")}
          </DialogContentText>
          <TextField
            variant="standard"
            margin="dense"
            label={t("label.name")}
            fullWidth
            value={name}
            disabled={isDisabled}
            onChange={this.handleNameChange}
          />
          <div className={classes.spacing} />
          <SelectBase
            isDisabled={isDisabled}
            allBases={allBases}
            basesFilter={baseHelper.onlyBasesUsingScannersFilter}
            selectedBaseId={baseId}
            onSelect={this.handleBaseIdChange}
          />
          <div className={classes.spacing} />
          <div className={classes.inputInfo}>
            <InfoIcon fontSize="small" className={classes.inputInfoIcon} />
            <Typography variant="caption">
              {t(
                "scanner.enable-scanners-in-base-settings-in-order-to-select-it"
              )}
            </Typography>
          </div>
          <div className={classes.spacing} />
          <div className={classes.inputContainer}>
            <div className={classes.inputLabel}>
              <Typography>{t("scanner.active")}</Typography>
            </div>
            <Switch
              edge="end"
              disabled={isDisabled}
              checked={isActive}
              onChange={this.handleIsActiveChange}
            />
          </div>
          <div className={classes.inputContainer}>
            <div className={classes.inputLabel}>
              <Typography>{t("scanner.allow-cash-payments")}</Typography>
            </div>
            <Switch
              edge="end"
              disabled={isDisabled || !bar.isPinEnabledForDeferredPayments()}
              checked={allowCashPayments}
              onChange={this.handleAllowCashPaymentsChange}
            />
          </div>
          {!bar.isPinEnabledForDeferredPayments() && (
            <div>
              <Typography className={classes.warning}>
                {`${t("scanner.for-scanners-to-take-cash-payments-you-need")} `}
                <strong>{t("promo.to-set-a-pin")}</strong>.
              </Typography>
              <EditPin bar={bar} />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isDisabled}
            onClick={this.handleClose}
            color="secondary"
          >
            {t("label.cancel")}
          </Button>
          <Button
            disabled={isDisabled}
            onClick={this.handleAddScanner}
            color="primary"
          >
            {t("label.add")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withAuthorization()(
  withStyles(styles)(withTranslation("common")(AddScannerDialog))
);

/*
<div className={classes.inputContainer}>
  <div className={classes.inputLabel}>
    <Typography>{t("scanner.allow-creating-orders")}</Typography>
  </div>
  <Switch
    edge="end"
    disabled={isDisabled}
    checked={allowCreatingOrders}
    onChange={this.handleAllowCreatingOrdersChange}
  />
</div>
*/