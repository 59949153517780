import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import ReactMoment from "react-moment";

import Moment from "moment";

import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  List,
  ListItem,
  ListItemText,
  Slide,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/Info";
import withStyles from "@mui/styles/withStyles";

import { barService, voucherService } from "tap-io/client/services";
import { utilsHelper, voucherHelper } from "tap-io/helpers";
import config from "tap-io/client/env";
import { deviceStorage } from "tap-io/storage";
import SelectPaymentMethod from "tap-io/client/components/payment/SelectPaymentMethod";

import withAuthorization from "../auth/withAuthorization";
import { hasNativeWrapper, printer } from "../../native";
import * as routes from "../../constants/routes";
import EditReplyToEmailAddressDialog from "../settings/EditReplyToEmailAddressDialog";
import EnterVoucherValueDialog from "./EnterVoucherValueDialog";
import CapabilityUnavailableButton from "../subscription/CapabilityUnavailableButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => ({
  content: {
    display: "flex",
    overflowY: "auto",
    flexDirection: "row",
    width: "100%",
    height: "100%"
  },
  buttons: {
    overflow: "auto",
    width: "100%"
  },
  order: {
    overflow: "auto",
    width: 400,
    height: "100%"
  },
  line: {
    width: "100%",
    height: 1,
    margin: "10px 0",
    backgroundColor: "#ccc"
  },
  customer: {
    flexDirection: "column"
  },
  fieldInfo: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 5
  },
  fieldInfoIcon: {
    marginRight: 5
  },
  item: {
    margin: 10
  },
  itemAmountAndName: {
    fontSize: 18
  },
  itemValidUntil: {
    fontStyle: "italic"
  },
  itemCombineVouchers: {
    display: "flex"
  },
  itemCombineVouchersIcon: {
    marginRight: theme.spacing(1)
  },
  itemValue: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  valueCalculation: {
    width: "100%"
  },
  calculatedValue: {
    width: "100%",
    fontSize: 16,
    textAlign: "right"
  },
  total: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  totalLabel: {
    width: "100%",
    fontSize: 18
  },
  totalAmount: {
    width: "100%",
    textAlign: "right",
    fontSize: 18
  },
  link: {
    textDecoration: "none"
  },
  spacing: {
    height: 40
  },
  disabled: {
    opacity: 0.4
  },
  personalVoucher: {
    borderRadius: 4,
    backgroundColor: theme.palette.background.default
  }
});

class GenerateVouchersFromTemplatesDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = (deviceSettings, voucherTemplates) => {
    return {
      isEditReplyToEmailAddressDialogOpen: false,
      voucherTemplateToEnterValueFor: null,
      isDisabled: false,
      voucherTemplates: voucherTemplates ? voucherTemplates : [],
      voucherLabel:"",
      customerEmail: "",
      paymentMethod: "",
      orderItems: [],
      orderTotal: 0,
      printVoucherWhenCreatedFromVoucherTemplate: deviceSettings
        ? deviceSettings.printVoucherWhenCreatedFromVoucherTemplate === true
        : false
    };
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isOpen } = this.props;

    const prevIsOpen = prevProps.isOpen;

    if (isOpen && !prevIsOpen) {
      this.handleSetNewOrder();
      this.refreshVoucherTemplates();
    } else if (prevIsOpen && !isOpen) {
      this.clearVoucherTemplatesSubscription();
    }
  }

  componentWillUnmount() {
    this.clearVoucherTemplatesSubscription();
  }

  handleSetNewOrder = () => {
    const { deviceSettings } = this.props;
    const { voucherTemplates } = this.state;

    this.setState(this.initialState(deviceSettings, voucherTemplates));
  };

  refreshVoucherTemplates = () => {
    const { bar } = this.props;

    this.clearVoucherTemplatesSubscription();

    if (bar && bar.id) {
      this.unsubscribeVoucherTemplates = voucherService.onAllVoucherTemplates(
        bar.id,
        (voucherTemplates) => {
          this.setState({ voucherTemplates });
        }
      );
    } else {
      this.setState({ voucherTemplates: [] });
    }
  };

  clearVoucherTemplatesSubscription = () => {
    if (this.unsubscribeVoucherTemplates) {
      this.unsubscribeVoucherTemplates();
      this.unsubscribeVoucherTemplates = undefined;
    }
  };

  handleVoucherTemplateClick = (voucherTemplate) => (event) => {
    if (!voucherTemplate.voucherValue) {
      this.handleEnterVoucherValue(voucherTemplate);
    } else {
      this.incrementItemWithAmount(voucherTemplate.id, voucherTemplate, 1);
    }
  };

  handleItemClick = (item) => (event) => {
    this.incrementItemWithAmount(item.id, item, -1);
  };

  incrementItemWithAmount = (itemId, item, amount) => {
    const orderItems = [...this.state.orderItems];

    let itemIndex = utilsHelper.findIndexInArrayById(orderItems, itemId);

    if (itemIndex === undefined) {
      itemIndex =
        orderItems.push({
          id: itemId,
          amount: 0,
          voucherLabel: item.name,
          voucherType: item.voucherType,
          voucherValue: item.voucherValue,
          voucherValidUntil: Moment()
            .add(item.voucherMaxAgeInDays, "days")
            .endOf("day")
            .toDate(),
          combineMultipleVouchers: item.combineMultipleVouchers,
          name: item.name
        }) - 1;
    }

    orderItems[itemIndex].amount += amount;

    if (orderItems[itemIndex].amount <= 0) {
      orderItems.splice(itemIndex, 1);
    }

    const orderTotal = orderItems.reduce((total, orderItem) => {
      return total + orderItem.voucherValue * orderItem.amount;
    }, 0);

    this.setState({
      orderItems,
      orderTotal
    });
  };

  handleVoucherLabelChange = (event) => {
    this.setState({ voucherLabel: event.target.value });
  };

  handleCustomerEmailChange = (event) => {
    this.setState({ customerEmail: event.target.value });
  };

  handlePaymentMethodChange = (paymentMethod) => {
    this.setState({ paymentMethod });
  };

  handleDoReplyToEmailAddressCheckAndGenerateVouchers = async () => {
    const { bar } = this.props;
    const { customerEmail } = this.state;

    if (
      customerEmail &&
      utilsHelper.parseEmail(customerEmail) &&
      !bar.params.replyToEmailAddress
    ) {
      this.handleEditReplyToEmailAddress();
    } else {
      await this.handleGenerateVouchers();
    }
  };

  handleEditReplyToEmailAddress = () => {
    this.setState({ isEditReplyToEmailAddressDialogOpen: true });
  };

  handleEditReplyToEmailAddressDialogClose = async (replyToEmailAddress) => {
    this.setState({ isEditReplyToEmailAddressDialogOpen: false });

    if (replyToEmailAddress) {
      await this.handleGenerateVouchers();
    }
  };

  handleEnterVoucherValue = (voucherTemplate) => {
    this.setState({ voucherTemplateToEnterValueFor: voucherTemplate });
  };

  handleEnterVoucherValueDialogClose = (voucherValue) => {
    const { voucherTemplateToEnterValueFor } = this.state;

    this.setState({ voucherTemplateToEnterValueFor: null });

    if (voucherValue) {
      const voucherTemplate = voucherTemplateToEnterValueFor.clone();
      voucherTemplate.voucherValue = voucherValue;

      this.incrementItemWithAmount(
        `${voucherTemplate.id}_${voucherValue}`,
        voucherTemplate,
        1
      );
    }
  };

  handleGenerateVouchers = async () => {
    const { t, auth, bar } = this.props;
    const { voucherLabel, customerEmail, paymentMethod, orderItems } = this.state;

    let parsedCustomerEmail;
    if (customerEmail) {
      parsedCustomerEmail = utilsHelper.parseEmail(customerEmail);

      if (!parsedCustomerEmail) {
        return toast.error(t("error.customer-email-is-not-valid"));
      }
    }

    if (!auth || !auth.user || !auth.user.uid) {
      return toast.error(
        `${t("label.error")}: ${t("error.no-valid-user-found")}`
      );
    }

    const toastId = toast(`${t("voucher.generate-vouchers")}...`, {
      autoClose: false
    });
    this.setState({ isDisabled: true });

    try {
      const data = await Promise.all(
        orderItems.map((item) =>
          voucherService.generateVouchers(
            auth.user.uid,
            bar,
            item.combineMultipleVouchers ? 1 : item.amount,
            item.voucherValidUntil,
            false,
            item.voucherType,
            item.combineMultipleVouchers
              ? utilsHelper.roundToTwoDecimals(item.amount * item.voucherValue)
              : item.voucherValue,
            voucherLabel,
            item.id,
            parsedCustomerEmail,
            paymentMethod ? paymentMethod : undefined
          )
        )
      );

      const voucherIds = data.reduce(
        (voucherIds, taskData) =>
          taskData && taskData.voucherIds
            ? voucherIds.concat(taskData.voucherIds)
            : voucherIds,
        []
      );

      if (voucherIds.length > 0) {
        this.printVouchersIfNeeded(voucherIds);
      }

      toast.update(toastId, {
        render: t("voucher.vouchers-generated"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false }, this.handleSetNewOrder);
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.message})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  printVouchersIfNeeded = async (voucherIds) => {
    const { t, bar, deviceSettings, assets } = this.props;

    try {
      if (
        hasNativeWrapper() &&
        deviceSettings &&
        deviceSettings.printVoucherWhenCreatedFromVoucherTemplate
      ) {
        const barUrl = await barService.getBarUrl(
          bar,
          config.hosting.orderDomain
        );

        await Promise.all(
          voucherIds.map(async (voucherId) => {
            const voucher = await voucherService.getVoucherById(
              bar.id,
              voucherId
            );
            const pin = await voucherService.getVoucherPin(voucher);
            const voucherCode = voucherHelper.encodeVoucherCode(
              voucher.id,
              pin
            );
            const voucherUrl = voucherHelper.getVoucherUrl(barUrl, voucherCode);

            await printer.printVoucher(
              t,
              bar,
              assets,
              voucher,
              pin,
              voucherUrl,
              deviceSettings.printBarLogo,
              1
            );
          })
        );
      }
    } catch (error) {
      if (error) {
        toast.error(error.message);
      }
      console.warn(error);
    }
  };

  handlePrintVoucherWhenCreatedFromVoucherTemplateChange = async (event) => {
    const { t, bar } = this.props;

    const printVoucherWhenCreatedFromVoucherTemplate =
      event.target.checked === true;

    this.setState({
      printVoucherWhenCreatedFromVoucherTemplate
    });

    if (bar) {
      try {
        await deviceStorage.updateDeviceSettings(bar.id, {
          printVoucherWhenCreatedFromVoucherTemplate
        });
      } catch (error) {
        toast.error(
          `${t("label.something-went-wrong")}: (${
            error && error.message ? error.message : t("error.unknown-error")
          })`
        );

        console.warn(error);
      }
    }
  };

  render() {
    const { classes, t, isOpen, onClose, bar, subscription } = this.props;
    const {
      isEditReplyToEmailAddressDialogOpen,
      voucherTemplateToEnterValueFor,
      isDisabled,
      voucherTemplates,
      voucherLabel,
      customerEmail,
      paymentMethod,
      orderItems,
      orderTotal,
      printVoucherWhenCreatedFromVoucherTemplate
    } = this.state;

    const currency =
      bar && bar.params && bar.params.orderCurrency
        ? bar.params.orderCurrency
        : "";

    const arePersonalVouchersAvailable =
      subscription &&
      subscription.capabilities &&
      subscription.capabilities.arePersonalVouchersAvailable();

    return (
      <div>
        <EditReplyToEmailAddressDialog
          isOpen={isEditReplyToEmailAddressDialogOpen}
          onClose={this.handleEditReplyToEmailAddressDialogClose}
          bar={bar}
        />
        <EnterVoucherValueDialog
          isOpen={voucherTemplateToEnterValueFor !== null}
          onClose={this.handleEnterVoucherValueDialogClose}
        />
        <Dialog
          open={isOpen}
          onClose={onClose}
          TransitionComponent={Transition}
          fullScreen
        >
          <DialogTitle>{t("voucher.generate-vouchers")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("voucher.generate-vouchers-below")}
            </DialogContentText>
            {voucherTemplates && voucherTemplates.length > 0 ? (
              <div className={classes.content}>
                <div className={classes.buttons}>
                  {voucherTemplates.map((voucherTemplate) => (
                    <Fab
                      key={voucherTemplate.id}
                      className={classes.item}
                      variant="extended"
                      disabled={isDisabled}
                      onClick={this.handleVoucherTemplateClick(voucherTemplate)}
                    >
                      {voucherTemplate.name}
                    </Fab>
                  ))}
                </div>
                <List className={classes.order}>
                  <ListItem className={classes.label}>
                    <TextField
                        label={`${t("voucher.voucher-label")} (${t(
                            "label.optional"
                        )})`}
                        value={voucherLabel}
                        onChange={this.handleVoucherLabelChange}
                        disabled={isDisabled}
                        variant="standard"
                        margin="normal"
                        fullWidth
                    />
                  </ListItem>
                  <div className={classes.personalVoucher}>
                    <div
                      className={
                        arePersonalVouchersAvailable
                          ? undefined
                          : classes.disabled
                      }
                    >
                      <ListItem className={classes.customer}>
                        <TextField
                          label={`${t("voucher.customer-email")} (${t(
                            "label.optional"
                          )})`}
                          error={
                            customerEmail &&
                            !utilsHelper.parseEmail(customerEmail)
                              ? true
                              : false
                          }
                          value={customerEmail}
                          onChange={this.handleCustomerEmailChange}
                          disabled={isDisabled || !arePersonalVouchersAvailable}
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                        <div className={classes.fieldInfo}>
                          <InfoIcon
                            fontSize="small"
                            className={classes.fieldInfoIcon}
                          />
                          <Typography variant="caption">
                            {t(
                              "voucher.confirmation-will-be-sent-to-this-email-address"
                            )}
                          </Typography>
                        </div>
                      </ListItem>
                      <ListItem>
                        <SelectPaymentMethod
                          isDisabled={
                            isDisabled || !arePersonalVouchersAvailable
                          }
                          addEmptyOption={true}
                          label={`${t("voucher.payment-method")} (${t(
                            "label.optional"
                          )})`}
                          paymentMethod={paymentMethod}
                          onChange={this.handlePaymentMethodChange}
                        />
                      </ListItem>
                    </div>
                    {!arePersonalVouchersAvailable && (
                      <ListItem>
                        <CapabilityUnavailableButton
                          bar={bar}
                          subscription={subscription}
                          label={t(
                            "voucher.personal-vouchers-available-in-other-plans"
                          )}
                        />
                      </ListItem>
                    )}
                  </div>
                  {hasNativeWrapper() && (
                    <ListItem>
                      <ListItemText primary={t("voucher.print-voucher")} />
                      <Switch
                        edge="end"
                        disabled={isDisabled}
                        checked={printVoucherWhenCreatedFromVoucherTemplate}
                        onChange={
                          this
                            .handlePrintVoucherWhenCreatedFromVoucherTemplateChange
                        }
                      />
                    </ListItem>
                  )}
                  <ListItem>
                    <ListItemText
                      primary={
                        <div className={classes.total}>
                          <span className={classes.totalLabel}>
                            {t("order.total")}
                          </span>
                          <strong
                            className={classes.totalAmount}
                          >{`${utilsHelper.formatToTwoDecimals(
                            orderTotal
                          )} ${currency}`}</strong>
                        </div>
                      }
                    />
                  </ListItem>
                  {orderItems &&
                    orderItems.map((item) => (
                      <ListItem
                        key={item.id}
                        disabled={isDisabled}
                        onClick={this.handleItemClick(item)}
                      >
                        <ListItemText
                          primary={
                            <span>
                              <span className={classes.itemAmountAndName}>
                                <strong>{item.amount}</strong>
                                {` x `}
                                <strong>{item.name}</strong>
                              </span>
                              <br />
                              <span className={classes.itemValidUntil}>
                                {t("voucher.voucher-valid-until")}{" "}
                                <ReactMoment
                                  locale={bar.getAppLocale()}
                                  format="DD/MM/YYYY"
                                >
                                  {item.voucherValidUntil}
                                </ReactMoment>
                              </span>
                              {item.combineMultipleVouchers && (
                                <span>
                                  <br />
                                  <span className={classes.itemCombineVouchers}>
                                    <CheckIcon
                                      className={
                                        classes.itemCombineVouchersIcon
                                      }
                                    />
                                    {t("voucher.combine-vouchers")}
                                  </span>
                                </span>
                              )}
                            </span>
                          }
                          secondary={
                            <span className={classes.itemValue}>
                              <span className={classes.valueCalculation}>
                                {`${
                                  item.amount
                                } x ${utilsHelper.formatToTwoDecimals(
                                  item.voucherValue
                                )} ${currency}`}
                              </span>
                              <span className={classes.calculatedValue}>
                                {`${utilsHelper.formatToTwoDecimals(
                                  item.amount * item.voucherValue
                                )} ${currency}`}
                              </span>
                            </span>
                          }
                        />
                      </ListItem>
                    ))}
                </List>
              </div>
            ) : (
              <div>
                <Typography>
                  {t("voucher.no-voucher-templates-found")}
                </Typography>
                <div className={classes.spacing} />
                <Link to={routes.PROMO} className={classes.link}>
                  <Button fullWidth onClick={onClose}>
                    {t("voucher.manage-voucher-templates")}
                  </Button>
                </Link>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button disabled={isDisabled} onClick={onClose} color="secondary">
              {t("label.close")}
            </Button>
            <Button
              disabled={isDisabled || !orderItems || orderItems.length === 0}
              onClick={this.handleDoReplyToEmailAddressCheckAndGenerateVouchers}
              color="primary"
            >
              {t("voucher.generate")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withAuthorization()(
  withStyles(styles)(
    withTranslation("common")(GenerateVouchersFromTemplatesDialog)
  )
);
