import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { List, ListItem, ListItemText, Switch } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { barService } from "tap-io/client/services";
import PaymentProvider from "tap-io/models/payment/PaymentProvider";

import ConfirmDeactivatePaymentProvider from "../payment/ConfirmDeactivatePaymentProvider";

const styles = (theme) => ({
  accentuate: {
    display: "block",
    color: "red"
  }
});

class EditDeferred extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmDeactivateDeferredPaymentsDialogOpen: false
    };
  }

  handleAllowDeferredPayments = async (event) => {
    const allowDeferredPayments = event.target.checked === true;

    if (allowDeferredPayments) {
      this.updateAllowDeferredPayments(allowDeferredPayments);
    } else {
      this.setState({ isConfirmDeactivateDeferredPaymentsDialogOpen: true });
    }
  };

  handleConfirmDeactivateDeferredPayments = () => {
    this.setState({ isConfirmDeactivateDeferredPaymentsDialogOpen: false });

    this.updateAllowDeferredPayments(false);
  };

  handleCancelDeactivateDeferredPayments = () => {
    this.setState({ isConfirmDeactivateDeferredPaymentsDialogOpen: false });
  };

  updateAllowDeferredPayments = async (allowDeferredPayments) => {
    const { t, bar } = this.props;

    const toastId = toast(
      allowDeferredPayments
        ? t("settings.enabling-deferred-payments")
        : t("settings.disabling-deferred-payments"),
      { autoClose: false }
    );
    this.setState({ isDisabled: true });

    try {
      await barService.setBarDeferredPaymentProviderIsActive(
        bar,
        allowDeferredPayments
      );

      toast.update(toastId, {
        render: allowDeferredPayments
          ? t("settings.deferred-payments-enabled")
          : t("settings.deferred-payments-disabled"),
        type: toast.TYPE.INFO,
        autoClose: 3000
      });

      this.setState({ isDisabled: false });
    } catch (error) {
      console.warn(error);

      this.setState({ isDisabled: false });
      toast.update(toastId, {
        render: `${t("label.something-went-wrong")} (${error.toString()})`,
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
    }
  };

  render() {
    const { classes, t, bar } = this.props;
    const { isDisabled, isConfirmDeactivateDeferredPaymentsDialogOpen } =
      this.state;

    const isAllowingDeferredPayments = bar.isAllowingDeferredPayments();

    const canDeferredPaymentsBeEnabled =
      bar.isPinEnabledForDeferredPayments() || isAllowingDeferredPayments;

    return (
      <div>
        <ConfirmDeactivatePaymentProvider
          isOpen={isConfirmDeactivateDeferredPaymentsDialogOpen}
          paymentProvider={PaymentProvider.DEFERRED}
          onConfirm={this.handleConfirmDeactivateDeferredPayments}
          onCancel={this.handleCancelDeactivateDeferredPayments}
        />
        <List>
          <ListItem>
            <ListItemText
              primary={t("settings.deferred-payments-label")}
              secondary={
                <span>
                  {t("settings.deferred-payments-description")}
                  {!canDeferredPaymentsBeEnabled && (
                    <span className={classes.accentuate}>
                      {` ${t(
                        "settings.create-pin-to-enable-deferred-payments"
                      )}`}
                    </span>
                  )}
                </span>
              }
            />
            <Switch
              onChange={this.handleAllowDeferredPayments}
              checked={isAllowingDeferredPayments}
              disabled={isDisabled || !canDeferredPaymentsBeEnabled}
            />
          </ListItem>
        </List>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("common")(EditDeferred)
);
